.profile-sources {
  display: flex;
  flex-direction: row;
  margin-right: 4px;
}
.profile-sources .source-link span {
  line-height: 20px;
}
.profile-sources .source-link {
  margin-right: 4px;
}
.profile-sources .source-link:last-child {
  margin-right: 0px;
}
.profile-sources .source-link-inner {
  height: 14px;
  width: 14px;
  font-size: 14px;
  opacity: 0.35;
  color: #112e48;
}
.profile-sources .source-link-inner:hover {
  opacity: 1;
}
