@import 'src/less/colors';

.section {
  margin: 16px 0;
}

.freeTrialMessage {
  color: @PrimaryCobalt60;
  margin: 16px 0;
}
