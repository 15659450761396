/*
 * colors.less
 */
.recursive-selector.depth_0 {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  padding: 4px;
}
.recursive-selector:not(.depth_0) {
  border-left: 1px solid #709dff;
  margin-left: 10px;
  padding-right: 20px;
}
.recursive-selector .selector-title {
  margin: 8px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  color: #4c5892;
}
.recursive-selector .selector-title.selected {
  background-color: #f1f6ff;
  color: #709dff;
}
