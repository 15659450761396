/*
 * colors.less
 */
.label {
  margin-bottom: 2px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1f2e77;
}
.label.secondary {
  color: #7982ad;
  font-size: 12px;
}
