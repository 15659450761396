/*
 * colors.less
 */
.warningBox {
  background-color: #fff7de;
  min-height: 64px;
  border-radius: 8px;
  padding: 16px;
  color: #ffab1c;
}
.warningBox .title {
  color: #ffab1c;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.warningBox .description {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #ffab1c;
}
