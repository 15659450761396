/*
 * flex.less
 */
/*
 * colors.less
 */
/* Text box */
.txt-box-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
  border: 1px solid #dae0f4;
  border-radius: 2px;
  height: auto;
  margin-top: 10px;
}
.txt-box-container .box-title {
  background-color: white;
  border-radius: 2px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.txt-box-container .box-title .box-identity {
  display: flex;
  flex-direction: row;
}
.txt-box-container .box-title .box-identity .user-thumbnail .image.initials {
  background-color: #f9c8ca;
}
.txt-box-container .box-title .box-identity .user-thumbnail .image.initials .initials-inner-text {
  color: #1f2e77;
}
.txt-box-container .box-title .box-email-header {
  font-family: Graphik;
  font-weight: 600;
  font-size: 14px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}
.txt-box-container .box-title .box-email-body {
  font-size: 13px;
  color: #767689;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}
.txt-box-container .box-title .box-action {
  font-size: 13px;
  color: #f86e7f;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
}
.txt-box-container .box-title .box-action:hover {
  text-decoration: underline;
}
