/*
 * colors.less
 */
.add-to-sequence-sidebar .side-panel-panel {
  width: 480px;
}
.add-to-sequence-sidebar,
.add-to-project-sidebar {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.add-to-sequence-sidebar .dimmer,
.add-to-project-sidebar .dimmer {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  height: 100%;
  flex: 1;
}
.add-to-sequence-sidebar .sidebar,
.add-to-project-sidebar .sidebar {
  width: 400px;
  height: 100%;
  background-color: white;
}
.add-to-sequence-sidebar .no-sequences-content,
.add-to-project-sidebar .no-sequences-content {
  height: calc(100% - 44px);
  background-color: #f8faff;
  padding: 24px;
  font-size: 15px;
  line-height: 1.3;
}
.add-to-sequence-sidebar .tasks-form .job-dropdown,
.add-to-project-sidebar .tasks-form .job-dropdown,
.add-to-sequence-sidebar .tasks-form #sequence-dropdown,
.add-to-project-sidebar .tasks-form #sequence-dropdown {
  margin-bottom: 1em;
}
.add-to-sequence-sidebar .tasks-form .job-dropdown > span,
.add-to-project-sidebar .tasks-form .job-dropdown > span,
.add-to-sequence-sidebar .tasks-form #sequence-dropdown > span,
.add-to-project-sidebar .tasks-form #sequence-dropdown > span {
  color: #1f2e77;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
}
.add-to-sequence-sidebar .tasks-form .ui.search.dropdown .text,
.add-to-project-sidebar .tasks-form .ui.search.dropdown .text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 2px;
}
.add-to-sequence-sidebar .tasks-form .ui.search.dropdown .text span,
.add-to-project-sidebar .tasks-form .ui.search.dropdown .text span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  margin-top: 2px;
}
.add-to-sequence-sidebar .tasks-form .ui.search.dropdown .text .sequence-preview,
.add-to-project-sidebar .tasks-form .ui.search.dropdown .text .sequence-preview {
  flex: 1;
  min-width: 200px;
  margin: 0px 8px;
  display: inline-block;
}
.add-to-sequence-sidebar .tasks-form .ui.search.dropdown .text .sequence-preview .sequence-container .row-sequence-container .row-icon-container,
.add-to-project-sidebar .tasks-form .ui.search.dropdown .text .sequence-preview .sequence-container .row-sequence-container .row-icon-container {
  margin-top: 0;
}
.add-to-sequence-sidebar .tasks-form .sequence-dropdown .menu .item,
.add-to-project-sidebar .tasks-form .sequence-dropdown .menu .item {
  padding: 0px !important;
}
.add-to-sequence-sidebar .tasks-form .sequence-dropdown .menu .item .hover-item-popup-trigger,
.add-to-project-sidebar .tasks-form .sequence-dropdown .menu .item .hover-item-popup-trigger {
  padding: 0.9em 1em;
}
.add-to-sequence-sidebar .tasks-form .menu .item .hover-item-popup-trigger,
.add-to-project-sidebar .tasks-form .menu .item .hover-item-popup-trigger,
.add-to-sequence-sidebar .tasks-form .selection.dropdown .text,
.add-to-project-sidebar .tasks-form .selection.dropdown .text {
  display: flex;
  flex-direction: row;
  justify-items: baseline;
}
.add-to-sequence-sidebar .tasks-form .menu .item .hover-item-popup-trigger span,
.add-to-project-sidebar .tasks-form .menu .item .hover-item-popup-trigger span,
.add-to-sequence-sidebar .tasks-form .selection.dropdown .text span,
.add-to-project-sidebar .tasks-form .selection.dropdown .text span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  margin-top: 2px;
}
.add-to-sequence-sidebar .tasks-form .menu .item .hover-item-popup-trigger .sequence-preview,
.add-to-project-sidebar .tasks-form .menu .item .hover-item-popup-trigger .sequence-preview,
.add-to-sequence-sidebar .tasks-form .selection.dropdown .text .sequence-preview,
.add-to-project-sidebar .tasks-form .selection.dropdown .text .sequence-preview {
  flex: 1;
  min-width: 200px;
  margin: 0px 8px;
  align-self: center;
  display: inline-block;
}
.add-to-sequence-sidebar .tasks-form .menu .item .hover-item-popup-trigger .sequence-preview .sequence-container .row-sequence-container .row-icon-container,
.add-to-project-sidebar .tasks-form .menu .item .hover-item-popup-trigger .sequence-preview .sequence-container .row-sequence-container .row-icon-container,
.add-to-sequence-sidebar .tasks-form .selection.dropdown .text .sequence-preview .sequence-container .row-sequence-container .row-icon-container,
.add-to-project-sidebar .tasks-form .selection.dropdown .text .sequence-preview .sequence-container .row-sequence-container .row-icon-container {
  margin-top: 0;
}
.add-to-sequence-sidebar .content,
.add-to-project-sidebar .content {
  height: calc(100% - 70px);
  background-color: #f8faff;
  padding: 24px;
}
.add-to-sequence-sidebar .sequence-dropdown.field,
.add-to-project-sidebar .sequence-dropdown.field {
  margin-top: 24px;
}
.add-to-sequence-sidebar .ui.placeholder,
.add-to-project-sidebar .ui.placeholder {
  background-color: #e2ebff;
  margin: 15px 0px !important;
}
.add-to-sequence-sidebar .ui.placeholder:first-of-type,
.add-to-project-sidebar .ui.placeholder:first-of-type {
  margin-top: 0px !important;
}
.add-to-sequence-sidebardiv:not(.placeholder) .header,
.add-to-project-sidebardiv:not(.placeholder) .header {
  padding: 12px 16px;
  color: white;
  background-color: #1f2e77;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-to-sequence-sidebardiv:not(.placeholder) .header h4,
.add-to-project-sidebardiv:not(.placeholder) .header h4 {
  margin: 0;
}
.add-to-sequence-sidebardiv:not(.placeholder) .header i,
.add-to-project-sidebardiv:not(.placeholder) .header i {
  color: #a5abc9;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
.add-to-sequence-sidebardiv:not(.placeholder) .header i:hover,
.add-to-project-sidebardiv:not(.placeholder) .header i:hover {
  color: white;
}
.add-to-sequence-sidebar .footer,
.add-to-project-sidebar .footer {
  padding: 16px 24px;
  box-shadow: 0px -4px 17px rgba(186, 186, 186, 0.75);
  justify-content: flex-end;
  display: flex;
}
.add-to-sequence-sidebar .ui.button.submit-button,
.add-to-project-sidebar .ui.button.submit-button {
  padding: 8px 16px;
}
.add-to-sequence-sidebar .sequence-preview-container.ui.segment,
.add-to-project-sidebar .sequence-preview-container.ui.segment {
  z-index: 0;
  padding: 24px 24px 24px 12px;
}
.add-to-sequence-sidebar .auto-send-container button,
.add-to-project-sidebar .auto-send-container button {
  padding-left: 0;
}
.add-to-sequence-sidebar .auto-send-container .auto-send-warning,
.add-to-project-sidebar .auto-send-container .auto-send-warning {
  font-size: 11px;
  color: #7982ad;
}
.add-to-sequence-sidebar .ui.dropdown,
.add-to-project-sidebar .ui.dropdown {
  border: 1px solid #dae0f4;
  background-color: white;
  padding: 10px;
}
.add-to-sequence-sidebar .ui.dropdown .menu,
.add-to-project-sidebar .ui.dropdown .menu {
  width: 100%;
}
.add-to-sequence-sidebar .ui.dropdown .menu .header,
.add-to-project-sidebar .ui.dropdown .menu .header {
  margin: 0;
  padding: 10px 10px;
  background-color: white;
  color: #a5abc9;
}
.add-to-sequence-sidebar .ui.dropdown .menu .divider,
.add-to-project-sidebar .ui.dropdown .menu .divider {
  margin: 1em;
}
