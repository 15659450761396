/*
 * colors.less
 */
.edit-mission-modal .ui.form {
  width: 100%;
}
.edit-mission-modal .actions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.custom-field-date-container {
  margin-bottom: 1em;
}
.custom-field-date-container label {
  display: block;
  margin: 0em 0em 0.57142857rem 0em;
  color: #4c5892;
  font-size: 1rem;
  font-weight: 500;
}
