/*
 * colors.less
 */
.card {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  margin: 16px 0;
}
.title {
  color: #1f2e77;
}
.filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}
