/*
 * colors.less
 */
.jobBreadcrumb {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
}
.jobBreadcrumb .breadCrumbSection {
  font-family: Gilroy;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  color: #1f2e77;
  white-space: nowrap;
}
.jobBreadcrumb .breadCrumbSection.collapsible {
  text-overflow: ellipsis;
  overflow: hidden;
}
.jobBreadcrumb .breadcrumbDivider {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  color: #1f2e77;
}
