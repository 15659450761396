/*
 * colors.less
 */
.table {
  border-radius: 8px !important;
  overflow: hidden;
}
.table .header {
  background: #f8faff !important;
  border: 1px solid #dae0f4 !important;
}
.table .header .th {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  color: #7982ad;
}
.table .header .th.th:first-of-type {
  border-top-left-radius: 8px;
}
.table .header .th.th:last-of-type {
  border-top-right-radius: 8px;
}
.table .row {
  background: white !important;
  border-bottom: 1px solid #dae0f4 !important;
}
.table .row:last-of-type .unipileIcon {
  border-bottom-left-radius: 8px;
}
.table .row:last-of-type .actions {
  border-bottom-right-radius: 8px;
}
.table .row .unipileIcon {
  padding-right: 0px;
}
.table .row .unipileIcon .icon {
  display: flex;
  border-radius: 3px;
  padding: 2px;
}
.table .row .status {
  margin: 0px;
  font-family: 'Graphik', sans-serif;
  display: inline-flex;
  align-items: center;
  height: 20px;
}
.table .row .status i {
  margin: 0px 5px 1px 0px;
}
.table .row .sharedWithContainer {
  display: flex;
  flex-direction: column;
}
.table .row .name {
  color: #4864c9 !important;
  font-weight: 500 !important;
}
.table .row .name .taskIcon {
  margin-right: 8px;
}
.table .row .subtype {
  color: #1f2e77 !important;
}
.table .row .icon {
  margin: 0px 0px;
  color: #7982ad;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.actionPanel {
  width: fit-content;
}
.actionPanel .actionPanelItem span {
  margin-right: 8px;
  color: #7982ad;
}
.emptyState {
  margin-bottom: 12px;
}
