/*
 * colors.less
 */
.followup-kanban-card.ui.segment.disabled {
  border: 1px solid #dae0f4;
  user-select: none;
  opacity: 1;
  box-shadow: none;
}
.followup-kanban-card.ui.segment.disabled .avatar-image {
  filter: grayscale(100%);
}
.followup-kanban-card.ui.segment.disabled .card-body .source-indicator {
  filter: grayscale(1);
}
.followup-kanban-card.ui.segment.disabled .card-info .full-name,
.followup-kanban-card.ui.segment.disabled .card-info .headline .headline-text,
.followup-kanban-card.ui.segment.disabled .card-info .job {
  color: #7982ad;
}
.followup-kanban-card.ui.segment.disabled .card-info .profile-state {
  color: #a5abc9;
}
.followup-kanban-card.ui.segment.disabled .card-footer .task-icon,
.followup-kanban-card.ui.segment.disabled .card-footer .popup-trigger {
  filter: grayscale(1);
}
.followup-kanban-card.ui.segment {
  padding: 0px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
  transition: transform 250ms;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.08);
}
.followup-kanban-card.ui.segment .header-annotations {
  position: absolute;
  left: -4px;
  top: -4px;
}
.followup-kanban-card.ui.segment:focus,
.followup-kanban-card.ui.segment:hover {
  border: 1px solid #709dff;
}
.followup-kanban-card.ui.segment .skip-profile-button-container {
  flex-grow: 1;
}
.followup-kanban-card.ui.segment .skip-profile-button-container .skip-profile-button {
  padding: 8px;
  border: 1px solid #e2ebff;
  float: right;
  color: #7982ad;
  border-radius: 2px;
  cursor: pointer;
  margin: 12px 12px 0px 0px;
}
.followup-kanban-card.ui.segment .skip-profile-button-container .skip-profile-button i {
  vertical-align: -3px;
}
.followup-kanban-card.ui.segment .card-body {
  padding: 16px;
  display: flex;
  cursor: grab;
  flex-direction: row;
  border-radius: 4px;
}
.followup-kanban-card.ui.segment .card-body .card-avatar {
  margin-right: 12px;
  flex-shrink: 0;
  position: relative;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .hired-avatar-icon {
  background-color: #28ca42;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .hired-avatar-icon i {
  position: relative;
  top: -3px;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .source-indicator {
  z-index: 9;
  position: absolute;
  top: 40px;
  left: 40px;
  background-color: #709dff;
  height: 16px;
  min-width: 16px;
  border-radius: 8px;
  border: 2px solid white;
  box-sizing: border-box;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .source-indicator i {
  font-size: 8px;
  position: absolute;
  top: 1.5px;
  left: 1px;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .source-indicator span.prequalified-label {
  position: absolute;
  left: 16px;
  color: white;
  font-size: 12px;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .source-indicator.hover {
  line-height: 12px;
  width: 100px;
}
.followup-kanban-card.ui.segment .card-body .card-avatar .source-indicator.career-page {
  background-color: #28ca42;
}
.followup-kanban-card.ui.segment .card-body .avatar-image {
  width: 56px;
  height: 56px;
}
.followup-kanban-card.ui.segment .card-body .card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown {
  padding: 4px 8px 4px 0px;
  color: #4864c9;
  font-weight: 500;
  font-size: 12px;
  border: none !important;
  box-shadow: none;
  height: 18px;
  min-height: 0px !important;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown .text {
  line-height: 15px;
  padding-top: 0px;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown input {
  padding: 0px;
  padding-top: 0px;
  margin-left: 0px;
  cursor: pointer !important;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown .search {
  text-overflow: ellipsis;
  overflow: hidden;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown .menu {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  left: -17px;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown .menu .item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.followup-kanban-card.ui.segment .card-body .card-info .ui.dropdown .dropdown.icon {
  display: none;
}
.followup-kanban-card.ui.segment .card-body h4.full-name {
  color: #1f2e77;
  margin-bottom: 4px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.followup-kanban-card.ui.segment .card-body h4.full-name .profile-state {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  vertical-align: middle;
  margin-left: 4px;
}
.followup-kanban-card.ui.segment .card-body .headline {
  color: #7982ad;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.followup-kanban-card.ui.segment .card-body .headline .headline-text {
  display: block;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
@supports (-webkit-line-clamp: 2) {
  .followup-kanban-card.ui.segment .card-body .headline .headline-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.followup-kanban-card.ui.segment .card-body .job {
  color: #4864c9;
  font-weight: 500;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
}
.followup-kanban-card.ui.segment .card-body .sources-container {
  margin-top: 6px;
  white-space: pre-wrap;
}
.followup-kanban-card.ui.segment .card-body .skip-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #d2d5e4;
}
.followup-kanban-card.ui.segment .card-body .skip-icon-container:hover {
  color: #f66f81;
}
.followup-kanban-card.ui.segment .card-body .skip-icon-container i {
  font-weight: 600;
  font-size: 16px;
}
.followup-kanban-card.ui.segment .ui.divider {
  margin: 0px;
}
.followup-kanban-card.ui.segment .card-footer {
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 4px;
  position: relative;
}
.followup-kanban-card.ui.segment .card-footer img {
  margin-left: 4px;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage {
  height: 24px !important;
  margin-left: 8px;
  flex-grow: 1;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage .dropdown {
  min-height: 24px !important;
  height: 24px !important;
  padding: 3.5px 20px 3.5px 8px;
  border: none;
  background-color: #f6f7fc !important;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage .dropdown .text {
  line-height: 17px;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage .dropdown .popup-trigger .i {
  font-family: 'Icons';
  width: 12px;
  vertical-align: -1px;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage .dropdown .menu {
  border: none;
  box-shadow: 0px 2px 16px 0px rgba(31, 46, 119, 0.15) !important;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage .dropdown {
  font-size: 12px;
  color: #7982ad;
}
.followup-kanban-card.ui.segment .card-footer .in-process-label,
.followup-kanban-card.ui.segment .card-footer .reply-status,
.followup-kanban-card.ui.segment .card-footer .reply-date {
  font-family: Graphik;
  font-size: 12px;
  color: #7982ad;
}
.followup-kanban-card.ui.segment .card-footer .in-process-stage {
  max-width: 200px;
}
.followup-kanban-card.ui.segment .card-footer .in-process-label,
.followup-kanban-card.ui.segment .card-footer .reply-status {
  font-weight: 500;
  margin-left: 8px;
}
.followup-kanban-card.ui.segment .card-footer .reply-date {
  font-weight: 400;
  margin-left: 4px;
}
.followup-kanban-card.ui.segment .card-footer .popup-trigger i {
  vertical-align: -2px;
}
.followup-kanban-card.ui.segment .card-footer .ri-user-smile-line {
  color: #28ca42;
}
.followup-kanban-card.ui.segment .card-footer .ri-user-smile-line.grey {
  filter: grayscale(100%);
}
.followup-kanban-card.ui.segment .card-footer .ri-emotion-sad-line {
  color: #ff6059;
}
.followup-kanban-card.ui.segment .card-footer #change-answer-icon {
  font-size: 18px;
  cursor: pointer;
}
.followup-kanban-card.ui.segment .card-footer .flex-grow {
  flex-grow: 1;
}
