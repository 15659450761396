/*
 * colors.less
 */
.title {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
}
.field {
  width: 100%;
}
