@import 'src/less/colors';

.pill {
  display: inline-flex;
  padding: 0px 8px;
  align-items: center;
  font-size: 10px;
  border-radius: 1em;
  background-color: @SecondaryBlue10;
  color: @SecondaryBlue;
  gap: 4px;
}
