/*
 * colors.less
 */
.answerMenuWrapper {
  position: absolute;
  min-width: 200px;
  min-height: min-content;
  background-color: white;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 4px;
  bottom: 35px;
  right: -10px;
  padding: 12px;
  cursor: initial;
  z-index: 1;
}
.answerMenuWrapper .title {
  margin: 0px 0px 4px 0px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  color: #7982ad;
}
.answerMenuWrapper .option {
  color: #1f2e77;
  margin-top: 8px;
  font-weight: 500;
  align-items: baseline;
  cursor: pointer;
}
.answerMenuWrapper .option i {
  vertical-align: -2px;
  margin-right: 4px;
  font-size: 16px;
}
