/*
 * colors.less
 */
.ui.dropdown .menu .top-header-dropdown-item.item {
  background-color: white;
  color: #a5abc9;
}
.ui.dropdown .menu .top-header-dropdown-item.item:hover {
  background-color: #f6f7fc;
}
.ui.dropdown .menu .top-header-dropdown-item.item.active {
  color: #1f2e77;
  font-weight: inherit;
}
.ui.dropdown .menu .top-header-dropdown-item.item.top-header-dropdown-item--reveal.active {
  color: #4864c9;
}
.navigation-item-link {
  height: 100%;
  display: flex;
  align-items: center;
}
.navigation-item-link.active .navigation-item {
  color: #1f2e77;
}
.navigation-item-link.active .navigation-item.with-icon,
.navigation-item-link.active .navigation-item.navigation-item--reveal {
  color: #4864c9;
}
.navigation-item-link .navigation-item {
  margin-right: 36px;
  white-space: nowrap;
  border-radius: 4px;
  color: #a5abc9;
  font-size: 14px;
  font-family: Graphik, sans-serif;
  font-weight: 600;
}
.navigation-item-link .navigation-item.with-icon {
  margin-right: 24px;
  padding: 4px 8px;
  font-weight: 500;
}
.navigation-item-link .navigation-item.with-icon:hover {
  background-color: #f6f7fc;
}
.navigation-item-link .navigation-item .icon {
  margin-right: 9px;
  font-size: 16px;
}
.navigation-item-link .navigation-item .menu > .item {
  font-size: 14px;
}
