/*
 * colors.less
 */
.scale-rating .ui.basic.button.scale-rating-button {
  height: 33px;
  width: 33px;
  padding: 0;
  border-radius: 0;
  color: #7982ad !important;
}
.scale-rating .ui.basic.button.scale-rating-button.selected {
  color: #709dff !important;
  box-shadow: 0px 0px 0px 1px #709dff inset;
  background-color: #f1f6ff !important;
}
.scale-rating .ui.basic.button.scale-rating-button:last-child {
  margin-right: 0;
}
.scale-rating .legend {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Graphik, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #7982ad;
}
