@import 'src/less/colors';

.switch {
  display: flex;
  padding: 2px;
  border-radius: 4px;
  background-color: @SecondaryBlue20;
}

.option {
  flex: 1;
  border-radius: 4px;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  color: @PrimaryCobalt60;

  &.selected {
    box-shadow: 0px 2px 8px 0px @PrimaryCobaltOp06;
    background-color: white;
    color: @PrimaryCobalt;
  }

  &:not(.selected) {
    cursor: pointer;
  }
}
