/*
 * colors.less
 */
.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 8px;
  flex: 1.5 0;
}
span.pill {
  padding: 2px 8px;
  min-height: 24px;
  max-width: 200px;
}
span.pill span {
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pillLogo {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: -4px;
  margin-right: -4px;
}
.rejectedAtsPill {
  opacity: 0.4;
}
.notInAJobPill span {
  color: #91a2df;
}
