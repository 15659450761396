@import 'src/less/colors';

.formInputLegend {
  color: @PrimaryCobalt40;
  font-size: 12px;

  &.clickable {
    cursor: pointer;
  }
}

.clearButton {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: hsl(0, 0%, 80%);

  &:hover {
    color: hsl(0, 0%, 40%);
  }

  .clearIcon {
    font-size: 20px;
  }
}
