@import 'src/less/colors';

.actionButton {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: @SecondarySkyBlue120;
  cursor: pointer;
  white-space: nowrap;

  i {
    display: inline-block;
    margin-left: 5px;
    &:before {
      vertical-align: bottom;
      line-height: 13px;
    }
  }
}
