/*
 * colors.less
 */
.customTaskPreviewContainer {
  padding: 24px;
  background-color: #f6f7fc;
  min-height: 100%;
}
.customTaskPreviewContainer .previewTitle {
  text-align: center;
  color: #1f2e77;
  font-family: Graphik;
}
.customTaskPreviewContainer .customTaskPreview {
  padding: 12px;
  border-radius: 8px;
  border: none !important;
  background-color: white;
}
.customTaskPreviewContainer .customTaskPreview .customTaskPreviewHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}
.customTaskPreviewContainer .customTaskPreview .customTaskPreviewHeader .taskIcon {
  width: 16px;
  height: 16px;
}
.customTaskPreviewContainer .customTaskPreview .customTaskPreviewHeader h5 {
  margin-top: 3px;
}
.customTaskPreviewContainer .customTaskPreview .fakeCompletionButton {
  background-color: #28ca42 !important;
  width: 100%;
}
