/*
 * colors.less
 */
.modalContent {
  padding: 0 !important;
  height: 400px;
}
.modalDescription {
  font-family: Graphik;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1em;
  letter-spacing: 0px;
  text-align: center;
  color: #1f2e77;
}
.jobPostingmodalContainer {
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  gap: 24px;
}
.templateSelectorContainer {
  width: 100%;
  min-width: 100%;
  display: inline-block;
  height: inherit;
  display: flex;
  flex-direction: column;
}
.templateSelectorContainer .description {
  color: #4c5892;
}
.templateSelectorContainer > div {
  padding: 0px 24px;
}
.templateSelectorContainer > div:first-of-type {
  padding: 24px 24px 0px 24px;
}
.templateSelectorContainer > div:last-of-type {
  padding: 0px 24px 24px 24px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}
.templateSelectorContainer > div:last-of-type::-webkit-scrollbar {
  display: none;
}
.jobPostingActions {
  display: flex;
  gap: 16px;
  align-items: center;
}
.jobPostingPreviewTrigger {
  color: #7982ad;
}
.jobPostingPreview {
  width: 400px;
}
.offerSearch {
  width: 100%;
  margin-top: 24px;
  flex-shrink: 0;
}
.jobPostingsContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}
.jobPostingsContainer .emptyState {
  color: #7982ad;
  text-align: center;
}
.jobPostingsContainer .jobPosting {
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #1f2e771a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.jobPostingsContainer .jobPosting .jobPostingHeader h4 {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  margin-bottom: 0px;
  display: inline;
}
.jobPostingsContainer .jobPosting .publicationStatus {
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  line-height: 20px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
}
.jobPostingsContainer .jobPosting .publicationStatus.published {
  color: #28ca42;
}
.jobPostingsContainer .jobPosting .publicationStatus.unpublished {
  color: #7982ad;
}
.jobPostingsContainer .jobPosting .publicationStatus i {
  margin-right: 5px;
}
.choicesContainer {
  display: inline-flex;
  gap: 8px;
  width: 100%;
  min-width: 100%;
  margin-left: 0px;
  transition: margin-left 250ms;
  padding: 24px;
}
.choicesContainer.hidden {
  margin-left: calc(-100% - 24px);
}
.choicesContainer .choiceCard {
  flex: 1 1;
  text-align: center;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #dae0f4;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
  margin: auto;
}
.choicesContainer .choiceCard img {
  width: 100%;
}
.choicesContainer .choiceCard .choiceTitle {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #1f2e77;
  margin: 8px 0px;
}
.choicesContainer .choiceCard .choiceDescription {
  flex: 1 1;
  color: #7982ad;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.choicesContainer .choiceCard .choiceCardAction {
  position: relative;
  width: 100;
}
.choicesContainer .choiceCard .choiceCardAction .choiceButton {
  width: 100%;
  padding: 10px 0px;
  height: auto;
  line-height: 20px;
  transition: background 250ms;
  position: relative;
  z-index: 12;
}
.choicesContainer .choiceCard .choiceCardAction .choiceButton.AIGeneration {
  background: transparent;
}
.choicesContainer .choiceCard .choiceCardAction .gradientButtonBackground {
  background: linear-gradient(90deg, #4568dc 0%, #b06ab3 100%);
  width: 100%;
  padding: 10px 0px;
  height: auto;
  line-height: 20px;
  border-radius: 8px;
  color: transparent;
  position: absolute;
  z-index: 0;
  top: 0;
}
.choicesContainer .choiceCard:hover .choiceButton.AIGeneration {
  background: transparent;
}
.actions {
  padding: 24px !important;
}
