@import 'src/less/colors';

.pill {
  display: inline-flex;
  padding: 4px;
  padding-left: 8px;
  margin: 0 2px;
  gap: 4px;

  background: none;
  background-color: @PrimaryCobalt05;
  border: 1px solid @PrimaryCobalt05;
  border-radius: 20px;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      border-color: @SecondaryBlue;
    }
  }

  &.selected {
    background-color: @SecondarySkyBlue20;
    border-color: @SecondaryBlue;
  }

  .name {
    user-select: none;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    font-family: Graphik;
    font-weight: 400;
    color: @PrimaryCobalt;
  }

  &:disabled:not(.selected) {
    .name {
      color: @PrimaryCobalt40;
    }
  }
}
