/*
 * colors.less
 */
.header {
  margin-bottom: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #1f2e77;
}
.contactCard {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  background-color: #f1f6ff;
  border-radius: 8px;
}
.contactCard .avatar {
  width: 48px;
}
@media (max-width: 1400px) {
  .contactCard .avatar {
    width: 32px;
  }
}
.contactCard .contactInfo {
  flex: 1;
}
.contactCard .contactInfo .name {
  display: inline-block;
  margin-right: 4px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  color: #1f2e77;
}
.contactCard .contactInfo .title {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #7982ad;
}
@media (max-width: 1400px) {
  .contactCard .contactInfo .title {
    display: none;
  }
}
.contactCard .contactInfo .email {
  display: block;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: #7982ad;
  text-decoration: underline;
}
.contactCard .contactInfo .email:hover {
  color: #4c5892;
}
.contactCard .contactInfo .phoneNumber {
  display: block;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #7982ad;
}
.contactCard .contactInfo .phoneNumber:hover {
  color: #4c5892;
}
.contactCard .buttons {
  display: flex;
  gap: 8px;
}
