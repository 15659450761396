@import 'src/less/colors';

.message {
  font-size: 12px;
  line-height: 140%;
  color: @PrimaryCobalt;
  font-weight: 400;
  background-color: @SecondaryBlue05;
  border-radius: 8px;
  padding: 12px;
  white-space: pre-wrap;
  margin: 8px 0;
  word-break: break-word;
}

.editableBody {
  cursor: pointer;
}

.submit {
  display: block;
  width: 100%;
  height: 38px;
  background-color: @PositiveColor !important;

  &.disabled {
    background-color: @PositiveColor !important;
    filter: opacity(0.5);
  }
}

.field {
  display: flex;
  align-items: center;
  gap: 4px;
}

.label {
  width: 40px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  color: @PrimaryCobalt60;
}

.phoneNumber {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: @SecondaryBlue;
}

.markAsCompletedButton {
  button {
    border-radius: 8px !important;
  }
}
