/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.settingsContainer {
  display: flex;
  width: 100%;
}
.settingsContainer .connectContainer {
  display: flex;
  gap: 8px;
}
.settingsContainer .connectContainer input {
  border-radius: 8px;
}
.settingsContainer .linkedAccountsSection {
  flex-grow: 1;
}
.settingsContainer .linkedAccountsSection .loaderContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settingsContainer .linkedAccountsSection .settingsHeader {
  margin-bottom: 15px;
}
.settingsContainer .linkedAccountsSection .settingsHeader h1 {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
  flex-grow: 1;
}
.settingsContainer .linkedAccountsSection h2 {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 18px;
  color: #1f2e77;
  margin-bottom: 8px;
}
.settingsContainer .linkedAccountsSection h3 {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  font-size: 13px;
  color: #1d1e21;
  font-weight: 400;
}
.settingsContainer .linkedAccountsSection .separator {
  border-bottom: 1px solid #e5e5e5;
  height: 0px;
  width: 100%;
  background: #e5e5e5;
}
.settingsContainer .linkedAccountsSection .section {
  margin-bottom: 30px;
}
.settingsContainer .linkedAccountsSection .section.visible {
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 2px;
  padding: 24px 24px 0px 24px;
}
.settingsContainer .linkedAccountsSection .section .segment {
  background-color: #f1f6ff;
  color: #7982ad;
  font-weight: 500;
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.settingsContainer .linkedAccountsSection .section .description {
  color: #7982ad;
}
.settingsContainer .linkedAccountsSection .section .settingsPlaceholder {
  text-align: center;
  margin-bottom: 24px;
}
.settingsContainer .linkedAccountsSection .section .inputContainer {
  margin-bottom: 24px;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .inputLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .inputLabel .noEmphasis {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .previewLabel {
  color: #7982ad;
  font-size: 12px;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .inputDescription {
  font-size: 12px;
  color: #a5abc9;
  margin-bottom: 8px;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .inputDescription:last-child {
  margin-bottom: 0;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .input-emphasis {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #7982ad;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .input-error {
  font-size: 12px;
  color: #ff6059;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .input-error input {
  border-color: #ff6059;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .input-element.half-width {
  width: 50%;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .input-element .field.signature-field .content-edition-field {
  border: 1px solid #dae0f4;
  padding: 0.39285714em 1em;
  border-radius: 2px;
  font-size: 1em;
  box-shadow: none;
  outline: none;
  min-height: 80px;
  overflow: auto;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .input-element .field.signature-field .content-edition-field:focus {
  border-color: #4864c9;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .accordion .content {
  padding-bottom: 0px;
}
.settingsContainer .linkedAccountsSection .section .inputContainer .accordion .title:not(.active) {
  padding-bottom: 0px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row.align-right {
  justify-content: flex-end;
}
.settingsContainer .linkedAccountsSection .section .inputs-row.align-right .input-container.half-width:first-child:last-child {
  padding-right: 0px;
  padding-left: 12px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row:last-child {
  margin-bottom: 0px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row .input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 0;
  padding-right: 12px;
  padding-left: 12px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row .input-container.half-width {
  width: 50%;
}
.settingsContainer .linkedAccountsSection .section .inputs-row .input-container:first-child {
  padding-left: 0px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row .input-container:last-child {
  padding-right: 0px;
}
.settingsContainer .linkedAccountsSection .section .inputs-row .input-container.half-width:first-child:last-child {
  padding-right: 12px;
}
.settingsContainer .linkedAccountsSection .settings-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
  gap: 8px;
}
.settingsContainer .linkedAccountsSection .settings-row .input {
  flex-grow: 1;
}
.settingsContainer .linkedAccountsSection .settings-row .ui.button.primary {
  margin-left: 10px;
}
.settingsContainer .linkedAccountsSection .settings-header h1 {
  display: inline;
}
.settingsContainer .linkedAccountsSection .settings-header .pill-message {
  margin-left: 10px;
  vertical-align: top;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: visible;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit button.save-modifications {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #ffffff;
  font-family: Graphik;
  color: #ffffff;
  background-color: #4864c9;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  margin-bottom: 8px;
  line-height: 20px;
  padding: 5px 15px;
  border-radius: 4px;
  transition: 150ms;
  font-size: 14px;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit button.save-modifications.disabled {
  cursor: default;
  background: #dfdfe6;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit button.save-modifications:active {
  background: #1f2e77;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit .settings-submit-message {
  text-align: right;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit .settings-submit-message .just-saved {
  color: #4864c9;
  opacity: 0.8;
}
.settingsContainer .linkedAccountsSection .settings-header .settings-submit .settings-submit-message .error-message {
  font-size: 13px;
  color: #f86e7f;
}
.settingsContainer .messaging-modal-opener {
  color: #4864c9;
  cursor: pointer;
  font-weight: 500;
}
.settingsContainer .messaging-modal-opener:hover {
  text-decoration: underline;
}
