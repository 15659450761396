/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.ui.modal.offer-integration-workflows-modal .integration-link-container {
  margin-bottom: 1em;
}
.ui.modal.offer-integration-workflows-modal .workflow-container {
  margin-top: 2em;
  padding: 1em;
  border: solid 1px #dae0f4;
  border-radius: 2px;
}
.ui.modal.offer-integration-workflows-modal .workflow-container .workflow-form {
  display: flex;
  flex-direction: column;
}
.ui.modal.offer-integration-workflows-modal .workflow-container .workflow-button {
  display: flex;
  justify-content: flex-end;
}
.ui.modal.offer-integration-workflows-modal .workflow-container .workflow-button .ui.button {
  padding: 0px 0px 12px;
}
.ui.modal.offer-integration-workflows-modal .workflow-button .ui.button {
  color: #4864c9;
  background-color: white;
  font-size: 15px;
  padding: 2em 16px 0 0;
}
.ui.modal.offer-integration-workflows-modal .workflow-button .ui.button:hover {
  color: #1f2e77;
  text-decoration: underline;
}
