/*
 * colors.less
 */
.greenhouse-filters .filter-icon {
  margin-right: 8px;
}
.greenhouse-filters .filter-icon img {
  border-radius: 4px;
}
.greenhouse-filters .sub-title {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1f2e77;
  margin: 4px 0 12px 0;
}
.greenhouse-filters .label {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  margin: 16px 0 4px 0;
}
.greenhouse-filters .hs-button-group button.selected {
  border-left: 1px solid !important;
}
