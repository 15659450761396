/*
 * colors.less
 */
.callout {
  border: 1px solid #e2ebff;
  border-radius: 8px;
}
.callout.borderless {
  border: none;
}
.callout.primary {
  padding: 24px;
  background-color: #f1f6ff;
}
.callout:last-of-type {
  margin-bottom: 24px;
}
.title {
  color: #1f2e77;
  font-size: 16px;
  font-family: 'Gilroy', sans-serif;
  margin: 16px 0px 16px 0px !important;
}
.calloutInfoLine {
  display: grid;
  grid-template-columns: 15px 0.3fr 0.7fr;
  font-size: 12px;
  font-family: Graphik, sans-sefir;
  font-weight: 500;
}
.calloutInfoLine:not(:last-child) {
  margin-bottom: 10px;
}
.calloutInfoLine .calloutInfoLineIcon {
  font-size: 1rem;
  padding-top: 3px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
}
.calloutInfoLine .calloutInfoLineIcon svg,
.calloutInfoLine .calloutInfoLineIcon svg *,
.calloutInfoLine .calloutInfoLineIcon i,
.calloutInfoLine .calloutInfoLineIcon i * {
  fill: #a5abc9;
  color: #a5abc9;
  height: 14px;
}
.calloutInfoLine .calloutInfoLineTitle {
  margin-left: 10px;
  color: #7982ad;
}
.calloutInfoLine .calloutInfoLineInfo {
  color: #1f2e77;
}
