/*
 * colors.less
 */
.missionSelectorContainer {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.missionSelectorContainer label {
  font-size: 14px;
  color: #4c5892;
  font-family: Graphik;
}
.missionSelectorContainer .tooltip {
  color: #a5abc9;
}
.missionSelectorContainer .selector {
  z-index: 2;
}
