/*
 * colors.less
 */
.job-posting-creator {
  margin-bottom: 48px;
}
.job-posting-creator .job-posting-header {
  margin-bottom: 36px;
}
.job-posting-creator .job-posting-header:not(.editing) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.job-posting-creator .buttons-container {
  display: flex;
  justify-content: flex-end;
}
.job-posting-creator .organization-logo {
  margin-bottom: 24px;
}
