/*
 * colors.less
 */
.profile-relevant-jobs {
  margin-top: 36px;
}
.profile-relevant-jobs.loading {
  text-align: center;
  margin: 48px 0;
}
.profile-relevant-jobs.empty {
  text-align: center;
}
.profile-relevant-jobs .job {
  margin: 16px 0;
  padding: 16px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #1f2e770f;
}
.profile-relevant-jobs .job:first-child {
  margin-top: 12px;
}
.profile-relevant-jobs .job:last-child {
  margin-bottom: 12px;
}
.profile-relevant-jobs .job .job-title {
  display: flex;
  flex-grow: 1;
}
.profile-relevant-jobs .job .job-title a {
  color: #4864c9;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 14px;
  margin-right: 0.5em;
}
.profile-relevant-jobs .job .job-title .add-button {
  padding: 5px 12px;
  margin: -6px -8px 8px 0;
}
.profile-relevant-jobs .job .job-title .add-button i {
  margin-right: 4px;
  font-weight: 400;
  font-size: 14px;
}
.profile-relevant-jobs .job .criteria-and-score {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}
.profile-relevant-jobs .job .criteria-and-score .job-score {
  margin-left: 24px;
  margin-top: 12px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.profile-relevant-jobs .job .criteria-and-score .job-score .text {
  margin-top: 8px;
  color: #a5abc9;
  font-size: 12px;
}
.profile-relevant-jobs .job .criteria-tags {
  margin-top: 12px;
}
.profile-relevant-jobs .job .criteria-tags h5 {
  font-weight: 400;
  font-size: 12px;
  color: #a5abc9;
}
