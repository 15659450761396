/*
 * colors.less
 */
.switch {
  display: flex;
  padding: 2px;
  border-radius: 4px;
  background-color: #dae0f4;
}
.option {
  flex: 1;
  border-radius: 4px;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #7982ad;
}
.option.selected {
  box-shadow: 0px 2px 8px 0px #1f2e770f;
  background-color: white;
  color: #1f2e77;
}
.option:not(.selected) {
  cursor: pointer;
}
