/*
 * colors.less
 */
.actionPanel {
  width: fit-content;
  overflow: hidden;
}
.actionPanelItem {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #1f2e77;
}
.actionPanelItem span {
  color: #a5abc9;
}
.actionPanelItem.selected {
  background: #f6f7fc;
}
.triggerContent svg {
  margin-left: 6px;
}
.dropdownContent {
  z-index: 10;
}
.ascending {
  transform: rotate(180deg);
}
.inactive {
  opacity: 0.2;
}
