/*
 * colors.less
 */
.oauth-signin-button {
  width: 240px;
  height: 40px;
  padding: 11px 8px;
  cursor: pointer;
  border: 1px solid #dfdfe6;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1f2e77;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}
.oauth-signin-button .provider-logo {
  width: 18px;
  height: 18px;
  margin-right: 20px;
  margin-bottom: 4px;
}
.oauth-signin-button:hover {
  transition: border 400ms;
  border: 1px solid #1f2e77;
}
.oauth-signin-button:disabled {
  cursor: wait;
}
