.logoContainer {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.logoContainer .animatedLogo {
  margin: -4px;
}
.logoContainer.classic {
  margin-left: 22px;
  margin-right: -2px;
  width: 130px;
}
