/*
 * colors.less
 */
.details-section {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: Graphik, sans-serif;
}
.details-section:not(:first-child) {
  margin-top: 16px;
}
.details-section:not(:last-child) {
  margin-bottom: 16px;
}
.details-section .section-header {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #a5abc9;
}
.details-section .title {
  color: #a5abc9;
}
.details-section .value {
  color: #3450b5;
}
.details-section .score {
  color: #3450b5;
}
.details-section .labels {
  margin-top: 4px;
}
