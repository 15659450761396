/*
 * colors.less
 */
.profile-selector {
  height: 100%;
}
.profile-selector.reduced {
  border-right: 1px solid #dddddd;
}
.profile-selector .profile-list {
  padding-bottom: 40px;
}
.profile-selector .see-more-profiles-section-container {
  margin-top: 10px;
}
.profile-selector .see-more-profiles-section-container .see-more-profiles-section {
  display: flex;
  justify-content: center;
}
.profile-selector .see-more-profiles-section-container .see-more-profiles-section .see-more-profiles-button {
  background: none;
  opacity: 0.3;
}
.profile-selector .see-more-profiles-section-container .see-more-profiles-section .see-more-profiles-button:hover {
  color: #4864c9;
  opacity: 1;
}
.profile-selector.full .profile-card-compact > .content {
  display: flex;
}
.profile-selector.full .selected {
  background-color: #f1f6ff;
}
.profile-selector.full .selected .bubble-star {
  border-color: #e2ebff;
}
.profile-selector.reduced .not-selected img {
  opacity: 0.6;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
.profile-selector .category-header {
  opacity: 0.5;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  padding: 10px 0px;
  white-space: nowrap;
}
.profile-selector:not(.full) .category-header .count {
  display: none;
}
.profile-card-compact {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 60px;
  padding: 8px 16px;
  transition: background-color 0.2s ease, height 0.3s ease;
}
.profile-card-compact.animate-exit,
.profile-card-compact.animate-enter {
  height: 0;
}
.profile-card-compact.animate-enter-active {
  overflow: hidden;
}
.profile-card-compact.animate-enter-active {
  visibility: hidden;
}
.profile-card-compact:hover {
  background-color: #f8faff;
}
.profile-card-compact:hover .bubble-star {
  border-color: #f8faff;
}
.profile-card-compact:hover .profile-source-indicator {
  border-color: #f8faff;
}
.profile-card-compact.reduced > .content {
  width: 0;
}
.profile-card-compact > .content {
  height: 40px;
  margin: 10px;
  flex: 1;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
}
.profile-card-compact > .content > .name,
.profile-card-compact > .content > .description {
  white-space: nowrap;
}
.profile-card-compact > .content > .name {
  height: 22px;
  width: 100%;
  display: flex;
  align-items: center;
}
.profile-card-compact > .content > .name .inner-name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  color: #1f2e77;
  line-height: 22px;
}
.profile-card-compact > .content > .name .inner-name.unbiased {
  text-shadow: 0 0 10px #4864c9;
  color: transparent;
}
.profile-card-compact > .content > .name .inner-name.unbiased.unbiased:hover {
  text-shadow: 0 0 0px #000;
  color: #1f2e77;
}
.profile-card-compact > .content > .description {
  height: 18px;
  opacity: 0.5;
  font-size: 12px;
  color: #1d1e21;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: hidden;
}
.profile-card-compact.empty .name {
  background: linear-gradient(to right, #dddddd, #ebebeb);
}
.profile-card-compact.empty .description {
  background: linear-gradient(to right, #ebebeb, #f5f5f5);
}
.profile-card-compact .companyGlobalInterestIcon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  width: 14px;
  height: 14px;
  font-size: 8px;
  background-color: #3450b5;
  border-radius: 100%;
  border: 1px solid white;
  color: white;
}
.profile-card-compact .profileSelectorPowerHoursIcon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 13px;
  height: 13px;
  font-size: 8px;
  background-color: #28ca42;
  border-radius: 100%;
  border: 1px solid white;
  color: white;
}
.profile-bubble {
  position: relative;
  box-sizing: content-box;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 40px / 2 + 2px;
}
.profile-bubble .bubble-star {
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid white;
  line-height: 12px;
  width: 12px;
  font-size: 10px;
  text-align: center;
  border-radius: 12px;
  background: #ffbd2e;
  color: white;
}
.profile-bubble img {
  width: 100%;
  height: 100%;
}
.profile-bubble .profile-source-indicator {
  position: absolute;
  top: 28px;
  left: 28px;
  background-color: #709dff;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  border: 1px solid white;
  box-sizing: border-box;
  color: white;
}
.profile-bubble .profile-source-indicator i {
  position: absolute;
  left: 1.5px;
  font-size: 8px;
  line-height: 14px;
}
.profile-bubble .profile-source-indicator.career-page {
  background-color: #28ca42;
}
.empty-1 .profile-bubble {
  opacity: 0.8;
}
.empty-1 > .content {
  opacity: 1;
}
.empty-2 .profile-bubble {
  opacity: 0.4;
}
.empty-2 > .content {
  opacity: 0.6;
}
.empty-3 .profile-bubble {
  opacity: 0.15;
}
.empty-3 > .content {
  opacity: 0.35;
}
.empty-4 .profile-bubble {
  opacity: 0.05;
}
.empty-4 > .content {
  opacity: 0.25;
}
