/*
 * colors.less
 */
.panel {
  padding: 12px;
  background-color: #f8faff;
  border-radius: inherit;
}
.panel .checkboxContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  color: #1f2e77;
  font-family: Graphik, sans-serif;
  font-size: 14px;
}
.select {
  margin: 8px 0;
}
.button {
  width: 100%;
  margin-top: 8px;
}
.selectAllContainer {
  display: block;
  background-color: #fff;
  padding: 8px 12px 2px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #4864c9;
  border-top: 1px solid #dae0f4;
}
.selectAllContainer .selectAllButton {
  cursor: pointer;
}
