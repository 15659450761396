.cell {
  width: 150px;
  text-align: center;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
