.search-bar {
  height: 50px;
  align-items: center;
  margin: 15px 10px;
  transition: height margin-bottom 0.2s ease;
}
.search-bar.reduced input {
  display: none;
}
.search-bar.reduced > .icon > i.icon {
  width: 100%;
}
.search-bar > .input {
  width: 100%;
  height: 100%;
  display: flex;
}
.search-bar .icon::before {
  font-size: 18px !important;
}
