@import 'src/less/colors';

.category {
  white-space: nowrap;

  .box {
    display: inline-block;
    vertical-align: middle;
    height: 1em;
    width: 1em;
    border-radius: 3px;
    margin-right: 8px;
  }

  .label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    font-family: Graphik;
    font-weight: 500;
    color: @PrimaryCobalt;
  }

  &.condensed {
    .box {
      margin-right: 4px;
    }
  }
}
