/*
 * colors.less
 */
.resizable-label-list {
  position: relative;
  margin: -4px;
  flex-grow: 1;
}
.resizable-label-list .skill-tag {
  padding: 4px 10px !important;
  border-radius: 16px;
  font-size: 13px;
  line-height: 14px;
  font-family: Graphik, sans-serif;
  font-weight: normal;
  margin: 4px;
  background-color: #eeeff5;
}
.resizable-label-list .skill-tag:first-child,
.resizable-label-list .skill-tag:last-child {
  margin: 4px;
}
.resizable-label-list .skill-tag.important {
  background: #5c89eb;
  font-weight: 600;
  color: white;
}
.resizable-label-list .skill-tag.medium {
  background: #8db1ff;
  font-weight: 600;
  color: white;
}
.resizable-label-list-popup.ui.popup {
  max-width: 30vw;
  padding: 10px 8px;
}
.measure-text-temp {
  visibility: hidden;
}
