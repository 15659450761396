/*
 * colors.less
 */
.section {
  margin: 24px 0;
}
.section .title {
  color: #1f2e77;
}
.revealAnalyticsGraph {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  margin-top: 16px;
}
.revealAnalyticsGraph .loader {
  margin: 100px auto;
}
