@import 'src/less/colors';

.label {
  margin: 0;
  padding: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: @PrimaryCobalt60;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
