@import 'src/less/colors.less';

.inputContainer {
  margin-bottom: 24px;

  .inputTitle {
    //styleName: Body 14/Medium;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt;
    margin-bottom: 4px;
  }

  .inputSubtitle {
    //styleName: Body 12/Regular 400;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt60;
    margin-bottom: 4px;
  }
}

.modalActions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
