@import 'src/less/colors';

.stickyNoteContainer {
  width: 100%;
  overflow: hidden;
}

.stickyNote {
  border: 1px solid @Yellow40;
  border-radius: 8px;
  min-width: 0px;
  width: fit-content;
  display: flex;
  font-size: 12px;
  padding: 0px 10px;
  color: @PrimaryCobalt60;
  cursor: pointer;
  max-width: 100%;

  &.editing {
    border-color: @Yellow;
  }

  &.readOnly {
    background-color: @Yellow05;
    margin-top: 2px;
  }

  .stickyNoteText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.editIcon {
  margin-right: 6px;
}

.stickyNoteInput,
.stickyNoteInput input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent !important;
  display: flex !important;
  color: @PrimaryCobalt60 !important;
}

.stickyNoteInput {
  padding: 0px 0px !important;
}

.stickyNoteInput input {
  padding: 0 !important;
}

.stickyNoteCounter {
  margin-left: 10px;
  cursor: default;
}
