/*
 * colors.less
 */
.row {
  display: flex;
  align-items: center;
  padding: 18px 24px;
  background-color: #f8faff;
  border-top: 1px solid #dae0f4;
}
.row .descriptor {
  flex: 1;
  min-width: 0;
}
.row .button {
  text-align: center;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.row .button:not(:disabled) {
  cursor: pointer;
}
.row .button:not(:disabled):hover {
  text-decoration: underline;
}
.row .column {
  width: 150px;
}
