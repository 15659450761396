/*
 * colors.less
 */
.logoCircle {
  display: flex;
  width: 84px;
  height: 84px;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 2px 16px 0 #1f2e7726;
  align-items: center;
  justify-content: center;
}
.logoCircle .logo {
  width: 48px;
}
