/*
 * colors.less
 */
.modeSelector {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  padding-top: 0;
  gap: 8px;
}
.modeSelector .label {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  color: #7982ad;
}
.modeSelector .dropdown:global(.ui.dropdown) {
  font-family: Graphik;
  width: 160px;
  min-height: auto;
  padding: 8px;
  border-radius: 8px;
}
.modeSelector .dropdown:global(.ui.dropdown) :global(.text) {
  font-size: 12px;
  color: #1f2e77 !important;
}
.modeSelector .dropdown:global(.ui.dropdown) :global(.icon) {
  padding: 8px;
}
.modeSelector .dropdown:global(.ui.dropdown) :global(.menu) {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.modeSelector .dropdown:global(.ui.dropdown) :global(.menu) :global(.item) {
  padding: 8px !important;
  font-size: 12px;
  color: #1f2e77;
  font-weight: 500 !important;
}
