/*
 * colors.less
 */
.watch-collect-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff1f3;
  padding: 12px 16px;
  margin-bottom: 24px;
  border-radius: 2px;
}
.watch-collect-banner .banner-icon {
  background-color: #f86e7f;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 12px;
}
.watch-collect-banner .banner-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.watch-collect-banner .banner-main-text {
  color: #f86e7f;
  margin-bottom: 4px;
}
.watch-collect-banner .banner-sub-text {
  color: #1f2e77;
}
.watch-collect-banner .banner-button {
  padding: 6px 16px;
  line-height: 17px;
}
