@import 'src/less/colors';

.offer {
  width: 100%;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid @SecondaryBlue20;
  cursor: pointer;

  .favoriteButton {
    font-size: 24px;
    color: @PrimaryCobalt20;

    &:not(.own) {
      cursor: pointer;
    }

    &.own {
      color: @Yellow;
      .star {
        fill: @Yellow;
      }
    }

    &.favorite {
      color: @SecondaryBlue;
      .star {
        fill: @SecondaryBlue;
      }
    }
  }

  .info {
    flex: 1;
    .title {
      margin-bottom: 8px;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      text-align: left;
      color: @PrimaryCobalt;
    }

    .labels {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .extraColumn {
    width: 80px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;

    .count {
      cursor: pointer;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      color: @PrimaryCobalt40;

      &:hover {
        text-decoration: underline;
      }
    }

    &:is(button) {
      cursor: pointer;
      &:hover {
        .count {
          text-decoration: underline;
        }
      }
    }

    &.notEmpty {
      .count {
        color: @SecondaryBlue;
      }
    }
  }

  .menu {
    padding: 0;
    text-align: left;
    width: 32px;
  }
}

.hired {
  cursor: pointer;
  padding: 2px 8px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: @SecondaryBlue;
  background-color: @SecondarySkyBlue20;
  border-radius: 24px;

  &:hover {
    text-decoration: underline;
  }
}
