/*
 * colors.less
 */
.trigger {
  margin: 0;
  padding: 0;
  font-family: Graphik;
  color: #4864c9;
  font-weight: 500;
  background-color: transparent;
  cursor: pointer;
}
.trigger:hover {
  text-decoration: underline;
}
.trigger .arrow {
  margin-right: 0.25em;
  transition: transform 150ms ease;
}
.trigger .arrow.folded {
  transform: rotate(-0.25turn);
}
.content {
  margin-top: 4px;
}
