/*
 * colors.less
 */
.timeSelector {
  margin-left: 5px;
  border-color: #dae0f4 !important;
  border-radius: 2px;
  height: 20px !important;
}
.timeSelector > svg {
  scale: 0.8;
  color: #7982ad;
}
.timeSelector .currentTime {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.timeSelector .clearTime {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}
.timeSelector .clearTime:hover {
  background-color: #d2d5e4;
}
.timeSelector .clearTime svg {
  scale: 2;
}
