@import 'src/less/colors';

.header {
  margin-bottom: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: @PrimaryCobalt;
}

.contactCard {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  background-color: @SecondarySkyBlue10;
  border-radius: 8px;

  .avatar {
    width: 48px;

    @media (max-width: 1400px) {
      width: 32px;
    }
  }

  .contactInfo {
    flex: 1;

    .name {
      display: inline-block;
      margin-right: 4px;
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0px;
      color: @PrimaryCobalt;
    }

    .title {
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      color: @PrimaryCobalt60;

      @media (max-width: 1400px) {
        display: none;
      }
    }

    .email {
      display: block;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      color: @PrimaryCobalt60;
      text-decoration: underline;

      &:hover {
        color: @PrimaryCobalt80;
      }
    }

    .phoneNumber {
      display: block;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      color: @PrimaryCobalt60;

      &:hover {
        color: @PrimaryCobalt80;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }
}
