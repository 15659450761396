/*
 * colors.less
 */
.term {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 4px 0;
}
.text {
  color: #7982ad;
  font-size: 16px;
  font-family: Graphik;
  line-height: 140%;
}
.check {
  margin: 2px;
  display: inline-flex;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #a9c4ff;
  color: white;
  font-size: 16px;
}
