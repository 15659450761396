/*
 * colors.less
 */
.campaignFunnels {
  margin: 20px 0;
  background: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.campaignFunnels .top {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 11px 0 24px;
}
.campaignFunnels .top h3 {
  color: #1f2e77;
  margin: 0;
}
.campaignFunnels .top .topRight {
  display: inline-flex;
  align-items: center;
  gap: 16px;
}
.campaignFunnels .tableHeader {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 18px 24px;
  background-color: #f8faff;
  color: #7982ad;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  border-bottom: 1px solid #dae0f4;
}
.campaignFunnels .tableHeader .name {
  width: 300px;
}
.campaignFunnels .tableHeader .headerStage {
  flex: 1;
}
.campaignFunnels .tableHeader .headerStage.wider {
  flex: 2;
}
.campaignFunnels .tableHeader .headerStage.centered {
  text-align: center;
}
.nbUnsubscribe {
  justify-content: center;
  border-left: 1px solid #dae0f4;
}
.nbUnsubscribe .count {
  text-align: center;
}
