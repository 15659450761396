/*
 * colors.less
 */
.section {
  margin: 16px 0;
}
.freeTrialMessage {
  color: #7982ad;
  margin: 16px 0;
}
