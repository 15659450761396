/*
 * colors.less
 */
.toggleContainer {
  margin-bottom: 12px;
}
.toggleContainer.small {
  height: 12px;
}
.toggleContainer.medium {
  height: 16px;
}
.toggleContainer.large {
  height: 22px;
}
:global(.ui.toggle.checkbox).toggle {
  min-height: 0px;
}
:global(.ui.toggle.checkbox).toggle.small {
  height: 12px;
}
:global(.ui.toggle.checkbox).toggle.small label {
  height: 12px;
  line-height: 12px;
}
:global(.ui.toggle.checkbox).toggle.small label:before {
  width: 21px;
  height: 12px;
}
:global(.ui.toggle.checkbox).toggle.small label:after {
  height: 10px;
  width: 10px;
  margin-top: 1px;
  margin-left: 1px;
}
:global(.ui.toggle.checkbox).toggle.small input:checked ~ label {
  color: #1f2e77 !important;
}
:global(.ui.toggle.checkbox).toggle.small input:checked ~ label:after {
  left: 10px !important;
}
:global(.ui.toggle.checkbox).toggle.medium {
  height: 16px;
}
:global(.ui.toggle.checkbox).toggle.medium label {
  height: 16px;
  line-height: 16px;
}
:global(.ui.toggle.checkbox).toggle.medium label:before {
  width: 28px;
  height: 16px;
}
:global(.ui.toggle.checkbox).toggle.medium label:after {
  height: 12px;
  width: 12px;
  margin-top: 2px;
  margin-left: 3px;
}
:global(.ui.toggle.checkbox).toggle.medium input:checked ~ label {
  color: #1f2e77 !important;
}
:global(.ui.toggle.checkbox).toggle.medium input:checked ~ label:after {
  left: 11px !important;
}
:global(.ui.toggle.checkbox).toggle.large {
  height: 22px;
}
:global(.ui.toggle.checkbox).toggle.large label {
  height: 22px;
  line-height: 22px;
}
:global(.ui.toggle.checkbox).toggle.large label:before {
  width: 35px;
  height: 20px;
}
:global(.ui.toggle.checkbox).toggle.large label:after {
  height: 16px;
  width: 16px;
  margin-top: 2px;
  margin-left: 3px;
}
:global(.ui.toggle.checkbox).toggle.large input:checked ~ label {
  color: #1f2e77 !important;
}
:global(.ui.toggle.checkbox).toggle.large input:checked ~ label:after {
  left: 14px !important;
}
:global(.ui.toggle.checkbox).toggle label {
  min-height: 0px;
  padding-left: 36px;
  padding-top: 0px;
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
}
:global(.ui.toggle.checkbox).toggle label:before {
  background: #b6c1e9 !important;
}
:global(.ui.toggle.checkbox).toggle label:after {
  border: none;
  box-shadow: none;
  background: white;
  box-shadow: none !important;
}
