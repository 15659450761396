@import 'src/less/colors';

.experienceSlider {
  height: 4px;
  border-radius: 2px;
  background: @SecondaryBlue20;
}

.labelsContainer {
  position: relative;
  margin: auto;
}

.labelMin,
.labelMax {
  position: absolute;
  color: @SecondaryBlue;
  top: -28px;
  font-weight: 600;
  font-family: Graphik, sans-serif;
  font-size: 0.8rem;
  white-space: nowrap;
  transform: translateX(-50%);
}

.experienceSlider :global(.range-slider__thumb)  {
  background: white;
  border: 2px solid @SecondaryBlue;
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.experienceSlider :global(.range-slider__range) {
  background: @SecondaryBlue;
}
