/*
 * colors.less
 */
.new-activity-modal {
  position: fixed;
  right: 20px;
  overflow: auto;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  padding: 24px;
  width: calc(calc(0.4325 * calc(100% - 174px)) - 40px);
}
.new-activity-modal:not(.plugin) {
  max-height: calc(100vh - 212px);
}
.new-activity-modal .log-activity-form,
.new-activity-modal h5,
.new-activity-modal h4,
.new-activity-modal p {
  color: #1f2e77;
}
.new-activity-modal h5 {
  margin-bottom: 4px;
  margin-top: 16px;
}
.new-activity-modal p {
  margin: 10px 0px;
}
.new-activity-modal b {
  font-weight: 500;
}
.new-activity-modal .log-activity-form {
  margin: 0px;
  margin-top: 16px;
}
.new-activity-modal .log-activity-form .input-label {
  color: #1f2e77;
}
.new-activity-modal .log-activity-form .rds-button-default.ui.button {
  border: 1px solid #dae0f4 !important;
  background-color: white !important;
}
.new-activity-modal .log-activity-form .rds-button-default.ui.button:hover {
  background-color: #edf0fa !important;
}
.new-activity-modal .log-activity-form .rds-button-selected.ui.button {
  border: 1px solid #4864c9 !important;
  background-color: #f1f6ff !important;
}
.new-activity-modal .activity-from-container,
.new-activity-modal .activity-types {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 4px;
}
.new-activity-modal .activity-from-container .activity-from,
.new-activity-modal .activity-types .activity-from,
.new-activity-modal .activity-from-container .activity-type,
.new-activity-modal .activity-types .activity-type {
  flex: 1 1 0px;
  text-align: center;
  border: 1px solid #dae0f4;
  color: #4c5892;
  border-radius: 2px;
  margin-bottom: 10px;
}
.new-activity-modal .activity-from-container .activity-from:hover,
.new-activity-modal .activity-types .activity-from:hover,
.new-activity-modal .activity-from-container .activity-type:hover,
.new-activity-modal .activity-types .activity-type:hover {
  background-color: #edf0fa;
}
.new-activity-modal .activity-from-container .activity-from.active,
.new-activity-modal .activity-types .activity-from.active,
.new-activity-modal .activity-from-container .activity-type.active,
.new-activity-modal .activity-types .activity-type.active {
  border: 1px solid #4864c9;
  background-color: #f1f6ff;
}
.new-activity-modal .activity-from-container .activity-from,
.new-activity-modal .activity-types .activity-from {
  padding-top: 8px;
  padding-bottom: 8px;
}
.new-activity-modal .activity-from-container .activity-from .avatar,
.new-activity-modal .activity-types .activity-from .avatar {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.new-activity-modal .activity-from-container .activity-type,
.new-activity-modal .activity-types .activity-type {
  padding-top: 4px;
  padding-bottom: 4px;
}
.new-activity-modal .activity-note,
.new-activity-modal .editor-container {
  border: 1px solid #dae0f4 !important;
  border-radius: 4px;
}
.new-activity-modal .activity-note.focus,
.new-activity-modal .editor-container.focus {
  border: 1px solid #5c89eb !important;
}
.new-activity-modal .activity-footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.new-activity-modal .activity-footer button {
  margin-left: auto;
  margin-right: 0;
}
.new-activity-modal.plugin {
  width: unset;
  position: unset;
  margin: 0px 10px;
}
