@import 'src/less/colors';

.modeSelector {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  padding-top: 0;
  gap: 8px;

  .label {
    font-family: Graphik;
    font-size: 12px;
    font-weight: 500;
    color: @PrimaryCobalt60;
  }

  .dropdown:global(.ui.dropdown) {
    font-family: Graphik;
    width: 160px;
    min-height: auto;
    padding: 8px;
    border-radius: 8px;

    :global(.text) {
      font-size: 12px;
      color: @PrimaryCobalt !important;
    }

    :global(.icon) {
      padding: 8px;
    }

    :global(.menu) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      :global(.item) {
        padding: 8px !important;
        font-size: 12px;
        color: @PrimaryCobalt;
        font-weight: 500 !important;
      }
    }
  }
}
