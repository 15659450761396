@import 'src/less/colors';

.emailSkip {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: fit-content(200px) auto 1fr;
  gap: 8px;
  height: 20px;
}

.emailPreviewLabel {
  font-family: Graphik;
  color: @PrimaryCobalt;
  font-weight: 500;
}

.emailPreview {
  font-size: 13px;
  font-family: Graphik;
}

.emailSubject,
.emailBody {
  width: 100%;
  color: @PrimaryCobalt;
  font-weight: 400;
  font-family: Graphik;
}

.emailBodyTextarea {
  width: 100%;
  min-height: fit-content;
  height: 150px;
  max-height: 200px;
  resize: none;
}

.emailPreview {
  padding: 0px 4px;
  min-width: 500px;
}

// .emailSkipActions {
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   justify-content: space-between;
// }

.emailSkipOptions {
  margin-top: 2px;
}

.templateAndPreview {
  padding-left: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;

  > span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: @PrimaryCobalt;
    font-family: Graphik;
  }

  .templateSelectorTrigger {
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: @PrimaryCobalt60;
    font-family: Graphik;
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: @PrimaryCobalt;
    }
  }
}

.templateDropdown {
  overflow: hidden;
  width: inherit;
}

.documentShow {
  cursor: pointer;
  flex-shrink: 0;
}

.customizeEmail {
  color: @PrimaryCobalt;
  font-size: 12px;
  font-family: Graphik;
  height: 20px;
  line-height: 20px;
  text-align: right;

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.skipButtons {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  > button {
    flex-grow: 1;
  }
}

.divider {
  margin: 1.2rem 0;
  border-top: 1px solid @SecondaryBlue20;
  width: 80%;
  margin-left: 10%;
}

.disabledButton {
  background-color: @SecondaryBlue40 !important;
  color: white;
  cursor: default !important;
}
