/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.dashboard {
  width: 100%;
  padding: 48px 70px 100px 70px;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
.dashboard h1 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  color: #709dff;
  margin-bottom: 24px;
}
.dashboard .dashboard-columns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media screen and (min-width: 1350px) {
  .dashboard .dashboard-columns {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.dashboard .column-1 {
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1350px) {
  .dashboard .column-1 {
    margin-right: 42px;
    max-width: calc(100% - 362px);
  }
}
@media screen and (min-width: 1445px) {
  .dashboard .column-1 {
    max-width: calc(100% - 442px);
  }
}
.dashboard .dashboard-power-hour-banner {
  margin-bottom: 16px;
}
.dashboard .column-2 {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1350px) {
  .dashboard .column-2 {
    max-width: 340px;
  }
}
@media screen and (min-width: 1445px) {
  .dashboard .column-2 {
    max-width: 400px;
  }
}
.dashboard .dashboard-section {
  width: 100%;
  background-color: white;
  padding: 27px 24px;
  margin-bottom: 42px;
  border-radius: 2px;
  border: 1px solid #dae0f4;
}
.dashboard .dashboard-section .header {
  display: flex;
}
.dashboard .dashboard-section .header h1 {
  flex-grow: 1;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.dashboard .dashboard-section .header h1 a {
  color: #709dff;
}
.dashboard .dashboard-section .header h1 a:hover {
  text-decoration: underline;
}
.dashboard .dashboard-section .see-all-link-container {
  text-align: center;
  font-size: 12px;
  color: #767689;
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
}
.dashboard .dashboard-section .see-all-link-container a {
  text-decoration: underline;
  font-weight: 600;
}
.dashboard .dashboard-section .see-all-link-container a:hover {
  color: #709dff;
}
.dashboard .dashboard-section .offers-table-placeholder {
  height: 300px;
  display: flex;
  align-items: center;
}
.dashboard .dashboard-section .no-items-container {
  height: unset;
}
.dashboard .dashboard-section .no-items-container .no-items {
  margin-top: 24px;
  color: #a5abc9;
}
.dashboard .offers table.table.ui {
  border: none;
  margin-top: 0;
}
.dashboard .offers table.table.ui thead th,
.dashboard .offers table.table.ui tbody td {
  padding: 16px 10px 16px 0;
}
.dashboard .offers table.table.ui tbody tr td {
  text-align: left;
}
.dashboard .offers table.table.ui thead,
.dashboard .offers table.table.ui thead tr,
.dashboard .offers table.table.ui thead tr th {
  border: none;
}
.dashboard .offers table.table.ui tbody,
.dashboard .offers table.table.ui tbody tr {
  border: none;
}
.dashboard .offers table.table.ui tbody td {
  border-top: 1px solid #f3f4f8;
  border-bottom: none;
}
.dashboard .offers table.table.ui tbody td:first-child {
  border-left: none;
}
.dashboard .offers table.table.ui tbody td:last-child {
  border-right: none;
}
.dashboard .offers table.table.ui tbody tr:last-child td {
  border-bottom: none;
}
.dashboard .offers table.table.ui thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.dashboard .offers table.table.ui thead th:first-letter {
  text-transform: capitalize;
}
.dashboard .offers table.table.ui tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard .offers table.table.ui tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.dashboard .offers table.table.ui tbody > tr > td:first-child {
  max-width: 230px;
}
.dashboard .offers table.table.ui .offers-table-row {
  cursor: pointer;
}
.dashboard .offers table.table.ui .offers-table-row:hover {
  background: rgba(100, 100, 100, 0.01);
}
.dashboard .offers table.table.ui .offers-table-cell.pending {
  color: #4864c9;
}
.dashboard .offers table.table.ui .offers-table-cell.hired {
  color: #14b62e;
}
.dashboard .offers table.table.ui .offers-table-cell.skipped {
  color: #f66f81;
}
.dashboard .offers table.table.ui .offers-table-cell.not-available {
  color: #d2d5e4;
}
.dashboard .offers table.table.ui .offers-table-cell:hover {
  text-decoration: underline;
}
.dashboard .activity,
.dashboard .notifications {
  font-size: 13px;
  color: #4c5892;
}
.dashboard .activity .date,
.dashboard .notifications .date {
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 1px;
  opacity: 0.7;
  text-transform: uppercase;
}
.dashboard .activity .activity-table-placeholder,
.dashboard .notifications .activity-table-placeholder {
  height: 500px;
  display: flex;
  align-items: center;
}
.dashboard .activity .notifications-table-placeholder,
.dashboard .notifications .notifications-table-placeholder {
  height: 500px;
  display: flex;
  align-items: center;
}
.dashboard .activity .grouped-activities,
.dashboard .notifications .grouped-activities {
  margin-bottom: 2rem;
}
.dashboard .activity .text-ellipsis,
.dashboard .notifications .text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard .activity .profiles-cell,
.dashboard .notifications .profiles-cell {
  display: flex;
  align-items: center;
}
.dashboard .activity .profile-cell div,
.dashboard .notifications .profile-cell div {
  vertical-align: middle;
  display: inline;
}
.dashboard .activity .profile-cell .mini-profile-bubble,
.dashboard .notifications .profile-cell .mini-profile-bubble {
  display: inline-block;
  margin-right: 0.3em;
  margin-left: 0;
}
.dashboard .activity .profile-cell a:hover,
.dashboard .notifications .profile-cell a:hover {
  text-decoration: underline;
}
.dashboard .activity .table tbody tr,
.dashboard .notifications .table tbody tr {
  display: flex;
  align-items: center;
  border-bottom: solid 1px lightgray;
}
.dashboard .activity .table tbody tr:first-child,
.dashboard .notifications .table tbody tr:first-child {
  border-top: solid 1px lightgray;
}
.dashboard .activity .table tbody tr td,
.dashboard .notifications .table tbody tr td {
  border: none;
  color: #767689;
  line-height: 21px;
}
.dashboard .activity .table tbody tr td:first-child,
.dashboard .notifications .table tbody tr td:first-child {
  max-width: 12vw;
  padding-left: 0;
  flex: 1;
}
.dashboard .activity .table tbody tr td:first-child a,
.dashboard .notifications .table tbody tr td:first-child a {
  font-weight: 600;
  font-family: Gilroy;
  color: #1f2e77;
}
.dashboard .activity .table tbody tr td:first-child a:hover,
.dashboard .notifications .table tbody tr td:first-child a:hover {
  text-decoration: underline;
  color: #4864c9;
}
.dashboard .activity .table tbody tr td:nth-child(2),
.dashboard .notifications .table tbody tr td:nth-child(2) {
  flex: 2;
}
.dashboard .activity .table tbody tr td:last-child,
.dashboard .notifications .table tbody tr td:last-child {
  font-size: 11px;
  opacity: 0.7;
}
.dashboard .activity .table tbody tr td.dismiss-cell,
.dashboard .notifications .table tbody tr td.dismiss-cell {
  width: 60px;
  text-align: right;
}
.dashboard .activity .table tbody tr td.dismiss-cell i.delete.icon,
.dashboard .notifications .table tbody tr td.dismiss-cell i.delete.icon {
  opacity: 0.6;
  margin-right: 0px !important;
}
.dashboard .activity .ui.table td .image,
.dashboard .notifications .ui.table td .image {
  max-width: 25px;
}
.dashboard .get-started .completed-steps-progress {
  width: 40px;
}
.dashboard .get-started .ratio-container {
  color: #767689;
  font-size: 12px;
}
.dashboard .users table.table.ui {
  margin-top: 0;
  border: none;
}
.dashboard .users table.table.ui thead th,
.dashboard .users table.table.ui tbody td {
  padding: 16px 10px 16px 0;
}
.dashboard .users table.table.ui tbody tr td {
  text-align: left;
}
.dashboard .users table.table.ui thead,
.dashboard .users table.table.ui thead tr,
.dashboard .users table.table.ui thead tr th {
  border: none;
}
.dashboard .users table.table.ui tbody,
.dashboard .users table.table.ui tbody tr {
  border: none;
}
.dashboard .users table.table.ui tbody td {
  border-top: 1px solid #f3f4f8;
  border-bottom: none;
}
.dashboard .users table.table.ui tbody td:first-child {
  border-left: none;
}
.dashboard .users table.table.ui tbody td:last-child {
  border-right: none;
}
.dashboard .users table.table.ui tbody tr:last-child td {
  border-bottom: none;
}
.dashboard .users table.table.ui tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.dashboard .users table.table.ui tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard .users table.table.ui thead th {
  font-family: Graphik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #a5abc9;
}
.dashboard .users .users-table-placeholder {
  height: 300px;
  display: flex;
  align-items: center;
}
.ui.modal.add-user-modal {
  width: 600px;
}
.ui.modal.add-user-modal .close.icon {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: #000000;
  opacity: 0.2;
}
.ui.modal.add-user-modal .close.icon::before {
  font-family: verdana;
  content: '✕';
  font-weight: bold;
}
.ui.modal.add-user-modal .close.icon:hover {
  opacity: 0.4;
}
.ui.modal.add-user-modal .header {
  background: #f8f8f8;
  border-bottom: none;
  border-radius: 3px !important;
  padding-top: 25px;
  padding-bottom: 19px;
  padding-left: 60px;
  padding-right: 60px;
}
.ui.modal.add-user-modal .header h4 {
  margin-bottom: 4px;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 17px;
  color: #1f2e77;
  letter-spacing: -0.21px;
}
.ui.modal.add-user-modal .header h5 {
  margin: 0;
  font-family: Graphik;
  font-size: 13px;
  font-weight: normal;
  color: #767689;
}
.ui.modal.add-user-modal .content {
  position: relative;
  background: #ffffff;
  padding-top: 60px / 2;
  padding-bottom: 60px / 2;
  padding-left: 60px;
  padding-right: 60px;
  font-family: Graphik;
  font-size: 15px;
  color: #2b3d4f;
  text-align: left;
}
.ui.modal.add-user-modal .content:after {
  position: absolute;
  bottom: 0px;
  left: 60px;
  content: '';
  width: 480px;
  height: 1px;
  opacity: 0.5;
  background: #d6d6dc;
}
.ui.modal.add-user-modal .actions {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 60px;
  border: none;
  background: none;
}
.ui.modal.add-user-modal .actions .ui.button {
  margin-right: 0;
}
.ui.modal.add-user-modal .ui.input {
  width: 100%;
}
.ui.modal.add-user-modal .error {
  margin-top: 0.5em;
  color: #f86e7f;
}
.get-started-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}
.get-started-item .check-mark-icon {
  margin-left: 4px;
  height: 8px;
  color: #8e8e90;
  fill: #8e8e90;
}
.get-started-item .check-mark-icon.completed {
  fill: #28ca42;
}
.get-started-item--clickable {
  cursor: pointer;
}
.get-started-item .get-started-item-text {
  margin: 0 10px;
  color: #6d83d4;
  font-weight: 500;
}
.get-started-item .get-started-item-text.completed {
  text-decoration: line-through;
}
.get-started-item .get-started-item-duration {
  white-space: nowrap;
  margin: 0 10px;
  color: #a5abc9;
}
.get-started-item .spacer {
  display: flex;
  flex-grow: 1;
}
.blog .blog-articles-container {
  display: flex;
  flex-direction: column;
}
.blog .blog-article {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.blog .blog-article:hover .article-title {
  cursor: pointer;
  text-decoration: underline;
  color: #4864c9;
}
.blog .article-image-container {
  display: flex;
  margin-right: 10px;
}
.blog .article-image {
  width: 130px;
  height: 100px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid lightgray;
  cursor: pointer;
}
.blog .article-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog .article-title {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #1f2e77;
}
.blog .article-extract {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 18px;
  word-break: break-word;
  hyphens: auto;
  color: #767689;
}
.blog .article-author {
  font-size: 11px;
  color: #a6a6a6;
  line-height: 1;
  margin-bottom: 5px;
}
.blog .see-all-link-container {
  margin-top: 15px;
}
.blog a:hover {
  text-decoration: underline;
}
