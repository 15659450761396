/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.generic-signin-form {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  padding: 20px;
}
.generic-signin-form .signin-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.generic-signin-form .signin-buttons div:last-child {
  margin-left: 20px;
}
.generic-signin-form .signin-separator-dark {
  margin: 20px 0;
  opacity: 1;
  color: #1f2e77;
}
.generic-signin-form .signin-separator-dark:before,
.generic-signin-form .signin-separator-dark:after {
  opacity: 0.4;
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #1f2e77;
  margin: auto;
}
.generic-signin-form .user-cgv .ui.checkbox {
  display: flex;
  margin-top: 20px;
}
.generic-signin-form .user-cgv .ui.checkbox label {
  padding-left: 24px;
}
.generic-signin-form .user-cgv .ui.checkbox label a {
  color: rgba(0, 0, 0, 0.87);
}
.generic-signin-form input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.generic-signin-form input .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.generic-signin-form input:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.generic-signin-form .field.email-field input:disabled {
  opacity: 0.5;
}
.generic-signin-form .form-details {
  color: #1f2e77;
  font-size: 14px;
}
.generic-signin-form .field.password-field .input-error {
  border-color: #e08383;
}
.generic-signin-form .field.submit-field {
  margin-top: 30px;
  text-align: center;
}
.generic-signin-form .field.submit-field .submit-button-styles {
  min-width: 220px;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
  border-radius: 4px;
  height: 52px;
  font-size: 20px;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
}
.generic-signin-form .field.submit-field .submit-button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid white;
  font-family: Graphik;
  color: white;
  background-color: #1f2e77;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  min-width: 220px;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
  border-radius: 4px;
  height: 52px;
  font-size: 20px;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
}
.generic-signin-form .field.submit-field .submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.generic-signin-form .field.submit-field .submit-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid white;
  font-family: Graphik;
  color: white;
  background-color: #4864c9;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  min-width: 220px;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
  border-radius: 4px;
  height: 52px;
  font-size: 20px;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
}
.generic-signin-form .underlined-link {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.generic-signin-form .underlined-link span {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  opacity: 0.7;
  font-family: Graphik, sans-serif;
  font-size: 13px;
  color: #1f2e77;
  letter-spacing: 0;
  text-decoration: underline;
}
.generic-signin-form .underlined-link span:hover {
  color: #4864c9;
}
.generic-signin-form .candidate-link {
  margin-top: 6px;
}
.generic-signin-form .qr-code-container {
  display: flex;
  justify-content: center;
}
.generic-signin-form .signup-error {
  display: inline-block;
  font-size: 14px;
  margin-top: 14px;
  color: #f86e7f;
  text-align: center;
  font-weight: 600;
  font-family: Gilroy, sans-serif;
}
.generic-signin-form .ui.message.error-message {
  margin-top: 20px;
  font-family: Graphik, sans-serif;
}
.generic-signin-form .ui.message.error-message .header {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
}
.generic-signin-form .cgv-first-line {
  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-family: Graphik, sans-serif;
  font-weight: 400;
  line-height: 16px;
}
.generic-signin-form .cgv-first-line .cgv-modal-trigger {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  text-decoration: underline;
}
.generic-signin-form .warning-message {
  margin-top: 14px;
  color: #f86e7f;
}
.generic-signin-form .link-to-signin-container {
  text-align: right;
  margin-bottom: 30px;
}
.already-have-an-account-link {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-family: Graphik, sans-serif;
}
.already-have-an-account-link .link-to-signin {
  font-weight: 600;
}
.signin-separator {
  font-family: Gilroy;
  font-weight: 600;
  opacity: 0.4;
  color: white;
  display: flex;
  flex-direction: row;
}
.signin-separator > span {
  padding: 0px 20px;
}
.signin-separator:before,
.signin-separator:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid white;
  margin: auto;
}
.password-checks {
  margin: 0.6em 0;
  height: 0px;
  transition: all 300ms ease-in-out;
  overflow: hidden;
}
.password-checks.active {
  height: 60px;
}
.password-checks .invalid {
  color: black;
  opacity: 0.5;
}
.password-checks .valid {
  color: #4864c9;
}
