@import 'src/less/colors';

.column {
  cursor: pointer;
  width: 160px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 8px;

  background-color: @SecondaryBlue05;

  @media (max-width: 1400px) {
    width: 100px;
    justify-content: center;
  }

  .count {
    text-align: left;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: @PrimaryCobalt40;
  }

  &:hover {
    .count {
      text-decoration: underline;
    }
  }

  &.notEmpty {
    background-color: @SecondarySkyBlue10;

    .count {
      color: @SecondaryBlue;
    }
  }
}
