@import 'src/less/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .headerTitleContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    h4 {
      margin: 0;
    }

    i {
      color: white;

      &.whatsapp:before {
        background: rgb(91, 180, 81);
      }

      &.linkedin:before {
        background: rgb(70, 128, 215);
      }

      &:before {
        border-radius: 3px;
        padding: 3px;
      }
    }
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  .checkboxLabel {
    color: @PrimaryCobalt;
  }
}

.formContainer {
  margin-top: 12px;
}

.infoBox {
  margin: 8px 0px;
}

.dropdownOption {
  display: flex;
  gap: 8px;
  align-items: center;

  .dropdownIconContainer {
    border-radius: 3px;
    padding: 1px;
    text-align: center;
    width: 18px;
    height: 18px;

    i {
      line-height: 16px;
    }
  }
}

.textArea {
  resize: none !important;
}
