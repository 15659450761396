/*
 * colors.less
 */
.graph {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}
.graph .label {
  min-width: 240px;
  max-width: 400px;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  color: #4864c9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.graph .label.labelLink:hover {
  text-decoration: underline;
}
.graph .scale {
  grid-column: 2;
  display: flex;
  justify-content: space-between;
}
.graph .scale .tick {
  font-size: 12px;
  font-family: Graphik;
  color: #7982ad;
}
