/*
 * colors.less
 */
.panel {
  padding: 8px 0;
}
.header {
  min-height: 32px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dotsPanel {
  max-width: max-content;
}
.title {
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #7982ad;
}
.option {
  display: flex;
  height: 32px;
  width: 100%;
  gap: 8px;
  align-items: center;
  padding: 0 12px;
  font-size: 16px;
  color: #1f2e77;
  cursor: pointer;
}
.option:hover {
  background-color: #f6f7fc;
}
.dot {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: #4864c9;
}
.label {
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.chevron {
  flex: 1;
  text-align: right;
}
