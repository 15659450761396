/*
 * colors.less
 */
.new-sequence-edit.ui.container {
  width: 930px;
}
.new-sequence-edit.ui.container .sequence-title-input.ui.input input {
  color: #4864c9;
  font-weight: 600;
}
.new-sequence-edit.ui.container .sequence-description-input {
  margin-top: 16px;
  width: 100%;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  padding: 8px 16px;
  font-size: 14px;
}
.sequence-subheader-more-actions {
  margin-right: 8px;
  color: #a5abc9;
}
