@import 'src/less/colors';

.header {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0px 0px 0px 4px;

  border-bottom: 1px solid @SecondaryBlue20;
  background-color: @SecondarySkyBlue05;

  .column {
    font-family: Graphik;
    font-size: 12px;
    color: @PrimaryCobalt60;
    font-weight: 500;
  }
}
