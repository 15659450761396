.menu {
  display: flex;
  width: 100%;
}
.menu .visibleMenu {
  white-space: nowrap;
  overflow: hidden;
}
.menu .visibleMenu .menuItem {
  display: inline-block;
  vertical-align: middle;
}
.menu .visibleMenu .menuItem.hidden {
  visibility: hidden;
}
.menu .ellipsis {
  flex: 1;
}
.menu .ellipsis.hidden {
  visibility: hidden;
}
