@import 'src/less/colors';

.card {
  margin: 16px 0;
  min-width: 450px;
  display: grid;
  grid-template-rows: auto 1fr;
  background: white;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  .cardHeader {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @SecondaryBlue20;
    padding: 0 24px;

    h3 {
      white-space: nowrap;
      color: @PrimaryCobalt;
      margin: 0;
    }
  }

  .cardBody {
    min-width: 0;
    .loader {
      margin: 100px auto;
    }
  }
}

.table {
  .row {
    display: flex;
    align-items: center;
    padding: 18px 24px;

    &:not(:last-child) {
      border-bottom: 1px solid @SecondaryBlue20;
    }
  }

  .header {
    color: @PrimaryCobalt60;
    height: 54px;
    padding: 18px 24px;
    background-color: @SecondarySkyBlue05;

    .cell {
      font-family: Graphik;
      font-weight: 500;
      font-size: 14px;
      color: @PrimaryCobalt60;
    }
  }

  .cell {
    font-family: Graphik;
    font-weight: 500;
    font-size: 14px;
    color: @PrimaryCobalt;

    &:first-child {
      flex: 1;
    }

    &:not(:first-child) {
      width: 150px;
      text-align: center;
    }

    &:is(button):not(:disabled) {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.link {
  .linkTitle {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .linkUrl {
    font-family: Graphik;
    font-weight: 400;
    font-size: 12px;
    color: @PrimaryCobalt60;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.linkSubtitle {
  overflow-wrap: break-word;
}
