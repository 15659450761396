.cell {
  width: 150px;
  text-align: center;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.cell:not(:disabled) {
  cursor: pointer;
}
.cell:not(:disabled):hover {
  text-decoration: underline;
}
