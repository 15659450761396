/*
 * colors.less
 */
.job-postings .ui.table {
  margin-bottom: 24px;
}
.job-postings .ui.table td:first-child {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #4c5892;
}
.job-postings .ui.table td.departments {
  width: 175px;
}
.job-postings .ui.table td:last-child {
  width: 150px;
}
.job-postings .ui.table td:last-child .edit-cell {
  justify-content: flex-end;
}
.job-postings .ui.table td:last-child .edit-cell .ui.button.tertiary {
  margin-right: 0px;
  margin-left: 0.25em;
}
.job-postings .ui.table th:last-child {
  text-align: right;
}
.job-postings .ui.table:last-child {
  margin-bottom: 24px;
}
.job-postings .button-container {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}
