@import 'src/less/colors';

.trigger {
  display: block;
  width: 100%;
  padding: 0;
  font-family: Graphik;
  text-align: left;
}

.triggerContent {
  display: flex;
  align-items: center;

  .triggerText {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dropdown {
  display: block;
  width: 100%;
}

.triggerPlaceholder {
  color: @PrimaryCobalt40;
}

.pillMessage {
  max-width: 120px;
  width: fit-content;
  display: flex !important;
  align-items: center;
}

.itemListContainer {
  display: flex;
  align-items: center;
  display: inline-block;
}

.projectTitle {
  max-width: 80%;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.removeItem {
  margin-left: 4px;
  margin-top: -6px;
  height: 16px;
  margin-left: 4px;

  svg {
    font-size: 17px;
  }
}
