@import 'src/less/colors';

.wrapper {
  border: 2px solid #c6d8ff;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 8px;
  background: white;
  position: absolute;
  bottom: calc(100% + 1px);
  left: -30px;
  z-index: 1900;
  outline: none;
  width: 265px;
  max-height: 346px;
  overflow-y: scroll;

  .padding {
    padding: 5px 10px;
  }

  .hr {
    background: #dae0f4;
    margin: 0;
    opacity: 0.4;
  }

  .sectionTitle {
    font-family: 'Graphik';
    font-weight: 400;
    font-size: 12px;
    color: @PrimaryCobalt60;
    display: block;
    padding: 3px 10px;
  }

  .variable {
    font-family: 'Graphik';
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    padding: 3px 10px;

    &:hover {
      background: @SecondaryBlue05;
    }

    &.justifyBetween {
      justify-content: space-between;
    }
  }

  .subSectionTitle {
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 12px;
    color: @PrimaryCobalt;
    cursor: pointer;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: @SecondaryBlue05;
    }

    .icon {
      height: auto;
    }

    .textWithIcon {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }

  .pointer {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .emptyState {
    padding: 3px;
    gap: 5px;
  }

  .missionCustomFieldsEmptyState {
    display: flex;
    gap: 8px;
    padding: 5px 10px;
    padding-top: 0px;

    img {
      width: 40px;
    }

    h4 {
      font-size: 12px;
      font-family: Gilroy;
      color: @PrimaryCobalt !important;
      margin-bottom: 0;
      font-weight: 600;
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
    }

    .cta {
      font-family: Graphik;
      font-style: normal;
      font-size: 10px;
      font-weight: 400;
      color: @SecondaryBlue !important;
      display: flex;
      align-items: center;
    }
  }
}

.wrapperTemplate {
  max-height: 220px;
}
