/*
 * colors.less
 */
.criteria-tags {
  margin-top: 20px;
}
.criteria-tags h5 {
  margin-bottom: 8px;
}
.criteria-tags .tags-container {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}
.criteria-tags .criteria-tag {
  padding: 1px 6px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin: 2px;
  border-radius: 8px;
  background-color: #f1f6ff;
  color: #7982ad;
}
.criteria-tags .criteria-tag.major,
.criteria-tags .criteria-tag.main {
  background-color: #4864c9;
  color: white;
}
.criteria-tags .criteria-tag.important,
.criteria-tags .criteria-tag.medium {
  background-color: #709dff;
  color: white;
}
.criteria-tags .criteria-tag.on {
  background-color: #28ca421a;
  color: #28ca42;
}
.criteria-tags .criteria-tag.off {
  background-color: #d2d5e4;
  color: white;
}
