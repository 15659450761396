@import 'src/less/colors';

.triggerContent {
  display: flex;
  align-items: center;
  font-family: Graphik, sans-serif;

  .triggerText {
    overflow: hidden;
    text-overflow: ellipsis;
    color: @PrimaryCobalt;
  }

  .triggerPlaceholder {
    color: @PrimaryCobalt40;
  }
}
