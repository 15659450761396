@import 'src/less/colors.less';

:global(.ui.toggle.checkbox).toggle {
  height: 16px;
  min-height: 0px;
  width: 28px !important;

  input {
    width: 28px;
    height: 16px;
  }

  label {
    height: 16px;
    min-height: 0px;
    padding-left: 36px;
    padding-top: 0px;
    color: @PrimaryCobalt;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    &:before {
      width: 28px;
      height: 16px;
      background: @SecondaryBlue40;
    }

    &:after {
      height: 12px;
      width: 12px;
      border: none;
      box-shadow: none;
      margin-top: 2px;
      margin-left: 2px;
      background: white;
      box-shadow: none !important;
    }
  }

  input:checked ~ label {
    color: @PrimaryCobalt !important;

    &:after {
      left: 12px !important;
    }
  }
}
