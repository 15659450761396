@import 'src/less/colors';

.modal {
  z-index: 10;

  .formItem {
    margin-bottom: 16px;

    .label {
      margin-bottom: 6px;
    }

    .subject {
      width: 100%;
    }
  }

  .textarea {
    width: 100%;
  }

  .disabledButton {
    background-color: @SecondaryBlue40 !important;
    color: white;
    cursor: default !important;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
