.achievements-container {
  padding-top: 25px;
}
.achievements-container .achievements-descriptor {
  font-size: 12px;
  font-weight: 500;
}
.ui.table.achievements-table {
  font-size: 12px;
}
