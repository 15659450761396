/*
 * colors.less
 */
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Extrabold.otf);
  font-family: Gilroy;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Bold.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Semibold.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Regular.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-BoldItalic.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-SemiboldItalic.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-RegularItalic.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Bold.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Semibold.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Medium.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Regular.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-BoldItalic.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-SemiboldItalic.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-MediumItalic.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-RegularItalic.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Regular.ttf);
  font-family: 'Lexend Exa';
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Bold.ttf);
  font-family: 'Lexend Exa';
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'TaskIcons';
  src: url('/fonts/tasks/TaskIcons.eot?akl2n2');
  src: url('/fonts/tasks/TaskIcons.eot?akl2n2#iefix') format('embedded-opentype'), url('/fonts/tasks/TaskIcons.ttf?akl2n2') format('truetype'), url('/fonts/tasks/TaskIcons.woff?akl2n2') format('woff'), url('/fonts/tasks/TaskIcons.svg?akl2n2#TaskIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.wrapper {
  font-family: Gilroy, sans-serif;
  background: #ffffff;
  border: 1px solid #dae0f4;
  min-width: 300px;
  max-width: 336px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid #4864c9;
  color: #4c5892;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  z-index: 15;
}
.pluginWrapper {
  min-width: unset;
  max-height: unset;
  width: 334px;
}
.absoluteLeft {
  left: 0px;
}
.absoluteRight {
  right: 40px;
}
.header {
  height: 48px;
  padding: 0 12px;
  width: 100%;
  border-bottom: 1px solid #f3f3f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content {
  padding: 10px;
  font-family: Graphik, sans-serif;
}
.paddingY {
  padding: 10px 0px;
}
.stage {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 12px;
}
.stage:hover {
  background: #edf0fa;
}
.stageDisabled {
  cursor: auto;
  background: #f3f4f8;
  opacity: 0.7;
}
.stageDisabled:hover {
  background: #f3f4f8;
}
.moveButton {
  background-color: #4864c9 !important;
  color: white !important;
  border-radius: 2px !important;
  padding: 5px 8px !important;
  font-size: 14px !important;
}
.headerStageSelected {
  cursor: pointer;
}
.headerStageSelected i {
  padding-right: 10px;
}
.customButton {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  color: #4c5892;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
.buttonActive {
  border: 2px solid #4864c9;
}
.paragraph {
  display: block;
  font-weight: 600;
  color: #4c5892;
  font-size: 12px;
  margin-bottom: 10px;
}
.paragraphLight {
  display: block;
  color: #4c5892;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 400;
}
.buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.buttons button {
  flex: 1;
}
.sequence {
  padding-top: 10px;
  padding-bottom: 5px;
  color: #4c5892;
  font-size: 12px;
  font-weight: 400;
}
.sequenceName {
  color: #4864c9;
  font-weight: 600;
}
.blueBox {
  margin-top: 15px;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  background-color: #e2ebff;
  color: #4c5892;
  font-weight: 400;
  white-space: normal;
}
.blueBox span {
  display: block;
  margin-bottom: 5px;
}
