/*
 * colors.less
 */
.fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #404564;
}
.fieldsContainer input,
.fieldsContainer div:global(.ui.selection.dropdown) {
  border-radius: 8px !important;
}
.fieldsSectionContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0px;
}
.fieldsSectionContainer .fieldsTagSection,
.fieldsSectionContainer .fieldsQuestionSection,
.fieldsSectionContainer .fieldsActionsSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.fieldsSectionContainer .fieldsTagSection span,
.fieldsSectionContainer .fieldsQuestionSection span,
.fieldsSectionContainer .fieldsActionsSection span {
  color: #1f2e77;
  margin-bottom: -8px;
}
.fieldsSectionContainer .fieldsQuestionSection {
  flex-grow: 1;
}
.fieldsSectionContainer .fieldsActionsSection {
  flex-shrink: 1;
  flex-grow: 0;
}
.fieldsSectionContainer .fieldsActionsSection > div {
  padding: 6px 0px;
  margin: auto;
}
.modalActions:global(.actions) button:first-of-type {
  padding-left: 16px !important;
}
