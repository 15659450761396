/*
 * colors.less
 */
.new-campaign-edit.ui.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.new-campaign-edit.ui.container h3 {
  margin-bottom: 0px;
}
.new-campaign-edit.ui.container .campaign-title-input.ui.input input {
  padding-left: 40px;
  color: #4864c9;
  font-weight: 600;
}
.new-campaign-edit.ui.container .campaign-subject-input.ui.input input {
  padding-left: 40px;
  color: #4864c9;
  font-weight: 600;
}
.new-campaign-edit.ui.container .campaign-input-container {
  margin-top: 0;
}
.new-campaign-edit.ui.container .editor-container {
  flex-grow: 1;
  padding: 0px;
}
.new-campaign-edit.ui.container .editor-container > div {
  height: 100%;
}
.new-campaign-edit.ui.container .campaign-description-input {
  margin-top: 16px;
  width: 100%;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  padding: 8px 16px;
  font-size: 14px;
}
