/*
 * colors.less
 */
.sequence-tab .sequences-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sequence-tab .sequences-container .preview-container {
  background-color: #f8faff;
  border: 1px solid #edf0fa;
  border-radius: 4px;
}
.sequence-tab .sequences-container .preview-container .preview {
  background-color: white;
  width: calc(100% - 120px);
  height: 100%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 1px solid #edf0fa;
  border-right: 1px solid #edf0fa;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header,
.sequence-tab .sequences-container .preview-container .preview .preview-body {
  padding: 0px 20px;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .picture-and-headline {
  display: flex;
  flex-direction: row;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .picture-and-headline .headline-container {
  padding: 3px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .picture-and-headline .headline-container .item {
  border-radius: 2px;
  background-color: #e9ebf2;
  height: 4px;
  width: 120px;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .picture-and-headline .headline-container .item.header-item {
  border-radius: 4px;
  background-color: #4864c9;
  height: 8px;
  width: 100px;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .description {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .description .item {
  border-radius: 2px;
  background-color: #e9ebf2;
  height: 4px;
  width: 50px;
}
.sequence-tab .sequences-container .preview-container .preview .preview-header .description .item:first-of-type {
  background-color: #4864c9;
  width: 60px;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-input {
  display: flex;
  width: 100%;
  border: 1px solid #dae0f4;
  border-radius: 4px;
  height: 40px;
  padding: 0.65em 1em;
  vertical-align: middle;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-input .fake-input-value {
  margin-bottom: 0;
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-input span {
  float: right;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu {
  width: calc(100% - 20px);
  margin: auto;
  margin-top: -5px;
  border-radius: 4px;
  padding: 1em;
  border: 1px solid #b6c1e9;
  background-color: white;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .divider {
  margin: 1em 0em;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .ui.tiny.header {
  color: #a5abc9;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .header {
  margin-bottom: 5px;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .default-sequence-preview,
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .secondary-sequence-preview {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 2px;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .default-sequence-preview span,
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .secondary-sequence-preview span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  margin-top: 2px;
  min-width: auto;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .default-sequence-preview .sequence-preview,
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .secondary-sequence-preview .sequence-preview {
  flex-shrink: 0;
  margin: 0px 8px;
  display: inline-block;
  flex-grow: 1;
}
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .default-sequence-preview .sequence-preview .sequence-container .row-sequence-container .row-icon-container,
.sequence-tab .sequences-container .preview-container .preview .sequence-dropdown-preview-container .fake-dropdown-menu .default-preview .secondary-sequence-preview .sequence-preview .sequence-container .row-sequence-container .row-icon-container {
  margin-top: 0;
}
.sequence-tab .sequences-container .result-preview {
  width: 56%;
  max-width: 56%;
}
.sequence-tab .sequences-container .mission-sequences {
  width: 40%;
  position: relative;
}
.sequence-tab .sequences-container .mission-sequences .dropdown .default.text {
  color: #7982ad;
}
.sequence-tab .sequences-container .mission-sequences .dropdown.with-preview.has-value {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.sequence-tab .sequences-container .mission-sequences .dropdown.with-preview .divider.default.text {
  color: #7982ad;
}
.sequence-tab .sequences-container .mission-sequences .dropdown.with-preview .divider.text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 2px;
}
.sequence-tab .sequences-container .mission-sequences .dropdown.with-preview .divider.text span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  margin-top: 2px;
}
.sequence-tab .sequences-container .mission-sequences .dropdown.with-preview .divider.text .sequence-preview {
  flex-shrink: 0;
  margin: 0px 8px;
  display: inline-block;
  flex-grow: 1;
}
.sequence-tab .sequences-container .mission-sequences .dropdown.with-preview .divider.text .sequence-preview .sequence-container .row-sequence-container .row-icon-container {
  margin-top: 0;
}
.sequence-tab .sequences-container .mission-sequences .close-default-container {
  position: absolute;
  background-color: white;
  top: 85px;
  right: 10px;
}
.sequence-tab .sequences-container .mission-sequences .close-default-container i {
  cursor: pointer;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence {
  width: 100%;
  margin: 10px 0px;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .controls {
  display: inline-block;
  padding: 10px 0px;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .sequence {
  width: 100%;
  padding: 8px 8px 10px 15px;
  display: flex;
  text-align: left;
  border: 1px solid #dae0f4;
  height: 38px;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .sequence .text {
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  justify-items: baseline;
  align-items: baseline;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .sequence .text span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 1px;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .sequence .text .sequence-preview {
  flex-shrink: 0;
  margin: 0px 8px;
  align-self: center;
  display: inline-block;
  flex-grow: 1;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .sequence .text .sequence-preview .sequence-container .row-sequence-container .row-icon-container {
  margin-top: 0;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence .sequence i {
  float: right;
  cursor: pointer;
}
.sequence-tab .sequences-container .mission-sequences .secondary-sequence hr {
  border-color: silver;
}
.sequence-tab .sequences-container .mission-sequences .selection.dropdown {
  width: 100%;
}
.sequence-tab .ui.selection.dropdown .menu .item {
  padding: 0px !important;
}
.sequence-tab .ui.selection.dropdown .menu .item .hover-item-popup-trigger {
  padding: 0.8em 1em;
}
.sequence-tab .menu .item .hover-item-popup-trigger,
.sequence-tab .selection.dropdown .text {
  display: flex;
  flex-direction: row;
  justify-items: baseline;
}
.sequence-tab .menu .item .hover-item-popup-trigger span,
.sequence-tab .selection.dropdown .text span {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
  margin-top: 2px;
}
.sequence-tab .menu .item .hover-item-popup-trigger .sequence-preview,
.sequence-tab .selection.dropdown .text .sequence-preview {
  flex-shrink: 0;
  margin: 0px 8px;
  align-self: center;
  display: inline-block;
  flex-grow: 1;
}
.sequence-tab .menu .item .hover-item-popup-trigger .sequence-preview .sequence-container .row-sequence-container .row-icon-container,
.sequence-tab .selection.dropdown .text .sequence-preview .sequence-container .row-sequence-container .row-icon-container {
  margin-top: 0;
}
.sequence-tab .info-box {
  display: flex;
  flex-direction: row;
  margin: 1em 0 2em 0;
  padding: 16px;
  align-items: center;
  background-color: #f1f6ff;
  border-radius: 8px;
  justify-content: space-between;
}
.sequence-tab .info-box p {
  margin: 0;
  margin-right: 10px;
  min-width: 0;
}
.sequence-tab .info-box .info-box-buttons {
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}
.sequence-tab .info-box .button {
  white-space: nowrap;
}
