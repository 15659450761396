@import 'src/less/colors';

.unlinkFragmentModal {
  .wrapper {
    width: 80%;
    margin: 0 auto;

    .content {
      font-family: 'Graphik';
      font-weight: 400;
      font-size: 14px;
      color: @PrimaryCobalt60;
      text-align: center;
    }

    .warning {
      background: #f1f6ff;
      border-radius: 4px;
      padding: 5px 0px;
      font-family: 'Graphik';
      font-weight: 400;
      font-size: 14px;
      color: @PrimaryColorLight;
      text-align: center;
    }
  }

  .unlinkBtn {
    background-color: @SecondaryRed !important;
  }

  .pluginWrapper {
    width: 100%;
  }
}
