@import 'src/less/colors';

.datePickerWrapper {
  width: 100%;
}

.inputError {
  margin-top: 5px;
  font-size: 12px;
  color: @NegativeColor;
}

.clearButton::after {
  height: 24px !important;
  width: 24px !important;
  padding: 6px !important;
  font-size: 20px !important;
  line-height: 0 !important;
  color: hsl(0, 0%, 80%) !important;
  background: transparent !important;
}
