/*
 * colors.less
 */
.overview-container {
  margin-top: 20px;
}
.overview-container .overview {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
}
.overview-container .overview .overview-section {
  width: 33%;
  padding-right: 16px;
  padding-left: 16px;
  text-align: left;
  display: flex;
  gap: 8px;
  justify-content: left;
}
.overview-container .overview .overview-section:not(:last-of-type) {
  border-right: 1px solid #dae0f4;
}
.overview-container .overview .overview-section .icon-container i {
  color: #709dff;
}
.overview-container .overview .overview-section .section-details {
  overflow: hidden;
}
.overview-container .overview .overview-section .section-details .section-title {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #1f2e77;
  margin: 0;
}
.overview-container .overview .overview-section .section-details .section-value {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #7982ad;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
