/*
 * colors.less
 */
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  height: 64px;
  margin-bottom: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.banner .message {
  flex: 1;
  margin-left: 10px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #4c5892;
}
.banner .link {
  flex-shrink: 0;
  width: max-content;
}
