/*
 * colors.less
 */
.warning {
  margin: 8px 0;
  padding: 7.5px 12px;
  border-radius: 3px;
  border-left: 4px solid #FFBD2E;
  background-color: #FFF2D5;
  font-size: 12px;
  font-family: Graphik;
}
.warning .title {
  font-weight: 500;
  color: #1f2e77;
  margin-right: 4px;
}
.warning .description {
  color: #4c5892;
}
