/*
 * colors.less
 */
.wrapper {
  border: 2px solid #c6d8ff;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 8px;
  background: white;
  position: absolute;
  bottom: calc(100% + 1px);
  left: -30px;
  z-index: 1900;
  outline: none;
  width: 265px;
  max-height: 346px;
  overflow-y: scroll;
}
.wrapper .padding {
  padding: 5px 10px;
}
.wrapper .hr {
  background: #dae0f4;
  margin: 0;
  opacity: 0.4;
}
.wrapper .sectionTitle {
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 12px;
  color: #7982ad;
  display: block;
  padding: 3px 10px;
}
.wrapper .variable {
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  padding: 3px 10px;
}
.wrapper .variable:hover {
  background: #f6f7fc;
}
.wrapper .variable.justifyBetween {
  justify-content: space-between;
}
.wrapper .subSectionTitle {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  color: #1f2e77;
  cursor: pointer;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .subSectionTitle:hover {
  background: #f6f7fc;
}
.wrapper .subSectionTitle .icon {
  height: auto;
}
.wrapper .subSectionTitle .textWithIcon {
  display: flex;
  align-items: center;
  gap: 3px;
}
.wrapper .pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.wrapper .emptyState {
  padding: 3px;
  gap: 5px;
}
.wrapper .missionCustomFieldsEmptyState {
  display: flex;
  gap: 8px;
  padding: 5px 10px;
  padding-top: 0px;
}
.wrapper .missionCustomFieldsEmptyState img {
  width: 40px;
}
.wrapper .missionCustomFieldsEmptyState h4 {
  font-size: 12px;
  font-family: Gilroy;
  color: #1f2e77 !important;
  margin-bottom: 0;
  font-weight: 600;
}
.wrapper .missionCustomFieldsEmptyState .contentWrapper {
  display: flex;
  flex-direction: column;
}
.wrapper .missionCustomFieldsEmptyState .cta {
  font-family: Graphik;
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
  color: #4864c9 !important;
  display: flex;
  align-items: center;
}
.wrapperTemplate {
  max-height: 220px;
}
