@import 'src/less/colors';

.missionSelectorContainer {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  label {
    font-size: 14px;
    color: @PrimaryCobalt80;
    font-family: Graphik;
  }

  .tooltip {
    color: @PrimaryCobalt40;
  }

  .selector {
    z-index: 2;
  }
}
