/*
 * colors.less
 */
.similar-profile-card.ui.segment {
  padding: 0;
}
.similar-profile-card.ui.segment:hover {
  cursor: pointer;
  box-shadow: 2px 2px 10px 1px rgba(72, 100, 201, 0.09);
}
.similar-profile-card.ui.segment .header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #dae0f4;
  width: 100%;
}
.similar-profile-card.ui.segment .header:last-child {
  border-bottom: none;
}
.similar-profile-card.ui.segment .content {
  padding: 16px;
}
.similar-profile-card.ui.segment .avatar-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0;
  min-width: 54px;
  margin-right: 13px;
}
.similar-profile-card.ui.segment .avatar-container img {
  width: 54px;
  height: 54px;
}
.similar-profile-card.ui.segment .name-and-headline-container {
  display: flex;
  flex: 3 0 0;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
}
.similar-profile-card.ui.segment .full-name {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  color: #4864c9;
}
.similar-profile-card.ui.segment .full-name .full-name-text {
  margin-right: 16px;
}
.similar-profile-card.ui.segment .headline {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #4c5892;
}
.similar-profile-card.ui.segment .main-education {
  margin-top: 2px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #7982ad;
}
.similar-profile-card.ui.segment .similarity-score {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.similar-profile-card.ui.segment .similarity-text {
  margin-top: 8px;
  color: #a5abc9;
}
