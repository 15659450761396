@import 'src/less/colors';

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 40px;

  border-bottom: 1px solid @PrimaryCobalt20;
  background-color: white;

  .navigation {
    margin: 0;
    padding: 4px 0;

    .tab {
      display: inline-block;
      margin: 0;
      margin-right: 24px;

      .tabButton {
        font-family: Gilroy;
        font-weight: 700;
        font-size: 22px;
        color: @PrimaryCobalt40;

        &:hover {
          color: @SecondaryBlue80;
        }

        &.active {
          color: @PrimaryCobalt;
        }
      }
    }
  }
  .sequencesButtons {
    display: flex;
    gap: 8px;
  }
}
