/*
 * colors.less
 */
.email-body-and-subject {
  color: #4c5892;
}
.email-body-and-subject .body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}
.email-body-and-subject .body .content {
  white-space: pre-wrap;
}
.email-body-and-subject .body.minimized .content {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-body-and-subject .subject {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}
.email-body-and-subject .subject span {
  font-size: 14px;
  font-weight: 500;
}
.email-body-and-subject .subject.minimized {
  margin-bottom: 4px;
}
.email-body-and-subject .subject.minimized span {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
