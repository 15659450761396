/*
 * colors.less
 */
.tasks-tab .title {
  color: #1f2e77;
}
.tasks-tab .tasks-cohorts-graph {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  margin-top: 16px;
}
.tasks-tab .tasks-cohorts-graph .header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #dae0f4;
}
.tasks-tab .tasks-cohorts-graph .graph {
  width: 100%;
  padding: 35px 20px 20px 15px;
}
.tasks-tab .tasks-cohorts-graph .graph-empty {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tasks-tab .tasks-cohorts-graph .graph-empty-text {
  font-size: 15px;
  opacity: 0.3;
}
.tasks-tab .tasks-cohorts-graph .loader {
  margin: 100px auto;
}
