/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.action-trigger-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}
.action-trigger-editor .trigger-type-label {
  color: #709dff;
}
.action-trigger-editor .automatic-selector,
.action-trigger-editor .trigger-extra-description {
  color: #7982ad;
  font-weight: 400;
}
.action-trigger-editor .automatic-selector .dropdown,
.action-trigger-editor .trigger-extra-description .dropdown {
  width: 150px;
  min-width: auto;
}
.action-trigger-editor .automatic-selector .dropdown.automatic-selector-number,
.action-trigger-editor .trigger-extra-description .dropdown.automatic-selector-number {
  width: 70px;
}
.action-trigger-editor .datepicker {
  margin-left: 8px;
  font-size: 13px;
  font-family: Graphik;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0px 6px;
  border-radius: 4px;
  height: 22px;
}
.action-trigger-editor .react-datepicker-popper {
  z-index: 30;
}
.action-trigger-editor .hs-inline-dropdown.ui.dropdown {
  border: 1px solid #dae0f4;
  border-radius: 2px;
  padding: 0 2px;
  line-height: 18px;
}
.action-trigger-editor .hs-inline-dropdown.ui.dropdown i.icon {
  margin-left: 5px;
  margin-right: 2px;
}
