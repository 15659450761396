@import 'src/less/colors';

.modalHeaderSubtitle {
  font-weight: 500;
  font-style: italic;
  font-size: 18px;
  margin-left: 5px;
}

.container {
  margin-bottom: 24px;

  .title {
    //styleName: Body 14/Medium;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt;
    margin-bottom: 4px;
  }

  .error {
    display: block;
    margin: 5px 0;
    color: #ff6059;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
  }

  .centered {
    text-align: center;
  }
}
