.secondarySequence:not(:last-child) {
  margin-bottom: 8px;
}

.secondarySequenceTrigger {
  display: flex;
  align-items: center;
  gap: 8px;

  :global(.sequence-preview) {
    flex-grow: 1;
  }

  :global(.row-icon-container) {
    margin-top: 0;
  }
}

.fakeTrigger {
  :global(i.dropdown.icon) {
    display: none;
  }
}
