/*
 * colors.less
 */
.dropdownContainer {
  position: relative;
  display: inline-block;
  width: fit-content;
}
.dropdownContent {
  display: none;
  position: absolute;
  z-index: 1;
}
.dropdownContent.left {
  left: 0;
}
.dropdownContent.right {
  right: 0;
}
.dropdownContent.left.center {
  top: 50%;
  transform: translate(50%, -50%);
}
.dropdownContent.right.center {
  top: 50%;
  transform: translate(-50%, -50%);
}
.dropdownContent.open {
  display: block;
}
.dropdownContent.bottom {
  top: calc(100% + 8px);
}
.dropdownContent.top {
  bottom: calc(100% + 8px);
}
.dropdownContent.bottom.center:not(.left):not(.right),
.dropdownContent.top.center:not(.left):not(.right) {
  left: 50%;
  transform: translateX(-50%);
}
