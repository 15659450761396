/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.jobs-view .jobs-header {
  height: 70px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jobs-view .jobs-header .jobs-title {
  color: #1f2e77;
  margin: 0;
}
.jobs-view .jobs-header .new-project-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.jobs-view .jobs-content {
  padding: 0 40px;
}
.jobs-view .jobs-section {
  margin-bottom: 142px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dae0f4;
}
.jobs-view .jobs-section .jobs-section-top {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 11px 0 24px;
}
.jobs-view .jobs-section .jobs-section-top h3 {
  color: #1f2e77;
  margin: 0;
}
.jobs-view .jobs-section .jobs-section-top .jobs-section-top-settings {
  display: inline-flex;
  align-items: center;
  gap: 16px;
}
.jobs-view .jobs-section .jobs-section-pipeline {
  border-bottom: 1px solid #dae0f4;
}
.jobs-view .jobs-section .jobs-section-filters {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #dae0f4;
}
.jobs-view .jobs-section .jobs-section-filters .jobs-section-filters-right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.jobs-view .job-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 48px;
}
.jobs-view .job-cards-container .table-section {
  padding: 0;
}
.jobs-view .job-cards-container .table-section.archived-jobs {
  border-radius: 8px;
  background: white;
  border: 1px solid #dae0f4;
  width: 100%;
}
.jobs-view .archived-link-box {
  text-align: center;
  margin: 30px auto;
}
.column-selector-popup h4 {
  color: #1f2e77;
}
.column-selector {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.column-selector .column-name {
  color: #1f2e77 !important;
}
.column-selector-switch input,
.column-selector-switch label::before {
  width: 34px !important;
  height: 18px !important;
}
.column-selector-switch label::after {
  width: 14px !important;
  height: 14px !important;
  border: none !important;
  background: white !important;
  box-shadow: none !important;
  margin: 2px;
}
.column-selector-switch.checked label {
  color: #1f2e77 !important;
}
.column-selector-switch.checked label::after {
  left: 16px !important;
}
.table-container {
  width: 100%;
  overflow: auto;
}
table.ui.table.jobs-table {
  border: none;
  margin: 0;
}
table.ui.table.jobs-table thead th,
table.ui.table.jobs-table tbody td {
  padding: 16px 10px 16px 0;
}
table.ui.table.jobs-table tbody tr td {
  text-align: left;
}
table.ui.table.jobs-table thead,
table.ui.table.jobs-table thead tr,
table.ui.table.jobs-table thead tr th {
  border: none;
}
table.ui.table.jobs-table tbody,
table.ui.table.jobs-table tbody tr {
  border: none;
}
table.ui.table.jobs-table tbody td {
  border-top: 1px solid #f3f4f8;
  border-bottom: none;
}
table.ui.table.jobs-table tbody td:first-child {
  border-left: none;
}
table.ui.table.jobs-table tbody td:last-child {
  border-right: none;
}
table.ui.table.jobs-table tbody tr:last-child td {
  border-bottom: none;
}
table.ui.table.jobs-table thead th {
  font-family: Graphik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #a5abc9;
}
table.ui.table.jobs-table tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
table.ui.table.jobs-table tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.jobs-table .align-left {
  text-align: left !important;
}
table.ui.table.jobs-table .ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.jobs-table .job-table-headers tr {
  background-color: #f8faff !important;
}
table.ui.table.jobs-table .job-table-headers tr th {
  padding-left: 24px;
  padding-right: 24px;
  color: #7982ad;
  font-weight: 500;
  font-size: 12px;
  font-family: Graphik, sans-serif;
  font-style: normal;
}
table.ui.table.jobs-table .job-table-headers tr th:first-child {
  text-align: left;
}
table.ui.table.jobs-table .job-table-headers tr .column-selector-header {
  text-align: right !important;
}
table.ui.table.jobs-table .job-table-headers .button-header .header-name {
  padding-top: 2px;
  display: inline-block;
}
table.ui.table.jobs-table .job-table-headers .button-header:hover .header-name {
  text-decoration: underline;
}
table.ui.table.jobs-table .job-table-headers .button-header.sort .icon-container {
  vertical-align: top;
  display: inline-block;
}
table.ui.table.jobs-table .job-table-headers .button-header.sort.increasing .icon-container {
  padding-top: 1px;
}
table.ui.table.jobs-table .job-table-headers .button-header.sort.decreasing .icon-container {
  padding-top: 2px;
}
table.ui.table.jobs-table .job-row {
  color: #4864c9;
}
table.ui.table.jobs-table .job-row td,
table.ui.table.jobs-table .job-row a,
table.ui.table.jobs-table .job-row span {
  color: inherit;
}
table.ui.table.jobs-table .job-row .no-effect {
  text-decoration: none !important;
}
table.ui.table.jobs-table .job-row :not(:has(.recommendations:hover)):hover a {
  text-decoration: underline;
}
table.ui.table.jobs-table .job-row td {
  padding-left: 24px;
  padding-right: 24px;
  font-size: 12px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
}
table.ui.table.jobs-table .job-row td.owner {
  max-width: 150px;
}
table.ui.table.jobs-table .job-row td.owner span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.jobs-table .job-row .title {
  color: #4864c9;
}
table.ui.table.jobs-table .job-row .title > div {
  width: 100%;
  display: flex;
}
table.ui.table.jobs-table .job-row .recommendations {
  font-size: 10px;
  padding: 4px 6px 4px 4px !important;
}
table.ui.table.jobs-table .job-row .recommendations:hover {
  text-decoration: underline;
}
table.ui.table.jobs-table .job-row .recommendations .recommendation-icon {
  margin-right: 3px;
  max-width: 10px;
}
table.ui.table.jobs-table .job-row .job-actions {
  display: flex;
  justify-content: right;
  align-items: center;
  visibility: hidden;
}
table.ui.table.jobs-table .job-row .job-actions .placeholder-button {
  visibility: hidden;
  width: 84px;
}
table.ui.table.jobs-table .job-row .job-owner-date span {
  display: block;
}
table.ui.table.jobs-table .job-row .job-owner-date span.job-owner {
  color: #1f2e77;
  font-weight: 500;
  margin-bottom: 5px;
}
table.ui.table.jobs-table .job-row .job-owner-date span.job-date {
  color: #7982ad;
  font-weight: 400;
}
table.ui.table.jobs-table .job-header {
  display: flex;
  align-items: center;
}
table.ui.table.jobs-table .job-header-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.jobs-table .job-header-title:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #4864c9;
}
table.ui.table.jobs-table .job-row {
  cursor: pointer;
}
table.ui.table.jobs-table .job-row.not-clickable {
  cursor: default;
}
table.ui.table.jobs-table .job-row:hover {
  background: rgba(100, 100, 100, 0.01);
}
table.ui.table.jobs-table .job-row:hover .job-actions {
  visibility: visible;
}
table.ui.table.jobs-table .job-row td {
  white-space: nowrap;
}
table.ui.table.jobs-table .job-row td:first-child {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.jobs-table .job-icon {
  margin-right: 10px;
  color: #a5abc9;
}
.ui.dropdown .menu .item.option-tier1 {
  font-size: 14px;
  color: #1f2e77;
}
.ui.dropdown .menu .item.option-tier1:not(:first-child) {
  padding-bottom: 8px !important;
  border-top: 1px solid #dae0f4;
}
.ui.dropdown .menu .item.option-tier1:not(:first-child):not(:nth-child(2)) {
  margin-top: 8px;
}
.ui.dropdown .menu .item.option-tier2 {
  padding-bottom: 8px !important;
  padding-left: 24px !important;
}
.ui.dropdown .menu .item.option-tier3 {
  padding-left: 32px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 400;
}
.ui.dropdown.search.department-search {
  display: inline-block;
  min-width: 14em;
  min-height: 2.85714286em;
  line-height: 1em;
  padding: 0.85714286em 2.1em 0.85714286em 1em;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.ui.dropdown.search.department-search:not(.disabled) {
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.ui.dropdown.search.department-search:has(input:focus) {
  border-color: #a9c4ff;
  box-shadow: inset 0px 0px 1px 1px #a9c4ff;
}
.ui.dropdown.search.department-search input.search {
  line-height: 1.21428571em;
  padding: 0.75em 2.1em 0.75em 1em;
}
.ui.dropdown.search.department-search .dropdown.icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.85714286em;
  right: 1em;
  z-index: 3;
  margin: -0.85714286em;
  padding: 1em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
}
