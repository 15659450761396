@import 'src/less/colors.less';

.dropdownTrigger {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  .trigger {
    flex-grow: 1;

    // To ellipsise text if too long
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      color: @PrimaryCobalt60;
    }

    i.dropdownIcon {
      margin-top: -5px;
    }

    i:not(:last-child) {
      margin-right: 12px;
    }
  }
}
