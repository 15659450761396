@import 'src/less/colors';

.timelineItem {
  margin-bottom: 20px;

  &:has(.content) {
    .iconAfter {
      &::after {
        display: none;
      }
    }
    position: relative;

    &::after {
      border-left: 2px solid #e8e8ec;
      content: '';
      position: absolute;
      top: 19px;
      left: 7px;
      bottom: 0;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      height: calc(100% - 20px);
    }
  }
}

.header {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.content {
  border-radius: 8px;
  border: 1px solid @SecondarySkyBlue20;
  background: white;
  padding: 8px 12px;
  margin-left: 44px;
  font-size: 12px;
  margin-top: 4px;
}

.description {
  flex: 1;
  text-align: left;
  padding-left: 30px;
}

.taskType {
  color: @PrimaryCobalt80;
  font-weight: 500;
}

.icon {
  color: @PrimaryCobalt40;
  font-size: 14px;
}

.iconAfter {
  &::after {
    border-left: 2px solid #e8e8ec;
    content: '';
    position: absolute;
    top: 19px;
    left: 7px;
    bottom: -19px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.author {
  font-size: 12px;
  text-align: right;
  color: @PrimaryCobalt60;
}

.date {
  font-size: 16px;
  font-weight: 700;
  color: @PrimaryCobalt80;
  margin-bottom: 10px;
}

.marginTop {
  margin-top: 40px;
}

.seeMore {
  padding: 15px 0;
  cursor: pointer;
  text-decoration: underline;
  color: @SecondarySkyBlue120;
  font-size: 12px;
  color: @PrimaryCobalt60;
  font-weight: 400;
}

.target {
  font-weight: 500;
  color: @SecondarySkyBlue120;
}
