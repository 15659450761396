/*
 * colors.less
 */
.searchResultsPage {
  height: 100%;
}
.searchResultsPage .loadingPlaceholder {
  min-height: 250px;
  height: 100%;
  display: flex;
  align-items: center;
}
.searchResultsPage .loadingPlaceholder :global(.ui.loader) {
  z-index: 1;
}
.searchResultsPage .noResults {
  padding: 120px 24px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #1f2e77;
}
.searchResultsPage .row {
  position: relative;
}
.searchResultsPage .row .actions {
  display: none;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
.searchResultsPage .row:hover .actions,
.searchResultsPage .row.lockedHover .actions {
  display: block;
}
.searchResultsPage .row:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
