@import 'src/main';
@import 'src/less/colors';

.header {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr auto;
  align-items: center;

  .left {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 24px;
  }

  .right {
    display: flex;
    align-items: center;

    .shortcutIcons {
      display: flex;
      align-items: center;
    }

    .iconNavigationItem {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      cursor: pointer;
      height: 100%;

      color: @PrimaryCobalt60;

      i {
        margin: 0;
        font-size: 16px;
      }

      .addIcon {
        height: 16px;
      }
    }

    .clientProfilesSearchBarContainer {
      margin-right: 30px;
      position: relative;

      img:hover {
        cursor: pointer;
      }
    }

    .notificationsOuterContainer {
      vertical-align: middle;
      padding-top: 7px;
      margin-right: 36px;
    }
  }
}
