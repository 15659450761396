/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.ui.segment.todos-content {
  flex-grow: 1;
  margin-top: 0px;
  padding: 12px 0 0 0;
}
.ui.segment.todos-content .todos-header-container {
  display: flex;
  border-bottom: solid 1px #dfdfe6;
  margin-bottom: 28px;
}
.ui.segment.todos-content .todos-header-container .tab {
  font-weight: 500;
  padding: 10px 12px 8px;
  cursor: pointer;
  color: #a5abc9;
}
.ui.segment.todos-content .todos-header-container .tab.active {
  color: #709dff;
  border-bottom: solid 2px #709dff;
}
.ui.segment.todos-content .todos-header-container .tab:first-child {
  margin-left: 38px;
}
.ui.segment.todos-content .view-mode-buttons {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #e2ebff;
  height: 28px;
  margin-right: 20px;
}
.ui.segment.todos-content .view-mode-buttons .button {
  background-color: transparent;
  color: #e2ebff;
  padding: 6px 12px;
}
.ui.segment.todos-content .view-mode-buttons .button.active {
  color: #709dff;
  margin: -1px;
  border: 1px solid #709dff;
  border-radius: 4px;
  background-color: #e2ebff;
}
.ui.segment.todos-content .task-cards {
  padding: 0 20px 20px;
  margin: -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ui.segment.todos-content .task-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #dae0f4;
  flex-basis: calc(100% - 2 * 10px);
  margin: 10px;
}
.ui.segment.todos-content .task-card:hover {
  box-shadow: 2px 2px 10px 1px rgba(72, 100, 201, 0.09);
}
@media screen and (min-width: 1350px) {
  .ui.segment.todos-content .task-card {
    flex-basis: calc(50% - 2 * 10px);
  }
}
.ui.segment.todos-content .task-card .header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
}
.ui.segment.todos-content .task-card .header .ui.button {
  white-space: nowrap;
  margin-right: 0px;
}
.ui.segment.todos-content .task-card .header .avatar-and-info-container {
  display: flex;
  align-items: center;
  flex-grow: 0;
}
.ui.segment.todos-content .task-card .header .avatar-container {
  position: relative;
  flex: 0 0;
  min-width: calc(2 * 16px);
  margin-right: 12px;
  height: calc(2 * 16px);
}
.ui.segment.todos-content .task-card .header .avatar-container img {
  width: 100%;
}
.ui.segment.todos-content .task-card .header .profile-info-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ui.segment.todos-content .task-card .header .profile-info-container .fullname {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 16px;
  color: #4864c9;
  line-height: 20px;
}
.ui.segment.todos-content .task-card .header .profile-info-container .fullname.unbiased {
  text-shadow: 0 0 10px #4864c9;
  color: transparent;
}
.ui.segment.todos-content .task-card .header .profile-info-container .fullname.unbiased:hover {
  text-shadow: 0 0 0px #000;
  color: #4864c9;
}
.ui.segment.todos-content .task-card .header .profile-info-container .headline {
  color: #4c5892;
  font-weight: 500;
  font-size: 13px;
}
.ui.segment.todos-content .task-card .header .buttons-container {
  margin-left: 16px;
}
.ui.segment.todos-content .task-card .task-card-content {
  padding: 16px 24px;
  font-size: 12px;
  color: #4864c9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.ui.segment.todos-content .task-card .task-card-content .line i {
  margin-right: 0.5em;
  vertical-align: bottom;
  color: #d2d5e4;
}
.ui.segment.todos-content .task-cards-empty {
  padding: 24px;
  margin: auto;
}
.ui.segment.todos-content .task-cards-empty img {
  height: 340px;
}
.ui.segment.todos-content .task-cards-empty .no-tasks-text {
  color: #709dff;
  margin-top: 40px;
  text-align: center;
}
.ui.segment.todos-content .profile-preview-container .dimmer {
  flex: 0 1 50%;
}
.ui.segment.todos-content .profile-preview-container .profile-preview .contact-section-container {
  margin: 20px;
}
.ui.segment.todos-content .profile-focus {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100% - 64px);
  z-index: 10;
}
.ui.segment.todos-content .profile-focus .profile-focus-content {
  background-color: #f8f8f8;
  overflow-y: scroll;
  height: calc(100% - 64px);
}
.ui.segment.todos-content .profile-focus .profile-focus-content .profile-preview {
  padding: 12px 154px;
  align-items: flex-start;
}
.ui.segment.todos-content .profile-focus .profile-focus-content .profile-preview .contact-section-container {
  margin-top: 20px;
  margin-right: 20px;
}
