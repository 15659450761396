.app-sub-header {
  margin-left: 175px;
  margin-right: 70px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  align-items: center;
}
.app-sub-header .selector-and-steps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.app-sub-header .selector-and-steps.selector-and-steps--unified {
  gap: 12px;
}
.app-sub-header .selector-and-labels {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.app-sub-header .labels-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 4px;
  max-height: 57px;
  margin-right: 10px;
}
.app-sub-header .labels-container .offer-label {
  margin-bottom: 4px;
}
.app-sub-header .app-sub-header-steps {
  grid-column: 1;
  grid-row: 2;
}
.app-sub-header .app-sub-header-steps.app-sub-header-steps--unified {
  grid-column: 1 / span 2;
}
.app-sub-header .sub-header-settings {
  grid-column: 2;
  grid-row: 1 / span 2;
  display: flex;
  align-items: center;
}
.app-sub-header .sub-header-settings.sub-header-settings--unified {
  grid-row: 1;
}
