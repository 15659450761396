.dotContainer {
  display: inline;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.popupContent {
  z-index: 900 !important;
}
