/*
 * colors.less
 */
.container {
  display: inline-flex;
  align-items: stretch;
  position: relative;
}
.container.small {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.container.small .button {
  padding: 0 8px;
  gap: 4px;
}
.container.small .dropdownButton {
  padding: 0 8px;
}
.container.normal {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.container.normal .button {
  padding: 0 8px 0 16px;
}
.container.normal .dropdownButton {
  padding: 0 12px 0 8px;
}
.container.big {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.container .separator {
  display: inline-block;
  width: 1px;
}
.container .button {
  border-radius: 8px 0 0 8px;
}
.container .dropdownButton {
  border-radius: 0 8px 8px 0;
}
.container .button,
.container .dropdownButton {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;
  font-weight: 700;
  transition: color ease 150ms, background-color ease 150ms, border-color ease 150ms;
}
.container .button:disabled,
.container .dropdownButton:disabled {
  background-color: #b6c1e9;
}
.container .button:not(:disabled),
.container .dropdownButton:not(:disabled) {
  cursor: pointer;
}
.container.primary .separator {
  background-color: white;
}
.container.primary .button,
.container.primary .dropdownButton {
  color: white;
}
.container.primary.blue .button:not(:disabled),
.container.primary.blue .dropdownButton:not(:disabled) {
  background-color: #4864c9;
}
.container.primary.blue .button:not(:disabled):hover,
.container.primary.blue .dropdownButton:not(:disabled):hover {
  background-color: #3450b5;
}
.container.primary.red .button:not(:disabled),
.container.primary.red .dropdownButton:not(:disabled) {
  background-color: #f66f81;
}
.container.primary.red .button:not(:disabled):hover,
.container.primary.red .dropdownButton:not(:disabled):hover {
  background-color: #e45a6b;
}
.container.secondary .button,
.container.secondary .dropdownButton {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: currentColor;
}
.container.secondary .button:not(:disabled),
.container.secondary .dropdownButton:not(:disabled) {
  box-shadow: 0 2px 4px 0 #1f2e770f;
}
.container.secondary .button:disabled,
.container.secondary .dropdownButton:disabled {
  color: #b6c1e9;
}
.container.secondary .separator.disabled {
  background-color: #b6c1e9;
}
.container.secondary .button {
  border-right: none;
}
.container.secondary .dropdownButton {
  border-left: none;
}
.container.secondary.blue .separator:not(.disabled) {
  background-color: #4864c9;
}
.container.secondary.blue .button:not(:disabled),
.container.secondary.blue .dropdownButton:not(:disabled) {
  color: #4864c9;
}
.container.secondary.blue .button:not(:disabled):hover,
.container.secondary.blue .dropdownButton:not(:disabled):hover {
  color: #3450b5;
  background-color: #edf0fa;
}
.container.secondary.red .separator:not(.disabled) {
  background-color: #f66f81;
}
.container.secondary.red .button:not(:disabled),
.container.secondary.red .dropdownButton:not(:disabled) {
  color: #f66f81;
}
.container.secondary.red .button:not(:disabled):hover,
.container.secondary.red .dropdownButton:not(:disabled):hover {
  color: #e45a6b;
  background-color: #fff1f3;
}
.dropdownContent {
  display: none;
  position: absolute;
  top: calc(100% + 8px);
}
.dropdownContent.left {
  left: 0;
}
.dropdownContent.right {
  right: 0;
}
.dropdownContent.open {
  display: block;
}
.menu {
  left: calc(50% - 180px);
  max-height: 329.5px;
  overflow-y: auto;
  border: 1px solid #dae0f4;
  border-radius: 4px;
  box-shadow: 0px 2px 8px #1f2e7726;
  background-color: white;
}
.option {
  display: block;
  width: 100%;
  padding: 9.5px 12px;
  line-height: 20px;
  white-space: nowrap;
  font-family: Graphik;
  font-size: 12px;
  text-align: left;
  color: #4864c9;
  font-weight: 500;
}
.option:not(:first-child) {
  border-top: 1px solid #dae0f4;
}
.option:hover {
  background-color: #f6f7fc;
}
.option.disabled {
  background-color: #f6f7fc;
  color: #7982ad;
}
