/*
 * colors.less
 */
.dropzone-container {
  display: flex;
}
.dropzone-container.centered {
  justify-content: center;
}
.dropzone-container.drag-active .dropzone {
  border: 2px dashed #a5abc9;
}
.dropzone-container .dropzone {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4em 1em;
  border: 1px dashed #dfdfe6;
  border-radius: 2px;
}
.dropzone-container .dropzone p {
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: #1f2e77;
}
.dropzone-container .dropzone .spacer {
  flex: 0 1 32px;
}
.dropzone-container .dropzone .dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dropzone-container .button-select.ui > i:not(.button) {
  opacity: 1;
}
