/*
 * colors.less
 */
.previewContainer {
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.preview {
  max-height: 300px;
  overflow-y: auto;
}
.previewHeader {
  padding: 16px;
  border-bottom: 1px solid #dae0f4;
}
.previewHeaderTitle {
  display: flex;
  gap: 8px;
}
.mainDescriptionForComplexeCase {
  color: #1f2e77;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
}
.inputContainerComplexCase {
  margin-bottom: 30px;
}
.inputContainerComplexCase .inputLabel {
  margin-top: 25px;
  color: #1f2e77;
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}
.inputContainerComplexCase .inputDescription {
  color: #7982ad;
  font-family: Graphik;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}
.preview {
  padding: 16px;
  display: grid;
  gap: 10px 0px;
  grid-template-columns: 400px auto;
}
.preview :global(.name-and-headline-container) {
  display: flex;
  align-items: center;
  color: #4864c9;
  padding-right: 20px;
}
.preview :global(.full-name) {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.errorPreview {
  grid-template-columns: minmax(auto, max-content) minmax(auto, max-content) auto;
}
.errorPreview .cell:nth-last-child(-n+3) {
  border-bottom: none;
}
.error {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  flex-grow: 1;
  color: #ff6059;
  padding-left: 20px;
}
.cell {
  padding-top: 5px;
  padding-bottom: 5px;
}
.cell:nth-last-child(-n+2) {
  border-bottom: none;
}
.sequenceCellContainer :global(.sequence-title) {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  color: #4864c9;
}
.sequenceCellContainer :global(.row-sequence-container) {
  display: flex;
  justify-content: flex-end;
}
.sequenceCellContainer :global(.row-sequence-container) :global(.more-actions.hidden) {
  display: none;
}
.sequenceRow {
  width: unset !important;
  justify-content: flex-end;
}
