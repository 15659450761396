/*
 * colors.less
 */
.remoteSelector {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 20px 24px;
  padding: 8px 12px;
  column-gap: 8px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.remoteSelector:hover {
  border-color: #4864c9;
}
.remoteSelector .star {
  grid-row: 1 / span 2;
  grid-column: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  background: none;
  color: #a5abc9;
  border: 1px solid #dae0f4;
  border-radius: 32px;
}
.remoteSelector .star:not(:disabled) {
  cursor: pointer;
}
.remoteSelector .star:not(:disabled):hover {
  color: #4864c9;
  border-color: #4864c9;
}
.remoteSelector .star:disabled {
  color: #dae0f4;
}
.remoteSelector .star.active {
  color: white;
  background-color: #4864c9;
  border-color: #4864c9;
}
.remoteSelector .star.active:hover {
  color: white;
  background-color: #6d83d4;
}
.remoteSelector .location {
  grid-row: 1;
  grid-column: 2;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1f2e77;
}
.remoteSelector .remote {
  grid-row: 2;
  grid-column: 2;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 8px;
}
.remoteSelector .remote .label {
  grid-column: 1;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #7982ad;
}
.remoteSelector .remote .pills {
  grid-column: 2;
  white-space: nowrap;
  line-height: 24px;
}
.remoteSelector .remove {
  grid-row: 1 / span 2;
  grid-column: 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  background: none;
  border: none;
  color: #7982ad;
  cursor: pointer;
}
.remoteSelector .remove:hover {
  color: #4864c9;
}
.nudge {
  display: inline-block;
  margin-left: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 3px 8px;
  border: 1px solid #dae0f4;
  border-top: none;
  box-shadow: 0px 1px 2px 0px #1f2e770f;
}
.nudge .nudgeButton {
  display: inline;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-family: Graphik;
  font-size: 12px;
}
.nudge .nudgeButton:not(:disabled) {
  color: #4864c9;
  cursor: pointer;
}
.nudge .nudgeButton:not(:disabled):hover {
  text-decoration: underline;
  color: #1f2e77;
}
.nudge .nudgeButton:disabled {
  color: #4c5892;
}
