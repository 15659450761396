/*
 * colors.less
 */
.debounced-search-bar.ui.input i.icon.custom-dropdown-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rounded-input.ui.input input {
  border-radius: 32px;
}
