/*
 * colors.less
 */
.container {
  padding: 16px;
  margin-bottom: 16px;
  background-color: white;
  border: 1px solid #dfdfe6;
  border-radius: 4px;
}
.settings {
  display: flex;
  gap: 16px;
  align-items: center;
}
.settings .illustration {
  position: relative;
  padding: 12px;
  background-color: #f1f6ff;
  border-radius: 8px;
}
.settings .illustration .logo {
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: white;
  border-radius: 12px;
}
.settings .illustration .logo img {
  width: 100%;
}
.settings .illustration .count {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0 6px;
  background-color: #e2ebff;
  border: 2px solid white;
  border-radius: 20px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #4864c9;
}
.settings .main .title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #1f2e77;
}
.settings .main .description {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7982ad;
}
.settings .aside {
  margin-top: 8px;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: #e2ebff;
}
.settings .aside .usage {
  margin-right: 8px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #4864c9;
}
.settings .aside .update {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7982ad;
  display: inline-block;
}
.toggle {
  width: 28px !important;
}
