/*
 * colors.less
 */
.container {
  display: flex;
  cursor: pointer;
  text-align: initial;
}
.container .label {
  display: inline-block;
  margin-left: 8px;
  color: #4c5892;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik;
}
