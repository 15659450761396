.logoContainer {
  display: inline-block;
  vertical-align: middle;
  text-align: center;

  .animatedLogo {
    margin: -4px;
  }

  &.classic {
    margin-left: 22px;
    margin-right: -2px;
    width: 130px;
  }
}
