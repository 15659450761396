@import 'src/less/colors';

.linear-heatmap {
  display: flex;

  
  .heatmap-item {
    // negative margin for bigger hitbox on popup
    margin: -4px 5px -4px -4px;
    // padding to compensate
    padding: 4px;

    &:last-child {
      margin-right: -4px;
    }

    >div {
      height: 16px;
      width: 6px;
      border-radius: 8px;
    }

    .shade-0 {
      background-color: @PrimaryCobalt10;
    }

    .shade-1 {
      background-color: @SecondarySkyBlue40;
    }

    .shade-2 {
      background-color: @SecondarySkyBlue60;
    }

    .shade-3 {
      background-color: @SecondarySkyBlue80;
    }

    .shade-4 {
      background-color: @SecondarySkyBlue;
    }

    .shade-5 {
      background-color: @SecondarySkyBlue120;
    }
  }
}

.popup-header {
  font-size: 14px;
  font-weight: 500;
  color: @PrimaryCobalt;
}

.popup-content {
  color: @PrimaryCobalt60;
  font-weight: 500;

  .popup-content-count {
    color: @SecondaryBlue;
    font-size: 18px;
    font-family: Gilroy, sans-serif;
    font-weight: bold;
  }
}
