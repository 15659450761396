/*
 * colors.less
 */
.headLabel {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}
.headLabel > span:first-child {
  font-size: 14px;
  color: #1f2e77;
  font-family: 'Graphik';
  font-weight: 500;
}
.headLabel > span.timezoneToggle {
  font-size: 12px;
  color: #4864c9;
  font-family: 'Graphik';
  font-weight: 500;
  margin-left: auto;
  cursor: pointer;
}
.headLabel > span.timezoneToggle svg {
  scale: 1.5;
  margin-left: 5px;
}
.headLabel > span.timezoneToggle.up svg {
  rotate: 90deg;
}
.headLabel > span.timezoneToggle.down svg {
  rotate: -90deg;
}
.timezoneSelect {
  margin-bottom: 8px;
  margin-top: 10px;
}
.defaultTimeOption {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  cursor: pointer;
}
.defaultTimeOption.checked {
  border-color: #4864c9;
}
.defaultTimeOption:not(:last-child) {
  margin-bottom: 8px;
}
.defaultTimeOption .label {
  color: #1f2e77;
  font-size: 14px;
  font-family: 'Graphik';
  font-weight: 500;
  margin-left: 8px;
}
.defaultTimeOption .time {
  margin-left: auto;
  font-size: 14px;
  font-family: 'Graphik';
  color: #7982ad;
  font-weight: 400;
}
.timeInput {
  margin-left: auto;
}
.circleCheckbox {
  width: 22px;
  height: 22px;
  border-radius: 100%;
}
