@import 'src/less/colors';

.visual {
  position: absolute;
  inset: 0;
  background-color: @SecondaryBlue20;
}

.asterisk {
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 203px;
  color: @SecondaryBlue80;
}

.mockup {
  position: absolute;
  bottom: 0;
  left: 0;
}
