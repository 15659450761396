/*
 * colors.less
 */
.header {
  margin-bottom: 16px;
}
.header h1 {
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  color: #1f2e77;
  margin-bottom: 4px;
}
.header h2 {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #1f2e77;
  margin: 0;
}
