@import 'src/less/colors';

.cardHeader {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 600;
  color: @SecondaryBlue;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .placeholder {
    margin: 5px 0px 5px;
  }

  .avatarContainer {
    width: 40px;
    height: 40px;
    min-width: 40px;

    .avatar {
      width: 100%;
      height: 100%;
    }
  }

  .profileInfoContainer {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
  }

  .missionTitle {
    font-size: 12px;
    color: @PrimaryCobalt60;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cardBody {
  border-top: 1px solid @SecondarySkyBlue10;
  padding: 8px;
  font-size: 11px;

  .cardHeadline {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .placeholder {
    margin: 7px 0px;
  }
}

.cardCustomFieldsContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .remainingCustomFields {
    color: @PrimaryCobalt60;
  }
}

.cardCustomFieldsList {
  display: flex;
  gap: 2px;
}

.cardCustomField {
  flex-shrink: 0;
  height: 18px;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  background: @SecondaryBlue05;
  display: flex;

  &.customFieldHidden {
    visibility: hidden !important;
  }

  .customFieldTitle,
  .customFieldValue {
    //styleName: Body 12/Regular 400;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .customFieldTitle {
    color: @PrimaryCobalt;
    margin-right: 4px;
  }

  .customFieldValue {
    color: @PrimaryCobalt60;
  }
}
