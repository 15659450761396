/*
 * colors.less
 */
.job-posting-form-fields .job-posting-form-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 36px;
}
.job-posting-form-fields .job-posting-title-input {
  display: flex;
  flex: 1;
  max-width: 400px;
  font-size: 18px;
}
.job-posting-form-fields .job-posting-title-input input {
  font-weight: 600;
  color: #709dff !important;
}
.job-posting-form-fields .publication-status {
  margin-left: 16px;
}
.job-posting-form-fields .form-main-fields {
  margin-bottom: 60px;
}
.job-posting-form-fields .buttons-container {
  display: flex;
  justify-content: flex-end;
}
.job-posting-form-fields .organization-logo {
  margin-bottom: 24px;
}
