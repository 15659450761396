/*
 * colors.less
 */
.ui.modal.new-sender-modal.add-account .signin-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 20px auto;
  width: 350px;
}
.ui.modal.new-sender-modal.add-account .email-signin-button {
  border: 1px solid #dae0f4;
  text-align: left;
  border-radius: 8px;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 15px;
}
.ui.modal.new-sender-modal.add-account .email-signin-button span {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  margin-left: 20px;
}
.ui.modal.new-sender-modal.add-account .email-signin-button:not(.loader-button):hover {
  border: 1px solid #9da7d8;
  cursor: pointer;
}
.ui.modal.new-sender-modal.add-account .confidentiality-text {
  margin-top: 15px;
  font-size: 12px;
}
.ui.modal.new-sender-modal.add-account .loader-box {
  text-align: left;
}
.modal-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
