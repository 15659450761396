/*
 * colors.less
 */
.container {
  max-height: 250px;
  overflow-y: auto;
}
.category:not(:first-child) {
  margin-top: 1rem;
}
.urlInput {
  width: 100%;
  margin-bottom: 8px;
}
.useUrlContainer {
  text-align: right;
}
.categoryLabel {
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: #7982ad;
  margin-bottom: 8px;
}
.iconList {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(8, 1fr);
}
.taskIcon {
  max-width: 24px;
  max-height: 24px;
}
span.taskIcon {
  font-size: 24px;
  line-height: 24px;
}
span.taskIcon.small {
  display: inline-block;
  margin-right: 8px;
  font-size: 24px;
  line-height: 24px;
  margin-top: -5px;
  margin-bottom: -0.5em;
}
.clickable {
  cursor: pointer;
}
