/*
 * colors.less
 */
.offer {
  width: 100%;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #dae0f4;
  cursor: pointer;
}
.offer .favoriteButton {
  font-size: 24px;
  color: #d2d5e4;
}
.offer .favoriteButton:not(.own) {
  cursor: pointer;
}
.offer .favoriteButton.own {
  color: #ffbd2e;
}
.offer .favoriteButton.own .star {
  fill: #ffbd2e;
}
.offer .favoriteButton.favorite {
  color: #4864c9;
}
.offer .favoriteButton.favorite .star {
  fill: #4864c9;
}
.offer .info {
  flex: 1;
}
.offer .info .title {
  margin-bottom: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  color: #1f2e77;
}
.offer .info .labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.offer .extraColumn {
  width: 80px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.offer .extraColumn .count {
  cursor: pointer;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  color: #a5abc9;
}
.offer .extraColumn .count:hover {
  text-decoration: underline;
}
.offer .extraColumn:is(button) {
  cursor: pointer;
}
.offer .extraColumn:is(button):hover .count {
  text-decoration: underline;
}
.offer .extraColumn.notEmpty .count {
  color: #4864c9;
}
.offer .menu {
  padding: 0;
  text-align: left;
  width: 32px;
}
.hired {
  cursor: pointer;
  padding: 2px 8px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: #4864c9;
  background-color: #e2ebff;
  border-radius: 24px;
}
.hired:hover {
  text-decoration: underline;
}
