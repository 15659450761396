@import 'src/less/colors';

.filtersContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;

  h2 {
    font-family: Gilroy, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: @PrimaryCobalt;
  }

  .userContainer {
    min-width: 200px;
  }

  .selectedProjects {
    min-width: 350px;
    max-width: 350px;
  }

  .selectedStages {
    width: 350px;
  }
}
