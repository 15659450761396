@import 'src/less/colors';

.checkbox {
  display: inline-flex;
  vertical-align: text-top;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  font-size: 16px;
  flex-shrink: 0;
  border: 2px solid @PrimaryCobalt20;
  background-color: white;
  color: white;
  border-radius: 4px;
  user-select: none;

  &:is(button) {
    cursor: pointer;
  }

  &.big {
    width: 24px;
    height: 24px;
  }

  &.checked {
    border-color: @SecondaryBlue;
    background-color: @SecondaryBlue;
  }

  input {
    display: none;
  }
}
