@import 'src/less/colors';

.genericTitle {
  margin: 0;
  padding: 0;

  &.Gilroy {
    font-family: Gilroy;
  }

  &.Graphik {
    font-family: Graphik;
  }

  &:is(h5) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
  }

  &.cobalt {
    color: @PrimaryCobalt;
  }
}
