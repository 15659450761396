@import 'src/less/colors';

.page {
  grid-column: 1 / span 2;
  padding: 40px 60px;
}

.emptyState {
  padding: 124px 80px;
  background-color: white;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  .loader {
    font-size: 110px;
    text-align: center;
  }

  .message {
    margin-top: 16px;
    font-family: Gilroy;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
    color: @PrimaryCobalt;
  }
}
