/*
 * colors.less
 */
.followup-button {
  padding: 0;
  margin-top: 24px;
  text-align: left;
}
.followup-button .followup-button-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #7982ad;
}
.followup-button .followup-button-header span {
  line-height: 20px;
}
.followup-button i.ri-ghost-smile-line {
  font-size: 18px;
  color: #a5abc9;
  margin: 0 8px;
}
.followup-button .followup-button-explanation {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  color: #f66f81;
  margin-top: 6px;
  margin-left: 30px;
}
