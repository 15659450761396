/*
 * colors.less
 */
.smart-tags-container .smart-tag-line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #4c5892;
  line-height: 20px;
}
.smart-tags-container .smart-tag-line:not(:last-child) {
  margin-bottom: 10px;
}
.smart-tags-container .smart-tag-line.reduced {
  flex-wrap: nowrap;
}
.smart-tags-container .smart-tag-line.reduced .smart-tag-line-value {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.smart-tags-container .smart-tag-line.reduced .smart-tag-line-value span {
  white-space: nowrap;
}
.smart-tags-container .smart-tag-line span {
  white-space: nowrap;
}
.smart-tags-container .smart-tag-line .smart-tag-line-prefix {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 16px;
  border-radius: 50%;
}
.smart-tags-container .smart-tag-line .smart-tag-line-prefix i {
  display: block;
  color: #1f2e77;
}
.smart-tags-container .smart-tag-line .smart-tag-line-value {
  margin-left: -3px;
  padding: 0 3px;
  border-radius: 2px;
  font-weight: 600;
}
.smart-tags-container .smart-tag-line .smart-tag-line-value span {
  white-space: normal;
}
.smart-tags-container .smart-tag-line .smart-tag-line-value.ellipsis {
  cursor: pointer;
}
.smart-tags-container .smart-tag-line .smart-tag-line-value.ellipsis:hover {
  background-color: #f6f7fc;
}
.smart-tags-container .smart-tag-line .smart-tag-line-suffix {
  flex-shrink: 0;
  padding: 4.5px 6px;
  margin-left: 12px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
}
.smart-tags-container .smart-tag-line .smart-tag-line-suffix img {
  height: 10px;
  width: 10px;
  display: inline-block;
}
pre.smart-tag-tooltip-content {
  font-family: Graphik;
  margin: 0;
  padding: 0;
  font-size: inherit;
}
