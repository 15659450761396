/*
 * colors.less
 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.header .headerTitleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.header .headerTitleContainer h4 {
  margin: 0;
}
.header .headerTitleContainer i {
  color: white;
}
.header .headerTitleContainer i.whatsapp:before {
  background: #5bb451;
}
.header .headerTitleContainer i.linkedin:before {
  background: #4680d7;
}
.header .headerTitleContainer i:before {
  border-radius: 3px;
  padding: 3px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.checkboxContainer .checkboxLabel {
  color: #1f2e77;
}
.formContainer {
  margin-top: 12px;
}
.infoBox {
  margin: 8px 0px;
}
.dropdownOption {
  display: flex;
  gap: 8px;
  align-items: center;
}
.dropdownOption .dropdownIconContainer {
  border-radius: 3px;
  padding: 1px;
  text-align: center;
  width: 18px;
  height: 18px;
}
.dropdownOption .dropdownIconContainer i {
  line-height: 16px;
}
.textArea {
  resize: none !important;
}
