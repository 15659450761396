/*
 * colors.less
 */
.settings.slack-integration-page .ui.button.slack-button {
  background-color: white;
  border: solid 1px #dfdfe6;
  border-radius: 6px;
  padding: 8px 10px;
}
.settings.slack-integration-page .ui.button.slack-button .provider-logo {
  width: 23px;
  margin-right: 8px;
}
.settings.slack-integration-page .ui.button.slack-button .button-text {
  font-size: 15px;
  color: black;
}
.settings.slack-integration-page .strong {
  font-weight: 600;
}
