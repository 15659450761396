@import 'src/less/colors';

.warning {
  margin: 8px 0;
  padding: 7.5px 12px;

  border-radius: 3px;
  border-left: 4px solid #FFBD2E;
  background-color: #FFF2D5;

  font-size: 12px;
  font-family: Graphik;

  .title {
    font-weight: 500;
    color: @PrimaryCobalt;
    margin-right: 4px;
  }

  .description {
    color: @PrimaryCobalt80;
  }
}
