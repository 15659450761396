/*
 * colors.less
 */
.cardHeader {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 600;
  color: #4864c9;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.cardHeader .placeholder {
  margin: 5px 0px 5px;
}
.cardHeader .avatarContainer {
  width: 40px;
  height: 40px;
  min-width: 40px;
}
.cardHeader .avatarContainer .avatar {
  width: 100%;
  height: 100%;
}
.cardHeader .profileInfoContainer {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
}
.cardHeader .missionTitle {
  font-size: 12px;
  color: #7982ad;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardBody {
  border-top: 1px solid #f1f6ff;
  padding: 8px;
  font-size: 11px;
}
.cardBody .cardHeadline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.cardBody .placeholder {
  margin: 7px 0px;
}
.cardCustomFieldsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cardCustomFieldsContainer .remainingCustomFields {
  color: #7982ad;
}
.cardCustomFieldsList {
  display: flex;
  gap: 2px;
}
.cardCustomField {
  flex-shrink: 0;
  height: 18px;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  background: #f6f7fc;
  display: flex;
}
.cardCustomField.customFieldHidden {
  visibility: hidden !important;
}
.cardCustomField .customFieldTitle,
.cardCustomField .customFieldValue {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.cardCustomField .customFieldTitle {
  color: #1f2e77;
  margin-right: 4px;
}
.cardCustomField .customFieldValue {
  color: #7982ad;
}
