/*
 * colors.less
 */
.mini-profiles-container {
  margin-left: 0.3em;
  height: 25px;
  display: flex;
}
.mini-profile-bubble {
  box-sizing: content-box;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 25px / 2 + 2px;
  border: 1px solid transparent;
}
.counter {
  position: relative;
  box-sizing: content-box;
  height: 25px;
  width: 25px;
  min-width: 25px;
  border-radius: 25px / 2 + 2px;
  border: 1px solid #606163;
}
.counter .counter-inner {
  position: absolute;
  top: 3px;
  width: 25px;
  font-size: 9px;
  color: #606163;
}
.mini-profile-bubble-popup-content .profile-name {
  font-size: 16px;
  font-weight: 600;
  font-family: Gilroy;
  color: #1f2e77;
}
.mini-profile-bubble-popup-content .profile-headline {
  font-size: 14px;
  opacity: 0.5;
}
