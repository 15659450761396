/*
 * colors.less
 */
.settingTab,
.more-contacts-view {
  margin: 8px 40px;
  display: flex;
  flex-direction: row;
}
.settingTab .menu:not(.pagination),
.more-contacts-view .menu:not(.pagination) {
  width: 240px;
  margin-right: 2em;
  flex-shrink: 0;
}
.settingTab .menu:not(.pagination) .reset-all-button,
.more-contacts-view .menu:not(.pagination) .reset-all-button {
  margin-bottom: 1em !important;
  width: 100%;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item),
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) {
  padding: 0;
  width: 100%;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink {
  width: 100%;
  height: 40px;
  margin-right: 0;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  text-align: left;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink .ui.label,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink .ui.label {
  height: 16px;
  text-align: center;
  line-height: 19px;
  padding: 0px 4px 0px 4px;
  float: right;
  background-color: #e2ebff;
  color: #4864c9;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink:hover,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink:hover {
  background-color: #f8faff;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink .menu-icon,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink .menu-icon {
  margin-right: 5px;
  margin-left: -1px;
  color: #5c89eb;
  vertical-align: bottom;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active {
  background-color: #4864c9;
  color: white;
  border: 1px solid #4864c9;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active:hover,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active:hover {
  background: #3450b5;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active .menu-icon,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active .menu-icon {
  color: white;
}
.settingTab .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active .ui.label,
.more-contacts-view .menu:not(.pagination) .item:not(.editor-toolbar-item) .settingLink.active .ui.label {
  background-color: white;
}
.settingTab .contentTab,
.more-contacts-view .contentTab {
  flex-grow: 1;
  min-width: 0px;
  color: #1f2e77;
}
.settingTab .contentTab .candidates-list-pane,
.more-contacts-view .contentTab .candidates-list-pane {
  width: 100% !important;
}
.settingTab .contentTab .pure-criteria-form,
.more-contacts-view .contentTab .pure-criteria-form {
  border-radius: 4px;
}
.settingTab .contentTab .pure-criteria-form .settings-divider,
.more-contacts-view .contentTab .pure-criteria-form .settings-divider {
  margin: 2.5em 0em 2em;
}
.settingTab .contentTab .pure-criteria-form .divider:empty,
.more-contacts-view .contentTab .pure-criteria-form .divider:empty {
  margin: 2.5em 0em 2.5em;
}
.settingTab .contentTab .pure-criteria-form h3,
.more-contacts-view .contentTab .pure-criteria-form h3 {
  margin-bottom: 1em;
}
.settingTab .contentTab .pure-criteria-form h3:first-of-type,
.more-contacts-view .contentTab .pure-criteria-form h3:first-of-type {
  margin-top: 0;
}
.settingTab .contentTab .form-field-label,
.more-contacts-view .contentTab .form-field-label {
  color: #1f2e77;
}
.settingTab .contentTab .save-button,
.more-contacts-view .contentTab .save-button {
  position: absolute !important;
  right: 24px !important;
  top: 24px !important;
}
