/*
 * colors.less
 */
.lineIcon {
  padding: 10px;
  background: #f6f7fc;
  border-radius: 8px;
  height: 56px;
  width: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.emptyState {
  padding: 16px;
  color: #1f2e77;
  background-color: white;
  border: 1px solid #dae0f4;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  margin-top: 14px;
}
.contractLine {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 16px;
}
.contractLine:not(:last-child) {
  margin-bottom: 16px;
}
.contractLine .contractLineInfo > span {
  display: block;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.contractLine .contractLineInfo span:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
  line-height: 19.6px;
}
.contractLine .contractLineInfo span:not(:first-child) {
  color: #7982ad;
}
.contractLine .contractLineInfo span.contractNameLegalId {
  font-size: 14px;
  font-weight: 400;
  color: #7982ad;
}
