/*
 * colors.less
 */
.dynamicVariablesWrapper {
  position: relative;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.dynamicVariablesWrapperPlugin {
  padding: 7px;
}
.variableInfo {
  padding: 1px 4px;
  background: #ffffff;
  border-radius: 12px;
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 12px;
  color: #4864c9;
  margin: 0 2px;
}
.sequenceLockPopup {
  width: 300px;
  padding: 15px;
}
.sequenceLockPopup .title {
  font-family: 'Graphik';
  font-weight: 600;
  font-size: 12px;
  color: #4c5892;
}
.sequenceLockPopup .input {
  margin-top: 10px;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
  width: 100%;
  border: 1px solid #dae0f4;
  padding: 11px 1em;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 16px;
  border-radius: 2px;
  resize: none;
}
.sequenceLockPopup .buttons {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.sequenceLockPopup .buttons .customFieldCheckbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.sequenceLockPopup .buttons .customFieldCheckbox .checkboxLabel {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  color: #1f2e77;
}
.sequenceLockPopup .buttons .button {
  padding: 0 !important;
  background: none !important;
}
.sequenceLockPopup .buttons .submitButton {
  color: #4864c9;
}
.sequenceLockPopup .buttonsSpaceBetween {
  justify-content: space-between;
}
.sequenceLockPopup .preview {
  margin-top: 15px;
}
.sequenceLockPopup .preview .previewOutput {
  padding: 16px;
  background: #f1f6ff;
  border-radius: 8px;
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: #4864c9;
  display: flex;
  gap: 5px;
  align-items: center;
}
.sequenceLockPopupPlugin {
  width: 210px;
}
.sequenceFallbackPopup {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.sequenceFallbackPopup .form {
  display: flex;
  align-items: center;
}
.sequenceFallbackPopup .title {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  display: flex;
  gap: 2px;
  align-items: center;
  color: #1f2e77;
  padding: 0px 10px;
}
.sequenceFallbackPopup .title .image {
  width: 14px;
}
.sequenceFallbackPopup .button {
  background: none !important;
}
.sequenceFallbackPopup .submitButton {
  color: #4864c9;
}
.sequenceFallbackPopup .editButton {
  color: #4864c9;
  border-left: 1px solid #dae0f4 !important;
}
.sequenceFallbackPopup .fallbackInput:global(.ui.input > input) {
  border: none !important;
  border-left: 1px solid #dae0f4 !important;
}
