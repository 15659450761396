@import 'src/less/colors';

.pagination {
  display: inline-flex;

  .paginationButton {
    height: 32px;
    min-width: 32px;
    border-top: 1px solid @SecondaryBlue20;
    border-bottom: 1px solid @SecondaryBlue20;
    border-right: 1px solid @SecondaryBlue20;
    color: @PrimaryCobalt60;
    user-select: none;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    &:not(:disabled) {
      cursor: pointer;
      &:hover {
        background-color: @SecondarySkyBlue05;
      }
    }

    &:disabled {
      color: @SecondaryBlue20;
    }

    &.active {
      background-color: @SecondaryBlue;
      color: white;

      &:not(:disabled):hover {
        background-color: @SecondaryBlue120;
      }
    }

    &.previous {
      border-left: 1px solid @SecondaryBlue20;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.next {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
