@import 'src/less/colors';

.tooltip {
  background-color: @SecondaryDark !important;
  color: white !important;
  font-family: Graphik !important;
  box-shadow: none !important;
  border: none !important;

  &:not(.rich) {
    padding: 8px !important;
    border-radius: 4px !important;
    max-width: 240px !important;
    font-size: 12px !important;
    text-align: center;
  }

  &.rich {
    min-width: 300px;
    padding: 12px !important;
    border-radius: 8px !important;
  }
}
