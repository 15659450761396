.mission-settings .mission-settings-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.mission-settings .mission-settings-fields .form-box {
  width: auto;
  flex-grow: 1;
  padding: 1em 1em 0em;
}
.mission-settings .mission-settings-fields .form-box:first-of-type {
  padding-left: 0;
}
.mission-settings .mission-settings-padding {
  padding-top: 30px;
}
.mission-settings .button-with-icon {
  line-height: 13px;
}
.mission-settings .button-with-icon .btn-icon {
  margin-right: 5px;
  vertical-align: bottom;
}
.mission-settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mission-settings-header h2 {
  margin: 0;
}
.mission-targets-container,
.mission-departments-container {
  max-width: 418px;
  margin: 2rem 0;
}
