.user-thumbnail {
  position: relative;
}
.user-thumbnail .image {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border: none;
  border-radius: 20px;
}
.user-thumbnail .image.initials {
  background: #fbfbfb;
}
.user-thumbnail .image.initials .initials-inner-text {
  width: 100%;
  height: 100%;
  padding-top: 11px;
  padding-left: 1px;
  text-align: center;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 15px;
  color: #2b3d4f;
  letter-spacing: 0;
}
.user-thumbnail .image.initials .initials-inner-text i {
  font-weight: 500;
}
.user-thumbnail .image.photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
