/*
 * colors.less
 */
.pill-message {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px 4px;
  display: inline-block;
}
.pill-message:not(.mini):not(.equal-padding) {
  padding: 4px 8px 4px 4px !important;
}
.pill-message.no-margin {
  margin: 0 !important;
}
.pill-message.plugin {
  padding: 2px 8px 2px 4px !important;
}
.pill-message i {
  margin: 0px 5px 1px 0px;
  vertical-align: -2px;
  font-size: initial !important;
}
.ui.popup.profileViews-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.1);
  border-radius: 4px;
}
.ui.popup.profileViews-popup .view-header-row {
  padding: 12px;
}
.ui.popup.profileViews-popup .view-row {
  padding: 8px 12px;
}
.ui.popup.profileViews-popup .view-row,
.ui.popup.profileViews-popup .view-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-shadow: inset 0px -1px 0px #dae0f4;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.ui.popup.profileViews-popup .view-row .name,
.ui.popup.profileViews-popup .view-header-row .name,
.ui.popup.profileViews-popup .view-row .title,
.ui.popup.profileViews-popup .view-header-row .title {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.ui.popup.profileViews-popup .view-row .date,
.ui.popup.profileViews-popup .view-header-row .date {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.ui.popup.profileViews-popup .view-row .view-author-image,
.ui.popup.profileViews-popup .view-header-row .view-author-image {
  width: 24px;
  height: 24px;
}
