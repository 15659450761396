/*
 * colors.less
 */
.offers {
  margin-bottom: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.offers .header {
  height: 52px;
  padding: 0 16px;
  display: flex;
  grid-template-columns: 1fr 184px 380px;
  align-items: center;
  gap: 12px;
}
.offers .header .title {
  flex: 1;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1f2e77;
}
.offers .header .column {
  text-align: left;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #7982ad;
}
.offers .header .column.main {
  width: 160px;
  text-align: left;
}
@media (max-width: 1400px) {
  .offers .header .column.main {
    width: 100px;
  }
}
.offers .header .column.extra {
  width: 80px;
  text-align: center;
}
.offers .header .menu {
  width: 32px;
}
