/*
 * colors.less
 */
.notifications-timeline-item {
  display: flex;
  flex-direction: row;
}
.notifications-timeline-item .notification-icon-container {
  position: relative;
}
.notifications-timeline-item:not(:last-child) {
  margin-bottom: 24px;
}
.notifications-timeline-item:not(:last-child) .notification-icon-container:after {
  border-left: 2px solid #e9ebf2;
  content: "";
  position: absolute;
  top: 24px;
  left: 24px / 2;
  bottom: -24px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.notifications-timeline-item .mini-profile-bubble {
  margin: 0 16px 0 0;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
}
.notifications-timeline-item .notification-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.notifications-timeline-item .notification-action {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #d2d5e4;
  font-weight: 600;
  margin-right: 8px;
}
.notifications-timeline-item .profile-name {
  font-weight: 500;
  color: #6d83d4;
}
.notifications-timeline-item .notification-details {
  font-weight: 500;
}
.notifications-timeline-item .notification-details a {
  color: #4c5892;
}
.notifications-timeline-item .notification-date {
  color: #a5abc9;
}
