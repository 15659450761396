.preview {
  display: inline-grid;
  align-items: center;
  column-gap: 0.6em;
  row-gap: 0.2em;
  grid-template-columns: auto auto;
}
.image {
  grid-row: 1;
  grid-column: 1;
}
.image:is(img) {
  width: 1em;
  height: 1em;
}
.image.extended {
  grid-row: 1 / span 2;
}
.title {
  grid-row: 1;
  grid-column: 2;
  line-height: 1em;
}
.url {
  grid-row: 2;
  grid-column: 2;
  line-height: 1em;
}
