@import 'src/less/colors';

.root {
  position: relative;
  z-index: 0;
  padding: 0px 12px;

  &.minimized {
    padding: 0 4px;

    .block {
      margin: 4px 0;
    }
  }
}

.block {
  margin: 10px 0px;

  h4 {
    color: @PrimaryCobalt;
    margin-bottom: 0px;

    .headerLabel {
      margin-left: 8px !important;
      padding: 3px 4px 1px;
      color: @SecondaryBlue;
      background-color: @SecondarySkyBlue20;
    }

    .ui.label {
      margin-left: 8px;
      padding: 3px 4px 1px;
      color: @SecondaryBlue;
      background-color: @SecondarySkyBlue20;
    }
  }
}

.noPendingAction {
  padding: 8px;
  background-color: white;
  border: 1px solid @SecondaryBlue20;

  h4 {
    margin-bottom: 4px;
    text-align: center;
    font-family: Graphik;
    font-weight: 500;
    font-size: 12px;
    color: @PrimaryCobalt;
  }

  p {
    text-align: center;
    font-family: Graphik;
    font-size: 12px;
    color: @PrimaryCobalt60;
  }
}
