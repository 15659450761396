@import 'src/less/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 26px;
    color: @PrimaryCobalt;
  }
}

.modalContentContainer {
  padding: 0 !important;
}

.description {
  color: @PrimaryCobalt60;
  margin-bottom: 20px;
}

.fieldHeader {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: @PrimaryCobalt;
  margin-bottom: 5px;

  span {
    margin-bottom: -3px;
  }
}

.toggle {
  margin-bottom: 0px;
  width: 2.5rem;
}

.modalForm {
  width: 100%;

  .modalContent {
    display: flex;
    max-height: 77svh;
    overflow: hidden;

    .formContentContainer {
      padding: 24px;
    }

    .previewContainer,
    .formContent {
      flex: 1 1;
      overflow: auto;
    }
  }

  .modalFormField {
    margin: 25px 0px !important;

    &:first-of-type {
      margin-top: 0px !important;
    }
  }

  .modalFormInput {
    margin-top: 0px;
    border-radius: 8px !important;
    display: block;
    box-shadow: 0px 2px 4px @PrimaryCobaltOp06 !important;
    input {
      border-radius: 8px !important;
      border: 1px solid @SecondaryBlue20 !important;
      width: 100% !important;
    }
  }

  .optionWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .optionInput {
    margin: 5px 0px;
    flex: 1;
    input {
      border: 1px solid @SecondaryBlue20;
      box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
      border-radius: 8px;
    }
  }

  .modalActions {
    padding: 16px 24px;
    border-top: 1px solid @SecondaryBlue20;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }

  .conditionsChainingForm {
    display: flex;
    width: 100%;
    gap: 20px;

    .left {
      flex: 1;
    }
    .right {
      flex: 0.4;
      background: #f3f4f8;
    }

    .padding {
      padding: 12px 24px 24px 24px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  :global(.ui.modal).customActionFormModal {
    width: 1200px;
  }
}
