/*
 * colors.less
 */
.flexBetween {
  justify-content: space-between;
}
.column {
  flex-direction: column;
  align-items: flex-start !important;
}
.contactout {
  margin: 12px 0;
  display: flex;
  align-items: center;
  border: 1px solid #dae0f4;
  border-radius: 3px;
  padding: 5px;
  gap: 20px;
}
.contactout .iconWrapper {
  padding-left: 10px;
}
.contactout .icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.contactout .label {
  color: #1f2e77;
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 700;
}
.contactout .noResults {
  color: #7982ad;
  font-family: 'Graphik';
  font-size: 12px;
}
.contactout .button {
  margin-right: 10px;
}
.contactout .contactoutResult h5 {
  margin-bottom: 5px;
}
.contactout .flexWrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex: 0.9;
}
.contactout .flexWrap .flexItem {
  font-size: 12px;
  font-family: 'Graphik';
  font-weight: 400;
  color: #4864c9;
  text-decoration: underline;
  cursor: pointer;
  overflow-wrap: anywhere;
}
.contactout.minimized {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 4px;
  padding: 8px;
  row-gap: 4px;
  column-gap: 0;
  justify-content: space-between;
  align-items: center;
}
.contactout.minimized .iconWrapper {
  grid-row: 1;
  grid-column: 1;
  min-width: unset;
  white-space: nowrap;
}
.contactout.minimized .iconWrapper .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.contactout.minimized .iconWrapper .label {
  font-size: 12px;
  font-family: Graphik;
  font-weight: 400;
  color: #7982ad;
}
.contactout.minimized .noResults {
  text-align: right;
}
.contactout.minimized .expand {
  grid-row: 1;
  grid-column: 2;
  padding: 0;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  font-family: Graphik;
  font-weight: 500;
  color: #4864c9;
  cursor: pointer;
}
.contactout.minimized .expand:hover {
  text-decoration: underline;
}
.contactout.minimized .expand i {
  margin-left: 2px;
  line-height: normal;
}
.contactout.minimized .contactoutResult {
  grid-column: 1 / span 2;
  padding-top: 4px;
  border-top: 1px solid #edf0fa;
}
.plugin {
  background-color: white;
  padding: 12px;
  margin: 10px 0;
  border-radius: 8px;
}
.plugin .iconWrapper {
  align-self: center;
  padding-left: 0px;
}
.plugin .button {
  align-self: center;
}
.quota {
  color: #7982ad;
  font-size: 12px;
  font-family: 'Graphik';
  font-weight: 500;
  margin-left: 8px;
  display: inline-block;
}
.quota.quotaPlugin {
  display: block;
}
