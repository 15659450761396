/*
 * colors.less
 */
.pill {
  display: inline-flex;
  vertical-align: middle;
  gap: 4px;
  align-items: center;
  padding: 1px 8px;
  font-weight: 500;
  font-size: 12px;
  background-color: #28ca421a;
  color: #14b62e;
  border-radius: 20px;
}
