/*
 * colors.less
 */
.viewport {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
}
.header {
  height: 64px;
  grid-row: 1;
  z-index: 1;
  display: flex;
  position: relative;
  gap: 24px;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
  background-color: #1f2e77;
}
.logo {
  font-size: 32px;
}
.logoMain {
  color: white;
}
.headline {
  flex: 1;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: white;
}
.ctas {
  display: flex;
  gap: 8px;
}
.main {
  grid-row: 2;
  z-index: 0;
  position: relative;
  min-height: 0;
}
.demoModal {
  padding: 16px;
}
.demoModal .modalControls {
  text-align: right;
  margin-top: 16px;
}
