/*
 * colors.less
 */
.sequence-view .funnel-kpi {
  margin-bottom: 16px;
}
.sequence-view .sequences-header {
  height: 72px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  padding: 0 40px;
  background-color: white;
  border-bottom: 1px solid #dae0f4;
}
.sequence-view .sequences-header .sequence-view-breadcrumb {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
}
.sequence-view .sequences-header .sequence-view-breadcrumb .sequence-view-breadcrumb-title {
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  color: #1f2e77;
  white-space: nowrap;
}
.sequence-view .sequences-header .sequence-view-breadcrumb .sequence-view-breadcrumb-title.collapsible {
  text-overflow: ellipsis;
  overflow: hidden;
}
.sequence-view .sequences-header .sequence-view-breadcrumb .sequence-view-breadcrumb-divider {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  color: #1f2e77;
}
.sequence-view .sequences-content {
  margin: 24px 40px;
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
