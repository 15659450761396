/*
 * colors.less
 */
.title {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  gap: 4px;
  padding-top: 16px;
  padding-bottom: 12px;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid #d2d5e4;
  color: #1f2e77;
  cursor: pointer;
}
.title:hover {
  background-color: #f6f7fc;
}
