/*
 * colors.less
 */
.title {
  display: flex;
  font-family: Gilroy;
  font-weight: 700;
  color: #1f2e77;
  font-size: 14px;
  margin-top: 12px;
  cursor: pointer;
}
