/*
 * colors.less
 */
.alert {
  margin: 8px 0;
  padding: 7.5px 12px;
  border-radius: 3px;
  border-left: 4px solid #f66f81;
  font-size: 12px;
  font-family: Graphik;
}
.alert.error {
  background-color: #fff1f3;
}
.alert.warning {
  border-color: #ffbd2e;
  background-color: #fff9ed;
}
.alert.info {
  border-color: #4864c9;
  background-color: #edf0fa;
}
.alert.flex {
  display: flex;
  align-items: center;
  gap: 24px;
}
.alert .title {
  font-weight: 500;
  color: #1f2e77;
  margin-right: 4px;
}
.alert .description {
  color: #4c5892;
}
