.ui.dropdown > .text > .description,
.ui.dropdown .menu > .item > .description {
  color: #7982ad;
  font-weight: 400;
}
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown:hover .menu {
  border-color: #dae0f4 !important;
}
.editor-container-no-padding {
  padding: 0px !important;
}
