/*
 * colors.less
 */
.sequences-tab .funnel-kpi {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.sequences-tab .statistics-title {
  color: #1f2e77;
}
.sequences-tab .sequences-cohorts-graph {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  margin-top: 16px;
}
.sequences-tab .sequences-cohorts-graph .graph {
  width: 100%;
  padding: 35px 20px 20px 15px;
}
.sequences-tab .sequences-cohorts-graph .graph-empty {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sequences-tab .sequences-cohorts-graph .graph-empty-text {
  font-size: 15px;
  opacity: 0.3;
}
.sequences-tab .sequences-cohorts-graph .loader {
  margin: 100px auto;
}
.sequences-tab .sequences-cohorts-graph .sequences-graph-header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #dae0f4;
}
.sequences-tab .sequences-cohorts-graph .sequences-graph-header .sequences-graph-title {
  color: #1f2e77;
  margin: 0;
}
