@import 'src/less/colors';

.textarea {
  padding: 12px;
  border: 1px solid @SecondaryBlue20;
  background-color: white;
  border-radius: 8px;

  font-family: Graphik;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: @PrimaryCobalt;

  outline: none;

  &:not(:disabled) {
    box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;

    ::placeholder {
      color: @PrimaryCobalt40;
    }
  }

  &:disabled {
    background-color: @SecondaryBlue05;
    color: @PrimaryCobalt40;

    ::placeholder {
      color: @PrimaryCobalt20;
    }
  }

  &:focus {
    border-color: @SecondarySkyBlue60;
    box-shadow: inset 0px 0px 1px 1px @SecondarySkyBlue60;
  }
}
