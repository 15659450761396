/*
 * colors.less
 */
.content {
  padding: 0 !important;
}
.form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.form .left {
  flex: 1;
}
.form .right {
  flex: 0.4;
  background: #f3f4f8;
}
.form .rightBig {
  flex: 0.6;
}
.form .padding {
  padding: 12px 24px 24px 24px;
}
.form .marginTop {
  margin-top: 23px !important;
}
.form .input input {
  border-radius: 8px !important;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
}
.form .select {
  border-radius: 8px !important;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
}
.form .formatOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.form .formatOptions .customButton {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  color: #4c5892;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
  flex: 1;
}
.form .formatOptions .buttonActive {
  border: 2px solid #4864c9;
  background: #f1f6ff;
  color: #1f2e77;
  font-weight: 500;
}
.form .infoAlert {
  margin-top: 30px;
  background: #f1f6ff;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  color: #4864c9;
}
.form .infoAlert .bold {
  font-weight: 500;
}
.examples {
  max-height: 400px;
  overflow-y: scroll;
}
.examples::-webkit-scrollbar {
  display: none;
}
.examples .examplesTitle {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 16px;
  color: #4864c9;
}
.examples .example {
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  padding: 16px;
}
.examples .example .subtitle {
  display: block;
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  color: #7982ad;
  margin-bottom: 5px;
}
.examples .example .text {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  color: #1f2e77;
}
