/*
 * colors.less
 */
.title {
  margin-bottom: 10px;
  color: #1f2e77;
  margin-bottom: 0px;
}
.addProjectButton {
  cursor: pointer;
  width: fit-content;
  margin: 12px 0 0 0;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #7982ad;
  font-family: 'Gilroy', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
}
.emptyState {
  margin: 8px 0;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #edf0fa;
  text-align: center;
  color: #7982ad;
}
.card {
  margin: 8px 0;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #edf0fa;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  transition: border-color 150ms;
  display: flex;
  align-items: center;
}
.card:hover {
  border-color: #91a2df;
}
.archived {
  filter: opacity(0.5);
  font-weight: 400;
}
.cardTitle {
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
}
.jobPopupContainer {
  padding: 12px;
  width: 600px;
}
.jobPopupTitle {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 18px;
  color: #4864c9;
  line-height: 20px;
}
.linkProjectContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.linkButton {
  height: 40px;
}
