/*
 * colors.less
 */
.search-pool-profile-actions {
  gap: 8px;
}
.search-pool-profile-actions .skip-button.ui.basic.secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  line-height: 20px;
  box-shadow: 0px 0px 0px 1px #e8e8e8 inset !important;
}
.search-pool-profile-actions .skip-button.ui.basic.secondary i.ri-close-line {
  font-size: 16px;
  margin-right: 0.5em;
}
.search-pool-profile-actions .skip-button.ui.basic.secondary i.ri-arrow-go-back-line {
  font-size: 13px;
  margin-right: 0.5em;
}
.search-pool-profile-actions .skip-button.ui.basic.secondary:hover {
  box-shadow: 0px 0px 0px 1px #f66f81 inset !important;
  background-color: #fff1f3 !important;
}
.search-pool-profile-actions .review-profile-button.ui.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  line-height: 20px;
  box-shadow: 0px 0px 0px 1px #e8e8e8 inset !important;
  white-space: nowrap;
  margin: 0 6px;
}
.search-pool-profile-actions .review-profile-button.ui.button i.ri-check-line {
  font-size: 16px;
  margin-right: 0.5em;
}
.search-pool-profile-actions .review-profile-button.ui.button:hover {
  background-color: #edf0fa !important;
}
.add-to-job-button {
  margin-left: 12px;
}
