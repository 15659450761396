/*
 * colors.less
 */
.copyToClipboardWrapper {
  display: flex;
  justify-content: flex-end;
}
.copyToClipboardWrapper .copyToClipboard {
  border-radius: 2px;
  border: 1px solid #dae0f4;
  background-color: #ffffff !important;
  color: #7982ad !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}
.copyToClipboardWrapper .copyToClipboard.minimized {
  font-size: 12px;
  padding: 1px 2px;
}
.copyToClipboardWrapper .textToCopy {
  cursor: pointer;
}
.copyToClipboardWrapper .hiddenCopy {
  position: absolute;
  top: -999999999999px;
  left: -999999999999px;
}
