@import 'src/less/colors';

.table {
  margin-bottom: 12px;
  border: 1px solid @SecondaryBlue20;
  border-radius: 3px;

  .pagination {
    display: block;
    width: 100%;
    text-align: right;
    padding: 8px;
  }
}

.task {
  flex: 1 0;
}

.avatar {
  flex: 0 0;
  min-width: 32px;
  margin-left: 16px;
  margin-right: 8px;
}

.nameAndHeadline {
  flex: 1 0;
}
