@import 'src/less/colors.less';

.atsDataCard {
  padding: 12px;
  background-color: white;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  width: 100%;

  h3 {
    width: 100%;
    display: flex;
    align-items: center;
    color: @PrimaryCobalt;
    font-size: 14px;
    margin-bottom: 0;

    img {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      margin-right: 6px;
    }

    .rightTitle {
      margin-left: auto;
    }
  }

  .description {
    font-size: 12px;
    color: @PrimaryCobalt60;
    padding-left: 20px;
  }

  .content {
    margin-top: 8px;
  }

  .extraTitle {
    width: 100%;
    min-width: 0;
  }
}

.notInAJob {
  font-weight: 400;
  color: @PrimaryCobalt60;
  font-size: 12px;
  font-family: Graphik;
  margin-left: 4px;
}

.atsDataTitle {
  display: flex;
  align-items: center;
}

.rightAligned {
  margin-left: auto;
}

.infoCategory {
  display: flex;
  align-items: center;
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;

  // Global icons color
  i {
    color: @PrimaryCobalt60;
  }
}

.svgIcon {
  fill: @PrimaryCobalt60;
}

.infoCategoryTitle {
  color: @PrimaryCobalt60;
  margin: 0 5px;
}

.infoCategoryDescription {
  color: @PrimaryCobalt;
}

.chevron {
  cursor: pointer;
  color: @PrimaryCobalt60;
}

.candidateUrl {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: @SecondaryBlue;

  i {
    color: @SecondaryBlue;
  }
}
