/*
 * colors.less
 */
.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 2.31851852;
  border-radius: 8px;
  margin: 24px;
  margin-bottom: 0;
  background: linear-gradient(to bottom, #f1f6ff, transparent);
  padding: 8px;
}
.illustration .avatars {
  display: flex;
}
.illustration .avatars .avatar {
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  width: 64px;
  aspect-ratio: 1;
  border-radius: 64px;
  background-color: white;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #4864c9;
}
.illustration .avatars .avatar:not(:first-child) {
  margin-left: -8px;
}
