/*
 * colors.less
 */
.log-activity-form {
  margin: 16px 12px 4px 12px;
}
.log-activity-form .close-button {
  float: right;
  margin-right: -1.4rem;
  margin-top: -1rem;
  font-size: 1.4rem;
  cursor: pointer;
}
.log-activity-form .main-body.RichEditor-editor .public-DraftEditorPlaceholder-root {
  min-height: 120px;
}
.log-activity-form .main-body.RichEditor-editor .public-DraftEditor-content {
  min-height: 120px;
}
.log-activity-form .submission-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.log-activity-form .submission-group :last-child {
  margin-left: auto;
}
.log-activity-form .radio-button-selector {
  width: 100%;
  gap: 5px;
}
.log-activity-form .radio-button-selector .rds-button-selected.ui.basic.button,
.log-activity-form .radio-button-selector .rds-button-default.ui.button {
  background-color: #f3f4f8 !important;
  padding: 8px !important;
  border-radius: 8px !important;
  color: #1f2e77 !important;
  font-size: 12px !important;
  font-weight: normal !important;
  border: 2px solid #f3f4f8 !important;
  font-family: Graphik, sans-serif !important;
  white-space: nowrap !important;
}
.log-activity-form .radio-button-selector .rds-button-selected.ui.basic.button {
  border: 2px solid #4864c9 !important;
}
.log-activity-form .registered-checkbox {
  margin-bottom: 10px;
}
.log-activity-form .registered-checkbox .main-title {
  color: #4864c9;
}
.log-activity-form .registered-checkbox .subtitle {
  font-size: 12px;
  display: block;
  font-weight: 400;
  margin-top: 5px;
}
.log-activity-form .move-to-archived-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.log-activity-form .move-to-archived-wrapper .registered-checkbox {
  margin: 0;
}
.log-activity-form .move-to-archived-wrapper .subtitle {
  color: #1f2e77;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}
.log-activity-form .move-to-archived-wrapper .dropdown-field {
  flex: 0.7;
}
.log-activity-form .move-to-archived-wrapper .ui.selection.dropdown {
  border-radius: 2px !important;
  padding: 8px 8px 8px 12px !important;
  min-height: 32px !important;
}
.log-activity-form .move-to-archived-wrapper .ui.selection.dropdown .dropdown.icon {
  line-height: 1.2em !important;
  top: 0.7em !important;
}
