@import 'src/less/colors';

.dropdownContainer {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.dropdownMenu {
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  left: calc(50% - 180px);
  width: 360px;
  max-height: 300px;

  overflow-y: auto;
  border: 1px solid @SecondaryBlue20;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(31, 46, 119, 0.1);
  background-color: white;

  &.open {
    display: block;
  }
}

.dropdownOption {
  display: block;
  width: 100%;
  padding: 9.5px 12px;
  line-height: 20px;

  font-family: Graphik;
  font-size: 12px;
  text-align: left;
  color: @SecondaryBlue;
  font-weight: 500;

  &:not(:first-child) {
    border-top: 1px solid @SecondaryBlue20;
  }

  &:hover {
    background-color: @SecondaryBlue05;
  }

  &.disabled {
    background-color: @SecondaryBlue05;
    color: @PrimaryCobalt60;
  }
}

.profileProjectsTab {
  padding-top: 24px;

  h3 {
    margin-top: 40px;
  }
}
