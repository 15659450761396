/*
 * colors.less
 */
.column {
  width: 160px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 16px;
  border-radius: 8px;
  cursor: default;
  background-color: #f6f7fc;
}
.column.double {
  width: 332px;
}
.column .explanation {
  font-family: Graphik;
  color: #7982ad;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}
.column .cta {
  text-align: center;
}
@media (max-width: 1400px) {
  .column {
    display: inline-block;
    padding: 16px;
    height: auto;
  }
  .column.double {
    width: 212px;
  }
  .column .explanation {
    margin-bottom: 8px;
  }
}
