/*
 * colors.less
 */
.emptyState {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 48px;
  gap: 16px;
}
.emptyState .smallImg {
  width: 60px;
}
.emptyState .title {
  margin: 0;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #0b1a63;
}
.emptyState .smallTitle {
  font-size: 18px;
}
.emptyState .innerContent {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7982ad;
}
.emptyState .smallInnerContent {
  font-size: 12px;
}
