/*
 * colors.less
 */
.datepicker-content .date-picker-label {
  font-size: 13px;
  font-weight: 500;
  margin: 16px 0 8px 0;
}
.datepicker-content .dynamic-date-picker .delay-input {
  display: flex;
  flex-shrink: 1;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-count-input {
  min-width: 0;
  flex: 0 1 60px;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-count-input input {
  border-color: #dae0f4;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-count-input input:hover {
  border: 2px solid #c6d8ff;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-type-input {
  min-width: 0;
  flex: 0 1 130px;
  white-space: nowrap;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #dae0f4;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-type-input .menu {
  border: 2px solid #c6d8ff !important;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-reset-button.ui.basic.button {
  color: #a5abc9;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-reset-button.ui.basic.button:hover {
  color: #4c5892;
}
.datepicker-content .dynamic-date-picker .delay-input .delay-reset-button,
.datepicker-content .dynamic-date-picker .delay-input .delay-reset-button:hover {
  padding: 12px 4px 9px;
  background: transparent none;
  border: none;
  box-shadow: none;
}
.datepicker-content .dynamic-date-picker .date-picker-description:not(:empty) {
  color: #7982ad;
  font-size: 14px;
  margin-top: 16px;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.date-range-picker .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.date-range-picker .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow,
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.date-range-picker .react-datepicker__year-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.date-range-picker .react-datepicker__year-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow::before {
  content: '';
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #dae0f4;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f1f6ff;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #dae0f4;
}
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.date-range-picker .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.date-range-picker .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow,
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.date-range-picker .react-datepicker__year-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: white;
}
.date-range-picker .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.date-range-picker .react-datepicker__year-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-read-view--down-arrow::before,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #dae0f4;
}
.date-range-picker .react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;
  width: 100%;
}
.date-range-picker .react-datepicker {
  font-family: Graphik, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  background-color: white;
  color: #000;
  border: 1px solid #dae0f4;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  box-shadow: 1px 1px 10px 1px rgba(72, 100, 201, 0.09);
}
.date-range-picker .react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.date-range-picker .react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.date-range-picker .react-datepicker--time-only .react-datepicker__time,
.date-range-picker .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.date-range-picker .react-datepicker__triangle {
  position: absolute;
  left: 50px;
}
.date-range-picker .react-datepicker-popper {
  z-index: 1;
}
.date-range-picker .react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 10px;
}
.date-range-picker .react-datepicker-popper[data-placement='bottom-end'] .react-datepicker__triangle,
.date-range-picker .react-datepicker-popper[data-placement='top-end'] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.date-range-picker .react-datepicker-popper[data-placement^='top'] {
  margin-bottom: 10px;
}
.date-range-picker .react-datepicker-popper[data-placement^='right'] {
  margin-left: 8px;
}
.date-range-picker .react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.date-range-picker .react-datepicker-popper[data-placement^='left'] {
  margin-right: 8px;
}
.date-range-picker .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}
.date-range-picker .react-datepicker__header {
  text-align: center;
  background-color: #f1f6ff;
  border-bottom: 1px solid #dae0f4;
  border-top-left-radius: 2px;
  padding: 8px 0;
  position: relative;
}
.date-range-picker .react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.date-range-picker .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.date-range-picker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 2px;
}
.date-range-picker .react-datepicker__year-dropdown-container--select,
.date-range-picker .react-datepicker__month-dropdown-container--select,
.date-range-picker .react-datepicker__month-year-dropdown-container--select,
.date-range-picker .react-datepicker__year-dropdown-container--scroll,
.date-range-picker .react-datepicker__month-dropdown-container--scroll,
.date-range-picker .react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.date-range-picker .react-datepicker__current-month,
.date-range-picker .react-datepicker-time__header,
.date-range-picker .react-datepicker-year-header {
  color: #1f2e77;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  margin-top: 2px;
  margin-bottom: 14px;
}
.date-range-picker .react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.date-range-picker .react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}
.date-range-picker .react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #4864c9;
}
.date-range-picker .react-datepicker__navigation--previous:hover {
  border-right-color: #709dff;
}
.date-range-picker .react-datepicker__navigation--previous--disabled,
.date-range-picker .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #709dff;
  cursor: default;
}
.date-range-picker .react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #4864c9;
}
.date-range-picker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}
.date-range-picker .react-datepicker__navigation--next:hover {
  border-left-color: #709dff;
}
.date-range-picker .react-datepicker__navigation--next--disabled,
.date-range-picker .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #dae0f4;
  cursor: default;
}
.date-range-picker .react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.date-range-picker .react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #4864c9;
}
.date-range-picker .react-datepicker__navigation--years-previous:hover {
  border-top-color: #709dff;
}
.date-range-picker .react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #4864c9;
}
.date-range-picker .react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #dae0f4;
}
.date-range-picker .react-datepicker__month-container {
  float: left;
}
.date-range-picker .react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.date-range-picker .react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.date-range-picker .react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}
.date-range-picker .react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.date-range-picker .react-datepicker__month .react-datepicker__month-text,
.date-range-picker .react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}
.date-range-picker .react-datepicker__week-number {
  color: #dae0f4;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.date-range-picker .react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.date-range-picker .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 2px;
  background-color: #f1f6ff;
}
.date-range-picker .react-datepicker__day-names,
.date-range-picker .react-datepicker__week {
  white-space: nowrap;
}
.date-range-picker .react-datepicker__day-name,
.date-range-picker .react-datepicker__day,
.date-range-picker .react-datepicker__time-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.date-range-picker .react-datepicker__day-name {
  color: #4864c9;
}
.date-range-picker .react-datepicker__month--selected,
.date-range-picker .react-datepicker__month--in-selecting-range,
.date-range-picker .react-datepicker__month--in-range,
.date-range-picker .react-datepicker__quarter--selected,
.date-range-picker .react-datepicker__quarter--in-selecting-range,
.date-range-picker .react-datepicker__quarter--in-range {
  border-radius: 2px;
  background-color: #216ba5;
  color: #fff;
}
.date-range-picker .react-datepicker__month--selected:hover,
.date-range-picker .react-datepicker__month--in-selecting-range:hover,
.date-range-picker .react-datepicker__month--in-range:hover,
.date-range-picker .react-datepicker__quarter--selected:hover,
.date-range-picker .react-datepicker__quarter--in-selecting-range:hover,
.date-range-picker .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.date-range-picker .react-datepicker__month--disabled,
.date-range-picker .react-datepicker__quarter--disabled {
  color: #dae0f4;
  pointer-events: none;
}
.date-range-picker .react-datepicker__month--disabled:hover,
.date-range-picker .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}
.date-range-picker .react-datepicker__day,
.date-range-picker .react-datepicker__month-text,
.date-range-picker .react-datepicker__quarter-text,
.date-range-picker .react-datepicker__year-text {
  cursor: pointer;
}
.date-range-picker .react-datepicker__day:hover,
.date-range-picker .react-datepicker__month-text:hover,
.date-range-picker .react-datepicker__quarter-text:hover,
.date-range-picker .react-datepicker__year-text:hover {
  border-radius: 2px;
  background-color: #f1f6ff;
}
.date-range-picker .react-datepicker__day--today,
.date-range-picker .react-datepicker__month-text--today,
.date-range-picker .react-datepicker__quarter-text--today,
.date-range-picker .react-datepicker__year-text--today {
  font-weight: 500;
}
.date-range-picker .react-datepicker__day--highlighted,
.date-range-picker .react-datepicker__month-text--highlighted,
.date-range-picker .react-datepicker__quarter-text--highlighted,
.date-range-picker .react-datepicker__year-text--highlighted {
  border-radius: 2px;
  background-color: #3dcc4a;
  color: #fff;
}
.date-range-picker .react-datepicker__day--highlighted:hover,
.date-range-picker .react-datepicker__month-text--highlighted:hover,
.date-range-picker .react-datepicker__quarter-text--highlighted:hover,
.date-range-picker .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.date-range-picker .react-datepicker__day--highlighted-custom-1,
.date-range-picker .react-datepicker__month-text--highlighted-custom-1,
.date-range-picker .react-datepicker__quarter-text--highlighted-custom-1,
.date-range-picker .react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.date-range-picker .react-datepicker__day--highlighted-custom-2,
.date-range-picker .react-datepicker__month-text--highlighted-custom-2,
.date-range-picker .react-datepicker__quarter-text--highlighted-custom-2,
.date-range-picker .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.date-range-picker .react-datepicker__day--selected,
.date-range-picker .react-datepicker__day--in-selecting-range,
.date-range-picker .react-datepicker__day--in-range,
.date-range-picker .react-datepicker__month-text--selected,
.date-range-picker .react-datepicker__month-text--in-selecting-range,
.date-range-picker .react-datepicker__month-text--in-range,
.date-range-picker .react-datepicker__quarter-text--selected,
.date-range-picker .react-datepicker__quarter-text--in-selecting-range,
.date-range-picker .react-datepicker__quarter-text--in-range,
.date-range-picker .react-datepicker__year-text--selected,
.date-range-picker .react-datepicker__year-text--in-selecting-range,
.date-range-picker .react-datepicker__year-text--in-range {
  border-radius: 2px;
  background-color: #216ba5;
  color: #fff;
}
.date-range-picker .react-datepicker__day--selected:hover,
.date-range-picker .react-datepicker__day--in-selecting-range:hover,
.date-range-picker .react-datepicker__day--in-range:hover,
.date-range-picker .react-datepicker__month-text--selected:hover,
.date-range-picker .react-datepicker__month-text--in-selecting-range:hover,
.date-range-picker .react-datepicker__month-text--in-range:hover,
.date-range-picker .react-datepicker__quarter-text--selected:hover,
.date-range-picker .react-datepicker__quarter-text--in-selecting-range:hover,
.date-range-picker .react-datepicker__quarter-text--in-range:hover,
.date-range-picker .react-datepicker__year-text--selected:hover,
.date-range-picker .react-datepicker__year-text--in-selecting-range:hover,
.date-range-picker .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.date-range-picker .react-datepicker__day--keyboard-selected,
.date-range-picker .react-datepicker__month-text--keyboard-selected,
.date-range-picker .react-datepicker__quarter-text--keyboard-selected,
.date-range-picker .react-datepicker__year-text--keyboard-selected {
  border-radius: 2px;
  background-color: #2a87d0;
  color: #fff;
}
.date-range-picker .react-datepicker__day--keyboard-selected:hover,
.date-range-picker .react-datepicker__month-text--keyboard-selected:hover,
.date-range-picker .react-datepicker__quarter-text--keyboard-selected:hover,
.date-range-picker .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.date-range-picker .react-datepicker__day--in-selecting-range,
.date-range-picker .react-datepicker__month-text--in-selecting-range,
.date-range-picker .react-datepicker__quarter-text--in-selecting-range,
.date-range-picker .react-datepicker__year-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}
.date-range-picker .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.date-range-picker .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
.date-range-picker .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range,
.date-range-picker .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
  background-color: #f1f6ff;
  color: #000;
}
.date-range-picker .react-datepicker__day--disabled,
.date-range-picker .react-datepicker__month-text--disabled,
.date-range-picker .react-datepicker__quarter-text--disabled,
.date-range-picker .react-datepicker__year-text--disabled {
  cursor: default;
  color: #dae0f4;
}
.date-range-picker .react-datepicker__day--disabled:hover,
.date-range-picker .react-datepicker__month-text--disabled:hover,
.date-range-picker .react-datepicker__quarter-text--disabled:hover,
.date-range-picker .react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}
.date-range-picker .react-datepicker__month-text.react-datepicker__month--selected:hover,
.date-range-picker .react-datepicker__month-text.react-datepicker__month--in-range:hover,
.date-range-picker .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.date-range-picker .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.date-range-picker .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.date-range-picker .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.date-range-picker .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.date-range-picker .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.date-range-picker .react-datepicker__month-text:hover,
.date-range-picker .react-datepicker__quarter-text:hover {
  background-color: #f1f6ff;
}
.date-range-picker .react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 220px;
}
.date-range-picker .react-datepicker__input-container input {
  width: 100%;
  background-color: white;
  height: 40px;
  padding: 0.25em 2.1em 0.25em 10px !important;
  border-radius: 0px 2px 2px 0px;
  border: 1px solid #dae0f4;
  outline-width: 0;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #4c5892;
}
.date-range-picker .react-datepicker__year-read-view,
.date-range-picker .react-datepicker__month-read-view,
.date-range-picker .react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 2px;
}
.date-range-picker .react-datepicker__year-read-view:hover,
.date-range-picker .react-datepicker__month-read-view:hover,
.date-range-picker .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.date-range-picker .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #dae0f4;
}
.date-range-picker .react-datepicker__year-read-view--down-arrow,
.date-range-picker .react-datepicker__month-read-view--down-arrow,
.date-range-picker .react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #dae0f4;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}
.date-range-picker .react-datepicker__year-dropdown,
.date-range-picker .react-datepicker__month-dropdown,
.date-range-picker .react-datepicker__month-year-dropdown {
  background-color: #f1f6ff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #aeaeae;
}
.date-range-picker .react-datepicker__year-dropdown:hover,
.date-range-picker .react-datepicker__month-dropdown:hover,
.date-range-picker .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.date-range-picker .react-datepicker__year-dropdown--scrollable,
.date-range-picker .react-datepicker__month-dropdown--scrollable,
.date-range-picker .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.date-range-picker .react-datepicker__year-option,
.date-range-picker .react-datepicker__month-option,
.date-range-picker .react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.date-range-picker .react-datepicker__year-option:first-of-type,
.date-range-picker .react-datepicker__month-option:first-of-type,
.date-range-picker .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.date-range-picker .react-datepicker__year-option:last-of-type,
.date-range-picker .react-datepicker__month-option:last-of-type,
.date-range-picker .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.date-range-picker .react-datepicker__year-option:hover,
.date-range-picker .react-datepicker__month-option:hover,
.date-range-picker .react-datepicker__month-year-option:hover {
  background-color: #dae0f4;
}
.date-range-picker .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.date-range-picker .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.date-range-picker .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #dae0f4;
}
.date-range-picker .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.date-range-picker .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.date-range-picker .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #dae0f4;
}
.date-range-picker .react-datepicker__year-option--selected,
.date-range-picker .react-datepicker__month-option--selected,
.date-range-picker .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}
.date-range-picker .react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.date-range-picker .react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '\00d7';
}
.date-range-picker .react-datepicker__today-button {
  background: #f1f6ff;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  padding: 5px 0;
  clear: left;
}
.date-range-picker .react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.date-range-picker .react-datepicker__portal .react-datepicker__day-name,
.date-range-picker .react-datepicker__portal .react-datepicker__day,
.date-range-picker .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .date-range-picker .react-datepicker__portal .react-datepicker__day-name,
  .date-range-picker .react-datepicker__portal .react-datepicker__day,
  .date-range-picker .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.date-range-picker .react-datepicker__portal .react-datepicker__current-month,
.date-range-picker .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}
.date-range-picker .react-datepicker__navigation--previous:focus,
.date-range-picker .react-datepicker__navigation--next:focus {
  outline-width: 0 !important;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #dae0f4;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #dae0f4;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #dae0f4;
  cursor: default;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #dae0f4;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #dae0f4;
}
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--next--disabled,
.date-range-picker .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #dae0f4;
  cursor: default;
}
.tab-container .tab-selectors {
  display: flex;
  border-bottom: solid 2px #dae0f4;
}
.tab-container .tab-selectors .tab-button {
  flex-grow: 1;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: unset;
  font-size: 13px;
}
.tab-container .tab-selectors .tab-button:hover {
  background: transparent none !important;
  box-shadow: 0 2px 0px 0px #a9c4ff;
}
.tab-container .tab-selectors .tab-button.active {
  background: transparent none !important;
  box-shadow: 0 2px 0px 0px #709dff;
}
