/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.ui.segment.mini-card {
  border-radius: 5px !important;
  border: 1px solid #e8e8e8;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  margin-right: 8px;
  margin-bottom: 16px;
  position: relative;
  border-bottom: 1px solid #dfdfe6;
  cursor: pointer;
  transition: all ease 50ms;
}
.ui.segment.mini-card:hover {
  transition: all ease 150ms;
  box-shadow: 0px 2px 16px 0px #1f2e7714;
  border-color: #4864c9;
}
.ui.segment.mini-card .watch-profile-header {
  border-bottom: 1px solid #dfdfe6;
}
.ui.segment.mini-card .watch-profile-header .header-annotations {
  position: absolute;
  left: -4px;
  top: -4px;
}
.ui.segment.mini-card .watch-profile-header:last-child {
  border-bottom: none;
}
.ui.segment.mini-card .watch-profile-header .header-show-interest {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #4864c9;
  color: white;
  border: 2px solid white;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  font-size: 13px;
  padding: 1.5px 4.5px 7.5px 4.5px;
}
.ui.segment.mini-card .watch-profile-header .title {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
}
.ui.segment.mini-card .watch-profile-header .content {
  font-size: 14px;
}
.ui.segment.mini-card .watch-profile-header .icon {
  fill: #4864c9;
  margin-bottom: 4px;
  height: 14px;
}
.ui.segment.mini-card .watch-profile-header .miniCardPowerHoursIcon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: -2px;
  top: -4px;
  width: 24px;
  height: 24px;
  font-size: 13px;
  background-color: #28ca42;
  border-radius: 100%;
  border: 2px solid white;
  color: white;
}
.ui.button.remove-from-job-button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #e8e8e8;
  color: #a0a0a0;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: 0ms;
}
.ui.button.remove-from-job-button:hover,
.ui.button.remove-from-job-button:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #e8e8e8;
  color: #a0a0a0;
}
.ui.button.remove-from-job-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #1f2e77;
}
.ui.button.remove-from-job-button .button-icon {
  opacity: 1;
  height: 9px;
  color: #a0a0a0;
  margin-right: 6px;
}
.ui.button.remove-from-job-button:hover {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #4864c9;
  color: white;
  transition: 0ms;
}
.ui.button.remove-from-job-button:hover:hover,
.ui.button.remove-from-job-button:hover:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #4864c9;
  color: white;
}
.ui.button.remove-from-job-button:hover:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #1f2e77;
}
.ui.button.remove-from-job-button:hover .button-icon {
  transition: 0ms;
  color: white;
}
