/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.evaluations-sets-container {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  margin-bottom: 42px;
  padding: 20px;
}
.evaluations-sets-container .link-like-button {
  font-family: Graphik, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.evaluations-sets-container .link-like-button:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.evaluations-sets-container .evaluations-sets-header .header {
  margin-top: 0 !important;
  color: #4864c9;
  margin-bottom: 16px;
}
.evaluations-sets-container .evaluations-sets-header .no-evaluations {
  font-style: italic;
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #a1a1b3;
  line-height: 20px;
}
.evaluations-sets-container .add-evaluation-form .choose-evaluation-form-template {
  font-family: Graphik, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.evaluations-sets-container .add-evaluation-form .choose-evaluation-form-template:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.evaluations-sets-container .add-evaluation-form .choose-evaluation-form-template .ui.dropdown > i.dropdown.icon {
  margin: 0 0 0 0.5em !important;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container {
  margin-bottom: 15px;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container > .ui.segment {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 16px;
  padding-bottom: 16px;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header {
  padding-left: 16px;
  padding-right: 16px;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .evaluations-set-title h4 {
  color: #709dff;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .evaluations-set-title .row {
  display: flex;
  align-items: center;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .evaluations-set-title .row input {
  margin-right: 3.5px;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .buttons-container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .buttons-container button {
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .buttons-container button img.trash-svg {
  opacity: 0.6;
  width: 10px;
  height: 13px;
  padding-bottom: 3px;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-set-header .buttons-container .modify-button:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list .evaluation-item {
  display: flex;
  justify-content: space-between;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list .see-evaluation {
  font-family: Graphik, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list .see-evaluation:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list .add-evaluation {
  font-family: Graphik, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list .add-evaluation:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list button {
  outline: none;
  cursor: pointer;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .evaluations-list button img.trash-svg {
  opacity: 0.6;
  width: 10px;
  height: 13px;
  padding-bottom: 3px;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .see-aggregated {
  font-family: Graphik, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  text-align: center;
}
.evaluations-sets-container .evaluations-sets-list .evaluations-set-container .see-aggregated:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.evaluations-sets-container .evaluations-sets-add-more {
  backround: yellow;
}
.evaluations-sets-container .evaluation-divider-container {
  padding-left: 14px;
  padding-right: 14px;
}
.evaluations-sets-container .evaluation-display {
  padding-left: 16px;
  padding-right: 16px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-header .evaluation-display-title {
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #a1a1b3;
  line-height: 20px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-header .close-button {
  padding: 3px;
  opacity: 0.6;
  cursor: pointer;
}
.evaluations-sets-container .evaluation-display .evaluation-display-header .close-button img {
  width: 9px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-header .close-button:hover {
  opacity: 1;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body {
  padding-top: 10px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-display-average-container {
  padding-bottom: 5px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-display-average-container .evaluation-display-average {
  font-family: Gilroy;
  font-weight: 600 !important;
  font-size: 15px;
  color: #4864C9;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field {
  padding-top: 8px;
  padding-bottom: 14px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field h5 {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #10173D;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field .field-comment {
  font-style: italic;
  font-family: Graphik;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field .field-comment .no-comment {
  color: #a1a1b3;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field table {
  font-size: 12px;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field .title-cell .title-cell-coefficient {
  color: #a1a1b3;
  opacity: 0.7;
}
.evaluations-sets-container .evaluation-display .evaluation-display-body .evaluation-field .grade-cell {
  width: 80px !important;
  min-width: 80px !important;
}
