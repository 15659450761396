@import 'src/less/colors';

.table {
  border-radius: 8px !important;
  overflow: hidden;

  .header {
    background: @SecondarySkyBlue05 !important;
    border: 1px solid @SecondaryBlue20 !important;

    .th {
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 14px;
      color: @PrimaryCobalt60;
    }
  }

  .row {
    background: white !important;
    border-bottom: 1px solid @SecondaryBlue20 !important;

    .name {
      color: @SecondaryBlue !important;
      font-weight: 600 !important;

      .taskIcon {
        margin-right: 8px;
      }
    }

    .subtype {
      color: @PrimaryCobalt !important;
    }

    .icon {
      margin: 0px 0px;
      color: @PrimaryCobalt60;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
