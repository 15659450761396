/*
 * colors.less
 */
.profile-details.plugin-mode .do-not-contact-section {
  flex-basis: 100% !important;
}
.profile-details.plugin-mode .basic-details-section .column .field {
  width: 88% !important;
}
.profile-details {
  margin-top: 36px;
  margin-left: 24px;
  margin-right: 24px;
}
.profile-details .details-section {
  margin-bottom: 32px !important;
}
.profile-details .basic-details-section .container {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  flex-wrap: wrap;
  gap: 20px;
}
.profile-details .basic-details-section .container .column {
  text-align: left;
  flex-grow: 1;
}
.profile-details .basic-details-section .container .column .field {
  display: flex;
  width: 80%;
}
.profile-details .basic-details-section .container .column .field .field-name {
  max-width: 128px;
  width: 128px;
  color: #7982ad;
  flex-shrink: 0;
}
.profile-details .basic-details-section .container .column .field .field-value {
  color: #1f2e77;
  font-family: Graphik;
  font-weight: 500;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-details hr {
  border-color: #e9ebf2;
  background-color: #e9ebf2;
  margin-bottom: 32px;
  border-style: solid;
  border-bottom: none;
}
.profile-details .empty-details {
  padding-top: 34px;
  display: block;
  margin: auto;
  width: 80%;
}
.profile-details h4 {
  color: #1f2e77;
  font-family: Gilroy !important;
}
.profile-details .label {
  font-size: 12px;
  color: #7982ad;
  margin-right: 0.5em;
  font-family: Gilroy !important;
}
.profile-details .value {
  font-size: 12px;
  font-weight: 500;
  color: #1f2e77;
  font-family: Gilroy !important;
}
.profile-details .application:not(:last-child) {
  margin-bottom: 14px;
}
.profile-details .application .application-title {
  font-family: Gilroy !important;
  font-weight: 700;
  font-size: 16px;
  color: #1f2e77;
  margin-bottom: 2px;
}
.profile-details .application .application-info-item {
  margin-bottom: 2px;
}
.profile-details .application .application-date {
  font-size: 12px;
  font-weight: 500;
  color: #1f2e77;
}
.profile-details .application .rejected {
  color: #ff6059;
}
.profile-details .application .hired {
  color: #28ca42;
}
.profile-details .application .active {
  color: #709dff;
}
.profile-details .additional-details h3 {
  display: flex;
}
.profile-details .additional-details h3 a {
  margin-left: 0.5em;
  font-size: 14px;
}
.profile-details .additional-details .additional-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}
.profile-details .additional-details .additional-detail .additional-detail-label {
  font-size: 12px;
  font-weight: 400;
  color: #7982ad;
}
.profile-details .additional-details .additional-detail .additional-detail-value {
  font-size: 12px;
  font-weight: 500;
  color: #1f2e77;
}
.profile-details .personal-data-section .do-not-contact-current-status {
  width: 100%;
  color: #7982ad;
  font-size: 12px;
  font-family: Graphik !important;
}
.profile-details .personal-data-section .do-not-contact-current-status .status {
  float: right;
}
.profile-details .personal-data-section .actions {
  margin-top: 18px;
  display: flex;
  gap: 24px;
}
.profile-details .personal-data-section .actions .do-not-contact-section {
  border: 1px solid #dae0f4;
  border-radius: 3px;
  padding: 16px;
  flex-basis: 50%;
}
.profile-details .personal-data-section .actions .do-not-contact-section h5 {
  color: #1f2e77;
  font-family: Gilroy !important;
  margin-bottom: 8px;
}
.profile-details .personal-data-section .actions .do-not-contact-section p {
  color: #7982ad;
  font-size: 12px;
}
.reasonContainer {
  margin-top: 24px;
}
.reasonContainer .reasonTitle {
  font-weight: 500;
  margin-bottom: 4px;
}
.buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.buttons button {
  flex: 1;
}
.buttons .buttonActive {
  border: 2px solid #4864c9;
}
.customButton {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  color: #4c5892;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
