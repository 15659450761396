/*
 * colors.less
 */
.settings.evaluationForms-page .evaluationTemplates-editor {
  margin-top: 20px;
  width: 55%;
}
.settings.evaluationForms-page .evaluationTemplates-editor .evaluationTemplates-list-container {
  margin-bottom: 20px;
}
.settings.evaluationForms-page .evaluationTemplates-editor .evaluationTemplates-list-container .evaluationTemplate-container {
  margin-top: 10px;
}
.settings.evaluationForms-page .evaluationTemplates-editor .evaluationTemplates-list-container .evaluationTemplate-container .ui.segment.evaluation-template {
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings.evaluationForms-page .evaluationTemplates-editor .evaluationTemplates-list-container .evaluationTemplate-container .ui.segment.evaluation-template h3 {
  margin-top: 5px !important;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 15px;
  flex-grow: 1;
  overflow: auto;
}
.settings.evaluationForms-page .evaluationTemplates-editor .evaluationTemplates-list-container .evaluationTemplate-container .ui.segment.evaluation-template .settings-dropdown-container {
  flex-shrink: 0;
}
.settings.evaluationForms-page .evaluationTemplates-editor .evaluationTemplates-list-container .evaluationTemplate-container .ui.segment.evaluation-template .settings-dropdown-container .ui.dropdown .menu {
  margin-top: 3px;
}
