@import 'src/less/colors';

.root {
  padding: 10px;
  background: white;
}

.form {
  padding-bottom: 60px;
  overflow: auto;

  .actions {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    width: 350px;
    background-color: white;
    padding: 10px 0px;

    .submitButton {
      background-color: @SecondaryBlue !important;
      border: 1px solid @SecondaryBlue !important;
    }

    .cancel {
      box-shadow: 0px 0px 0px 1px #4864c9 inset !important;
      color: #4864c9 !important;
      font-weight: 700;
      border-radius: 0.14285714rem;
      text-transform: none;
      text-shadow: none !important;
      background-color: white !important;
    }
  }

  .title {
    color: @SecondaryBlue;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;

    margin-bottom: 24px;
  }
}

.form::-webkit-scrollbar {
  display: none;
}
