@import 'src/less/colors';

.header {
  margin-bottom: 16px;

  h1 {
    font-family: Gilroy;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: @PrimaryCobalt;
    margin-bottom: 4px;
  }

  h2 {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: @PrimaryCobalt;
    margin: 0;
  }
}
