/*
 * colors.less
 */
.link {
  display: grid;
  margin-bottom: 32px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 12px;
}
.link .icon {
  grid-column: 1;
  grid-row: 1 / span 2;
  font-size: 16px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f2e77;
  background-color: #f6f7fc;
  border-radius: 8px;
}
.link .title {
  grid-column: 2;
  grid-row: 1;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #1f2e77;
}
.link .subtitle {
  grid-column: 2;
  grid-row: 2;
  color: #7982ad;
  font-size: 12px;
}
.link:hover .title {
  text-decoration: underline;
}
