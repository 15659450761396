@import 'src/less/colors';

.pill {
  display: inline-block;
  vertical-align: text-bottom;
  margin: 0 8px;
  padding: 2px 8px;
  border-radius: 2em;
  font-size: 0.75em;
  font-family: Graphik;
  font-weight: 500;
  line-height: 1.25em;
  color: @SecondaryBlue;
  background-color: @SecondarySkyBlue20;
}
