@import 'src/less/colors';

.link {
  display: grid;
  margin-bottom: 32px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 12px;

  .icon {
    grid-column: 1;
    grid-row: 1 / span 2;
    font-size: 16px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @PrimaryCobalt;
    background-color: @SecondaryBlue05;
    border-radius: 8px;
  }

  .title {
    grid-column: 2;
    grid-row: 1;

    font-family: Graphik;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    color: @PrimaryCobalt;
  }

  .subtitle {
    grid-column: 2;
    grid-row: 2;
    color: @PrimaryCobalt60;
    font-size: 12px;
  }

  &:hover {
    .title {
      text-decoration: underline;
    }
  }
}
