@import 'src/less/colors';

.bcSelector {
  text-align: right;
  flex-shrink: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: @PrimaryCobalt60;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
  span:not(:first-child) {
    margin-left: 8px;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
  margin: 0px !important;

  .introWord {
    color: @PrimaryCobalt60;
    //styleName: Body 12/Regular 400;
    font-family: Graphik !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
  }

  .pill {
    display: inline-flex;
    align-items: center;
    height: 18px;
    padding: 0px 0px 0px 8px !important;
    margin: 0 !important;

    .deleteAddress {
      margin-left: 8px;
      vertical-align: 0px;
      cursor: pointer;
    }

    span:focus-visible {
      outline: none;
    }
  }

  .inputElement {
    flex-grow: 1;
    font-size: 12px;
    min-width: 150px;
    display: flex;

    .recipientInput {
      flex-grow: 1;
      input {
        border: none;
        padding: 0px;
        color: @PrimaryCobalt;

        &::placeholder {
          color: @PrimaryCobalt40 !important;
        }
      }
    }
  }
  .inputCancel {
    margin-left: auto;
    cursor: pointer;
    svg {
      color: @PrimaryCobalt60;
      height: 9px;
    }
  }

  .soboSelector {
    flex-grow: 1;
  }
}

.clickable {
  cursor: pointer;
}

.soboTrigger {
  // 1em does not seem to work, maybe because of dropdown
  font-size: 12px;
}

.unauthorizedSender {
  color: @SecondaryRed120;
}

.soboDropdown {
  color: @SecondarySkyBlue120;
}

.dropdownContent  {
  z-index: 2;
}
