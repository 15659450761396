@import 'src/less/colors';

.mainTitle {
  font-size: 26px;
  font-weight: 700;
  color: @PrimaryCobalt;
}

.privacySection {
  margin-bottom: 40px;
  .title {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    font-family: Gilroy !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    color: @PrimaryCobalt !important;
  }

  .background {
    background-color: white;
    border-radius: 4px;
    border: 1px solid @SecondaryBlue20;
    padding: 25px;

    .paragraph {
      font-size: 14px;
      font-weight: 400;
      color: @PrimaryCobalt60;
      margin-bottom: 20px;
    }
  }

  .content {
    display: grid;
    width: 100%;
    position: relative;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    margin: 16px 0px;
    font-family: Graphik;
    color: @PrimaryCobalt60;
    font-weight: 400;
    font-size: 14px;

    span {
      color: @PrimaryCobalt;
      font-weight: 500;
      margin-left: 8px;
    }

    .field {
      margin-bottom: 4px;
    }

    .input {
      width: 100%;
    }
  }

  .actions {
    display: flex;
    justify-content: right;
    gap: 8px;
  }
}
