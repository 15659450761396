/*
 * colors.less
 */
.fieldItem {
  padding: 16px 8px;
  display: flex;
  align-items: center;
}
.fieldItem:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.fieldItem .fieldEditIcon {
  visibility: hidden;
  display: inline-block;
  vertical-align: middle;
}
.fieldItem .fieldEditIcon::before {
  vertical-align: initial;
}
.fieldItem :hover > .fieldEditIcon {
  visibility: visible;
  margin-left: 0.25rem;
  color: #333;
}
.handle {
  padding: 0 0.9rem;
  display: inline-block;
  vertical-align: middle;
}
.handle::before {
  vertical-align: initial;
}
.fieldName {
  flex: 1 0;
  cursor: pointer;
}
.fieldTypeTag {
  background-color: #f1f6ff;
  color: #1f2e77;
  border-radius: 2px;
  padding: 6px 4px;
  line-height: 22px;
}
.fieldTypeTag i {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
}
.fieldTypeTag i::before {
  vertical-align: initial;
}
.fieldTypeTag span {
  display: inline-block;
  vertical-align: middle;
}
.cell {
  flex: 1 0;
}
.fieldAction {
  cursor: pointer;
}
.buttonAdd:global(.ui.button) {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.buttonAdd:global(.ui.button) i {
  margin-left: 0.5rem;
}
.modalActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
