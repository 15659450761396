/*
 * colors.less
 */
:global(.ui.modal) > .modal:global(.content) {
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}
.form,
.preview {
  flex: 1;
}
.form {
  padding: 32px;
}
.modal:global(.ui.modal) {
  width: 1050px;
  max-width: 90vw;
}
:global(.ui.modal) > .actions {
  border-top: 1px solid #dae0f4;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
}
:global(.ui.modal) :global(.ui.modal) > .actions > :global(.button) {
  margin-left: 0;
}
.options {
  border: 1px solid #dae0f4;
  padding: 8px;
  border-radius: 3px;
}
.displayOption {
  margin-top: 32px;
}
.rowOption {
  display: flex;
  flex-flow: row nowrap;
  height: 32px;
  padding-bottom: 4px;
}
.inputOptionRow {
  flex: 1;
}
.inputOption:global(.ui.input) input {
  border: none;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  color: #1f2e77;
  padding: 4px;
}
.inputOption:global(.ui.input) input:focus {
  color: #1f2e77;
  border: 1px solid #8db1ff !important;
}
.deleteOption {
  color: #7982ad;
  cursor: pointer;
  margin-left: 3px;
  outline: 0;
  border: 1px solid transparent;
}
.deleteOption:focus {
  border-color: #8db1ff;
  border-radius: 2px;
}
.deleteOption:disabled {
  cursor: initial;
  color: #d2d5e4;
}
.addOptionContainer {
  padding-top: 8px;
  border-top: 1px solid #dae0f4;
  width: 100%;
}
.addOptionContainer button {
  margin: 0;
}
.addOption {
  color: #709dff;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik, sans-serif;
  vertical-align: middle;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;
  outline: 0;
  border: 1px solid transparent;
}
.addOption i {
  font-weight: 700;
}
.addOption:focus {
  border-color: #8db1ff;
  border-radius: 2px;
}
:global(.ui.modal) .defaultValueSelectField {
  margin-top: 24px;
  margin-bottom: 0;
}
.toggleContainer {
  z-index: 0;
  position: relative;
}
