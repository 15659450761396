/*
 * colors.less
 */
.watch-collect-filters-modal.ui.modal {
  width: 784px;
}
.watch-collect-filters-modal.ui.modal .subheader {
  white-space: pre-wrap;
}
.watch-collect-filters-modal.ui.modal .content {
  padding: 20px 32px;
  max-height: calc(100vh - 114px - 160px);
  overflow-y: scroll;
}
.watch-collect-filters-modal.ui.modal .description {
  margin-bottom: 40px;
  font-size: 12px;
}
.watch-collect-filters-modal.ui.modal .actions {
  padding: 24px 32px;
  position: relative;
  box-shadow: 0px -2px 20px rgba(165, 165, 165, 0.25), inset 0px 1px 0px #e8e8ec;
}
.watch-collect-filters-modal.ui.modal .actions .dismiss {
  text-decoration: underline;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter {
  padding-top: 20px;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter .dropdown {
  margin-bottom: 16px;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter:not(:last-child) {
  border-bottom: 1px solid #e8e8ec;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter .filter-header {
  display: flex;
  justify-content: space-between;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter .filter-title {
  color: #1f2e77;
  margin-bottom: 0;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter .filter-content {
  margin: 20px 0;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter.half-size .criterion-selector,
.watch-collect-filters-modal.ui.modal .watch-collect-filter.half-size .experience-years-criterion,
.watch-collect-filters-modal.ui.modal .watch-collect-filter.half-size .salary-criterion {
  max-width: 60%;
}
.watch-collect-filters-modal.ui.modal .watch-collect-filter .criterion-selector .criterion-displayed-items {
  margin-top: 0;
  margin-bottom: 0;
}
.watch-collect-filters-modal.ui.modal .experience-filter .min-max-labels {
  margin-left: 20px;
  margin-right: 12px;
}
.watch-collect-filters-modal.ui.modal .locations-filter .filters-toggle.ui.checkbox {
  margin-top: 24px;
}
.watch-collect-filters-modal.ui.modal .salary-filter,
.watch-collect-filters-modal.ui.modal .experience-filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.watch-collect-filters-modal.ui.modal .salary-filter .input-range-container,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range-container {
  padding: 50px 24px 8px;
}
.watch-collect-filters-modal.ui.modal .salary-filter .min-max-labels,
.watch-collect-filters-modal.ui.modal .experience-filter .min-max-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.watch-collect-filters-modal.ui.modal .salary-filter .input-range__slider,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range__slider {
  background: white;
}
.watch-collect-filters-modal.ui.modal .salary-filter .input-range__label,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range__label {
  color: #4864c9;
  font-weight: 600;
}
.watch-collect-filters-modal.ui.modal .salary-filter .input-range__label--min,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range__label--min,
.watch-collect-filters-modal.ui.modal .salary-filter .input-range__label--max,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range__label--max {
  display: none;
  top: 0.4rem;
  position: absolute;
  font-size: 10px;
}
.watch-collect-filters-modal.ui.modal .salary-filter .input-range__slider-container .input-range__label--value,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range__slider-container .input-range__label--value {
  background-color: #f2f4f6;
  border-radius: 4px;
  padding: 8px 10px;
  top: -3.8rem;
  right: 0px;
  transform: translateX(50%);
}
.watch-collect-filters-modal.ui.modal .salary-filter .input-range__slider-container ~ .input-range__slider-container .input-range__label--value,
.watch-collect-filters-modal.ui.modal .experience-filter .input-range__slider-container ~ .input-range__slider-container .input-range__label--value {
  background-color: #f2f4f6;
  border-radius: 4px;
  padding: 8px 10px;
  top: -3.8rem;
  right: auto;
  left: 0px;
  transform: translateX(-50%);
}
.watch-collect-filters-modal.ui.modal label.filters-toggle {
  color: #7982ad !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.watch-collect-filters-modal.ui.modal label.filters-toggle:hover .ui.checkbox input:not(:checked) ~ label:before {
  background-color: #e9ebf2 !important;
}
.watch-collect-filters-modal.ui.modal label.filters-toggle .ui.checkbox,
.watch-collect-filters-modal.ui.modal label.filters-toggle .toggle-label {
  margin: 20px 0;
  display: block;
}
.watch-collect-filters-modal.ui.modal label.filters-toggle .ui.checkbox input:checked ~ label:before {
  background-color: #709dff !important;
}
.watch-collect-filters-trigger.ui.button {
  margin: 0;
}
.watch-collect-filters-trigger.ui.button i.ri-equalizer-line {
  font-weight: 400;
  font-size: 13px;
  margin-right: 8px;
}
