@import 'src/less/colors';

.container {
  display: inline-flex;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  border: 1px solid @SecondaryBlue20;
  background-color: white;
  border-radius: 8px;

  &:not(.disabled) {
    box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
  }

  &.disabled {
    background-color: @SecondaryBlue05;
  }

  &.big {
    height: 40px;

    .input {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.fluid {
    width: 100%;
  }

  .icon {
    font-size: 16px;
    color: @PrimaryCobalt40;
  }

  .input {
    flex: 1;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: @PrimaryCobalt;
    outline: none;

    &.inputDisabled {
      color: @PrimaryCobalt40;
    }
  }
}
