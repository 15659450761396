@import 'src/less/colors';

.kanbanColumn {
  height: 100%;
  display: flex;
  flex-direction: column;

  .columnHeader {
    color: @PrimaryCobalt;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0px 16px 12px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;

    h4  {
      margin: 0;
      line-height: 18px;
    }

    .itemCounter:global(.ui.label) {
      background-color: @SecondarySkyBlue20;
      color: @SecondaryBlue;
      padding: 4px 5px 4px 5px;
      margin-left: 8px;
    }
  }

  &.disabled {
    .columnHeader {
      color: @PrimaryCobalt60;
    }
    .itemCounter:global(.ui.label) {
      background-color: @SecondaryBlue10;
      color: @PrimaryCobalt60;
    }
  }

  .columnContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 200px;
    padding: 0px 16px 16px 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    .kanbanColumnAnchor,
    .kanbanAnchor {
      width: 100%;
      height: 0px;
      position: absolute;
      left: 0;
      width: 100%;
      border-radius: 2px;
      background-color: @SecondaryBlue80;
      pointer-events: none;
      transition: height 250ms;

      &.visible {
        height: 4px;
      }
    }

    .emptyStateCard {
      width: 100%;
      background-color: @SecondaryBlue10;
      border-radius: 8px;
      padding: 16px;
      text-align: center;
      color: @PrimaryCobalt60;
      font-family: Graphik;
      font-weight: 400;
      font-size: 14px;
    }

    .kanbanColumnAnchor {
      width: calc(100% - 32px);
      left: 16px;
    }

    .disabledColumnOverlay {
      position: absolute;
      height: calc(100% + 4px);
      width: calc(100% - 28px);
      top: -2px;
      left: 14px;
      border-radius: 4px;
      background-color: rgba(150, 150, 150, 0.4);
    }
  }

  .showMore {
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
    color: @PrimaryCobalt;
    margin-bottom: 24px;
  }

  a:last-child {
    margin-bottom: 24px;
  }
}
