/*
 * colors.less
 */
.click {
  overflow-x: hidden;
}
.click .sequenceName {
  font-size: 10px;
  font-family: Graphik;
  font-weight: 500;
  color: #4864c9;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.click .description {
  font-size: 10px;
  font-family: Graphik;
  font-weight: 400;
  color: #4c5892;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
