@import 'src/less/colors';

.loader:global(.ui.loader.centered) {
  margin: 4rem auto;
}

.pagination {
  padding: 0 8px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
