/*
 * colors.less
 */
.matching-score-label {
  display: inline-block;
  white-space: nowrap;
  font-weight: 600;
  font-family: Gilroy, sans-serif;
  color: #1f2e77;
  line-height: 1;
}
.matching-score-label.not-available.main-score {
  font-size: 12px;
  font-weight: 500;
  margin-left: 16px;
}
.matching-score-label .score-icon {
  font-weight: 500;
  color: #4864c9;
  margin-right: 4px;
  vertical-align: top;
}
.matching-label {
  border-radius: 2px;
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 0 8px;
  margin: 2px;
}
.matching-label.near-perfect {
  background-color: #3450b5;
}
.matching-label.high {
  background-color: #4864c9;
}
.matching-label.medium {
  background-color: #6d83d4;
}
.matching-label.low {
  background-color: #91a2df;
}
.matching-label.normal {
  background-color: #d2d5e4;
}
.matching-label.neutral {
  font-weight: 400;
  color: #7982ad;
  background-color: #f3f4f8;
}
