/*
 * colors.less
 */
.section {
  margin: 24px 0;
}
.section .title {
  color: #1f2e77;
}
.section .graph {
  margin-top: 16px;
  background: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.section .graph .loader {
  margin: 100px auto;
}
