/*
 * colors.less
 */
.project-filters.ui.popup {
  min-width: 300px;
}
.project-filters .add-button {
  display: flex;
  align-items: center;
}
.project-filters .tags-container {
  display: flex;
  flex-direction: column;
}
.project-filters .tags-container .project-filter-tag {
  padding: 8px;
  border-radius: 2px;
  background-color: #e2ebff;
  color: #3450b5;
  margin-bottom: 8px;
}
.project-filters .tags-container .project-filter-tag .project-name {
  font-weight: 500;
}
.project-filters .project-filters-checkbox {
  display: block;
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin-bottom: 24px;
}
.project-filters .project-filters-checkbox .project-filters-label {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik;
  color: #4c5892;
}
