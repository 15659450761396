/*
 * colors.less
 */
/*
 * typography.less
 */
body {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}
body .ui.input > input {
  font-family: Graphik, sans-serif !important;
}
input,
optgroup,
select,
textarea {
  font-family: Graphik, sans-serif;
}
h1,
.headline-1 {
  font-family: Gilroy, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}
h2,
.headline-2 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}
h3,
.headline-3 {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
h4,
.headline-4 {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
h5,
.headline-5 {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}
.body-semibold {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.body-medium {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.body-regular {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.caption-medium {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}
.caption {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1px;
}
.caption-2 {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
@media only screen and (min-width: 1200px) {
  .reveal-onboarding-modal.ui.large.modal {
    width: 1130px;
  }
}
#reveal-onboarding-dimmer {
  background-color: #1f2e77b3;
}
.reveal-onboarding-modal.ui.modal {
  border-radius: 6px;
}
.reveal-onboarding-modal.ui.modal .layout {
  display: flex;
}
.reveal-onboarding-modal.ui.modal .layout .layout-left {
  flex-shrink: 0;
  width: 505px;
  max-width: 50%;
  min-height: 530px;
  border-radius: 6px 0px 0px 6px;
  padding: 48px 40px 32px;
  image-rendering: -webkit-optimize-contrast;
}
.reveal-onboarding-modal.ui.modal .layout .visual0 {
  flex-shrink: 0;
  width: 505px;
  max-width: 50%;
  min-height: 530px;
  border-radius: 6px 0px 0px 6px;
  padding: 48px 40px 32px;
  image-rendering: -webkit-optimize-contrast;
  background-color: #e2ebff;
  background-image: url('/images/screenshots/crm-elements.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.reveal-onboarding-modal.ui.modal .layout .visual1 {
  flex-shrink: 0;
  width: 505px;
  max-width: 50%;
  min-height: 530px;
  border-radius: 6px 0px 0px 6px;
  padding: 48px 40px 32px;
  image-rendering: -webkit-optimize-contrast;
  background-color: #fee2e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reveal-onboarding-modal.ui.modal .layout .visual1 .loom-embed {
  position: relative;
  padding-bottom: 62.5%;
  height: 0;
  background-color: #4c5892;
  border-radius: 6px;
  margin-bottom: 16px;
}
.reveal-onboarding-modal.ui.modal .layout .visual1 .loom-embed iframe {
  position: absolute;
  border-radius: 6px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.reveal-onboarding-modal.ui.modal .layout .visual1 .embed-title {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #1f2e77;
  margin: 0;
}
.reveal-onboarding-modal.ui.modal .layout .visual2 {
  flex-shrink: 0;
  width: 505px;
  max-width: 50%;
  min-height: 530px;
  border-radius: 6px 0px 0px 6px;
  padding: 48px 40px 32px;
  image-rendering: -webkit-optimize-contrast;
  background-color: #a9c4ff;
  padding: 0;
  position: relative;
}
.reveal-onboarding-modal.ui.modal .layout .visual3 {
  flex-shrink: 0;
  width: 505px;
  max-width: 50%;
  min-height: 530px;
  border-radius: 6px 0px 0px 6px;
  padding: 48px 40px 32px;
  image-rendering: -webkit-optimize-contrast;
  background-color: #fee2e5;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.reveal-onboarding-modal.ui.modal .layout .layout-right {
  display: flex;
  flex-direction: column;
  min-height: 573px;
  flex-grow: 1;
  padding: 48px 40px 32px;
}
.reveal-onboarding-modal.ui.modal .step {
  flex-grow: 1;
  margin-top: 24px;
}
.reveal-onboarding-modal.ui.modal .step .step-title {
  color: #1f2e77;
  font-weight: 700;
  font-family: Gilroy;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}
.reveal-onboarding-modal.ui.modal .step .step-subtitle {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  margin-bottom: 18px;
}
.reveal-onboarding-modal.ui.modal .step .step-paragraph {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .list .list-item {
  margin-bottom: 24px;
  display: flex;
}
.reveal-onboarding-modal.ui.modal .list .list-item .number {
  font-family: Gilroy;
  font-weight: 700;
  color: #f9c8ca;
  font-size: 16px;
  line-height: 20px;
  width: 12px;
  height: 20px;
  margin-right: 8px;
  flex-shrink: 0;
}
.reveal-onboarding-modal.ui.modal .list .list-item .content {
  flex-grow: 1;
}
.reveal-onboarding-modal.ui.modal .list .list-item .content .title {
  font-family: Gilroy;
  font-weight: 700;
  color: #1f2e77;
  font-size: 16px;
  line-height: 20px;
}
.reveal-onboarding-modal.ui.modal .list .list-item .content .description {
  font-family: Graphik;
  font-size: 14px;
  line-height: 20px;
  color: #1f2e77;
}
.reveal-onboarding-modal.ui.modal .list div.description {
  margin-top: 4px;
}
.reveal-onboarding-modal.ui.modal .list .green-pill {
  margin-left: 6px;
}
.reveal-onboarding-modal.ui.modal .step0 {
  flex-grow: 1;
  margin-top: 24px;
}
.reveal-onboarding-modal.ui.modal .step0 .step-title {
  color: #1f2e77;
  font-weight: 700;
  font-family: Gilroy;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}
.reveal-onboarding-modal.ui.modal .step0 .step-subtitle {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  margin-bottom: 18px;
}
.reveal-onboarding-modal.ui.modal .step0 .step-paragraph {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .step0 .description {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.reveal-onboarding-modal.ui.modal .step1 {
  flex-grow: 1;
  margin-top: 24px;
}
.reveal-onboarding-modal.ui.modal .step1 .step-title {
  color: #1f2e77;
  font-weight: 700;
  font-family: Gilroy;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}
.reveal-onboarding-modal.ui.modal .step1 .step-subtitle {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  margin-bottom: 18px;
}
.reveal-onboarding-modal.ui.modal .step1 .step-paragraph {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .step2 {
  flex-grow: 1;
  margin-top: 24px;
}
.reveal-onboarding-modal.ui.modal .step2 .step-title {
  color: #1f2e77;
  font-weight: 700;
  font-family: Gilroy;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}
.reveal-onboarding-modal.ui.modal .step2 .step-subtitle {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  margin-bottom: 18px;
}
.reveal-onboarding-modal.ui.modal .step2 .step-paragraph {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .step2 .step2-button-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.reveal-onboarding-modal.ui.modal .step2 .step2-button-container .step2-synchronized {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .step3 {
  flex-grow: 1;
  margin-top: 24px;
}
.reveal-onboarding-modal.ui.modal .step3 .step-title {
  color: #1f2e77;
  font-weight: 700;
  font-family: Gilroy;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}
.reveal-onboarding-modal.ui.modal .step3 .step-subtitle {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  margin-bottom: 18px;
}
.reveal-onboarding-modal.ui.modal .step3 .step-paragraph {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector {
  margin: 12px 0;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 12px;
  gap: 12px;
  height: 64px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector:not(:disabled) {
  cursor: pointer;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector:not(:disabled):hover {
  transition: border-color ease 300ms;
  border-color: #91a2df;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector .step3-mail-logo-container {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector .step3-mail-logo-container .step3-mail-logo {
  width: 30px;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector .step3-mail-cta {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector .step3-mail-cta.connected {
  color: #7982ad;
}
.reveal-onboarding-modal.ui.modal .step3 .step3-mail-connector .step3-mail-check {
  flex: 1;
  text-align: right;
  font-size: 40px;
  color: #b6c1e9;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list {
  margin-top: -4px;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list .call-to-action {
  border-radius: 5px;
  border: 1px solid #d2d5e4;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 11px 15px;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list .call-to-action .icon {
  font-size: 24px;
  flex-shrink: 0;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list .call-to-action .icon i {
  color: #a5abc9;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list .call-to-action .content {
  flex-grow: 1;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list .call-to-action .ui.button {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  padding: 7.5px 12px;
}
.reveal-onboarding-modal.ui.modal .step3 .call-to-action-list .call-to-action:not(:last-child) {
  margin-bottom: 8px;
}
.reveal-onboarding-modal.ui.modal .actions {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reveal-onboarding-modal.ui.modal .actions .ui.button {
  vertical-align: middle;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding: 9px 16px;
}
.reveal-onboarding-modal.ui.modal .actions .ui.button i.middle {
  vertical-align: middle;
}
.reveal-onboarding-modal.ui.modal .pill {
  display: inline-block;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 16px;
  font-family: Graphik;
  font-weight: 500;
  border-radius: 9999px;
}
.reveal-onboarding-modal.ui.modal .green-pill {
  display: inline-block;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 16px;
  font-family: Graphik;
  font-weight: 500;
  border-radius: 9999px;
  background-color: #28ca421a;
  color: #14b62e;
}
.reveal-onboarding-modal.ui.modal .blue-pill {
  display: inline-block;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 16px;
  font-family: Graphik;
  font-weight: 500;
  border-radius: 9999px;
  background-color: #e2ebff;
  color: #5c89eb;
}
