@import 'src/less/colors';
@import 'src/less/mixins';
@import 'src/utils';

.formSection {
  width: calc(100% - 80px;);
  margin: auto;
  margin-top: 24px;
  .loaderContainer {
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  .title {
    font-family: Gilroy;
    font-weight: 700;
    font-size: 18px;
    color: @PrimaryCobalt;
    margin-bottom: 8px;
  }

  .separator {
    border-bottom: 1px solid #e5e5e5;
    height: 0px;
    width: 100%;
    background: #e5e5e5;
  }

  .section {
    margin-top: 20px;
    margin-bottom: 30px;

    &.visible {
      background-color: white;
      border: 1px solid @SecondaryBlue20;
      border-radius: 8px;
      padding: 24px 24px 0px 24px;
    }

    .segment {
      background-color: @SecondarySkyBlue10;
      color: @PrimaryCobalt60;
      font-weight: 500;
      padding: 5px 8px;
      border-radius: 5px;
      margin-bottom: 12px;
    }

    .description {
      color: @PrimaryCobalt60;
    }

    .settingsPlaceholder {
      text-align: center;
      margin-bottom: 24px;
    }

    .inputContainer {
      margin-bottom: 24px;

      .inputLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 8px;
        color: @PrimaryCobalt80;

        .noEmphasis {
          font-size: 12px;
          font-weight: 400;
          font-style: italic;
        }
      }

      .inputDescription {
        font-size: 12px;
        color: @PrimaryCobalt40;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .settings-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5em;
    gap: 8px;

    .input {
      flex-grow: 1;
    }

    .ui.button.primary {
      margin-left: 10px;
    }
  }

  .settings-header {
    h1 {
      display: inline;
    }
    .pill-message {
      margin-left: 10px;
      vertical-align: top;
    }

    .settings-submit {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-end;
      overflow-y: visible;
    }
  }
}
