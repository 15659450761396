/*
 * colors.less
 */
.modalDescription {
  font-family: Graphik;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1em;
  letter-spacing: 0px;
  color: #7982ad;
}
.renameStepsModalContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.renameStepsModalContainer .header {
  display: flex;
  gap: 34px;
  margin-bottom: -6px;
}
.renameStepsModalContainer .header div:first-of-type {
  width: 40%;
}
.renameStepsModalContainer .header .sectionName {
  margin-bottom: 4px;
}
.renameStepsModalContainer .stepContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.renameStepsModalContainer .stepContainer .originalStep {
  width: 40%;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  background-color: #f6f7fc;
  color: #4c5892;
  border: 1px solid #dae0f4;
  padding: 10px 12px;
}
.renameStepsModalContainer .stepContainer .newStepContainer {
  flex-grow: 1;
}
