/*
 * colors.less
 */
.title {
  color: #1f2e77;
  margin-bottom: 10px;
}
.addContactButton {
  cursor: pointer;
  width: fit-content;
  margin: 12px 0 0 0;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #7982ad;
  font-family: 'Gilroy', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
}
.emptyState {
  margin: 8px 0;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #edf0fa;
  text-align: center;
  color: #7982ad;
}
.card {
  display: block;
  margin: 8px 0;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #edf0fa;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  transition: border-color 150ms;
}
.card:hover {
  border-color: #91a2df;
}
.cardTitle {
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.cardTitle .divider {
  color: #7982ad;
}
.cardTitle > div {
  margin-top: 0px;
  margin-left: 0px;
}
.cardTitle img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.cardTitle .headline {
  color: #4c5892;
  font-size: 12px;
}
.contactSelectorContainer {
  width: 100%;
  min-width: 100%;
  display: inline-block;
  height: inherit;
  display: flex;
  flex-direction: column;
}
.contactSelectorContainer .description {
  color: #4c5892;
}
.contactSelectorContainer > div {
  padding: 0px 24px;
}
.contactSelectorContainer > div:first-of-type {
  padding: 24px 24px 0px 24px;
}
.contactSelectorContainer > div:last-of-type {
  padding: 0px 24px 24px 24px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}
.contactSelectorContainer > div:last-of-type::-webkit-scrollbar {
  display: none;
}
.contactSelectorContainer .contactSearch {
  width: 100%;
  margin-top: 24px;
  flex-shrink: 0;
}
.contactSelectorContainer .contactsContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  height: 400px;
}
.contactSelectorContainer .contactsContainer .emptyState {
  color: #7982ad;
  text-align: center;
}
.contactSelectorContainer .contactsContainer .contact {
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #1f2e771a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid transparent;
}
.contactSelectorContainer .contactsContainer .contact.active {
  border: 1px solid #4864c9;
}
.contactSelectorContainer .contactsContainer .contact .contactHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}
.contactSelectorContainer .contactsContainer .contact .contactHeader .contactAvatar {
  width: 40px;
  height: 40px;
}
.contactSelectorContainer .contactsContainer .contact .contactHeader .contactName {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  display: inline;
}
