/*
 * colors.less
 */
.job-calibration-view > a {
  display: flex;
}
.job-calibration-view h3 {
  margin-bottom: 4px;
  margin-top: 14px;
}
.job-calibration-view .sub-title {
  color: #7982ad;
  font-size: 12px;
  margin-bottom: 18px;
}
.job-calibration-view .job-calibration-header {
  display: flex;
  justify-content: space-between;
}
.job-calibration-view .job-calibration-header .candidates-count {
  margin-top: 14px;
}
.job-calibration-view .job-calibration-header .candidates-count .count {
  text-align: right;
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  color: #4864c9;
  margin-bottom: 4px;
}
.job-calibration-view .job-breadcrumb-dropdown {
  display: flex;
  flex-direction: row;
  white-space: pre;
  align-items: flex-end;
}
.job-calibration-view .job-breadcrumb-dropdown .job-title {
  font-weight: 600;
  color: #4864c9;
  margin-top: 2px;
}
.job-calibration-view .job-calibration-main-content {
  display: flex;
}
.job-calibration-view .job-calibration-main-content .items-selector {
  width: 240px;
  margin-top: 0px;
}
.job-calibration-view .job-calibration-main-content .button.save-button {
  width: 100%;
}
.job-calibration-view .job-calibration-main-content .pure-criteria-form {
  margin-top: 0px;
  margin-left: 24px;
  flex-grow: 1;
}
.job-calibration-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.job-calibration-header h2 {
  margin: 0;
}
