@import 'src/less/colors.less';

.title {
  display: flex;
  font-family: Gilroy;
  font-weight: 700;
  color: @PrimaryCobalt;
  font-size: 14px;
  margin-top: 12px;
  cursor: pointer;
}
