.contact-flow-editor {
  width: 100%;
}
.contact-flow-editor .sequence-tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.contact-flow-editor .sequence-tab {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-right: none;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
}
.contact-flow-editor .sequence-tab.selected {
  font-weight: 600;
  background-color: #F8F8F8;
}
.contact-flow-editor .sequence-tab.tab-add-sequence {
  width: 50px;
}
.contact-flow-editor .sequence-tab.tab-add-sequence i {
  height: 17px;
  width: 17px;
  margin: 0;
}
.contact-flow-editor .contact-flow-tab-content {
  background-color: #F8F8F8;
  padding: 40px 30px 30px 30px;
}
.contact-flow-editor .sequence-trigger-selector {
  background-color: white;
  padding: 20px;
}
