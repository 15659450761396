/*
 * colors.less
 */
.table {
  border-radius: 8px !important;
}
.table .header {
  background: #f8faff !important;
  border: 1px solid #dae0f4 !important;
}
.table .header .headerRow .th:first-of-type {
  border-top-left-radius: 8px;
}
.table .header .headerRow .th:last-of-type {
  border-top-right-radius: 8px;
}
.table .header .th {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  color: #7982ad;
}
.table .row {
  background: white !important;
  border-bottom: 1px solid #dae0f4 !important;
}
.table .row:last-of-type .name {
  border-bottom-left-radius: 8px;
}
.table .row:last-of-type .actions {
  border-bottom-right-radius: 8px;
}
.table .row .actionPanel {
  width: fit-content;
}
.table .row .actionPanel .actionPanelItem span {
  margin-right: 8px;
  color: #7982ad;
}
.table .row .name {
  color: #4864c9 !important;
  font-weight: 600 !important;
}
.table .row .subtype {
  color: #1f2e77 !important;
}
.table .row .sectionsContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
}
.table .row .sectionContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.table .row .subSectionsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.table .row .icon {
  margin: 0px 0px;
  color: #7982ad;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
