/*
 * colors.less
 */
.genericTitle {
  margin: 0;
  padding: 0;
}
.genericTitle.Gilroy {
  font-family: Gilroy;
}
.genericTitle.Graphik {
  font-family: Graphik;
}
.genericTitle:is(h5) {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
}
.genericTitle.cobalt {
  color: #1f2e77;
}
