/*
 * colors.less
 */
.project-profiles-view {
  margin: 24px 0;
  padding: 0 40px;
}
.project-profiles-view.kanban-display {
  margin: 24px 0 0;
  height: calc(100% - 116px - 24px);
}
.project-profiles-view .display-type-container {
  display: flex;
  margin-bottom: 24px;
}
.project-profiles-view .display-type {
  color: #7982ad;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  gap: 8px;
  align-items: center;
}
.project-profiles-view .display-type.display-type-active {
  color: #4864c9;
  background-color: #e2ebff;
}
.project-profiles-tab.kanban-display {
  height: 100%;
}
.project-profiles-tab nav {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.project-profiles-tab .ui.button.calibration-button {
  display: inline-block;
  background-color: white;
  text-align: center;
  margin-bottom: 24px;
}
.project-profiles-tab .ui.button.calibration-button i {
  vertical-align: bottom;
}
.project-profiles-tab .button-container {
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}
.project-profiles-tab .button-container .tab-button {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 12px;
  background-color: transparent;
  color: #b6c1e9;
}
.project-profiles-tab .button-container .tab-button.active {
  color: #4864c9;
  background-color: #e2ebff;
}
.project-profiles-tab .button-container .move-to-stage-button {
  line-height: 22px !important;
}
.project-profiles-tab .button-container .reveal-stage-prev {
  vertical-align: bottom;
}
.project-profiles-tab .button-container .selected-profiles {
  margin-left: 1em;
  align-self: center;
  font-weight: 500 !important;
  font-family: Graphik, sans-serif !important;
}
.project-profiles-tab .button-container .selected-profiles .select-all-profiles {
  color: #4864c9;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 16px;
}
.project-profiles-tab .sequence-profiles-list-header {
  border-top: 1px solid #e2ebff;
}
.project-profiles-tab .project-profiles-table {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
  border: 1px solid #dae0f4;
}
.project-profiles-tab .empty-profiles-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #1f2e77;
}
.project-profiles-tab .empty-profiles-page .image-placeholder {
  margin-top: 42px;
  width: 456px;
}
.project-profiles-tab .empty-profiles-page button {
  margin-top: 3em;
}
.project-profiles-tab .profiles-page-container {
  flex-grow: 1;
  border-bottom: 1px solid #dae0f4;
}
.project-profiles-tab .candidates-pagination.ui.pagination {
  box-shadow: none;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0px;
  align-self: flex-start;
}
.project-profiles-tab .project-profiles-status-filter.ui.dropdown {
  margin-right: 1em;
  font-family: Gilroy, sans-serif;
  color: #4c5892;
  font-weight: 600;
}
.project-profiles-tab .project-profiles-status-filter.ui.dropdown .text {
  font-weight: 600 !important;
}
.project-profiles-tab .relative {
  position: relative;
}
.project-profiles-tab .dropdown-button i {
  height: 32px !important;
  font-size: 12px !important;
  padding-left: 10px !important;
}
.project-profiles-table .mission-filter-by-text {
  height: 32px;
  margin-right: 16px;
}
.project-profiles-table .mission-filter-by-text input {
  width: 200px;
  font-size: 12px;
  padding: 0 1em 0;
  border-radius: 32px;
}
.project-profiles-table .mission-filter-by-text input::placeholder {
  color: #7982ad;
}
.project-profiles-table .profile-per-page-dropdown {
  max-height: 32px;
  height: 32px;
  padding: 0;
  font-size: 12px;
  margin-right: 1.5em;
  line-height: 10px;
}
