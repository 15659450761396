@import 'src/less/colors';
@import 'src/less/mixins';

.linkedinAccountsSettingsTable {
  border: none !important;
  margin-bottom: 20px !important;

  .linkedinAccountsTableHeader {
    .linkedinAccountsTableHeaderItem {
      text-align: left;
      font-size: 12px;
      padding-top: 2px;
      padding-bottom: 2px;
      text-transform: uppercase;
      color: @PrimaryCobalt60;
    }

    .actionTableHeaderItem {
      text-align: right;
      font-size: 14px;
      padding-right: 16px;
    }
  }

  .tableCell {
    text-align: left !important;

    &.flex {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 2px;
    }
  }

  .noWrap {
    white-space: nowrap;
  }

  .actionTableCell {
    text-align: center !important;

    :first-child {
      cursor: pointer;
    }
  }

  .contextMenu .contextBtnContainer {
    justify-content: center !important;
  }

  // USER THUMBNAILS
  div.box-title {
    margin-top: 10px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .linkedinAccountsTableRowActions {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    cursor: pointer;
  }

  .synchronizationsStatus {
    .PillMessage();
    .PillMessage.blue();
    .PillMessage.small();

    &:not(last-of-type) {
      margin-right: 4px;
    }
  }
}
