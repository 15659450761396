@import 'src/less/colors';

.visual {
  position: absolute;
  inset: 0;
  background-color: @SecondaryRed20;
}

.bubble {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 232px;
  color: @SecondaryRed40;
}

.logos {
  display: flex;
  position: absolute;
  inset: 100px 0 0 auto;
  width: 100%;
  gap: 16px;
  justify-content: center;
}

.mockup {
  position: absolute;
  bottom: 0;
  left: 9%;
  width: 82%;
  box-shadow: 0 8px 32px -2px @PrimaryCobaltOp15;
}
