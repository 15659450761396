/*
 * colors.less
 */
.sobo-dropdown-item {
  color: #404564 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.sobo-dropdown-divider {
  border-top: 1px solid #dae0f4 !important;
  height: 0 !important;
  margin: 0 !important;
}
.email-style {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #4864c9;
}
.email-body:has(.email-info
    .email-info-container
    .default-template-replies-container) .email-body-editor {
  margin-top: 0px;
}
.email-body:has(.email-info
    .email-info-container
    .default-template-replies-container) .email-body-editor .editor-container {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: 0px;
}
.email-body:has(.editor-container.focus) .default-template-replies-container {
  border-color: #5c89eb;
}
.email-info-container {
  width: 100%;
}
.email-info-container .sender-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.email-info-container .sender-container .bc-selector {
  flex-shrink: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.email-info-container .sender-container .bc-selector span {
  cursor: pointer;
  text-decoration: underline;
}
.email-info-container .sender-container .bc-selector span:first-of-type {
  margin-right: 8px;
}
.email-info-container .input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0px !important;
}
.email-info-container .input-container .intro-word {
  color: #7982ad;
  font-family: Graphik !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}
.email-info-container .input-container .pill-message {
  display: inline-flex;
  align-items: center;
  height: 18px;
  padding: 0px 8px !important;
}
.email-info-container .input-container .pill-message .delete-cc {
  margin-left: 8px;
  cursor: pointer;
}
.email-info-container .input-container .pill-message span:focus-visible {
  outline: none;
}
.email-info-container .input-container .input-element {
  flex-grow: 1;
  font-size: 12px;
  min-width: 150px;
  display: flex;
}
.email-info-container .input-container .input-element .input,
.email-info-container .input-container .input-element input {
  flex-grow: 1;
  border: none;
  padding: 0px;
}
.email-info-container .input-container .input-cancel {
  cursor: pointer;
}
.email-info-container .input-container .input-cancel svg {
  color: #7982ad;
  height: 9px;
}
.email-info-container .sender-and-recipient {
  line-break: anywhere;
  margin-top: 0px !important;
  max-width: 100%;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #4864c9;
}
.email-info-container .sender-and-recipient .intro-word {
  font-weight: 400;
  color: #7982ad !important;
}
.email-info-container .sender-and-recipient .recipient-input {
  margin-left: -1px;
  cursor: text;
  display: flex;
}
.email-info-container .sender-and-recipient .recipient-input input {
  cursor: text;
  border: none !important;
  padding: 0px !important;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #4864c9;
  outline-width: 0;
}
.email-info-container .sender-and-recipient .recipient-input input::placeholder {
  color: #4864c9 !important;
}
.email-info-container .sender-and-recipient .recipient-input input:disabled {
  opacity: 1 !important;
}
.email-info-container .sender-and-recipient .recipient-input.error {
  color: #f86e7f;
}
.email-info-container .sender-and-recipient .recipient-input.error input {
  color: #f86e7f;
  font-weight: 600 !important;
}
.email-info-container .sender-and-recipient .sender,
.email-info-container .sender-and-recipient .recipient {
  text-overflow: ellipsis;
}
.email-info-container .sender-and-recipient .sender .span,
.email-info-container .sender-and-recipient .recipient .span {
  white-space: break-spaces !important;
}
.email-info-container .sender-and-recipient i.recipient-icon {
  color: #709dff;
  cursor: pointer;
  vertical-align: bottom;
}
.email-info-container .sender-and-recipient.minimized {
  margin-top: 4px;
}
.email-info-container .sender-and-recipient.minimized .sender,
.email-info-container .sender-and-recipient.minimized .recipient {
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-info-container .default-template-replies-container {
  border: 1px solid #dae0f4;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 8px 8px 8px;
  color: #7982ad;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  font-family: 'Graphik';
  gap: 2px;
}
.email-info-container .default-template-replies-container .default-template-replies {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}
.email-info-container .default-template-replies-container .default-template-replies .pill-message {
  cursor: pointer;
  padding: 4px 8px !important;
  margin: 0;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
