/*
 * colors.less
 */
.section {
  margin: 16px 0;
}
.section .description {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
}
.section .description .selector {
  flex-grow: 1;
}
