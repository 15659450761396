/*
 * colors.less
 */
.watch-new-tag.ui.label {
  padding: 0 10px;
  margin-left: 8px;
  color: #28ca42;
  background-color: #EAFAED;
  font-size: 12px;
  font-family: Gilroy, sans-serif;
  height: 20px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.watch-new-tag.ui.label .new-label-text {
  font-weight: 600;
  margin-top: 2px;
}
.watch-new-tag.ui.label svg {
  margin-left: -2px;
  margin-right: 0.5em;
}
