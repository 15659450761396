@import 'src/less/colors';

.section {
  background: #ffffff;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  margin-top: 16px;

  .header {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 11px 0 24px;
    border-bottom: 1px solid @SecondaryBlue20;

    .title {
      color: @PrimaryCobalt;
      margin: 0;
    }
  }

  .content {
    padding: 24px;

    .loader {
      margin: 100px auto;
    }
  }
}
