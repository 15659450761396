@import 'src/less/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 26px;
    color: @PrimaryCobalt;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 100px;

  .loader:after {
    border-color: @PrimaryCobalt transparent transparent !important;
  }

  .checkmark {
    background-color: @PrimaryCobalt;
    color: white;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    margin: auto;
  }

  .modalSuccess {
    text-align: center;
    svg {
      margin-bottom: 24px;
    }
  }

  .cross {
    background-color: @SecondaryRed;
    color: white;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    margin: auto;
  }

  .inputLabel {
    margin-bottom: 6px !important;
    font-weight: 500 !important;
  }
}

.formField {
  &:first-of-type {
    margin-bottom: 16px;
  }
  .formLabel {
    margin: 0px 0px 4px 0px;
    display: block;
    color: @PrimaryCobalt;
    font-family: Graphik, sans-serif;
    font-weight: 500;
    font-size: 14px;
  }
}

.confirmationModalActions {
  &.reversed {
    flex-direction: row-reverse;
  }
}

.description {
  color: @PrimaryCobalt60;
  margin-bottom: 20px;
}

.editApiKey {
  text-decoration: underline;
  cursor: pointer;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.deleteModalContent {
  color: @PrimaryCobalt;
}
