/*
 * colors.less
 */
.pipeline-graph {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 122px;
}
.pipeline-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1f2e77;
}
.pipeline-metric-label-tooltip {
  display: inline-block;
  margin-bottom: 2px;
}
.pipeline-metric-value {
  font-family: Gilroy;
  font-weight: 800;
  white-space: nowrap;
}
.pipeline-metric-main {
  font-size: 26px;
  line-height: 1;
}
.pipeline-metric-secondary {
  font-size: 14px;
  margin-left: 5px;
}
.pipeline-metric-loader {
  height: 40px;
  width: 100px;
}
