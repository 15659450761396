/*
 * colors.less
 */
.custom-criteria-filters .subtitle {
  margin-top: 8px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.custom-criteria-filters .default-button i {
  margin-right: 12px;
}
.custom-criteria-filters .accordion-filter {
  border: none;
}
.custom-criteria-filters .filter-list {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0px;
}
.custom-criteria-filters .filter-list .filter-button {
  position: relative;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 3px;
  background: #e2ebff;
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #3450b5;
  margin-bottom: 4px;
  margin-right: 4px;
}
.custom-criteria-filters .filter-list .filter-button .bold {
  font-weight: 700;
}
.custom-criteria-filters .filter-list .filter-button .cross {
  cursor: pointer;
  background-color: #c6d8ff;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 4px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.custom-criteria-filters .filter-list .filter-button .cross i {
  color: #5c89eb;
  margin: auto;
  width: 14px;
  height: 14px;
}
.custom-criteria-filters .filter-list .filter-button:hover {
  background: #c6d8ff;
}
.custom-criteria-filters .filter-list .filter-button:hover .cross {
  transition: all 0.2s;
  opacity: 1;
}
.custom-criteria-filters .filter-list .filter-button.hasError {
  background: #fcc5cc;
  color: #e45a6b;
}
.custom-criteria-filters .filter-list .filter-button.hasError .cross {
  background: #fba8b2;
}
.custom-criteria-filters .filter-list .filter-button.hasError .cross i {
  color: #e45a6b;
}
.custom-criteria-filters .filter-list .filter-button.hasError:hover {
  background: #fba8b2;
}
.custom-criteria-filters:last-of-type .accordion-filter {
  margin-bottom: 16px;
}
.custom-criteria-pop-in {
  padding: 0px !important;
}
.custom-criteria-pop-in .search {
  margin: 12px;
}
.custom-criteria-pop-in .dropdown-row {
  cursor: pointer;
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #1f2e77;
  padding: 12px;
}
.custom-criteria-pop-in .dropdown-row:hover {
  background-color: #edf0fa;
}
.custom-criteria-pop-in .property-details-wrapper {
  min-width: 200px;
  padding: 12px;
  background-color: #f8faff;
  box-shadow: inset 0px 1px 0px #DAE0F4;
}
.custom-criteria-pop-in .property-details-wrapper hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 15px;
}
.custom-criteria-pop-in .property-details-wrapper .label-divider {
  padding: 8px 12px;
  padding-bottom: 4px;
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #a5abc9;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper {
  margin-bottom: 10px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .radio-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .radio-row .radio {
  height: 18px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .radio-row .label {
  font-family: Graphik;
  line-height: 20px;
  color: #1f2e77;
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  margin-left: 8px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .radio-row .labelWithoutMargin {
  margin-left: 0px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .noMargin {
  margin: 0px !important;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector {
  padding: 0px 12px;
  margin-bottom: 20px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .datepicker,
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown {
  background: #ffffff;
  border: 1px solid #e2ebff;
  box-sizing: border-box;
  border-radius: 2px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown .dropdown.icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0px, -50%);
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown .text {
  width: 100%;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown .menu {
  width: 100%;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown .item:hover,
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .multi-dropdown .item.selected {
  color: #1f2e77;
  background-color: #edf0fa;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .delay-input {
  display: flex;
  width: 252px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .delay-input .delay-count-input {
  min-width: 65px;
  flex: 1 0 65px;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .delay-input .delay-count-input input {
  border-color: #dae0f4;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-criteria-pop-in .property-details-wrapper .radio-wrapper .property-details-selector .delay-input .delay-type-input {
  flex: 0 0;
  min-width: auto;
  white-space: nowrap;
  padding-left: 0px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #dae0f4;
}
.custom-criteria-pop-in .property-details-wrapper .save-button {
  cursor: pointer;
  padding: 8px;
  border-top: 1px solid #d2d5e4;
  text-align: center;
  font-family: Gilroy;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-radius: 8px;
  width: 100%;
  background-color: #4864c9;
}
