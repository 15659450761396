@import 'src/less/colors';

.wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .statementWrapper {
    border: 1px solid @SecondaryBlue20;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .simplifiedStatementWrapper {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    padding-left: 0;
    flex: 1;
  }

  .simplifiedStatementFieldWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .simplifiedStatementInput {
    width: 100%;
    margin: 0 !important;
  }

  .changeInputModeWrapper {
    cursor: pointer;
  }

  .ifStatementHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .andConditions {
    display: flex;
    flex-direction: column;
  }

  .subConditions {
    padding-left: 150px;
  }

  .button {
    align-self: flex-end;
    margin: 10px 0;
  }

  .clearIcon {
    color: #7982ad;
    cursor: pointer;
  }

  .input {
    flex: 1;
    border-radius: 8px !important;
    display: block;
    box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
    input {
      border-radius: 8px !important;
      border: 1px solid @SecondaryBlue20 !important;
      width: 100% !important;
    }
  }

  .previewTitle {
    font-family: 'Gilroy';
    font-weight: 700;
    font-size: 16px;
    color: @PrimaryCobalt;
    margin-bottom: 0;
  }

  .previewSubtitle {
    font-family: 'Graphik';
    font-weight: 400;
    font-size: 14px;
    color: @SecondaryBlue;
    display: block;
    margin: 20px 0;
  }
}

.previewOutput {
  padding: 16px;
  background: #f1f6ff;
  border-radius: 8px;
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: @SecondaryBlue;
  display: flex;
  gap: 5px;
  align-items: center;
}

.statement {
  label {
    min-width: 100px;
  }
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px !important;
  flex: 1;
}

.columnStatement {
  flex-direction: column;
  align-items: unset;
  gap: 2px;
  margin-top: 10px !important;
}

.popup {
  top: -35px !important;
  left: -5px !important;
}
