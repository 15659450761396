.job-posting-description {
  .job-posting-description-section {
    white-space: pre-line;
    margin-bottom: 48px;

    .job-posting-description-section-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .input-label {
        display: flex;
        i {
          margin-left: 4px;
          cursor: pointer;
        }
      }

      button {
        margin-bottom: 10px;
      }
    }
  }

  .add-section-container {
    margin-bottom: 24px;
    display: flex;
    gap: 8px;
  }

  .video-container,
  .image-container {
    @margin: 48px;
    margin-left: @margin;
    margin-right: @margin;
  }

  .input-element .image-container {
    margin-top: 24px;
  }

  .player-wrapper {
    position: relative;
    padding-top: calc(720 / 1280 * 100%);

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
