/*
 * colors.less
 */
.bullet {
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 4px;
  align-items: center;
}
.bullet:not(:last-child) {
  margin-bottom: 24px;
}
.bullet .index {
  grid-column: 1;
  grid-row: 1;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  color: #f9c8ca;
}
.bullet .title {
  grid-column: 2;
  grid-row: 1;
  font-family: Gilroy;
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  color: #1f2e77;
}
.bullet .description {
  grid-column: 2;
  grid-row: 2;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: #7982ad;
}
