.blurLine {
  height: 8px;
  border-radius: 16px;
  width: 100%;
  display: block;
  background: linear-gradient(270deg, #dae0f4 -0.59%, #b6c1e9 100%);
  max-width: 250px;
}
.blurLine.inverted {
  background: linear-gradient(270deg, #b6c1e9 100%, #dae0f4 -0.59%);
}
.blurParagraph {
  width: 100%;
}
.blurParagraph .blurLine {
  max-width: calc(100% - var(--index-percent, 0%));
}
.blurParagraph .blurLine:not(:last-child) {
  margin-bottom: 8px;
}
