/*
 * colors.less
 */
.offer-recruiter-configuration {
  width: 100%;
}
.offer-recruiter-configuration h3.sender-title {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  color: #1f2e77;
  margin-bottom: 16px;
}
.offer-recruiter-configuration .offer-card-box {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}
.offer-recruiter-configuration .offer-card-box.error {
  border: 1px solid #f66f81;
}
.offer-recruiter-configuration .offer-card-sender-text {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 15px;
}
.offer-recruiter-configuration .offer-card-sender-text .full-name {
  font-weight: 600;
  color: #4864c9;
}
.offer-recruiter-configuration .offer-card-sender-text .email {
  margin-top: 4px;
  font-weight: 400;
  color: #7982ad;
}
.offer-recruiter-configuration .offer-card-sender-text .email.error {
  color: #f66f81;
}
.offer-recruiter-configuration .offer-card-error-text {
  padding: 0 5px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  color: #f86e7f;
  width: 380px;
}
.offer-recruiter-configuration .configure-text {
  padding: 0 2px;
  font-size: 12px;
  color: #709dff;
  cursor: pointer;
}
.offer-recruiter-configuration .configure-text:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #709dff;
}
