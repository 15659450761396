/*
 * colors.less
 */
.dropdown {
  width: 100%;
}
.trigger {
  display: block;
  width: 100%;
  padding: 0;
  font-family: Graphik;
  text-align: left;
  color: #1f2e77;
}
