/*
 * colors.less
 */
.reveal-profile-top-header-container {
  z-index: 1;
  background-color: white;
  padding: 12px 24px 2px;
  min-height: 110px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
.reveal-profile-top-header-container .avatar-and-infos {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 56.75%;
  min-width: 0px;
}
.reveal-profile-top-header-container .avatar-and-infos .avatar {
  position: relative;
  flex-shrink: 0;
}
.reveal-profile-top-header-container .avatar-and-infos .avatar img {
  width: 72px;
  max-height: 72px;
}
.reveal-profile-top-header-container .avatar-and-infos .avatar .last-sync-icon {
  background-color: white;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  color: #28ca42;
  position: absolute;
  bottom: 0px;
  left: 5px;
  font-size: 18px;
}
.reveal-profile-top-header-container .avatar-and-infos .avatar .last-sync-icon.not-updated {
  color: #a5abc9;
}
.reveal-profile-top-header-container .avatar-and-infos .avatar .last-sync-icon:before {
  margin-top: -1px;
  display: block;
}
.reveal-profile-top-header-container .avatar-and-infos .infos {
  margin: 0px 16px;
  min-width: 0;
  flex: 1;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .fullname {
  display: flex;
  color: #4864c9;
  font-family: 'Gilroy';
  margin-right: 6px;
  line-height: 27px;
  margin-bottom: 0px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .icons-container {
  height: 20px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .icons-container button {
  color: #4864c9;
  padding: 0px 0px 0px 4px;
  height: 16px;
  font-size: 14px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .headline {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-family: 'Graphik';
  font-weight: 500;
  font-style: normal;
  margin-bottom: 0px;
  color: #1f2e77;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container {
  color: rgba(17, 46, 72, 0.35);
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container .hover-icon {
  padding: 0;
  margin-right: 2px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container .hover-icon i {
  cursor: pointer;
  font-size: 16px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container .search-linkedin {
  cursor: pointer;
  color: #4864c9;
  margin-left: 4px;
  font-size: 12px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container .search-linkedin:hover {
  text-decoration: underline;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container .download-icon {
  margin-left: -4px;
}
.reveal-profile-top-header-container .avatar-and-infos .infos .sources-container div .source-link {
  color: #a5abc9 !important;
  /*          img {
              width: 11px;
              height: 11px;
              margin-bottom: 4px;
            } */
}
.reveal-profile-top-header-container .inline-middle {
  display: inline-block;
  vertical-align: middle;
}
.reveal-profile-top-header-container .main-education {
  font-size: 12px;
  font-family: 'Graphik';
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  color: #7982ad;
}
.reveal-profile-top-header-container .mission-sequence-container {
  flex-shrink: 0;
  width: 43.25%;
  padding: 0px 0px 0px 10px;
  position: relative;
}
.custom-fields-container {
  background-color: white;
  display: flex;
}
.custom-fields-container:not('.plugin-custom-fields') {
  box-shadow: inset 0px -1px 0px #dae0f4;
}
.custom-fields-container.plugin-custom-fields {
  margin-top: 8px;
}
.custom-fields-container.plugin-custom-fields .custom-fields-list {
  margin: 0px;
}
.custom-fields-container.plugin-custom-fields.adventure {
  margin: 0px 0px 12px 20px;
}
.custom-fields-container .custom-fields-list {
  margin: 0px 0px 8px 112px;
  display: flex;
  color: #1f2e77;
  font-size: 13px;
  font-family: 'Graphik';
  font-weight: 400;
  gap: 4px;
  overflow: hidden;
}
.custom-fields-container .process-custom-fields-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4px;
}
.custom-fields-container .process-custom-fields-list .process-custom-field {
  display: flex;
  gap: 4px;
  align-items: center;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-title {
  color: #1f2e77;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-value {
  color: #7982ad;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-title,
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-value {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-title {
  color: #1f2e77;
  margin-right: 4px;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-value {
  color: #7982ad;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-actions {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-left: 8px;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-actions .custom-field-edit {
  height: 12px;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-actions .custom-field-edit .edit-button.small {
  padding: 0px;
  margin: 0px;
  height: 12px;
  width: 12px;
  font-size: 12px;
  color: #7982ad;
  background-color: transparent;
  border: none;
  cursor: pointer;
  vertical-align: super;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-actions .custom-field-edit .edit-button.small:hover {
  color: #1f2e77;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-actions .custom-field-edit .edit-button.small .ri-sm {
  padding: 0px;
  margin: 0px;
  width: inherit;
  height: inherit;
}
.custom-fields-container .process-custom-fields-list .process-custom-field .custom-field-actions .custom-field-edit .edit-button.small .ri-sm::before {
  vertical-align: text-bottom;
}
.custom-fields-container .header-custom-field {
  flex-shrink: 0;
  border-radius: 2px;
  padding: 4px 8px 4px 8px;
  background: #f6f7fc;
  display: flex;
}
.custom-fields-container .header-custom-field.small {
  padding: 2px 4px 2px 4px;
}
.custom-fields-container .header-custom-field.hidden {
  visibility: hidden;
}
.custom-fields-container .header-custom-field .custom-field-title,
.custom-fields-container .header-custom-field .custom-field-value {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.custom-fields-container .header-custom-field .custom-field-title {
  color: #1f2e77;
  margin-right: 4px;
}
.custom-fields-container .header-custom-field .custom-field-value {
  color: #7982ad;
}
