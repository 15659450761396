.avatarContainer {
  .placeholder {
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }

  .avatar {
    width: 32px;
    height: 32px;
  }
}
