/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * colors.less
 */
.watch-profile-header {
  padding: 24px;
  display: flex;
  align-items: center;
}
.watch-profile-header .seen {
  opacity: 0.6;
}
.watch-profile-header .avatar-container {
  position: relative;
  flex-shrink: 0;
  margin-right: 10px;
}
.watch-profile-header .avatar-container img {
  width: 64px;
  height: 64px;
}
.watch-profile-header .avatar-container .source-indicator {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 46px;
  background-color: #709dff;
  height: 16px;
  min-width: 16px;
  border-radius: 16px;
  border: 1px solid white;
  box-sizing: border-box;
}
.watch-profile-header .avatar-container .source-indicator.seen {
  opacity: 1;
  background-color: #91a2df;
}
.watch-profile-header .avatar-container .source-indicator i {
  position: absolute;
  top: 3.8px;
  left: 2.6px;
}
.watch-profile-header .avatar-container .source-indicator i.small.icon {
  font-size: 7px;
}
.watch-profile-header .avatar-container .source-indicator span {
  position: absolute;
  font-size: 10px;
  line-height: 1;
  top: 2px;
  left: 14px;
  color: white;
}
.watch-profile-header .avatar-container .source-indicator.hover {
  width: 85px;
}
.watch-profile-header .headline {
  line-height: 1 !important;
  font-size: 12px !important;
  font-weight: 500;
  color: #1f2e77;
}
.watch-profile-header .headline .icon {
  color: #1f2e77;
}
.watch-profile-header .profile-data-extract {
  flex-grow: 1;
}
.watch-profile-header .profile-data-extract .first-line {
  display: flex;
  align-items: center;
}
.watch-profile-header .profile-data-extract .first-line .sources-container {
  margin-left: 0.5em;
}
.watch-profile-header .profile-data-extract .first-line .sources-container .source-link img {
  width: 14px;
}
.watch-profile-header .profile-data-extract .first-line .sources-container .source-link span {
  line-height: 20px;
}
.watch-profile-header .profile-data-extract .first-line .fullname {
  font-family: Gilroy;
  color: #4864c9;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
}
.watch-profile-header .profile-data-extract .first-line .fullname i {
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;
}
.watch-profile-header .profile-data-extract .first-line .fullname i.copied {
  color: #a5abc9;
}
.watch-profile-header .profile-data-extract .first-line .sources-container .source-link a img {
  margin-top: -2px;
}
.watch-profile-header .profile-data-extract .first-line .header-annotations {
  position: absolute;
  left: 0;
  top: 0px;
}
.watch-profile-header .profile-state {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 64px;
}
.watch-profile-header .actions-container {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
}
.watch-profile-header .actions-container .ui.button {
  white-space: nowrap;
  margin: 0 6px;
}
.watch-profile-header .actions-container .ui.button.basic-button {
  margin-right: 12px;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4864c9;
  border: 1px solid #4864c9;
}
.watch-profile-header .actions-container .ui.button.basic-button:hover,
.watch-profile-header .actions-container .ui.button.basic-button:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4864c9;
  border: 1px solid #4864c9;
}
.watch-profile-header .actions-container .ui.button.basic-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #1f2e77;
  border: 1px solid #1f2e77;
}
.watch-profile-header .actions-container .ui.button.basic-button .icon {
  height: 14px;
  fill: #4864c9;
  opacity: 1;
}
.watch-profile-header .actions-container .ui.button.basic-button:hover {
  background-color: #dee2f7;
}
.watch-profile-header .actions-container .ui.button.remove-button {
  margin-right: 12px;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f86e7f;
  border: 1px solid #f86e7f;
}
.watch-profile-header .actions-container .ui.button.remove-button:hover,
.watch-profile-header .actions-container .ui.button.remove-button:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f86e7f;
  border: 1px solid #f86e7f;
}
.watch-profile-header .actions-container .ui.button.remove-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f9c8ca;
  border: 1px solid #f9c8ca;
}
.watch-profile-header .actions-container .ui.button.remove-button:hover {
  background-color: #f9c8ca;
}
