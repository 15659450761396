@import 'src/less/colors';

.message {
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: @PrimaryCobalt60;
  margin-top: 2px;
  margin-bottom: 8px;
}

.address {
  font-weight: 500;
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.action {
  display: inline-flex;
  padding: 0;
  align-items: center;
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  gap: 4px;

  &:not(:disabled) {
    color: @SecondaryBlue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &:disabled {
    color: @PrimaryCobalt40;
  }

  .icon {
    font-size: 16px;
  }
}
