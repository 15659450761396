/*
 * colors.less
 */
.triggerContent {
  display: flex;
  align-items: center;
  font-family: Graphik, sans-serif;
}
.triggerContent .triggerText {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f2e77;
}
.triggerContent .triggerPlaceholder {
  color: #a5abc9;
}
