@import 'src/less/colors';

.emptyStates {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;

  .emptyState {
    max-width: 720px;
    flex: 1;
    background-color: white;
    box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
}
