@import 'src/less/colors';

.root {
  background: white;
  padding: 15px;
  padding-bottom: 8px;

  &.minimized {
    padding: 0;
    margin-bottom: 0px;

    .nameContainer {
      justify-content: space-between;
    }

    .name {
      font-size: 16px;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .maximizeButton {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: @SecondaryBlue;
      cursor: pointer;
    }
  }
}

.headlineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  //justify-content: space-between;
}

.name {
  font-family: Gilroy;
  color: @SecondaryBlue;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headline {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: @PrimaryCobalt;
  margin-bottom: 0px;
}

.headlineDatas {
  min-width: 100px;
  flex: 1;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover > .nameContainer > .fieldEditIconContainer {
    visibility: visible;
  }
}

.fieldEditIconContainer {
  flex-grow: 1;
  visibility: hidden;
  text-align: right;
}

.fieldEditIcon {
  font-family: Graphik;
  white-space: nowrap;
  cursor: pointer;
  flex-grow: 0;
  color: @SecondaryBlue;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid @SecondaryBlue;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 2px;
  width: min-content;
  margin-left: auto;
}

.headlinePicture {
  min-width: 48px;

  img {
    width: 48px;
  }
}

.email:hover {
  cursor: pointer;
  text-decoration: underline;
  color: @SecondaryBlue80;
}

.sourcesContainer {
  display: flex;
  height: 20px;
  align-items: center;
}

.emailAndPhone {
  color: @PrimaryCobalt60;
  font-family: Graphik;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: 12px;

  span:first-of-type:not(:last-child) {
    min-width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .divider {
    margin: 0px 4px;
  }

  .phoneIcon {
    margin-right: 2px;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.35;
    color: #112e48;
    display: flex;

    &:hover {
      opacity: 1;
    }
  }
}

.sourceIconsContainer {
  margin-top: 12px;
  i {
    &:first-of-type {
      margin-left: 0;
    }
    margin: 0px 4px;
    color: @PrimaryCobalt60;
  }
}
