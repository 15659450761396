/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.watch-collect-all-candidates-loading {
  height: calc(100vh - 64px - 80px);
  display: flex;
  align-items: center;
  width: 100%;
}
.overflow-modal {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100% - 64px);
  overflow-y: auto;
  z-index: 10;
}
.overflow-modal .profile-preview-container.marketplace-profile {
  position: initial;
  align-items: stretch;
  height: auto;
}
.overflow-modal .profile-preview-container.marketplace-profile .profile-preview {
  overflow-y: unset;
  height: auto;
}
.overflow-modal .profile-preview-container.marketplace-profile .dimmer {
  height: auto;
}
.profile-preview-container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.profile-preview-container.marketplace-profile {
  top: 64px;
  min-height: calc(100% - 64px);
}
.profile-preview-container.marketplace-profile .resume-section-container {
  height: auto;
}
.profile-preview-container.marketplace-profile .resume-section-container .resume-data-card {
  padding: 20px !important;
  margin-bottom: 60px !important;
}
.profile-preview-container.marketplace-profile .profile-preview {
  overflow-y: auto;
}
.profile-preview-container .dimmer {
  background-color: rgba(0, 0, 0, 0.5);
  flex: 0 0 174px;
  height: 100%;
}
.profile-preview-container .dimmer .actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 12px;
  margin-right: 12px;
}
.profile-preview-container .dimmer .actions-container .dimmer-button {
  height: 48px;
  width: 48px;
}
.profile-preview-container .dimmer .actions-container .ui.button {
  background-color: white;
  margin-bottom: 8px;
}
.profile-preview-container .dimmer .actions-container .ui.button.back-button {
  margin-bottom: 48px;
}
.profile-preview-container .task-dimmer .tasks-actions-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4864c9;
  border-radius: 35px;
  padding: 7px 5px;
}
.profile-preview-container .task-dimmer .tasks-actions-buttons .tasks-actions-title {
  font-size: 14px;
  color: #91a2df;
  margin: 10px 0;
  font-weight: 600;
}
.profile-preview-container .task-dimmer .tasks-actions-buttons .tasks-actions-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: white;
  margin: 10px 0;
  font-weight: bold;
}
.profile-preview-container .task-dimmer .tasks-actions-buttons .tasks-actions-count .current-index {
  border-bottom: 2px solid white;
  margin-bottom: 5px;
}
.profile-preview-container .task-dimmer .dimmer-button {
  color: white;
}
.profile-preview-container .task-dimmer .task-dimmer-button {
  height: 40px !important;
  margin-bottom: 0px !important;
  width: 40px !important;
  margin-right: 0px !important;
}
.profile-preview-container .task-dimmer .ui.button {
  background-color: #3450b5 !important;
}
.watch-collect-content-container {
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.modal-profile-header {
  display: flex;
  flex-direction: row;
}
.modal-profile-header .content {
  flex-grow: 1;
}
.modal-profile-header .profile-header-actions .profile-step-selector {
  margin-bottom: 8px;
}
.modal-profile-header .profile-header-actions .profile-stage-selector {
  text-align: right;
  padding-right: 4px;
}
.modal-profile-header .profile-header-actions .profile-stage-selector .change-profile-stage.dropdown {
  margin-top: 0px;
}
.modal-profile-header .profile-header-actions,
.modal-profile-header .actions-container {
  margin-left: 16px;
}
.modal-profile-header .actions-container {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column-reverse;
}
.modal-profile-header .actions-container .add-to-job-button {
  margin-bottom: 12px;
}
.modal-profile-header .actions-container .ui.button {
  white-space: nowrap;
  margin-bottom: 12px;
}
.modal-profile-header .actions-container .ui.button.basic-button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4864c9;
  border: 1px solid #4864c9;
}
.modal-profile-header .actions-container .ui.button.basic-button:hover,
.modal-profile-header .actions-container .ui.button.basic-button:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4864c9;
  border: 1px solid #4864c9;
}
.modal-profile-header .actions-container .ui.button.basic-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #1f2e77;
  border: 1px solid #1f2e77;
}
.modal-profile-header .actions-container .ui.button.basic-button .icon {
  height: 14px;
  fill: #4864c9;
  opacity: 1;
}
.modal-profile-header .actions-container .ui.button.basic-button:hover {
  background-color: #dee2f7;
}
.modal-profile-header .actions-container .ui.button.remove-button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f86e7f;
  border: 1px solid #f86e7f;
}
.modal-profile-header .actions-container .ui.button.remove-button:hover,
.modal-profile-header .actions-container .ui.button.remove-button:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f86e7f;
  border: 1px solid #f86e7f;
}
.modal-profile-header .actions-container .ui.button.remove-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f9c8ca;
  border: 1px solid #f9c8ca;
}
.modal-profile-header .actions-container .ui.button.remove-button:hover {
  background-color: #f9c8ca;
}
.watch-collect-all-candidates {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 64px - 40px);
  gap: 16px;
}
.watch-collect-all-candidates:not(.watch-collect-all-candidates--embedded) {
  padding: 24px 174px;
}
.watch-collect-all-candidates .loading-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}
.watch-collect-all-candidates .loading-placeholder .ui.loader {
  z-index: 1;
}
.watch-collect-top-bar {
  height: 64px;
  width: 100%;
  background-color: white;
  padding: 16px 52px;
  border-bottom: 1px solid #d2d5e4;
}
.watch-collect-container {
  width: 100%;
  flex: 1;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  display: flex;
  flex-direction: row;
}
.watch-collect-container .side-menu {
  flex: 0 0 250px;
  display: flex;
  flex-direction: column;
  border-right: solid 1px #dfdfe6;
}
.watch-collect-container .side-menu .categories {
  padding: 24px;
  border-bottom: solid 1px #dfdfe6;
}
.watch-collect-container .side-menu .categories .side-menu-item {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 8px;
  margin: 4px 0;
  cursor: pointer;
}
.watch-collect-container .side-menu .categories .side-menu-item:hover {
  color: #4864c9;
  background: #f9f9f9;
}
.watch-collect-container .side-menu .categories .side-menu-item.active {
  border-radius: 3px;
  color: #4864c9;
  background-color: #dee2f7;
}
.watch-collect-container .profiles-container {
  flex-grow: 1;
}
.watch-collect-container .top-menu {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #7982ad;
  margin: 24px;
  padding-right: 8px;
}
.watch-collect-container .top-menu .left {
  flex-grow: 1;
}
.watch-collect-container .top-menu .title {
  flex-grow: 1;
  font-family: Gilroy, sans-serif;
}
.watch-collect-container .top-menu .title .profiles-count {
  font-weight: 600;
  color: #4864c9;
}
.watch-collect-container .top-menu .title .candidates {
  color: #1d1e21;
}
.watch-collect-container .top-menu .title .emphasis {
  font-weight: 600;
}
.watch-collect-container .top-menu .actions-container {
  margin-left: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.watch-collect-container .top-menu .actions-container .ui.dropdown.hidden-profiles-dropdown,
.watch-collect-container .top-menu .actions-container .ui.dropdown.profiles-in-job-dropdown {
  margin-left: 8px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
}
.watch-collect-container .top-menu .actions-container .ui.dropdown.hidden-profiles-dropdown i.dropdown.icon,
.watch-collect-container .top-menu .actions-container .ui.dropdown.profiles-in-job-dropdown i.dropdown.icon {
  height: 32px;
  padding: 9px;
}
.watch-collect-container .top-menu .actions-container .toggle-buttons {
  margin-left: 8px;
  height: 32px;
}
.watch-collect-container .search-results {
  padding: 0 24px 0 24px;
  margin-bottom: 24px;
  height: calc(100% - 80px - 24px);
}
