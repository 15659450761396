/*
 * colors.less
 */
.dropdown:global(.ui.dropdown) {
  width: 400px;
  line-height: 30px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;
}
.dropdown:global(.ui.dropdown) :global(.text) {
  font-family: Gilroy;
  font-size: 14px;
  color: #4c5892;
  font-weight: 600 !important;
}
.dropdown:global(.ui.dropdown) :global(.dropdown.icon) {
  height: 32px;
  padding: 8px;
}
