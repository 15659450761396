/*
 * colors.less
 */
.apply-search-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;
}
.apply-search-dropdown .sort-by-text {
  font-size: 14px;
  white-space: pre;
}
.apply-search-dropdown .text-styles {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.apply-search-dropdown .ui.dropdown .item {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.apply-search-dropdown .ui.dropdown:not(.button) > .default.text {
  color: #4c5892;
}
.apply-search-dropdown .saved-search-item .item-text-and-cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.apply-search-dropdown .saved-search-item .item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.apply-search-dropdown .saved-search-item i {
  display: none;
}
.apply-search-dropdown .saved-search-item:hover i {
  display: block;
  color: #a5abc9;
}
.apply-search-dropdown .saved-search-item:hover i:hover {
  color: #4c5892;
}
.apply-search-dropdown .ui.dropdown {
  width: 270px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.apply-search-dropdown .ui.dropdown .text {
  color: #4c5892;
  font-weight: 600 !important;
}
.apply-search-dropdown .ui.dropdown i.dropdown.icon {
  height: 32px;
  padding: 9px;
}
