/*
 * colors.less
 */
.selectDropdown {
  padding: 6px 2px 6px 8px;
  font-size: 12px;
  margin-right: 8px;
  font-family: Graphik;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  border: 2px solid transparent;
  display: flex;
  gap: 8px;
  cursor: pointer;
}
.selectDropdown:hover,
.selectDropdown.triggerActive {
  border: 2px solid #a9c4ff;
  border-radius: 8px;
  background: white;
}
.dropdownOptionList ul {
  text-decoration: none;
  padding: 0;
  margin: 0;
  pointer-events: all;
}
.dropdownOptionList li {
  text-decoration: none;
  list-style: none;
  pointer-events: all;
  font-weight: 500;
  font-size: 12px;
  color: #1f2e77;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.dropdownOptionList li:hover {
  color: #4864c9;
}
.dropdownOptionList li.active {
  color: #4864c9;
}
.dropdownOptionList li:not(:last-child) {
  margin-bottom: 8px;
}
.dropdownOptionList li i.activeOption {
  margin-left: auto;
  font-size: 16px;
}
.dropdownOptionList span {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #7982ad;
  margin-bottom: 8px;
  display: inline-block;
}
.group {
  border-radius: 0px;
  padding: 4px 8px 4px 16px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7982ad;
  border-top: 1px solid #dae0f4;
  border-bottom: none;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group.coloredGroup {
  background-color: #f8faff;
}
.group + .sequencePopupItem {
  border-top: none !important;
}
.group .optionsPanel {
  padding: 16px;
  width: max-content;
}
.group .selectAllContainer {
  background-color: #f8faff;
  color: #7982ad;
  padding: 4px 8px 4px 16px;
  font-family: Graphik;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  display: inline-flex;
  gap: 4px;
  align-items: center;
}
.group .selectAllContainer span {
  flex-shrink: 0;
  cursor: pointer;
}
.sequenceList {
  width: 100%;
  overflow: overlay;
  overflow-x: hidden;
}
.sequenceList::-webkit-scrollbar-track {
  background: transparent !important;
}
.sequenceList .sequenceItemName {
  color: #1f2e77;
  display: flex;
  gap: 4px;
  align-items: center;
}
.sequenceList .sequenceItemName :global(.pill-message) {
  margin: 0px;
  padding: 0 4px !important;
}
.sequenceList .sequencePopupItem {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  color: #4864c9;
  border-top: 1px solid #dae0f4;
  width: 100%;
  height: 37px;
  justify-content: space-between;
}
.sequenceList .sequencePopupItem:first-of-type {
  border-top: none;
}
.sequenceList .sequencePopupItem:hover {
  background-color: #f6f7fc;
}
.sequenceList .sequencePopupItem :global(.sequence-container) {
  flex-grow: 1;
}
.sequenceList .sequencePopupItem :global(.sequence-container) :global(.row-icon-container) {
  margin-top: 0;
}
.sequenceList .sequencePopupItem .sequenceTitle {
  margin-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sequenceList .sequencePopupItem .pluginSequenceTitle {
  margin-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sequenceList .sequencePopupItem :global(.row-sequence-container) {
  display: flex;
  justify-content: flex-end;
}
.sequenceList .sequencePopupItem :global(.row-sequence-container) :global(.more-actions.hidden) {
  display: none;
}
