.create-manual-tasks-form.form.ui .field {
  margin: 0;
}
.create-manual-tasks-form.form.ui .field:not(:first-child) {
  margin-top: 24px;
}
.create-manual-tasks-form.form.ui .field label.filters-label {
  margin-bottom: 16px;
}
.create-manual-tasks-form.form.ui .d-flex-align-center {
  display: flex;
  align-items: center;
}
.create-manual-tasks-form.form.ui .d-flex-align-end {
  display: flex;
  align-items: flex-end;
}
.create-manual-tasks-form.form.ui .margin-top {
  margin-top: 25px;
}
.create-manual-tasks-form.form.ui .mt-O {
  margin-top: 0 !important;
}
.create-manual-tasks-form.form.ui .compact-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-manual-tasks-form.form.ui .small-field {
  min-width: 9em !important;
}
.create-manual-tasks-form.form.ui .item {
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap !important;
}
