/*
 * colors.less
 */
.enriched-profiles {
  height: 100%;
}
.enriched-profiles .batch-actions {
  padding: 0 12px 14px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  flex-shrink: 0;
}
.enriched-profiles .batch-actions .ui.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  line-height: 17px;
  margin: 0 6px;
}
.enriched-profiles .batch-actions .ui.button i.ri-send-plane-2-fill {
  font-size: 14px;
  margin-left: 6px;
}
.enriched-profiles .batch-actions .ui.button i.ri-close-line {
  font-size: 16px;
  margin-right: -2px;
}
.enriched-profiles .batch-actions .ui.button i.ri-add-line {
  font-size: 16px;
  font-weight: 600;
  margin-right: 6px;
}
.enriched-profiles .batch-actions .ui.button:last-child {
  margin-right: 0;
}
.enriched-profiles .batch-actions .skip-all-button.ui.basic.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  line-height: 20px;
  box-shadow: 0px 0px 0px 1px #e8e8e8 inset !important;
}
.enriched-profiles .batch-actions .skip-all-button.ui.basic.button i.ri-close-line {
  font-size: 16px;
  margin-right: 0.5em;
}
.enriched-profiles .batch-actions .skip-all-button.ui.basic.button i.ri-arrow-go-back-line {
  font-size: 13px;
  margin-right: 0.5em;
}
.enriched-profiles .batch-actions .skip-all-button.ui.basic.button:hover {
  box-shadow: 0px 0px 0px 1px #f66f81 inset !important;
  background-color: #fff1f3 !important;
}
.enriched-profiles .batch-actions .select-all-button.ui.basic.button {
  color: #4c5892 !important;
}
.enriched-profiles .enriched-profiles-scroll-container {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.enriched-profiles .enriched-profiles-scroll-container.small-card-mode {
  height: calc(100% - 46px);
}
.enriched-profiles .enriched-profiles-scroll-container .infinite-scroll-component.infinite-scroll {
  overflow: unset !important;
}
.enriched-profiles .enriched-profiles-scroll-container::-webkit-scrollbar {
  display: none;
}
.enriched-profiles .no-results {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.enriched-profiles .no-results img {
  width: 500px;
  margin: 30px;
}
.enriched-profiles .no-results h3 {
  color: #4c5892;
}
