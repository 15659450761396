/*
 * colors.less
 */
.icon {
  margin: 0 4px;
  color: #7982ad;
}
.content h1 {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
}
.content h2 {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  color: #8db1ff;
  margin: 0;
}
.content p {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin: 0;
}
.content p:not(:last-child) {
  margin-bottom: 8px;
}
