@import 'src/less/colors';

:global(.ui.modal) > .modal:global(.content) {
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.form,
.preview {
  flex: 1;
}
.form {
  padding: 32px;
}

.modal:global(.ui.modal) {
  width: 1050px;
  max-width: 90vw;
}

:global(.ui.modal) > .actions {
  border-top: 1px solid @SecondaryBlue20;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  :global(.ui.modal) & > :global(.button) {
    margin-left: 0;
  }
}

.options {
  border: 1px solid @SecondaryBlue20;
  padding: 8px;
  border-radius: 3px;
}

.displayOption {
  margin-top: 32px;
}

.rowOption {
  display: flex;
  flex-flow: row nowrap;
  height: 32px;
  padding-bottom: 4px;
}

.inputOptionRow {
  flex: 1;
}

.inputOption:global(.ui.input) input {
  border: none;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  color: @PrimaryCobalt;
  padding: 4px;
  &:focus {
    color: @PrimaryCobalt;
    border: 1px solid @SecondarySkyBlue80 !important;
  }
}

.deleteOption {
  color: @PrimaryCobalt60;
  cursor: pointer;
  margin-left: 3px;
  outline: 0;
  border: 1px solid transparent;
  &:focus {
    border-color: @SecondarySkyBlue80;
    border-radius: 2px;
  }

  &:disabled {
    cursor: initial;
    color: @PrimaryCobalt20;
  }
}

.addOptionContainer {
  padding-top: 8px;
  border-top: 1px solid @SecondaryBlue20;
  width: 100%;
  button {
    margin: 0;
  }
}

.addOption {
  color: @SecondarySkyBlue;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik, sans-serif;
  vertical-align: middle;
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    font-weight: 700;
  }
  margin-bottom: 4px;

  outline: 0;
  border: 1px solid transparent;
  &:focus {
    border-color: @SecondarySkyBlue80;
    border-radius: 2px;
  }
}

:global(.ui.modal) .defaultValueSelectField {
  margin-top: 24px;
  margin-bottom: 0;
}

.toggleContainer {
  z-index: 0;
  position: relative;
}
