/*
 * colors.less
 */
.container {
  padding: 24px;
}
.container .emptyState {
  text-align: center;
  margin: 100px auto;
  font-size: 15px;
  opacity: 0.3;
}
