/*
 * colors.less
 */
.job-posting {
  margin-bottom: 48px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  padding: 24px;
}
.job-posting .job-posting-header {
  margin-bottom: 36px;
}
.job-posting .job-posting-header:not(.editing) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.job-posting .buttons-container {
  display: flex;
  justify-content: flex-end;
}
.job-posting .organization-logo {
  margin-bottom: 24px;
}
