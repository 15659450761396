/*
 * colors.less
 */
.task-link-full.ui.segment {
  display: block !important;
  padding: 20px 10px !important;
}
.task-link.ui.segment {
  padding: 13px;
  gap: 10px;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  font-family: Graphik, sans-serif;
  color: #1f2e77;
}
.task-link.ui.segment .sequence-info {
  font-size: 14px;
}
.task-link.ui.segment .task-link-contact {
  flex: 1;
}
.task-link.ui.segment .task-link-action {
  display: flex;
  justify-content: space-between;
}
.task-link.ui.segment .task-icon {
  margin-right: 10px;
}
.task-link.ui.segment .sequence-title {
  white-space: pre-wrap;
}
.task-link.ui.segment .task-link-date {
  color: #5c89eb;
  padding-top: 15px;
}
.task-link.ui.segment .task-link-date span {
  margin-left: 10px;
  color: #4864c9;
  font-weight: 500;
  cursor: pointer;
}
.task-link.ui.segment .task-link-description {
  margin-top: 20px;
}
.task-link.ui.segment .strong {
  font-weight: 500;
  color: #1f2e77;
}
.task-link.ui.segment .ui.button {
  margin-top: 10px;
}
.task-link.ui.segment .mt-0 {
  margin-top: 0px !important;
}
