/*
 * colors.less
 */
.workplace-popup {
  box-shadow: none !important;
  min-width: 370px !important;
}
.workplace-popup .popup-first-line {
  display: flex;
  flex-direction: row;
  height: 24px;
}
.workplace-popup .popup-first-line .workplace-name,
.workplace-popup .popup-first-line .workplace-type {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1f2e77;
  flex-grow: 0;
  white-space: nowrap;
}
.workplace-popup .popup-first-line .workplace-name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.workplace-popup .popup-first-line .workplace-type {
  margin-left: 3px;
}
.workplace-popup .popup-first-line .popup-first-line-spacer {
  flex-grow: 1;
}
.workplace-popup .popup-first-line .sources-container {
  flex-grow: 0;
  margin-left: 6px;
  display: flex;
  flex-direction: row;
}
.workplace-popup .popup-first-line .sources-container .source-link {
  margin-right: 4px;
}
.workplace-popup .popup-first-line .sources-container .source-link:last-child {
  margin-right: 0px;
}
.workplace-popup .popup-first-line .sources-container .source-link .source-link-inner {
  height: 14px;
  width: 16px;
  opacity: 0.35;
  color: #112e48;
}
.workplace-popup .popup-first-line .sources-container .source-link .source-link-inner:hover {
  opacity: 1;
}
.workplace-popup .popup-first-line .sources-container .source-link .source-link-inner.website {
  line-height: 14px;
  font-size: 12px;
}
.workplace-popup .popup-subtitle {
  color: #4c5892;
  font-size: 12px;
  margin-bottom: 16px;
}
.workplace-popup .popup-subtitle .workplace-staff-count {
  float: right;
  color: #1f2e77;
}
.workplace-popup .industry-label {
  margin: 2px 3px 2px 0px;
  background: #f3f4f8;
  font-family: Graphik, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #7982ad;
}
.workplace-popup .workplace-industries {
  margin-bottom: 16px;
}
.workplace-popup .workplace-stack {
  margin-top: 6px;
  line-height: 20px;
}
.workplace-popup .workplace-stack .technology-icon {
  min-width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
  vertical-align: middle !important;
}
.workplace-popup .workplace-stack .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  height: 14px;
  min-width: 14px;
}
.workplace-popup .workplace-stack .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 7px;
  line-height: 14px;
  font-family: Graphik, sans-serif;
  padding-right: 2px;
  padding-left: 2px;
}
.workplace-popup .workplace-description {
  margin-top: 16px;
  font-size: 12px;
}
