/*
 * colors.less
 */
.emptyState {
  padding: 24px;
  text-align: center;
  border: 1px solid #dae0f4;
  color: #7982ad;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}
.separator {
  border: 1px solid #dae0f4;
  border-bottom: none;
  margin: 32px 0 0;
}
