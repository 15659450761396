/*
 * colors.less
 */
.valueContainer {
  position: relative;
  height: 28px;
  width: calc(100% - 14px);
  margin: auto;
}
.value {
  position: absolute;
  color: #4864c9;
  top: 50%;
  font-weight: 600;
  font-family: Graphik, sans-serif;
  font-size: 0.8rem;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}
.slider {
  position: relative;
  width: calc(100% - 14px);
  margin: auto;
  height: 4px;
  background: #dae0f4;
  border-radius: 4px;
}
.progress {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #4864c9;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.input {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -7px;
  right: -7px;
  appearance: none;
  background: none;
  outline: none;
}
.input::-webkit-slider-thumb {
  appearance: none;
  width: 14px;
  height: 14px;
  background: white;
  border: 2px solid #4864c9;
  border-radius: 14px;
  cursor: pointer;
}
