/*
 * colors.less
 */
.career-page.ui.form {
  margin-bottom: 40px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  padding: 24px;
}
.career-page.ui.form .buttons-container {
  display: flex;
  justify-content: flex-end;
}
.career-page.ui.form .organization-logo {
  margin-bottom: 24px;
}
.career-page.ui.form .career-page-status {
  margin: 40px 0;
}
.career-page.ui.form .company-logo {
  margin: 40px 0;
}
.career-page.ui.form .company-logo .input-label {
  display: flex;
}
.career-page.ui.form .company-logo .input-label i {
  margin-left: 4px;
}
.career-page.ui.form .company-logo-upload {
  padding: 0 !important;
}
.career-page.ui.form .company-logo-label {
  cursor: pointer;
}
.career-page.ui.form .dropzone-container .dropzone {
  padding: 1em 1em;
}
.career-page.ui.form .input-container.logo-preview .input-element {
  display: flex;
  flex-grow: 1;
}
.career-page.ui.form .company-logo-preview {
  height: 44px;
}
.career-page.ui.form .page-url input {
  color: #1f2e77;
  opacity: 1 !important;
}
.career-page.ui.form .page-url label a {
  color: #4c5892 !important;
  cursor: pointer;
}
.career-page.ui.form .page-url label a:hover span {
  text-decoration: underline;
}
.career-page.ui.form .page-url .link-icon {
  margin-left: 10px;
  position: relative;
  top: 1.5px;
}
