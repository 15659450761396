/*
 * colors.less
 */
.job-contact-info .job-contact-kpis {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.job-contact-info .job-contact-kpis .title {
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;
  color: #a5abc9;
  margin-top: 12px;
  height: 16px;
}
.job-contact-info .job-contact-kpis .job-contact-kpi:first-child {
  margin-left: 0px;
}
.job-contact-info .job-contact-kpis .job-contact-kpi {
  display: flex;
  align-items: center;
  height: 33px;
  margin-left: 32px;
}
.job-contact-info .job-contact-kpis .job-contact-kpi .number {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.24px;
  color: #4864c9;
  margin-right: 8px;
  margin-top: 5px;
}
.job-contact-info .job-contact-kpis .job-contact-kpi .legend > pre {
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #4c5892;
  margin: 0px;
}
.job-contact-info .job-linear-heat-map {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1em;
}
.job-contact-info .job-linear-heat-map .job-contact-small-divider {
  width: 0px;
  height: 16px;
  border-left: 1px solid #dae0f4;
  margin-left: 1em;
  margin-right: 1em;
}
.job-contact-info .job-linear-heat-map .job-contact-all-activity-btn > span {
  margin-top: 0px !important;
  text-decoration: none;
  font-weight: 600;
  white-space: pre;
  color: #4864c9;
}
.job-contact-info .job-linear-heat-map .job-contact-all-activity-btn:hover > span {
  text-decoration: underline !important;
}
