@import 'src/less/colors';

.dropdown:global(.ui.dropdown) {
  width: 400px;
  line-height: 30px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;

  :global(.text) {
    font-family: Gilroy;
    font-size: 14px;
    color: @PrimaryCobalt80;
    font-weight: 600 !important;
  }

  :global(.dropdown.icon) {
    height: 32px;
    padding: 8px;
  }
}
