/*
 * colors.less
 */
.grade-button-selector.ui.buttons .ui.button {
  width: 29px;
  font-weight: 500;
  padding: 8px;
  font-size: 12px;
  font-family: Graphik, sans-serif;
}
.grade-button-selector.ui.buttons .ui.button.is-selected {
  background: #4864c9 !important;
  color: white !important;
}
