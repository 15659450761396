@import 'src/less/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 26px;
    color: @PrimaryCobalt;
  }
}

.toggleLabelAndTooltip {
  display: flex;
  align-items: center;
  gap: 4px;

  > div {
    margin-bottom: 0px;
  }
}

.description {
  color: @PrimaryCobalt60;
  margin-bottom: 20px;
}

.modalForm {
  .modalFormField {
    margin: 0px 0px 25px 0px !important;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .optionWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .optionInput {
    margin: 5px 0px;
    flex: 1;
    input {
      border: 1px solid @SecondaryBlue20;
      box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
      border-radius: 8px;
    }
  }

  .modalActions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }

  .conditionsChainingForm {
    display: flex;
    width: 100%;
    gap: 20px;

    .left {
      flex: 1;
    }
    .right {
      flex: 0.4;
      background: #f3f4f8;
    }

    .padding {
      padding: 12px 24px 24px 24px;
    }
  }
}

.aiTokenSection {
  margin-bottom: 24px;
  .aiTokenOptionGroupName {
    margin-bottom: 8px;
    display: block;
  }
}
