/*
 * colors.less
 */
.column {
  cursor: pointer;
  width: 160px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 8px;
  background-color: #f6f7fc;
}
@media (max-width: 1400px) {
  .column {
    width: 100px;
    justify-content: center;
  }
}
.column .count {
  text-align: left;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #a5abc9;
}
.column:hover .count {
  text-decoration: underline;
}
.column.notEmpty {
  background-color: #f1f6ff;
}
.column.notEmpty .count {
  color: #4864c9;
}
