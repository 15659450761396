@import 'src/less/colors';

.salary-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  height: 38.5px;

  .select-not-editing-container {
    //width: 68px;
    display: inline-block;

    .select-not-editing-inner {
      width: 68px;
      padding-right: 0px;

      &.error {
        border-color: #ff00007b;
      }
    }
  }

  .inline-separator {
    margin-right: 10px;
    margin-left: 10px;
    vertical-align: middle;
  }

  .custom-input:hover .label,
  .custom-input:hover input {
    border-color: @SecondaryBlue80;
  }

  .custom-input {
    //width: 60px;
    //margin-right: 18px;

    input {
      border-right: none !important;
    }

    .label {
      width: 18px;
      border-left: none !important;
      padding-left: 2px;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 400;
    }

    input:focus {
      border-color: @PrimaryColorLight;
      border-width: 1px;
    }

    input:focus + .label {
      border-color: @PrimaryColorLight;
      border-width: 1px;
    }
  }

  .currency {
    flex: 1;
    display: inline-block;
    margin-left: 15px;

    .select-not-editing-container {
      display: inline-block;
    }

    > div > div {
      width: auto;
    }
  }

  .custom-select {
    display: inline-block;
  }
}

.money-input {
  .label {
    width: 18px;
    border-left: none !important;
    padding-left: 2px;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 400;
  }
}
