@import 'src/less/colors';

.subtitle {
  font-size: 14px;
  font-weight: 600;
  color: @PrimaryCobalt;
  margin-bottom: 8px;
}

.modalActions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
