/*
 * colors.less
 */
.modalHeaderSubtitle {
  font-weight: 500;
  font-style: italic;
  font-size: 18px;
  margin-left: 5px;
}
.container {
  margin-bottom: 24px;
}
.container .title {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
  margin-bottom: 4px;
}
.container .error {
  display: block;
  margin: 5px 0;
  color: #ff6059;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
}
.container .centered {
  text-align: center;
}
