.menu {
  display: flex;
  width: 100%;

  .visibleMenu {
    white-space: nowrap;
    overflow: hidden;

    .menuItem {
      display: inline-block;
      vertical-align: middle;

      &.hidden {
        visibility: hidden;
      }
    }
  }

  .ellipsis {
    flex: 1;
    &.hidden {
      visibility: hidden;
    }
  }
}
