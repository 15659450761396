@import 'src/less/colors';

.card {
  background: #ffffff;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  margin: 16px 0;

  .header {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1px solid @SecondaryBlue20;

    .title {
      color: @PrimaryCobalt;
    }
  }

  .content {
    padding: 24px;
  }
}
