/*
 * colors.less
 */
.container {
  text-align: center;
  padding: 16px;
  gap: 16px;
}
.container .title {
  color: #1f2e77;
  font-weight: 500;
  margin-bottom: 4px;
}
.container .description {
  font-weight: 400;
  color: #7982ad;
  margin-bottom: 4px;
}
.container .ctaContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  color: #4864c9;
}
.container .ctaContainer svg {
  width: 10px;
}
