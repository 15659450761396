/*
 * colors.less
 */
.snoozeModal {
  width: 100%;
  background: #ffffff;
}
.snoozeModal:not(.minimized) {
  position: absolute;
  top: 45px;
  left: 0;
  border: 1px solid #4864c9;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 15px;
  z-index: 10;
}
.snoozeModal .title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.snoozeModal .buttons {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.snoozeModal .buttons:not(last-of-type) {
  margin-bottom: 12px;
}
.snoozeModal .buttons .button {
  flex: 1 1;
  min-width: fit-content;
  background: #f3f4f8;
  border: 1px solid #f3f4f8;
  border-radius: 8px;
  color: #1f2e77;
  font-size: 12px;
  font-family: Graphik, sans-serif;
  font-weight: normal;
}
.snoozeModal .buttons .buttonSelected {
  background: #e2ebff;
  border: 1px solid #4864c9;
}
.snoozeModal .comment {
  margin: 10px 0 12px 0;
  width: 100%;
}
.snoozeModal .datePickerWrapper {
  display: flex;
  justify-content: center;
}
.snoozeModal .confirm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 10px;
}
.snoozeModal .confirm div {
  color: #7982ad;
  font-size: 12px;
}
.snoozeModal.minimized {
  margin-top: 4px;
}
.snoozeModal.minimized .title {
  font-size: 12px;
}
.snoozeModal.minimized .buttons {
  margin-top: 4px;
  gap: 4px;
}
.snoozeModal.minimized .buttons .button {
  padding: 2px;
}
.snoozeModal.minimized .confirm {
  margin-top: 4px;
  display: block;
}
.snoozeModal.minimized .confirm div {
  text-align: center;
  font-size: 11px;
}
.snoozeModal.minimized .confirm button {
  display: block;
  margin: auto;
  font-size: 11px;
}
