/*
 * colors.less
 */
.expandable-container .expandable-container-content.has-show-more:not(.show-more) {
  max-height: 190px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
}
.expandable-container .show-less-button,
.expandable-container .show-more-button {
  cursor: pointer;
  margin-top: 8px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #d2d5e4;
  text-align: center;
}
.expandable-container .show-less-button:hover,
.expandable-container .show-more-button:hover {
  text-decoration: underline;
}
