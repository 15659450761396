/*
 * colors.less
 */
.companySettings {
  margin-bottom: 40px;
}
.companySettings .title {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
}
.companySettings .section {
  margin-top: 20px;
}
.companySettings .section h2 {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 18px;
  color: #1f2e77;
  margin-bottom: 5px;
}
.companySettings .section .card {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.companySettings .section .card .flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.companySettings .section .card .flexBetween .formField {
  flex: 1;
}
.companySettings .section .card .hint {
  font-weight: 400;
  font-size: 14px;
  color: #7982ad;
  display: block;
  margin-bottom: 20px;
}
.companySettings .section .card .nameAndLogo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 80%;
}
.companySettings .section .card .formField {
  min-width: 300px;
  margin-bottom: 25px;
}
.companySettings .section .card .formField label {
  font-weight: 500;
  font-size: 14px;
  color: #1f2e77;
}
.companySettings .section .card .linkFormField {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.companySettings .section .card .linkFormField label {
  width: 15%;
}
.companySettings .section .card .input {
  flex: 1;
}
.companySettings .section .card .input input {
  border: 1px solid #dae0f4;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
  border-radius: 8px;
}
.companySettings .section .card .invalidInput input {
  border: 1px solid #e45a6b;
}
.companySettings .section .card .logoWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.companySettings .section .card .logoWrapper .spanIcon {
  width: 64px;
  height: 64px;
  background-color: #dae0f4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
}
.companySettings .section .card .logoWrapper img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.companySettings .section .card .logoWrapper button {
  margin-bottom: 5px;
}
.companySettings .actions {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
