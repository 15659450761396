@import 'src/less/colors';

.container {
  display: block;
  margin: 24px 0;
  width: 100%;
  text-align: center;

  .button {
    font-family: Graphik;
    font-size: 12px;
    text-decoration: underline;
    color: @PrimaryCobalt60;
    cursor: pointer;

    &:hover {
      color: @PrimaryCobalt;
    }
  }
}
