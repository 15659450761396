@import 'src/utils';

// This class should become a part of the theme (modulo Marketplace app)
.revealPopup {
  padding: 12px;
  border-radius: 12px !important;
  background-color: @PrimaryCobalt !important;
  max-width: 500px;
}

.titleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  a {
    color: unset;
  }
}

.titleHeader {
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  margin-right: 0px;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
}

.titleIcon {
  margin-left: 6px;
  font-size: 16px;
}

.disabled {
  color: @PrimaryCobalt20;
}

.popupContainer {
  min-width: 265px;
}

.popupTitle {
  display: flex;
}

.popupTitle:not(:last-child) {
  margin-bottom: 12px;
}

.popupTitle span {
  display: block;
}

.popupTitle span:not(.titleCount) {
  margin-left: 5px;
}

.popupTitle span.titleCount {
  font-size: 1rem;
  font-weight: bold;
  margin-left: auto;
}

.popupInfoRow {
  display: flex;
  width: 100%;
}

.popupInfoRow span {
  display: inline-block;
}

.popupInfoRow span:last-child {
  margin-left: auto;
  color: @PrimaryCobalt60;
}

.sequenceList > span {
  font-weight: bold;
  margin-top: 5px;
  display: block;
}

.sequenceListItem > span {
  width: 100%;
  flex-grow: 1;
  display: block;
  margin-right: 8px;
  color: @PrimaryCobalt40;
}

.sequenceListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25rem;

  :global(.sequence-container) {
    width: 100%;
    flex-grow: 1;
  }

  :global(.row-icon-container) {
    margin-top: 0;
  }
}

.badgeIcon {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.badgeIcon .badge {
  position: absolute;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  width: 7px;
  height: 7px;
}

.badge.green {
  background-color: @PositiveColor120;
}

.badge.yellow {
  background-color: @Yellow;
}

.atsLogo {
  border-radius: 5px;
  display: inline-block;
  margin-left: 5px;
}

.atsDisabledLogo {
  opacity: 0.4;
}

.iconLink {
  // for some reason using "contents" breaks the popup
  text-decoration: none !important;
  display: flex;
}
