/*
 * colors.less
 */
.ui.modal.new-sender-modal.configure-settings .preview-label {
  color: #7982ad;
  font-size: 12px;
}
.ui.modal.new-sender-modal.configure-settings .field.signature-field {
  width: 100%;
}
.ui.modal.new-sender-modal.configure-settings .field.signature-field .content-edition-field {
  border: 1px solid #dae0f4;
  padding: 0.39285714em 1em;
  border-radius: 2px;
  font-size: 1em;
  box-shadow: none;
  outline: none;
  min-height: 80px;
  overflow: auto;
}
.ui.modal.new-sender-modal.configure-settings .field.signature-field .content-edition-field:focus {
  border-color: #4864c9;
}
.modal-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
