@import 'src/less/colors.less';

.dropdownContainer {
  // height: 32px;
  height: 40px;
  width: 100%;
  border: 1px solid @SecondaryBlue20;
  border-left: none;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background: white;

  &.mini {
    height: 32px;
  }

  // Semantic does some magic with triggers and forwardRef is not enough
  // so we style divs by default
  > div {
    width: 100%;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid @SecondaryBlue20;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
