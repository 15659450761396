/*
 * colors.less
 */
.root {
  position: relative;
  z-index: 0;
  padding: 0px 12px;
}
.root.minimized {
  padding: 0 4px;
}
.root.minimized .block {
  margin: 4px 0;
}
.block {
  margin: 10px 0px;
}
.block h4 {
  color: #1f2e77;
  margin-bottom: 0px;
}
.block h4 .headerLabel {
  margin-left: 8px !important;
  padding: 3px 4px 1px;
  color: #4864c9;
  background-color: #e2ebff;
}
.block h4 .ui.label {
  margin-left: 8px;
  padding: 3px 4px 1px;
  color: #4864c9;
  background-color: #e2ebff;
}
.noPendingAction {
  padding: 8px;
  background-color: white;
  border: 1px solid #dae0f4;
}
.noPendingAction h4 {
  margin-bottom: 4px;
  text-align: center;
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;
  color: #1f2e77;
}
.noPendingAction p {
  text-align: center;
  font-family: Graphik;
  font-size: 12px;
  color: #7982ad;
}
