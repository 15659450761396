/*
 * colors.less
 */
.menuContainer {
  display: inline-block;
  position: relative;
}
.menuContainer button {
  color: #4864c9 !important;
}
.menuContainer .menu {
  display: none;
}
.menuContainer .menu.open {
  display: block;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 1000;
  white-space: nowrap;
  background-color: white;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 4px;
}
.menuContainer .menu .option {
  display: block;
  padding: 16px;
  width: 100%;
  text-align: left;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  color: #1f2e77;
  cursor: pointer;
}
.menuContainer .menu .option:hover .label {
  text-decoration: underline;
}
.menuContainer .menu .option .icon {
  font-size: 1.3333em;
  margin-right: 8px;
}
.menuContainer .menu .option:not(:last-child) {
  border-bottom: 1px solid #dfdfe6;
}
.reasonContainer {
  margin-top: 24px;
}
.reasonContainer .reasonTitle {
  font-weight: 500;
  margin-bottom: 4px;
}
.buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.buttons button {
  flex: 1;
}
.buttons .buttonActive {
  border: 2px solid #4864c9;
}
.customButton {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  color: #4c5892;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
.mergeModalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.mergeModalContent .summary {
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
  cursor: pointer;
}
.mergeModalContent .summary span {
  margin-left: 8px;
}
.mergeModalContent .summaryContent {
  margin-top: 12px;
  border-left: 2px solid #1f2e77;
  padding-left: 12px;
  margin-left: 8px;
}
.mergeModalContent .profileIdExample {
  margin: 12px 0px;
}
.mergeModalContent .fakeInput {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
}
.mergeModalContent .fakeInput .urlPart1 {
  color: #7982ad;
}
.mergeModalContent .fakeInput .urlPart2 {
  color: #1f2e77;
}
.mergeModalContent .formField {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.mergeModalContent .formField .label {
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.mergeModalContent .formField .input {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #f3f4f8;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
}
.mergeModalContent .highlight {
  font-weight: 500;
}
