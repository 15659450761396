/*
 * colors.less
 */
.logos {
  position: absolute;
  top: 40px;
  left: 40px;
}
.logos .logo {
  margin-bottom: 16px;
}
.shadow {
  position: absolute;
  inset: 0 0 0 auto;
  width: 108px;
  background: linear-gradient(to right, transparent, #1f2e7726);
}
.corner {
  position: absolute;
  left: 24px;
  bottom: 44px;
  font-size: 40px;
  color: #c6d8ff;
}
