/*
 * colors.less
 */
.offersPage {
  margin: 0 70px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 440px;
  grid-template-rows: auto auto;
  column-gap: 24px;
}
@media (max-width: 1400px) {
  .offersPage {
    grid-template-columns: 1fr 320px;
  }
}
.offersPage .header {
  grid-row: 1;
  grid-column: 1 / span 2;
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offersPage .header .title {
  margin: 0;
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  color: #4864c9;
}
.offersPage .main {
  grid-row: 2;
  grid-column: 1;
}
.offersPage .main .emptyState {
  padding: 16px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  border-radius: 4px;
}
.offersPage .main .emptyState .cta {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  gap: 8px;
  margin: 0 4px;
}
.offersPage .aside {
  grid-row: 2;
  grid-column: 2;
}
.offersPage .aside .section {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: stretch;
  margin-bottom: 24px;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  border-radius: 8px;
  gap: 16px;
}
.offersPage .aside .section .illustration {
  min-width: 80px;
}
@media (max-width: 1400px) {
  .offersPage .aside .section .illustration {
    display: none;
  }
}
.offersPage .aside .section .content .title {
  margin-bottom: 6px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #1f2e77;
}
.offersPage .aside .section .content .description {
  margin-bottom: 6px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7982ad;
}
.offersPage .aside .section .wide {
  grid-column: 1 / span 2;
}
