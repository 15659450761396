/*
 * colors.less
 */
.dropdownContainer {
  position: relative;
  display: inline-block;
  width: fit-content;
}
.dropdownMenu {
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  left: calc(50% - 180px);
  width: 360px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dae0f4;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(31, 46, 119, 0.1);
  background-color: white;
}
.dropdownMenu.open {
  display: block;
}
.dropdownOption {
  display: block;
  width: 100%;
  padding: 9.5px 12px;
  line-height: 20px;
  font-family: Graphik;
  font-size: 12px;
  text-align: left;
  color: #4864c9;
  font-weight: 500;
}
.dropdownOption:not(:first-child) {
  border-top: 1px solid #dae0f4;
}
.dropdownOption:hover {
  background-color: #f6f7fc;
}
.dropdownOption.disabled {
  background-color: #f6f7fc;
  color: #7982ad;
}
.profileProjectsTab {
  padding-top: 24px;
}
.profileProjectsTab h3 {
  margin-top: 40px;
}
