@import 'src/less/colors';

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 100px;

  .loader:after {
    border-color: @PrimaryCobalt transparent transparent !important;
  }

  .checkmark {
    background-color: @PrimaryCobalt;
    color: white;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    margin: auto;
  }

  .modalSuccess {
    text-align: center;
    svg {
      margin-bottom: 24px;
    }
  }

  .cross {
    background-color: @SecondaryRed;
    color: white;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    margin: auto;
  }

  .inputLabel {
    margin-bottom: 6px !important;
    font-weight: 500 !important;
  }
}

.confirmationModalActions {
  justify-content: center !important;
}
