@import 'src/less/colors';

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  height: 64px;
  margin-bottom: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;

  .message {
    flex: 1;
    margin-left: 10px;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt80;
  }

  .link {
    flex-shrink: 0;
    width: max-content;
  }
}
