/*
 * colors.less
 */
.banner {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: #1f2e77;
}
.remaining {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: white;
}
.ctas {
  display: flex;
  gap: 8px;
}
