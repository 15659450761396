@import 'src/less/colors';
@import 'src/less/mixins';

.connectFormsList {
  margin-top: 16px;
  margin-bottom: 142px;

  background-color: white;
  border-radius: 3px;
  border: 1px solid #dae0f4;

  .connectFormsSettingsTable:global(.table.ui) {
    .BorderLessHeaderTable();
    .UppercaseTableHeader();
    .DefaultTableCells();
    .DefaultFirstColumn();

    .connectFormsTableHeaders {
      border-top: 1px solid #e8e8e8;
      color: red;
      tr {
        background-color: @SecondarySkyBlue05 !important;

        th {
          padding-left: 24px;
          padding-right: 24px;
          color: @PrimaryCobalt60;
          font-weight: 500;
          font-family: Graphik, sans-serif;
          font-size: 12px;
          font-style: normal;
        }
      }
      .button-header {
        .header-name {
          padding-top: 2px;
          display: inline-block;
        }

        &:hover {
          .header-name {
            text-decoration: underline;
          }
        }

        &.sort {
          .icon-container {
            vertical-align: top;
            display: inline-block;
          }

          &.increasing {
            .icon-container {
              padding-top: 1px;
            }
          }

          &.decreasing {
            .icon-container {
              padding-top: 2px;
            }
          }
        }
      }
    }

    .tableRow {
      .tableCell {
        padding-left: 24px;
        padding-right: 24px;
      }
      .actionTableCell {
        display: flex;
        justify-content: right;
        align-items: center;
        visibility: visible;

        .campaign-more-action-btn {
          padding: 5px 0px;
          text-align: center;
          width: 28px;
          height: 28px;
          border: 2px solid transparent;
          border-radius: 25%;

          &:hover {
            border: 2px solid @SecondaryBlue40;
          }
        }

        .placeholder-button {
          visibility: visible;
          width: 84px;
        }
      }
    }

    .campaign-header {
      display: flex;
      align-items: center;
      &.in-folder {
        margin-left: 30px;
      }
    }

    .campaign-header-title {
      .TextEllipsis();
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @PrimaryColorLight;
      }
    }

    .tableRow {
      cursor: pointer;

      &:hover,
      &.active {
        background-color: @SecondarySkyBlue05 !important;

        .actionTableCell {
          visibility: visible;
        }
      }

      td {
        white-space: nowrap;

        &:first-child {
          max-width: 200px;
          .TextEllipsis();
        }
      }
    }

    .campaign-icon {
      margin-right: 10px;
      color: #a5abc9;
    }

    .actionTableCell {
      text-align: center !important;

      :first-child {
        cursor: pointer;
      }
    }

    .contextMenu .contextBtnContainer {
      justify-content: center !important;
    }

    // USER THUMBNAILS
    div.box-title {
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0;
      }
    }

    .connectFormsTableRowActions {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: flex-start;
      cursor: pointer;
    }
  }
}
