/*
 * colors.less
 */
.button {
  display: inline-flex;
  padding: 0 16px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  font-family: Gilroy;
  font-weight: 700;
  transition: color ease 150ms, background-color ease 150ms, border-color ease 150ms;
}
.button.icon {
  padding: 0;
}
.button:is(button):not(:disabled),
.button:is(a):not(.disabled) {
  cursor: pointer;
}
.small {
  height: 24px;
  font-size: 12px;
  padding: 0 8px;
}
.small.icon {
  width: 24px;
}
.normal {
  height: 32px;
  font-size: 14px;
}
.normal.icon {
  width: 32px;
  font-size: 16px;
}
.big {
  height: 40px;
  font-size: 16px;
}
.big.icon {
  width: 40px;
  font-size: 20px;
}
.primary {
  color: white;
}
.primary:is(button):disabled,
.primary:is(a).disabled {
  background-color: #b6c1e9;
}
.primary:is(button):not(:disabled).blue,
.primary:is(a):not(.disabled).blue {
  background-color: #4864c9;
}
.primary:is(button):not(:disabled).blue:hover,
.primary:is(a):not(.disabled).blue:hover {
  background-color: #3450b5;
}
.primary:is(button):not(:disabled).red,
.primary:is(a):not(.disabled).red {
  background-color: #f66f81;
}
.primary:is(button):not(:disabled).red:hover,
.primary:is(a):not(.disabled).red:hover {
  background-color: #e45a6b;
}
.secondary {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: currentColor;
}
.secondary:is(button):disabled,
.secondary:is(a).disabled {
  color: #b6c1e9;
}
.secondary:is(button):not(:disabled),
.secondary:is(a):not(.disabled) {
  box-shadow: 0 2px 4px 0 #1f2e770f;
}
.secondary:is(button):not(:disabled).blue,
.secondary:is(a):not(.disabled).blue {
  color: #4864c9;
}
.secondary:is(button):not(:disabled).blue:hover,
.secondary:is(a):not(.disabled).blue:hover {
  color: #3450b5;
  background-color: #edf0fa;
}
.secondary:is(button):not(:disabled).red,
.secondary:is(a):not(.disabled).red {
  color: #f66f81;
}
.secondary:is(button):not(:disabled).red:hover,
.secondary:is(a):not(.disabled).red:hover {
  color: #e45a6b;
  background-color: #fff1f3;
}
.secondary:is(button):not(:disabled).grey,
.secondary:is(a):not(.disabled).grey {
  color: #7982ad;
  border-color: #b6c1e9;
}
.secondary:is(button):not(:disabled).grey:hover,
.secondary:is(a):not(.disabled).grey:hover {
  color: #4c5892;
  background-color: #f6f7fc;
}
.tertiary {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: #7982ad;
}
.tertiary:is(button):disabled,
.tertiary:is(a).disabled {
  color: #b6c1e9;
}
.tertiary:is(button):not(:disabled):hover,
.tertiary:is(a):not(.disabled):hover {
  color: #4c5892;
  background-color: #f6f7fc;
  border-color: #dae0f4;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  transition: all ease 150ms;
}
