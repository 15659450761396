/*
 * colors.less
 */
.profile-card-content {
  padding: 16px 24px 24px;
}
.profile-card-content:empty {
  padding: 12px 24px;
}
.profile-card-content .summary {
  color: #3450b5;
  background-color: #f6f7fc;
  border-radius: 2px;
  padding: 12px;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 14px;
}
.profile-card-content .row {
  display: flex;
  margin-right: -4px;
  margin-left: -4px;
}
.profile-card-content .row .body-item {
  flex: 1;
  flex-basis: 100%;
  margin-right: 4px;
  margin-left: 4px;
}
.profile-card-content .body-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  font-size: 12px;
}
.profile-card-content i {
  font-size: 17px;
  color: #a5abc9;
  padding-right: 8px;
}
.profile-card-content .skills {
  margin-top: 18px;
}
