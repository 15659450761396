@import 'src/less/colors';

.tableHeader {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 18px 24px;
  background-color: @SecondarySkyBlue05;
  color: @PrimaryCobalt60;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  border-bottom: 1px solid @SecondaryBlue20;

  .name {
    width: 300px;
  }

  .headerStage {
    flex: 1;

    &.span2 {
      flex: 2;
    }

    &.archived {
      text-align: center;
    }
  }
}
