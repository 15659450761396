/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.ui.modal.add-to-job-multiple-modal .modal-content {
  margin-bottom: 16px;
}
.ui.modal.add-to-job-multiple-modal .watch-profile-header {
  margin-bottom: 32px;
  padding: 0px;
  display: flex;
  align-items: center;
}
.ui.modal.add-to-job-multiple-modal .watch-profile-header .avatar-container {
  flex-shrink: 0;
  margin-right: 10px;
}
.ui.modal.add-to-job-multiple-modal .watch-profile-header .avatar-container img {
  width: 64px;
  height: 64px;
}
.ui.modal.add-to-job-multiple-modal .watch-profile-header .profile-data-extract {
  flex-grow: 1;
}
.ui.modal.add-to-job-multiple-modal .watch-profile-header .profile-data-extract .fullname {
  font-family: Gilroy;
  color: #4864c9;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.ui.modal.add-to-job-multiple-modal .add-to-new-job-button {
  width: 100%;
}
.ui.modal.add-to-job-multiple-modal .ui.active.visible.dropdown.job-dropdown div.menu.transition.visible div.item:last-of-type {
  margin-top: 8px;
  padding: 0 !important;
}
