@import 'src/less/colors';

.revealAnalyticsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 40px;

  border-bottom: 1px solid @PrimaryCobalt20;
  background-color: white;

  .navigationButtons {
    white-space: nowrap;
    margin: 0;
    padding: 4px 0;

    .navigationButton {
      display: inline-block;
      margin: 0;
      margin-right: 24px;
      padding: 0;
      font-family: Gilroy;
      font-weight: 700;
      font-size: 22px;
      color: @PrimaryCobalt40;
      cursor: pointer;

      &:hover {
        color: @SecondaryBlue80;
      }

      &.selected {
        color: @PrimaryCobalt;
      }
    }
  }

  .selectionDropdown {
    display: flex;
    align-items: center;

    .leftDropdown {
      margin-right: 12px;

      .multiDropdown {
        background: #ffffff;
        border: 1px solid @SecondarySkyBlue20;
        box-sizing: border-box;
        border-radius: 2px;
        min-width: 180px;
        display: flex;

        :global(.label) {
          display: flex;
          white-space: nowrap;
        }

        :global(.sizer) {
          opacity: 0;
        }
      }

      .sequencesDropdown,
      .missionsDropdown {
        min-width: 200px;
        max-width: 370px;
        flex-wrap: wrap;
      }
    }

    .dateRangePicker {
      flex-grow: 0;
      max-width: 128px;
      min-width: 80px;

      &.last {
        margin-right: 12px;
      }

      input {
        width: 100%;
      }
    }
  }
}
