/*
 * colors.less
 */
.profile-background .background-filter {
  display: none;
}
.profile-background .background-section {
  padding: 24px 0;
}
.profile-background .background-section:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.profile-background.reveal {
  color: #1f2e77;
}
.profile-background.reveal .background-section-title {
  margin: 0px 0 16px 0;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}
.profile-background.reveal .background-section-title.summary-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 15px;
}
.profile-background.reveal .background-section-title.summary-title .summary-stack-container {
  display: flex;
}
.profile-background.reveal .background-section-title.summary-title .summary-stack-container img {
  max-width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}
.profile-background.reveal .background-section-title.summary-title .summary-stack-container .technology-icon {
  max-width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}
.profile-background.reveal .background-section-title.summary-title .summary-stack-container .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}
.profile-background.reveal .background-section-title.summary-title .summary-stack-container .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  padding-top: 0.5px;
  font-family: Graphik;
  padding-right: 2px;
  padding-left: 3px;
  font-weight: 400;
}
.profile-background .background-section-title {
  color: #1f2e77;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}
.profile-background .background-placeholder {
  margin: 50px 100px 50px 140px;
  width: calc(100% - 240px);
  opacity: 0.4;
}
.profile-background .questions-form .question-title {
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #1f2e77;
}
.profile-background .attachments-section .subsection h4,
.profile-background .attachments-section .subsection h5 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #4c5892;
}
.profile-background .attachments-section .subsection h4 > i,
.profile-background .attachments-section .subsection h5 > i {
  margin-right: 10px;
  padding-bottom: 4px;
  font-weight: normal;
  font-size: 18px;
}
.profile-background .attachments-section .subsection h4 > span,
.profile-background .attachments-section .subsection h5 > span {
  margin-right: 0.5em;
}
.profile-background .attachments-section .subsection .attachment-viewer {
  margin-bottom: 24px;
}
.profile-background .profile-summary {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px !important;
  white-space: pre-line;
  color: #4c5892;
}
