/*
 * colors.less
 */
.integrationHelper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
  background-color: #f1f6ff;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  color: #1f2e77;
}
.integrationHelperInstructions {
  flex-shrink: 0;
  align-self: center;
}
.breadcrumbDividerIcon {
  font-size: 18px;
  margin: 0px 10px;
}
.subHeader {
  color: #7982ad;
}
.integrationHelperInstructionsHeaderClose {
  display: inline-block;
  float: right;
  cursor: pointer;
}
