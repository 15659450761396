@import 'src/less/colors.less';

.headLabel {
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  > span:first-child {
    font-size: 14px;
    color: @PrimaryCobalt;
    font-family: 'Graphik';
    font-weight: 500;
  }

  > span.timezoneToggle {
    font-size: 12px;
    color: @SecondaryBlue;
    font-family: 'Graphik';
    font-weight: 500;
    margin-left: auto;
    cursor: pointer;

    svg {
      scale: 1.5;
      margin-left: 5px;
    }
    &.up svg {
      rotate: 90deg;
    }

    &.down svg {
      rotate: -90deg;
    }
  }
}

.timezoneSelect {
  margin-bottom: 8px;
  margin-top: 10px;
}

// Default options
.defaultTimeOption {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  cursor: pointer;

  &.checked {
    border-color: @SecondaryBlue;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .label {
    color: @PrimaryCobalt;
    font-size: 14px;
    font-family: 'Graphik';
    font-weight: 500;
    margin-left: 8px;
  }

  .time {
    margin-left: auto;
    font-size: 14px;
    font-family: 'Graphik';
    color: @PrimaryCobalt60;
    font-weight: 400;
  }
}

.timeInput {
  margin-left: auto;
}

.circleCheckbox {
  width: 22px;
  height: 22px;
  border-radius: 100%;
}
