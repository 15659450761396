/*
 * colors.less
 */
.panel {
  padding: 12px;
  background-color: #f8faff;
  border-radius: inherit;
}
.title {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: left;
  color: #1f2e77;
}
.button {
  width: 100%;
  margin-top: 8px;
}
.datepicker {
  margin-top: 8px;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  font-family: Graphik;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #1f2e77;
  outline: none;
}
