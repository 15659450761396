@import 'src/less/colors';

.offersPage {
  margin: 0 70px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 440px;
  grid-template-rows: auto auto;
  column-gap: 24px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 320px;
  }

  .header {
    grid-row: 1;
    grid-column: 1 / span 2;

    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: 0;
      font-family: Gilroy;
      font-size: 22px;
      font-weight: 700;
      line-height: 27px;
      color: @SecondaryBlue;
    }
  }

  .main {
    grid-row: 2;
    grid-column: 1;

    .emptyState {
      padding: 16px;
      background-color: white;
      box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
      border-radius: 4px;

      .cta {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        gap: 8px;
        margin: 0 4px;
      }
    }
  }

  .aside {
    grid-row: 2;
    grid-column: 2;

    .section {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: stretch;
      margin-bottom: 24px;
      padding: 16px;
      background-color: white;
      box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
      border-radius: 8px;
      gap: 16px;

      .illustration {
        @media (max-width: 1400px) {
          display: none;
        }
        min-width: 80px;
      }

      .content {
        .title {
          margin-bottom: 6px;
          font-family: Gilroy;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          color: @PrimaryCobalt;
        }

        .description {
          margin-bottom: 6px;
          font-family: Graphik;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: @PrimaryCobalt60;
        }
      }

      .wide {
        grid-column: 1 / span 2;
      }
    }
  }
}
