@import 'src/less/colors';

.row {
  min-height: 72px;
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid @SecondaryBlue20;
  padding: 4px;
}
