/*
 * colors.less
 */
.user-initial-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 2px;
  background-color: #f9c8ca;
  color: #1f2e77;
  font-weight: 500;
}
