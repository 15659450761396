/*
 * colors.less
 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.header h4 {
  margin: 0;
}
.form {
  display: flex;
  flex-direction: column;
  z-index: 0;
}
.form .selectors {
  z-index: 1;
}
.form .selector {
  margin-bottom: 12px;
}
.form .editor {
  z-index: 0;
}
.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  gap: 8px;
}
.error {
  font-size: 12px;
  color: #f66f81;
}
