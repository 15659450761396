@import 'src/utils';
@import 'src/less/mixins';
@import 'src/less/colors';

.ui.modal.target-account-modal {
  .description {
    text-align: center;
  }
  .edit-sender-details {
    margin: 0px;

    .row {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 15px;
      }
    }

    .field .input {
      width: 100%;
    }

    .field.alias-field {
      margin-bottom: 10px;

      input {
        font-family: Graphik, sans-serif;
      }
    }

    .field.signature-field {
      border: 1px solid #d6d6dc;
      padding: 5px;
      border-radius: 3px;
      font-size: 13px;

      input {
        font-family: Graphik, sans-serif;
      }

      .content-edition-field {
        box-shadow: none;
        border: none;
        outline: none;
        min-height: 80px;
        overflow: auto;
      }
    }

    .field.email-field {
      display: flex;

      input {
        width: auto;
        flex-grow: 1;
      }

      button {
        cursor: pointer;

        &:hover,
        :focus {
          background-color: #d6d6dc;
        }

        &:disabled {
          cursor: wait;
        }
      }
    }

    textarea {
      resize: none;
    }

    .input-error {
      border-color: #e08383;
    }
  }

  .select-mail-account {
    .button-container {
      display: flex;
      flex-direction: row;
    }
  }
}

// step 1
.ui.modal.target-account-modal.add-account {
  .signin-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto 20px auto;
    width: 350px;
  }

  .email-signin-button {
    border: 1px solid @SecondaryBlue20;
    text-align: left;
    border-radius: 8px;
    padding: 10px 15px;

    width: 100%;

    span {
      font-family: Gilroy, sans-serif;
      font-weight: 600;
      margin-left: 20px;
    }

    &:not(.loader-button):hover {
      border: 1px solid @PrimaryColorFaded;
      cursor: pointer;
    }

    margin-bottom: 15px;
  }

  .confidentiality-text {
    margin-top: 15px;
    font-size: 12px;
  }

  .loader-box {
    text-align: left;
  }
}

.modal-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

// step 2
.ui.modal.target-account-modal.configure-settings {
  .preview-label {
    color: @PrimaryCobalt60;
    font-size: 12px;
  }

  .field.signature-field {
    width: 100%;

    .content-edition-field {
      border: 1px solid @SecondaryBlue20;
      padding: 0.39285714em 1em;
      border-radius: 2px;
      font-size: 1em;
      box-shadow: none;
      outline: none;
      min-height: 80px;
      overflow: auto;

      &:focus {
        border-color: @SecondaryBlue;
      }
    }
  }
}

.modal-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
