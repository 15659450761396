@import 'src/less/colors.less';

.jobBreadcrumb {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;

  .breadCrumbSection {
    font-family: Gilroy;
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    color: @PrimaryCobalt;
    white-space: nowrap;

    &.collapsible {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .breadcrumbDivider {
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px;
    color: @PrimaryCobalt;
  }
}
