/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.ui.modal.new-profile-modal {
  width: 650px;
}
.ui.modal.new-profile-modal .close.icon {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: #000000;
  opacity: 0.2;
}
.ui.modal.new-profile-modal .close.icon::before {
  font-family: verdana;
  content: '✕';
  font-weight: bold;
}
.ui.modal.new-profile-modal .close.icon:hover {
  opacity: 0.4;
}
.ui.modal.new-profile-modal .header {
  background: #f8f8f8;
  border-bottom: none;
  border-radius: 3px !important;
  padding-top: 25px;
  padding-bottom: 19px;
  padding-left: 60px;
  padding-right: 60px;
}
.ui.modal.new-profile-modal .header h4 {
  margin-bottom: 4px;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 17px;
  color: #1f2e77;
  letter-spacing: -0.21px;
}
.ui.modal.new-profile-modal .header h5 {
  margin: 0;
  font-family: Graphik;
  font-size: 13px;
  font-weight: normal;
  color: #767689;
}
.ui.modal.new-profile-modal .content {
  position: relative;
  background: #ffffff;
  padding-top: 60px / 2;
  padding-bottom: 60px / 2;
  padding-left: 60px;
  padding-right: 60px;
  font-family: Graphik;
  font-size: 15px;
  color: #2b3d4f;
  text-align: left;
}
.ui.modal.new-profile-modal .content:after {
  position: absolute;
  bottom: 0px;
  left: 60px;
  content: '';
  width: 530px;
  height: 1px;
  opacity: 0.5;
  background: #d6d6dc;
}
.ui.modal.new-profile-modal .actions {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 60px;
  border: none;
  background: none;
}
.ui.modal.new-profile-modal .actions .ui.button {
  margin-right: 0;
}
.ui.modal.new-profile-modal .ui.input {
  width: 100%;
}
.ui.modal.new-profile-modal .content {
  padding-top: 50px;
}
.ui.modal.new-profile-modal .form-row {
  display: flex;
  flex-direction: column;
  color: #1f2e77;
}
.ui.modal.new-profile-modal .light-text {
  opacity: 0.5;
}
.ui.modal.new-profile-modal .form-row-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-bottom: 10px;
  width: 400px;
}
.ui.modal.new-profile-modal .form-row-element-text {
  width: 115px;
  opacity: 0.5;
}
.ui.modal.new-profile-modal .form-row-default-element-value {
  width: 100%;
  color: #1f2e77;
}
.ui.modal.new-profile-modal .form-error {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: #9f3a38;
  width: 100%;
  margin-left: 10px;
  margin-bottom: 10px;
  line-height: 1.2;
}
.ui.modal.new-profile-modal .form-section-title {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  color: #1f2e77;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
  padding: 20px 0px;
}
.ui.modal.new-profile-modal .form-section-title.optional {
  display: flex;
  align-items: center;
}
.ui.modal.new-profile-modal .form-section-title.optional i.dropdown.icon {
  margin-bottom: 2px;
}
.ui.modal.new-profile-modal .ui.accordion .content:after {
  display: none;
}
.ui.modal.new-profile-modal .ui.dropdown.form-row-dropdown {
  color: #1f2e77;
}
.ui.modal.new-profile-modal .ui.dropdown.form-row-dropdown div.text {
  width: 100%;
}
.ui.modal.new-profile-modal .ui.dropdown.form-row-dropdown .selected.item {
  font-weight: 600;
}
