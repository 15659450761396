/*
 * colors.less
 */
.application {
  padding-left: 20px;
  font-size: 12px;
  color: #1f2e77;
}
.application:not(:last-child) {
  margin-bottom: 5px;
}
.jobTitle {
  font-weight: 500;
}
.applicationInfo {
  line-height: 0.8em;
}
.applicationDescription,
.applicationDate {
  font-weight: 400;
}
.applicationDate {
  color: #7982ad;
  margin-left: 8px;
}
