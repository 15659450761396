@import 'src/less/colors';

.panel {
  padding: 12px;
  background-color: @SecondarySkyBlue05;
  border-radius: inherit;
}

.button {
  width: 100%;
  margin-top: 8px;
}

.selectAllContainer {
  display: block;
  background-color: #fff;
  padding: 8px 12px 2px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: @SecondaryBlue;
  border-top: 1px solid @SecondaryBlue20;

  .selectAllButton {
    cursor: pointer;
  }
}

.operatorSwitch {
  margin-bottom: 8px;
}
