/*
 * colors.less
 */
.volumeByStage {
  margin: 20px 0;
  background: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.volumeByStage .loader {
  margin: 100px auto;
}
.volumeByStage .top {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 11px 0 24px;
}
.volumeByStage .top h3 {
  color: #1f2e77;
  margin: 0;
}
