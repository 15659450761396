@import 'src/less/colors';

.dropdownMenuContainer {
  z-index: 12;
}

.archiveReasonsMenuContainer {
  width: 600px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 8px;
  padding: 0px;

  .archiveReasonsMenu {
    padding: 24px;

    .archiveReasonsContainer {
      margin: 12px 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .skipReason {
        flex-grow: 1;
        border-radius: 8px;
        padding: 8px;
        color: @PrimaryCobalt;
        background-color: white;
        text-align: center;
        font-family: Graphik;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
        border: 1px solid @SecondaryBlue20;
        cursor: pointer;
        width: calc(50% - 4px);
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;

        i {
          color: @PrimaryCobalt40;
          font-size: 16px;
        }

        &.selected,
        &:hover {
          border: 1px solid @SecondaryBlue;
          background-color: @SecondarySkyBlue20;

          i {
            color: @SecondaryBlue;
          }
        }
      }
    }

    .showMoreBtn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;
      width: fit-content;
      margin: 0px 0px;
      //styleName: Body 12/Medium 500;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: @SecondaryBlue;
      cursor: pointer;
    }

    .reasonInputContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      .reasonInput {
        flex-grow: 1;

        input {
          border-radius: 8px;
          padding: 8px 12px 8px 12px;
          box-shadow: 0px 2px 4px 0px #1f2e770f;
          color: @PrimaryCobalt;
          font-family: Graphik;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
        }
      }
    }
  }

  .archiveReasonsFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 16px 0px #1f2e7726;
    padding: 16px 24px 16px 24px;
  }
}
