/*
 * colors.less
 */
.hint span {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.subStepEditionRawContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}
.subStepEditionRawContainer .subStepEditionToggle {
  margin: 0;
}
.subStepEditionFakeInput {
  flex: 1 1;
  display: inline-flex;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  grid-gap: 8px;
  gap: 8px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
  color: #1f2e77 !important;
}
.tableHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;
}
.tableHeader h3 {
  margin: 0 !important;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.newSubstepContainer {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: #a5abc9;
  border: 2px dashed #d2d5e4;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  margin: 0px 0px 4px 44px;
  opacity: 1;
}
.newSubstepNameLabel {
  margin-bottom: 6px;
  font-weight: 500;
}
.panel .panelOption {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.panel .panelOption .panelOptionIcon,
.panel .panelOption i {
  width: 16px;
  color: #a5abc9;
}
.newSubstepContainer.newSubstepVisible {
  opacity: 1;
}
.newSubstepContainer:hover {
  background: #f3f4f8;
}
.newSubstepSpacer {
  height: 40px;
}
.newSubstepNameInput {
  width: 100%;
}
.substepActions {
  margin-top: 8px;
}
.infoBox {
  margin-top: 12px;
  background-color: #f1f6ff;
  padding: 16px;
  border-radius: 8px;
  color: #1f2e77;
  font-size: 12px;
}
