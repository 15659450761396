/*
 * colors.less
 */
.segmentationLine {
  display: flex;
  flex-flow: row nowrap;
  font-family: Graphik, sans-serif;
  color: #a5abc9;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px 0 0;
  background-color: white;
  border: 1px solid #dae0f4;
  border-bottom-width: 0;
  overflow-x: auto;
}
.segmentationLine .startSeparator {
  border-left: 1px solid #dae0f4;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: center;
  color: #7982ad;
  padding: 12px 12px 12px 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.segmentationLine .endSeparator {
  border-right: 1px solid #dae0f4;
}
.segmentationDropdown:global(.ui.dropdown) {
  border-right: 1px solid #dae0f4;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.segmentationLineItem {
  margin: 0px 12px;
  cursor: pointer;
  padding: 12px 12px;
  font-family: Graphik;
  font-weight: 500;
  font-style: normal;
  flex-shrink: 0;
  outline: 0;
}
.segmentationLineItem.segmentationLineItemSelected {
  color: #4864c9;
  border-bottom: 2px solid #4864c9;
}
.segmentationLineItem:focus {
  color: #4864c9;
}
.itemDivider {
  display: inline-block;
  margin: 0 0.5rem;
}
