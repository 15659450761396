@import 'src/less/colors';

.stackedArea {
  position: relative;
  height: 100%;
}

.tooltip {
  min-width: 240px;
  padding: 12px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 2px 16px @PrimaryCobaltOp15;

  .tooltipHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid @SecondaryBlue20;
    font-size: 14px;
    font-family: Gilroy;
    font-weight: 700;
    color: @PrimaryCobalt;
  }

  .tooltipItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 8px;
    font-size: 12px;
    font-family: Graphik;
    font-weight: 500;
    color: @PrimaryCobalt60;
  }
}
