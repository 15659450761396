@import 'src/less/colors';
@import 'src/less/mixins';

// COMMON

.pill-message {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;

  &.small {
    padding: 3px 8px;
  }

  &.mini {
    padding: 0px 8px;

    i {
      vertical-align: text-bottom;
      margin-right: 2px;
    }
  }

  &.small-radius {
    border-radius: 6px;
  }

  &.spaced {
    margin-left: 8px;
  }

  &.green {
    background-color: #eff9ee;
    color: @PositiveColor120;
  }

  &.red {
    background-color: @SecondaryRed20;
    color: @NegativeColor120;
  }

  &.yellow {
    background-color: @WarningColor10;
    color: @WarningColor120;
  }

  &.grey {
    background-color: @PrimaryCobalt10;
    color: #7982ad;
  }

  &.orange {
    background-color: #fff1c6;
    color: #eba91a;
  }

  &.blue {
    background-color: @SecondarySkyBlue20;
    color: @SecondarySkyBlue120;
  }
}

// HEAD

.reveal-list-header {
  background-color: @SecondarySkyBlue05;
  border-top: 1px solid @SecondaryBlue20;
}

.reveal-list-row,
.reveal-list-header {
  min-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid @SecondaryBlue20;

  &:last-child {
    // Hack to avoid double border
    border-bottom: none;
    box-shadow: 0 0 0 1px @SecondaryBlue20;
  }

  padding: 8px 8px 8px 0;

  .row-checkbox-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    // padding for click-ability
    padding-left: 18.5px;
    padding-right: 11px;
  }

  .avatar-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0;
    min-width: 32px;
    margin-right: 13px;

    img {
      width: 32px;
      height: 32px;
    }

    .source-indicator {
      z-index: 1;
      position: absolute;
      top: 22px;
      left: 22px;
      background-color: @SecondarySkyBlue;
      height: 12px;
      min-width: 12px;
      border-radius: 12px;
      border: 1px solid white;
      box-sizing: border-box;

      i {
        position: absolute; // center the icon in the circle
        top: 1.8px;
        left: 1.4px;

        &.small.icon {
          font-size: 6px;
        }
      }

      span {
        position: absolute;
        font-size: 10px;
        line-height: 1;
        top: 2px;
        left: 14px;
        color: white;
      }

      &.hover {
        width: 85px;
      }
    }
  }

  .name-and-headline-container {
    display: flex;
    flex: 2 0 10%;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;

    .headline-and-custom-fields {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .score-container {
    display: flex;
    align-items: center;
    margin: 0 8px;
    flex: 1 0 15%;
  }

  .missions-container,
  .campaign-container,
  .relevance-container,
  .sequence-container,
  .active-sequence-container,
  .deal-state-container,
  .comment-container,
  .campaign-state-container {
    display: flex;
    align-items: center;
    margin: 0 8px;
    width: 20%;

    font-size: 12px;
    color: @PrimaryCobalt80;
  }

  .missions-container {
    flex: 1 0;
  }

  .sequence-container,
  .active-sequence-container {
    flex: 1.5 0;
  }

  .no-match-level {
    color: @PrimaryCobalt40;
    margin-left: 32px;
  }

  .stat-container {
    display: flex;
    align-items: center;
    margin: 0 4px;
    flex: 1 0 0;

    justify-content: center;

    .stat {
      display: flex;
      color: @PrimaryCobalt40;
    }
  }

  .full-name {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    color: @SecondaryBlue;

    .full-name-text {
      flex-grow: 1;
    }
  }

  .headline {
    font-family: Graphik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    color: @PrimaryCobalt80;
  }

  .skills {
    margin: 0 21px;
    width: 40%;
    //padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .last-interaction {
    .email {
      margin-bottom: 2px;
      font-weight: 500;
      margin-bottom: 1m;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.reveal-list-row {
  cursor: pointer;
  padding-left: 4px;

  &:hover,
  &.selected {
    background-color: rgba(72, 100, 201, 0.03);
  }

  &.selected {
    padding-left: 0px;
    border-left: 4px solid @SecondaryBlue;
  }
}

.reveal-list-header {
  padding-left: 4px;
  border-bottom: 1px solid @SecondaryBlue20;

  .header-text {
    color: @PrimaryCobalt60;
    font-weight: 500;
  }
}

.last-interaction-container {
  display: flex;
  align-items: center;
  margin: 0 4px;
  flex: 1.5 0;

  font-size: 12px;
  color: @PrimaryCobalt80;
}

.next-interaction-container {
  display: flex;
  align-items: center;
  margin: 0 4px;
  flex: 1.5 0;

  font-size: 12px;
  color: @PrimaryCobalt80;

  .next-interaction {
    .email {
      margin-bottom: 2px;
      font-weight: normal;
    }

    .date {
      font-weight: 500;
    }

    .tasks-action {
      * {
        padding: auto;
        vertical-align: middle;
        display: inline;
      }

      h5  {
        font-weight: 600;
        margin-left: 0.5em;
        line-height: 20px;
      }

      img {
        width: 18px;
        padding: auto;
      }

      .ri-external-link-line {
        margin-left: 5px;
        margin-bottom: 2px; // center icon vertically
        display: inline-block;
      }
    }
  }
}

// PROFILES

.generic-profiles-list-header {
  background-color: @SecondarySkyBlue05;
  border-top: 1px solid @SecondaryBlue20;
  min-height: 56px !important;
  position: relative;
}

.generic-profile-row,
.generic-profiles-list-header {
  min-height: 72px;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:not(.generic-profiles-list-header) {
    cursor: pointer;
  }

  padding: 0px 0px 0px 4px;

  .row-checkbox-container {
    display: flex;
    //align-items: center;
    // padding for click-ability
    padding-left: 18.5px;
    padding-right: 11px;
    margin-top: auto;
    margin-bottom: auto;

    .checkbox {
      width: 22px !important;
      transform: scale(1.4665);
      input {
        border-radius: 4px !important;
      }
    }
  }

  .whitespace-container {
    flex: 1 0;
  }

  .avatar-container {
    flex: 0 0;
    min-width: 32px;
    margin-right: 13px;
    display: flex;
    align-items: center;

    .source-indicator {
      z-index: 1;
      position: absolute;
      top: 22px;
      left: 22px;
      background-color: @SecondarySkyBlue;
      height: 12px;
      min-width: 12px;
      border-radius: 12px;
      border: 1px solid white;
      box-sizing: border-box;

      i {
        position: absolute; // center the icon in the circle
        top: 1.8px;
        left: 1.4px;

        &.small.icon {
          font-size: 6px;
        }
      }

      span {
        position: absolute;
        font-size: 10px;
        line-height: 1;
        top: 2px;
        left: 14px;
        color: white;
      }

      &.hover {
        width: 85px;
      }
    }
  }

  .name-and-headline-container {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
    flex: 2.5 0 0;
    max-width: 15%;
  }

  .stage-container {
    display: flex;
    align-items: center;
    margin: 0 8px;
    flex: 2 0 0;

    color: @PrimaryCobalt;
  }

  .missions-container,
  .title-container,
  .company-container,
  .email-container,
  .source-container,
  .has-been-candidate-container,
  .subtype-container {
    width: 5%;
  }

  .missions-container,
  .campaign-container,
  .relevance-container,
  .sequence-container,
  .title-container,
  .company-container,
  .source-container,
  .has-been-candidate-container,
  .email-container,
  .subtype-container,
  .deal-state-container,
  .comment-container,
  .active-sequence-container,
  .phone-container {
    display: flex;
    align-items: center;
    margin: 0 8px;

    font-size: 12px;
    color: @PrimaryCobalt;

    .sequence-title {
      font-weight: 500;
      margin-bottom: 0;
      color: @SecondaryBlue;
    }
  }

  .subtype-container {
    .content {
      display: flex;
      flex-direction: column;

      .pill-message {
        display: block;
        max-width: 100%;
        white-space: break-spaces;
        text-align: center;
        line-height: 12px;
      }
    }
  }

  .campaign-container,
  .missions-container,
  .subtype-container,
  .title-container,
  .company-container,
  .source-container,
  .has-been-candidate-container,
  .email-container,
  .deal-state-container,
  .comment-container,
  .relevance-container,
  .phone-container {
    flex: 1 0;
  }

  .email-container {
    .email-link {
      color: @PrimaryCobalt60;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .campaign-container {
    .pill-message:first-of-type {
      margin: 0 !important;
    }
  }

  .sequence-container,
  .active-sequence-container {
    flex: 1.5 0;
    width: 15%;
    .row-sequence-container {
      width: 100%;

      .sequence-title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .full-name {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    color: @SecondaryBlue;

    .full-name-text {
      flex-grow: 1;
    }
  }

  .headline {
    font-family: Graphik, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    color: @PrimaryCobalt80;
  }

  .skills {
    margin: 0 21px;
    width: 40%;
    //padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.generic-profile-row {
  &:hover,
  &.selected {
    background-color: @SecondarySkyBlue05;
  }

  &.selected {
    padding-left: 0px;
    border-left: 4px solid @SecondaryBlue;
  }

  &.disabled {
    cursor: initial;
    pointer-events: none !important;
    filter: grayscale(1);
    background-color: rgba(150, 150, 150, 0.1);
  }
}

.generic-profiles-list-header {
  border-bottom: 1px solid @SecondaryBlue20;

  .header-text {
    color: @PrimaryCobalt60;
    font-weight: 500;
  }
}

.tasks-action a {
  cursor: pointer;
}

.ats-stage-header {
  display: flex;
  align-items: center;
  margin: 0 4px;
  flex: 1.5 0;

  font-size: 12px;
  color: @PrimaryCobalt80;
}
