/*
 * colors.less
 */
.select {
  min-width: 150px;
}
.picker {
  padding: 0 10px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  background-color: white;
  min-height: 38px;
  color: #1f2e77;
  outline: none;
}
.picker:hover {
  border: 1px solid #c6d8ff;
  box-shadow: 0px 2px 4px 0px #1f2e770f, inset 0px 0px 0px 1px #c6d8ff;
}
