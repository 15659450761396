/*
 * colors.less
 */
.pipeline-table-placeholder {
  display: flex;
  align-items: center;
  height: 164px;
}
.ui.table.pipeline-table {
  font-size: 12px;
  margin-top: 60px;
}
.ui.table.pipeline-table thead.pipeline-table-header th.pipeline-table-header-cell {
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  color: #a5abc9;
  background-color: white;
  border: none;
}
.ui.table.pipeline-table .pipeline-table-email-cell {
  color: #1f2e77;
}
.ui.table.pipeline-table .pipeline-totals td {
  padding-bottom: 48px;
}
.ui.table.pipeline-table .sent {
  color: #1f2e77;
}
.ui.table.pipeline-table .opened {
  color: #4864c9;
}
.ui.table.pipeline-table .answered {
  color: #5c89eb;
}
.ui.table.pipeline-table .positive-answered {
  color: #a9c4ff;
}
.ui.table.pipeline-table .process {
  color: #3cde56;
}
.ui.table.pipeline-table .hired {
  color: #14b62e;
}
