/*
 * colors.less
 */
.generic-offer-label-container .offer-label {
  height: 20px;
  border-radius: 10px;
  white-space: nowrap;
  background: #f1f6ff;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 20px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
  color: #709dff;
  display: flex;
  align-items: center;
  gap: 5px;
}
.generic-offer-label-container .offer-label svg {
  height: 10px;
  width: 10px;
}
.generic-offer-label-container .offer-label i {
  font-size: 11px;
}
.generic-offer-label-container .offer-label.hoverable {
  cursor: pointer;
}
.offer-label-container {
  display: inline-block;
}
.offer-label-container .offer-label {
  height: 20px;
  border-radius: 10px;
  white-space: nowrap;
  background: #f1f6ff;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 20px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
  color: #709dff;
  display: flex;
  align-items: center;
  gap: 5px;
}
.offer-label-container .offer-label svg {
  height: 10px;
  width: 10px;
}
.offer-label-container .offer-label i {
  font-size: 11px;
}
.offer-label-container .offer-label.hoverable {
  cursor: pointer;
}
.offer-label-container .offer-label.watch-label {
  background: #4864c9;
  color: white;
}
.offer-label-container .offer-label.waiting-for-validation {
  background: #f3f4f8;
  color: #a5abc9;
}
.offer-label-container .offer-label.hold {
  background: #f3f4f8;
  color: #a5abc9;
}
.offer-label-container .offer-label.unlimited-flow {
  background: #f1f6ff;
  color: #709dff;
}
.offer-label-container .offer-label.job-active {
  background-color: #28ca421a;
  color: #28ca42;
}
.offer-label-container .offer-label.job-workflow-label {
  background-color: #28ca421a;
  color: #28ca42;
}
.offer-label-container .offer-label.has-owner {
  background-color: #28ca421a;
  color: #28ca42;
}
.offer-label-container .offer-label.has-no-owner {
  background: #eba91a1a;
  color: #ffbd2e;
}
.offer-label-container .offer-label svg.job-suspended-icon {
  color: transparent;
}
.offer-label-container .offer-label svg.job-suspended-icon path {
  color: #a5abc9;
}
.offer-label-container .offer-label svg.job-suspended-icon circle {
  stroke: #a5abc9;
}
