/*
 * colors.less
 */
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}
.wrapper .item {
  width: 10px;
  height: 6px;
  border-radius: 4px;
  background-color: #c6d8ff;
}
.wrapper .active {
  width: 24px;
  background-color: #709dff;
}
