@import 'src/less/colors';

.section {
  margin: 24px 0;

  .title {
    color: @PrimaryCobalt;
  }

  .graph {
    margin-top: 16px;
    background: white;
    border: 1px solid @SecondaryBlue20;
    border-radius: 8px;

    .loader {
      margin: 100px auto;
    }
  }
}
