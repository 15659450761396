@import 'src/less/colors';

.contextBtnContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  visibility: visible;
}

.contextBtn {
  padding: 6px 0px;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  border-radius: 25%;
  color: @PrimaryCobalt60;

  &:hover {
    border: 1px solid @SecondaryBlue20;
    background: @SecondaryBlue05;
    box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
  }
}

.contextMenuContainer {
  max-width: 200px;
}

.contextMenuPopup {
  padding: 0px !important;
}

.contextMenuAction {
  cursor: pointer;
  padding: 0.6em 1.14em !important;
  display: flex;
  align-items: center;
  max-width: 100%;

  span {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg,
  i,
  img {
    margin-right: 8px;
    color: @PrimaryCobalt40;
  }

  &:not(.disabled):hover {
    background-color: @SecondaryBlue10;
  }

  &.disabled {
    background-color: @PrimaryCobalt05;
    cursor: default;

    span {
      color: @PrimaryCobalt20;
    }

    svg,
    i,
    img {
      margin-right: 8px;
      color: @PrimaryCobalt20;
    }
  }
}
