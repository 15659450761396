@import 'src/less/colors';

.customFieldValue {
  display: flex;
  align-items: center;
  flex: 1 0;

  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  color: @PrimaryCobalt;

  .content {
    display: flex;
    flex-direction: column;
  }
}
