/*
 * colors.less
 */
.summary {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}
.title {
  font-size: 12px;
  color: #7982ad;
}
