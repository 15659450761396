/*
 * colors.less
 */
.dropdownTrigger {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdownTrigger .trigger {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdownTrigger .actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.dropdownTrigger .actions i {
  color: #7982ad;
}
.dropdownTrigger .actions i.dropdownIcon {
  margin-top: -5px;
}
.dropdownTrigger .actions i:not(:last-child) {
  margin-right: 12px;
}
