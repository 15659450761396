/*
 * utils.less
 */
/*
 * colors.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.pending-actions {
  margin: -1rem 0 0 0;
  position: relative;
}
.pending-actions .timeline-card-mail-wrapper.classic-item .content {
  border: none !important;
}
.pending-actions:before {
  border-left: 3px solid rgba(17, 46, 71, 0.1);
  content: ' ';
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.pending-actions h3.section-title {
  margin-bottom: 15px;
  font-family: Graphik, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #709dff;
  line-height: 30px;
}
.pending-actions .action-trigger-editor {
  color: #2b3d4f;
}
.pending-actions .action-trigger-editor .ui.dropdown {
  margin-left: 8px;
  font-size: 13px;
  font-family: Graphik;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0px 6px;
  border-radius: 4px;
  height: 22px;
}
.pending-actions .action-trigger-editor .ui.dropdown .icon {
  margin-left: 0.5em;
}
