/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.sequence-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}
.sequence-layout .sequence-layout_header {
  grid-row: 1;
}
.sequence-layout .sequence-layout_frame {
  display: flex;
  grid-row: 2;
  background: #f8faff;
  overflow: auto;
}
.sequence-layout .sequence-layout_frame .sequence-layout_content {
  padding: 32px 0;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  transition: left 250ms;
}
.sequence-layout .sequence-layout_frame .sequence-layout_content.with-side-panel {
  left: -230.5px;
}
.sequence-layout .sequence-layout_frame .sequence-layout_content.right {
  left: 0px;
}
.sequence-layout .sequence-layout_frame .sequence-layout_content .panel-loader {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
.sequence-layout .sequence-layout_frame .sequence-layout_content .panel-loader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel {
  width: 461px;
  background-color: white;
  overflow-x: hidden;
  border-right: 1px solid #edf0fa;
  transition: left 250ms;
  position: relative;
  left: -461px;
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel.active {
  left: 0px;
  transition: left 250ms;
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel .panel-content {
  height: calc(100% - 88px);
  padding: 40px 40px 24px 40px;
  overflow-y: auto;
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel .panel-action-container {
  height: 88px;
  padding: 24px 40px;
  text-align: center;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  background-color: white;
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel .panel-action-container .panel-action {
  height: 40px;
  border-radius: 8px;
  padding: 0px 16px;
  color: white;
  width: 100%;
  background: linear-gradient(90deg, #4568dc 0%, #b06ab3 100%);
  font-family: Gilroy;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel .panel-action-container .panel-action:disabled {
  opacity: 30% !important;
}
.sequence-layout .sequence-layout_frame .sequence-layout_side-panel .panel-action-container .panel-action svg {
  margin-right: 8px;
}
