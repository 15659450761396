@import 'src/less/colors';

.title {
  color: @PrimaryCobalt;
  margin-bottom: 10px;
}

.addContactButton {
  cursor: pointer;
  width: fit-content;
  margin: 12px 0 0 0;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: @PrimaryCobalt60;
  font-family: 'Gilroy', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
}

.emptyState {
  margin: 8px 0;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid @SecondaryBlue10;
  text-align: center;
  color: @PrimaryCobalt60;
}

.card {
  display: block;
  margin: 8px 0;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid @SecondaryBlue10;
  box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
  transition: border-color 150ms;

  &:hover {
    border-color: @SecondaryBlue60;
  }
}

.cardTitle {
  color: @PrimaryCobalt;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  gap: 8px;

  .divider {
    color: @PrimaryCobalt60;
  }

  > div {
    margin-top: 0px;
    margin-left: 0px;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .headline {
    color: @PrimaryCobalt80;
    font-size: 12px;
  }
}

.contactSelectorContainer {
  width: 100%;
  min-width: 100%;
  display: inline-block;
  height: inherit;
  display: flex;
  flex-direction: column;

  .description {
    color: @PrimaryCobalt80;
  }

  > div {
    &:first-of-type {
      padding: 24px 24px 0px 24px;
    }
    &:last-of-type {
      padding: 0px 24px 24px 24px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    padding: 0px 24px;
  }

  .contactSearch {
    width: 100%;
    margin-top: 24px;
    flex-shrink: 0;
  }

  .contactsContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    height: 400px;

    .emptyState {
      color: @PrimaryCobalt60;
      text-align: center;
    }

    .contact {
      padding: 12px;
      border-radius: 8px;
      box-shadow: 0px 2px 8px 0px #1f2e771a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border: 1px solid transparent;

      &.active {
        border: 1px solid @SecondaryBlue;
      }

      .contactHeader {
        display: flex;
        align-items: center;
        gap: 12px;

        .contactAvatar {
          width: 40px;
          height: 40px;
        }

        .contactName {
          font-family: Gilroy;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          color: @PrimaryCobalt;
          display: inline;
        }
      }
    }
  }
}
