/*
 * utils.less
 */
/*
 * colors.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.revealPopup {
  padding: 12px;
  border-radius: 12px !important;
  background-color: #1f2e77 !important;
  max-width: 500px;
}
.titleContainer {
  display: flex;
  width: 100%;
  align-items: center;
}
.titleContainer a {
  color: unset;
}
.titleHeader {
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  margin-right: 0px;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
}
.titleIcon {
  margin-left: 6px;
  font-size: 16px;
}
.disabled {
  color: #d2d5e4;
}
.popupContainer {
  min-width: 265px;
}
.popupTitle {
  display: flex;
}
.popupTitle:not(:last-child) {
  margin-bottom: 12px;
}
.popupTitle span {
  display: block;
}
.popupTitle span:not(.titleCount) {
  margin-left: 5px;
}
.popupTitle span.titleCount {
  font-size: 1rem;
  font-weight: bold;
  margin-left: auto;
}
.popupInfoRow {
  display: flex;
  width: 100%;
}
.popupInfoRow span {
  display: inline-block;
}
.popupInfoRow span:last-child {
  margin-left: auto;
  color: #7982ad;
}
.sequenceList > span {
  font-weight: bold;
  margin-top: 5px;
  display: block;
}
.sequenceListItem > span {
  width: 100%;
  flex-grow: 1;
  display: block;
  margin-right: 8px;
  color: #a5abc9;
}
.sequenceListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25rem;
}
.sequenceListItem :global(.sequence-container) {
  width: 100%;
  flex-grow: 1;
}
.sequenceListItem :global(.row-icon-container) {
  margin-top: 0;
}
.badgeIcon {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.badgeIcon .badge {
  position: absolute;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  width: 7px;
  height: 7px;
}
.badge.green {
  background-color: #14b62e;
}
.badge.yellow {
  background-color: #ffbd2e;
}
.atsLogo {
  border-radius: 5px;
  display: inline-block;
  margin-left: 5px;
}
.atsDisabledLogo {
  opacity: 0.4;
}
.iconLink {
  text-decoration: none !important;
  display: flex;
}
