@import 'src/less/colors';

.bubble {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 232px;
  color: @SecondaryRed40;
}

.logos {
  display: flex;
  position: absolute;
  inset: 100px 0 0 auto;
  width: 100%;
  gap: 16px;
  justify-content: center;
}
