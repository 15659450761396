/*
 * colors.less
 */
.revealAnalyticsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 40px;
  border-bottom: 1px solid #d2d5e4;
  background-color: white;
}
.revealAnalyticsHeader .navigationButtons {
  white-space: nowrap;
  margin: 0;
  padding: 4px 0;
}
.revealAnalyticsHeader .navigationButtons .navigationButton {
  display: inline-block;
  margin: 0;
  margin-right: 24px;
  padding: 0;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 22px;
  color: #a5abc9;
  cursor: pointer;
}
.revealAnalyticsHeader .navigationButtons .navigationButton:hover {
  color: #6d83d4;
}
.revealAnalyticsHeader .navigationButtons .navigationButton.selected {
  color: #1f2e77;
}
.revealAnalyticsHeader .selectionDropdown {
  display: flex;
  align-items: center;
}
.revealAnalyticsHeader .selectionDropdown .leftDropdown {
  margin-right: 12px;
}
.revealAnalyticsHeader .selectionDropdown .leftDropdown .multiDropdown {
  background: #ffffff;
  border: 1px solid #e2ebff;
  box-sizing: border-box;
  border-radius: 2px;
  min-width: 180px;
  display: flex;
}
.revealAnalyticsHeader .selectionDropdown .leftDropdown .multiDropdown :global(.label) {
  display: flex;
  white-space: nowrap;
}
.revealAnalyticsHeader .selectionDropdown .leftDropdown .multiDropdown :global(.sizer) {
  opacity: 0;
}
.revealAnalyticsHeader .selectionDropdown .leftDropdown .sequencesDropdown,
.revealAnalyticsHeader .selectionDropdown .leftDropdown .missionsDropdown {
  min-width: 200px;
  max-width: 370px;
  flex-wrap: wrap;
}
.revealAnalyticsHeader .selectionDropdown .dateRangePicker {
  flex-grow: 0;
  max-width: 128px;
  min-width: 80px;
}
.revealAnalyticsHeader .selectionDropdown .dateRangePicker.last {
  margin-right: 12px;
}
.revealAnalyticsHeader .selectionDropdown .dateRangePicker input {
  width: 100%;
}
