@import 'src/utils';
@import 'src/flex';

.sweetappContainer {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;

  overflow: hidden;

  background-color: @SecondarySkyBlue05;

  .contentContainer {
    grid-row: 2;
    overflow: auto;
  }

  .headerContainer {
    grid-row: 1;
  }

  .topHeader {
    width: 100%;
    height: @top-header-height;
    padding: 0 40px;
    background: white;
    border-bottom: 1px solid @PrimaryCobalt20;

    &.classic {
      padding-left: 0;
      padding-right: 70px;

      :global(.animated-logo-container) {
        width: 130px;
        margin: 0 22px;
      }
    }
  }

  // One client stuck on Windows 10 because no scrollbars
  &:not(:global(.win)) {
    .hide-scrollbars();
  }
}
