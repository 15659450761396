@import 'src/less/colors';

.dropdownContainer {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.dropdownContent {
  display: none;
  position: absolute;
  z-index: 1;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.left.center {
    top: 50%;
    transform: translate(50%, -50%);
  }

  &.right.center {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.open {
    display: block;
  }

  &.bottom {
    top: calc(100% + 8px);
  }

  &.top {
    bottom: calc(100% + 8px);
  }

  &.bottom.center:not(.left):not(.right),
  &.top.center:not(.left):not(.right) {
    left: 50%;
    transform: translateX(-50%);
  }
}
