@import 'src/less/colors';

.snoozeModal {
  width: 100%;
  background: #ffffff;

  &:not(.minimized) {
    position: absolute;
    top: 45px;
    left: 0;
    border: 1px solid @SecondaryBlue;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 15px;
    z-index: 10;
  }

  .title {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    &:not(last-of-type) {
      margin-bottom: 12px;
    }

    .button {
      flex: 1 1;
      min-width: fit-content;
      background: @PrimaryCobalt05;
      border: 1px solid @PrimaryCobalt05;
      border-radius: 8px;
      color: @PrimaryCobalt;
      font-size: 12px;
      font-family: Graphik, sans-serif;
      font-weight: normal;
    }

    .buttonSelected {
      background: @SecondarySkyBlue20;
      border: 1px solid @SecondaryBlue;
    }
  }

  .comment {
    margin: 10px 0 12px 0;
    width: 100%;
  }

  .datePickerWrapper {
    display: flex;
    justify-content: center;
  }
  .confirm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    gap: 10px;

    div {
      color: @PrimaryCobalt60;
      font-size: 12px;
    }
  }

  &.minimized {
    margin-top: 4px;

    .title {
      font-size: 12px;
    }

    .buttons {
      margin-top: 4px;
      gap: 4px;

      .button {
        padding: 2px;
      }
    }

    .confirm {
      margin-top: 4px;
      display: block;

      div {
        text-align: center;
        font-size: 11px;
      }

      button {
        display: block;
        margin: auto;
        font-size: 11px;
      }
    }
  }
}
