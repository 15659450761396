/*
 * colors.less
 */
.container {
  margin: 20px 0;
  background: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.container .loader {
  margin: 100px auto;
}
.container .top {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 11px 0 24px;
}
.container .top h3 {
  color: #1f2e77;
  margin: 0;
}
.container .graph {
  padding: 24px;
}
.modalTitle {
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  color: #1f2e77;
  margin-bottom: 24px;
}
