/*
 * colors.less
 */
.container {
  display: flex;
  align-items: center;
  margin: 0 12px;
  gap: 12px;
}
.container .departmentsDropdownTrigger {
  width: 250px;
}
.container .missionDropdown:global(.ui.dropdown) {
  height: 40px;
}
.container .missionDropdown:global(.ui.dropdown) :global(.default.text) {
  color: #a5abc9;
}
