@import 'src/less/colors.less';

.timeSelector {
  // HACK
  // These styles are a small hack to match the current place of this button
  // But will need to be moved later
  margin-left: 5px;
  border-color: @SecondaryBlue20 !important;
  border-radius: 2px;
  height: 20px !important;
  > svg {
    scale: 0.8;
    color: @PrimaryCobalt60;
  }
  // END HACK

  .currentTime {
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }

  .clearTime {
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background-color: @PrimaryCobalt20;
    }

    svg {
      scale: 2;
    }
  }
}
