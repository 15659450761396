/*
 * colors.less
 */
.add-action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
}
.add-action-button-container .icon:hover {
  cursor: pointer;
}
.completed-sequence-label {
  text-align: center;
  font-weight: 600;
  color: #1f2e77;
}
