@import 'src/less/colors';

.volumeByStage {
  margin: 20px 0;
  background: white;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  .loader {
    margin: 100px auto;
  }

  .top {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @SecondaryBlue20;
    padding: 0 11px 0 24px;

    h3 {
      color: @PrimaryCobalt;
      margin: 0;
    }

    .topRight {
      display: inline-flex;
      align-items: center;
      gap: 16px;
    }
  }
}
