@import 'src/less/colors';

.in-progress {
  .count {
    color: @SecondaryBlue;
  }
}

.replied {
  .count {
    color: @SecondarySkyBlue;
  }
}

.interested {
  .count {
    color: @Purple;
  }
}

.hired {
  .count {
    color: @PositiveColor120;
  }
}

.archived {
  border-left: 1px solid @SecondaryBlue20;
}
