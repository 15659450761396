@import 'src/less/colors';

.section {
  margin: 24px 0;

  .title {
    color: @PrimaryCobalt;
  }
}

.revealAnalyticsGraph {
  background: #ffffff;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  margin-top: 16px;

  .loader {
    margin: 100px auto;
  }
}
