@import 'src/less/colors.less';

.itemList {
  width: 100%;
  overflow: overlay;
  overflow-x: hidden;
  height: 100%;
  max-height: 400px;

  &:has(.selectors) {
    margin-bottom: 37px;
  }

  &.checkBoxItemList {
    .item {
      border: none;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  .group {
    border-radius: 0px;
    padding: 4px 8px 4px 16px;
    background-color: @SecondarySkyBlue05;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: @PrimaryCobalt60;
    border-top: 1px solid @SecondaryBlue20;
    border-bottom: none;
    min-height: 40px;

    display: flex;
    align-items: center;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    color: @SecondaryBlue;
    border-top: 1px solid @SecondaryBlue20;
    width: 100%;
    height: 37px;
    justify-content: space-between;

    &.selected {
      background-color: @SecondarySkyBlue10;
    }

    .itemContent {
      flex: 1;
    }

    &:first-of-type {
      border-top: none;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.itemDisabled {
      background-color: @SecondarySkyBlue05;
      color: @PrimaryCobalt40;
      cursor: default;
    }

    &.itemTitle {
      margin-right: 8px;
      line-height: 12px;
      font-size: 12px;
      font-weight: 500;
      .itemContent {
        display: flex;
        align-items: center;
        width: 100%;

        .itemCheckbox {
          margin-right: 8px;
        }

        .itemName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &:hover:not(.itemDisabled) {
      background-color: @SecondaryBlue05;
    }

    .disabled {
      background-color: @PrimaryCobalt40;
    }
  }

  .selectors {
    background-color: white;
    border-top: 1px solid @SecondaryBlue20;
    position: absolute;
    bottom: -37px;
    left: 0;
    padding: 8px 12px;
    width: 100%;

    span {
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: @SecondaryBlue;
      cursor: pointer;
      margin: 0px 4px;

      &.disabled {
        color: @SecondaryBlue20;
      }
    }
  }
}
