@import 'src/less/colors';

.missions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .missionLink {
    width: inherit;
    font-size: 12px medium;
    font-weight: 500;
    font-family: Graphik;
    color: @SecondaryBlue;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:not(:last-child) {
      :first-child {
        max-width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &::after {
        content: ',';
        margin-left: 2px;
      }
    }

    &:last-child {
      .missionTitle {
        max-width: 80%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .morePill {
    padding: 3px 8px;
    margin-left: 8px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    background-color: @PrimaryCobalt10;
    color: #7982ad;
  }
}
