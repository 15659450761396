/*
 * colors.less
 */
.sort-by-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
}
.sort-by-dropdown .sort-by-text {
  font-size: 14px;
  white-space: pre;
}
.sort-by-dropdown .text-styles {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.sort-by-dropdown .ui.dropdown .item {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.sort-by-dropdown .ui.dropdown {
  margin-left: 8px;
  min-width: 110px;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.sort-by-dropdown .ui.dropdown .text {
  font-weight: 600 !important;
  color: #4c5892;
}
.sort-by-dropdown .ui.dropdown i.dropdown.icon {
  height: 32px;
  padding: 9px;
}
