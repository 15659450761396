.bar {
  animation: spin 1.2s linear 0s infinite normal;
  transform-origin: 50% 50%;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
