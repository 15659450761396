.columnSelectorHeader {
  display: flex;
  max-width: 40px;
  padding-right: 1rem;

  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.customFieldHeader {
  display: flex;
  align-items: center;
  flex: 1 0;
}
