.separator {
  position: absolute;
  z-index: 2;
  background-color: white;
  inset: 0;
}
.area {
  position: absolute;
  z-index: 1;
  inset: 0;
}
