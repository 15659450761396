/*
 * colors.less
 */
.card {
  margin-top: 12px;
  padding: 16px;
  padding-bottom: 0;
  background-color: #f1f6ff;
  border: 1px solid #e2ebff;
  border-radius: 4px;
}
