/*
 * main.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Extrabold.otf);
  font-family: Gilroy;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Bold.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Semibold.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Regular.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-BoldItalic.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-SemiboldItalic.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-RegularItalic.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Bold.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Semibold.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Medium.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Regular.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-BoldItalic.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-SemiboldItalic.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-MediumItalic.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-RegularItalic.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Regular.ttf);
  font-family: 'Lexend Exa';
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Bold.ttf);
  font-family: 'Lexend Exa';
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'TaskIcons';
  src: url('/fonts/tasks/TaskIcons.eot?akl2n2');
  src: url('/fonts/tasks/TaskIcons.eot?akl2n2#iefix') format('embedded-opentype'), url('/fonts/tasks/TaskIcons.ttf?akl2n2') format('truetype'), url('/fonts/tasks/TaskIcons.woff?akl2n2') format('woff'), url('/fonts/tasks/TaskIcons.svg?akl2n2#TaskIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='custom-icon-'],
[class*=' custom-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'TaskIcons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-icon-sms-task:before {
  content: '\e900';
}
.custom-icon-manual-task:before {
  content: '\e901';
}
/*
 * typography.less
 */
body {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}
body .ui.input > input {
  font-family: Graphik, sans-serif !important;
}
input,
optgroup,
select,
textarea {
  font-family: Graphik, sans-serif;
}
h1,
.headline-1 {
  font-family: Gilroy, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}
h2,
.headline-2 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}
h3,
.headline-3 {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
h4,
.headline-4 {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
h5,
.headline-5 {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}
.body-semibold {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.body-medium {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.body-regular {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.caption-medium {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}
.caption {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1px;
}
.caption-2 {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
body {
  width: 100%;
}
body .ui.input > input:focus {
  border-color: #4864c9;
}
.row,
.wide.column,
.ui-grid > * {
  padding: 0 !important;
}
.button-warning,
.ui.button.button-warning {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #f86e7f;
  font-family: Graphik;
  color: #f86e7f;
  background-color: #ffffff;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  line-height: 20px;
  padding: 5px 15px;
  border-radius: 4px !important;
}
.button-warning:hover,
.ui.button.button-warning:hover {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #ffffff;
  font-family: Graphik;
  color: #ffffff;
  background-color: #f86e7f;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
}
.button-default,
.ui.button.button-default {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #4864c9;
  font-family: Graphik;
  color: #4864c9;
  background-color: #ffffff;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  line-height: 20px;
  padding: 5px 15px;
  border-radius: 4px !important;
}
.button-default:hover,
.ui.button.button-default:hover {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #ffffff;
  font-family: Graphik;
  color: #ffffff;
  background-color: #4864c9;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
}
.primary-cta,
.ui.button.primary-cta,
a.primary-cta {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  color: white;
  background-color: #4864c9;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  transition: 150ms;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.primary-cta:active:not(:disabled),
.ui.button.primary-cta:active:not(:disabled),
a.primary-cta:active:not(:disabled) {
  background: #4864c9;
}
.primary-cta img,
.ui.button.primary-cta img,
a.primary-cta img {
  margin-right: 8px;
  height: 13px;
}
.primary-cta i.ri-add-line,
.ui.button.primary-cta i.ri-add-line,
a.primary-cta i.ri-add-line {
  font-size: 16px;
  font-weight: 600;
  margin-right: 6px;
}
.primary-cta:disabled,
.ui.button.primary-cta:disabled,
a.primary-cta:disabled {
  opacity: 0.6;
  cursor: default;
}
.primary-cta:hover,
.ui.button.primary-cta:hover,
a.primary-cta:hover {
  background-color: #3450b5;
}
.modal-actions-buttons {
  text-align: right;
}
.modal-actions-buttons button:last-child {
  margin-left: 5px;
}
.modal-actions-buttons .flex-spaced-between {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.confirm-button,
.ui.button.confirm-button,
a.confirm-button {
  min-width: 100px;
}
.confirm-button.primary,
.ui.button.confirm-button.primary,
a.confirm-button.primary {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #4864c9;
  color: white;
}
.confirm-button.primary:hover,
.ui.button.confirm-button.primary:hover,
a.confirm-button.primary:hover,
.confirm-button.primary:focus,
.ui.button.confirm-button.primary:focus,
a.confirm-button.primary:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #4864c9;
  color: white;
}
.confirm-button.primary:active,
.ui.button.confirm-button.primary:active,
a.confirm-button.primary:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #1f2e77;
}
.confirm-button.secondary,
.ui.button.confirm-button.secondary,
a.confirm-button.secondary {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #f86e7f;
  color: white;
}
.confirm-button.secondary:hover,
.ui.button.confirm-button.secondary:hover,
a.confirm-button.secondary:hover,
.confirm-button.secondary:focus,
.ui.button.confirm-button.secondary:focus,
a.confirm-button.secondary:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #f86e7f;
  color: white;
}
.confirm-button.secondary:active,
.ui.button.confirm-button.secondary:active,
a.confirm-button.secondary:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
  background-color: #f9c8ca;
}
.dismiss-button,
.ui.button.dismiss-button,
a.dismiss-button {
  min-width: 100px;
}
.dismiss-button.primary,
.ui.button.dismiss-button.primary,
a.dismiss-button.primary {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4864c9;
  border: 1px solid #4864c9;
}
.dismiss-button.primary:hover,
.ui.button.dismiss-button.primary:hover,
a.dismiss-button.primary:hover,
.dismiss-button.primary:focus,
.ui.button.dismiss-button.primary:focus,
a.dismiss-button.primary:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4864c9;
  border: 1px solid #4864c9;
}
.dismiss-button.primary:active,
.ui.button.dismiss-button.primary:active,
a.dismiss-button.primary:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #1f2e77;
  border: 1px solid #1f2e77;
}
.dismiss-button.secondary,
.ui.button.dismiss-button.secondary,
a.dismiss-button.secondary {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f86e7f;
  border: 1px solid #f86e7f;
}
.dismiss-button.secondary:hover,
.ui.button.dismiss-button.secondary:hover,
a.dismiss-button.secondary:hover,
.dismiss-button.secondary:focus,
.ui.button.dismiss-button.secondary:focus,
a.dismiss-button.secondary:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f86e7f;
  border: 1px solid #f86e7f;
}
.dismiss-button.secondary:active,
.ui.button.dismiss-button.secondary:active,
a.dismiss-button.secondary:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #f9c8ca;
  border: 1px solid #f9c8ca;
}
.hs-default-button,
.hs-default-button.ui.button,
a.hs-default-button,
.default-button,
.ui.button.default-button,
a.default-button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4c5892;
  border: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  align-items: center;
}
.hs-default-button:hover,
.hs-default-button.ui.button:hover,
a.hs-default-button:hover,
.default-button:hover,
.ui.button.default-button:hover,
a.default-button:hover,
.hs-default-button:focus,
.hs-default-button.ui.button:focus,
a.hs-default-button:focus,
.default-button:focus,
.ui.button.default-button:focus,
a.default-button:focus {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4c5892;
  border: 1px solid rgba(34, 36, 38, 0.35);
}
.hs-default-button:active,
.hs-default-button.ui.button:active,
a.hs-default-button:active,
.default-button:active,
.ui.button.default-button:active,
a.default-button:active {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: white;
  color: #4c5892;
  border: 1px solid #4c5892;
}
.hs-default-dropdown.ui.dropdown {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.hs-default-dropdown.ui.dropdown .dropdown.icon {
  height: 32px;
  padding: 9px;
}
.ui.dropdown .selected.item {
  background-color: unset !important;
  color: #4c5892 !important;
}
.ui.dropdown .selected.item:hover {
  background-color: #edf0fa !important;
}
.link-button,
.ui.button.link-button {
  font-family: Gilroy;
  color: #4c5892;
  font-size: 12px;
  text-decoration: underline;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
}
.primary-link-button {
  background-color: transparent;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  outline: none;
}
.primary-link-button:focus {
  text-decoration: underline;
}
.primary-link-button:hover {
  cursor: pointer;
  text-decoration: underline;
}
.ui.button.tertiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 12px 5px 12px;
  font-family: Graphik, sans-serif;
  color: #7982ad;
  border-radius: 2px;
  border: 1px solid #d2d5e4;
  background-color: transparent;
}
.ui.button.tertiary:hover {
  border-color: #a5abc9;
  background-color: transparent;
}
.ui.button.tertiary:focus {
  color: #4c5892;
  border-color: #4c5892;
  background-color: transparent;
}
.ui.button.tertiary.negative {
  color: #ff6059;
  border-color: #f2938e;
}
.ui.button.tertiary.negative:hover {
  color: #ff6059;
  border-color: #ff6059;
}
.ui.button.tertiary.negative:focus {
  color: #ff3930;
  border-color: #ff3930;
}
.ui.button.tertiary i {
  font-size: 16px;
  margin-right: 8px;
}
.ui.dropdown .active.selected.item {
  font-weight: 600 !important;
}
.ui.form input:focus {
  border-color: #4864c9 !important;
}
.ui.dimmer .ui.loader.on-dimmer:before {
  border-color: rgba(0, 0, 0, 0.1);
}
.ui.dimmer .ui.loader.on-dimmer:after {
  border-color: #767676 transparent transparent;
}
.no-items-container {
  height: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.no-items-container .no-items {
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #1f2e77;
  margin-top: 2em;
}
.code-text {
  color: #f8f8f8;
  background-color: #505050;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
}
.hs-dropdown-menu,
.ui.dropdown .menu.hs-dropdown-menu {
  border-radius: 2px;
  width: 264px;
}
.hs-dropdown-menu .dropdown-item,
.ui.dropdown .menu.hs-dropdown-menu .dropdown-item,
.hs-dropdown-menu .item.dropdown-item,
.ui.dropdown .menu.hs-dropdown-menu .item.dropdown-item {
  padding: 10px 18px !important;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #7982ad;
}
.hs-dropdown-menu .dropdown-item.first,
.ui.dropdown .menu.hs-dropdown-menu .dropdown-item.first,
.hs-dropdown-menu .item.dropdown-item.first,
.ui.dropdown .menu.hs-dropdown-menu .item.dropdown-item.first {
  padding-top: 18px !important;
}
.hs-dropdown-menu .dropdown-item.last,
.ui.dropdown .menu.hs-dropdown-menu .dropdown-item.last,
.hs-dropdown-menu .item.dropdown-item.last,
.ui.dropdown .menu.hs-dropdown-menu .item.dropdown-item.last {
  padding-bottom: 18px !important;
}
.hs-dropdown-menu .dropdown-item:hover,
.ui.dropdown .menu.hs-dropdown-menu .dropdown-item:hover,
.hs-dropdown-menu .item.dropdown-item:hover,
.ui.dropdown .menu.hs-dropdown-menu .item.dropdown-item:hover {
  color: #1f2e77;
  background-color: #edf0fa;
  z-index: 13;
}
.hs-dropdown-menu .divider,
.ui.dropdown .menu.hs-dropdown-menu .divider {
  border-top: 1px solid #dae0f4;
  height: 0;
  margin: 0;
}
.hs-dropdown-disabled {
  pointer-events: none;
}
.hs-dropdown-disabled i.icon {
  display: none;
}
.hs-item-content {
  vertical-align: text-bottom;
  display: flex;
  justify-content: space-between;
}
.hs-item-content i ::before {
  vertical-align: middle;
}
.hs-item-content i.ri-pencil-line {
  font-weight: 400;
}
.hs-item-content i.ri-add-line {
  font-weight: 400;
  font-size: 16px;
}
.hs-large-dropdown .menu,
.ui.dropdown.hs-large-dropdown .menu {
  top: 30px;
  min-width: 250px;
}
.hs-large-dropdown .item.large-item,
.ui.dropdown.hs-large-dropdown .item.large-item {
  color: #4c5892;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}
.hs-contextual-menu.ui.dropdown > .text {
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #7982ad;
}
.hs-contextual-menu.ui.dropdown .menu > .item {
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #7982ad;
  display: flex;
  align-items: center;
}
.hs-contextual-menu.ui.dropdown .menu > .item:hover {
  color: #1f2e77;
}
.hs-contextual-menu.ui.dropdown .menu > .item .menu-icon {
  color: #709dff;
  margin-right: 10px;
}
.hs-tertiary-button,
.ui.button.hs-tertiary-button {
  display: flex;
  height: 28px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 8px;
  background-color: transparent;
  color: #4c5892;
  border: 1px solid #4c5892;
}
.hs-tertiary-button:hover,
.ui.button.hs-tertiary-button:hover {
  background-color: #e9ebf2;
}
.hs-tertiary-button i,
.ui.button.hs-tertiary-button i {
  margin-right: 8px;
}
.hs-tertiary-button:not(:last-child),
.ui.button.hs-tertiary-button:not(:last-child) {
  margin-right: 8px;
}
.hs-dropdown-ellipsis > div.text,
.hs-dropdown-ellipsis.ui.dropdown > div.text {
  vertical-align: top;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hs-button-group {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.hs-button-group .button {
  border-radius: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f3f4f8;
  border: solid 1px #f3f4f8;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  color: #7982ad;
}
.hs-button-group .button.selected {
  background-color: #f1f6ff;
  color: #709dff;
  font-weight: 500;
  border: solid 1px #709dff;
}
.hs-button-group .button:focus {
  background-color: #f1f6ff;
}
.hs-flex-spacer {
  flex: 1;
}
.hs-dropdown-menu-ellipsis .menu > .item,
.hs-dropdown-menu-ellipsis.ui.dropdown .menu > .item,
.hs-dropdown-menu-ellipsis.ui.dropdown.selection .menu > .item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.input-container {
  margin-bottom: 24px;
}
.input-container.input-container-horizontal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.input-container.input-container-horizontal .input-label {
  flex: 0 0 120px;
  width: 120px;
  min-height: 40px;
  line-height: 145%;
  margin-right: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.input-container.input-container-horizontal .input-container-wrapper {
  flex: 3 1 100%;
  max-width: 291px;
}
.input-container.input-container-horizontal .input-error {
  margin-top: 5px;
}
.input-container .control-label-supplement {
  color: #7982ad;
  font-weight: normal;
}
.input-container:last-child {
  margin-bottom: 0px;
}
.input-container .input-label,
.input-container label {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
.input-container .input-label .no-emphasis,
.input-container label .no-emphasis {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}
.input-container .preview-label {
  color: #7982ad;
  font-size: 12px;
}
.input-container .input-description {
  font-size: 12px;
  color: #a5abc9;
  margin-bottom: 8px;
}
.input-container .input-description:last-child {
  margin-bottom: 0;
}
.input-container .input-error {
  font-size: 12px;
  color: #ff6059;
}
.input-container .input-error input {
  border-color: #ff6059;
}
.input-container .input-element.input-and-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.input-container .input-element.input-and-button div:first-child {
  flex-grow: 1;
}
.input-container .input-element.input-and-button .buttons-container {
  display: flex;
  margin-left: 10px;
}
.input-container .input-element.half-width {
  width: 50%;
}
.input-container .input-element .field.signature-field .content-edition-field {
  border: 1px solid #dae0f4;
  padding: 0.39285714em 1em;
  border-radius: 2px;
  font-size: 1em;
  box-shadow: none;
  outline: none;
  min-height: 80px;
  overflow: auto;
}
.input-container .input-element .field.signature-field .content-edition-field:focus {
  border-color: #4864c9;
}
.input-container .accordion .content {
  padding-bottom: 0px;
}
.input-container .accordion .title:not(.active) {
  padding-bottom: 0px;
}
.inputs-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.inputs-row.align-right {
  justify-content: flex-end;
}
.inputs-row.align-right .input-container.half-width:first-child:last-child {
  padding-right: 0px;
  padding-left: 12px;
}
.inputs-row:last-child {
  margin-bottom: 0px;
}
.inputs-row .input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  padding-right: 12px;
  padding-left: 12px;
}
.inputs-row .input-container.half-width {
  width: 50%;
}
.inputs-row .input-container:first-child {
  padding-left: 0px;
}
.inputs-row .input-container:last-child {
  padding-right: 0px;
}
.inputs-row .input-container.half-width:first-child:last-child {
  padding-right: 12px;
}
.hs-spacer {
  display: flex;
  flex-grow: 1;
}
.hs-accordion.ui.accordion .title .dropdown.icon {
  width: 0.8em;
  vertical-align: middle;
}
.hs-accordion.ui.accordion .active.title .dropdown.icon {
  transform: rotate(-180deg) translate(0, 2px);
}
.hs-container-button,
.hs-container-button.ui.button {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  min-height: 0;
}
.hs-dropdown-text-with-image.ui.dropdown .menu .item img,
.hs-dropdown-text-with-image .ui.dropdown .menu .item img {
  vertical-align: baseline;
}
.hs-dropdown-text-with-image.ui.dropdown .text-with-image img,
.hs-dropdown-text-with-image .ui.dropdown .text-with-image img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  margin-right: 8px;
  max-height: 2em;
}
.hs-hidden {
  display: none !important;
}
.ui.label.hiresweet-label {
  margin: 2px 3px 2px 0px;
  background: #f3f4f8;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #7982ad;
}
.clipboard-active-target-command {
  border: 1px solid blue !important;
}
.position-relative {
  position: relative;
}
.hiresweet-rounded {
  border-radius: 8px !important;
}
.ui.checkbox.toggle.hiresweet-small-toggle input,
.ui.checkbox.toggle.hiresweet-small-toggle label::before {
  width: 34px !important;
  height: 18px !important;
}
.ui.checkbox.toggle.hiresweet-small-toggle label::after {
  width: 14px !important;
  height: 14px !important;
  border: none !important;
  background: white !important;
  box-shadow: none !important;
  margin: 2px;
  left: 0px;
}
.ui.checkbox.toggle.hiresweet-small-toggle label {
  padding-left: 38px;
  color: #7982ad;
}
.ui.checkbox.toggle.hiresweet-small-toggle.checked label {
  color: #4c5892 !important;
}
.ui.checkbox.toggle.hiresweet-small-toggle.checked label::after {
  left: 16px !important;
}
/*
 * colors.less
 */
.notifications-dropdown .ri-notification-4-line {
  font-size: 17px;
  color: #7982ad;
}
.notifications-dropdown .notifications-count-bubble {
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  background-color: #e45a6b;
  font-family: Graphik, sans-serif;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  position: absolute;
  top: -2px;
  left: 8px;
}
.notifications-dropdown .notifications-dropdown-container {
  border-radius: 4px;
  width: 440px;
  padding: 0 10px;
}
.notifications-dropdown .notifications-dropdown-container.no-items-container {
  height: 250px;
}
.notifications-dropdown .alert {
  margin: 1em auto;
  text-align: center;
  opacity: 0.7;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications-dropdown .alert .icon {
  margin: auto 0.5em;
}
.notifications-dropdown .show-more {
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  display: flex;
  height: 40px;
}
.notifications-dropdown.ui.dropdown .menu {
  top: 23px;
  max-height: 80vh;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding: 10px 0;
}
.notifications-dropdown.ui.dropdown .menu > .menu-group > .header {
  opacity: 0.5;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  padding: 5px 0px;
  white-space: nowrap;
  padding-left: 15px;
}
.notifications-dropdown.ui.dropdown .menu .item:not(.show-more) {
  transition: height 0.2s ease;
  height: 64px;
  box-sizing: border-box;
}
.notifications-dropdown.ui.dropdown .menu .item:not(.show-more).animate-exit,
.notifications-dropdown.ui.dropdown .menu .item:not(.show-more).animate-enter {
  height: 0;
}
.notifications-dropdown.ui.dropdown .menu .item:not(.show-more).animate-enter.animate-enter-active {
  height: 64px;
}
.notifications-dropdown.ui.dropdown .menu .item:not(.show-more).animate-enter {
  overflow: hidden;
}
.notifications-dropdown .floating.label {
  color: white;
  background-color: #f86e7f;
}
.notification-card {
  border-radius: 0;
  width: 100%;
  height: 64px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px 10px;
  border: 1px solid transparent;
  margin: 5px 0;
  transition: border-color 0.2s ease;
}
.notification-card.seen {
  background-color: #f7f7f7;
}
.notification-card.not-seen {
  background: #f8f8f8;
}
.notification-card:hover {
  border-color: #4864c9;
}
.notification-card .notification-bubble {
  margin-right: 10px;
  box-sizing: content-box;
  width: 40px;
  height: 40px;
}
.notification-card .content .name {
  height: 22px;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  color: #1d1e21;
  line-height: 22px;
}
.notification-card .content .description {
  height: 22px;
  opacity: 0.5;
  font-size: 14px;
  color: #1d1e21;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: hidden;
}
