/*
 * colors.less
 */
.pluginMockup {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 67%;
  box-shadow: 0 8px 32px -2px #1f2e7726;
}
