@import 'src/less/colors';

.panel {
  padding: 12px;
  background-color: @SecondarySkyBlue05;
  border-radius: inherit;
}

.button {
  width: 100%;
  margin-top: 8px;
}
