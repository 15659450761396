/*
 * colors.less
 */
.profile-missions-management .ui.segment {
  font-family: Graphik;
}
.profile-missions-management .ui.segment.mission-item {
  padding: 12px;
}
.profile-missions-management .ui.segment .mission-item-main-content {
  display: flex;
  align-items: center;
  gap: 12px;
}
.profile-missions-management .ui.segment .mission-item-main-content .mission-title-container {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}
.profile-missions-management .ui.segment .mission-item-main-content .mission-title {
  color: #1f2e77;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 4px;
}
.profile-missions-management .ui.segment .mission-item-main-content .mission-pipeline-dropdown {
  font-family: Graphik;
  font-weight: 500;
  background-color: #e2ebff;
  color: #3450b5;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  line-height: 20px;
}
.profile-missions-management .ui.segment .mission-item-main-content .mission-pipeline-dropdown i {
  font-size: 12px !important;
  padding-left: 10px !important;
}
.profile-missions-management .ui.segment .mission-item-main-content .mission-remove-button {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0 0 0 16px;
  color: #3450b5 !important;
}
.profile-missions-management .ui.segment .mission-item-main-content .mission-remove-button:hover {
  color: #ff3930 !important;
}
.profile-missions-management .ui.segment .mission-comment {
  min-width: 0;
  font-size: 12px;
  width: fit-content;
  color: #7982ad;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.profile-missions-management .ui.segment .mission-comment:is(textarea) {
  flex-grow: 1;
  cursor: text;
  padding: 4px;
  border: 1px solid #e2ebff;
  border-radius: 8px;
  resize: none;
  outline: none;
}
.profile-missions-management .ui.segment .mission-comment-container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
}
.profile-missions-management .ui.segment .mission-comment-container .edit-mission-comment {
  display: flex;
  cursor: pointer;
  min-width: 0;
  align-items: center;
}
.profile-missions-management .ui.segment .mission-comment-container svg {
  margin-left: 4px;
  font-size: 12px;
  color: #7982ad;
}
.profile-missions-management .ui.segment.mission-dropdown {
  padding: 0px;
}
.profile-missions-management .ui.segment.mission-dropdown .button-dropdown {
  color: #a5abc9 !important;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  border: none;
  padding-left: 38px !important;
  box-shadow: none;
}
.profile-missions-management .ui.segment.mission-dropdown .button-dropdown.simplified {
  padding-left: 28px !important;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik, sans-serif;
}
.profile-missions-management .ui.segment.mission-dropdown .button-dropdown:hover {
  color: #4c5892 !important;
}
.profile-missions-management .ui.segment.mission-dropdown .button-dropdown .ri-add-line {
  position: absolute;
  left: 12px;
}
