@import 'src/less/colors.less';

.container {
  display: flex;
  align-items: center;
}
.container > div {
  display: inline-block;
}

.container > div.icon {
  border: 1px solid @SecondaryBlue20;
  border-radius: 5px;
  padding: 3px;
  margin-right: 1rem;
  max-width: 32px;
  max-height: 32px;
}
