/*
 * colors.less
 */
.sequencePopupItem {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #4864c9;
  border-top: 1px solid #dae0f4;
  width: 100%;
  justify-content: space-between;
}
.sequencePopupItem:first-of-type {
  border-top: none;
}
.sequencePopupItem:hover {
  background-color: #f6f7fc;
}
.sequencePopupItem :global(.sequence-container) {
  flex-grow: 1;
}
.sequencePopupItem :global(.sequence-container) :global(.row-icon-container) {
  margin-top: 0;
}
.sequencePopupItem .sequenceTitle {
  margin-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sequencePopupItem .pluginSequenceTitle {
  margin-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sequencePopupItem :global(.row-sequence-container) {
  display: flex;
  justify-content: flex-end;
}
.sequencePopupItem :global(.row-sequence-container) :global(.more-actions.hidden) {
  display: none;
}
