@import 'src/less/colors';

.summary {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.title {
  font-size: 12px;
  color: @PrimaryCobalt60;
}
