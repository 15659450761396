@import 'src/less/colors';

.select {
  min-width: 150px;
}

.picker {
  padding: 0 10px;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
  background-color: white;
  min-height: 38px;
  color: @PrimaryCobalt;
  outline: none;

  &:hover {
    border: 1px solid @SecondarySkyBlue40;
    box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06,
      inset 0px 0px 0px 1px @SecondarySkyBlue40;
  }
}
