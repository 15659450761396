/*
 * colors.less
 */
.background-item-sub-card {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
}
.background-item-sub-card:first-child {
  margin-top: 12px;
}
.background-item-sub-card:not(:last-child) .sub-card-bullet-container {
  position: relative;
}
.background-item-sub-card:not(:last-child) .sub-card-bullet-container:after {
  border-left: 2px solid #f3f4f8;
  content: '';
  position: absolute;
  top: 15px;
  left: 19px;
  bottom: -37px;
  transform: translateX(-50%);
}
.background-item-sub-card .sub-card-bullet {
  margin-top: 5px;
  margin-left: 14px;
  margin-right: 28px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #f66f81;
}
.background-item-sub-card .sub-card-content {
  flex-grow: 1;
}
.background-item-sub-card .card-title {
  margin-bottom: 4px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1f2e77;
}
.background-item-sub-card .card-subtitle {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  color: #7982ad;
}
.background-item-sub-card .card-content {
  margin-top: 8px;
  font-size: 12px;
  line-height: 17px;
  color: #4c5892;
}
