@import 'src/less/colors.less';

.panel {
  min-width: 100%;
  overflow: hidden;
}

.selectItemDropdownContent {
  min-width: 100%;
}

.searchBar {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  gap: 8px;
  border-bottom: 2px solid @SecondarySkyBlue40 !important;

  i {
    color: @PrimaryCobalt60;
  }
  :global(.ui.input) {
    flex-grow: 1;
    input {
      border: none !important;
      padding: 0px;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
