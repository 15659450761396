@import 'src/less/colors';

.column {
  width: 160px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 16px;
  border-radius: 8px;

  cursor: default;

  background-color: @SecondaryBlue05;

  &.double {
    width: 332px;
  }

  .explanation {
    font-family: Graphik;
    color: @PrimaryCobalt60;
    font-weight: 500;
    text-align: center;
    font-size: 12px;
  }

  .cta {
    text-align: center;
  }
}

@media (max-width: 1400px) {
  .column {
    display: inline-block;
    padding: 16px;
    height: auto;

    &.double {
      width: 212px;
    }

    .explanation {
      margin-bottom: 8px;
    }
  }
}
