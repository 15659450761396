/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.connectPagesList {
  margin-top: 24px;
  margin-bottom: 142px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #dae0f4;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) {
  border: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) thead th,
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody td {
  padding: 16px 10px 16px 0;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody tr td {
  text-align: left;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) thead,
.connectPagesList .connectPagesSettingsTable:global(.table.ui) thead tr,
.connectPagesList .connectPagesSettingsTable:global(.table.ui) thead tr th {
  border: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody,
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody tr {
  border: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody td {
  border-top: 1px solid #f3f4f8;
  border-bottom: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody td:first-child {
  border-left: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody td:last-child {
  border-right: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody tr:last-child td {
  border-bottom: none;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) thead th {
  font-family: Graphik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #a5abc9;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders {
  border-top: 1px solid #e8e8e8;
  color: red;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders tr {
  background-color: #f8faff !important;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders tr th {
  padding-left: 24px;
  padding-right: 24px;
  color: #7982ad;
  font-weight: 500;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders .button-header .header-name {
  padding-top: 2px;
  display: inline-block;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders .button-header:hover .header-name {
  text-decoration: underline;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders .button-header.sort .icon-container {
  vertical-align: top;
  display: inline-block;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders .button-header.sort.increasing .icon-container {
  padding-top: 1px;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableHeaders .button-header.sort.decreasing .icon-container {
  padding-top: 2px;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow .tableCell {
  padding-left: 24px;
  padding-right: 24px;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow .actionTableCell {
  display: flex;
  justify-content: right;
  align-items: center;
  visibility: visible;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow .actionTableCell .campaign-more-action-btn {
  padding: 5px 0px;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 2px solid transparent;
  border-radius: 25%;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow .actionTableCell .campaign-more-action-btn:hover {
  border: 2px solid #b6c1e9;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow .actionTableCell .placeholder-button {
  visibility: visible;
  width: 84px;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow .actionTableCell .externalLink {
  text-decoration: none;
  margin-left: 4px;
  color: #4c5892;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .campaign-header {
  display: flex;
  align-items: center;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .campaign-header.in-folder {
  margin-left: 30px;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .campaign-header-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .campaign-header-title:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #4864c9;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow {
  cursor: pointer;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow:hover,
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow.active {
  background-color: #f8faff !important;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow:hover .actionTableCell,
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow.active .actionTableCell {
  visibility: visible;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow td {
  white-space: nowrap;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .tableRow td:first-child {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .campaign-icon {
  margin-right: 10px;
  color: #a5abc9;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .actionTableCell {
  text-align: center !important;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .actionTableCell :first-child {
  cursor: pointer;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .contextMenu .contextBtnContainer {
  justify-content: center !important;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) div.box-title {
  margin-top: 10px;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) div.box-title:first-of-type {
  margin-top: 0;
}
.connectPagesList .connectPagesSettingsTable:global(.table.ui) .connectPagesTableRowActions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  cursor: pointer;
}
.actionTableCell {
  text-align: center !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  min-width: fit-content !important;
  gap: 4px;
}
.actionTableCell :first-child {
  cursor: pointer;
}
.actionTableCell > div {
  display: flex;
  justify-content: right;
  align-items: center;
  visibility: visible;
}
.actionTableCell > div:last-of-type i {
  padding: 5px 0px;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  border-radius: 25%;
  color: #7982ad;
}
.actionTableCell > div:last-of-type i:hover {
  border: 1px solid #dae0f4;
  background: #f6f7fc;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
}
