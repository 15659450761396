@import 'src/less/colors';


.previewContainer {
  max-height: 50vh;
  overflow-y: auto;

  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
}

.preview {
  max-height: 300px;
  overflow-y: auto;
}

.previewHeader {
  padding: 16px;
  border-bottom: 1px solid @SecondaryBlue20;
}


.previewHeaderTitle {
  display: flex;
  gap: 8px;
}

.mainDescriptionForComplexeCase {
  color: @PrimaryCobalt;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
}

.inputContainerComplexCase {
  
  margin-bottom: 30px;

  .inputLabel{
    margin-top: 25px;
    color: @PrimaryCobalt;
    font-family: Graphik;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .inputDescription {
    color: @PrimaryCobalt60;
    font-family: Graphik;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;

  }
}

.preview {
  padding: 16px;
  display: grid;
  gap: 10px 0px;

  //grid-template-columns: minmax(auto, max-content) auto;
  grid-template-columns: 400px auto;

  :global(.name-and-headline-container) {
    display: flex;
    align-items: center;
    color: @SecondaryBlue;
    padding-right: 20px;
  }

  :global(.full-name) {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.errorPreview {
  grid-template-columns: minmax(auto, max-content) minmax(auto, max-content) auto;
  .cell {
    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.error {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  flex-grow: 1;
  color: @NegativeColor;
  padding-left: 20px;
}

.cell {
  padding-top: 5px;
  padding-bottom: 5px;
  //border-bottom: 1px solid #dae0f4;
  &:nth-last-child(-n+2) {
    border-bottom: none;
  }
}

.sequenceCellContainer {
  :global(.sequence-title) {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: @SecondaryBlue;
  }

  // Overrides for sequence icons
  :global(.row-sequence-container) {
    display: flex;
    justify-content: flex-end;

    :global(.more-actions.hidden) {
      display: none;
    }
  }
}

.sequenceRow {
  width: unset !important;
  justify-content: flex-end;
}