/*
 * colors.less
 */
.mainTitle {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
}
.content {
  padding: 24px;
}
.reasonsTable {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  border: none !important;
}
.reasonsTable thead th {
  color: #7982ad !important;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 500 !important;
  line-height: 13px;
  letter-spacing: 0px;
  background-color: transparent !important;
  border-bottom: 1px solid #dae0f4;
  padding-left: 0 !important;
}
.reasonsTable thead th .actionColumn {
  min-width: 20px;
}
.reasonsTable tbody td {
  border: none !important;
  padding: 0 !important;
  height: 56px !important;
  margin: 4px 0px !important;
}
.reasonName {
  color: #1f2e77;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.reasonCategory {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.reasonTags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.reasonActions {
  text-align: right !important;
}
.reasonActions i {
  margin: 0px 8px;
  color: #7982ad;
}
.tag {
  display: inline-block;
  margin-right: 4px;
  border-radius: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
  font-size: 12px;
  background-color: #e2ebff;
  color: #5c89eb;
}
.modalActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
