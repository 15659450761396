/*
 * colors.less
 */
.languageDropdown {
  margin-bottom: 24px;
  width: 100%;
}
.sequenceDropdown:global(.ui.dropdown) {
  border-radius: 8px;
}
.sequenceDropdown:global(.ui.dropdown) :global(.divider.text) {
  width: 100%;
}
.formGroup {
  margin-bottom: 24px;
}
.formTitle {
  margin-bottom: 4px;
  color: #1f2e77;
  font-weight: 600;
  line-height: 19.7px;
}
.formInput {
  width: 100%;
}
.textAreaContainer,
.sequenceStructureContainer {
  margin: 0px 0px 24px;
}
.textAreaContainer textarea,
.sequenceStructureContainer textarea {
  width: 100%;
  color: #1f2e77;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #dae0f4;
  resize: none;
  font-family: Graphik;
  font-size: 13px;
}
.tooltipTrigger {
  background-color: #1f2e77;
  color: white;
  font-weight: 500;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.toneContainer {
  margin-bottom: 24px;
}
.toneContainer .tones {
  display: flex;
  gap: 4px;
}
.toneContainer .tones .tone {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 6px;
  flex-grow: 1;
  text-align: center;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.toneContainer .tones .tone:hover {
  border-color: #91a2df;
  background-color: #f1f6ff;
}
.toneContainer .tones .tone.selected {
  border-color: #4864c9;
  background-color: #f1f6ff;
}
.loaderButton {
  padding: 10px;
  position: relative;
}
.generateAndConfirm {
  display: flex;
  align-items: center;
}
.generateAndConfirm button {
  margin-left: 4px;
}
.generateModalTrigger {
  margin-top: 12px;
  float: right;
}
.formWithPreview {
  display: flex;
  gap: 12px;
  height: 600px;
  overflow: hidden;
}
.formWithPreview .formContainer {
  flex: 1 1;
  height: 100%;
  overflow: auto;
  padding: 0px 12px;
}
.formWithPreview .generatedContent {
  flex: 1 1;
  height: 100%;
  overflow: auto;
  padding: 0px 12px;
}
.formWithPreview .generatedContent h4 {
  text-align: center;
}
