/*
 * colors.less
 */
.modal {
  width: 454px !important;
  padding: 64px 40px;
}
.title {
  font-family: Gilroy;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #1f2e77;
}
.paragraph {
  margin: 32px 0;
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.cta {
  width: 100%;
}
.cta:not(:last-child) {
  margin-bottom: 12px;
}
