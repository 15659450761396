/*
 * colors.less
 */
.dropdownTrigger {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
.dropdownTrigger:not(:disabled) {
  cursor: pointer;
}
.title {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.arrow {
  font-family: Gilroy;
  font-size: 16px;
  color: #1f2e77;
}
