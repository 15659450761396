.rich-editor.small {
  position: relative;
}
.rich-editor .editor-input-container {
  padding: 11px 14px;
  margin-bottom: 6px;
  min-height: 100px;
}
.rich-editor .public-DraftEditor-content {
  min-height: 100px;
}
.rich-editor-controls.disabled {
  pointer-events: none;
}
.rich-editor-controls.small .editor-container {
  padding-bottom: 31px;
}
.rich-editor-controls.small .controls-container {
  min-height: 30px;
  margin-top: 4px;
  position: absolute;
  width: calc(100% - 2px) !important;
  bottom: 1px;
  border: none;
  left: 1px;
  padding: 0px 5px;
  gap: 14px;
}
.rich-editor-controls.small .controls-container .item,
.rich-editor-controls.small .controls-container .control-item,
.rich-editor-controls.small .controls-container .editor-toolbar-item {
  padding: 2px;
  flex: 0 !important;
}
.rich-editor-controls.small .controls-container .item::before,
.rich-editor-controls.small .controls-container .control-item::before,
.rich-editor-controls.small .controls-container .editor-toolbar-item::before {
  display: none;
}
.rich-editor-controls.small .controls-container .item.active,
.rich-editor-controls.small .controls-container .control-item.active,
.rich-editor-controls.small .controls-container .editor-toolbar-item.active,
.rich-editor-controls.small .controls-container .item:hover,
.rich-editor-controls.small .controls-container .control-item:hover,
.rich-editor-controls.small .controls-container .editor-toolbar-item:hover {
  background-color: transparent !important;
}
.rich-editor-controls.small .controls-container .item.active .control-icon,
.rich-editor-controls.small .controls-container .control-item.active .control-icon,
.rich-editor-controls.small .controls-container .editor-toolbar-item.active .control-icon,
.rich-editor-controls.small .controls-container .item:hover .control-icon,
.rich-editor-controls.small .controls-container .control-item:hover .control-icon,
.rich-editor-controls.small .controls-container .editor-toolbar-item:hover .control-icon {
  color: black;
}
.rich-editor-controls.small .controls-container .item .input,
.rich-editor-controls.small .controls-container .control-item .input,
.rich-editor-controls.small .controls-container .editor-toolbar-item .input {
  height: 100%;
}
.rich-editor-controls .controls-container {
  width: 100% !important;
}
.rich-editor-controls .controls-container .item,
.rich-editor-controls .controls-container .control-item,
.rich-editor-controls .controls-container .editor-toolbar-item {
  flex: 1 1 !important;
}
.rich-editor-controls .controls-container .item .control-icon,
.rich-editor-controls .controls-container .control-item .control-icon,
.rich-editor-controls .controls-container .editor-toolbar-item .control-icon {
  margin: 0px auto !important;
  color: grey;
}
.rich-editor-controls.ui.menu {
  margin-top: 0px;
}
.rich-editor-controls.ui.menu i.icon.control-icon {
  margin: 0 !important;
}
