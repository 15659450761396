@import 'src/less/colors';

.sequencePopupItem {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: @SecondaryBlue;
  border-top: 1px solid @SecondaryBlue20;
  width: 100%;
  justify-content: space-between;

  &:first-of-type {
    border-top: none;
  }

  &:hover {
    background-color: @SecondaryBlue05;
  }

  :global(.sequence-container) {
    flex-grow: 1;

    :global(.row-icon-container) {
      margin-top: 0;
    }
  }
  .sequenceTitle {
    margin-right: 8px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 500;
    max-width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pluginSequenceTitle {
    margin-right: 8px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Overrides for sequence icons
  :global(.row-sequence-container) {
    display: flex;
    justify-content: flex-end;

    :global(.more-actions.hidden) {
      display: none;
    }
  }
}
