/*
 * colors.less
 */
.title {
  margin-bottom: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #1f2e77;
}
.loading {
  margin: 16px 0;
}
.emptyState {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
}
.emptyState .illustration {
  min-width: 84px;
}
@media (max-width: 1400px) {
  .emptyState .illustration {
    display: none;
  }
}
.emptyState .noItem {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7982ad;
}
