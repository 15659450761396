/*
 * colors.less
 */
.contextBtnContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  visibility: visible;
}
.contextBtn {
  padding: 6px 0px;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  border-radius: 25%;
  color: #7982ad;
}
.contextBtn:hover {
  border: 1px solid #dae0f4;
  background: #f6f7fc;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
}
.contextMenuContainer {
  max-width: 200px;
}
.contextMenuPopup {
  padding: 0px !important;
}
.contextMenuAction {
  cursor: pointer;
  padding: 0.6em 1.14em !important;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.contextMenuAction span {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contextMenuAction svg,
.contextMenuAction i,
.contextMenuAction img {
  margin-right: 8px;
  color: #a5abc9;
}
.contextMenuAction:not(.disabled):hover {
  background-color: #edf0fa;
}
.contextMenuAction.disabled {
  background-color: #f3f4f8;
  cursor: default;
}
.contextMenuAction.disabled span {
  color: #d2d5e4;
}
.contextMenuAction.disabled svg,
.contextMenuAction.disabled i,
.contextMenuAction.disabled img {
  margin-right: 8px;
  color: #d2d5e4;
}
