/*
 * colors.less
 */
.content:global(.content) {
  max-height: 540px;
  overflow: auto;
}
.QRCodeModalHeader {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: #f1f6ff;
  background: linear-gradient(180deg, #f1f6ff 0%, rgba(255, 255, 255, 0) 100%);
  font-family: Gilroy;
  font-size: 40px;
  font-weight: 900;
  line-height: 50px;
  text-align: center;
  padding-top: 48px;
  margin-bottom: 50px;
  color: #1f2e77;
}
.QRCodeContainer {
  width: fit-content;
  border-radius: 40px;
  background: linear-gradient(180deg, #0880f5 0%, #006ae0 100%);
  padding: 24px;
  margin: auto;
  margin-bottom: 70px;
}
.QRCodeContainer canvas {
  border-radius: 16px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;
}
.footer span {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.footer a {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #4864c9;
  text-decoration: underline;
}
.modalActions:global(.actions) {
  padding: 16px 32px !important;
  box-shadow: 0px 2px 16px 0px #1f2e7726;
}
.modalActions:global(.actions) button:first-of-type {
  padding-left: 16px !important;
}
.modalActions:global(.actions) button:not(:first-of-type) {
  margin-left: 8px;
}
