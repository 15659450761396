.hover-items-dropdown.ui.dropdown .menu > .item.hover-item {
  padding: 0 !important;
}
.hover-items-dropdown.ui.dropdown .hover-item-popup-trigger {
  padding: 11px 16px;
}
.hover-item-popup-content {
  max-height: 90vh;
  overflow: auto;
}
