/*
 * colors.less
 */
.job-tabs {
  border-bottom: solid 1px #dae0f4;
  margin-bottom: 24px;
  display: flex;
}
.job-tabs .tab {
  display: flex;
  border-bottom: solid 2px transparent;
  align-items: center;
  padding: 4px 0px;
  margin-right: 48px;
  color: #a5abc9;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.job-tabs .tab i {
  margin-right: 6px;
}
.job-tabs .tab .count {
  margin-left: 6px;
  font-size: 12px;
  padding: 2px 3px;
  display: flex;
  align-items: center;
  height: 18px;
  background-color: #e9ebf2;
  border-radius: 2px;
}
.job-tabs .tab.active {
  color: #5c89eb;
  border-bottom: solid 2px #5c89eb;
}
.job-tabs .tab.active .count {
  background-color: #e2ebff;
}
