.timeSelectorDropdown {
  display: flex;
  align-items: center;
  gap: 8px;
}
.timeSelectorDropdown .dropdown > div {
  font-size: 12px;
}
.timeSelectorDropdown .dropdown > div > div:first-child {
  padding: 0;
  padding-right: 20px;
}
