.timeline-item-action-dropdown {
  .menu.transition .item {
    display: flex !important;
    align-items: center;

    i {
      font-size: 16px;
      font-weight: 500;
      margin-right: 12px;
    }
  }
}
