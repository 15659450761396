/*
 * colors.less
 */
.container {
  display: block;
  margin-top: 8px;
}
.pill {
  display: inline-flex;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  gap: 6px;
  cursor: pointer;
  border-radius: 28px;
  border: 1px solid #dae0f4;
  color: #7982ad;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.pill:hover {
  transition: border-color ease 150ms;
  border-color: #a5abc9;
}
.dropdownPanel {
  width: fit-content;
  min-width: 272px;
  padding: 12px;
}
.distanceTitle {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #1f2e77;
}
.close {
  height: 14px;
  width: 14px;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
}
.close:hover {
  color: #4864c9;
}
.distance {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 14px;
  background-color: #e9ebf2;
  color: #a5abc9;
}
.arrow:hover {
  color: #4864c9;
}
