/*
 * colors.less
 */
.profile-link .linkedin-logo {
  height: 10px;
}
.profile-link .linkedin-logo .logo-fill {
  fill: #4864c9;
}
.profile-link .profile-link-item {
  margin: 4px 0;
}
