@import 'src/less/colors';

.applicationsCriterias {
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid @SecondaryBlue20;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    color: @PrimaryCobalt;

    .title {
      font-size: 14px;
      font-family: Graphik, sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      .icon {
        vertical-align: text-bottom;
        margin-right: 8px;
        color: @SecondaryBlue;
      }
    }

    .publishToggleContainer {
      display: flex;
      align-items: center;
      gap: 4px;

      > div {
        margin-bottom: 0px;
        width: 30px;
      }
    }
  }

  .settings {
    .jobPostingTitle {
      display: flex;
      gap: 4px;
      align-items: center;
      color: @PrimaryCobalt;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 12px;

      .link {
        line-height: 13px;
        i {
          margin-top: 6px;
        }
      }
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      margin: 8px 0px 16px;

      .calibration-label {
        padding: 8px;
        line-height: 12px;
        //margin-right: 5px;
        border-radius: 2px;
        background-color: @PrimaryCobalt05;
        color: @PrimaryCobalt80;
      }
    }
  }
}
