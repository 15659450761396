/*
 * colors.less
 */
.matching-details-popup {
  max-height: 300px;
  min-width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.matching-details-popup .popup-header .matching-score-label {
  font-size: 18px;
  font-weight: 600;
}
.matching-details-popup .popup-header {
  margin-bottom: 48px;
  font-size: 14px;
  font-weight: 500;
  color: #a5abc9;
}
.matching-details-popup .section-container:not(:empty) {
  width: 100%;
}
.matching-details-popup .section-container:not(:empty):not(:first-child) {
  margin-top: 20px;
}
.matching-details-popup .section-container:not(:empty):not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.matching-details-popup .popup-score {
  font-size: 16px;
}
.matching-details-popup .popup-score .score-icon {
  font-size: 16px;
}
