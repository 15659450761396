/*
 * colors.less
 */
.fieldsContainer {
  flex: 1 1;
  padding: 0;
}
.fieldsContainer .modalFormField {
  margin: 25px 0px !important;
}
.fieldsContainer .orderInput {
  margin-top: 25px;
}
.fieldsContainer .modalFormInput {
  flex-grow: 1;
  border-radius: 8px !important;
  display: block;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
}
.fieldsContainer .modalFormInput input {
  border-radius: 8px !important;
  border: 1px solid #dae0f4 !important;
  width: 100% !important;
}
.fieldsContainer .formFieldContainer {
  margin-bottom: 16px;
}
.fieldsContainer .formFieldContainer .fieldTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fieldsContainer .formFieldContainer .fieldTitle .fieldTitleInfo {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
  margin-bottom: 4px;
}
.fieldsContainer .formFieldContainer .fieldTitle .fieldTitleInfo span {
  margin-left: 8px;
}
.fieldsContainer .formFieldContainer .fieldTitle .fieldTitleActions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 4px;
}
.fieldsContainer .formFieldContainer .selectFieldQuestionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.fieldsContainer .formFieldContainer .selectFieldQuestionContainer .addOptionButton {
  margin-left: 4px;
}
.fieldsContainer .formFieldContainer .selectFieldQuestionContainer .input {
  flex-grow: 1;
}
.fieldsContainer .formFieldContainer .selectFieldQuestionContainer .customFieldSelector {
  width: 100%;
}
.fieldsContainer .fieldSectionContainer {
  margin: 12px 0px;
}
.fieldsContainer .fieldSectionContainer .fieldsTagSection,
.fieldsContainer .fieldSectionContainer .fieldsQuestionSection,
.fieldsContainer .fieldSectionContainer .fieldsActionsSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.fieldsContainer .fieldSectionContainer .fieldsTagSection span,
.fieldsContainer .fieldSectionContainer .fieldsQuestionSection span,
.fieldsContainer .fieldSectionContainer .fieldsActionsSection span {
  color: #1f2e77;
  margin-bottom: -8px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer {
  margin-bottom: 16px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .removeFieldButton {
  margin-left: 4px;
  color: #7982ad;
  font-weight: 600;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .removeFieldButton i {
  margin: 0px 4px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .fieldHeader {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: #1f2e77;
  margin-bottom: 5px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .fieldHeader span {
  margin-bottom: -3px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .toggle {
  margin-bottom: 0px;
  width: 2.5rem;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .optionsSelector .fieldOptionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .optionsSelector .fieldOptionContainer .removeOptionButton {
  color: #7982ad;
  font-weight: 600;
  height: 100%;
  cursor: pointer;
  margin-left: 4px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .optionsSelector .fieldOptionContainer i {
  margin: 0px 4px;
}
.fieldsContainer .fieldSectionContainer .fieldContainer .optionsSelector .fieldOptionContainer .fieldOption {
  color: #1f2e77;
  flex-grow: 1;
  max-width: calc(100% - 160px);
}
.fieldsContainer .fieldSectionContainer .fieldsQuestionSection {
  flex-grow: 1;
}
.fieldsContainer .fieldSectionContainer .fieldsActionsSection {
  flex-shrink: 1;
  flex-grow: 0;
}
.fieldsContainer .fieldSectionContainer .fieldsActionsSection > div {
  padding: 6px 0px;
  margin: auto;
}
.fieldsContainer .panel {
  padding: 10px;
  background-color: white;
  width: fit-content;
}
.fieldsContainer .panel .newFieldDropdownOption {
  color: #1f2e77;
  font-family: Graphik;
  cursor: pointer;
  white-space: nowrap;
}
.fieldsContainer .panel .newFieldDropdownOption:not(:last-of-type) {
  margin-bottom: 8px;
}
