/*
 * colors.less
 */
.background-item-card {
  margin-bottom: 7px !important;
  padding: 15px 15px 12px 0 !important;
}
.background-item-card.reveal {
  padding: 16px 15px 0px 0px !important;
}
.background-item-card.reveal:first-of-type {
  padding: 0px 15px 0px 0px !important;
}
.background-item-card .background-item-row {
  display: flex;
  flex-flow: row nowrap;
}
.background-item-card .background-item-row > .main {
  overflow: hidden;
  flex: 1 0;
}
.background-item-card .card-content .card-description {
  margin-top: 8px;
  font-size: 12px;
  line-height: 17px;
  color: #4c5892;
}
.background-item-card .card-header .show-more {
  float: right;
}
.background-item-card .card-header .card-title {
  margin-bottom: 4px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1f2e77;
}
.background-item-card .card-header .card-title .workplace-popup-trigger {
  text-decoration: underline;
}
.background-item-card .card-header .card-title .workplace-popup-trigger:hover {
  color: #4864c9;
}
.background-item-card .card-header .card-title .workplace-popup-trigger a:hover {
  color: #4864c9;
}
.background-item-card .card-header .card-title .card-link {
  cursor: pointer;
  text-decoration: none;
}
.background-item-card .card-header .card-title .card-link:hover {
  text-decoration: underline;
}
.background-item-card .card-header .card-title .card-link:not(:hover) {
  color: #1f2e77;
}
.background-item-card .card-header .card-subtitle {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  color: #7982ad;
}
.background-item-card .card-header .technology-icon {
  max-width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}
.background-item-card .card-header .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}
.background-item-card .card-header .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  padding-top: 0.5px;
  font-family: Graphik;
  padding-right: 2px;
  padding-left: 3px;
}
.background-item-card .card-company-image {
  border-radius: 2px;
  width: 36px;
  height: 36px;
  margin-right: 16px;
}
