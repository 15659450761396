/*
 * colors.less
 */
.atsDataCard {
  padding: 12px;
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  width: 100%;
}
.atsDataCard h3 {
  width: 100%;
  display: flex;
  align-items: center;
  color: #1f2e77;
  font-size: 14px;
  margin-bottom: 0;
}
.atsDataCard h3 img {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 6px;
}
.atsDataCard h3 .rightTitle {
  margin-left: auto;
}
.atsDataCard .description {
  font-size: 12px;
  color: #7982ad;
  padding-left: 20px;
}
.atsDataCard .content {
  margin-top: 8px;
}
.atsDataCard .extraTitle {
  width: 100%;
  min-width: 0;
}
.notInAJob {
  font-weight: 400;
  color: #7982ad;
  font-size: 12px;
  font-family: Graphik;
  margin-left: 4px;
}
.atsDataTitle {
  display: flex;
  align-items: center;
}
.rightAligned {
  margin-left: auto;
}
.infoCategory {
  display: flex;
  align-items: center;
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;
}
.infoCategory i {
  color: #7982ad;
}
.svgIcon {
  fill: #7982ad;
}
.infoCategoryTitle {
  color: #7982ad;
  margin: 0 5px;
}
.infoCategoryDescription {
  color: #1f2e77;
}
.chevron {
  cursor: pointer;
  color: #7982ad;
}
.candidateUrl {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #4864c9;
}
.candidateUrl i {
  color: #4864c9;
}
