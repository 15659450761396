@import 'src/less/colors';

.emptyState {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 48px;
  gap: 16px;

  .smallImg {
    width: 60px;
  }

  .title {
    margin: 0;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: @PrimaryCobalt120;
  }

  .smallTitle {
    font-size: 18px;
  }

  .innerContent {
    font-family: Graphik;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: @PrimaryCobalt60;
  }

  .smallInnerContent{
    font-size: 12px;
  }
}
