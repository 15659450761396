/*
 * utils.less
 */

@import 'src/less/colors';

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

// Modal

@modalPadding: 20px;

.generic-modal-style(@width, @modalPadding) {
  width: @width;
  // margin: 0 0 0 -@width/2;

  .close.icon {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: #000000;
    opacity: 0.2;
    &::before {
      font-family: verdana;
      content: '✕';
      font-weight: bold;
    }
    &:hover {
      opacity: 0.4;
    }
  }

  .header {
    background: @White;
    border-bottom: none;
    border-radius: 3px !important;
    padding-top: 25px;
    padding-bottom: 19px;
    padding-left: @modalPadding;
    padding-right: @modalPadding;
    h4 {
      margin-bottom: 4px;
      font-family: Gilroy;
      font-weight: 600;
      font-size: 17px;
      color: @PrimaryColor;
      letter-spacing: -0.21px;
    }
    h5 {
      margin: 0;
      font-family: Graphik;
      font-size: 13px;
      font-weight: normal;
      color: #767689;
    }
  }

  .content {
    position: relative;
    background: #ffffff;
    padding-top: @modalPadding / 2;
    padding-bottom: @modalPadding / 2;
    padding-left: @modalPadding;
    padding-right: @modalPadding;

    font-family: Graphik;
    font-size: 15px;
    color: #2b3d4f;
    text-align: left;

    &:after {
      position: absolute;
      bottom: 0px;
      left: @modalPadding;
      content: '';
      width: @width - 2 * @modalPadding;
      height: 1px;
      opacity: 0.5;
      background: #d6d6dc;
    }
  }

  .actions {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: @modalPadding;
    border: none;
    background: none;
    .ui.button {
      margin-right: 0;
    }
  }
}

.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
  .field {
    margin-bottom: 10px;
  }
}

// Dropdown

.hiresweet-dropdown {
  //.ui.selection.dropdown {
  //  &:focus {
  //    border-color: @PrimaryColorLight;
  //  }
  //}
  //.ui.active.selection.dropdown {
  //  border-color: @PrimaryColorLight;
  //}
  //.ui.active.visible.dropdown {
  //  div.menu.transition.visible {
  //    border: none !important;
  //    min-width: 220px;
  //    margin-top: 10px;
  //    padding-top: 10px;
  //    padding-bottom: 10px;
  //    .item {
  //      border-radius: 3px;
  //      margin-left: 10px;
  //      margin-right: 10px;
  //      padding-left: 10px !important;
  //      padding-right: 10px !important;
  //      padding-top: 9px !important;
  //      padding-bottom: 9px !important;
  //
  //      font-family: Graphik;
  //      font-size: 14px;
  //      color: #2b3d4f;
  //      letter-spacing: 0;
  //      line-height: 20px;
  //      text-align: left;
  //      cursor: pointer;
  //
  //      &:hover {
  //        background-color: @White;
  //      }
  //
  //      &.current {
  //        &:after {
  //          content: " ";
  //          display: block;
  //          position: absolute;
  //          right: 8px;
  //          bottom: 15px;
  //          border-radius: 3px;
  //          background: #10da73;
  //          height: 6px;
  //          width: 6px;
  //        }
  //      }
  //    }
  //  }
  //}
}

.hiresweet-dropdown-with-border {
  //.ui.selection.dropdown {
  //  &:focus {
  //    border-color: @PrimaryColorLight;
  //  }
  //}
  //.ui.active.selection.dropdown {
  //  border-color: @PrimaryColorLight;
  //}
  //.ui.active.visible.dropdown {
  //  div.menu.transition.visible {
  //    min-width: 220px;
  //    margin-top: 10px;
  //    padding-top: 10px;
  //    padding-bottom: 10px;
  //    .item {
  //      border-radius: 3px;
  //      margin-left: 10px;
  //      margin-right: 10px;
  //      padding-left: 10px !important;
  //      padding-right: 10px !important;
  //      padding-top: 9px !important;
  //      padding-bottom: 9px !important;
  //
  //      font-family: Graphik;
  //      font-size: 14px;
  //      color: #2b3d4f;
  //      letter-spacing: 0;
  //      line-height: 20px;
  //      text-align: left;
  //      cursor: pointer;
  //
  //      &:hover {
  //        background-color: @White;
  //      }
  //
  //      &.current {
  //        &:after {
  //          content: " ";
  //          display: block;
  //          position: absolute;
  //          right: 8px;
  //          bottom: 15px;
  //          border-radius: 3px;
  //          background: #10da73;
  //          height: 6px;
  //          width: 6px;
  //        }
  //      }
  //    }
  //  }
  //}
}

// Top bar & sub-bar headers
@top-header-height: 64px;
@sub-header-height: 90px;
@watch-collect-sub-header-height: 40px;
@sequence-sub-header-height: 64px;
