/*
 * colors.less
 */
.legend {
  display: flex;
  margin-bottom: 24px;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 16px;
}
