/*
 * colors.less
 */
.candidate-edit-form.ui.form {
  height: calc(100vh - 120px);
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: white;
}
.candidate-edit-form.ui.form .candidate-edit-header {
  padding: 20px;
  border-bottom: 1px solid #dae0f4;
}
.candidate-edit-form.ui.form .candidate-edit-content {
  overflow-y: auto;
}
.candidate-edit-form.ui.form .candidate-edit-content .candidate-edit-standard-fields {
  padding: 20px;
  border-bottom: 1px solid #dae0f4;
}
.candidate-edit-form.ui.form .candidate-edit-content .candidate-edit-custom-fields {
  padding: 20px;
}
.candidate-edit-form.ui.form .actions {
  display: flex;
  justify-content: space-between;
}
.candidate-edit-form.ui.form .title {
  color: #4864c9;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
}
.candidate-edit-form.ui.form .field.inline .ui.input {
  width: 250px !important;
}
.candidate-edit-form.ui.form .field.inline label {
  width: 200px;
}
