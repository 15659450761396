@import 'src/less/colors';

.tabs {
  .tab {
    margin: 0;
    margin-right: 24px;
    padding: 0;
    padding-bottom: 10px;
    color: @PrimaryCobalt40;
    border-bottom: 2px solid transparent;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    transition: all 75ms ease;

    &:hover {
      border-bottom-color: @PrimaryCobalt40;
    }

    &.active {
      color: @SecondaryBlue;
      border-bottom-color: @SecondaryBlue;
    }
  }
}
