/*
 * colors.less
 */
.workflowsSection .header h2 {
  font-weight: 700;
  font-size: 22px;
  color: #1f2e77;
  margin-bottom: 0px;
}
.workflowsSection .hint {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.workflowsSection .hint i {
  font-size: 24px;
  padding: 10px;
  color: #4864c9;
  background-color: #e2ebff;
  border-radius: 50%;
}
.workflowsSection .banner {
  background: #f1f6ff;
  border-radius: 8px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.workflowsSection .banner .bannerTitle {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 18px;
  color: #1f2e77;
  margin-bottom: 0px;
}
.workflowsSection .banner .bannerSubtitle {
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: #7982ad;
}
.workflowsSection .banner .bannerButton {
  border-radius: 8px !important;
  background: #4864c9 !important;
}
.workflowsSection .title {
  display: block !important;
  color: #1f2e77 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}
.workflowsSection .flexColum {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.workflowsSection .flexGrow {
  flex: 1;
}
.workflowsSection .dropdown {
  border-radius: 8px !important;
  min-width: 100px !important;
}
.workflowsSection .count {
  padding: 6px;
  border-radius: 50%;
  background-color: #e2ebff;
  color: #4864c9;
  margin-left: 10px;
  font-size: 12px;
}
.workflowsSection .connector {
  margin-top: 30px;
  margin-bottom: 45px;
}
.workflowsSection .connector .description {
  display: block;
  padding-top: 10px;
  color: #1f2e77;
  font-size: 14px;
  font-weight: 500;
}
.workflowsSection .connector .atsJobAndOwner {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}
.workflowsSection .connector .emptyStateWrapper {
  background: #f6f7fc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}
.workflowsSection .connector .emptyStateWrapper h2 {
  font-size: 22px;
  font-weight: 700;
  color: #1f2e77;
}
.workflowsSection .connector .emptyStateWrapper span {
  font-size: 14px;
  font-weight: 400;
  color: #4c5892;
  font-family: Graphik, sans-serif;
}
.workflowsSection .connector .emptyStateWrapper .icons {
  margin: 35px 0;
  display: flex;
  gap: 15px;
  align-items: center;
}
.workflowsSection .connector .emptyStateWrapper .icons img {
  width: 56px;
  height: 56px;
  border-radius: 28px;
}
.workflowsSection .connector .emptyStateWrapper .button {
  border-radius: 2px !important;
}
.workflowsSection .divider {
  border-top: 1px solid #d2d5e4;
  border-bottom: 0px;
}
.workflowsSection .section {
  margin: 45px 0;
}
.workflowsSection .workflowsList {
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.workflowsSection .workflowContainer {
  display: flex;
  gap: 15px;
  padding: 20px 12px;
  border-bottom: 1px solid #d2d5e4;
}
.workflowsSection .workflow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.workflowsSection .workflow .trigger {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
}
.workflowsSection .workflow .trigger span {
  margin-right: 4px;
  white-space: break-spaces;
}
.workflowsSection .workflow .trigger .dropdown {
  flex-basis: fit-content;
  flex-grow: 1;
}
.workflowsSection .workflow .actionWrapper {
  display: flex;
  gap: 10px;
  flex: 1;
}
.workflowsSection .workflow .actionWrapper span {
  white-space: break-spaces;
}
.workflowsSection .workflow .action {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
}
.workflowsSection .workflow .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #b6c1e9;
  background-color: #f8faff;
  border-radius: 8px;
  font-size: 18px;
  height: fit-content;
}
.workflowsSection .workflow .workflowActions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.workflowsSection .workflow .workflowActions .deleteIcon {
  cursor: pointer;
}
.workflowsSection .workflowActions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.workflowsSection .footer {
  display: flex;
  justify-content: flex-end;
}
