/*
 * colors.less
 */
.snippetInfo {
  padding: 1px 4px;
  background: #ffffff;
  border-radius: 12px;
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 12px;
  color: #4864c9;
  margin: 0 2px;
}
.placeholder {
  background-color: #e2ebff;
  padding: 3px 7px;
  border-radius: 1em;
  color: #1f2e77;
  font-weight: 500;
  cursor: pointer;
}
.margin {
  margin-right: 4px;
}
.popup {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.popup .title {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  display: flex;
  color: #1f2e77;
  padding: 10px;
  cursor: pointer;
}
.fragmentPlaceholder {
  box-sizing: border-box;
  background-color: white;
  border-radius: 1em;
  color: #4864c9;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Graphik';
  /*   &:hover {
    border: 1px solid #a9c4ff;
  } */
}
.fragmentPopupWrapper:global(.ui.popup) {
  border-radius: 4px;
  padding: 0px;
  white-space: nowrap;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContent {
  display: flex;
  align-items: center;
  font-family: 'Graphik';
  font-size: 14px;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContent .fragmentName {
  font-weight: 500;
  color: #1f2e77;
  padding: 5px;
  padding-right: 0px;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContent .sync {
  font-weight: 400;
  color: #7982ad;
  padding: 5px;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContent .sync .link {
  color: #4864c9;
  text-decoration: underline;
  font-weight: 500;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContent .unlink {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  color: #ff6059;
  cursor: pointer;
  border-left: 1px solid #dae0f4;
  padding-left: 5px;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContentPlugin {
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContentPlugin .unlink {
  border-left: none;
}
.fragmentPopupWrapper:global(.ui.popup) .fragmentPopupContentPlugin .sync {
  padding-bottom: 0;
}
