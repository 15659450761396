@import 'src/less/colors';

.table {
  border-radius: 8px !important;

  .header {
    background: #f8faff !important;
    border: 1px solid #dae0f4 !important;

    .headerRow {
      .th:first-of-type {
        border-top-left-radius: 8px;
      }

      .th:last-of-type {
        border-top-right-radius: 8px;
      }
    }

    .th {
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 14px;
      color: #7982ad;
    }
  }

  .row {
    background: #ffffff !important;
    border-bottom: 1px solid #dae0f4 !important;

    &:last-of-type {
      .name {
        border-bottom-left-radius: 8px;
      }

      .actions {
        border-bottom-right-radius: 8px;
      }
    }

    .name {
      color: #4864c9 !important;
      font-weight: 600 !important;
    }

    .subtype {
      color: #1f2e77 !important;
    }

    .icon {
      margin: 0px 5px;
      color: #7982ad;
      font-size: 22px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
