/*
 * colors.less
 */
.action-preview {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
}
.action-preview:first-of-type {
  margin-top: 0;
}
.action-preview:last-of-type {
  margin-bottom: 0;
}
.action-preview .trigger {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #5c89eb;
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
}
.action-preview .trigger .trigger-text {
  margin-left: 16px;
}
.action-preview .sender {
  line-break: anywhere;
  margin-top: 0px !important;
  max-width: 100%;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.action-preview .sender span {
  white-space: break-spaces !important;
}
.action-preview .sender span.sender-address {
  color: #5c89eb;
}
.action-preview .sender span.sender-error {
  color: #e45a6b;
}
.action-preview .sender .intro-word {
  font-weight: 400;
  margin-left: 16px;
  color: #7982ad !important;
}
.action-preview .title {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #1f2e77;
  display: flex;
  align-items: center;
}
.action-preview .position {
  margin-right: 16px;
  height: 32px;
  width: 32px;
  background-color: white;
  border: 1px solid #d2d5e4;
  border-radius: 28px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #7982ad;
  z-index: 2;
}
.action-preview .line,
.action-preview .top-line {
  width: 28px;
  position: relative;
  flex-shrink: 0;
}
.action-preview .line::after,
.action-preview .top-line::after {
  border-left: 1px solid #d2d5e4;
  content: '';
  position: absolute;
  top: calc((-1 * 24px) / 2);
  left: 15px;
  bottom: calc(-1 * 24px);
}
.action-preview:first-child .top-line::after {
  display: none;
}
.action-preview:last-child .line::after {
  display: none;
}
.action-preview .task-icon {
  height: 20px;
  margin-right: 10px;
  vertical-align: bottom;
}
.action-preview .description {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 8px;
  display: flex;
  align-items: stretch;
}
.action-preview .description .description-text {
  flex-grow: 1;
  margin-left: 16px;
}
.action-preview .description .description-text-content {
  white-space: pre-wrap;
}
.action-preview .message {
  margin-top: 12px;
  display: flex;
  align-items: stretch;
}
.action-preview .message .message-template {
  flex-grow: 1;
  margin-left: 16px;
}
.action-preview .editor-container .body-and-signature {
  min-height: 0;
  margin-top: 10px;
}
.action-preview .editor-container .body-and-signature .RichEditor-editor {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
