@import 'src/less/colors';

.content {
  display: block;
  text-align: center;
  font-family: Graphik;
  color: @PrimaryCobalt60;
  font-weight: 400;

  .link {
    font-weight: 500;
    color: @SecondaryBlue;
    text-decoration: underline;
  }
}
