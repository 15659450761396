/*
 * colors.less
 */
.custom-textarea {
  display: block;
  min-height: 50px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #dae0f4;
  border-radius: 0.28571429rem;
}
.custom-textarea:hover {
  border-color: #6d83d4;
}
.custom-textarea:focus {
  border-radius: 4px;
  outline-width: 0;
  border: 1px solid #4864c9;
}
.custom-textarea::placeholder,
.custom-textarea ::-webkit-input-placeholder {
  color: rgba(191, 191, 191, 0.87);
  opacity: 1;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
