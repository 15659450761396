@import 'src/less/colors';

.dropdownContainer {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.dropdownContent {
  display: none;
  position: absolute;
  z-index: 100;

  transition: opacity 50ms ease;

  &.open {
    display: block;
  }

  &.invisible {
    opacity: 0;
  }
}
