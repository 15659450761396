/*
 * colors.less
 */
.full-contact-flow-sequence-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.full-contact-flow-sequence-editor .contact-flow-sequence {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.full-contact-flow-sequence-editor .sequence-editor-header {
  padding: 5px;
  width: 100%;
  background: #4864c9;
  color: white;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.full-contact-flow-sequence-editor .sequence-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  padding-top: 0;
}
.full-contact-flow-sequence-editor .sequence-actions .sequence-action {
  width: 100%;
}
.full-contact-flow-sequence-editor .sequence-actions .vertical-line-separator {
  width: 100%;
  position: relative;
  height: 55px;
  font-size: 20px;
}
.full-contact-flow-sequence-editor .sequence-actions .vertical-line-separator .line {
  border-left: 4px solid #dfdfe6;
  position: absolute;
  height: 55px;
  left: 50%;
  margin-left: -2px;
  top: 0;
}
.full-contact-flow-sequence-editor .sequence-actions .vertical-line-separator .add-icon {
  position: absolute;
  left: 50%;
  margin-left: -14px;
  top: 14px;
  color: #7982ad;
  padding: 4px;
  border: 1px solid #d2d5e4;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
.full-contact-flow-sequence-editor .sequence-actions .offer-2nd-followup-prompt {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}
.full-contact-flow-sequence-editor .sequence-actions .offer-2nd-followup-prompt span:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.full-contact-flow-sequence-editor .sequence-actions .offer-2nd-followup-prompt .end-of-sequence {
  color: #7982ad !important;
  background-color: white !important;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  cursor: auto !important;
}
.full-contact-flow-sequence-editor .sequence-actions .offer-2nd-followup-prompt .end-of-sequence :hover {
  box-shadow: none !important;
}
