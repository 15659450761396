@import 'src/less/colors';

.companySettings {
  margin-bottom: 40px;

  .title {
    font-weight: 700;
    font-size: 26px;
    color: @PrimaryCobalt;
  }

  .section {
    margin-top: 20px;

    h2 {
      font-family: 'Gilroy';
      font-weight: 700;
      font-size: 18px;
      color: @PrimaryCobalt;
      margin-bottom: 5px;
    }

    .card {
      padding: 24px;
      background: #ffffff;
      border: 1px solid @SecondaryBlue20;
      border-radius: 8px;

      .flexBetween {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        .formField {
          flex: 1;
        }
      }

      .hint {
        font-weight: 400;
        font-size: 14px;
        color: @PrimaryCobalt60;
        display: block;
        margin-bottom: 20px;
      }

      .nameAndLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        width: 80%;
      }

      .formField {
        min-width: 300px;
        margin-bottom: 25px;

        label {
          font-weight: 500;
          font-size: 14px;
          color: @PrimaryCobalt;
        }
      }

      .linkFormField {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          width: 15%;
        }
      }

      .input {
        flex: 1;

        input {
          border: 1px solid @SecondaryBlue20;
          box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
          border-radius: 8px;
        }
      }

      .invalidInput {
        input {
          border: 1px solid @SecondaryRed120;
        }
      }

      .logoWrapper {
        display: flex;
        align-items: center;
        gap: 15px;

        .spanIcon {
          width: 64px;
          height: 64px;
          background-color: @SecondaryBlue20;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          text-transform: uppercase;
          color: white;
          font-weight: bold;
        }

        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }

        button {
          margin-bottom: 5px;
        }
      }
    }
  }

  .actions {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }
}
