/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.new-template-modal .editor-container,
.contact-flow-editor .editor-container {
  border: 1px solid #dae0f4;
  box-shadow: inset 0 0 2px #dddddd;
}
.new-template-modal .editor-container > .email-subject.RichEditor-editor .public-DraftEditorPlaceholder-root,
.contact-flow-editor .editor-container > .email-subject.RichEditor-editor .public-DraftEditorPlaceholder-root,
.new-template-modal .editor-container > .email-subject.RichEditor-editor .public-DraftEditor-content,
.contact-flow-editor .editor-container > .email-subject.RichEditor-editor .public-DraftEditor-content {
  padding: 0;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 600;
}
.new-template-modal .editor-container > .secondary.menu,
.contact-flow-editor .editor-container > .secondary.menu {
  display: flex;
  justify-content: flex-end;
}
.new-template-modal .editor-container > .secondary.menu > .right.menu,
.contact-flow-editor .editor-container > .secondary.menu > .right.menu {
  margin: 0 !important;
  flex-grow: 0;
}
.inplace-input {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 22px;
  border-bottom: 1px dashed #dae0f4;
  padding: 5px 0;
  color: #1f2e77;
  width: 100%;
}
.inplace-input:focus {
  outline: none;
}
.inplace-input.input-error {
  border-color: #e08383;
}
.inplace-input.input-error::placeholder {
  color: #b10101;
  opacity: 0.7;
}
.new-template-container .ui.button {
  height: 38px;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  align-items: center;
  display: flex;
}
.new-template-container .ui.button i.ri-add-line {
  font-weight: 500;
  font-size: 26px;
  margin-right: 10px;
}
.title-field {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 20px;
  color: #112e48;
  line-height: 30px;
}
