/*
 * colors.less
 */
.container {
  display: inline-flex;
  height: 40px;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
}
.container:not(.disabled) {
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.container.disabled {
  background-color: #f6f7fc;
}
.container.focused {
  border-color: #a9c4ff;
  box-shadow: inset 0px 0px 1px 1px #a9c4ff;
}
.container.small {
  height: 32px;
}
.container.small .input {
  font-size: 12px;
  line-height: 16px;
}
.container.fluid {
  width: 100%;
}
.container .icon {
  font-size: 16px;
  color: #a5abc9;
}
.container .input {
  flex: 1;
  font-family: Graphik;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #1f2e77;
  outline: none;
}
.container .input ::placeholder {
  color: #a5abc9;
}
.container .input:disabled {
  color: #a5abc9;
}
.container .input:disabled ::placeholder {
  color: #d2d5e4;
}
