@import 'src/less/colors';

.fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #404564;
}

.fieldsContainer {
  input,
  div:global(.ui.selection.dropdown) {
    border-radius: 8px !important;
  }
}

.fieldsSectionContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0px;

  .fieldsTagSection,
  .fieldsQuestionSection,
  .fieldsActionsSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    span {
      color: @PrimaryCobalt;
      margin-bottom: -8px;
    }
  }

  .fieldsQuestionSection {
    flex-grow: 1;
  }

  .fieldsActionsSection {
    flex-shrink: 1;
    flex-grow: 0;

    > div {
      padding: 6px 0px;
      margin: auto;
    }
  }
}

.modalActions:global(.actions) {
  button:first-of-type {
    padding-left: 16px !important;
  }
}
