/*
 * colors.less
 */
.jobHeader {
  display: grid;
  height: 116px;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: center;
  padding: 10px 40px;
  padding-right: 60px;
  background-color: white;
  border-bottom: 1px solid #dae0f4;
}
.jobHeader .breadcrumbs {
  grid-row: 1;
  grid-column: 1;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
}
.jobHeader .linkedCompany {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.jobHeader .companyLogo {
  width: 16px;
  height: 16px;
}
.jobHeader .linkedCompanyContainer {
  padding: 4px 8px;
  border-radius: 8px;
  background: #f6f7fc;
  color: #7982ad;
  display: flex;
  align-items: center;
  gap: 4px;
}
.jobHeader .positionDescriptionTrigger {
  padding: 4px 4px;
  border-radius: 8px;
  background: #f6f7fc;
  color: #7982ad;
  line-height: 14px;
  font-size: 16px;
}
.jobHeader .navigation {
  grid-row: 2;
  grid-column: 1;
  margin-bottom: 6px;
}
.jobHeader .navigation .displayTypeDropdown {
  margin-left: 8px;
}
.jobHeader .navigation .displayTypeDropdown:global(.ui.dropdown) {
  display: inline-flex;
  align-items: center;
}
.jobHeader .navigation .displayTypeDropdown:global(.ui.dropdown) i {
  margin-right: 0 !important;
  margin-left: 6px;
}
.jobHeader .navigation .displayTypeDropdown .menu {
  width: fit-content;
  text-align: left;
  padding: 8px 12px;
}
.jobHeader .navigation .buttonsContainer {
  display: flex;
  flex-direction: row;
}
.jobHeader .navigation .buttonsContainer :global .ui.button {
  margin: 0 6px;
  border: none;
  background-color: transparent;
  color: #7982ad;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}
.jobHeader .navigation .buttonsContainer :global .ui.button .ui.label {
  padding: 0.3em 0.5em 0.2em 0.5em;
  margin-left: 1em;
  margin-top: -2px;
  vertical-align: middle;
  align-self: center;
  color: #7982ad;
  background-color: #e9ebf2;
}
.jobHeader .navigation .buttonsContainer :global .ui.button.active {
  background-color: #e2ebff;
  color: #4864c9;
}
.jobHeader .navigation .buttonsContainer :global .ui.button.active .ui.label {
  background-color: white;
  color: #4864c9;
}
.jobHeader .navigation .buttonsContainer :global .ui.button.active:hover {
  background-color: #c6d8ff;
}
.jobHeader .navigation .buttonsContainer :global .ui.button:hover {
  background-color: #f3f4f8;
}
.jobHeader .navigation .buttonsContainer :global .ui.button .icon {
  margin-right: 8px;
  margin-top: -1px;
  display: inline-block;
}
.jobHeader .kpis {
  grid-row: 1 / span 2;
  grid-column: 2;
}
