@import 'src/less/colors';

.createMetaTaskAndPreviewFormContainer {
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid @SecondarySkyBlue20;

  > div {
    flex: 1 1;
    padding: 12px 24px;
  }

  .fieldLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px;
    color: #404564;
  }

  .previewContainer {
    flex: 1 1;
    padding: 12px 24px;
    border-left: 1px solid @SecondarySkyBlue20;

    .previewTitle {
      text-align: center;
      color: @PrimaryCobalt;
      font-family: Graphik;
    }
  }
}

.modalFooter:global(.actions) {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  color: red;
}
