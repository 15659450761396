@import 'src/less/colors';

@options-displayed: 8.7;
@option-height: 32px;
@padding-y: 8px;

.menu {
  padding: @padding-y 0;
  // Leave a padding height at the end to indicate there are more elements than displayed
  max-height: @options-displayed * @option-height + @padding-y;

  overflow-y: auto;
}
