@import 'src/less/colors.less';

.lineIcon {
  padding: 10px;
  background: @SecondaryBlue05;
  border-radius: 8px;
  height: 56px;
  width: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.emptyState {
  padding: 16px;
  color: @PrimaryCobalt;
  background-color: white;
  border: 1px solid @SecondaryBlue20;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  margin-top: 14px;
}

.contractLine {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .contractLineInfo {
    > span {
      display: block;
      font-family: Graphik, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    span:first-child {
      font-family: Graphik, sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: @PrimaryCobalt;
      line-height: 19.6px;
    }

    span:not(:first-child) {
      color: @PrimaryCobalt60;
    }

    span.contractNameLegalId {
      font-size: 14px;
      font-weight: 400;
      color: @PrimaryCobalt60;
    }
  }
}
