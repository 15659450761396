/*
 * colors.less
 */
.landing-layout {
  width: 100vw;
  min-height: 100vh;
  background-color: #1f2e77;
  color: #f8f8f8;
  overflow-y: hidden;
  padding: 5px;
  border: 8px solid white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (min-width: 1120px) {
  .landing-layout {
    border: 16px solid white;
  }
}
.landing-layout .hiresweet-logo {
  position: absolute;
  margin-top: 1px;
  height: 17px;
  left: 20px;
  top: 20px;
  z-index: 5;
}
@media screen and (min-width: 1120px) {
  .landing-layout .hiresweet-logo {
    top: 55px;
    left: 50px;
  }
}
.landing-layout .layout-content-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.landing-layout .layout-content {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.landing-layout .layout-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.landing-layout .layout-title {
  position: relative;
  margin-left: 30px;
  white-space: pre-wrap;
  z-index: 1;
  min-width: 490px;
  max-width: 600px;
}
.landing-layout .layout-title .title {
  padding: 0 30px;
  font-family: Gilroy, sans-serif;
  font-size: 80px;
  line-height: 80px;
  color: #4864c9;
}
.landing-layout .layout-title .subtitle {
  padding: 0 30px;
  color: #f8f8f8;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 20px;
}
.landing-layout .layout-title .details {
  padding: 0 30px;
  max-width: 450px;
  font-style: italic;
  opacity: 0.7;
  font-family: Graphik, sans-serif;
}
.landing-layout .layout-title::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  top: calc(50% - (490px / 2));
  background-image: url(/images/logos/logo-shadow.svg);
  width: 490px;
  height: 490px;
  z-index: -1;
}
.landing-layout .layout-form {
  margin-left: 30px;
  margin-right: 30px;
  width: 540px;
  z-index: 1;
}
