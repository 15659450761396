@import 'src/less/colors';

.connectPagePreview {
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
  .previewHeader {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    background: @SecondarySkyBlue10;
    background: linear-gradient(
      180deg,
      rgba(241, 246, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    color: @PrimaryCobalt;
    .previewText {
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      padding-top: 10px;
    }
    .title {
      font-family: Gilroy;
      font-size: 40px;
      font-weight: 900;
      line-height: 50px;
      text-align: center;
      padding-top: 20px;
      margin-bottom: 40px;
    }
  }

  .description {
    text-align: center;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.23999999463558197px;
    text-align: center;
    color: @PrimaryCobalt60;
    width: 90%;
    margin: 0 auto 50px auto;
  }

  .previewForm {
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .question {
      .questionTitle {
        font-weight: 500;
        margin-bottom: 4px;
        color: @PrimaryCobalt;
      }

      .fakeInput {
        &:is(textarea) {
          width: 100%;
          border: 1px solid @SecondaryBlue20;
          opacity: 0.45;
          padding: 11px 1em;
          border-radius: 8px;
        }
        > input {
          border-radius: 8px !important;
        }
      }
    }
  }
}
