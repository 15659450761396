/*
 * colors.less
 */
.subHeader {
  height: 72px;
  display: flex;
  border-bottom: 1px solid #d2d5e4;
  background-color: white;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
}
.subHeader .left {
  display: flex;
  align-items: center;
  gap: 24px;
}
.subHeader .title {
  margin: 0;
  padding: 0;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 22px;
  color: #1f2e77;
}
.subHeader .subtypes {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 24px;
}
.subHeader .subtypes .subtype {
  line-height: 1em;
  list-style: none;
}
.subHeader .subtypes .subtype button {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 22px;
  color: #a5abc9;
  cursor: pointer;
}
.subHeader .subtypes .subtype button:hover {
  color: #6d83d4;
}
.subHeader .subtypes .subtype.selected button {
  color: #1f2e77;
}
