/*
 * colors.less
 */
.dashboard-quick-actions .quick-action {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 27px;
  background-color: white;
  border: 1px solid #dae0f4;
  margin-bottom: 8px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #7982ad;
}
.dashboard-quick-actions .quick-action:hover {
  background-color: #f3f4f8;
}
.dashboard-quick-actions .quick-action:last-child {
  margin-bottom: 40px;
}
.dashboard-quick-actions .quick-action i {
  color: #709dff;
  width: 15px;
  margin-right: 10px;
}
