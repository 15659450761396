/*
 * colors.less
 */
.date {
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #7982ad;
  line-height: 20px;
}
.atsIcon {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
