/*
 * colors.less
 */
.simpleDropdown:global(.ui):global(.dropdown) {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #5c89eb;
  cursor: pointer;
}
.simpleDropdown:global(.ui):global(.dropdown) > i:global(.dropdown):global(.icon) {
  margin-left: 4px;
}
