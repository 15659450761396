/*
 * colors.less
 */
.pill {
  display: inline-flex;
  padding: 4px;
  padding-left: 8px;
  margin: 0 2px;
  gap: 4px;
  background: none;
  background-color: #f3f4f8;
  border: 1px solid #f3f4f8;
  border-radius: 20px;
}
.pill:not(:disabled) {
  cursor: pointer;
}
.pill:not(:disabled):hover {
  border-color: #4864c9;
}
.pill.selected {
  background-color: #e2ebff;
  border-color: #4864c9;
}
.pill .name {
  user-select: none;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  font-family: Graphik;
  font-weight: 400;
  color: #1f2e77;
}
.pill:disabled:not(.selected) .name {
  color: #a5abc9;
}
