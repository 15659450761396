@import 'src/less/colors';

.workflowsSection {
  .header {
    h2 {
      font-weight: 700;
      font-size: 22px;
      color: @PrimaryCobalt;
      margin-bottom: 0px;
    }
  }

  .hint {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    i {
      font-size: 24px;
      padding: 10px;
      color: @SecondaryBlue;
      background-color: @SecondarySkyBlue20;
      border-radius: 50%;
    }
  }

  .banner {
    background: @SecondarySkyBlue10;
    border-radius: 8px;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    margin-bottom: 30px;

    .bannerTitle {
      font-family: 'Gilroy';
      font-weight: 700;
      font-size: 18px;
      color: @PrimaryCobalt;
      margin-bottom: 0px;
    }

    .bannerSubtitle {
      font-family: 'Graphik';
      font-weight: 400;
      font-size: 14px;
      color: @PrimaryCobalt60;
    }

    .bannerButton {
      border-radius: 8px !important;
      background: @SecondaryBlue !important;
    }
  }

  .title {
    display: block !important;
    color: @PrimaryCobalt !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
  }

  .flexColum {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .flexGrow {
    flex: 1;
  }

  .dropdown {
    border-radius: 8px !important;
    min-width: 100px !important;
  }

  .count {
    padding: 6px;
    border-radius: 50%;
    background-color: @SecondarySkyBlue20;
    color: @SecondaryBlue;
    margin-left: 10px;
    font-size: 12px;
  }

  .connector {
    margin-top: 30px;
    margin-bottom: 45px;
    .description {
      display: block;
      padding-top: 10px;
      color: @PrimaryCobalt;
      font-size: 14px;
      font-weight: 500;
    }

    .atsJobAndOwner {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }

    .emptyStateWrapper {
      background: #f6f7fc;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0;

      h2 {
        font-size: 22px;
        font-weight: 700;
        color: @PrimaryCobalt;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: @PrimaryCobalt80;
        font-family: Graphik, sans-serif;
      }

      .icons {
        margin: 35px 0;
        display: flex;
        gap: 15px;
        align-items: center;

        img {
          width: 56px;
          height: 56px;
          border-radius: 28px;
        }
      }
      .button {
        border-radius: 2px !important;
      }
    }
  }

  .divider {
    border-top: 1px solid @PrimaryCobalt20;
    border-bottom: 0px;
  }

  .section {
    margin: 45px 0;
  }

  .workflowsList {
    border: 1px solid @SecondaryBlue20;
    border-radius: 8px;
  }

  .workflowContainer {
    display: flex;
    gap: 15px;
    padding: 20px 12px;
    border-bottom: 1px solid @PrimaryCobalt20;
  }

  .workflow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;

    .trigger {
      display: flex;
      gap: 10px;
      align-items: center;
      flex: 1;

      span {
        margin-right: 4px;
        white-space: break-spaces;
      }

      .dropdown {
        flex-basis: fit-content;
        flex-grow: 1;
      }
    }

    .actionWrapper {
      display: flex;
      gap: 10px;
      flex: 1;

      span {
        white-space: break-spaces;
      }
    }

    .action {
      display: flex;
      gap: 10px;
      align-items: center;
      flex: 1;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: @SecondaryBlue40;
      background-color: @SecondarySkyBlue05;
      border-radius: 8px;
      font-size: 18px;
      height: fit-content;
    }

    .workflowActions {
      display: flex;
      align-items: center;
      gap: 10px;

      .deleteIcon {
        cursor: pointer;
      }
    }
  }

  .workflowActions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
