/*
 * colors.less
 */
.tagMessage {
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 24px;
}
.tagMessage:not(:last-child) {
  margin-right: 4px;
}
.tagMessage.small {
  padding: 3px 8px;
}
.tagMessage.mini {
  padding: 0px 8px;
}
.tagMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.tagMessage.spaced {
  margin-left: 8px;
}
.tagMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.tagMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.tagMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.tagMessage.grey {
  background-color: #f6f7fc;
  color: #7982ad;
}
.tagMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.tagMessage.blue {
  background-color: #e2ebff;
  color: #4864c9;
}
