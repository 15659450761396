/*
 * colors.less
 */
.inputContainer {
  margin-bottom: 24px;
}
.inputContainer .inputTitle {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  margin-bottom: 4px;
}
.inputContainer .inputSubtitle {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  margin-bottom: 4px;
}
.modalActions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
