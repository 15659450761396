@import 'src/less/colors';

.table {
  border-radius: 8px !important;
  overflow: hidden;

  .header {
    background: @SecondarySkyBlue05 !important;
    border: 1px solid @SecondaryBlue20 !important;

    .th {
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 14px;
      color: @PrimaryCobalt60;

      &.th:first-of-type {
        border-top-left-radius: 8px;
      }

      &.th:last-of-type {
        border-top-right-radius: 8px;
      }
    }
  }

  .row {
    background: white !important;
    border-bottom: 1px solid @SecondaryBlue20 !important;

    &:last-of-type {
      .unipileIcon {
        border-bottom-left-radius: 8px;
      }

      .actions {
        border-bottom-right-radius: 8px;
      }
    }

    .unipileIcon {
      padding-right: 0px;
      .icon {
        display: flex;
        border-radius: 3px;
        padding: 2px;
      }
    }

    .status {
      margin: 0px;
      font-family: 'Graphik', sans-serif;
      display: inline-flex;
      align-items: center;
      height: 20px;

      i {
        margin: 0px 5px 1px 0px;
      }
    }

    .sharedWithContainer {
      display: flex;
      flex-direction: column;
    }

    .name {
      color: @SecondaryBlue !important;
      font-weight: 500 !important;

      .taskIcon {
        margin-right: 8px;
      }
    }

    .subtype {
      color: @PrimaryCobalt !important;
    }

    .icon {
      margin: 0px 0px;
      color: @PrimaryCobalt60;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.actionPanel {
  width: fit-content;

  .actionPanelItem {
    span {
      margin-right: 8px;
      color: @PrimaryCobalt60;
    }
  }
}

.emptyState {
  margin-bottom: 12px;
}
