/*
 * colors.less
 */
.companies .field {
  margin: 8px 0;
}
.companies .field .radio-row {
  cursor: pointer;
  margin-bottom: 8px;
}
.companies .field .radio-row .radio-label {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik;
  color: #4c5892;
}
