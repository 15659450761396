/*
 * colors.less
 */
.formInputLegend {
  color: #a5abc9;
  font-size: 12px;
}
.formInputLegend.clickable {
  cursor: pointer;
}
.clearButton {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: hsl(0, 0%, 80%);
}
.clearButton:hover {
  color: hsl(0, 0%, 40%);
}
.clearButton .clearIcon {
  font-size: 20px;
}
