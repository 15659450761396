/*
 * colors.less
 */
.genericTabMenu {
  padding: 10px 20px 10px 20px;
  box-shadow: inset 0px -1px 0px #dae0f4;
}
.genericTabMenu .tab {
  display: inline-block;
  padding: 6px 12px 6px 12px;
  font-weight: 500;
  color: #7982ad;
  cursor: pointer;
  margin: 0px 8px;
}
.genericTabMenu .tab.activeTab {
  background-color: #e2ebff;
  color: #4864c9;
  border-radius: 4px;
}
