/*
 * colors.less
 */
.snoozeDimmer {
  position: absolute;
  inset: -15px;
  background-color: #1f2e774c;
}
.infoBox {
  margin-top: 8px;
}
