/*
 * colors.less
 */
.metaTaskFormContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 14px;
}
.metaTaskFormContainer .metaTaskTitle {
  color: #1f2e77;
  font-weight: 500;
  font-family: Graphik;
  width: 100%;
}
.metaTaskFormContainer .helperText {
  background-color: #f6f7fc;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 8px;
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 600;
  color: #4c5892;
}
.metaTaskFormContainer .metaTaskAnswersContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.metaTaskFormContainer .metaTaskAnswersContainer .metaTaskAnswerContainer {
  flex: 1 1;
}
.metaTaskFormContainer .metaTaskAnswersContainer .metaTaskAnswerContainer .metaTaskSelectAnswer {
  cursor: pointer;
  background-color: #f6f7fc;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 8px;
  text-align: center;
  font-family: Graphik;
  font-size: 14px;
  color: #4c5892;
  min-width: 150px;
  white-space: nowrap;
}
.metaTaskFormContainer .metaTaskAnswersContainer .metaTaskAnswerContainer .metaTaskSelectAnswer:hover {
  background-color: #edf0fa;
}
.metaTaskFormContainer .metaTaskAnswersContainer .metaTaskAnswerContainer .metaTaskSelectAnswer.selected {
  border: 1px solid #4864c9;
}
.metaTaskFormContainer .metaTaskAnswersContainer .metaTaskAnswerContainer .metaTaskTextArea {
  display: inline-block;
}
.classicField .metaTaskTitle {
  margin-bottom: 8px;
}
.metaTaskAnswer {
  flex-grow: 1;
}
.metaTaskAnswer .metaTaskInput {
  width: 100%;
}
.metaTaskAnswer .metaTaskTextArea {
  resize: none;
}
.metaTaskAnswer .datePickerInput {
  padding: 1em;
  display: inline-flex;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  grid-gap: 8px;
  gap: 8px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
}
