@import 'src/less/colors';

.holdNudge {
  padding: 0 80px 0 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .suspendedIcon {
    color: transparent;

    path {
      color: @PrimaryCobalt60;
    }

    circle {
      stroke: @PrimaryCobalt60;
    }
  }

  .title {
    font-weight: 500;
  }
}
