/*
 * colors.less
 */
.reasonsLabel {
  margin-top: 20px;
}
.reasonsLabel .mainTitle {
  font-size: 14px;
  font-weight: 700;
  color: #4c5892;
}
.reasonsLabel .subTitle {
  font-size: 12px;
  font-weight: 400;
  color: #a5abc9;
}
.reasonsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.reasonsButtons .button {
  white-space: normal;
  padding: 8px !important;
  background: #f3f4f8;
  border-radius: 2px;
  color: #4c5892;
  font-size: 12px;
  font-weight: 400;
  font-family: Graphik, sans-serif;
  border: 1px solid #f3f4f8;
}
.reasonsButtons .button:hover,
.reasonsButtons .button:active,
.reasonsButtons .button:focus {
  background: #f3f4f8;
}
.reasonsButtons .buttonSelected {
  border-color: #4864c9;
}
