/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.analytics-view {
  display: flex;
}
.analytics-view .analytics {
  flex-grow: 1;
}
.analytics-view .analytics h2 {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 21px;
  color: #1f2e77;
  letter-spacing: 0;
}
.analytics-view .analytics .pipeline-body-container {
  padding: 25px;
}
.analytics-view .analytics.offer-analytics .pipeline-body-container {
  padding-left: 0px;
  padding-right: 0px;
}
.analytics-view .analytics .section {
  border: 1px solid #dae0f4;
  border-radius: 2px;
  background-color: white;
  padding: 29px 24px;
  margin-bottom: 42px;
}
.analytics-view .analytics .section h3 {
  margin-top: 4px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #709dff;
}
.analytics-view .analytics .analytics-pipeline-container {
  margin-top: 40px;
}
.analytics-view .analytics .cohorts-body-container {
  padding: 25px;
}
.analytics-view .analytics.offer-analytics .cohorts-body-container {
  padding-left: 0px;
  padding-right: 0px;
}
.analytics-view .analytics .analytics-cohorts-container {
  margin-top: 50px;
}
.analytics-view .analytics .skip-ratio-body-container {
  padding: 25px;
}
.analytics-view .analytics.offer-analytics .skip-ratio-body-container {
  padding-left: 0px;
  padding-right: 0px;
}
.analytics-view .analytics .analytics-skip-ratio-container {
  margin-top: 50px;
}
