/*
 * colors.less
 */
.emailSkip {
  display: grid;
  grid-template-columns: fit-content(200px) auto 1fr;
  gap: 8px;
  height: 20px;
}
.emailPreviewLabel {
  font-family: Graphik;
  color: #1f2e77;
  font-weight: 500;
}
.emailPreview {
  font-size: 13px;
  font-family: Graphik;
}
.emailSubject,
.emailBody {
  width: 100%;
  color: #1f2e77;
  font-weight: 400;
  font-family: Graphik;
}
.emailBodyTextarea {
  width: 100%;
  min-height: fit-content;
  height: 150px;
  max-height: 200px;
  resize: none;
}
.emailPreview {
  padding: 0px 4px;
  min-width: 500px;
}
.emailSkipOptions {
  margin-top: 2px;
}
.templateAndPreview {
  padding-left: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
}
.templateAndPreview > span {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #1f2e77;
  font-family: Graphik;
}
.templateAndPreview .templateSelectorTrigger {
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #7982ad;
  font-family: Graphik;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.templateAndPreview .templateSelectorTrigger:hover {
  color: #1f2e77;
}
.templateDropdown {
  overflow: hidden;
  width: inherit;
}
.documentShow {
  cursor: pointer;
  flex-shrink: 0;
}
.customizeEmail {
  color: #1f2e77;
  font-size: 12px;
  font-family: Graphik;
  height: 20px;
  line-height: 20px;
  text-align: right;
}
.customizeEmail span {
  cursor: pointer;
}
.customizeEmail span:hover {
  text-decoration: underline;
}
.skipButtons {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}
.skipButtons > button {
  flex-grow: 1;
}
.divider {
  margin: 1.2rem 0;
  border-top: 1px solid #dae0f4;
  width: 80%;
  margin-left: 10%;
}
.disabledButton {
  background-color: #b6c1e9 !important;
  color: white;
  cursor: default !important;
}
