/*
 * colors.less
 */
.superPipelineSettingsContainer {
  background: white;
  border-radius: 8px;
  padding: 32px;
}
.superPipelineSettingsContainer .description {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  margin-bottom: 24px;
}
.superPipelineSettingsContainer .infoBox {
  background: #f1f6ff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
}
.superPipelineSettingsContainer .infoBox .infoBoxTitle {
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.superPipelineSettingsContainer .infoBox .infoBoxDescription {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.superPipelineSettingsContainer .scopedPipelineInfoBox {
  margin-bottom: 24px;
}
.superPipelineSettingsContainer .stepsTitle {
  font-family: Gilroy;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77 !important;
  margin-bottom: 16px !important;
}
.superPipelineSettingsContainer .stepsTable {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.superPipelineSettingsContainer .stepsTable .stepContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.superPipelineSettingsContainer .stepsTable .stepContainer .stepNumber {
  background: #4864c9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  padding: 7px 0px 5px 0px;
  color: white;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}
.superPipelineSettingsContainer .stepsTable .stepContainer .newStepButton,
.superPipelineSettingsContainer .stepsTable .stepContainer .deleteSubstepButton {
  cursor: pointer;
}
.superPipelineSettingsContainer .stepsTable .stepContainer .stepValue {
  flex-grow: 1;
  background-color: #f6f7fc;
  padding: 8px 12px;
  border-radius: 8px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #1f2e77;
}
.superPipelineSettingsContainer .stepsTable .stepExtension {
  margin-left: 44px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  padding: 16px 24px;
}
.superPipelineSettingsContainer .stepsTable .stepExtension .substepActions {
  margin-top: 12px;
}
.superPipelineSettingsContainer .stepsTable .stepExtension > div:last-of-type .newSubstepContainer {
  margin-bottom: 0px;
}
.superPipelineSettingsContainer .stepsTable .stepExtension > div:last-of-type .newSubstepSpacer {
  height: 32px;
}
.superPipelineSettingsContainer .stepsTable .substepContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 4px;
}
.superPipelineSettingsContainer .stepsTable .substepContainer .substepNumber {
  background: #f6f7fc;
  color: #1f2e77;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  padding: 7px 0px 5px 0px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}
.superPipelineSettingsContainer .stepsTable .substepContainer .newStepButton,
.superPipelineSettingsContainer .stepsTable .substepContainer .deleteSubstepButton {
  cursor: pointer;
}
.superPipelineSettingsContainer .stepsTable .substepContainer .substep {
  flex-grow: 1;
  border: 1px solid #dae0f4;
  padding: 5px 10px;
  border-radius: 8px;
  color: #1f2e77;
}
.superPipelineSettingsContainer .actionButton {
  padding: 0px 8px;
}
.superPipelineSettingsContainer .panel {
  width: fit-content;
}
.superPipelineSettingsContainer .panel .panelOption {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.superPipelineSettingsContainer .panel .panelOption .panelOptionIcon,
.superPipelineSettingsContainer .panel .panelOption i {
  width: 16px;
  color: #a5abc9;
}
.newSubstepNameInput {
  width: 100%;
  margin-bottom: 12px;
}
.scopeSelector {
  margin-top: 4px;
  border-radius: 8px !important;
  display: block;
  box-shadow: 0px 2px 4px #1f2e770f !important;
  margin-bottom: 12px;
}
.scopeSelector input {
  border-radius: 8px !important;
  border: 1px solid #dae0f4 !important;
  width: 100% !important;
}
.departmentsCheckboxContainer {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
}
.departmentsCheckboxContainer span {
  margin-top: 2px;
}
.warning {
  padding: 12px 24px;
  background-color: #fcc5cc;
  color: #e45a6b;
  border-radius: 8px;
  font-weight: 500;
}
.newSubstepNameLabel,
.stepsLabel {
  margin-bottom: 6px;
  font-weight: 500;
}
.subStepEditionRawContainer {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.subStepEditionRawContainer > div {
  margin-bottom: 0px;
}
.subStepEditionRawContainer .subStepEditionInput {
  flex-grow: 1 !important;
}
.newSubstepContainer {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: #a5abc9;
  border: 2px dashed #d2d5e4;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  margin: 0px 44px 4px 44px;
  opacity: 0;
  transition: opacity 200ms;
}
.newSubstepContainer.newSubstepVisible {
  opacity: 1;
}
.newSubstepContainer:hover {
  background: #f3f4f8;
}
.newSubstepSpacer {
  height: 40px;
}
