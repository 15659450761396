@import 'src/less/colors';

.atsCardTitle {
  border-radius: 4px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  align-items: baseline;
}

.ats-status-banner {
  margin-top: 20px;
  padding: 16px;
  background-color: @SecondarySkyBlue10;
  border: 1px solid @SecondarySkyBlue20;

  img {
    border-radius: 3px;
    margin-right: 8px;
  }

  h3 {
    margin: 0px;
    margin-right: 8px;
    color: @PrimaryCobalt;
  }

  .last-sync {
    color: @PrimaryCobalt60;
  }

  :global(.ats-url) {
    margin: 0 8px;
    text-decoration: underline;
  }
}

.addButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.add-to-ats-button {
  margin: 0px 0px 0px auto;
}
