/*
 * colors.less
 */
.trigger {
  display: inline-flex;
  padding: 0;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.trigger:hover .label {
  text-decoration: underline;
}
.trigger .label {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #4864c9;
}
.trigger .arrow {
  font-size: 16px;
  line-height: 16px;
  color: #4864c9;
}
