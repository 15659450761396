/*
 * colors.less
 */
.dropdownMenuContainer {
  z-index: 12;
}
.archiveReasonsMenuContainer {
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 8px;
  padding: 0px;
}
.archiveReasonsMenuContainer .archiveReasonsMenu {
  padding: 24px;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer {
  margin: 12px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer .skipReason {
  flex-grow: 1;
  border-radius: 8px;
  padding: 8px;
  color: #1f2e77;
  background-color: white;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  border: 1px solid #dae0f4;
  cursor: pointer;
  width: calc(50% - 4px);
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer .skipReason i {
  color: #a5abc9;
  font-size: 16px;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer .skipReason.selected,
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer .skipReason:hover {
  border: 1px solid #4864c9;
  background-color: #e2ebff;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer .skipReason.selected i,
.archiveReasonsMenuContainer .archiveReasonsMenu .archiveReasonsContainer .skipReason:hover i {
  color: #4864c9;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .showMoreBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: fit-content;
  margin: 0px 0px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #4864c9;
  cursor: pointer;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .reasonInputContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .reasonInputContainer .reasonInput {
  flex-grow: 1;
}
.archiveReasonsMenuContainer .archiveReasonsMenu .reasonInputContainer .reasonInput input {
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  color: #1f2e77;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.archiveReasonsMenuContainer .archiveReasonsFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 16px 0px #1f2e7726;
  padding: 16px 24px 16px 24px;
}
