@import 'src/less/colors';

.label {
  margin-bottom: 2px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: @PrimaryCobalt;

  &.secondary {
    color: @PrimaryCobalt60;
    font-size: 12px;
  }
}
