/*
 * colors.less
 */
.job-posting-form-selector .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}
.job-posting-form-selector .buttons-container {
  margin-top: 60px;
  text-align: right;
}
.ui.form.application-form-display {
  max-width: 600px;
}
.ui.form.application-form-display h5 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #4c5892;
}
.ui.form.application-form-display .field.inline.default-input label {
  margin-right: 0;
  width: 200px;
  font-weight: 500;
  opacity: 1;
  color: #a5abc9;
}
.ui.form.application-form-display .field.inline.default-input .ui.input {
  width: calc(100% - 200px);
  opacity: 1;
}
.ui.form.application-form-display .field.inline.default-input.file-input input {
  overflow: hidden;
}
.ui.form.application-form-display .cover-letter-separator {
  font-weight: 600;
  padding-left: 200px;
  width: 100%;
  margin-bottom: 1em;
  text-align: center;
}
.ui.form.application-form-display .default-cover-letter-text {
  margin-left: 200px;
}
.ui.form.application-form-display .default-cover-letter-text textarea {
  min-height: 200px;
}
.ui.form.application-form-display .application-form .application-form-divider-header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ui.form.application-form-display .application-form .application-form-field-container {
  margin-bottom: 14px;
}
.ui.form.application-form-display .application-form .application-form-field-container .application-form-title-container {
  opacity: 0.45;
  margin-bottom: 8px;
  font-family: Graphik;
  font-weight: 500;
  font-size: 1rem;
  color: #a5abc9;
}
.ui.form.application-form-display .application-form .application-form-field-container .field.inline.default-input .ui.input {
  width: 100%;
}
