@import 'src/less/colors.less';

.mainTitle {
  font-weight: 700;
  font-size: 26px;
  color: @PrimaryCobalt;
}

.content {
  padding: 24px;
}

.reasonsTable {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  border: none !important;
  thead th {
    color: @PrimaryCobalt60 !important;
    font-family: Graphik;
    font-size: 10px;
    font-weight: 500 !important;
    line-height: 13px;
    letter-spacing: 0px;
    background-color: transparent !important;
    border-bottom: 1px solid @SecondaryBlue20;
    padding-left: 0 !important;

    .actionColumn {
      min-width: 20px;
    }
  }

  tbody {
    td {
      border: none !important;
      padding: 0 !important;
      height: 56px !important;
      margin: 4px 0px !important;
    }
  }
}

.reasonName {
  color: @PrimaryCobalt;
  //styleName: Body 12/Medium 500;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.reasonCategory {
  //styleName: Body 12/Regular 400;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: @PrimaryCobalt60;
}

.reasonTags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.reasonActions {
  text-align: right !important;
  i {
    margin: 0px 8px;
    color: @PrimaryCobalt60;
  }
}

.tag {
  display: inline-block;

  margin-right: 4px;
  border-radius: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
  font-size: 12px;

  background-color: @SecondarySkyBlue20;
  color: @SecondarySkyBlue120;
}

.modalActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
