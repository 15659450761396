/*
 * colors.less
 */
.title {
  margin-top: 26px;
  margin-bottom: 16px;
  color: #1f2e77;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.attachmentType,
.attachmentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #4c5892;
}
.attachmentType :global(.edit-button),
.attachmentHeader :global(.edit-button) {
  background-color: transparent;
  color: #4c5892;
  padding: 0px 0px 2px 0px;
}
.attachmentType :global(.edit-button):hover,
.attachmentHeader :global(.edit-button):hover {
  background-color: transparent;
  color: #4c5892;
}
.attachmentType :global(.download-icon-container):focus-visible,
.attachmentHeader :global(.download-icon-container):focus-visible {
  outline-style: auto;
  outline-width: 1px;
}
.attachmentType :global(.download-icon-container) :global(.download-icon),
.attachmentHeader :global(.download-icon-container) :global(.download-icon) {
  color: transparent;
  stroke: #4c5892;
  cursor: pointer;
  margin-top: -4px;
}
.attachmentType :global(.download-icon-container) :global(.download-icon):hover,
.attachmentHeader :global(.download-icon-container) :global(.download-icon):hover {
  opacity: 1;
}
.attachmentType :global(.download-button),
.attachmentHeader :global(.download-button) {
  margin-top: -4px;
}
.attachmentType > i,
.attachmentHeader > i {
  margin-right: 10px;
  padding-bottom: 4px;
  font-weight: normal;
  font-size: 18px;
}
.attachmentType > span,
.attachmentHeader > span {
  margin-right: 0.5em;
}
.attachmentType .attachmentDate,
.attachmentHeader .attachmentDate {
  color: #7982ad;
  font-weight: 500;
  margin: 0px 8px 0px 4px;
}
.attachmentViewer {
  margin-bottom: 24px;
}
