/*
 * colors.less
 */
/*
 * typography.less
 */
body {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}
body .ui.input > input {
  font-family: Graphik, sans-serif !important;
}
input,
optgroup,
select,
textarea {
  font-family: Graphik, sans-serif;
}
h1,
.headline-1 {
  font-family: Gilroy, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}
h2,
.headline-2 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}
h3,
.headline-3 {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
h4,
.headline-4 {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
h5,
.headline-5 {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}
.body-semibold {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.body-medium {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.body-regular {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.caption-medium {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}
.caption {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1px;
}
.caption-2 {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
.reveal-search-modal.ui.modal > .header {
  padding: 12px 60px 12px 32px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reveal-search-modal.ui.modal > .header > .title {
  color: #3450b5;
}
.reveal-search-modal.ui.modal > .header .submit-button.ui.button {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding: 8px 16px;
  margin-left: 24px;
  margin-right: 24px;
}
.reveal-search-modal.ui.modal > .content {
  padding: 32px;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  background-color: #f8faff;
}
.reveal-search-modal.ui.modal > .content .column-1,
.reveal-search-modal.ui.modal > .content .column-2,
.reveal-search-modal.ui.modal > .content .column-3 {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 32px);
  margin-right: 32px;
}
.reveal-search-modal.ui.modal .anti-margin-collapse {
  height: 1px;
}
.reveal-filters-section > .title {
  color: #4864c9;
}
.reveal-filters-section .label {
  font-size: 13px;
  font-weight: 500;
  margin: 16px 0 12px 0;
}
.reveal-filters-section .sub-section {
  margin: 32px 0;
}
.reveal-filters-section .sub-section:first-child {
  margin-top: 8px;
}
.reveal-filters-section .sub-section:last-child {
  margin-bottom: 8px;
}
