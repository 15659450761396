/*
 * colors.less
 */
.background-timeline-experience:not(:first-child) {
  margin-top: 12px;
}
.background-timeline-experience {
  display: flex;
  flex-direction: row;
}
.background-timeline-experienceDetails,
.background-timeline-experienceBullet {
  display: flex;
}
.background-timeline-experienceDetails {
  flex-direction: column;
}
.background-timeline-experienceBullet {
  /*flex-direction: column;*/
  position: relative;
  z-index: 1;
}
.background-timeline-experience:not(:first-child) .background-timeline-experienceBullet:before {
  border-left: 2px solid #f3f3f5;
  /*border-left: 3px solid red;*/
  content: ' ';
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
.background-timeline-experience:not(:last-child) .background-timeline-experienceBullet:after {
  border-left: 2px solid #f3f3f5;
  /*border-left: 3px solid blue;*/
  content: ' ';
  position: absolute;
  top: 20px;
  left: calc(50% - 5px);
  bottom: -20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
.background-timeline-experienceTitle {
  /*font-size: 14px;*/
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-family: Graphik, sans-serif;
  color: #1f2e77;
}
.background-timeline-experienceDates {
  margin-top: 4px;
  line-height: 13px;
  color: #7982ad;
  font-family: Graphik, sans-serif;
  font-size: 12px;
}
.hsBulletPoint {
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 18px;
  background: white;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  border: 2px solid #f86e7f;
}
