@import 'src/less/colors';

.container {
  display: flex;
  align-items: center;
  margin: 0 12px;
  gap: 12px;

  .departmentsDropdownTrigger {
    width: 250px;
  }

  .missionDropdown:global(.ui.dropdown) {
    height: 40px;

    :global(.default.text) {
      color: @PrimaryCobalt40;
    }
  }
}
