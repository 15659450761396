/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * colors.less
 */
.ui.modal.application-form-edit-modal .content {
  min-height: 40vh;
  max-height: 70vh;
  overflow-y: auto;
}
.ui.modal.application-form-edit-modal .content::after {
  opacity: 0;
}
.ui.modal.application-form-edit-modal .ui.input {
  width: 100%;
}
.ui.modal.application-form-edit-modal input::placeholder {
  font-family: Graphik;
  font-size: 14px;
}
.ui.modal.application-form-edit-modal input:disabled {
  font-family: Graphik;
  font-size: 14px;
}
.ui.modal.application-form-edit-modal h4 {
  font-family: Graphik !important;
  font-weight: 600 !important;
  font-size: 14px;
}
.ui.modal.application-form-edit-modal h4 .grades-dropdown-container {
  padding-left: 15px;
}
.ui.modal.application-form-edit-modal h5 {
  font-family: Graphik !important;
  font-weight: normal !important;
  font-size: 14px;
}
.ui.modal.application-form-edit-modal h5 .grades-dropdown-container {
  padding-left: 15px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .link-like-button {
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .link-like-button:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .grade-field-grade-selector {
  margin-bottom: 5px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header .application-form-editor-field-settings {
  display: flex;
  align-items: center;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header .application-form-editor-field-settings .field-settings-edit-preview-container {
  display: inline-block;
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  margin-right: 10px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header .application-form-editor-field-settings .field-settings-edit-preview-container:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header .application-form-editor-field-settings .field-settings-dropdown-container {
  height: 18px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header .application-form-editor-field-settings .field-settings-dropdown-container .ui.dropdown img {
  margin-top: 3px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header .application-form-editor-field-settings .field-settings-dropdown-container .ui.dropdown .menu {
  margin-top: 6px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header h4 {
  margin-bottom: 0;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-header h4 .grades-dropdown-container {
  padding-left: 15px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .field-edition-light-text {
  opacity: 0.5;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .field-edition-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-bottom: 10px;
  width: 550px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .field-edition-element-text {
  width: 100px;
  flex-shrink: 0;
  opacity: 0.5;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .field-edition-element-value {
  width: 100%;
  color: #1f2e77;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .ui.dropdown.field-edition-input div.text {
  width: 100%;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .ui.dropdown.field-edition-input .selected.item {
  font-weight: 500;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor {
  width: 550px;
  padding-left: 100px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor .trash-button {
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor .trash-button img.trash-svg {
  opacity: 0.6;
  width: 10px;
  height: 13px;
  padding-bottom: 3px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor .scorecard-subfield-edition-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-bottom: 10px;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor .scorecard-subfield-edition-element .subfield-edition-element-text {
  width: 100px;
  opacity: 0.5;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor .add-subfield-container {
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
  text-align: left;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .application-form-editor-field .application-form-editor-field-edition .scorecard-subfields-editor .add-subfield-container:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .add-field-container {
  margin-top: 10px;
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  font-size: 15px;
  text-align: left;
}
.ui.modal.application-form-edit-modal .application-form-editor-fields-container .add-field-container:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.ui.modal.application-form-edit-modal .application-form-edit-modal__actions {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: space-between;
}
.ui.modal.save-application-form-template-modal .subheader {
  margin-bottom: 20px;
}
.ui.modal.save-application-form-template-modal .edit-title-container {
  margin-left: 30px;
  margin-top: 10px;
}
.ui.modal.save-application-form-template-modal .edit-title-container .field-edition-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-bottom: 10px;
}
.ui.modal.save-application-form-template-modal .edit-title-container .field-edition-element .field-edition-element-text {
  width: 100px;
  opacity: 0.5;
  flex-shrink: 0;
}
.ui.modal.save-application-form-template-modal .edit-title-container .field-edition-element .field-edition-input {
  width: 300px;
}
