/*
 * colors.less
 */
.profile-preview {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: #f6f7fc;
  overflow-y: auto;
}
.profile-preview .resume-section-container {
  width: 56.75%;
  min-height: 100%;
}
.profile-preview .resume-section-container .profile-background {
  padding-bottom: 20px;
}
.profile-preview .contact-section-container {
  width: 40%;
}
.profile-preview .contact-section-container .success {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-preview .contact-section-container .success .icon-container {
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
  background-color: rgba(249, 200, 202, 0.2);
  color: #f9c8ca;
}
.profile-preview .contact-section-container .success .icon-container i.icon {
  margin-right: 3px;
  margin-bottom: 3px;
}
.profile-preview .contact-section-container .success h3 {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  color: #1f2e77;
}
.profile-preview .ui.segment.resume-data-card,
.profile-preview .ui.segment.resume-data-card-placeholder {
  margin: 0px 0px 0px 0px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
}
.profile-preview .ui.segment.resume-data-card-placeholder {
  display: flex;
  align-items: center;
}
.profile-preview .regular-profile-view .ui.segment.resume-data-card,
.profile-preview .regular-profile-view .ui.segment.resume-data-card-placeholder {
  border: none;
  border-radius: 0px;
}
.profile-preview .contact-section {
  margin: 20px;
  margin-left: 0;
}
.profile-preview .contact-section .timeline-card-mail-wrapper .content {
  min-width: 0 !important;
}
.profile-preview .contact-section .segment-custom-padding {
  padding: 16px 0;
}
.profile-preview .profile-job-state-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 32px;
  border: 1px solid #4864c9;
  background-color: #dee2f7;
}
