@import 'src/less/colors';

.modalDescription {
  font-family: Graphik;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1em;
  letter-spacing: 0px;
  color: @PrimaryCobalt60;
}

.renameStepsModalContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    gap: 34px;
    margin-bottom: -6px;

    div:first-of-type {
      width: 40%;
    }

    .sectionName {
      margin-bottom: 4px;
    }
  }

  .stepContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .originalStep {
      width: 40%;
      border-radius: 8px;
      padding: 8px 12px 8px 12px;
      background-color: @SecondaryBlue05;
      color: @PrimaryCobalt80;
      border: 1px solid @SecondaryBlue20;
      padding: 10px 12px;
    }

    .newStepContainer {
      flex-grow: 1;
    }
  }
}
