.buttonWithDropdown {
  display: flex;
  align-items: center;
}

.button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;
}
