/*
 * colors.less
 */
.salary-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  height: 38.5px;
}
.salary-section .select-not-editing-container {
  display: inline-block;
}
.salary-section .select-not-editing-container .select-not-editing-inner {
  width: 68px;
  padding-right: 0px;
}
.salary-section .select-not-editing-container .select-not-editing-inner.error {
  border-color: #ff00007b;
}
.salary-section .inline-separator {
  margin-right: 10px;
  margin-left: 10px;
  vertical-align: middle;
}
.salary-section .custom-input:hover .label,
.salary-section .custom-input:hover input {
  border-color: #6d83d4;
}
.salary-section .custom-input input {
  border-right: none !important;
}
.salary-section .custom-input .label {
  width: 18px;
  border-left: none !important;
  padding-left: 2px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
}
.salary-section .custom-input input:focus {
  border-color: #4864c9;
  border-width: 1px;
}
.salary-section .custom-input input:focus + .label {
  border-color: #4864c9;
  border-width: 1px;
}
.salary-section .currency {
  flex: 1;
  display: inline-block;
  margin-left: 15px;
}
.salary-section .currency .select-not-editing-container {
  display: inline-block;
}
.salary-section .currency > div > div {
  width: auto;
}
.salary-section .custom-select {
  display: inline-block;
}
.money-input .label {
  width: 18px;
  border-left: none !important;
  padding-left: 2px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
}
