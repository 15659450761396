/*
 * colors.less
 */
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .leftPan,
.content .rightPan {
  flex: 1 1;
}
.content .leftPan .panTitle,
.content .rightPan .panTitle {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1f2e77;
}
.content .leftPan .subTitle,
.content .rightPan .subTitle {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4c5892;
  margin: 16px 0px 8px;
}
