@import 'src/less/colors';

.hiresweetDetailsContainer {
  margin-top: 24px;
  color: @PrimaryCobalt;

  &.plugin {
    margin-left: 12px;
    margin-right: 12px;
  }

  .tableHeader {
    border: 1px solid @SecondarySkyBlue20;
    background-color: @SecondarySkyBlue05;
  }

  .tableCell {
    border: 1px solid @SecondarySkyBlue20;
    padding: 4px 8px;
  }

  .pluginOffer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pluginOfferValue {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .detailsCategory {
    margin: 24px 0px;
    h5 {
      margin: 0;
    }

    .categoryColumns.plugin {
      display: block;
    }

    .categoryColumns:not(.plugin) {
      display: flex;
      gap: 48px;
    }

    .categoryItems {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .categoryItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 48px;

        .itemName {
          text-transform: capitalize;
        }
      }
    }
  }
}
