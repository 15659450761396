/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.campaigns-list {
  margin-top: 24px;
  padding: 0 40px;
}
.campaigns-list .archived-campaigns-link-box {
  text-align: center;
  margin: 30px auto;
}
.campaigns-list .campaigns-title {
  margin-bottom: 8px;
  line-height: 22px;
  font-family: Gilroy;
  font-weight: 700;
  font-size: 18px;
  color: #1f2e77;
}
.campaigns-list .campaigns-section {
  margin-top: 16px;
  margin-bottom: 142px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dae0f4;
}
.campaigns-list .campaigns-section .campaigns-header {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 11px 0 24px;
}
.campaigns-list .campaigns-section .campaigns-header h3 {
  color: #1f2e77;
  margin: 0;
}
.campaigns-list .campaigns-section .campaigns-header .campaigns-header-settings {
  display: inline-flex;
  align-items: center;
  gap: 16px;
}
.campaigns-list .campaigns-list-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}
.campaigns-list .campaigns-table-top-bar {
  text-align: right;
}
table.ui.table.campaigns-table {
  border: none;
}
table.ui.table.campaigns-table thead th,
table.ui.table.campaigns-table tbody td {
  padding: 16px 10px 16px 0;
}
table.ui.table.campaigns-table tbody tr td {
  text-align: left;
}
table.ui.table.campaigns-table thead,
table.ui.table.campaigns-table thead tr,
table.ui.table.campaigns-table thead tr th {
  border: none;
}
table.ui.table.campaigns-table tbody,
table.ui.table.campaigns-table tbody tr {
  border: none;
}
table.ui.table.campaigns-table tbody td {
  border-top: 1px solid #f3f4f8;
  border-bottom: none;
}
table.ui.table.campaigns-table tbody td:first-child {
  border-left: none;
}
table.ui.table.campaigns-table tbody td:last-child {
  border-right: none;
}
table.ui.table.campaigns-table tbody tr:last-child td {
  border-bottom: none;
}
table.ui.table.campaigns-table thead th {
  font-family: Graphik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #a5abc9;
}
table.ui.table.campaigns-table tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
table.ui.table.campaigns-table tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.campaigns-table .campaign-table-headers {
  border-top: 1px solid #e8e8e8;
}
table.ui.table.campaigns-table .campaign-table-headers tr {
  background-color: #f8faff !important;
}
table.ui.table.campaigns-table .campaign-table-headers tr th {
  padding-left: 24px;
  padding-right: 24px;
  color: #7982ad;
  font-weight: 500;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
}
table.ui.table.campaigns-table .campaign-table-headers .button-header .header-name {
  padding-top: 2px;
  display: inline-block;
}
table.ui.table.campaigns-table .campaign-table-headers .button-header:hover .header-name {
  text-decoration: underline;
}
table.ui.table.campaigns-table .campaign-table-headers .button-header.sort .icon-container {
  vertical-align: top;
  display: inline-block;
}
table.ui.table.campaigns-table .campaign-table-headers .button-header.sort.increasing .icon-container {
  padding-top: 1px;
}
table.ui.table.campaigns-table .campaign-table-headers .button-header.sort.decreasing .icon-container {
  padding-top: 2px;
}
table.ui.table.campaigns-table .campaign-row td,
table.ui.table.campaigns-table .folder-row td {
  padding-left: 24px;
  padding-right: 24px;
}
table.ui.table.campaigns-table .campaign-row .campaign-actions,
table.ui.table.campaigns-table .folder-row .campaign-actions {
  display: flex;
  justify-content: right;
  align-items: center;
  visibility: visible;
}
table.ui.table.campaigns-table .campaign-row .campaign-actions .campaign-more-action-btn,
table.ui.table.campaigns-table .folder-row .campaign-actions .campaign-more-action-btn {
  padding: 5px 0px;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 2px solid transparent;
  border-radius: 25%;
}
table.ui.table.campaigns-table .campaign-row .campaign-actions .campaign-more-action-btn:hover,
table.ui.table.campaigns-table .folder-row .campaign-actions .campaign-more-action-btn:hover {
  border: 2px solid #b6c1e9;
}
table.ui.table.campaigns-table .campaign-row .campaign-actions .placeholder-button,
table.ui.table.campaigns-table .folder-row .campaign-actions .placeholder-button {
  visibility: visible;
  width: 84px;
}
table.ui.table.campaigns-table .campaign-header {
  display: flex;
  align-items: center;
}
table.ui.table.campaigns-table .campaign-header.in-folder {
  margin-left: 30px;
}
table.ui.table.campaigns-table .campaign-header-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.campaigns-table .campaign-header-title:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #4864c9;
}
table.ui.table.campaigns-table .campaign-row,
table.ui.table.campaigns-table .folder-row {
  cursor: pointer;
}
table.ui.table.campaigns-table .campaign-row:hover,
table.ui.table.campaigns-table .folder-row:hover,
table.ui.table.campaigns-table .campaign-row.active,
table.ui.table.campaigns-table .folder-row.active {
  background-color: #f8faff !important;
}
table.ui.table.campaigns-table .campaign-row:hover .campaign-actions,
table.ui.table.campaigns-table .folder-row:hover .campaign-actions,
table.ui.table.campaigns-table .campaign-row.active .campaign-actions,
table.ui.table.campaigns-table .folder-row.active .campaign-actions {
  visibility: visible;
}
table.ui.table.campaigns-table .campaign-row td,
table.ui.table.campaigns-table .folder-row td {
  white-space: nowrap;
}
table.ui.table.campaigns-table .campaign-row td:first-child,
table.ui.table.campaigns-table .folder-row td:first-child {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.ui.table.campaigns-table .campaign-icon {
  margin-right: 10px;
  color: #a5abc9;
}
table.ui.table.campaigns-table .folder-row .folder-header {
  max-width: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
}
table.ui.table.campaigns-table .folder-row .folder-header .folder-header-title {
  margin-left: 8px;
  max-width: 76%;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
table.ui.table.campaigns-table .folder-row .folder-header .folder-empty-title {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  margin-left: 12px;
}
table.ui.table.campaigns-table .folder-row .folder-actions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 4px;
}
table.ui.table.campaigns-table .folder-row .folder-actions .ui.dropdown {
  padding: 5px 0px;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 2px solid transparent;
  border-radius: 25%;
}
table.ui.table.campaigns-table .folder-row .folder-actions .ui.dropdown i {
  border: none !important;
}
table.ui.table.campaigns-table .folder-row .folder-actions .ui.dropdown > .action-folder-title {
  padding: 0.5em 0.9em 0.5em 0.3em !important;
}
table.ui.table.campaigns-table .folder-row .folder-actions .ui.dropdown > .action-folder-title i {
  padding: 0px !important;
}
table.ui.table.campaigns-table .folder-row .folder-actions .ui.dropdown:hover {
  border: 2px solid #b6c1e9;
}
.ui.dropdown.folder-actions-dropdown .menu > .item {
  padding: 0.31em 1.14em 0.31em 0.8em !important;
}
.ui.popup.campaigns-more-actions-popup,
.ui.dropdown.folder-actions-dropdown {
  color: #1f2e77;
  padding: 0px;
}
.ui.popup.campaigns-more-actions-popup .action-folder-title,
.ui.dropdown.folder-actions-dropdown .action-folder-title {
  font-family: Graphik !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  text-align: left;
  color: #1f2e77;
  vertical-align: middle;
  padding: 0.5em 0.9em 0.5em 0.3em !important;
  cursor: pointer;
  display: flex !important;
  align-items: center;
}
.ui.popup.campaigns-more-actions-popup .action-folder-title i,
.ui.dropdown.folder-actions-dropdown .action-folder-title i {
  margin-right: 4px;
  color: #a5abc9;
}
.ui.popup.campaigns-more-actions-popup .action-folder-title:hover,
.ui.dropdown.folder-actions-dropdown .action-folder-title:hover {
  background-color: #edf0fa;
}
.ui.popup.campaigns-more-actions-popup .action-folder-title.disabled,
.ui.dropdown.folder-actions-dropdown .action-folder-title.disabled {
  color: #a5abc9 !important;
  background-color: #f6f7fc;
  cursor: default;
}
.ui.popup.campaign-more-actions-popup {
  padding: 0px;
}
.ui.popup.campaign-more-actions-popup .campaign-more-actions {
  max-width: 200px;
}
.ui.popup.campaign-more-actions-popup .campaign-more-actions .more-actions-category {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  margin: 8px 12px;
}
.ui.popup.campaign-more-actions-popup .campaign-more-actions .more-actions-folder {
  cursor: pointer;
  padding: 0.6em 1.14em !important;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.ui.popup.campaign-more-actions-popup .campaign-more-actions .more-actions-folder span {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ui.popup.campaign-more-actions-popup .campaign-more-actions .more-actions-folder svg,
.ui.popup.campaign-more-actions-popup .campaign-more-actions .more-actions-folder i {
  margin-right: 8px;
  color: #a5abc9;
}
.ui.popup.campaign-more-actions-popup .campaign-more-actions .more-actions-folder:hover {
  background-color: #edf0fa;
}
.rename-folder-modal-actions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
