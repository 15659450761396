/*
 * utils.less
 */
/*
 * colors.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * flex.less
 */
.sweetappContainer {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  background-color: #f8faff;
}
.sweetappContainer .contentContainer {
  grid-row: 2;
  overflow: auto;
}
.sweetappContainer .headerContainer {
  grid-row: 1;
}
.sweetappContainer .topHeader {
  width: 100%;
  height: 64px;
  padding: 0 40px;
  background: white;
  border-bottom: 1px solid #d2d5e4;
}
.sweetappContainer .topHeader.classic {
  padding-left: 0;
  padding-right: 70px;
}
.sweetappContainer .topHeader.classic :global(.animated-logo-container) {
  width: 130px;
  margin: 0 22px;
}
.sweetappContainer:not(:global(.win)) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.sweetappContainer:not(:global(.win)) ::-webkit-scrollbar {
  display: none;
}
