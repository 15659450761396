.mail-account-connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
  width: 100%;
  margin: 0px 0px 10px 0px;
  border: 1px solid #dfdfe6;
  border-radius: 3px;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  color: #2d3f51;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
}
.mail-account-connect:last-child {
  margin-bottom: 0px;
}
.mail-account-connect .mail-account-image {
  margin: 12px;
}
.mail-account-connect .spacer {
  flex-grow: 1;
}
.mail-account-connect .mail-account-switch {
  background: #e8e8e8;
  border: none;
  border-radius: 20px;
  width: 82px;
  height: 40px;
  margin-right: 30px;
  float: right;
}
.mail-account-connect .mail-account-switch .switch-toggle {
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  top: 4px;
  left: 4px;
}
