@import 'src/less/colors';

.connector {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 12px;
  gap: 12px;
  height: 64px;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      transition: border-color ease 300ms;
      border-color: @SecondaryBlue60;
    }
  }

  .logoContainer {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 30px;
    }
  }

  .cta {
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt;

    &.connected {
      color: @PrimaryCobalt60;
    }
  }

  .check {
    flex: 1;
    text-align: right;
    font-size: 40px;
    color: @SecondaryBlue40;
  }
}
