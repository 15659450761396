.userName {
  font-family: Graphik;
  font-weight: 500;
}
.quotaEditor {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.quotaEditor span {
  white-space: nowrap;
  margin-right: 5px;
}
