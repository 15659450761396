/*
 * colors.less
 */
.panel {
  padding: 12px;
  background-color: #f8faff;
  border-radius: inherit;
}
.button {
  width: 100%;
  margin-top: 8px;
}
