/*
 * colors.less
 */
:global(.ui.toggle.checkbox).toggle {
  height: 16px;
  min-height: 0px;
  width: 28px !important;
}
:global(.ui.toggle.checkbox).toggle input {
  width: 28px;
  height: 16px;
}
:global(.ui.toggle.checkbox).toggle label {
  height: 16px;
  min-height: 0px;
  padding-left: 36px;
  padding-top: 0px;
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
:global(.ui.toggle.checkbox).toggle label:before {
  width: 28px;
  height: 16px;
  background: #b6c1e9;
}
:global(.ui.toggle.checkbox).toggle label:after {
  height: 12px;
  width: 12px;
  border: none;
  box-shadow: none;
  margin-top: 2px;
  margin-left: 2px;
  background: white;
  box-shadow: none !important;
}
:global(.ui.toggle.checkbox).toggle input:checked ~ label {
  color: #1f2e77 !important;
}
:global(.ui.toggle.checkbox).toggle input:checked ~ label:after {
  left: 12px !important;
}
