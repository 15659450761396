.separator {
  position: absolute;
  z-index: 2;
  top: 25%;
  left: calc(50% - 1px);
  width: 1px;
  height: 50%;
  background-color: white;
}

.pieChartSection {
  position: absolute;
  z-index: 1;
  border-radius: 100%;
  inset: 0;
  mask: radial-gradient(farthest-side, transparent 70%, white calc(70% + 1px));
}
