.wrapper {
  padding: 30px;

  .content {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textAndSnippets {
    width: 45vw;
    height: 600px;
    background-color: #ebebeb;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}