@import 'src/less/colors';

.wrapper {
  background-color: @SecondarySkyBlue10;
  padding: 15px;
  margin: 10px 0;
  font-family: 'Graphik';

  .application {
    font-weight: 400;
    font-size: 12px;
    color: @PrimaryCobalt60;
    display: flex;
    align-items: center;
    gap: 5px;

    .icon {
      padding: 5px 4px 3px 4px;
      width: 24px;
      height: 24px;
      font-size: 16px;
      line-height: 16px;
      border-radius: 100%;
      background-color: @SecondaryBlue120;
      color: white;
    }

    .bold {
      color: @PrimaryCobalt;
      font-weight: 500;
    }
  }

  .message {
    margin-top: 10px;
    background: #ffffff;
    border-radius: 8px;
    color: @PrimaryCobalt;
    font-weight: 400;
    font-size: 12px;
    padding: 5px 10px;
  }

  .explanation {
    margin-top: 8px;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: @PrimaryCobalt60;

    .link {
      color: @PrimaryCobalt60;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: @PrimaryCobalt80;
      }
    }
  }
}
