/*
 * colors.less
 */
.header {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0px 0px 0px 4px;
  border-bottom: 1px solid #dae0f4;
  background-color: #f8faff;
}
.header .column {
  font-family: Graphik;
  font-size: 12px;
  color: #7982ad;
  font-weight: 500;
}
