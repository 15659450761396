/*
 * colors.less
 */
div.providersSettings:global(.settings) h2 {
  color: #1f2e77;
  margin-bottom: 5px;
}
div.providersSettings:global(.settings) .section {
  margin-top: 20px;
}
.providersGrid {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
}
.providersGrid :global(.integration-link) {
  width: 100%;
}
