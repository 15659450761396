@import 'src/less/colors';

.container {
  position: relative;
  left: 0;
  top: 0px;
  width: 24px;
  height: 24px;
  background: @SecondaryBlue10;
  border-radius: 50%;
  border: 2px solid white;

  &.isStarred {
    background-color: @WarningColor;
  }
}

.favoriteButton {
  padding: 0;
  font-size: 13px;
  line-height: 16px;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -50%);

  color: @PrimaryCobalt;

  &.marketAsFavorite {
    color: white;
    display: inline;
  }
}
