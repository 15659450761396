@import 'src/less/colors';

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 313 / 135;
  border-radius: 8px;
  margin: 24px;
  margin-bottom: 0;
  background: linear-gradient(to bottom, @SecondarySkyBlue10, transparent);
  padding: 8px;

  .avatars {
    display: flex;

    .avatar {
      box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
      width: 64px;
      aspect-ratio: 1;
      border-radius: 64px;
      background-color: white;
      font-family: Gilroy;
      font-size: 18px;
      font-weight: 700;
      line-height: 64px;
      text-align: center;
      color: @SecondaryBlue;

      &:not(:first-child) {
        margin-left: -8px;
      }
    }
  }
}
