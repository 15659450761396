.missions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;

  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 500;
  min-width: 0;

  .missionSelector {
    flex-grow: 1;
    min-width: 0;

    &:has(.onlyTitle) {
      border: none;
    }
  }

  .stageSelector {
    flex-shrink: 2;
  }

  .missionName {
    padding: 7px 0px;
    height: 32px;
    line-height: 19px;
    color: #4864c9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;

    > span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 5px;
    }
  }

  .showMoreMissions {
    margin-left: auto;
    color: #7982ad;
    cursor: pointer;
    font-size: 12px;

    i {
      margin-left: 8px;
      font-size: 15px;
    }
  }
}

.missionStateContainer {
  align-items: center;

  :global(.mission-pipeline-dropdown) {
    right: 0;
    top: 35px;
    border: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 19px;
    color: #7982ad;
    cursor: pointer;

    // Ensures text does not wrap if it becomes too small
    min-width: max-content;
    > div {
      padding-left: 6px;
      display: flex;
      align-items: center;
    }
  }

  > div {
    display: flex;
    align-items: center;

    i:first-child {
      font-size: 1.33em;
      margin-top: -3px;
    }
  }
}

.missionSelectorContainer  {
  display: flex !important;
  align-items: center;
  min-width: 0;
}

.pluginPopup:global(.ui.popup) {
  max-width: 300px !important;
  min-width: 300px !important;
}
