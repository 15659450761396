/*
 * colors.less
 */
.side-panel {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.side-panel .side-panel-dimmer {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  height: 100%;
  flex: 1;
}
.side-panel .side-panel-panel {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.side-panel .side-panel-header {
  padding: 12px 16px;
  color: white;
  background-color: #1f2e77;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-panel .side-panel-header h4 {
  margin: 0;
}
.side-panel .side-panel-header i {
  color: #a5abc9;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
.side-panel .side-panel-header i:hover {
  color: white;
}
.side-panel .side-panel-content {
  flex: 1;
  background-color: #f8faff;
  padding: 24px;
  overflow-y: auto;
}
.side-panel .side-panel-content ::-webkit-scrollbar {
  width: 10px;
}
.side-panel .side-panel-footer {
  padding: 16px 24px;
  box-shadow: 0px -4px 17px rgba(186, 186, 186, 0.75);
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  position: relative;
}
