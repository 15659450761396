.job-posting-description .job-posting-description-section {
  white-space: pre-line;
  margin-bottom: 48px;
}
.job-posting-description .job-posting-description-section .job-posting-description-section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.job-posting-description .job-posting-description-section .job-posting-description-section-header .input-label {
  display: flex;
}
.job-posting-description .job-posting-description-section .job-posting-description-section-header .input-label i {
  margin-left: 4px;
  cursor: pointer;
}
.job-posting-description .job-posting-description-section .job-posting-description-section-header button {
  margin-bottom: 10px;
}
.job-posting-description .add-section-container {
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
}
.job-posting-description .video-container,
.job-posting-description .image-container {
  margin-left: 48px;
  margin-right: 48px;
}
.job-posting-description .input-element .image-container {
  margin-top: 24px;
}
.job-posting-description .player-wrapper {
  position: relative;
  padding-top: calc(720 / 1280 * 100%);
}
.job-posting-description .player-wrapper .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
