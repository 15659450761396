/*
 * colors.less
 */
.settings-header {
  display: flex;
}
.settings-header h1 {
  margin: 0;
}
.notification-section {
  margin-bottom: 30px;
  font-size: 12px;
}
.notification-section h4 {
  margin-bottom: 5px;
  color: #1f2e77;
}
.notification-section p {
  color: #7982ad;
  margin-bottom: 5px;
}
.notification-section .field {
  margin-bottom: 5px !important;
}
