/*
 * colors.less
 */
.ui.modal.new-sequence-modal .sequence-title-input.ui.input input {
  color: #4864c9;
  font-weight: 600;
}
.ui.modal.new-sequence-modal .content {
  background-color: #f8faff;
}
.ui.modal.new-sequence-modal .submit-action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ui.modal.new-sequence-modal .form-errors {
  margin-right: 16px;
  font-weight: 600;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  color: #f66f81;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ui.modal.new-sequence-modal .form-errors .details {
  font-size: 12px;
  text-align: left;
  white-space: pre-wrap;
  line-height: 16px;
}
.ui.modal.new-sequence-modal .form-errors .count {
  margin-left: 32px;
  white-space: nowrap;
}
.ui.modal.new-sequence-modal .actions {
  border-top: 1px solid #e2ebff;
  padding-top: 24px;
}
