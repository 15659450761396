.diversityAnalytics {
  margin-top: 24px;
}
.diversityAnalytics .grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: min-content;
  gap: 20px;
  justify-content: start;
}
