/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.profileview {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  align-items: stretch;
  /*********************
  ** stickable-header **
  *********************/
  /******************************
  ** sticky-profile-navigation **
  ******************************/
}
.profileview .profile-and-sidebar-container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  min-height: 0;
}
.profileview .stickable-header {
  z-index: 2;
  width: 100%;
  transition: top 0.2s ease;
}
.profileview .stickable-header .top-header {
  width: 100%;
  height: 64px;
  padding-left: 0;
  padding-right: 70px;
  background: white;
  border-bottom: 1px solid #d2d5e4;
}
.profileview .stickable-header .top-header .animated-logo-container {
  width: 130px;
  margin: 0 22px;
}
.profileview .stickable-header .navigation {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: relative;
  background-color: #ffffff;
  height: 90px;
  border-bottom: 1px solid #dae0f4;
}
.profileview .stickable-header .sub-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  background-color: #ffffff;
  margin-top: 64px;
  padding: 0px 15px 0px 15px;
  box-shadow: 0 0 5px #dddddd;
  height: 90px;
}
.profileview .stickable-header .sub-header-button.button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #4864c9;
  font-family: Graphik;
  color: #4864c9;
  background-color: #ffffff;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  display: flex;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: Gilroy, sans-serif;
}
.profileview .stickable-header .sub-header-button.button:hover {
  background-color: #e8ebfc;
}
.profileview .stickable-header .sub-header-button.button img {
  margin-right: 0.5em;
}
.profileview .stickable-header .navigation-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 15px;
}
.profileview .sticky-profile-navigation {
  z-index: 40;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}
.profileview .sticky-profile-navigation:hover .profile-navigation .profile-nav-scroll-container::-webkit-scrollbar {
  display: initial;
}
.profileview .sticky-profile-navigation .profile-navigation {
  height: 100%;
  position: absolute;
}
.profileview .sticky-profile-navigation .profile-navigation .profile-nav-scroll-container {
  height: calc(100% - 80px);
  overflow-y: auto;
}
.profileview .sticky-profile-navigation .profile-navigation .profile-nav-scroll-container::-webkit-scrollbar {
  width: 6px;
  display: none;
}
.profileview .sticky-profile-navigation .profile-navigation .profile-nav-scroll-container::-webkit-scrollbar-track {
  background: transparent;
}
.profileview.header-is-hidden .sticky-profile-navigation {
  height: 100%;
}
.profileview .parameters-pages-content {
  vertical-align: top;
  padding: 32px 172px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
}
.profileview .parameters-offers {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.profileview .parameters-pages-page {
  width: 100%;
}
.profileview .profile {
  overflow-y: auto;
  background-color: #f8faff;
  position: relative;
  width: 100%;
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: auto 1fr;
  margin-left: 72px;
  column-gap: 46px;
  row-gap: 32px;
}
.profileview .nudge {
  grid-column: 1 / span 2;
  height: 48px;
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  background-color: white;
  box-shadow: 0px 2px 16px 0px #1f2e7726;
}
.profileview .pending-main-nudge {
  padding: 0 150px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.profileview .profile-resume {
  grid-row: 2;
  margin-left: 95px;
  min-width: 0;
}
.profileview .profile-resume .no-resume-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.profileview .profile-resume .no-resume-container .no-items {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #a9c4ff;
  margin-top: 17px;
  padding-left: 4em;
  padding-right: 4em;
  margin-bottom: 100px;
}
.profileview .profile-resume .no-resume-container .no-items.watch {
  white-space: pre-line;
  margin-bottom: 40px;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}
.profileview .profile-resume .import-items-container {
  padding-left: 35px;
  padding-right: 35px;
}
.profileview .profile-resume .import-items-container h3 {
  color: #1f2e77;
  margin-top: 85px;
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}
.profileview .profile-resume .import-items-container h4 {
  margin-top: 40px;
  margin-bottom: 34px;
  color: #1f2e77;
}
.profileview .profile-resume .import-items-container .player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}
.profileview .profile-contact {
  grid-row: 2;
  margin-right: 70px;
  min-width: 420px;
}
.profileview .trial-side-panel {
  grid-row: 2;
  margin-right: 70px;
  min-width: 420px;
}
.profileview .profile-empty-state {
  grid-row: 2;
  grid-column: 1 / span 2;
  padding: 48px;
}
.profileview .ui.segment.profile-contact-flow {
  margin-top: 0;
  padding: 15px 0;
}
.profileview .profile-contact-flow-placeholder {
  width: 100%;
  height: 620px;
  display: flex;
  align-items: center;
}
.parametersview {
  background-color: #f8faff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  align-items: stretch;
  /*********************
  ** stickable-header **
  *********************/
  /******************************
  ** sticky-profile-navigation **
  ******************************/
}
.parametersview .profile-and-sidebar-container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  min-height: 0;
}
.parametersview .stickable-header {
  z-index: 2;
  width: 100%;
  transition: top 0.2s ease;
}
.parametersview .stickable-header .top-header {
  width: 100%;
  height: 64px;
  padding-left: 0;
  padding-right: 70px;
  background: white;
  border-bottom: 1px solid #d2d5e4;
}
.parametersview .stickable-header .top-header .animated-logo-container {
  width: 130px;
  margin: 0 22px;
}
.parametersview .stickable-header .navigation {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: relative;
  background-color: #ffffff;
  height: 90px;
  border-bottom: 1px solid #dae0f4;
}
.parametersview .stickable-header .sub-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  background-color: #ffffff;
  margin-top: 64px;
  padding: 0px 15px 0px 15px;
  box-shadow: 0 0 5px #dddddd;
  height: 90px;
}
.parametersview .stickable-header .sub-header-button.button {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #4864c9;
  font-family: Graphik;
  color: #4864c9;
  background-color: #ffffff;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  display: flex;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: Gilroy, sans-serif;
}
.parametersview .stickable-header .sub-header-button.button:hover {
  background-color: #e8ebfc;
}
.parametersview .stickable-header .sub-header-button.button img {
  margin-right: 0.5em;
}
.parametersview .stickable-header .navigation-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 15px;
}
.parametersview .sticky-profile-navigation {
  z-index: 40;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}
.parametersview .sticky-profile-navigation:hover .profile-navigation .profile-nav-scroll-container::-webkit-scrollbar {
  display: initial;
}
.parametersview .sticky-profile-navigation .profile-navigation {
  height: 100%;
  position: absolute;
}
.parametersview .sticky-profile-navigation .profile-navigation .profile-nav-scroll-container {
  height: calc(100% - 80px);
  overflow-y: auto;
}
.parametersview .sticky-profile-navigation .profile-navigation .profile-nav-scroll-container::-webkit-scrollbar {
  width: 6px;
  display: none;
}
.parametersview .sticky-profile-navigation .profile-navigation .profile-nav-scroll-container::-webkit-scrollbar-track {
  background: transparent;
}
.parametersview.header-is-hidden .sticky-profile-navigation {
  height: 100%;
}
.parametersview .parameters-pages-content {
  vertical-align: top;
  padding: 32px 172px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
}
.parametersview .parameters-offers {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.parametersview .parameters-pages-page {
  width: 100%;
}
.parametersview .profile {
  overflow-y: auto;
  background-color: #f8faff;
  position: relative;
  width: 100%;
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: auto 1fr;
  margin-left: 72px;
  column-gap: 46px;
  row-gap: 32px;
}
.parametersview .nudge {
  grid-column: 1 / span 2;
  height: 48px;
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  background-color: white;
  box-shadow: 0px 2px 16px 0px #1f2e7726;
}
.parametersview .pending-main-nudge {
  padding: 0 150px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.parametersview .profile-resume {
  grid-row: 2;
  margin-left: 95px;
  min-width: 0;
}
.parametersview .profile-resume .no-resume-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.parametersview .profile-resume .no-resume-container .no-items {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #a9c4ff;
  margin-top: 17px;
  padding-left: 4em;
  padding-right: 4em;
  margin-bottom: 100px;
}
.parametersview .profile-resume .no-resume-container .no-items.watch {
  white-space: pre-line;
  margin-bottom: 40px;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}
.parametersview .profile-resume .import-items-container {
  padding-left: 35px;
  padding-right: 35px;
}
.parametersview .profile-resume .import-items-container h3 {
  color: #1f2e77;
  margin-top: 85px;
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}
.parametersview .profile-resume .import-items-container h4 {
  margin-top: 40px;
  margin-bottom: 34px;
  color: #1f2e77;
}
.parametersview .profile-resume .import-items-container .player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}
.parametersview .profile-contact {
  grid-row: 2;
  margin-right: 70px;
  min-width: 420px;
}
.parametersview .trial-side-panel {
  grid-row: 2;
  margin-right: 70px;
  min-width: 420px;
}
.parametersview .profile-empty-state {
  grid-row: 2;
  grid-column: 1 / span 2;
  padding: 48px;
}
.parametersview .ui.segment.profile-contact-flow {
  margin-top: 0;
  padding: 15px 0;
}
.parametersview .profile-contact-flow-placeholder {
  width: 100%;
  height: 620px;
  display: flex;
  align-items: center;
}
.instantflow-success-modal {
  max-width: 432px;
}
