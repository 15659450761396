/*
 * colors.less
 */
.relevant-facts .relevant-fact-group {
  display: flex;
  flex-direction: row;
}
.relevant-facts .relevant-fact {
  background: #f8faff;
  border-radius: 2px;
  width: 230px;
  margin-right: 24px;
  padding: 8px;
}
.relevant-facts .fact-label {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #4864c9;
  margin-bottom: 10px;
}
.relevant-facts .fact-value {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7982ad;
}
