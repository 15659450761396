/*
 * colors.less
 */
.modalContentContainer {
  padding: 0 !important;
}
.modalForm {
  width: 100%;
}
.modalForm .modalContent {
  display: flex;
  max-height: 77svh;
  overflow: hidden;
}
.modalForm .modalContent .formContentContainer {
  padding: 24px;
  overflow: auto;
  flex: 1 1;
}
.modalForm .modalContent .previewContainer {
  padding-bottom: 0px;
  background-color: #f3f4f8;
}
.modalForm .modalContent .formContent {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  border-right: 1px solid #dae0f4;
}
.modalForm .modalContent .previewContainer {
  flex: 1 1;
  overflow: auto;
}
.modalForm .modalActions {
  padding: 16px 24px;
  border-top: 1px solid #dae0f4;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
