/*
 * colors.less
 */
.bar {
  display: flex;
  align-items: stretch;
  width: 100%;
  padding: 0;
  transition: all 150ms ease;
}
.bar.clickable {
  cursor: pointer;
}
.bar.clickable:hover {
  filter: brightness(0.8) saturate(1.5);
  background-color: #1f2e7714;
  transform: scale(1.005);
  box-shadow: 0px 2px 6px #1f2e7726;
}
.bar .background {
  transition: flex 300ms ease;
}
.bar .background .totalCount {
  margin: 0 8px;
  animation: fadeIn 800ms;
  transition: opacity 500ms ease;
}
.bar.vertical {
  height: 100%;
  flex-direction: column-reverse;
}
.bar.vertical .section {
  animation: bottomSlideIn 500ms ease;
}
.bar.vertical .background {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.bar.vertical .background .totalCount {
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  font-family: Gilroy;
  color: #1f2e77;
}
.bar.horizontal {
  height: 24px;
  margin-bottom: 1px;
  border-radius: 3px;
}
.bar.horizontal .section {
  flex-basis: 1px;
  animation: leftSlideIn 500ms ease;
  border-radius: 3px;
}
.bar.horizontal .background {
  padding: 2px 0;
  text-align: left;
  animation: rightSlideIn 500ms ease;
}
.bar.horizontal .background .totalCount {
  font-size: 12px;
  font-family: Graphik;
  color: #7982ad;
  white-space: nowrap;
}
.tooltip {
  min-width: 240px;
  padding: 12px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 2px 16px #1f2e7726;
}
.tooltip .tooltipHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #dae0f4;
  font-size: 14px;
  font-family: Gilroy;
  font-weight: 700;
  color: #1f2e77;
}
.tooltip .tooltipItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  font-family: Graphik;
  font-weight: 500;
  color: #7982ad;
}
.tooltip .tooltipItem .type .box {
  display: inline-block;
  vertical-align: middle;
  height: 8px;
  width: 8px;
  border-radius: 3px;
  margin-right: 8px;
}
.tooltip .tooltipItem .type .label {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-family: Graphik;
  font-weight: 500;
  color: #1f2e77;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rightSlideIn {
  0% {
    opacity: 0;
    transform: translateX(50%) scaleX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scaleX(1);
  }
}
@keyframes leftSlideIn {
  0% {
    opacity: 0;
    transform: translateX(-50%) scaleX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scaleX(1);
  }
}
@keyframes bottomSlideIn {
  0% {
    opacity: 0;
    transform: translateY(50%) scaleY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}
