/*
 * colors.less
 */
.avatars {
  display: flex;
}
@media (max-width: 1400px) {
  .avatars {
    display: none;
  }
}
.avatars .avatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: white;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #a5abc9;
}
.avatars .avatar:not(:first-child) {
  margin-left: -4px;
}
