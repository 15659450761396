/*
 * colors.less
 */
.ui.modal.new-template-modal .body-and-signature {
  min-height: 23vh;
}
.ui.modal.new-template-modal .title-field {
  background-color: #f8faff;
}
.ui.modal.new-template-modal .form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-flow: row nowrap;
}
.ui.modal.new-template-modal .form-actions .create-template {
  margin-left: 75px;
}
.ui.modal.new-template-modal .form-actions .dismiss-button {
  margin: 0 10px;
}
.ui.modal.new-template-modal .add-to-default-replies-toggle {
  margin-bottom: 0;
  margin-top: 8px;
}
.new-template-modal-actions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
