/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.row-sequence-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.row-sequence-container.inline {
  flex-grow: 1;
  flex-shrink: 0;
}
.row-sequence-container.inline .row-icon-container {
  margin-top: 0px;
}
.sequence-with-title {
  flex: 1;
}
.sequence-with-title.inline {
  height: 14px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}
.sequence-with-title.inline .sequence-title {
  margin-bottom: 0px;
}
.row-icon-container {
  margin-top: 0.25em;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.row-icon-container .state {
  position: absolute;
  right: -2px;
  top: -2px;
  width: 8px;
  height: 8px;
  z-index: 1;
  color: white;
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid white;
}
.row-icon-container .state.complete {
  background-color: #14b62e;
}
.row-icon-container .state.due {
  background-color: #ffbd2e;
}
.row-icon-container .state.future {
  border: none;
  padding: 0;
  color: transparent;
}
.row-icon-container .state.failed {
  border: none;
  padding: 0;
  color: transparent;
}
.row-icon-container .state.stopped {
  color: grey;
  padding: 0;
  background-color: white;
}
.row-icon-container img:not(:last-child) {
  margin-right: 0.5em;
}
.row-icon-container .task-icon {
  border-radius: 50%;
  width: 20px;
}
.row-icon-container .caret-divider {
  display: inline-block;
  height: 0px !important;
  width: 1em;
  border: 1px solid #d2d5e4;
  margin: 0 0.5em;
  vertical-align: middle;
  border-radius: 1px;
  background-color: #d2d5e4;
}
.icon-container {
  position: relative;
  flex-shrink: 0;
}
.icon-container.hidden {
  visibility: hidden;
}
.icon-container.mini .task-icon {
  width: 18px;
}
.icon-container.mini .caret-divider {
  width: 0.6em;
  margin: 0 0.35em;
}
.icon-container .icon {
  position: relative;
}
.icon-container div {
  display: inline-block;
}
.icon-container .icon-grey .task-icon {
  filter: grayscale(1);
}
.icon-container.more-actions {
  align-self: center;
}
