@import 'src/less/colors';

.fieldsContainer {
  flex: 1 1;
  padding: 0;

  .modalFormField {
    margin: 25px 0px !important;
  }

  .orderInput {
    margin-top: 25px;
  }

  .modalFormInput {
    flex-grow: 1;
    border-radius: 8px !important;
    display: block;
    box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;

    input {
      border-radius: 8px !important;
      border: 1px solid @SecondaryBlue20 !important;
      width: 100% !important;
    }
  }

  .formFieldContainer {
    margin-bottom: 16px;

    .fieldTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fieldTitleInfo {
        font-family: Graphik;
        font-size: 14px;
        font-weight: 500;
        color: @PrimaryCobalt;
        margin-bottom: 4px;

        span  {
          margin-left: 8px;
        }
      }

      .fieldTitleActions {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-right: 4px;
      }
    }

    .selectFieldQuestionContainer {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .addOptionButton {
        margin-left: 4px;
      }

      .input {
        flex-grow: 1;
      }

      .customFieldSelector {
        width: 100%;
      }
    }
  }

  .fieldSectionContainer {
    margin: 12px 0px;

    .fieldsTagSection,
    .fieldsQuestionSection,
    .fieldsActionsSection {
      display: flex;
      flex-direction: column;
      gap: 12px;

      span {
        color: @PrimaryCobalt;
        margin-bottom: -8px;
      }
    }

    .fieldContainer {
      margin-bottom: 16px;

      .removeFieldButton {
        margin-left: 4px;
        color: @PrimaryCobalt60;
        font-weight: 600;

        i {
          margin: 0px 4px;
        }
      }

      .fieldHeader {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        font-family: Graphik;
        color: @PrimaryCobalt;
        margin-bottom: 5px;

        span {
          margin-bottom: -3px;
        }
      }

      .toggle {
        margin-bottom: 0px;
        width: 2.5rem;
      }

      .optionsSelector {
        .fieldOptionContainer {
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          .removeOptionButton {
            color: @PrimaryCobalt60;
            font-weight: 600;
            height: 100%;
            cursor: pointer;
            margin-left: 4px;
          }

          i {
            margin: 0px 4px;
          }

          .fieldOption {
            color: @PrimaryCobalt;
            flex-grow: 1;
            max-width: calc(100% - 160px);
          }
        }
      }
    }

    .fieldsQuestionSection {
      flex-grow: 1;
    }

    .fieldsActionsSection {
      flex-shrink: 1;
      flex-grow: 0;

      > div {
        padding: 6px 0px;
        margin: auto;
      }
    }
  }

  .panel {
    padding: 10px;
    width: fit-content;
    background-color: white;
    width: fit-content;

    .newFieldDropdownOption {
      color: @PrimaryCobalt;
      font-family: Graphik;
      cursor: pointer;
      white-space: nowrap;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}
