@import 'src/less/colors';

.pill {
  display: inline-flex;
  vertical-align: middle;
  gap: 4px;
  align-items: center;
  padding: 1px 8px;

  font-weight: 500;
  font-size: 12px;
  color: @NegativeColor;
  background-color: @SecondaryRed10;
  border-radius: 20px;
}
