@import 'src/less/colors';

.row {
  display: flex;
  align-items: center;
  padding: 18px 24px;
  background-color: @SecondarySkyBlue05;
  border-top: 1px solid @SecondaryBlue20;

  .descriptor {
    flex: 1;
    min-width: 0;
  }

  .button {
    text-align: center;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt;

    &:not(:disabled) {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .column {
    width: 150px;
  }
}
