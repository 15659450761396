@import 'src/less/colors';

.languageDropdown {
  margin-bottom: 24px;
  width: 100%;
}

.sequenceDropdown:global(.ui.dropdown) {
  border-radius: 8px;
  :global(.divider.text) {
    width: 100%;
  }
}

.formGroup {
  margin-bottom: 24px;
}

.formTitle {
  margin-bottom: 4px;
  color: @PrimaryCobalt;
  font-weight: 600;
  line-height: 19.7px;
}

.formInput {
  width: 100%;
}

.textAreaContainer,
.sequenceStructureContainer {
  margin: 0px 0px 24px;
  textarea {
    width: 100%;
    color: @PrimaryCobalt;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid @SecondaryBlue20;
    resize: none;
    font-family: Graphik;
    font-size: 13px;
  }
}

.tooltipTrigger {
  background-color: @PrimaryCobalt;
  color: white;
  font-weight: 500;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.toneContainer {
  margin-bottom: 24px;
  .tones {
    display: flex;
    gap: 4px;

    .tone {
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 6px;
      flex-grow: 1;
      text-align: center;
      border: 1px solid @SecondaryBlue20;
      border-radius: 8px;

      &:hover {
        border-color: @SecondaryBlue60;
        background-color: @SecondarySkyBlue10;
      }

      &.selected {
        border-color: @SecondaryBlue;
        background-color: @SecondarySkyBlue10;
      }
    }
  }
}

.loaderButton {
  padding: 10px;
  position: relative;
}

.generateAndConfirm {
  display: flex;
  align-items: center;
  button {
    margin-left: 4px;
  }
}

.generateModalTrigger {
  margin-top: 12px;
  float: right;
}

.formWithPreview {
  display: flex;
  gap: 12px;
  height: 600px;
  overflow: hidden;

  .formContainer {
    flex: 1 1;
    height: 100%;
    overflow: auto;
    padding: 0px 12px;
  }

  .generatedContent {
    flex: 1 1;
    height: 100%;
    overflow: auto;
    padding: 0px 12px;

    h4 {
      text-align: center;
    }
  }
}
