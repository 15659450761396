/*
 * colors.less
 */
.message {
  font-size: 12px;
  line-height: 140%;
  color: #1f2e77;
  font-weight: 400;
  background-color: #f6f7fc;
  border-radius: 8px;
  padding: 12px;
  white-space: pre-wrap;
  margin: 8px 0;
  word-break: break-word;
}
.editableBody {
  cursor: pointer;
}
.submit {
  display: block;
  width: 100%;
  height: 38px;
  background-color: #28ca42 !important;
}
.submit.disabled {
  background-color: #28ca42 !important;
  filter: opacity(0.5);
}
.field {
  display: flex;
  align-items: center;
  gap: 4px;
}
.label {
  width: 40px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  color: #7982ad;
}
.phoneNumber {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #4864c9;
}
.markAsCompletedButton button {
  border-radius: 8px !important;
}
