/*
 * colors.less
 */
.offer-settings-dropdown div.ui.dropdown.icon {
  display: flex;
  align-items: center;
  color: #a5abc9;
}
.offer-settings-dropdown div.ui.dropdown.icon:hover {
  color: #a5abc9;
}
.offer-settings-dropdown div.ui.dropdown.icon:focus {
  color: #4c5892;
}
.offer-settings-dropdown .ui.dropdown > .left.menu {
  right: -11px !important;
}
.offer-settings-dropdown .ellipsis {
  color: #7982ad;
  font-size: 14px;
  display: inline-block;
}
.offer-settings-dropdown .ellipsis:hover {
  color: #4c5892;
}
