@import 'src/less/colors';

.simpleDropdown:global(.ui):global(.dropdown) {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: @SecondarySkyBlue120;
  cursor: pointer;
  > i:global(.dropdown):global(.icon) {
    margin-left: 4px;
  }
}
