/*
 * colors.less
 */
.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
