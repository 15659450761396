@import 'src/less/colors';

.navigation {
  display: flex;
  align-items: center;
  padding-right: 13px;
  gap: 24px;

  .tab {
    padding: 0;
    padding-bottom: 10px;
    display: inline-flex;
    align-items: center;
    height: 32px;
    gap: 8px;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    &:hover {
      border-bottom-color: @PrimaryCobalt60;

      .label {
        color: @PrimaryCobalt60;
      }
    }

    .label {
      font-family: Graphik;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: @PrimaryCobalt40;
    }

    &.active {
      border-bottom-color: @SecondaryBlue;

      .label {
        color: @SecondaryBlue;
      }
    }

    .counter {
      margin: 0;
    }
  }

  .separator {
    flex: 1;
  }

  .remainder {
    position: relative;
    padding-bottom: 12px;

    .trigger {
      padding: 0;
      display: inline-block;
      color: @PrimaryCobalt40;
      font-size: 14px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: @PrimaryCobalt60;
      }

      &.active {
        color: @SecondaryBlue;
      }
    }

    .dropdown {
      display: none;
      position: absolute;
      top: calc(100%);
      background-color: white;
      box-shadow: 0 2px 16px 0 @PrimaryCobaltOp15;

      .option {
        margin: 0;
        padding: 8px 12px;
        font-family: Graphik;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: @PrimaryCobalt40;
        cursor: pointer;

        &:hover {
          background-color: @SecondarySkyBlue05;
        }

        &.active {
          color: @SecondaryBlue;
        }
      }

      &.open {
        display: block;
      }
    }
  }
}
