@import 'src/less/colors.less';

.trigger {
  display: block;
  width: 100%;
  padding: 0;
  font-family: Graphik;
  text-align: left;
}

.dropdown {
  display: block;
  width: 100%;
}

.defaultTrigger {
  display: flex;
  align-items: center;
  gap: 8px;

  :global(.sequence-container) {
    flex-grow: 1;

    :global(.row-icon-container) {
      margin-top: -1px;
    }
  }
}

.triggerPlaceholder {
  color: @PrimaryCobalt40;
}
