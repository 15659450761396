/*
 * colors.less
 */
.helpText {
  background-color: #f6f7fc;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 16px;
  font-family: 'Graphik', 'Gilroy';
  font-size: 14px;
  font-weight: 400;
  color: #4c5892;
}
.helpText h4 {
  margin-top: 8px;
}
.helpText h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.helpText ul {
  margin-top: 4px;
  margin-bottom: 4px;
  padding-inline-start: 16px;
}
.helpTextIsNotFirst {
  margin-top: 8px;
}
.instructionsTitle {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
}
:global(.candidate-view) hr.hr {
  border: none;
  border-top: 1px solid #e9ebf2;
  margin: 12px 0;
}
.phoneNumberCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
  font-family: 'Graphik';
}
.phoneNumberCTA i {
  margin-right: 0.6rem;
}
.phoneNumberCTA:hover {
  color: #1f2e77;
}
.phoneNumberCTA:hover span {
  text-decoration: underline;
}
