/*
 * colors.less
 */
.applicationTitle {
  display: flex;
  align-items: center;
  min-width: 0;
  flex-grow: 1;
}
.applicationTitle .applicationTitleParts {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 4px;
}
.applicationTitle .applicationTitleStage {
  font-weight: 400;
  color: #7982ad;
  font-size: 12px;
  font-family: Graphik;
}
