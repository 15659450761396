@import 'src/less/colors';

.menuContainer {
  display: inline-block;
  position: relative;

  button {
    color: @SecondaryBlue !important;
  }

  .menu {
    display: none;

    &.open {
      display: block;
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      z-index: 1000;
      white-space: nowrap;

      background-color: white;
      box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
      border-radius: 4px;
    }

    .option {
      display: block;
      padding: 16px;
      width: 100%;
      text-align: left;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      color: @PrimaryCobalt;
      cursor: pointer;

      &:hover {
        .label {
          text-decoration: underline;
        }
      }

      .icon {
        font-size: 1.3333em;
        margin-right: 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid @BorderColor;
      }
    }
  }
}

.reasonContainer {
  margin-top: 24px;

  .reasonTitle {
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    flex: 1;
  }

  .buttonActive {
    border: 2px solid #4864c9;
  }
}

.customButton {
  background-color: @PrimaryCobalt05;
  padding: 8px;
  border-radius: 8px;
  color: @PrimaryCobalt80;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid @PrimaryCobalt05;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
}

.mergeModalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .summary {
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt;
    cursor: pointer;

    span {
      margin-left: 8px;
    }
  }

  .summaryContent {
    margin-top: 12px;
    border-left: 2px solid @PrimaryCobalt;
    padding-left: 12px;
    margin-left: 8px;
  }

  .profileIdExample {
    margin: 12px 0px;
  }

  .fakeInput {
    background-color: @PrimaryCobalt05;
    padding: 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: normal;
    border: 2px solid @PrimaryCobalt05;
    font-family: Graphik, sans-serif;
    white-space: nowrap;

    .urlPart1 {
      color: @PrimaryCobalt60;
    }

    .urlPart2 {
      color: @PrimaryCobalt;
    }
  }

  .formField {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .label {
      font-size: 14px;
      font-weight: 500;
      color: @PrimaryCobalt;
    }

    .input {
      background-color: @PrimaryCobalt05;
      padding: 8px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: normal;
      border: 2px solid @PrimaryCobalt05;
      font-family: Graphik, sans-serif;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .highlight {
    font-weight: 500;
  }
}
