.footer {
  padding: 8px 12px;
  text-align: right;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.footer .languageDropdown {
  width: 100px !important;
  min-width: 100px !important;
  color: #7982ad !important;
  font-family: Graphik, sans-serif !important;
  border: none !important;
  font-size: 12px !important;
}
.footer :global(.ui.upward.selection.dropdown .menu) {
  border: none !important;
}
.footer :global(.ui.selection.dropdown .menu > .item) {
  font-size: 12px !important;
}
