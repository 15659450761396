.panel {
  padding: 12px 16px;
  min-width: 450px;
}
.inputs {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
