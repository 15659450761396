@import 'src/less/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  h4 {
    margin: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  z-index: 0;

  .selectors {
    z-index: 1;
  }

  .selector {
    margin-bottom: 12px;
  }

  .editor {
    z-index: 0;
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: @PrimaryCobalt80;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  gap: 8px;
}

.error {
  font-size: 12px;
  color: @SecondaryRed;
}
