/*
 * colors.less
 */
.full-width-editor-wrapper {
  width: 100%;
}
.editor-container {
  color: black;
  border: 1px solid #dae0f4;
  box-shadow: none !important;
  background: #fff;
  border-radius: 0.28571429rem;
  padding: 12px;
}
.editor-container.focus {
  border: 1px solid #4864c9 !important;
}
.editor-container.radius {
  border-radius: 8px;
}
.editor-container .editor-toolbar-item {
  margin: 0px !important;
  padding: 0px 5px !important;
  height: 25px;
  width: 25px;
}
.editor-container .url-input-action {
  color: white;
  background-color: #1f2e77;
}
.editor-container .url-input-action:hover {
  background-color: #709dff;
}
.editor-container .body-and-signature {
  min-height: 120px;
  margin-top: 10px;
  overflow: hidden;
}
.editor-container .body-and-signature-small {
  min-height: 0px !important;
}
.editor-container .ui.secondary.menu {
  min-height: 30px !important;
}
.editor-container .character-count {
  margin-right: 0 !important;
  margin-left: 4px !important;
}
.editor-container .menu::after {
  display: none;
}
.editor-container .menu .ui.item.dropdown {
  margin: 0;
}
.editor-container .menu .edit-template-button {
  display: inline-flex;
  align-items: center;
  font-family: Graphik;
  font-size: 13px;
  cursor: pointer;
  color: #4864c9;
  padding: 5px;
  white-space: nowrap;
}
.editor-container .menu .edit-template-button:hover {
  text-decoration: underline;
}
.editor-container .menu .choose-template-trigger {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
}
.editor-container .menu .choose-template-trigger:hover {
  cursor: pointer;
  text-decoration: underline;
}
.editor-container .menu .merge-tags-selector-trigger {
  position: relative;
  display: flex;
}
.editor-container .menu .merge-tags-selector-trigger .trigger {
  align-self: center;
  margin: 0;
  padding: 0.78571429em 0.92857143em;
  line-height: 1;
  color: #4864c9;
}
.editor-container .menu .merge-tags-selector-trigger .trigger:hover {
  background: rgba(0, 0, 0, 0.05);
}
.editor-container .menu .merge-tags-selector-trigger .trigger .merge-tags-trigger-icon {
  margin: 0em 0em 0em 0.9em;
  font-size: 12px;
  color: black;
  opacity: 0.8;
  text-decoration: none;
}
.editor-container figure {
  margin: 0 !important;
}
.editor-container ul {
  padding-inline-start: 30px !important;
  margin: 0 !important;
}
.DraftEditor-root ul {
  padding-inline-start: 30px !important;
  margin: 0 !important;
}
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 13px;
}
.RichEditor-editor {
  cursor: text;
  font-size: 13px;
  font-family: Graphik;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root {
  pointer-events: none;
  position: absolute;
  color: #d3d3d3;
}
.email-subject.RichEditor-editor .public-DraftEditorPlaceholder-root,
.email-subject.RichEditor-editor .public-DraftEditor-content {
  min-height: 1em;
}
.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}
.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: Graphik;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}
.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 1em;
  padding: 20px;
}
.merge-tags-preview-blue-text {
  background-color: #e2ebff;
  padding: 3px 7px;
  border-radius: 1em;
  color: #1f2e77;
  font-weight: 500;
}
.merge-tags-preview-red-text {
  background-color: #fff1f3;
  padding: 3px 7px;
  border-radius: 1em;
  color: #f66f81;
  font-weight: 500;
}
.merge-tags-deep-snippet-value {
  background-color: #e2ebff;
  padding: 3px 7px;
  border-radius: 1em;
  color: #1f2e77;
  font-weight: 500;
  cursor: initial;
}
.merge-tags-deep-snippet-no-value {
  background-color: #fff1f3;
  color: #f66f81;
  display: inline;
  padding: 3px 7px;
  border-radius: 1em;
  font-size: 14px;
  font-weight: 500;
  cursor: initial;
}
.template-dropdown-panel {
  width: max-content !important;
  max-height: 286.4px;
  padding: 8px 0;
  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.template-dropdown-panel::-webkit-scrollbar {
  display: none;
}
