/*
 * colors.less
 */
.collapsible-toggle {
  padding-left: 3px;
  cursor: pointer;
  text-decoration: underline;
}
.collapsible-toggle:hover {
  color: #10173d;
}
