@import 'src/less/colors';

.projectProfileViewSettingsHeader {
  position: relative;
  margin: 6px 0px 26px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  z-index: 3;

  .displayTypeDropdown {
    //styleName: Body 14/Medium;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: @PrimaryCobalt60;
    display: flex;
    align-items: center;

    .displayTypeDropdownTrigger {
      display: inline-flex;
      align-items: center;
      gap: 6px;

      svg {
        width: 16px;
        height: 16px;
        margin-top: -2px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 16px;

    .disabledButton {
      filter: grayscale(100%);
      background: #edf0fa;
      cursor: initial;
      color: grey !important;
    }

    .weekSelectorContainer {
      position: relative;
      padding: 0px 16px;
      width: 200px;

      .currentWeek {
        height: 32px;
        width: calc(100%);
        border: 1px solid @SecondaryBlue40;
        background-color: white;
        text-align: center;
        padding: 5px 32px;
        font-family: Graphik;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: @PrimaryCobalt60;
      }

      .prevSelector,
      .nextSelector {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        background-color: white;
        border: 1px solid @SecondaryBlue40;
        border-radius: 24px;
        text-align: center;
        padding: 5px 0px;
        cursor: pointer;
      }

      .prevSelector {
        left: 0;
      }

      .nextSelector {
        right: 0;
      }
    }
  }
}
