@import 'src/less/colors.less';

.searchResultsPage {
  height: 100%;

  .loadingPlaceholder {
    min-height: 250px; // TODO: adapt to rowHeight * PAGE_SIZE
    height: 100%;
    display: flex;
    align-items: center;

    :global(.ui.loader) {
      z-index: 1;
    }
  }

  .noResults {
    padding: 120px 24px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: @PrimaryCobalt;
  }

  .row {
    position: relative;

    .actions {
      display: none;

      position: absolute;
      z-index: 2;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }

    &:hover,
    &.lockedHover {
      .actions {
        display: block;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid @SecondaryBlue20;
    }
  }
}
