/*
 * colors.less
 */
.dropdownContainer {
  position: relative;
  display: inline-block;
  width: fit-content;
}
.dropdownContent {
  display: none;
  position: absolute;
  z-index: 100;
  transition: opacity 50ms ease;
}
.dropdownContent.open {
  display: block;
}
.dropdownContent.invisible {
  opacity: 0;
}
