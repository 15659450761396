@import 'src/less/colors';

.hint {
  span {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt60;
  }
}

.subStepEditionRawContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;

  .subStepEditionToggle {
    margin: 0;
  }
}

.subStepEditionFakeInput {
  flex: 1 1;
  display: inline-flex;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  grid-gap: 8px;
  gap: 8px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
  color: @PrimaryCobalt !important;
}

.tableHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;

  h3 {
    margin: 0 !important;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt;
  }
}

.newSubstepContainer {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: @PrimaryCobalt40;
  border: 2px dashed @PrimaryCobalt20;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  margin: 0px 0px 4px 44px;
  opacity: 1;
  //transition: opacity 200ms;
}

.newSubstepNameLabel {
  margin-bottom: 6px;
  font-weight: 500;
}

.panel {
  // color: @PrimaryCobalt;
  // display: flex;
  // flex-direction: column;
  // gap: 8px;

  .panelOption {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

    .panelOptionIcon,
    i {
      width: 16px;
      color: @PrimaryCobalt40;
    }
  }
}

.newSubstepContainer.newSubstepVisible {
  opacity: 1;
}

.newSubstepContainer:hover {
  background: @PrimaryCobalt05;
}

.newSubstepSpacer {
  height: 40px;
}

.newSubstepNameInput {
  width: 100%;
}

.substepActions {
  margin-top: 8px;
}

.infoBox {
  margin-top: 12px;
  background-color: @SecondarySkyBlue10;
  padding: 16px;
  border-radius: 8px;
  color: @PrimaryCobalt;
  font-size: 12px;
}
