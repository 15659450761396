/*
 * colors.less
 */
:global(.ui.modal).modal {
  border-radius: 8px;
}
:global(.ui.modal).modal > :first-child:not(:global(.icon)) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
:global(.ui.modal).modal > :last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
:global(.ui.modal).modal :global(.content) {
  font-family: Graphik;
  color: #1f2e77;
}
:global(.ui.modal).modal :global(.header) {
  display: block;
  font-family: 'Gilroy', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: #f3f4f8;
  margin: 0em;
  padding: 1.25rem 1.5rem;
  box-shadow: none;
  color: #1f2e77;
  border-bottom: 1px solid #dae0f4;
  text-align: center;
}
:global(.ui.modal).modal :global(.actions) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:global(.ui.modal).modal :global(.actions) :global(.ui.button.dismiss) {
  background-color: #fff;
  color: #4864c9;
  font-size: 18px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 18px;
}
