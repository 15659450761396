@import 'src/less/colors';

.paragraph {
  margin: 16px 0;
  padding: 12px;
  border-radius: 4px;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background-color: @SecondarySkyBlue10;
  color: @SecondaryBlue;
}

.label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.pill {
  padding: 4px 8px;

  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;

  border-radius: 80px;
  color: @SecondaryBlue;
  background-color: @SecondarySkyBlue20;
}
