/*
 * colors.less
 */
.job-posting-display .display-header {
  display: flex;
  flex-direction: row;
}
.job-posting-display .display-header-info {
  display: flex;
  flex-direction: column;
}
.job-posting-display .header {
  margin-bottom: 0;
  color: #709dff;
  display: inline-block;
}
.job-posting-display .link-icon {
  margin-left: 10px;
  font-size: 16px;
  position: relative;
  top: 2px;
}
.job-posting-display .header-and-status {
  display: flex;
  flex-direction: row;
}
.job-posting-display .header-and-status a:hover .header {
  text-decoration: underline;
}
.job-posting-display .hs-flex-spacer {
  flex: 1;
}
.job-posting-display .publication-status {
  display: flex;
  align-items: center;
  margin-left: 16px;
  line-height: 20px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
}
.job-posting-display .publication-status.published {
  color: #28ca42;
}
.job-posting-display .publication-status.unpublished {
  color: #7982ad;
}
.job-posting-display .publication-status i {
  margin-right: 5px;
}
.job-posting-display .posting-management-info {
  margin-bottom: 36px;
}
.job-posting-display .foldering-display {
  margin-top: 8px;
  margin-bottom: 16px;
  color: #7982ad;
}
.job-posting-display .locations-display {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  margin-top: 16px;
}
.job-posting-display .job-description-container {
  margin-top: 40px;
}
