@import 'src/less/colors';

.kanbanCard {
  width: 100%;
  background: white;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: margin-top 250ms, rotate 100ms;
  box-shadow: 0px 2px 14px rgba(31, 46, 119, 0.08) !important;
  cursor: pointer;
  user-select: none;

  &:hover  {
    border: 1px solid @SecondaryBlue60;
  }

  &:active {
    cursor: grab;
  }
}
