@import 'src/less/colors';

.item {
  display: block;
  width: 100%;
  padding: 6px 12px;
  line-height: 140%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  font-family: Graphik;
  font-size: 14px;
  text-align: left;
  color: @PrimaryCobalt;
  font-weight: 400;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background-color: @SecondaryBlue05;
      &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  &:disabled {
    color: @PrimaryCobalt60;
  }
}
