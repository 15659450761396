.dynamicImportsSection .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dynamicImportsSection .header h2 {
  margin: 0;
}
.dynamicImportsSection .header .actions {
  display: flex;
  gap: 8px;
}
.dynamicImportsSection .content {
  margin-top: 24px;
}
.dynamicImportsSection .content .inputsContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}
.dynamicImportsSection .content .inputsContainer > div {
  flex: 1 1;
}
.dynamicImportsSection .content .inputsContainer .input {
  margin-bottom: 24px;
}
