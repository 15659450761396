/*
 * colors.less
 */
.actionButton {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #5c89eb;
  cursor: pointer;
  white-space: nowrap;
}
.actionButton i {
  display: inline-block;
  margin-left: 5px;
}
.actionButton i:before {
  vertical-align: bottom;
  line-height: 13px;
}
