@import 'src/less/colors';

.container {
  display: flex;
  cursor: pointer;
  text-align: initial;

  .label {
    display: inline-block;
    margin-left: 8px;
    color: @PrimaryCobalt80;
    font-size: 12px;
    font-weight: 500;
    font-family: Graphik;
  }
}
