/*
 * colors.less
 */
/*
 * typography.less
 */
body {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
}
body .ui.input > input {
  font-family: Graphik, sans-serif !important;
}
input,
optgroup,
select,
textarea {
  font-family: Graphik, sans-serif;
}
h1,
.headline-1 {
  font-family: Gilroy, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}
h2,
.headline-2 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}
h3,
.headline-3 {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
h4,
.headline-4 {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
h5,
.headline-5 {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}
.body-semibold {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.body-medium {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.body-regular {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.caption-medium {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}
.caption {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1px;
}
.caption-2 {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
#plugin-modal-dimmer {
  background-color: #1f2e77b3;
}
.plugin-modal.ui.modal {
  width: 600px;
  max-height: calc(100vh - 36px);
  position: fixed;
  bottom: 32px;
  left: 32px;
  background-color: transparent;
  border-radius: 4px;
  overflow-y: auto;
}
.plugin-modal.ui.modal .plugin-modal-wrapper {
  display: flex;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .plugin-modal-header {
  flex-shrink: 0;
  flex-basis: 300px;
  border-top-left-radius: 4px;
  background-color: #709dff;
  padding: 38px 38px 0px 38px;
  position: relative;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .plugin-modal-header .chrome-logo-wrapper {
  position: absolute;
  bottom: 180px;
  right: 40px;
  background-color: white;
  border-radius: 9999px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .plugin-modal-header .chrome-logo-wrapper .chrome-logo {
  height: 64px;
  width: 64px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .plugin-modal-header .plugin-screenshot {
  position: absolute;
  bottom: 0px;
  width: 223px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content {
  background-color: white;
  position: relative;
  flex-shrink: 0;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 24px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content .close-button {
  position: absolute;
  top: 18px;
  right: 18px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content .close-button i {
  color: #a5abc9;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content .close-button i:hover {
  color: #1f2e77;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content h3 {
  margin: 0px;
  margin-bottom: 8px;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #1f2e77;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content .ui.button.primary {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  height: 32px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content #plugin-modal-cta {
  margin-bottom: 8px;
}
.plugin-modal.ui.modal .plugin-modal-wrapper .content .description {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #4c5892;
  margin-bottom: 16px;
}
