/*
 * colors.less
 */
.connectPagePreview {
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
}
.connectPagePreview .previewHeader {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: #f1f6ff;
  background: linear-gradient(180deg, #f1f6ff 0%, rgba(255, 255, 255, 0) 100%);
  color: #1f2e77;
}
.connectPagePreview .previewHeader .previewText {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
}
.connectPagePreview .previewHeader .title {
  font-family: Gilroy;
  font-size: 40px;
  font-weight: 900;
  line-height: 50px;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 40px;
}
.connectPagePreview .description {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.23999999px;
  text-align: center;
  color: #7982ad;
  width: 90%;
  margin: 0 auto 50px auto;
}
.connectPagePreview .previewForm {
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.connectPagePreview .previewForm .question .questionTitle {
  font-weight: 500;
  margin-bottom: 4px;
  color: #1f2e77;
}
.connectPagePreview .previewForm .question .fakeInput:is(textarea) {
  width: 100%;
  border: 1px solid #dae0f4;
  opacity: 0.45;
  padding: 11px 1em;
  border-radius: 8px;
}
.connectPagePreview .previewForm .question .fakeInput > input {
  border-radius: 8px !important;
}
