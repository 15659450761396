/*
 * colors.less
 */
.instructions {
  color: #1f2e77;
}
.instructions h3 {
  margin: 20px 0px 8px;
}
.instructions h3 span {
  background-color: #e2ebff;
  border-radius: 50%;
  display: inline-block;
  width: 28px !important;
  height: 28px !important;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.instructions p {
  margin: 0px;
}
.instructions .image-container {
  background-color: #8db1ff;
  border-radius: 4px;
  padding: 15px;
  margin: 0px auto 20px;
  width: calc(50% - 30px);
}
.instructions .image-container.full-screen {
  width: calc(70% - 30px);
}
.instructions .image-container.full-screen img {
  width: 100% !important;
}
.instructions .image-container img {
  margin: auto;
  width: 100% !important;
  border-radius: 8px;
}
