@import 'src/less/colors';

.inputContainer {
  margin-bottom: 24px;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px;
    color: @PrimaryCobalt80;
  }
}
