/*
 * colors.less
 */
.bcSelector {
  text-align: right;
  flex-shrink: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #7982ad;
}
.bcSelector span {
  cursor: pointer;
  text-decoration: underline;
}
.bcSelector span:not(:first-child) {
  margin-left: 8px;
}
.inputContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
  margin: 0px !important;
}
.inputContainer .introWord {
  color: #7982ad;
  font-family: Graphik !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}
.inputContainer .pill {
  display: inline-flex;
  align-items: center;
  height: 18px;
  padding: 0px 0px 0px 8px !important;
  margin: 0 !important;
}
.inputContainer .pill .deleteAddress {
  margin-left: 8px;
  vertical-align: 0px;
  cursor: pointer;
}
.inputContainer .pill span:focus-visible {
  outline: none;
}
.inputContainer .inputElement {
  flex-grow: 1;
  font-size: 12px;
  min-width: 150px;
  display: flex;
}
.inputContainer .inputElement .recipientInput {
  flex-grow: 1;
}
.inputContainer .inputElement .recipientInput input {
  border: none;
  padding: 0px;
  color: #1f2e77;
}
.inputContainer .inputElement .recipientInput input::placeholder {
  color: #a5abc9 !important;
}
.inputContainer .inputCancel {
  margin-left: auto;
  cursor: pointer;
}
.inputContainer .inputCancel svg {
  color: #7982ad;
  height: 9px;
}
.inputContainer .soboSelector {
  flex-grow: 1;
}
.clickable {
  cursor: pointer;
}
.soboTrigger {
  font-size: 12px;
}
.unauthorizedSender {
  color: #e45a6b;
}
.soboDropdown {
  color: #5c89eb;
}
.dropdownContent {
  z-index: 2;
}
