.watch-collect-container .watch-collect-power-hours {
  margin-left: 24px;
  margin-bottom: 24px;
  margin-right: 32px;
}
.watch-collect-container .search-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 24px;
  padding-right: 8px;
}
.watch-collect-container .search-filters-container .search-actions {
  display: flex;
}
.watch-collect-container .search-filters-container .search-actions .review-all-button.ui.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 17px;
  white-space: nowrap;
  margin-right: 0;
  margin-left: 8px;
  padding: 6px 16px;
  height: 32px;
}
.watch-collect-container .search-filters-container .search-actions .review-all-button.ui.button i.ri-send-plane-2-fill {
  font-size: 14px;
  margin-left: 6px;
}
