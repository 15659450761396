/*
 * colors.less
 */
.container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 16px;
}
.container .descriptionContainer {
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
}
.container .descriptionContainer .title {
  color: #1f2e77;
  font-weight: 500;
  margin-bottom: 4px;
}
.container .descriptionContainer .description {
  font-weight: 400;
  color: #7982ad;
  margin-bottom: 4px;
}
.container .descriptionContainer .ctaContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #4864c9;
}
