@import 'src/less/colors.less';

.helpText {
  background-color: @SecondaryBlue05;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 16px;
  font-family: 'Graphik', 'Gilroy';
  font-size: 14px;
  font-weight: 400;
  color: @PrimaryCobalt80;

  h4 {
    margin-top: 8px;
  }

  h5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ul {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-inline-start: 16px;
  }
}

.helpTextIsNotFirst {
  margin-top: 8px;
}

.instructionsTitle {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
}

:global(.candidate-view) hr.hr {
  border: none;
  border-top: 1px solid @PrimaryCobalt10;
  margin: 12px 0;
}

.phoneNumberCTA {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  color: @PrimaryCobalt;
  font-family: 'Graphik';

  i {
    margin-right: 0.6rem;
  }
}

.phoneNumberCTA:hover {
  color: @PrimaryCobalt;

  span {
    text-decoration: underline;
  }
}
