/*
 * colors.less
 */
.row:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.row .main {
  display: flex;
  align-items: center;
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #4c5892;
  padding: 18px 24px;
}
.row .main .name {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 16px;
}
.row .main .name .link {
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #4864c9;
}
.row .main .name .link:hover {
  text-decoration: underline;
}
.row .main .name .prefixToggle {
  font-size: 14px;
  color: #4864c9;
}
