@import 'src/less/colors';

.button {
  display: block;
  width: 100%;
  margin: 10px 0;
  text-align: center;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: @PrimaryCobalt40;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
