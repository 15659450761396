/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.smart-no-profile-left-pane {
  background-color: white;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  border-radius: 8px;
  padding: 24px;
}
.smart-no-profile-left-pane.rating-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smart-no-profile-left-pane.rating-form h2 {
  margin-top: 40px;
}
.smart-no-profile-left-pane.rating-form h5 {
  margin-bottom: 8px;
}
.smart-no-profile-left-pane.rating-form .rating-button {
  width: 126px;
  padding: 8px;
  margin-bottom: 24px;
}
.smart-no-profile-left-pane .rating-container {
  margin-top: 36px;
}
.smart-no-profile-left-pane .comment-container {
  width: 380px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.smart-no-profile-left-pane .comment-container textarea {
  resize: none;
  font-family: Graphik !important;
  font-size: 13px !important;
  color: #112e48 !important;
  line-height: 20px !important;
}
.smart-no-profile-left-pane .comment-container textarea::placeholder {
  font-family: Graphik;
  font-size: 13px;
  color: #a1a1b3;
}
.smart-no-profile-left-pane .actions-container button,
.smart-no-profile-left-pane .actions-container button:hover {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid white;
  font-family: Graphik;
  color: white;
  background-color: #4864c9;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  border-radius: 4px;
}
.smart-no-profile-left-pane .actions-container button.disabled {
  background: #f1f1f1;
}
.smart-no-profile-left-pane .actions-container button.disabled span {
  opacity: 0.5 !important;
  color: #595a5c !important;
}
.smart-no-profile-left-pane .articles-pane {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smart-no-profile-left-pane .articles-pane .articles-pane-title {
  text-align: center;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #0b1a63;
  margin-bottom: 8px;
}
.smart-no-profile-left-pane .articles-pane .sub-header {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7982ad;
}
.smart-no-profile-left-pane .articles-pane .articles-container {
  margin-top: 32px;
  margin-bottom: 32px;
}
.smart-no-profile-left-pane .unlimited-flow-container {
  padding-left: 5%;
  padding-right: 5%;
}
.smart-no-profile-left-pane .unlimited-flow-container h3 {
  margin-bottom: 40px;
}
.smart-no-profile-left-pane .unlimited-flow-container .actions-container {
  margin-top: 40px;
}
.smart-no-profile-left-pane .unlimited-flow-container .rate-previous-selection {
  margin-top: 10px;
  color: #595a5c;
  text-decoration: underline;
  cursor: pointer;
}
.smart-no-profile-left-pane .ignore-container {
  margin-top: 10px;
}
.smart-no-profile-left-pane .ignore-text {
  color: #767689;
  cursor: pointer;
}
.smart-no-profile-left-pane .ignore-text:hover {
  text-decoration: underline;
}
