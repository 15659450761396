/*
 * colors.less
 */
.container {
  display: inline-flex;
  height: 32px;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
}
.container:not(.disabled) {
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.container.disabled {
  background-color: #f6f7fc;
}
.container.big {
  height: 40px;
}
.container.big .input {
  font-size: 14px;
  line-height: 20px;
}
.container.fluid {
  width: 100%;
}
.container .icon {
  font-size: 16px;
  color: #a5abc9;
}
.container .input {
  flex: 1;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1f2e77;
  outline: none;
}
.container .input.inputDisabled {
  color: #a5abc9;
}
