/*
 * colors.less
 */
.settings.departments-page .departments-form {
  margin: 20px 0px !important;
}
.settings.departments-page .departments-form .departments-list-container {
  margin-bottom: 20px;
}
.settings.departments-page .departments-form .departments-list-container .department-container {
  margin-top: 10px;
}
.settings.departments-page .departments-form .departments-list-container .department-container .ui.segment {
  padding: 18px 24px;
}
.settings.departments-page .departments-form .departments-list-container .department-container h3 {
  margin-top: 5px !important;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 15px;
}
.settings.departments-page .departments-form .departments-list-container .department-container .settings-dropdown-container .settings-dropdown-inner-container {
  position: absolute;
  top: -12px;
  right: 5px;
}
.settings.departments-page .departments-form .departments-list-container .sections-container .section-container:not(:first-child) {
  padding-top: 5px;
}
