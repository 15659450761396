/*
 * colors.less
 */
.button {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 22px;
  border-radius: 50%;
  background: #edf0fa;
  color: #1f2e77;
  border: 1px solid white;
  cursor: pointer;
}
.button.active {
  background: #f66f81;
  color: white;
}
