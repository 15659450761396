/*
 * colors.less
 */
.fallback-action.ui.segment {
  padding: 12px;
  color: #4c5892;
  font-family: Graphik, sans-serif;
  text-align: center;
  margin-top: 24px;
}
.fallback-action.ui.segment .action-header {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
}
