@import 'src/less/colors';

.partitioningSelector {
  .partitioningDropdown {
    height: 32px;
    min-height: 32px !important;
    max-height: 32px;
    padding: 0;
    font-size: 12px;
    margin: calc(0.5em + 1px) 1em;
    line-height: 9px !important;

    :global(.dropdown.icon) {
      line-height: 7px !important;
    }
  }
  .label {
    color: @PrimaryCobalt60;
    margin-right: 1em;
  }
}
