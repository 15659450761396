@import 'src/less/colors';

.bullet {
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 4px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .index {
    grid-column: 1;
    grid-row: 1;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 700;
    color: @SecondaryColorLight;
  }

  .title {
    grid-column: 2;
    grid-row: 1;
    font-family: Gilroy;
    font-size: 16px;
    line-height: normal;
    font-weight: 700;
    color: @PrimaryCobalt;
  }

  .description {
    grid-column: 2;
    grid-row: 2;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: @PrimaryCobalt60;
  }
}
