/*
 * colors.less
 */
.container {
  height: 100%;
  padding: 100px 48px;
  background-color: #3450b5;
}
.modal {
  background-color: white;
  border-radius: 16px;
  padding: 48px;
}
.title {
  color: #1f2e77;
  font-size: 22px;
  font-family: Gilroy;
  line-height: 130%;
  font-weight: 700;
}
.paragraph {
  margin: 24px 0;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  line-height: 140%;
  color: #1f2e77;
}
.cta {
  width: 100%;
  height: 56px;
  white-space: wrap;
}
.cta:not(:last-child) {
  margin-bottom: 8px;
}
.gtcModal {
  max-width: 600px;
}
.modalContent {
  padding: 24px;
}
.modalParagraph {
  color: #1f2e77;
  text-align: center;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  line-height: 140%;
}
.terms {
  margin: 16px 0;
  padding: 0;
}
.gtcCheckbox {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #7982ad;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik;
}
.gtcLink {
  color: #4864c9;
  text-decoration: underline;
}
.gtcLink:hover {
  color: #3450b5;
  text-decoration: underline;
}
.modalActions {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
}
