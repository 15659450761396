@import 'src/less/colors';

.emptyState {
  padding: 24px;
  justify-content: space-between;

  img {
    width: 100%;
  }
}
