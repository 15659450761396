/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.ui.modal.edit-sender-modal .preview-label {
  color: #7982ad;
  font-size: 12px;
}
.ui.modal.edit-sender-modal .field.signature-field {
  width: 100%;
}
.ui.modal.edit-sender-modal .field.signature-field .content-edition-field {
  border: 1px solid #dae0f4;
  padding: 0.39285714em 1em;
  border-radius: 2px;
  font-size: 1em;
  box-shadow: none;
  outline: none;
  min-height: 80px;
  overflow: auto;
}
.ui.modal.edit-sender-modal .field.signature-field .content-edition-field:focus {
  border-color: #4864c9;
}
