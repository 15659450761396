@import 'src/less/colors';

.rowActions {
  margin-left: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
}

.rowAction:global(.ui):global(.button) {
  display: flex;
  padding: 8px 16px;
  vertical-align: middle;
  align-items: center;
  i {
    float: left;
    margin-right: 0.4rem;
  }
}

.rowAvatar {
  margin-left: 0.8rem;
}

.rowProfileDetails {
  overflow-x: hidden;
  margin-top: 3px;
  flex-grow: 1;
  :global(.full-name):global(.headline-5) {
    width: 100%;
  }
}

.skipPopup:global(.ui):global(.popup) {
  display: flex;
  flex-direction: column;
  padding: 8px;
  z-index: 10;
}

.skipReasonsContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 50%;
  gap: 8px;
}

.other {
  width: 100%;
}

.otherReasonInput {
  @margin-right: 8px;
  margin: 6px @margin-right;
  width: calc(100% - 2 * @margin-right);
}

.profileAddToSequenceModal {
  background: #ffffff;
  border: 1px solid #d2d5e4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: absolute;
  padding: 12px;
  min-width: 450px;
  z-index: 10;

  .checkboxLabel {
    font-size: 14px;
    color: @PrimaryCobalt;
    font-weight: 500;
  }

  .modalBottom {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    > div {
      flex-grow: 1;
    }
  }
}
