@import 'src/less/colors';

.jobHeader {
  display: grid;
  height: 116px;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: center;
  padding: 10px 40px;
  padding-right: 60px;
  background-color: white;
  border-bottom: 1px solid #dae0f4;

  .breadcrumbs {
    grid-row: 1;
    grid-column: 1;

    min-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
  }

  .linkedCompany {
    //styleName: Body 12/Medium 500;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .companyLogo {
    width: 16px;
    height: 16px;
  }

  .linkedCompanyContainer {
    padding: 4px 8px;
    border-radius: 8px;
    background: @SecondaryBlue05;
    color: @PrimaryCobalt60;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .positionDescriptionTrigger {
    padding: 4px 4px;
    border-radius: 8px;
    background: @SecondaryBlue05;
    color: @PrimaryCobalt60;
    line-height: 14px;
    font-size: 16px;
  }

  .navigation {
    grid-row: 2;
    grid-column: 1;

    margin-bottom: 6px;

    .displayTypeDropdown {
      margin-left: 8px;
      &:global(.ui.dropdown) {
        display: inline-flex;
        align-items: center;

        i {
          margin-right: 0 !important;
          margin-left: 6px;
        }
      }

      .menu {
        width: fit-content;
        text-align: left;
        padding: 8px 12px;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: row;

      :global {
        .ui.button {
          margin: 0 6px;
          border: none;
          background-color: transparent;
          color: @PrimaryCobalt60;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          font-family: Gilroy;
          font-weight: 700;
          line-height: 24px;
          display: flex;

          .ui.label {
            padding: 0.3em 0.5em 0.2em 0.5em;
            margin-left: 1em;
            margin-top: -2px;
            vertical-align: middle;
            align-self: center;
            color: @PrimaryCobalt60;
            background-color: @PrimaryCobalt10;
          }

          &.active {
            background-color: @SecondarySkyBlue20;
            color: @SecondaryBlue;

            .ui.label {
              background-color: white;
              color: @SecondaryBlue;
            }

            &:hover {
              background-color: @SecondarySkyBlue40;
            }
          }

          &:hover {
            background-color: @PrimaryCobalt05;
          }

          .icon {
            margin-right: 8px;
            margin-top: -1px;
            display: inline-block;
          }
        }
      }
    }
  }

  .kpis {
    grid-row: 1 / span 2;
    grid-column: 2;
  }
}
