@import 'src/less/colors';

.section {
  margin: 16px 0;

  .description {
    margin-top: 12px;
    margin-bottom: 24px;
    display: flex;
    gap: 12px;
    align-items: center;

    .selector {
      flex-grow: 1;
    }
  }
}
