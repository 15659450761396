/*
 * colors.less
 */
.root {
  padding: 10px;
  background: white;
}
.form {
  padding-bottom: 60px;
  overflow: auto;
}
.form .actions {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  width: 350px;
  background-color: white;
  padding: 10px 0px;
}
.form .actions .submitButton {
  background-color: #4864c9 !important;
  border: 1px solid #4864c9 !important;
}
.form .actions .cancel {
  box-shadow: 0px 0px 0px 1px #4864c9 inset !important;
  color: #4864c9 !important;
  font-weight: 700;
  border-radius: 0.14285714rem;
  text-transform: none;
  text-shadow: none !important;
  background-color: white !important;
}
.form .title {
  color: #4864c9;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
}
.form::-webkit-scrollbar {
  display: none;
}
