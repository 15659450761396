/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.menu-templates {
  padding: 48px 70px 100px 70px;
  min-height: calc(100vh - 64px);
}
.menu-templates .archived-templates-link-box {
  text-align: center;
  margin: 30px auto;
}
.menu-templates .templates-header {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menu-templates .templates-header .templates-header-title {
  margin: 0;
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  color: #4864c9;
}
.menu-templates .templates-section {
  margin-top: 16px;
  margin-bottom: 142px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #dae0f4;
}
.menu-templates .templates-list-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}
.template-modal {
  width: 75%;
}
.template-modal .close.icon {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: #000000;
  opacity: 0.2;
}
.template-modal .close.icon::before {
  font-family: verdana;
  content: '✕';
  font-weight: bold;
}
.template-modal .close.icon:hover {
  opacity: 0.4;
}
.template-modal .header {
  background: #f8f8f8;
  border-bottom: none;
  border-radius: 3px !important;
  padding-top: 25px;
  padding-bottom: 19px;
  padding-left: 30px;
  padding-right: 30px;
}
.template-modal .header h4 {
  margin-bottom: 4px;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 17px;
  color: #1f2e77;
  letter-spacing: -0.21px;
}
.template-modal .header h5 {
  margin: 0;
  font-family: Graphik;
  font-size: 13px;
  font-weight: normal;
  color: #767689;
}
.template-modal .content {
  position: relative;
  background: #ffffff;
  padding-top: 30px / 2;
  padding-bottom: 30px / 2;
  padding-left: 30px;
  padding-right: 30px;
  font-family: Graphik;
  font-size: 15px;
  color: #2b3d4f;
  text-align: left;
}
.template-modal .content:after {
  position: absolute;
  bottom: 0px;
  left: 30px;
  content: '';
  width: 15%;
  height: 1px;
  opacity: 0.5;
  background: #d6d6dc;
}
.template-modal .actions {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  border: none;
  background: none;
}
.template-modal .actions .ui.button {
  margin-right: 0;
}
.template-modal .header {
  background: none;
  padding: 0;
}
.template-modal .header .leftCol {
  font-family: Graphik;
  padding: 20px !important;
  padding-left: 30px !important;
}
.template-modal .header .rightCol {
  background: #f8f8f8;
  padding: 20px !important;
}
.template-modal .content {
  padding: 0;
}
.template-modal .content .leftCol {
  padding: 30px !important;
}
.template-modal .content .leftCol .title-field {
  margin-bottom: 10px;
}
.template-modal .content .leftCol .title-field input {
  font-family: Graphik;
}
.template-modal .content .leftCol .editor-container {
  border: 1px solid #d6d6dc !important;
}
.template-modal .content .leftCol .email-subject {
  position: relative;
  border-bottom: none;
}
.template-modal .content .leftCol .email-subject::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 10px;
  background: #d6d6dc;
  height: 1px;
  width: calc(100% - 25px);
}
.template-modal .content .leftCol .body-and-signature .main-body {
  padding-top: 10px;
  border-bottom: none !important;
  min-height: 100px;
}
.template-modal .content .leftCol .body-and-signature .main-body .RichEditor-editor {
  border-bottom: none !important;
  min-height: 250px;
}
.template-modal .content .leftCol .body-and-signature .main-body .RichEditor-editor .public-DraftEditorPlaceholder-root,
.template-modal .content .leftCol .body-and-signature .main-body .RichEditor-editor .public-DraftEditor-content {
  min-height: 250px;
}
.template-modal .content .leftCol .body-and-signature .static-signature {
  padding-left: 20px;
  padding-bottom: 5px;
}
.template-modal .content .rightCol {
  background: #f8f8f8;
  padding: 30px !important;
  padding-top: 10px !important;
  padding-right: 80px !important;
  padding-bottom: 100px !important;
}
.template-modal .content .rightCol h2 {
  margin-bottom: 30px;
  font-family: Gilroy;
  font-size: 17px;
  color: #0087d5;
  letter-spacing: -0.2px;
  line-height: 25px;
}
.template-modal .content .rightCol h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 13px;
  color: #112e48;
  letter-spacing: 0;
  line-height: 21px;
}
.template-modal .content .rightCol .tip {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: left;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 12px;
  color: gray;
  letter-spacing: 0;
  line-height: 21px;
}
.template-modal .content .rightCol .admin-button {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 6px;
  background: purple;
  margin-right: 8px;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 11px;
  color: white;
  cursor: pointer;
}
.template-modal textarea {
  resize: none;
}
.template-modal .error-message {
  padding-left: 15px;
  font-family: Graphik;
  font-size: 13px;
  color: #e08383;
}
.template-modal .input-error {
  border-color: #e08383;
}
.template-table-action:not(:last-child) {
  margin-right: 8px;
}
