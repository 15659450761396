// Gilroy
@import 'variables';

@font-face {
  src: url(/fonts/Gilroy/Gilroy-Extrabold.otf);
  font-family: Gilroy;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Bold.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Semibold.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Regular.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url(/fonts/Gilroy/Gilroy-BoldItalic.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-SemiboldItalic.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-RegularItalic.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: italic;
}

// Graphik
@font-face {
  src: url(/fonts/Graphik/Graphik-Bold.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Semibold.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Medium.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Regular.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url(/fonts/Graphik/Graphik-BoldItalic.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-SemiboldItalic.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-MediumItalic.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-RegularItalic.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Regular.ttf);
  font-family: 'Lexend Exa';
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Bold.ttf);
  font-family: 'Lexend Exa';
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: '@{icomoon-font-family}';
  src: url('@{icomoon-font-path}/@{icomoon-font-family}.eot?akl2n2');
  src:
    url('@{icomoon-font-path}/@{icomoon-font-family}.eot?akl2n2#iefix')
      format('embedded-opentype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?akl2n2')
      format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?akl2n2')
      format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?akl2n2#@{icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
