/*
 * colors.less
 */
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Extrabold.otf);
  font-family: Gilroy;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Bold.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Semibold.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-Regular.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-BoldItalic.otf);
  font-family: Gilroy;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-SemiboldItalic.otf);
  font-family: Gilroy;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Gilroy/Gilroy-RegularItalic.otf);
  font-family: Gilroy;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Bold.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Semibold.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Medium.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-Regular.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-BoldItalic.otf);
  font-family: Graphik;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-SemiboldItalic.otf);
  font-family: Graphik;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-MediumItalic.otf);
  font-family: Graphik;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  src: url(/fonts/Graphik/Graphik-RegularItalic.otf);
  font-family: Graphik;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Regular.ttf);
  font-family: 'Lexend Exa';
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url(/fonts/LexendExa/LexendExa-Bold.ttf);
  font-family: 'Lexend Exa';
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'TaskIcons';
  src: url('/fonts/tasks/TaskIcons.eot?akl2n2');
  src: url('/fonts/tasks/TaskIcons.eot?akl2n2#iefix') format('embedded-opentype'), url('/fonts/tasks/TaskIcons.ttf?akl2n2') format('truetype'), url('/fonts/tasks/TaskIcons.woff?akl2n2') format('woff'), url('/fonts/tasks/TaskIcons.svg?akl2n2#TaskIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.actionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #f1f6ff;
  border-radius: 8px;
  margin: 8px 24px;
}
.title {
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
  line-height: 20px;
}
.description {
  margin-top: 4px;
  color: #1f2e77;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.button:global(.ui.button) {
  height: 32px;
  border-radius: 2px;
  padding: 0 12px;
  flex-shrink: 0;
  margin-left: 24px;
}
.button:global(.ui.button) > i {
  margin-top: -5px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
}
