@import 'src/less/colors';

.toggleContainer {
  &.small {
    height: 12px;
  }
  &.medium {
    height: 16px;
  }
  &.large {
    height: 22px;
  }
  margin-bottom: 12px;
}

:global(.ui.toggle.checkbox).toggle {
  &.small {
    height: 12px;
    label {
      height: 12px;
      line-height: 12px;

      &:before {
        width: 21px;
        height: 12px;
      }
      &:after {
        height: 10px;
        width: 10px;
        margin-top: 1px;
        margin-left: 1px;
      }
    }

    input:checked ~ label {
      color: @PrimaryCobalt !important;

      &:after {
        left: 10px !important;
      }
    }
  }
  &.medium {
    height: 16px;
    label {
      height: 16px;
      line-height: 16px;

      &:before {
        width: 28px;
        height: 16px;
      }
      &:after {
        height: 12px;
        width: 12px;
        margin-top: 2px;
        margin-left: 3px;
      }
    }

    input:checked ~ label {
      color: @PrimaryCobalt !important;

      &:after {
        left: 11px !important;
      }
    }
  }
  &.large {
    height: 22px;
    label {
      height: 22px;
      line-height: 22px;

      &:before {
        width: 35px;
        height: 20px;
      }
      &:after {
        height: 16px;
        width: 16px;
        margin-top: 2px;
        margin-left: 3px;
      }
    }

    input:checked ~ label {
      color: @PrimaryCobalt !important;

      &:after {
        left: 14px !important;
      }
    }
  }
  min-height: 0px;

  label {
    min-height: 0px;
    padding-left: 36px;
    padding-top: 0px;
    color: @PrimaryCobalt;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;

    &:before {
      background: @SecondaryBlue40 !important;
    }
    &:after {
      border: none;
      box-shadow: none;
      background: white;
      box-shadow: none !important;
    }
  }
}
