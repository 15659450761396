@import 'src/less/colors';

.title {
  margin-bottom: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: @PrimaryCobalt;
}

.loading {
  margin: 16px 0;
}

.emptyState {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;

  .illustration {
    @media (max-width: 1400px) {
      display: none;
    }
    min-width: 84px;
  }

  .noItem {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: @PrimaryCobalt60;
  }
}
