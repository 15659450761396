@import 'src/less/colors';

.dropdownContent {
  z-index: 2;
}

clickable {
  cursor: pointer;
}

.trigger {
  // 1em does not seem to work, maybe because of dropdown
  font-size: 12px;
}

.dropdown {
  color: @SecondarySkyBlue120;
}
