@import 'src/less/colors';

.container {
  display: inline-flex;
  gap: 8px;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      .label {
        color: @PrimaryCobalt;
      }
    }
  }

  .label {
    display: inline-block;
    color: @PrimaryCobalt60;
    font-size: 12px;
    font-weight: 500;
    font-family: Graphik;
  }
}
