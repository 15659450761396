/*
 * colors.less
 */
.columnSelectorTrigger {
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}
.columnsSelectorContainer {
  margin-bottom: 12px;
  max-height: 450px;
  overflow-y: auto;
}
h5.columnGroupTitle {
  color: #1f2e77;
  margin-top: 15px;
  margin-bottom: 5px;
}
.contextBtn {
  font-size: 17px;
  cursor: pointer;
}
.accordionGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordionGroup i {
  color: #1f2e77;
}
.accordionGroup .accordionTitle {
  color: #4864c9;
  font-weight: 400;
  font-size: 14px;
}
.button {
  width: 100%;
}
