@import 'src/less/colors';

.graph {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;

  .label {
    min-width: 240px;
    font-size: 14px;
    font-family: Graphik;
    font-weight: 500;
    color: @SecondaryBlue;
  }

  .scale {
    grid-column: 2;
    display: flex;
    justify-content: space-between;

    .tick {
      font-size: 12px;
      font-family: Graphik;
      color: @PrimaryCobalt60;
    }
  }
}
