/*
 * colors.less
 */
.itemList {
  width: 100%;
  overflow: overlay;
  overflow-x: hidden;
  height: 100%;
  max-height: 400px;
}
.itemList:has(.selectors) {
  margin-bottom: 37px;
}
.itemList.checkBoxItemList .item {
  border: none;
}
.itemList::-webkit-scrollbar-track {
  background: transparent !important;
}
.itemList .group {
  border-radius: 0px;
  padding: 4px 8px 4px 16px;
  background-color: #f8faff;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7982ad;
  border-top: 1px solid #dae0f4;
  border-bottom: none;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.itemList .item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  color: #4864c9;
  border-top: 1px solid #dae0f4;
  width: 100%;
  height: 37px;
  justify-content: space-between;
}
.itemList .item.selected {
  background-color: #f1f6ff;
}
.itemList .item .itemContent {
  flex: 1;
}
.itemList .item:first-of-type {
  border-top: none;
}
.itemList .item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.itemList .item.itemDisabled {
  background-color: #f8faff;
  color: #a5abc9;
  cursor: default;
}
.itemList .item.itemTitle {
  margin-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
}
.itemList .item.itemTitle .itemContent {
  display: flex;
  align-items: center;
  width: 100%;
}
.itemList .item.itemTitle .itemContent .itemCheckbox {
  margin-right: 8px;
}
.itemList .item.itemTitle .itemContent .itemName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemList .item:hover:not(.itemDisabled) {
  background-color: #f6f7fc;
}
.itemList .item .disabled {
  background-color: #a5abc9;
}
.itemList .selectors {
  background-color: white;
  border-top: 1px solid #dae0f4;
  position: absolute;
  bottom: -37px;
  left: 0;
  padding: 8px 12px;
  width: 100%;
}
.itemList .selectors span {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #4864c9;
  cursor: pointer;
  margin: 0px 4px;
}
.itemList .selectors span.disabled {
  color: #dae0f4;
}
