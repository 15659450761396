/*
 * colors.less
 */
.container {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
.container:disabled {
  cursor: not-allowed;
  filter: opacity(0.5);
}
.container:not(:disabled) {
  cursor: pointer;
}
.container:not(:disabled):hover .label {
  color: #1f2e77;
}
.container .label {
  display: inline-block;
  color: #7982ad;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik;
}
