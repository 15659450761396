/*
 * colors.less
 */
.hiresweetDetailsContainer {
  margin-top: 24px;
  color: #1f2e77;
}
.hiresweetDetailsContainer.plugin {
  margin-left: 12px;
  margin-right: 12px;
}
.hiresweetDetailsContainer .tableHeader {
  border: 1px solid #e2ebff;
  background-color: #f8faff;
}
.hiresweetDetailsContainer .tableCell {
  border: 1px solid #e2ebff;
  padding: 4px 8px;
}
.hiresweetDetailsContainer .pluginOffer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hiresweetDetailsContainer .pluginOffer .pluginOfferValue {
  display: flex;
  align-items: center;
  gap: 4px;
}
.hiresweetDetailsContainer .detailsCategory {
  margin: 24px 0px;
}
.hiresweetDetailsContainer .detailsCategory h5 {
  margin: 0;
}
.hiresweetDetailsContainer .detailsCategory .categoryColumns.plugin {
  display: block;
}
.hiresweetDetailsContainer .detailsCategory .categoryColumns:not(.plugin) {
  display: flex;
  gap: 48px;
}
.hiresweetDetailsContainer .detailsCategory .categoryItems {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.hiresweetDetailsContainer .detailsCategory .categoryItems .categoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
}
.hiresweetDetailsContainer .detailsCategory .categoryItems .categoryItem .itemName {
  text-transform: capitalize;
}
