/*
 * colors.less
 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .title {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
}
.modalContentContainer {
  padding: 0 !important;
}
.description {
  color: #7982ad;
  margin-bottom: 20px;
}
.fieldHeader {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: #1f2e77;
  margin-bottom: 5px;
}
.fieldHeader span {
  margin-bottom: -3px;
}
.toggle {
  margin-bottom: 0px;
  width: 2.5rem;
}
.modalForm {
  width: 100%;
}
.modalForm .modalContent {
  display: flex;
  max-height: 77svh;
  overflow: hidden;
}
.modalForm .modalContent .formContentContainer {
  padding: 24px;
}
.modalForm .modalContent .previewContainer,
.modalForm .modalContent .formContent {
  flex: 1 1;
  overflow: auto;
}
.modalForm .modalFormField {
  margin: 25px 0px !important;
}
.modalForm .modalFormField:first-of-type {
  margin-top: 0px !important;
}
.modalForm .modalFormInput {
  margin-top: 0px;
  border-radius: 8px !important;
  display: block;
  box-shadow: 0px 2px 4px #1f2e770f !important;
}
.modalForm .modalFormInput input {
  border-radius: 8px !important;
  border: 1px solid #dae0f4 !important;
  width: 100% !important;
}
.modalForm .optionWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.modalForm .optionInput {
  margin: 5px 0px;
  flex: 1;
}
.modalForm .optionInput input {
  border: 1px solid #dae0f4;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
  border-radius: 8px;
}
.modalForm .modalActions {
  padding: 16px 24px;
  border-top: 1px solid #dae0f4;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.modalForm .conditionsChainingForm {
  display: flex;
  width: 100%;
  gap: 20px;
}
.modalForm .conditionsChainingForm .left {
  flex: 1;
}
.modalForm .conditionsChainingForm .right {
  flex: 0.4;
  background: #f3f4f8;
}
.modalForm .conditionsChainingForm .padding {
  padding: 12px 24px 24px 24px;
}
@media only screen and (min-width: 1400px) {
  :global(.ui.modal).customActionFormModal {
    width: 1200px;
  }
}
