@import 'src/less/colors';

.fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #404564;
  display: flex;
  align-items: center;
  gap: 4px;
}

.connectPageWithPreview {
  display: flex !important;
  gap: 24px;
  min-height: 576px;
}

.showPlaceholder {
  color: @PrimaryCobalt40;
}

.connectPagePreviewContainer {
  flex: 1 1 0px;
  width: 0px;
}

.fieldsContainer {
  flex: 1 1 0px;
  width: 0px;
  input,
  div:global(.ui.selection.dropdown) {
    border-radius: 8px !important;
  }

  .customInputContainer {
    padding: 0.75em 1em;
    border: 1px solid @SecondaryBlue20;
    border-radius: 8px;
    display: inline-flex;
    cursor: text;
    width: 100%;

    .urlPrefix {
      flex-shrink: 1;
      white-space: nowrap;
    }
  }

  .customInput {
    flex-shrink: 1;
    min-width: 0px;
    input {
      padding: 0px !important;
      border: none !important;
      border-radius: 0px !important;
      background-color: transparent !important;
    }
  }
}

.fieldsSectionContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0px;

  .fieldsTagSection,
  .fieldsQuestionSection {
    display: flex;
    flex-direction: column;
    gap: 12px;

    span {
      color: @PrimaryCobalt;
      margin-bottom: -8px;
    }
  }

  .fieldsQuestionSection {
    flex-grow: 1;
  }
}

.modalActions:global(.actions) {
  button:first-of-type {
    padding-left: 16px !important;
  }
}

.urlCheckLoader:global(.ui.loader) {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  &::before  {
    border-color: @SecondaryBlue40 !important;
  }

  &::before,
  &::after {
    width: inherit;
    height: inherit;
  }
}

.pillMessage {
  padding: 2px 8px 0px !important;
}

.copyIcon {
  color: @PrimaryCobalt60;
  margin-left: 4px;
  cursor: pointer;
}

.externalLink {
  margin-left: 4px;
  margin-top: -1px;
  cursor: pointer;

  a {
    color: @PrimaryCobalt60;
  }
}
