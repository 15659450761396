@import 'src/less/colors';

.segmentationLine {
  display: flex;
  flex-flow: row nowrap;
  font-family: Graphik, sans-serif;
  color: @PrimaryCobalt40;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px 0 0;
  background-color: white;
  border: 1px solid @SecondaryBlue20;
  border-bottom-width: 0;
  overflow-x: auto;

  .startSeparator {
    border-left: 1px solid @SecondaryBlue20;
    font-family: Graphik;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: @PrimaryCobalt60;
    padding: 12px 12px 12px 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .endSeparator {
    border-right: 1px solid @SecondaryBlue20;
  }
}

.segmentationDropdown:global(.ui.dropdown) {
  border-right: 1px solid @SecondaryBlue20;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.segmentationLineItem {
  margin: 0px 12px;
  //padding: 12px 0 10px;
  //border-bottom: 2px solid transparent;
  cursor: pointer;
  //margin: 10px 16px;
  padding: 12px 12px;
  //border-radius: 4px;
  font-family: Graphik;
  font-weight: 500;
  font-style: normal;
  flex-shrink: 0;

  &.segmentationLineItemSelected {
    color: @SecondarySkyBlue100;
    //background-color: @SecondarySkyBlue20;
    border-bottom: 2px solid @SecondarySkyBlue100;
  }

  outline: 0;
  &:focus {
    color: @SecondarySkyBlue100;
  }
}

.itemDivider {
  display: inline-block;
  margin: 0 0.5rem;
}
