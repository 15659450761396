/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.offer-selector {
  min-width: 0;
  margin-right: 30px;
}
.offer-selector .ui.active.visible.dropdown div.menu.transition.visible .item {
  position: relative;
}
.offer-selector .ui.active.visible.dropdown div.menu.transition.visible .item.current {
  background-color: #f8f8f8;
}
.offer-selector .ui.active.visible.dropdown div.menu.transition.visible .item.current:after {
  content: none;
}
.offer-selector .new-tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.offer-selector .selector-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #1f2e77;
  padding: 0;
  margin: 0;
  text-align: left;
}
.offer-selector .selector-button.with-client .small {
  opacity: 0.6;
  font-family: Graphik;
  font-size: 11px;
  color: #1f2e77;
  letter-spacing: 0;
  line-height: 15px;
  text-align: left;
}
.offer-selector .selector-button.with-client .client-name-and-carret {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: black;
  line-height: 20px;
}
.offer-selector .selector-button.with-client .generic-dropdown .ui.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.offer-selector .selector-button.with-client .generic-dropdown .ui.dropdown .dropdown-trigger {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  color: #4864c9;
}
.offer-selector .selector-button.with-client .generic-dropdown .ri-arrow-drop-down-fill {
  margin-left: 8px;
  color: #a5abc9;
  font-size: 18px;
}
.offer-selector .offer-selector-menu {
  max-height: calc(100vh - 64px - 90px);
  overflow-y: auto;
}
