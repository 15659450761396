/*
 * colors.less
 */
.settingsDropdownContainer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-left: 10px;
  cursor: pointer;
  color: #7982ad;
}
.settingsDropdownContainer .dropdownTrigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.settingsDropdownContainer .dropdownTrigger .dropdownCaret {
  margin: 0;
  line-height: 16px;
  font-size: 16px;
  color: #1f2e77;
}
.settingsDropdownContainer .settingsDropdownMenu {
  top: 42px !important;
}
