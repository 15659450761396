@import 'src/less/colors';

.modalContentContainer {
  padding: 0 !important;
}

.modalForm {
  width: 100%;

  .modalContent {
    display: flex;
    max-height: 77svh;
    overflow: hidden;

    .formContentContainer {
      padding: 24px;
      overflow: auto;
      flex: 1 1;
    }

    .previewContainer {
      padding-bottom: 0px;
      background-color: @PrimaryCobalt05;
    }

    .formContent {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      border-right: 1px solid @SecondaryBlue20;
    }

    .previewContainer {
      flex: 1 1;
      overflow: auto;
    }
  }

  .modalActions {
    padding: 16px 24px;
    border-top: 1px solid @SecondaryBlue20;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
}
