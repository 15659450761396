@import 'src/less/colors';

.title {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  gap: 4px;
  padding-top: 16px;
  padding-bottom: 12px;

  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid @PrimaryCobalt20;
  color: @PrimaryCobalt;

  cursor: pointer;

  &:hover {
    background-color: @SecondaryBlue05;
  }
}
