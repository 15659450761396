/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.followup-kanban {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.followup-kanban .backdrop {
  background-color: #1f2e77;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.followup-kanban .followup-kanban-board {
  height: 100%;
  width: 100%;
  padding: 0;
  margin-top: 0;
  max-height: 100%;
  background-color: transparent;
}
.followup-kanban .followup-kanban-board > div {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}
.followup-kanban .followup-kanban-board .smooth-dnd-container.horizontal {
  display: flex;
  height: 100%;
}
.followup-kanban .followup-kanban-board .smooth-dnd-container.vertical {
  min-height: 100%;
  box-sizing: border-box;
  overflow-y: visible;
  padding-bottom: 20px;
}
.followup-kanban .followup-kanban-board .react-trello-lane {
  flex-grow: 0;
  padding: 0px;
  flex-basis: 25%;
  max-height: 100%;
  max-width: 25%;
  margin: 0px 0px;
  height: 100%;
  background: transparent;
}
.followup-kanban .followup-kanban-board .react-trello-lane > div {
  width: 100%;
  padding: 0px 20px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.followup-kanban .followup-kanban-board .react-trello-lane > div ::-webkit-scrollbar {
  display: none;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-title-text,
.followup-kanban .followup-kanban-board .react-trello-lane header {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #1f2e77;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-divider {
  height: 3px;
  width: 100%;
  border-radius: 8px;
  margin-top: 9px;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-divider.grey {
  background-color: #d2d5e4;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-divider.dark-blue {
  background-color: #4864c9;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-divider.light-blue {
  background-color: #8db1ff;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-divider.green {
  background-color: #28ca42;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-title-count {
  margin-left: 6px;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  color: #4864c9;
  background-color: #e2ebff;
  height: 20px;
  border-radius: 2px;
  padding: 0px 4px;
  display: flex;
  align-items: baseline;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-dropdown {
  flex-grow: 1;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-dropdown .kanban-column-dropdown {
  margin-left: 20px;
  width: auto;
  float: right;
  text-align: right;
  border: none !important;
  background-color: transparent;
  padding-right: 16px;
  box-shadow: none !important;
  color: #7982ad;
  font-size: 12px;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-dropdown .kanban-column-dropdown .text {
  white-space: nowrap;
  color: #7982ad;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-dropdown .kanban-column-dropdown i {
  padding-right: 0;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-dropdown .kanban-column-dropdown .menu {
  border: none;
  width: auto;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 4px;
}
.followup-kanban .followup-kanban-board .react-trello-lane .lane-dropdown .kanban-column-dropdown .menu .header {
  font-size: 12px;
  color: #7982ad;
  text-transform: none;
  font-weight: normal;
}
.followup-kanban .smooth-dnd-draggable-wrapper {
  overflow: unset !important;
  width: 100%;
}
.followup-kanban .smooth-dnd-draggable-wrapper:not(:last-child) {
  margin-bottom: 12px;
}
.followup-kanban .smooth-dnd-draggable-wrapper.smooth-dnd-ghost {
  box-shadow: none;
}
