/*
 * colors.less
 */
.holdNudge {
  padding: 0 80px 0 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.holdNudge .left {
  display: flex;
  align-items: center;
  gap: 8px;
}
.holdNudge .suspendedIcon {
  color: transparent;
}
.holdNudge .suspendedIcon path {
  color: #7982ad;
}
.holdNudge .suspendedIcon circle {
  stroke: #7982ad;
}
.holdNudge .title {
  font-weight: 500;
}
