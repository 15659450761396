@import 'src/less/colors';

.handler {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid @SecondaryBlue20;
}

.header {
  min-height: 32px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  color: @PrimaryCobalt60;
}

.option {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.name {
  flex: 1;
}
