@import 'src/less/colors';

.nameAndHeadline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;

  .fullname {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    color: @SecondaryBlue;

    .fullnameText {
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      color: @SecondaryBlue;
      &.clickable:hover {
        text-decoration: underline;
      }
    }

    .icon {
      vertical-align: middle;
      opacity: 0.7;
      padding-right: 3px;

      &::before {
        vertical-align: initial;
      }
    }
  }

  .headline {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: @PrimaryCobalt80;
  }
}
