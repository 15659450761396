/*
 * colors.less
 */
.new-sequence-preview {
  display: flex;
  height: 100%;
}
.new-sequence-preview > div {
  padding-bottom: 30px;
}
.new-sequence-preview .sequence-template-selector {
  padding-left: 57px;
  padding-right: 50px;
  flex-basis: 30%;
  overflow-y: scroll;
  box-shadow: inset -1px 0px 0px #dae0f4;
}
.new-sequence-preview .sequence-template-selector .sequence-template.ui.segment {
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  margin-bottom: 18px;
}
.new-sequence-preview .sequence-template-selector .sequence-template-icons {
  display: flex;
}
.new-sequence-preview .sequence-template-selector .sequence-template-icons .task-icon {
  border-radius: 50%;
  margin: 0 5px;
}
.new-sequence-preview .sequence-template-selector .sequence-template-icons .icon-wrapper:not(:last-child)::after {
  content: '';
  height: 1px;
  width: 8px;
  background: #d2d5e4;
  display: inline-block;
  position: relative;
  top: -3px;
  margin: 0 1px;
}
.new-sequence-preview .sequence-template-selector .empty-sequence-template {
  display: flex;
}
.new-sequence-preview .sequence-template-selector .empty-sequence-template .icon-container {
  flex-shrink: 0;
  background: #fef4f4;
  height: 48px;
  width: 48px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-sequence-preview .sequence-template-selector .empty-sequence-template .icon-container i {
  font-size: 20px;
  color: #e45a6b;
}
.new-sequence-preview .sequence-template-selector .templates-list-section-title {
  color: #4c5892;
  font-weight: 700;
  font-size: 14px;
}
.new-sequence-preview .sequence-template-selector .sequence-template-title {
  margin-bottom: 12px;
  color: #1f2e77;
}
.new-sequence-preview .sequence-template-selector .sequence-template-description {
  color: #4c5892;
}
.new-sequence-preview .sequence-template-selector .selected-sequence-template {
  border: 1px solid #5c89eb;
}
.new-sequence-preview .sequence-template-selector .selected-sequence-template .sequence-template-title {
  color: #4864c9;
}
.new-sequence-preview .new-sequence-preview-display {
  border-left: 1px solid #f3f4f8;
  padding-left: 70px;
  padding-right: 70px;
  flex-basis: 70%;
  overflow-y: scroll;
}
.new-sequence-preview .new-sequence-preview-display .preview-display-header {
  margin-bottom: 40px;
  color: #4c5892;
}
.new-sequence-preview .new-sequence-preview-display .preview-display-header > h2 {
  color: #4864c9;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.new-sequence-preview .new-sequence-preview-display .preview-display-header p {
  color: #1f2e77;
}
.new-sequence-preview .new-sequence-preview-display .create-first-step {
  text-align: center;
}
.new-sequence-preview .new-sequence-preview-display .create-first-step .create-first-step-title {
  color: #1f2e77;
}
.new-sequence-preview .new-sequence-preview-display .create-first-step .create-first-step-description {
  color: #4c5892;
}
