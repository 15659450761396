@import 'src/less/colors';

.button {
  display: inline-flex;
  padding: 0 16px;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: 8px;
  font-family: Gilroy;
  font-weight: 700;

  transition: color ease 150ms, background-color ease 150ms,
    border-color ease 150ms;

  &.icon {
    padding: 0;
  }

  &:is(button):not(:disabled),
  &:is(a):not(.disabled) {
    cursor: pointer;
  }
}

.small {
  height: 24px;
  font-size: 12px;
  padding: 0 8px;

  &.icon {
    width: 24px;
  }
}

.normal {
  height: 32px;
  font-size: 14px;

  &.icon {
    width: 32px;
    font-size: 16px;
  }
}

.big {
  height: 40px;
  font-size: 16px;

  &.icon {
    width: 40px;
    font-size: 20px;
  }
}

.primary {
  color: white;

  &:is(button):disabled,
  &:is(a).disabled {
    background-color: @SecondaryBlue40;
  }

  &:is(button):not(:disabled),
  &:is(a):not(.disabled) {
    &.blue {
      background-color: @SecondaryBlue;
      &:hover {
        background-color: @SecondaryBlue120;
      }
    }

    &.red {
      background-color: @SecondaryRed;
      &:hover {
        background-color: @SecondaryRed120;
      }
    }
  }
}

.secondary {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: currentColor;

  &:is(button):disabled,
  &:is(a).disabled {
    color: @SecondaryBlue40;
  }

  &:is(button):not(:disabled),
  &:is(a):not(.disabled) {
    box-shadow: 0 2px 4px 0 @PrimaryCobaltOp06;

    &.blue {
      color: @SecondaryBlue;
      &:hover {
        color: @SecondaryBlue120;
        background-color: @SecondaryBlue10;
      }
    }

    &.red {
      color: @SecondaryRed;
      &:hover {
        color: @SecondaryRed120;
        background-color: @SecondaryRed10;
      }
    }

    &.grey {
      color: @PrimaryCobalt60;
      border-color: @SecondaryBlue40;
      &:hover {
        color: @PrimaryCobalt80;
        background-color: @SecondaryBlue05;
      }
    }
  }
}

.tertiary {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: @PrimaryCobalt60;

  &:is(button):disabled,
  &:is(a).disabled {
    color: @SecondaryBlue40;
  }

  &:is(button):not(:disabled),
  &:is(a):not(.disabled) {
    &:hover {
      color: @PrimaryCobalt80;
      background-color: @SecondaryBlue05;
      border-color: @SecondaryBlue20;
      box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
      transition: all ease 150ms;
    }
  }
}
