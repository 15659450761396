@import 'src/less/colors.less';

.container {
  display: block;
}

.container:not(:last-child) {
  margin-bottom: 8px;
  text-decoration: none;
}

.listButton {
  border-radius: 8px;
  border: 1px solid @SecondaryBlue20;

  height: 64px;
  padding: 12px;

  display: flex;
  align-items: center;
}

.listButton > div {
  font-family: Graphik, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: @PrimaryCobalt;
}

div.linkIcon {
  display: block;
  margin-left: auto;
  font-size: 18px;

  display: flex;
  align-items: center;
}
