@import 'src/less/colors';

.copyToClipboardWrapper {
  display: flex;
  justify-content: flex-end;

  .copyToClipboard {
    border-radius: 2px;
    border: 1px solid #dae0f4;
    background-color: #ffffff !important;
    color: @PrimaryCobalt60 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;

    &.minimized {
      font-size: 12px;
      padding: 1px 2px;
    }
  }

  .textToCopy {
    cursor: pointer;
  }

  .hiddenCopy {
    position: absolute;
    top: -999999999999px;
    left: -999999999999px;
  }
}
