/*
 * colors.less
 */
.task-table.ui.segment {
  padding: 0;
  border-radius: 8px;
}
.task-table.ui.segment .empty-tasks-table {
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #1f2e77;
}
.task-table-header {
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 12px 12px 22px;
  align-items: center;
}
.task-table-header .header-title {
  margin: 0;
  padding: 0;
  color: #4864c9;
}
.task-table-header .header-title img {
  margin-right: 10px;
  vertical-align: text-bottom;
}
.task-table-header .header-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.task-table-header:empty {
  padding: 0;
}
.bulk-emails-modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
