@import 'src/less/colors';

.row {
  &:not(:last-child) {
    border-bottom: 1px solid @SecondaryBlue20;
  }

  .main {
    display: flex;
    align-items: center;
    font-family: Graphik;
    font-weight: 500;
    font-size: 14px;
    color: @PrimaryCobalt80;
    padding: 18px 24px;

    .name {
      flex: 1;
      min-width: 0;
      display: flex;
      align-items: center;
      gap: 16px;

      .link {
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: Graphik;
        font-weight: 500;
        font-size: 14px;
        color: @SecondaryBlue;

        &:hover {
          text-decoration: underline;
        }
      }

      .prefixToggle {
        font-size: 14px;
        color: @SecondaryBlue;
      }
    }
  }
}
