/*
 * colors.less
 */
.emptyState {
  padding: 24px;
  justify-content: space-between;
}
.emptyState img {
  width: 100%;
}
