/*
 * colors.less
 */
.partitioningSelector .partitioningDropdown {
  height: 32px;
  min-height: 32px !important;
  max-height: 32px;
  padding: 0;
  font-size: 12px;
  margin: calc(0.5em + 1px) 1em;
  line-height: 9px !important;
}
.partitioningSelector .partitioningDropdown :global(.dropdown.icon) {
  line-height: 7px !important;
}
.partitioningSelector .label {
  color: #7982ad;
  margin-right: 1em;
}
