.wrapper {
  padding: 30px;
}
.wrapper .content {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper .textAndSnippets {
  width: 45vw;
  height: 600px;
  background-color: #ebebeb;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
