/*
 * colors.less
 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .title {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
}
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 100px;
}
.modalContent .loader:after {
  border-color: #1f2e77 transparent transparent !important;
}
.modalContent .checkmark {
  background-color: #1f2e77;
  color: white;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin: auto;
}
.modalContent .modalSuccess {
  text-align: center;
}
.modalContent .modalSuccess svg {
  margin-bottom: 24px;
}
.modalContent .cross {
  background-color: #f66f81;
  color: white;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin: auto;
}
.modalContent .inputLabel {
  margin-bottom: 6px !important;
  font-weight: 500 !important;
}
.formField:first-of-type {
  margin-bottom: 16px;
}
.formField .formLabel {
  margin: 0px 0px 4px 0px;
  display: block;
  color: #1f2e77;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.confirmationModalActions.reversed {
  flex-direction: row-reverse;
}
.description {
  color: #7982ad;
  margin-bottom: 20px;
}
.editApiKey {
  text-decoration: underline;
  cursor: pointer;
}
.modalActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.deleteModalContent {
  color: #1f2e77;
}
