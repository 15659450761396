@import 'src/less/colors';

@ScaleHeight: 300px;

.graph {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 32px 1fr;
  padding-bottom: 16px;

  .scale {
    height: @ScaleHeight;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: right;

    .tick {
      font-size: 12px;
      font-family: Graphik;
      color: @PrimaryCobalt60;
    }
  }

  .dataContainer {
    grid-column: 2;
    min-width: 0;

    .scrollableData {
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: row-reverse;
      padding: 10px 0 200px 0;
      margin: -10px 0 -200px 0;

      .data {
        flex: 1;
        display: inline-grid;
        grid-template-rows: @ScaleHeight auto;
        align-items: stretch;

        .rulers {
          grid-row: 1;
          grid-column: 1;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          padding: 9px 0;

          .ruler {
            border-bottom-width: 1px;
            border-bottom-color: @SecondaryBlue20;

            &:not(:first-child) {
              border-bottom-style: dashed;
            }

            &:first-child {
              border-bottom-style: solid;
            }
          }
        }

        .areas {
          grid-row: 1;
          grid-column: 1;
          display: flex;
          align-items: stretch;
          padding: 10px 16px;

          .section {
            min-width: 80px;
            flex: 1;
          }
        }

        .labels {
          grid-row: 2;
          grid-column: 1;
          display: flex;
          justify-content: space-evenly;
          gap: 16px;
          padding: 0 16px;

          .label {
            min-width: 80px;
            flex-basis: 240px;
            flex-shrink: 1;
            text-align: center;
            color: @PrimaryCobalt60;
          }
        }
      }
    }
  }
}
