/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.common-style:focus {
  outline: 0;
}
.button-common-style:focus {
  outline: 0;
}
.button-common-style:hover {
  cursor: pointer;
}
.button-common-style::selection {
  cursor: pointer;
  border: none;
}
.input-common-style:focus {
  outline: 0;
}
.comment-generic-content .comment-content .editor-container {
  padding-bottom: 10px;
}
.comment-generic-content .comment-content .main-body.RichEditor-editor {
  border-bottom: none !important;
}
.comment-generic-content .comment-content .main-body.RichEditor-editor.RichEditor-editor {
  border-bottom: none !important;
  min-height: 100px;
}
.comment-generic-content .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditorPlaceholder-root,
.comment-generic-content .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.comment-generic-content .comment-content .save-comment-button {
  position: relative;
  top: -42px;
  float: right;
  right: 5px;
  width: 110px;
}
.timeline-default-card-wrapper.classic-item .editor-container {
  border: 1px solid #dae0f4 !important;
  border-radius: 4px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu {
  min-height: 20px;
  padding-left: 15px;
  border-bottom: 1px solid #dae0f4;
  margin: 0px;
  margin-bottom: 20px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #b6c1e9;
  padding: 0px 20px 8px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item.active {
  color: #4864c9;
  border-color: #4864c9;
  border-width: 2px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item.active:hover {
  color: #4864c9;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item:hover {
  color: #6d83d4;
}
.timeline-default-card-wrapper .comment-content .editor-container {
  padding-bottom: 10px;
}
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor {
  border-bottom: none !important;
}
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor.RichEditor-editor {
  border-bottom: none !important;
  min-height: 100px;
}
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditorPlaceholder-root,
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.timeline-default-card-wrapper .comment-content .save-comment-button {
  position: relative;
  top: -42px;
  float: right;
  right: 5px;
  width: 110px;
}
.timeline-default-card-wrapper .email-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
.timeline-default-card-wrapper .email-content .email-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}
.timeline-default-card-wrapper .email-content .email-header .email-title {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #1f2e77;
}
.timeline-default-card-wrapper .email-content .email-header .sending {
  font-size: 14px;
  font-weight: 600;
}
.timeline-default-card-wrapper .email-content .email-header .sending i.icon {
  height: 20px;
}
.timeline-default-card-wrapper .email-content .email-header button {
  white-space: nowrap;
}
.timeline-default-card-wrapper .email-content .email-header #button-ask-send {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 30px 6px 23px;
}
.timeline-default-card-wrapper .email-content .email-header #button-ask-send i.icon {
  height: 20px;
}
.timeline-default-card-wrapper .email-content .email-header .button-sobo {
  background-color: #709dff;
}
.timeline-default-card-wrapper .email-content .email-header .send.icon {
  font-size: 12px;
  margin-right: 8px;
}
.timeline-default-card-wrapper .email-content .email-header #button-gmail-connexion,
.timeline-default-card-wrapper .email-content .email-header #button-outlook-connexion {
  flex-grow: 1;
}
.timeline-default-card-wrapper .email-content .email-header #button-connection-in-progress {
  flex-grow: 1;
  margin-right: 0;
}
.timeline-default-card-wrapper .email-content .email-header #button-connection-in-progress span {
  margin-left: 10px;
}
.timeline-default-card-wrapper .email-content .email-body {
  margin: 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies {
  width: fit-content;
  cursor: pointer;
  font-size: 13px;
  margin-top: 8px;
  font-family: Graphik;
  max-width: 100%;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies > span {
  white-space: nowrap;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #4864c9;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies > span:hover {
  text-decoration: underline;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies > span .template-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies svg {
  margin-right: 0px;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies + .email-body-editor {
  margin-top: 4px;
}
.timeline-default-card-wrapper .email-content .email-body .email-info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 3px 3px 0 0;
  font-weight: normal;
  font-size: 13px;
  color: #2b3d4f;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}
.timeline-default-card-wrapper .email-content .email-body .email-info .to {
  color: #adadb7;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor {
  margin-top: 12px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body {
  border-bottom: none !important;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body .RichEditor-editor {
  border-bottom: none !important;
  min-height: 250px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body .RichEditor-editor .public-DraftEditorPlaceholder-root,
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body .RichEditor-editor .public-DraftEditor-content {
  min-height: 250px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .static-signature {
  padding-left: 0px;
  padding-bottom: 5px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .empty-menu {
  height: 15px;
}
.timeline-default-card-wrapper .email-content .email-body .email-subject.RichEditor-editor {
  margin-bottom: 10px !important;
  color: #4c5892;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 600;
}
.timeline-default-card-wrapper .email-content .email-body .email-subject,
.timeline-default-card-wrapper .email-content .email-body .email-message {
  resize: none;
}
.timeline-default-card-wrapper .email-content .email-body .email-subject:focus,
.timeline-default-card-wrapper .email-content .email-body .email-message:focus {
  outline: 0;
}
.timeline {
  position: relative;
  margin: 0 0 60px;
}
.timeline h3.section-title {
  padding-left: 0;
  margin-bottom: 15px;
  margin-top: 25px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #a5abc9;
}
.timeline h3.section-info {
  margin-bottom: 15px;
  margin-top: 25px;
  font-family: Graphik, sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: #a5abc9;
}
.timeline-card-mail-wrapper {
  padding: 0;
  position: relative;
  font-size: 12px;
}
.timeline-card-mail-wrapper.classic-item {
  background-color: white;
  border: 1px solid #e2ebff;
  word-break: break-word;
  margin: 5px 0 1rem 0;
  padding: 0px 12px 11px 12px;
}
.timeline-card-mail-wrapper.classic-item .author span,
.timeline-card-mail-wrapper.classic-item .author :not(.date) {
  font-weight: 500;
  color: #10173d;
}
.timeline-card-mail-wrapper.classic-item .card-content.message,
.timeline-card-mail-wrapper.classic-item .card-content.comment {
  margin-left: 12px !important;
  border: none;
  padding-left: 0;
  font-size: 13px;
  margin-top: 20px;
}
.timeline-card-mail-wrapper.classic-item .card-content.message .row,
.timeline-card-mail-wrapper.classic-item .card-content.comment .row {
  display: flex;
  margin-bottom: 18px;
}
.timeline-card-mail-wrapper.classic-item .card-content.message .row .show-more,
.timeline-card-mail-wrapper.classic-item .card-content.comment .row .show-more {
  width: auto;
  margin: 0;
}
.timeline-card-mail-wrapper .modify-button:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.timeline-card-mail-wrapper .content {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 2px;
}
.timeline-card-mail-wrapper .content .timeline-line {
  border-left: 1px solid #dae0f4;
  position: absolute;
  top: 0;
  left: 15.5px;
  height: 100%;
  z-index: 0;
}
.timeline-card-mail-wrapper .content .header {
  padding: 15px 0px 4px 0px;
  width: 100%;
  display: flex;
}
.timeline-card-mail-wrapper .content .header .icon-container {
  margin-right: 8px;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon {
  position: relative;
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  color: white;
  margin-top: 5px;
  border: 3px solid #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon svg {
  margin-left: 3px;
  margin-top: 2px;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon h1 {
  background-color: #f8f8f8;
  margin-top: 5px;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon .custom-task-icon {
  height: 26px;
  width: 26px;
  background-color: #f8f8f8;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon:has(.custom-task-icon-text) {
  background: #f8f8f8;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon .custom-task-icon-text {
  background-color: #f8f8f8;
  font-size: 24px;
  margin-top: 2px;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon img {
  border-radius: 14px;
}
.timeline-card-mail-wrapper .content .header .icon-container .icon i {
  /* text-align: center; */
  margin: auto;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  height: 26px;
  width: 26px;
  font-size: 16px;
}
.timeline-card-mail-wrapper .content .header .ats-logo {
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 15px;
  text-align: center;
}
.timeline-card-mail-wrapper .content .header .image {
  border-radius: 50%;
}
.timeline-card-mail-wrapper .content .header .author {
  min-height: 40px;
  font-family: Graphik !important;
  font-family: Gilroy;
  font-weight: 400;
  word-break: break-word;
  min-width: 0;
  font-size: 13px;
  color: #1f2e77;
}
.timeline-card-mail-wrapper .content .header .author b {
  font-weight: 500;
}
.timeline-card-mail-wrapper .content .header .author .light-bold {
  color: #4864c9;
}
.timeline-card-mail-wrapper .content .header .author .ui.label {
  background-color: #e2ebff;
  color: #4864c9;
}
.timeline-card-mail-wrapper .content .header .author .date {
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #7982ad;
  line-height: 20px;
}
.timeline-card-mail-wrapper .content .header .author .date .sub-ats-icon img {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.timeline-card-mail-wrapper .content .header .author .ats-synchronization {
  cursor: pointer;
  margin-left: 5px;
}
.timeline-card-mail-wrapper .content .header .timeline-item-actions {
  margin-left: auto;
}
.timeline-card-mail-wrapper .content .header .timeline-item-actions .ats-status {
  color: #7982ad;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
}
.timeline-card-mail-wrapper .content .header .buttons-container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 0;
  border: none;
  background-color: transparent;
  margin: 0;
}
.timeline-card-mail-wrapper .content .header .buttons-container button {
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}
.timeline-card-mail-wrapper .content .header .buttons-container button img.trash-svg {
  opacity: 0.6;
  width: 10px;
  height: 13px;
  padding-bottom: 3px;
}
.timeline-card-mail-wrapper .content .header .tracking {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: right;
  color: #4864c9;
  opacity: 0.5;
}
.timeline-card-mail-wrapper .content .header .tracking.active {
  opacity: 1;
}
.timeline-card-mail-wrapper .content .card-content {
  margin-left: 40px;
  padding: 12px;
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 4px;
}
.timeline-card-mail-wrapper .content .card-content .row {
  margin: 0px;
}
.timeline-card-mail-wrapper .content .card-content .body {
  padding: 0;
  word-break: break-word;
}
.timeline-card-mail-wrapper .content .card-content.comment.edit-mode .comment-content .editor-container {
  padding-bottom: 10px;
}
.timeline-card-mail-wrapper .content .card-content.comment.edit-mode .comment-content .main-body.RichEditor-editor {
  border-bottom: none !important;
}
.timeline-card-mail-wrapper .content .card-content.comment.edit-mode .comment-content .main-body.RichEditor-editor.RichEditor-editor {
  border-bottom: none !important;
  min-height: 100px;
}
.timeline-card-mail-wrapper .content .card-content.comment.edit-mode .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditorPlaceholder-root,
.timeline-card-mail-wrapper .content .card-content.comment.edit-mode .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.timeline-card-mail-wrapper .content .card-content.comment.edit-mode .comment-content .save-comment-button {
  position: relative;
  top: -42px;
  float: right;
  right: 5px;
  width: 110px;
}
.timeline-card-mail-wrapper .content .body {
  padding: 20px 30px 30px 30px;
  position: relative;
  transition: height 250ms;
}
.timeline-card-mail-wrapper .content .body .gradient-message {
  position: absolute;
  bottom: 0%;
  left: 0;
  height: 150px;
  width: 100%;
  opacity: 1;
  pointer-events: none;
  transition: opacity 100ms 150ms;
  background-color: transparent;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 40%);
}
.timeline-card-mail-wrapper .content .body .gradient-message.hidden {
  opacity: 0;
}
.timeline-card-mail-wrapper .content .body .body-content {
  overflow: hidden;
  height: 100%;
}
.timeline-card-mail-wrapper .content .title {
  color: #10173d;
  font-family: Gilroy;
  font-weight: 600;
  display: block;
}
.timeline-card-mail-wrapper .content .mt {
  margin-top: 10px;
}
.timeline-card-mail-wrapper .content .blur-effect {
  background-color: rgba(255, 255, 255, 0);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #ffffff);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), #ffffff);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), #ffffff);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0), #ffffff);
  height: 100px;
  width: 93%;
  position: absolute;
  bottom: 28px;
  left: 20px;
}
.timeline-card-mail-wrapper .content .show-more {
  margin-right: -15px;
  width: 130px;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 4px;
  cursor: pointer;
  font-family: 'Graphik';
}
.timeline-card-mail-wrapper .content .row {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
}
.timeline-card-mail-wrapper .content .row .spacer {
  flex-grow: 1;
}
.email-tracking-popup {
  font-size: 13px;
  min-width: 400px;
}
.email-tracking-popup .no-events {
  opacity: 0.7;
}
.dense .content .header,
.dense .content .body,
.dense .content .editor-container {
  padding: 10px;
}
