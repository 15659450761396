/*
 * main.less
 */
@import 'src/less/mixins';
@import 'src/less/fonts';
@import 'src/less/icons';
@import 'src/less/typography';

// DEFAULT COMP
body {
  width: 100%;
}

body .ui.input > input {
  &:focus {
    border-color: @PrimaryColorLight;
  }
}

.row,
.wide.column,
.ui-grid > * {
  padding: 0 !important;
}

// BUTTONS
//.button-warning, .ui.button.button-warning, button.ui.negative.button {
.button-warning,
.ui.button.button-warning {
  .Button(@color: @SecondaryColor; @backgroundColor: #ffffff);

  &:hover {
    .Button(@color: #ffffff; @backgroundColor: @SecondaryColor);
  }

  line-height: 20px;
  padding: 5px 15px; // 5px 15px to take border into account
  border-radius: 4px !important;
}

//.button-default, .ui.button.button-default, button.ui.positive.button {
.button-default,
.ui.button.button-default {
  .Button(@color: @PrimaryColorLight; @backgroundColor: #ffffff);

  &:hover {
    .Button(@color: #ffffff; @backgroundColor: @PrimaryColorLight);
  }

  line-height: 20px;
  padding: 5px 15px; // 5px 15px to take border into account
  border-radius: 4px !important;
}

.primary-cta,
.ui.button.primary-cta,
a.primary-cta {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  color: white;
  background-color: @SecondaryBlue;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  height: 32px; // force border to go inside when using .Button
  line-height: 20px;
  padding: 6px 16px;
  transition: 150ms;
  justify-content: center;
  align-items: center;

  &:active:not(:disabled) {
    background: @SecondaryBlue;
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
    height: 13px;
  }

  i.ri-add-line {
    font-size: 16px;
    font-weight: 600;
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  &:hover {
    background-color: @SecondaryBlue120;
  }
}

.modal-actions-buttons {
  text-align: right;

  button:last-child {
    margin-left: 5px;
  }

  .flex-spaced-between {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.confirm-button,
.ui.button.confirm-button,
a.confirm-button {
  min-width: 100px;

  &.primary {
    .FilledButton();
  }

  &.secondary {
    .FilledButton(white; @SecondaryColor; @SecondaryColorLight);
  }
}

.dismiss-button,
.ui.button.dismiss-button,
a.dismiss-button {
  min-width: 100px;

  &.primary {
    .BorderedButton();
  }

  &.secondary {
    .BorderedButton(@SecondaryColor; @SecondaryColorLight);
  }
}

.hs-default-button,
.hs-default-button.ui.button,
a.hs-default-button,
.default-button,
.ui.button.default-button,
a.default-button {
  .BicolorBorderedButton(
    @PrimaryCobalt80; rgba(34, 36, 38, 0.15) ; rgba(34, 36, 38, 0.35) ;
      @PrimaryCobalt80
  );
  display: flex;
  align-items: center;
}

.hs-default-dropdown.ui.dropdown {
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  min-height: 32px;
  padding: 0 24px 0 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);

  .dropdown.icon {
    height: 32px;
    padding: 9px;
  }
}

.ui.dropdown {
  .selected.item {
    background-color: unset !important;
    color: @PrimaryCobalt80 !important;

    &:hover {
      background-color: #edf0fa !important;
    }
  }
}

.link-button,
.ui.button.link-button {
  font-family: Gilroy;
  color: @PrimaryCobalt80;
  font-size: 12px;
  text-decoration: underline;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
}

.primary-link-button {
  background-color: transparent;
  font-weight: normal;
  font-size: 13px;
  color: @PrimaryColorLight;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  outline: none;

  &:focus {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ui.button.tertiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 12px 5px 12px;
  font-family: Graphik, sans-serif;
  color: @PrimaryCobalt60;
  border-radius: 2px;
  border: 1px solid @PrimaryCobalt20;
  background-color: transparent;

  &:hover {
    border-color: @PrimaryCobalt40;
    background-color: transparent;
  }

  &:focus {
    color: @PrimaryCobalt80;
    border-color: @PrimaryCobalt80;
    background-color: transparent;
  }

  &.negative {
    color: @NegativeColor;
    border-color: @NegativeColor80;

    &:hover {
      color: @NegativeColor;
      border-color: @NegativeColor;
    }

    &:focus {
      color: @NegativeColor120;
      border-color: @NegativeColor120;
    }
  }

  i {
    font-size: 16px;
    margin-right: 8px;
  }
}

// DROPDOWNS
.ui.dropdown .active.selected.item {
  font-weight: 600 !important;
}

.ui.form input {
  &:focus {
    border-color: @PrimaryColorLight !important;
  }
}

// WORKAROUND FOR MODAL LOADERS
// https://github.com/Semantic-Org/Semantic-UI-React/issues/3133
.ui.dimmer .ui.loader.on-dimmer {
  &:before {
    border-color: rgba(0, 0, 0, 0.1);
  }

  &:after {
    border-color: #767676 transparent transparent;
  }
}

// Empty pages
.no-items-container {
  height: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  .no-items {
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: @PrimaryColor;
    margin-top: 2em;
  }
}

.code-text {
  color: @White;
  background-color: #505050;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
}

.hs-dropdown-menu,
.ui.dropdown .menu.hs-dropdown-menu {
  border-radius: 2px;
  //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  //box-shadow: none;
  // or ?
  //border: none;
  width: 264px;

  .dropdown-item,
  .item.dropdown-item {
    padding: 10px 18px !important;

    &.first {
      padding-top: 18px !important;
    }

    &.last {
      padding-bottom: 18px !important;
    }

    font-family: Graphik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @PrimaryCobalt60;
    //margin: 20px 18px;

    &:hover {
      color: @PrimaryCobalt;
      background-color: @SecondaryBlue10;
      z-index: 13;
    }
  }

  .divider {
    border-top: 1px solid @SecondaryBlue20;
    height: 0;
    margin: 0;
  }
}

.hs-dropdown-disabled {
  pointer-events: none;

  i.icon {
    display: none;
  }
}

// Used for simple span content with icon
.hs-item-content {
  vertical-align: text-bottom;

  i ::before {
    vertical-align: middle;
  }

  display: flex;
  justify-content: space-between;

  i.ri-pencil-line {
    font-weight: 400;
  }

  i.ri-add-line {
    font-weight: 400;
    font-size: 16px;
  }
}

.hs-large-dropdown,
.ui.dropdown.hs-large-dropdown {
  .menu {
    top: 30px;
    min-width: 250px;
  }

  .item.large-item {
    color: @PrimaryCobalt80;

    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
  }
}

.hs-contextual-menu.ui.dropdown {
  > .text {
    // styleName: Body 12 Medium;
    font-family: Graphik, sans-serif;
    //font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: @PrimaryCobalt60;
  }

  .menu {
    //border: none;
  }

  .menu > .item {
    // styleName: Body 12 Medium;
    font-family: Graphik, sans-serif;
    //font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: @PrimaryCobalt60;

    display: flex;
    align-items: center;

    &:hover {
      color: @PrimaryCobalt;
    }

    .menu-icon {
      color: @SecondarySkyBlue;
      margin-right: 10px;
      //margin-bottom: 5px;
    }
  }
}

.hs-tertiary-button,
.ui.button.hs-tertiary-button {
  display: flex;
  height: 28px;

  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 8px;

  background-color: transparent;
  color: @PrimaryCobalt80;
  border: 1px solid @PrimaryCobalt80;

  &:hover {
    background-color: @PrimaryCobalt10;
  }

  i {
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.hs-dropdown-ellipsis,
.hs-dropdown-ellipsis.ui.dropdown {
  > div.text {
    // necessary to avoid extra vertical space inside div,
    // see https://stackoverflow.com/questions/23529369/why-does-x-overflowhidden-cause-extra-space-below
    vertical-align: top;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.hs-button-group {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .button {
    border-radius: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: @PrimaryCobalt05;
    border: solid 1px @PrimaryCobalt05;
    font-family: Graphik, sans-serif;
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    color: @PrimaryCobalt60;

    &.selected {
      background-color: @SecondarySkyBlue10;
      color: @SecondarySkyBlue;
      font-weight: 500;
      border: solid 1px @SecondarySkyBlue;
    }

    &:focus {
      background-color: @SecondarySkyBlue10;
    }
  }
}

.hs-flex-spacer {
  flex: 1;
}

.hs-dropdown-menu-ellipsis,
.hs-dropdown-menu-ellipsis.ui.dropdown,
.hs-dropdown-menu-ellipsis.ui.dropdown.selection {
  .menu > .item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// --------------
// FORMS
// --------------

.input-container {
  margin-bottom: 24px;

  &.input-container-horizontal {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .input-label {
      flex: 0 0 120px;
      width: 120px;
      min-height: 40px;
      line-height: 145%;
      margin-right: 0.5rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .input-container-wrapper {
      flex: 3 1 100%;
      max-width: 291px;
    }

    .input-error {
      margin-top: 5px;
    }
  }

  .control-label-supplement {
    color: @PrimaryCobalt60;
    font-weight: normal;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .input-label,
  label {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px;
    color: @PrimaryCobalt80;

    .no-emphasis {
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
    }
  }

  .preview-label {
    color: @PrimaryCobalt60;
    font-size: 12px;
  }

  .input-description {
    font-size: 12px;
    color: @PrimaryCobalt40;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .input-error {
    font-size: 12px;
    color: @NegativeColor;

    input {
      border-color: @NegativeColor;
    }
  }

  .input-element {
    &.input-and-button {
      display: flex;
      flex-direction: row;
      align-items: center;

      div:first-child {
        flex-grow: 1;
      }

      .buttons-container {
        display: flex;
        margin-left: 10px;
      }
    }

    &.half-width {
      width: 50%;
    }

    .field.signature-field {
      .content-edition-field {
        border: 1px solid @SecondaryBlue20;
        padding: 0.39285714em 1em;
        border-radius: 2px;
        font-size: 1em;
        box-shadow: none;
        outline: none;
        min-height: 80px;
        overflow: auto;

        &:focus {
          border-color: @SecondaryBlue;
        }
      }
    }
  }

  .accordion {
    .content {
      padding-bottom: 0px;
    }

    .title:not(.active) {
      padding-bottom: 0px;
    }
  }
}

.inputs-row {
  display: flex;
  flex-direction: row;

  &.align-right {
    justify-content: flex-end;

    .input-container {
      &.half-width:first-child:last-child {
        padding-right: 0px;
        padding-left: 12px;
      }
    }
  }

  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;

    &.half-width {
      width: 50%;
    }

    padding-right: 12px;
    padding-left: 12px;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }

    &.half-width:first-child:last-child {
      padding-right: 12px;
    }
  }
}

.hs-spacer {
  display: flex;
  flex-grow: 1;
}

.hs-accordion.ui.accordion {
  .title .dropdown.icon {
    width: 0.8em;
    vertical-align: middle;
  }

  .active.title .dropdown.icon {
    transform: rotate(-180deg) translate(0, 2px);
  }
}

.hs-container-button,
.hs-container-button.ui.button {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  min-height: 0;
}

.hs-dropdown-text-with-image.ui.dropdown,
/* necessary for Form.Dropdown */
.hs-dropdown-text-with-image .ui.dropdown {
  .menu .item {
    img {
      vertical-align: baseline;
    }
  }

  .text-with-image {
    img {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin-top: -0.5em;
      margin-bottom: -0.5em;
      margin-right: 8px;
      max-height: 2em;
    }
  }
}

.hs-hidden {
  display: none !important;
}

.ui.label.hiresweet-label {
  margin: 2px 3px 2px 0px;
  background: @PrimaryCobalt05;

  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: @PrimaryCobalt60;
}

.clipboard-active-target-command {
  border: 1px solid blue !important;
}

.position-relative {
  position: relative;
}

.hiresweet-rounded {
  border-radius: 8px !important;
}

.ui.checkbox.toggle.hiresweet-small-toggle {
  input,
  label::before {
    width: 34px !important;
    height: 18px !important;
  }
  label::after {
    width: 14px !important;
    height: 14px !important;
    border: none !important;
    background: white !important;
    box-shadow: none !important;
    margin: 2px;
    left: 0px;
  }
  label {
    padding-left: 38px;
    color: @PrimaryCobalt60;
  }
  &.checked {
    label {
      color: @PrimaryCobalt80 !important;
    }
    label::after {
      left: 16px !important;
    }
  }
}
