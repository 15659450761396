@import 'src/less/colors';

.modalDescription {
  font-family: Graphik;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1em;
  letter-spacing: 0px;
  text-align: center;
  color: @PrimaryColor;
}

.choicesContainer {
  display: flex;
  gap: 8px;

  .choiceCard {
    flex: 1 1;
    text-align: center;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid @SecondaryBlue20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    img {
      width: 100%;
    }

    .choiceTitle {
      font-family: Gilroy;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      color: @PrimaryCobalt;
      margin: 8px 0px;
    }

    .choiceDescription {
      flex: 1 1;
      color: @PrimaryCobalt60;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
    }

    .choiceCardAction {
      position: relative;
      width: 100;
      .choiceButton {
        width: 100%;
        padding: 10px 0px;
        height: auto;
        line-height: 20px;
        transition: background 250ms;
        position: relative;
        z-index: 12;

        &.AIGeneration {
          background: transparent;
        }
      }

      .gradientButtonBackground {
        background: linear-gradient(90deg, #4568dc 0%, #b06ab3 100%);
        width: 100%;
        padding: 10px 0px;
        height: auto;
        line-height: 20px;
        border-radius: 8px;
        color: transparent;
        position: absolute;
        z-index: 0;
        top: 0;
      }
    }

    &:hover {
      .choiceButton.AIGeneration {
        background: transparent;
      }
    }
  }
}
