@import 'src/less/colors';

.settingsDropdownContainer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-left: 10px;
  cursor: pointer;

  color: @PrimaryCobalt60;

  .dropdownTrigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .dropdownCaret {
      margin: 0;
      line-height: 16px;
      font-size: 16px;
      color: @PrimaryCobalt;
    }
  }

  .settingsDropdownMenu {
    top: 42px !important;
  }
}
