@import 'src/less/colors';

.title {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: @PrimaryCobalt;
}

.field {
  width: 100%;
}
