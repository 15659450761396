@import 'src/less/colors';

.container {
  display: inline-flex;
  align-items: stretch;
  position: relative;

  &.small {
    height: 24px;
    line-height: 24px;
    font-size: 12px;

    .button {
      padding: 0 8px;
      gap: 4px;
    }

    .dropdownButton {
      padding: 0 8px;
    }
  }

  &.normal {
    height: 32px;
    line-height: 32px;
    font-size: 14px;

    .button {
      padding: 0 8px 0 16px;
    }

    .dropdownButton {
      padding: 0 12px 0 8px;
    }
  }

  &.big {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }

  .separator {
    display: inline-block;
    width: 1px;
  }

  .button {
    border-radius: 8px 0 0 8px;
  }

  .dropdownButton {
    border-radius: 0 8px 8px 0;
  }

  .button,
  .dropdownButton {
    white-space: nowrap;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 8px;
    align-items: center;
    justify-content: center;

    font-family: Gilroy;
    font-weight: 700;

    transition: color ease 150ms, background-color ease 150ms,
      border-color ease 150ms;

    &:disabled {
      background-color: @SecondaryBlue40;
    }

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &.primary {
    .separator {
      background-color: white;
    }

    .button,
    .dropdownButton {
      color: white;
    }

    &.blue {
      .button:not(:disabled),
      .dropdownButton:not(:disabled) {
        background-color: @SecondaryBlue;
        &:hover {
          background-color: @SecondaryBlue120;
        }
      }
    }

    &.red {
      .button:not(:disabled),
      .dropdownButton:not(:disabled) {
        background-color: @SecondaryRed;
        &:hover {
          background-color: @SecondaryRed120;
        }
      }
    }
  }

  &.secondary {
    .button,
    .dropdownButton {
      background-color: white;
      border-width: 1px;
      border-style: solid;
      border-color: currentColor;

      &:not(:disabled) {
        box-shadow: 0 2px 4px 0 @PrimaryCobaltOp06;
      }

      &:disabled {
        color: @SecondaryBlue40;
      }
    }

    .separator.disabled {
      background-color: @SecondaryBlue40;
    }

    .button {
      border-right: none;
    }

    .dropdownButton {
      border-left: none;
    }

    &.blue {
      .separator:not(.disabled) {
        background-color: @SecondaryBlue;
      }

      .button:not(:disabled),
      .dropdownButton:not(:disabled) {
        color: @SecondaryBlue;
        &:hover {
          color: @SecondaryBlue120;
          background-color: @SecondaryBlue10;
        }
      }
    }

    &.red {
      .separator:not(.disabled) {
        background-color: @SecondaryRed;
      }

      .button:not(:disabled),
      .dropdownButton:not(:disabled) {
        color: @SecondaryRed;
        &:hover {
          color: @SecondaryRed120;
          background-color: @SecondaryRed10;
        }
      }
    }
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  top: calc(100% + 8px);

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.open {
    display: block;
  }
}

@line-height: 20px;
@padding-y: 9.5px;
@border-width: 1px;
@options-displayed: 8;

.menu {
  left: calc(50% - 180px);
  // Leave a padding height at the end to indicate there are more elements than displayed
  max-height: @options-displayed *
    (@line-height + 2 * @padding-y + @border-width) + @padding-y;

  overflow-y: auto;
  border: 1px solid @SecondaryBlue20;
  border-radius: 4px;
  box-shadow: 0px 2px 8px @PrimaryCobaltOp15;
  background-color: white;
}

.option {
  display: block;
  width: 100%;
  padding: @padding-y 12px;
  line-height: @line-height;
  white-space: nowrap;

  font-family: Graphik;
  font-size: 12px;
  text-align: left;
  color: @SecondaryBlue;
  font-weight: 500;

  &:not(:first-child) {
    border-top: @border-width solid @SecondaryBlue20;
  }

  &:hover {
    background-color: @SecondaryBlue05;
  }

  &.disabled {
    background-color: @SecondaryBlue05;
    color: @PrimaryCobalt60;
  }
}
