@import 'src/less/colors';

.container {
  padding: 16px;
  margin-bottom: 16px;
  background-color: white;
  border: 1px solid @BorderColor;
  border-radius: 4px;

  // This will be the future style
  // Left aside for the moment to match current page
  // border: 1px solid @SecondaryBlue20;
  // box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
  // border-radius: 8px;
}

.settings {
  display: flex;
  gap: 16px;
  align-items: center;

  .illustration {
    position: relative;
    padding: 12px;

    background-color: @SecondarySkyBlue10;
    border-radius: 8px;

    .logo {
      width: 48px;
      height: 48px;
      padding: 8px;

      background-color: white;
      border-radius: 12px;

      img {
        width: 100%;
      }
    }

    .count {
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 0 6px;

      background-color: @SecondarySkyBlue20;
      border: 2px solid white;
      border-radius: 20px;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: @SecondaryBlue;
    }
  }

  .main {
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 4px;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: @PrimaryCobalt;
    }

    .description {
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: @PrimaryCobalt60;
    }
  }

  .aside {
    margin-top: 8px;
    padding: 4px 12px;

    border-radius: 8px;
    background-color: @SecondarySkyBlue20;

    .usage {
      margin-right: 8px;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: @SecondaryBlue;
    }

    .update {
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: @PrimaryCobalt60;
      display: inline-block;
    }
  }
}

.toggle {
  // Small override over semantic
  width: 28px !important;
}
