@import 'src/less/colors';

.integrationHelper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
  background-color: @SecondarySkyBlue10;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  color: @PrimaryCobalt;
}

.integrationHelperInstructions {
  flex-shrink: 0;
  align-self: center;
}

.breadcrumbDividerIcon {
  font-size: 18px;
  margin: 0px 10px;
}

.subHeader {
  color: @PrimaryCobalt60;
}

.integrationHelperInstructionsHeaderClose {
  display: inline-block;
  float: right;
  cursor: pointer;
}
