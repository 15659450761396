@import 'src/less/colors';

.dropdown {
  width: 100%;
}

.trigger {
  display: block;
  width: 100%;
  padding: 0;
  font-family: Graphik;
  text-align: left;
  color: @PrimaryCobalt;
}
