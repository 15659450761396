/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.laser-focus-modal {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100% - 64px);
  z-index: 10;
}
.laser-focus-modal .profile-focus-content {
  background-color: #f8f8f8;
  overflow-y: scroll;
  height: calc(100% - 64px);
}
.laser-focus-modal .profile-focus-content .profile-preview {
  padding: 12px 154px;
  align-items: flex-start;
}
.laser-focus-modal .profile-focus-content .profile-preview .contact-section-container {
  margin-top: 20px;
  margin-right: 20px;
}
