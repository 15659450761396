@import 'src/less/colors';

.columnSelectorTrigger {
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}

.columnsSelectorContainer {
  margin-bottom: 12px;
  max-height: 450px;
  overflow-y: auto;
}

h5.columnGroupTitle {
  color: @PrimaryCobalt;
  margin-top: 15px;
  margin-bottom: 5px;
}

.contextBtn {
  font-size: 17px;
  cursor: pointer;
}

.accordionGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    color: @PrimaryCobalt;
  }

  .accordionTitle {
    color: @SecondaryBlue;
    font-weight: 400;
    font-size: 14px;
  }
}

.button {
  width: 100%;
}
