/*
 * colors.less
 */
.benchmark {
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dae0f4;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.title {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #1f2e77;
}
.partnership {
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 4px;
  gap: 6px;
  border-radius: 8px;
  background-color: #f6f7fc;
  color: #7982ad;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.partnership .logo {
  height: 24px;
  width: 24px;
  border-radius: 8px;
}
.partnership .link {
  color: #4864c9;
  font-weight: 500;
  text-decoration: underline;
}
.legend {
  display: flex;
  color: #a5abc9;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.legend .left {
  flex: 1;
  text-align: left;
}
.legend .right {
  flex: 1;
  text-align: right;
}
.graph {
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: 21px;
}
.bar {
  z-index: 1;
  height: 6px;
  min-width: 80px;
}
.handle {
  margin: 0 -6px;
  z-index: 2;
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 100%;
}
.upperLabel {
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  color: #a5abc9;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
}
.label {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: #a5abc9;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  white-space: nowrap;
}
.label .tooltip {
  color: inherit;
}
.first {
  background-color: #a8b5af;
}
.second {
  background-color: #367c3e;
}
.second.handle {
  height: 16px;
  width: 16px;
  border: 2px solid white;
}
.second.handle .label {
  color: #1f2e77;
  font-weight: 500;
}
.third {
  background-color: #82d69f;
}
.fourth {
  background-color: #fee2e5;
}
