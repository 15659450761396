/*
 * flex.less
 */
/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.common-style:focus {
  outline: 0;
}
.button-common-style:focus {
  outline: 0;
}
.button-common-style:hover {
  cursor: pointer;
}
.button-common-style::selection {
  cursor: pointer;
  border: none;
}
.input-common-style:focus {
  outline: 0;
}
.comment-generic-content .comment-content .editor-container {
  padding-bottom: 10px;
}
.comment-generic-content .comment-content .main-body.RichEditor-editor {
  border-bottom: none !important;
}
.comment-generic-content .comment-content .main-body.RichEditor-editor.RichEditor-editor {
  border-bottom: none !important;
  min-height: 100px;
}
.comment-generic-content .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditorPlaceholder-root,
.comment-generic-content .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.comment-generic-content .comment-content .save-comment-button {
  position: relative;
  top: -42px;
  float: right;
  right: 5px;
  width: 110px;
}
.timeline-default-card-wrapper.classic-item .editor-container {
  border: 1px solid #dae0f4 !important;
  border-radius: 4px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu {
  min-height: 20px;
  padding-left: 15px;
  border-bottom: 1px solid #dae0f4;
  margin: 0px;
  margin-bottom: 20px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #b6c1e9;
  padding: 0px 20px 8px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item.active {
  color: #4864c9;
  border-color: #4864c9;
  border-width: 2px;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item.active:hover {
  color: #4864c9;
}
.timeline-default-card-wrapper .ui.pointing.secondary.menu a.item:hover {
  color: #6d83d4;
}
.timeline-default-card-wrapper .comment-content .editor-container {
  padding-bottom: 10px;
}
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor {
  border-bottom: none !important;
}
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor.RichEditor-editor {
  border-bottom: none !important;
  min-height: 100px;
}
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditorPlaceholder-root,
.timeline-default-card-wrapper .comment-content .main-body.RichEditor-editor.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.timeline-default-card-wrapper .comment-content .save-comment-button {
  position: relative;
  top: -42px;
  float: right;
  right: 5px;
  width: 110px;
}
.timeline-default-card-wrapper .email-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
.timeline-default-card-wrapper .email-content .email-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}
.timeline-default-card-wrapper .email-content .email-header .email-title {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #1f2e77;
}
.timeline-default-card-wrapper .email-content .email-header .sending {
  font-size: 14px;
  font-weight: 600;
}
.timeline-default-card-wrapper .email-content .email-header .sending i.icon {
  height: 20px;
}
.timeline-default-card-wrapper .email-content .email-header button {
  white-space: nowrap;
}
.timeline-default-card-wrapper .email-content .email-header #button-ask-send {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 30px 6px 23px;
}
.timeline-default-card-wrapper .email-content .email-header #button-ask-send i.icon {
  height: 20px;
}
.timeline-default-card-wrapper .email-content .email-header .button-sobo {
  background-color: #709dff;
}
.timeline-default-card-wrapper .email-content .email-header .send.icon {
  font-size: 12px;
  margin-right: 8px;
}
.timeline-default-card-wrapper .email-content .email-header #button-gmail-connexion,
.timeline-default-card-wrapper .email-content .email-header #button-outlook-connexion {
  flex-grow: 1;
}
.timeline-default-card-wrapper .email-content .email-header #button-connection-in-progress {
  flex-grow: 1;
  margin-right: 0;
}
.timeline-default-card-wrapper .email-content .email-header #button-connection-in-progress span {
  margin-left: 10px;
}
.timeline-default-card-wrapper .email-content .email-body {
  margin: 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies {
  width: fit-content;
  cursor: pointer;
  font-size: 13px;
  margin-top: 8px;
  font-family: Graphik;
  max-width: 100%;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies > span {
  white-space: nowrap;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #4864c9;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies > span:hover {
  text-decoration: underline;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies > span .template-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies svg {
  margin-right: 0px;
}
.timeline-default-card-wrapper .email-content .email-body .add-to-default-template-replies + .email-body-editor {
  margin-top: 4px;
}
.timeline-default-card-wrapper .email-content .email-body .email-info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 3px 3px 0 0;
  font-weight: normal;
  font-size: 13px;
  color: #2b3d4f;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}
.timeline-default-card-wrapper .email-content .email-body .email-info .to {
  color: #adadb7;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor {
  margin-top: 12px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body {
  border-bottom: none !important;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body .RichEditor-editor {
  border-bottom: none !important;
  min-height: 250px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body .RichEditor-editor .public-DraftEditorPlaceholder-root,
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .main-body .RichEditor-editor .public-DraftEditor-content {
  min-height: 250px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .static-signature {
  padding-left: 0px;
  padding-bottom: 5px;
}
.timeline-default-card-wrapper .email-content .email-body .email-body-editor .empty-menu {
  height: 15px;
}
.timeline-default-card-wrapper .email-content .email-body .email-subject.RichEditor-editor {
  margin-bottom: 10px !important;
  color: #4c5892;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 600;
}
.timeline-default-card-wrapper .email-content .email-body .email-subject,
.timeline-default-card-wrapper .email-content .email-body .email-message {
  resize: none;
}
.timeline-default-card-wrapper .email-content .email-body .email-subject:focus,
.timeline-default-card-wrapper .email-content .email-body .email-message:focus {
  outline: 0;
}
