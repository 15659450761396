.sources-container {
  display: flex;
  flex-direction: row;
  height: 20px;
}
.sources-container .source-link {
  margin-right: 6px;
}
.sources-container .source-link:last-child {
  margin-right: 0px;
}
.sources-container .source-link img {
  height: 13px;
  width: 13px;
  opacity: 0.35;
}
.sources-container .source-link img:hover {
  opacity: 1;
}
.sources-container .source-link span {
  font-size: 13px;
  color: #112e48;
  opacity: 0.35;
  line-height: 22px;
}
.sources-container .source-link span:hover {
  color: #4183c4;
  opacity: 1;
  text-decoration: underline;
}
.sources-container .download-icon-container:focus-visible {
  outline-style: auto;
  outline-width: 1px;
}
.sources-container .download-icon-container .download-icon {
  color: transparent;
  height: 11px;
  width: 13px;
  opacity: 0.35;
  stroke: #112e48;
  cursor: pointer;
}
.sources-container .download-icon-container .download-icon:hover {
  opacity: 1;
}
