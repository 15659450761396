.tooltipContainer {
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  &.display {
    display: block;
    z-index: 10;

    animation: fadeIn 100ms ease-in-out;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
