.qualifications {
  font-weight: 400;
}
.qualifications .primaryQualification {
  font-weight: 500;
}
.missionsIcon {
  color: #a5abc9;
  margin-top: -2px;
}
.profileMission {
  margin: 0;
}
