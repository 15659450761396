/*
 * colors.less
 */
.section {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  margin-top: 16px;
}
.section .header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11px 0 24px;
  border-bottom: 1px solid #dae0f4;
}
.section .header .title {
  color: #1f2e77;
  margin: 0;
}
.section .content {
  padding: 24px;
}
.section .content .loader {
  margin: 100px auto;
}
