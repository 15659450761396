/*
 * colors.less
 */
.unlinkFragmentModal .wrapper {
  width: 80%;
  margin: 0 auto;
}
.unlinkFragmentModal .wrapper .content {
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: #7982ad;
  text-align: center;
}
.unlinkFragmentModal .wrapper .warning {
  background: #f1f6ff;
  border-radius: 4px;
  padding: 5px 0px;
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: #4864c9;
  text-align: center;
}
.unlinkFragmentModal .unlinkBtn {
  background-color: #f66f81 !important;
}
.unlinkFragmentModal .pluginWrapper {
  width: 100%;
}
