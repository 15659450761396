/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.next-activities-title .ui.label,
.timeline .ui.label {
  margin-top: -1px;
  margin-left: 5px;
  padding: 6px;
  color: #4864c9;
  background-color: #e2ebff;
}
.next-activities-title {
  display: flex;
  align-items: center;
}
.reveal-pending-actions {
  margin-top: 8px;
  position: relative;
}
.reveal-pending-actions.minimized {
  margin-top: 4px;
}
.reveal-pending-actions .more-pending-actions-container {
  position: relative;
  transition: margin-bottom 250ms;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions {
  margin-top: -18px;
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 4px;
  overflow: hidden;
  transition: max-height 250ms, top 250ms, padding 250ms;
  transition-timing-function: ease-out;
  box-shadow: 0px 2px 16px 0px #1f2e7714;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions:not(.only-future) {
  position: relative;
  top: -12px;
  z-index: 0;
  left: 16px;
  width: calc(100% - 32px);
  max-height: 70px;
  padding: 33px 16px 12px 16px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.only-future {
  margin-top: 10px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.only-future .preview {
  padding: 15px 0px 0px 3px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.only-future.minimized {
  margin-top: 0;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.expanded {
  top: 14px;
  margin-bottom: 48px;
  padding: 16px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.expanded .ui.divider,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.expanded .all-actions {
  opacity: 1;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions.expanded .expand-icon {
  transform: rotateX(180deg) !important;
  vertical-align: -5px !important;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview {
  display: flex;
  justify-content: space-between;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content {
  display: flex;
  font-weight: 500;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content .divider {
  width: 10px;
  height: 12px;
  border-left: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
  border-bottom-left-radius: 4px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content .icons-container {
  display: flex;
  flex-direction: row-reverse;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content .icons-container img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid white;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content .icons-container img:last-of-type {
  margin-left: 6px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content .icons-container img:not(:last-of-type) {
  margin-left: -5px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content p {
  margin-left: 8px;
  align-self: center;
  margin-top: 4px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content.minimized {
  flex-direction: column-reverse;
  align-items: center;
  gap: 4px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content.minimized p {
  font-size: 12px;
  font-family: Graphik;
  font-weight: 500;
  color: #1f2e77;
  margin: 0;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content.minimized .icons-container img {
  height: 20px;
  width: 20px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-content.minimized .icons-container img:last-of-type {
  margin-left: -5px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-maximize {
  color: #4864c9;
  margin-top: 2px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-maximize p,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-maximize i {
  margin-bottom: 0;
  display: inline-block;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview .preview-maximize i {
  transition: transform 250ms, vertical-align 250ms;
  vertical-align: -3px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .preview.minimized {
  display: block;
  padding: 8px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .ui.divider,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .all-actions {
  transition: opacity 250ms;
  padding-right: 10px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .ui.divider .upcoming-actions-separator,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .all-actions .upcoming-actions-separator {
  margin: 0px 0px 16px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .ui.divider .action-item-icon-wrapper::after,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .all-actions .action-item-icon-wrapper::after {
  display: none !important;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .ui.divider .action-item-icon-wrapper img,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .all-actions .action-item-icon-wrapper img {
  border-radius: 50% !important;
  width: 24px;
}
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .ui.divider .email-body-and-subject .body,
.reveal-pending-actions .more-pending-actions-container .more-pending-actions .all-actions .email-body-and-subject .body {
  font-size: 12px;
}
.reveal-pending-actions h3.section-info {
  margin-bottom: 15px;
  margin-top: 25px;
  font-family: Graphik, sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: #a5abc9;
}
.reveal-pending-actions .action-trigger-editor {
  color: #2b3d4f;
  flex-wrap: wrap;
  margin-bottom: 8px;
  gap: 8px;
}
.reveal-pending-actions .action-trigger-editor .ui.dropdown {
  font-size: 13px;
  font-family: Graphik;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0px 6px;
  border-radius: 4px;
  height: 22px;
}
.reveal-pending-actions .action-trigger-editor .ui.dropdown .icon {
  margin-left: 0.5em;
}
.reveal-pending-actions .action-item-editor-title {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #1f2e77;
  margin-bottom: 16px !important;
}
.reveal-pending-actions .action-item-editor-buttons {
  display: flex;
  gap: 8px;
}
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:first-child {
  min-width: 62px;
}
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:last-child {
  min-width: 170px;
}
.reveal-pending-actions .action-type-editor,
.reveal-pending-actions .action-trigger-editor .automatic-selector {
  flex-grow: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}
.reveal-pending-actions .action-type-editor .ui.dropdown,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown {
  height: 36px;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1f2e77;
  display: flex;
  align-items: center;
  flex: 1 1;
}
.reveal-pending-actions .action-type-editor .ui.dropdown.visible,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown.visible,
.reveal-pending-actions .action-type-editor .ui.dropdown:hover,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:hover,
.reveal-pending-actions .action-type-editor .ui.dropdown:focus,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus,
.reveal-pending-actions .action-type-editor .ui.dropdown:focus-visible,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus-visible,
.reveal-pending-actions .action-type-editor .ui.dropdown:focus-within,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus-within {
  border-color: #dae0f4;
}
.reveal-pending-actions .action-type-editor .ui.dropdown.visible:not(.upward),
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown.visible:not(.upward),
.reveal-pending-actions .action-type-editor .ui.dropdown:hover:not(.upward),
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:hover:not(.upward),
.reveal-pending-actions .action-type-editor .ui.dropdown:focus:not(.upward),
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus:not(.upward),
.reveal-pending-actions .action-type-editor .ui.dropdown:focus-visible:not(.upward),
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus-visible:not(.upward),
.reveal-pending-actions .action-type-editor .ui.dropdown:focus-within:not(.upward),
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus-within:not(.upward) {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.reveal-pending-actions .action-type-editor .ui.dropdown.visible.upward,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown.visible.upward,
.reveal-pending-actions .action-type-editor .ui.dropdown:hover.upward,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:hover.upward,
.reveal-pending-actions .action-type-editor .ui.dropdown:focus.upward,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus.upward,
.reveal-pending-actions .action-type-editor .ui.dropdown:focus-visible.upward,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus-visible.upward,
.reveal-pending-actions .action-type-editor .ui.dropdown:focus-within.upward,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown:focus-within.upward {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.reveal-pending-actions .action-type-editor .ui.dropdown .menu,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown .menu {
  min-width: none !important;
  width: auto !important;
}
.reveal-pending-actions .action-type-editor .ui.dropdown .menu .item,
.reveal-pending-actions .action-trigger-editor .automatic-selector .ui.dropdown .menu .item {
  display: flex;
  align-items: center;
}
.reveal-pending-actions .action-type-editor .action-type-editor-info,
.reveal-pending-actions .action-trigger-editor .automatic-selector .action-type-editor-info {
  color: #1f2e77;
  font-size: 12px;
}
.reveal-pending-actions .action-item-container {
  font-family: Graphik;
  font-style: normal;
  z-index: 9;
  border: none;
}
.reveal-pending-actions .action-item-container .action-item-icon-wrapper {
  position: relative;
}
.reveal-pending-actions .action-item-container .action-item-icon-wrapper::after {
  border-left: 2px solid #e9ebf2;
  content: ' ';
  position: absolute;
  top: 20px;
  left: 9px;
  bottom: -1px;
}
.reveal-pending-actions .action-item-container.add-action {
  color: #d2d5e4;
  cursor: pointer;
}
.reveal-pending-actions .action-item-container.add-action:hover {
  color: #5c89eb;
}
.reveal-pending-actions .action-item-container.add-action:hover .action-item-content {
  text-decoration: underline;
}
.reveal-pending-actions .action-item-container.add-action .add-icon {
  display: block;
  margin-top: -2px;
  margin-left: -2px;
  font-size: 24px;
}
.reveal-pending-actions .action-item-container.last-action .action-item-icon-wrapper::after {
  border-left: none;
}
.reveal-pending-actions .action-item-container .action-item-content {
  padding-left: 16px;
  margin-bottom: 16px;
  flex-grow: 1;
  position: relative;
}
.reveal-pending-actions .action-item-container .action-item-content .sender {
  line-break: anywhere;
  margin-top: 0px !important;
  max-width: 100%;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-overflow: ellipsis;
}
.reveal-pending-actions .action-item-container .action-item-content .sender span {
  white-space: break-spaces !important;
}
.reveal-pending-actions .action-item-container .action-item-content .sender span.sender-address {
  color: #5c89eb;
}
.reveal-pending-actions .action-item-container .action-item-content .sender span.sender-error {
  color: #e45a6b;
}
.reveal-pending-actions .action-item-container .action-item-content .sender .intro-word {
  font-weight: 400;
  color: #7982ad !important;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container {
  margin-bottom: 12px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  height: 32px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header h5 {
  flex-grow: 1;
  font-size: 14px;
  font-family: Graphik;
  color: #1f2e77;
  font-weight: 500;
  margin: 0;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header h5.minimized {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .ui.dropdown {
  flex-shrink: 0;
  align-self: flex-start;
  color: #4864c9;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .ui.dropdown .menu {
  background-color: white;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .header-button-action {
  background-color: #4864c9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header button {
  line-height: 14px;
  gap: 5px;
  cursor: pointer;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .mail-button {
  font-size: 14px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .mail-button .button-sobo {
  background-color: #709dff;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .snooze-count-text {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #e9ebf2;
  border-radius: 20px;
  color: #7982ad;
  padding: 5px 10px;
  font-weight: 500;
  white-space: nowrap;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .action-item-content-header .snooze-count-text i {
  color: #7982ad;
  font-weight: bold;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-header-container .complete-on-linkedin-link {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: block;
  margin-top: -4px;
  width: fit-content;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-subtitle {
  font-size: 12px;
  line-height: 140%;
  color: #5c89eb;
  font-weight: 400;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-subtitle:last-child {
  margin-bottom: 0px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-subtitle span {
  margin-left: 10px;
  color: #4864c9;
  font-weight: 500;
  cursor: pointer;
}
.reveal-pending-actions .action-item-container .action-item-content .show-more {
  margin-right: -15px;
  width: 130px;
  font-size: 12px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 4px;
  cursor: pointer;
  font-family: 'Graphik';
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-description {
  font-size: 12px;
  transition: max-height 250ms;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  position: relative;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-description.copy {
  visibility: hidden;
  position: absolute;
  white-space: pre-wrap;
  color: transparent;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-description .gradient-cover {
  height: 30px;
  position: absolute;
  width: 100%;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 0px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-content-description.editable {
  cursor: pointer;
}
.reveal-pending-actions .action-item-container .action-item-content .action-content {
  font-size: 12px;
  line-height: 140%;
  color: #10173d;
  font-weight: 400;
  background-color: #f6f7fc;
  border-radius: 2px;
  padding: 12px;
  white-space: pre-wrap;
  margin-top: 15px;
  word-break: break-word;
}
.reveal-pending-actions .action-item-container .action-item-content .action-content.editable {
  cursor: pointer;
}
.reveal-pending-actions .action-item-container .action-item-content .action-content.minimized {
  margin-top: 4px;
  padding: 4px;
}
.reveal-pending-actions .action-item-container .action-item-content .action-item-view-in-task {
  margin-top: 8px;
  text-align: right;
}
.reveal-pending-actions .action-item-container .action-item-content .apply-to-next-mails {
  color: #4864c9;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}
.reveal-pending-actions .action-item-container .action-item-content .email-editor-container {
  margin-top: 15px;
  position: relative;
}
.reveal-pending-actions .action-item-container:last-of-type .action-item-content {
  margin-bottom: 16px;
}
.reveal-pending-actions .action-item-container .mark-completed-button {
  display: flex;
}
.reveal-pending-actions .action-item-container .mark-completed-button button {
  flex: 1;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 700;
}
.reveal-pending-actions .action-item-container .mark-completed-button .mail-button i {
  height: 20px;
}
.reveal-pending-actions .action-item-container #snooze-task-one-week-hidden {
  position: absolute;
  visibility: hidden;
}
.reveal-pending-actions .action-item-container .pending-action-buttons {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
}
.reveal-pending-actions .action-item-container .pending-action-buttons .pending-action-button {
  width: 100%;
  margin: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 5px;
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 700;
}
.reveal-pending-actions .action-item-container .pending-action-buttons .mark-completed-button {
  width: 100%;
  min-width: 100%;
}
.reveal-pending-actions .action-item-container .pending-action-buttons .snooze-button,
.reveal-pending-actions .action-item-container .pending-action-buttons .remove-button,
.reveal-pending-actions .action-item-container .pending-action-buttons .edit-button {
  flex: 1 1;
  min-width: calc(50% - 5px);
}
.reveal-pending-actions .action-item-container .pending-action-buttons.minimized {
  margin-top: 4px;
  display: flex;
  gap: 4px;
}
.reveal-pending-actions .action-item-container .pending-action-buttons.minimized button {
  padding: 8px 0;
}
.reveal-pending-actions .action-item-container .pending-action-buttons.minimized .mark-completed-button,
.reveal-pending-actions .action-item-container .pending-action-buttons.minimized .snooze-button,
.reveal-pending-actions .action-item-container .pending-action-buttons.minimized .remove-button,
.reveal-pending-actions .action-item-container .pending-action-buttons.minimized .edit-button {
  flex: 1;
}
.reveal-pending-actions .action-item-container.box-mode {
  margin-bottom: 10px !important;
  padding: 15px;
  border-radius: 4px;
}
.reveal-pending-actions .action-item-container.box-mode.minimized {
  margin-bottom: 4px !important;
  padding: 4px;
}
.reveal-pending-actions .action-item-container.box-mode .action-item-icon-wrapper::after {
  top: calc(100% + 12px);
  bottom: -38px;
  left: -4px;
}
.reveal-pending-actions .action-item-container.box-mode .action-item-content {
  margin-bottom: 0px;
  padding-left: 0px;
}
.reveal-pending-actions .action-item-container.with-border {
  box-shadow: 0px 2px 16px 0px rgba(31, 46, 119, 0.15) !important;
}
.reveal-pending-actions .action-item-container .react-datepicker {
  margin-top: 15px;
}
.reveal-pending-actions .action-item-container .react-datepicker .react-datepicker__header {
  background-color: #f3f4f8;
  box-shadow: inset 0px 1px 0px #dae0f4;
}
.reveal-pending-actions .action-item-container .react-datepicker .react-datepicker__current-month,
.reveal-pending-actions .action-item-container .react-datepicker .react-datepicker-time__header {
  color: #1f2e77;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.reveal-pending-actions .action-item-container .plugin-date-picker-wrapper .react-datepicker__month-container {
  width: 180px;
  overflow-x: scroll;
}
.reveal-pending-actions .action-item-container.minimized {
  margin-top: 4px;
}
.reveal-pending-actions .action-item-container.minimized .snooze-count-text {
  padding: 0px 5px !important;
  font-size: 12px;
}
.action-and-timeline-divider {
  margin: 32px 0px !important;
}
.empty-pending-actions {
  margin: 8px 0px 32px;
  width: 100%;
  padding: 16px;
  background-color: white;
  text-align: center;
  color: #1f2e77;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15);
  border-radius: 4px;
}
.pill-message {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.pill-message.small {
  padding: 3px 8px;
}
.pill-message.mini {
  padding: 0px 8px;
}
.pill-message.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.pill-message.spaced {
  margin-left: 8px;
}
.pill-message.green {
  background-color: #eff9ee;
  color: #14b62e;
}
.pill-message.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.pill-message.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.pill-message.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.pill-message.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.pill-message.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
