/*
 * colors.less
 */
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 100px;
}
.modalContent .loader:after {
  border-color: #1f2e77 transparent transparent !important;
}
.modalContent .checkmark {
  background-color: #1f2e77;
  color: white;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin: auto;
}
.modalContent .modalSuccess {
  text-align: center;
}
.modalContent .modalSuccess svg {
  margin-bottom: 24px;
}
.modalContent .cross {
  background-color: #f66f81;
  color: white;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  margin: auto;
}
.modalContent .inputLabel {
  margin-bottom: 6px !important;
  font-weight: 500 !important;
}
.confirmationModalActions {
  justify-content: center !important;
}
