/*
 * colors.less
 */
.title {
  color: #1f2e77;
}
.input {
  width: 100%;
}
.checkbox {
  margin-bottom: 8px;
}
