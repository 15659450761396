/*
 * colors.less
 */
.modal {
  z-index: 10;
}
.modal .formItem {
  margin-bottom: 16px;
}
.modal .formItem .label {
  margin-bottom: 6px;
}
.modal .formItem .subject {
  width: 100%;
}
.modal .textarea {
  width: 100%;
}
.modal .disabledButton {
  background-color: #b6c1e9 !important;
  color: white;
  cursor: default !important;
}
.footer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
