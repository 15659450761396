/*
 * colors.less
 */
.archived {
  justify-content: center;
  border-left: 1px solid #dae0f4;
}
.archived .count {
  text-align: center;
}
