/*
 * flex.less
 */
/*
 * colors.less
 */
.offer-step-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.offer-step-nav .step {
  cursor: pointer;
  margin-right: 22px;
  padding: 0;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #a5abc9;
  border-bottom: solid 2px white;
  border-top: solid 2px white;
}
.offer-step-nav .step.active {
  color: #5c89eb;
  border-bottom: solid 2px #5c89eb;
  border-top: solid 2px white;
}
