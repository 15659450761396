/*
 * colors.less
 */
.genericText {
  font-family: Graphik;
  margin: 0;
  padding: 0;
}
.genericText.medium {
  font-weight: 400;
  font-size: 12px;
}
.genericText.cobalt {
  color: #1f2e77;
}
.genericText.cobalt60 {
  color: #7982ad;
}
