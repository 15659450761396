/*
 * colors.less
 */
.offer-job-postings .job-postings-manager .header {
  color: #709dff;
}
.offer-job-postings .section {
  margin-bottom: 48px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #dae0f4;
  padding: 24px;
}
