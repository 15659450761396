@import 'src/less/colors.less';

// Job applications
.application {
  // To align with title
  padding-left: 20px;
  font-size: 12px;
  color: @PrimaryCobalt;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.jobTitle {
  font-weight: 500;
}

.applicationInfo {
  line-height: 0.8em;
}

.applicationDescription,
.applicationDate {
  font-weight: 400;
}

.applicationDate {
  color: @PrimaryCobalt60;
  margin-left: 8px;
}
