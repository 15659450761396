/*
 * colors.less
 */
.container {
  display: inline-block;
  width: 100px;
  text-align: left;
}
.funnel {
  display: inline-block;
  position: relative;
  padding: 6px 16px;
  text-align: center;
  border-radius: 3px 0 0 3px;
  border-top: 1px solid #dae0f4;
  border-left: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
  font-size: 14px;
  font-family: Graphik;
  color: #7982ad;
}
.funnel .arrowTop {
  position: absolute;
  right: 0;
  top: -1px;
  width: 1px;
  height: 57.8%;
  /* 1 / 2cos(30°) */
  background-color: #dae0f4;
  transform-origin: top right;
  transform: rotate(-24deg);
}
.funnel .arrowBottom {
  position: absolute;
  right: 0;
  bottom: -1px;
  width: 1px;
  height: 57.8%;
  /* 1 / 2cos(30°) */
  background-color: #dae0f4;
  transform-origin: bottom right;
  transform: rotate(24deg);
}
