/*
 * colors.less
 */
.title {
  font-size: 22px;
  font-weight: 700;
  font-family: Gilroy;
  color: #1f2e77;
  margin-bottom: 8px;
}
.linkedATSUserContainer {
  display: flex;
  width: 100%;
  align-items: center;
}
.linkedATSUserContainer .linkedUser {
  flex-grow: 1;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #7982ad;
}
.linkedATSUserContainer .removeLinkedUser {
  margin-left: 12px;
}
.linkedATSUserContainer .removeLinkedUser svg {
  color: #7982ad;
  cursor: pointer;
}
