/*
 * colors.less
 */
.container {
  width: 240px;
  margin: 16px 0;
}
.dropdown {
  width: 100%;
}
.dropdownTrigger {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
.dropdownTrigger:not(:disabled) {
  cursor: pointer;
}
.title {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #1f2e77;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.arrow {
  font-family: Gilroy;
  font-size: 16px;
  color: #1f2e77;
}
.actions {
  margin: 8px 0;
  min-height: 16px;
}
.action {
  display: block;
  margin: 4px 0;
  padding: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}
.action:not(:disabled) {
  color: #4864c9;
  cursor: pointer;
}
.action:not(:disabled):hover {
  text-decoration: underline;
}
.menu {
  padding: 8px 0;
  max-height: 356px;
  overflow-y: auto;
}
.menuItem {
  position: relative;
}
.menuItem .menuItemSelect {
  padding: 10px 12px;
  padding-right: 40px;
}
.menuItem .menuItemDelete {
  display: none;
  position: absolute;
  right: 8px;
  top: 4px;
}
.menuItem:hover .menuItemDelete {
  display: block;
}
