/*
 * colors.less
 */
.cohorts-graph {
  width: 100%;
  padding: 35px 20px 15px 15px;
}
.cohorts-graph-empty {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cohorts-graph-empty-text {
  font-size: 15px;
  opacity: 0.3;
}
.cohorts-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cohorts-legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px;
  cursor: pointer;
}
.cohorts-legend-checkbox-box {
  border: 1px solid;
  margin-right: 5px;
  padding: 2px;
}
.cohorts-legend-checkbox {
  width: 8px;
  height: 8px;
}
.cohorts-legend-text {
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  color: #4c5892;
}
