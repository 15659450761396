/*
 * colors.less
 */
.tabs .tab {
  margin: 0;
  margin-right: 24px;
  padding: 0;
  padding-bottom: 10px;
  color: #a5abc9;
  border-bottom: 2px solid transparent;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all 75ms ease;
}
.tabs .tab:hover {
  border-bottom-color: #a5abc9;
}
.tabs .tab.active {
  color: #4864c9;
  border-bottom-color: #4864c9;
}
