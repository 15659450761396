/*
 * colors.less
 */
.item {
  display: block;
  width: 100%;
  padding: 6px 12px;
  line-height: 140%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: Graphik;
  font-size: 14px;
  text-align: left;
  color: #1f2e77;
  font-weight: 400;
}
.item:not(:disabled) {
  cursor: pointer;
}
.item:not(:disabled):hover {
  background-color: #f6f7fc;
}
.item:not(:disabled):hover:first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.item:not(:disabled):hover:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.item:disabled {
  color: #7982ad;
}
