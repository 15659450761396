/*
 * colors.less
 */
.alertBox {
  background-color: #fee2e5;
  border-radius: 8px;
  padding: 16px;
  color: #e45a6b;
}
.alertBox .title {
  color: #e45a6b;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.alertBox .description {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #e45a6b;
}
