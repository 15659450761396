/*
 * colors.less
 */
.jobs-watch-collect-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  background-color: white;
  padding: 16px;
  border-radius: 2px;
  border: 1px solid #dae0f4;
}
.jobs-watch-collect-banner .banner-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-watch-collect-banner .banner-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.jobs-watch-collect-banner .banner-main-text {
  line-height: 20px;
  color: #4c5892;
}
.jobs-watch-collect-banner .total-count {
  font-weight: 600;
}
