/*
 * colors.less
 */
.textarea {
  padding: 12px;
  border: 1px solid #dae0f4;
  background-color: white;
  border-radius: 8px;
  font-family: Graphik;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1f2e77;
  outline: none;
}
.textarea:not(:disabled) {
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.textarea:not(:disabled) ::placeholder {
  color: #a5abc9;
}
.textarea:disabled {
  background-color: #f6f7fc;
  color: #a5abc9;
}
.textarea:disabled ::placeholder {
  color: #d2d5e4;
}
.textarea:focus {
  border-color: #a9c4ff;
  box-shadow: inset 0px 0px 1px 1px #a9c4ff;
}
