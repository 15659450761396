@import 'src/less/colors';

.bar {
  display: flex;
  gap: 1px;

  transition: all 150ms ease;

  &:hover {
    background-color: @PrimaryCobaltOp08;
    transform: scale(1.005);
    box-shadow: 0px 2px 6px @PrimaryCobaltOp15;
  }

  .section {
    &.clickable {
      cursor: pointer;

      &:hover {
        filter: brightness(0.8) saturate(1.5);
      }
    }
  }

  .background {
    transition: flex 300ms ease;

    .totalCount {
      margin: 0 8px;

      animation: fadeIn 800ms;
      transition: opacity 500ms ease;

      &.hidden {
        opacity: 0;
      }
    }
  }

  &.vertical {
    height: 100%;
    flex-direction: column-reverse;

    .section {
      animation: bottomSlideIn 500ms ease;
    }

    .background {
      margin-bottom: -1px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .totalCount {
        position: absolute;
        font-weight: 700;
        font-size: 14px;
        font-family: Gilroy;
        color: @PrimaryCobalt;
      }
    }
  }

  &.horizontal {
    height: 24px;
    border-radius: 3px;
    overflow: hidden;

    .section {
      animation: leftSlideIn 500ms ease;
    }

    .background {
      padding: 2px 0;
      background-color: @SecondaryBlue05;

      animation: rightSlideIn 500ms ease;

      .totalCount {
        font-size: 12px;
        font-family: Graphik;
        color: @PrimaryCobalt60;

        &.hidden {
          display: none;
        }
      }
    }
  }
}

.tooltip {
  min-width: 240px;
  padding: 12px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 2px 16px @PrimaryCobaltOp15;

  .tooltipHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid @SecondaryBlue20;
    font-size: 14px;
    font-family: Gilroy;
    font-weight: 700;
    color: @PrimaryCobalt;
  }

  .tooltipItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 8px;
    font-size: 12px;
    font-family: Graphik;
    font-weight: 500;
    color: @PrimaryCobalt60;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rightSlideIn {
  0% {
    opacity: 0;
    transform: translateX(50%) scaleX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scaleX(1);
  }
}

@keyframes leftSlideIn {
  0% {
    opacity: 0;
    transform: translateX(-50%) scaleX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scaleX(1);
  }
}

@keyframes bottomSlideIn {
  0% {
    opacity: 0;
    transform: translateY(50%) scaleY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}
