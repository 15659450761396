@import 'src/less/colors';

.content {
  padding: 0 !important;
}

.form {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .left {
    flex: 1;
  }

  .right {
    flex: 0.4;
    background: #f3f4f8;
  }

  .rightBig {
    flex: 0.6;
  }

  .padding {
    padding: 12px 24px 24px 24px;
  }

  .marginTop {
    margin-top: 23px !important;
  }

  .input {
    input {
      border-radius: 8px !important;
      box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
    }
  }

  .select {
    border-radius: 8px !important;
    box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
  }

  .formatOptions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .customButton {
      background-color: @PrimaryCobalt05;
      padding: 8px;
      border-radius: 8px;
      color: @PrimaryCobalt80;
      font-size: 12px;
      font-weight: normal;
      border: 2px solid @PrimaryCobalt05;
      font-family: Graphik, sans-serif;
      white-space: nowrap;
      cursor: pointer;
      flex: 1;
    }

    .buttonActive {
      border: 2px solid #4864c9;
      background: @SecondarySkyBlue10;
      color: @PrimaryCobalt;
      font-weight: 500;
    }
  }

  .infoAlert {
    margin-top: 30px;
    background: #f1f6ff;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    color: @SecondaryBlue;

    .bold {
      font-weight: 500;
    }
  }
}

.examples {
  max-height: 400px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .examplesTitle {
    font-family: 'Gilroy';
    font-weight: 700;
    font-size: 16px;
    color: @SecondaryBlue;
  }

  .example {
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #dae0f4;
    border-radius: 8px;
    padding: 16px;

    .subtitle {
      display: block;
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 12px;
      color: @PrimaryCobalt60;
      margin-bottom: 5px;
    }

    .text {
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 14px;
      color: @PrimaryCobalt;
    }
  }
}
