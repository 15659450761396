.container {
  input {
    opacity: 0;
    display: block;
    width: 32px;
    height: 32px;
    border: none;
    cursor: pointer;
  }
}
