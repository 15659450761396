@import 'src/less/colors';

.displayHeader {
  display: flex;
  flex-direction: row;
}

.publishToggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;

  .publishToggle {
    margin-bottom: 0px;
  }
}

.displayHeaderInfo {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 0;
  color: @SecondarySkyBlue;
  display: inline-block;
}

.sectionsContainer {
  margin-top: 32px;
}

.linkIcon {
  margin-left: 10px;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.headerAndStatus {
  display: flex;
  flex-direction: row;

  a:hover .header {
    text-decoration: underline;
  }

  h2 {
    line-height: 22px;
  }
}

.hsFlexSpacer {
  flex: 1;
  margin-top: 24px;
}

.publicationStatus {
  display: flex;
  align-items: center;
  margin-left: 16px;

  line-height: 20px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;

  &.published {
    //background-color: @PositiveColor10;
    color: @PositiveColor;
  }
  &.unpublished {
    //background-color: @PositiveColor10;
    color: @PrimaryCobalt60;
  }

  i {
    margin-right: 5px;
  }
}
.postingManagementInfo {
  margin-bottom: 36px;
}

.folderingDisplay {
  margin-top: 8px;
  margin-bottom: 16px;
  color: @PrimaryCobalt60;
}

.locationsDisplay {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  margin-top: 16px;
}

.jobDescriptionContainer {
  margin-top: 40px;
}

.inputContainer:not(:first-of-type) {
  margin-top: 12px;
}

.inputInlineLabel {
  margin-left: 8px;
  display: inline-block;
}

.removeJobPostingIcon {
  margin-left: 8px;
  color: @PrimaryCobalt60;
  cursor: pointer;
}

.inputLabel {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}

.simpleSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0px;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputElement {
  > div:global(.salary-section) {
    div:global(.ui.input.money-input) {
      box-shadow: 0px 2px 4px 0px #1f2e770f;
      border-radius: 8px;
      input {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      div:global(.ui.label) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    div:global(.currency) {
      > div > div {
        box-shadow: 0px 2px 4px 0px #1f2e770f;
        border-radius: 8px;
      }
    }
    div:global(.ui.input.money-input-no-unit) {
      input {
        box-shadow: 0px 2px 4px 0px #1f2e770f;
        border-radius: 8px;
      }
    }
  }
  div:global(.salary-type) {
    > div > div {
      box-shadow: 0px 2px 4px 0px #1f2e770f;
      border-radius: 8px;
    }
  }

  .free-salary-input {
    border-radius: 8px;
  }

  &.inline {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.aiGenerationLoader {
  text-align: center;
  position: relative;
  margin-bottom: 12px;
}

.externalLink {
  display: flex;
  margin: 8px 0;

  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  overflow: hidden;

  .source {
    border-right: inherit;
    background-color: @SecondarySkyBlue05;
    padding: 8px 16px;
    color: @PrimaryCobalt;
    font-weight: 500;
  }

  .link {
    flex: 1;
    padding: 8px 16px;
    color: @PrimaryCobalt80;
  }

  .copy {
    padding: 8px 16px;
    border-left: inherit;
    cursor: pointer;

    &:hover {
      background-color: @SecondarySkyBlue05;
    }
  }
}
