@import 'src/less/colors';

.trigger {
  display: inline-flex;
  padding: 0;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    .label {
      text-decoration: underline;
    }
  }

  .label {
    font-family: Graphik;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: @SecondaryBlue;
  }

  .arrow {
    font-size: 16px;
    line-height: 16px;
    color: @SecondaryBlue;
  }
}
