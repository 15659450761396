/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.full-contact-flow-action-editor,
.ui.segment.full-contact-flow-action-editor {
  padding-top: 16px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
}
.full-contact-flow-action-editor.error,
.ui.segment.full-contact-flow-action-editor.error {
  border-color: #f66f81;
}
.full-contact-flow-action-editor h4,
.ui.segment.full-contact-flow-action-editor h4,
.full-contact-flow-action-editor h5,
.ui.segment.full-contact-flow-action-editor h5 {
  color: #709dff;
}
.full-contact-flow-action-editor .email-header,
.ui.segment.full-contact-flow-action-editor .email-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1f2e77;
}
.full-contact-flow-action-editor .email-header h3,
.ui.segment.full-contact-flow-action-editor .email-header h3 {
  margin: 0;
  padding-right: 16px;
}
.full-contact-flow-action-editor .email-header .ui.dropdown .text,
.ui.segment.full-contact-flow-action-editor .email-header .ui.dropdown .text {
  color: #1f2e77;
  font-weight: 500;
}
.full-contact-flow-action-editor .template-header,
.ui.segment.full-contact-flow-action-editor .template-header {
  margin: 0 16px 0 0;
}
.full-contact-flow-action-editor .apply-to-next-mails,
.ui.segment.full-contact-flow-action-editor .apply-to-next-mails {
  color: #4864c9;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}
.full-contact-flow-action-editor .action-editor-header,
.ui.segment.full-contact-flow-action-editor .action-editor-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}
.full-contact-flow-action-editor .meta-task-form-container,
.ui.segment.full-contact-flow-action-editor .meta-task-form-container {
  margin-top: 12px;
}
.full-contact-flow-action-editor .action-editor-config .action-editor-config-header,
.ui.segment.full-contact-flow-action-editor .action-editor-config .action-editor-config-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-contact-flow-action-editor .unipile-sender-toggle,
.ui.segment.full-contact-flow-action-editor .unipile-sender-toggle {
  cursor: pointer;
  text-decoration: underline;
  text-align: right;
  flex-shrink: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #7982ad;
}
.full-contact-flow-action-editor .task-type-dropdown,
.ui.segment.full-contact-flow-action-editor .task-type-dropdown {
  min-width: 320px;
}
.full-contact-flow-action-editor .task-type-dropdown .item .item-content,
.ui.segment.full-contact-flow-action-editor .task-type-dropdown .item .item-content {
  display: flex;
  align-items: center;
  gap: 12px;
}
.full-contact-flow-action-editor .task-type-dropdown .item img,
.ui.segment.full-contact-flow-action-editor .task-type-dropdown .item img {
  width: 20px;
  height: 20px;
}
.full-contact-flow-action-editor .task-type-dropdown .item span,
.ui.segment.full-contact-flow-action-editor .task-type-dropdown .item span {
  margin: 0;
  display: inline-block;
  line-height: 20px;
  font-size: 20px;
}
.full-contact-flow-action-editor .detach-button,
.ui.segment.full-contact-flow-action-editor .detach-button {
  display: flex;
  align-items: center;
}
.full-contact-flow-action-editor .detach-button i,
.ui.segment.full-contact-flow-action-editor .detach-button i {
  font-weight: 600;
}
.full-contact-flow-action-editor .sequences-actions-dropdown,
.ui.segment.full-contact-flow-action-editor .sequences-actions-dropdown {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #5c89eb;
  cursor: pointer;
}
.full-contact-flow-action-editor .sequences-actions-dropdown .dropdown-action,
.ui.segment.full-contact-flow-action-editor .sequences-actions-dropdown .dropdown-action {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}
.full-contact-flow-action-editor .sequences-actions-dropdown .dropdown-action-delete,
.ui.segment.full-contact-flow-action-editor .sequences-actions-dropdown .dropdown-action-delete {
  border-top: 1px solid #e9ebf2;
}
.full-contact-flow-action-editor .sequences-actions-dropdown .confirm-delete,
.ui.segment.full-contact-flow-action-editor .sequences-actions-dropdown .confirm-delete {
  color: #e45a6b;
}
.full-contact-flow-action-editor .edit-template-button,
.ui.segment.full-contact-flow-action-editor .edit-template-button {
  margin-top: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #709dff;
  font-size: 12px;
  font-weight: 500;
}
.full-contact-flow-action-editor .edit-template-button:hover span,
.ui.segment.full-contact-flow-action-editor .edit-template-button:hover span {
  text-decoration: underline;
}
.full-contact-flow-action-editor .edit-template-button i,
.ui.segment.full-contact-flow-action-editor .edit-template-button i {
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 1px;
}
.full-contact-flow-action-editor .action-template-content-checkbox,
.ui.segment.full-contact-flow-action-editor .action-template-content-checkbox {
  margin-top: 24px;
}
.full-contact-flow-action-editor .action-template-content-checkbox label,
.ui.segment.full-contact-flow-action-editor .action-template-content-checkbox label {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #7982ad;
}
.full-contact-flow-action-editor .action-template-editor,
.ui.segment.full-contact-flow-action-editor .action-template-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.full-contact-flow-action-editor .template-selector,
.ui.segment.full-contact-flow-action-editor .template-selector {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.full-contact-flow-action-editor .template-selector-dropdown.ui.dropdown,
.ui.segment.full-contact-flow-action-editor .template-selector-dropdown.ui.dropdown {
  margin: 0;
}
.full-contact-flow-action-editor .template-selector-dropdown.ui.dropdown .text,
.ui.segment.full-contact-flow-action-editor .template-selector-dropdown.ui.dropdown .text {
  font-family: Graphik;
  font-weight: normal;
  font-size: 13px;
  color: #4864c9;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}
.full-contact-flow-action-editor .template-selector-dropdown.ui.dropdown .text:hover,
.ui.segment.full-contact-flow-action-editor .template-selector-dropdown.ui.dropdown .text:hover {
  text-decoration: underline;
}
.full-contact-flow-action-editor .offer-card-email,
.ui.segment.full-contact-flow-action-editor .offer-card-email {
  margin-top: 8px;
}
.full-contact-flow-action-editor .action-trigger-container,
.ui.segment.full-contact-flow-action-editor .action-trigger-container {
  padding: 0 24px 16px;
  margin: 0 -24px;
  display: flex;
  border-bottom: 1px solid #e2ebff;
}
.full-contact-flow-action-editor .description-container,
.ui.segment.full-contact-flow-action-editor .description-container {
  margin-top: 8px;
}
.full-contact-flow-action-editor .description-container textarea,
.ui.segment.full-contact-flow-action-editor .description-container textarea {
  min-height: 100px;
}
.full-contact-flow-action-editor .email-type-dropdown.ui.dropdown,
.ui.segment.full-contact-flow-action-editor .email-type-dropdown.ui.dropdown {
  margin-left: 8px;
}
.full-contact-flow-action-editor .email-type-dropdown.ui.dropdown.error,
.ui.segment.full-contact-flow-action-editor .email-type-dropdown.ui.dropdown.error {
  background: transparent;
}
.full-contact-flow-action-editor .unipile-sender-dropdown :global(.dropdownContent),
.ui.segment.full-contact-flow-action-editor .unipile-sender-dropdown :global(.dropdownContent) {
  z-index: 2 !important;
}
.conditional-rules-container,
.periodic-check-container {
  margin-top: 8px;
}
.conditional-rules-container label,
.periodic-check-container label {
  display: block;
  margin-bottom: 4px;
  color: #1f2e77;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
}
.conditional-rules-container h5,
.periodic-check-container h5 {
  margin-bottom: 8px;
}
.conditional-rules-container .field button,
.periodic-check-container .field button {
  padding-left: 0;
}
