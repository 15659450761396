/*
 * colors.less
 */
.create-tasks-sidebar {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.create-tasks-sidebar .dimmer {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  height: 100%;
  flex: 1;
}
.create-tasks-sidebar .sidebar {
  width: 400px;
  height: 100%;
  background-color: white;
}
.create-tasks-sidebar .no-sequences-content {
  height: calc(100% - 44px);
  background-color: #f8faff;
  padding: 24px;
  font-size: 15px;
  line-height: 1.3;
}
.create-tasks-sidebar .tasks-form {
  height: calc(100% - 44px);
}
.create-tasks-sidebar .content {
  height: calc(100% - 70px);
  background-color: #f8faff;
  padding: 24px;
}
.create-tasks-sidebar .sequence-dropdown.field {
  margin-top: 24px;
}
.create-tasks-sidebar .header {
  padding: 12px 16px;
  color: white;
  background-color: #1f2e77;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-tasks-sidebar .header h4 {
  margin: 0;
}
.create-tasks-sidebar .header i {
  color: #a5abc9;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
.create-tasks-sidebar .header i:hover {
  color: white;
}
.create-tasks-sidebar .footer {
  padding: 16px 24px;
  box-shadow: 0px -4px 17px rgba(186, 186, 186, 0.75);
  justify-content: flex-end;
  display: flex;
}
.create-tasks-sidebar .ui.button.submit-button {
  padding: 8px 16px;
}
.create-tasks-sidebar .due-date-dropdowns {
  display: flex;
  flex-direction: row;
}
.create-tasks-sidebar .due-when-dropdown.ui.dropdown {
  width: 140px;
  min-width: 140px;
}
.create-tasks-sidebar .react-datepicker-wrapper {
  flex-grow: 1;
  margin-left: 16px;
}
.create-tasks-trigger i {
  font-weight: 400;
  margin-right: 8px;
}
.add-to-sequence-trigger.ui.button {
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.add-to-sequence-trigger.ui.button i {
  font-weight: 400;
  margin-right: 8px;
}
