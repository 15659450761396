/*
 * colors.less
 */
.tooltip {
  background-color: #10173d !important;
  color: white !important;
  font-family: Graphik !important;
  box-shadow: none !important;
  border: none !important;
}
.tooltip:not(.rich) {
  padding: 8px !important;
  border-radius: 4px !important;
  max-width: 240px !important;
  font-size: 12px !important;
  text-align: center;
}
.tooltip.rich {
  min-width: 300px;
  padding: 12px !important;
  border-radius: 8px !important;
}
