/*
 * colors.less
 */
.linear-heatmap {
  display: flex;
}
.linear-heatmap .heatmap-item {
  margin: -4px 5px -4px -4px;
  padding: 4px;
}
.linear-heatmap .heatmap-item:last-child {
  margin-right: -4px;
}
.linear-heatmap .heatmap-item > div {
  height: 16px;
  width: 6px;
  border-radius: 8px;
}
.linear-heatmap .heatmap-item .shade-0 {
  background-color: #e9ebf2;
}
.linear-heatmap .heatmap-item .shade-1 {
  background-color: #c6d8ff;
}
.linear-heatmap .heatmap-item .shade-2 {
  background-color: #a9c4ff;
}
.linear-heatmap .heatmap-item .shade-3 {
  background-color: #8db1ff;
}
.linear-heatmap .heatmap-item .shade-4 {
  background-color: #709dff;
}
.linear-heatmap .heatmap-item .shade-5 {
  background-color: #5c89eb;
}
.popup-header {
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.popup-content {
  color: #7982ad;
  font-weight: 500;
}
.popup-content .popup-content-count {
  color: #4864c9;
  font-size: 18px;
  font-family: Gilroy, sans-serif;
  font-weight: bold;
}
