/*
 * colors.less
 */
.funnel-kpi {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 32px 64px;
}
.funnel-kpi .kpi-title {
  font-family: Graphik, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #7982ad;
}
.funnel-kpi .score {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.funnel-kpi .score .absolute {
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
}
.funnel-kpi .score .symbol {
  margin-left: 2px;
  font-size: 18px;
}
.funnel-kpi .score .clickable {
  cursor: pointer;
}
.funnel-kpi .score .clickable:hover {
  text-decoration: underline;
}
.funnel-kpi .score .percent {
  font-family: Gilroy, sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.funnel-kpi .score.grey {
  color: #a5abc9;
}
.funnel-kpi .score.cobalt {
  color: #4c5892;
}
.funnel-kpi .score.skyblue {
  color: #709dff;
}
.funnel-kpi .score.blue {
  color: #4864c9;
}
.funnel-kpi .score.lightgreen {
  color: #3cde56;
}
.funnel-kpi .score.green {
  color: #14b62e;
}
