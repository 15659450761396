@import 'src/less/colors';

.table {
  border-radius: 8px !important;
  overflow: hidden;

  .header {
    background: #f8faff !important;
    border: 1px solid #dae0f4 !important;

    .th {
      padding: 12px;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0px;
      text-align: left;
      color: @PrimaryCobalt60;
      text-transform: uppercase;
    }
  }

  .row {
    background: #ffffff !important;
    border-bottom: 1px solid #dae0f4 !important;

    .name {
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: @PrimaryCobalt;
    }

    .icon {
      margin: 0px 5px;
      color: #7982ad;
      font-size: 22px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
