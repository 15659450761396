/*
 * colors.less
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-section.recommended-profiles .header .link a {
  color: #4864c9;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
}
.dashboard-section.recommended-profiles .header .link a:hover {
  text-decoration: underline;
}
.dashboard-section.recommended-profiles .card-container {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 8px;
  border-radius: 4px;
  margin-bottom: -8px;
}
.dashboard-section.recommended-profiles .card-container:not(.placeholder) {
  border-right: 1px solid #E8E8E8;
}
.dashboard-section.recommended-profiles .card-container.placeholder {
  justify-content: center;
  align-items: center;
  height: 250px;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card {
  flex: 0 0 296px;
  max-width: 296px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card:hover {
  box-shadow: 2px 2px 10px 1px rgba(72, 100, 201, 0.09);
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card:not(:first-child) {
  margin-left: 8px;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E8E8E8;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-header .mini-card-header-title {
  color: #4c5892;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header .profile-avatar {
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header .profile-avatar img {
  width: 40px;
  height: 40px;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header .profile-info {
  width: calc(100% - 56px);
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header .profile-info .profile-name {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4864c9;
  display: flex;
  align-items: center;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header .profile-info .full-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-header .profile-info .profile-headline {
  margin-top: 4px;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4c5892;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-details {
  border-top: 1px dashed #E8E8E8;
  padding: 16px 24px;
  width: 100%;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-details .profile-detail {
  display: flex;
  flex-direction: row;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-details .profile-detail .profile-detail-icon {
  font-size: 17px;
  margin-right: 8px;
  color: #d2d5e4;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card .mini-card-profile .mini-card-profile-details .profile-detail .profile-detail-content {
  color: #4c5892;
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card.mini-card-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F8FAFF;
}
.dashboard-section.recommended-profiles .card-container .candidate-mini-card.mini-card-placeholder:hover,
.dashboard-section.recommended-profiles .card-container .candidate-mini-card.mini-card-placeholder :focus {
  text-decoration: underline;
}
.dashboard-section.recommended-profiles .card-container .summary {
  margin: -4px -12px;
  color: #3450b5;
  background-color: #f6f7fc;
  border-radius: 2px;
  padding: 12px;
  font-size: 12px;
  line-height: 17px;
}
.tooltip-text .strong {
  font-weight: 500;
}
.tooltip-text .nowrap {
  white-space: nowrap;
}
