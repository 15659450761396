/*
 * colors.less
 */
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}
.container .chart {
  flex: 1;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  aspect-ratio: 1;
  position: relative;
}
.container .legend {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.container .legend .legendValue {
  text-align: right;
  white-space: nowrap;
}
.container .legend .legendValue .percentage {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  color: #1f2e77;
}
.container .legend .legendValue .number {
  display: inline-block;
  vertical-align: middle;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  color: #7982ad;
}
