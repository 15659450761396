@import 'src/less/colors.less';

.atsLastSyncValue,
.atsLastSyncText {
  color: @PrimaryCobalt60;
  font-family: Graphik;
  font-size: 12px;
}

.atsLastSyncText {
  &:not(.minimized) {
    margin-left: 8px;
  }
  font-weight: 400;
}

.atsLastSyncValue {
  font-weight: 500;
  margin-left: 4px;
}
