@import 'src/less/colors';

.themeContainer {
  .inputContainer {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
    .inputLabel {
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 8px;
      color: #4c5892;
    }

    .colorsContainer {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .colorOption {
      border: 1px solid @SecondaryBlue20;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      cursor: pointer;

      &.selectedColor {
        border: 2px solid @SecondaryBlue;
      }
    }

    .colorInputContainer {
      margin-top: 8px;
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .borderRadiusSelector {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
