.json {
  height: auto;
  max-height: 600px;
  overflow: scroll;
  background-color: #ebebeb;
  margin: 10px 0;
}
.json .pre {
  height: 100%;
  margin: 0;
}
.jsonInvalid {
  border: 1px solid red;
}
.textWrapper {
  width: 100%;
}
.textWrapper .instantiatedText {
  width: 100%;
  height: 160px;
  background-color: white;
}
.textWrapper .text {
  background-color: white;
}
.textWrapper .text textarea {
  width: 100%;
  height: 160px;
}
.snippetsWrapper {
  margin-top: 40px;
}
.snippetsWrapper .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
