/*
 * colors.less
 */
.sweetapp-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.sweetapp-footer img.footer-bullet {
  display: inline;
}
.sweetapp-footer img.chrome-logo {
  margin-right: 10px;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-extra-sections {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  color: #a5abc9;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-extra-sections span,
.footer-extra-sections a {
  color: #a5abc9;
  margin: 14px;
  cursor: pointer;
}
.footer-extra-sections span:hover,
.footer-extra-sections a:hover {
  text-decoration: underline;
}
.footer-mentions {
  margin-top: 10px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  color: #d2d5e4;
}
.footer-mentions span,
.footer-mentions a {
  color: #d2d5e4;
  margin: 0 8px;
  cursor: pointer;
}
.footer-mentions span:hover,
.footer-mentions a:hover {
  text-decoration: underline;
}
.footer-chrome-link {
  align-items: center;
  display: flex;
}
