@import 'src/less/colors';

.section {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
  border: 1px solid @SecondaryBlue20;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .illustration {
    @media (max-width: 1400px) {
      display: none;
    }
    flex-shrink: 0;
    width: 80px;
  }

  .content {
    flex: 1;

    .title {
      margin-bottom: 8px;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      color: @PrimaryCobalt;
    }

    .description {
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 138%;
      color: @PrimaryCobalt60;
    }
  }
}
