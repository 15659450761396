/*
 * colors.less
 */
.checkbox {
  display: inline-flex;
  vertical-align: text-top;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  font-size: 16px;
  flex-shrink: 0;
  border: 2px solid #d2d5e4;
  background-color: white;
  color: white;
  border-radius: 4px;
  user-select: none;
}
.checkbox:is(button) {
  cursor: pointer;
}
.checkbox.big {
  width: 24px;
  height: 24px;
}
.checkbox.checked {
  border-color: #4864c9;
  background-color: #4864c9;
}
.checkbox input {
  display: none;
}
