/*
 * typography.less
 */

// Common
body {
  .body-regular();
  // TODO: makes most fonts thinner / less heavy
  -webkit-font-smoothing: antialiased;
}

body .ui.input > input {
  font-family: Graphik, sans-serif !important;
}

input,
optgroup,
select,
textarea {
  font-family: Graphik, sans-serif;
}

// Headlines
h1,
.headline-1 {
  font-family: Gilroy, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
}

h2,
.headline-2 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

h3,
.headline-3 {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

h4,
.headline-4 {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

h5,
.headline-5 {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}

// Body
.body-semibold {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.body-medium {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.body-regular {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

// Captions
.caption-medium {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
}

.caption {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1px;
}

.caption-2 {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
