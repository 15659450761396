/*
 * colors.less
 */
.applicationsCriterias {
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #dae0f4;
}
.applicationsCriterias .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #1f2e77;
}
.applicationsCriterias .header .title {
  font-size: 14px;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.applicationsCriterias .header .title .icon {
  vertical-align: text-bottom;
  margin-right: 8px;
  color: #4864c9;
}
.applicationsCriterias .header .publishToggleContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
.applicationsCriterias .header .publishToggleContainer > div {
  margin-bottom: 0px;
  width: 30px;
}
.applicationsCriterias .settings .jobPostingTitle {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #1f2e77;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 12px;
}
.applicationsCriterias .settings .jobPostingTitle .link {
  line-height: 13px;
}
.applicationsCriterias .settings .jobPostingTitle .link i {
  margin-top: 6px;
}
.applicationsCriterias .settings .tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin: 8px 0px 16px;
}
.applicationsCriterias .settings .tags .calibration-label {
  padding: 8px;
  line-height: 12px;
  border-radius: 2px;
  background-color: #f3f4f8;
  color: #4c5892;
}
