/*
 * colors.less
 */
.category {
  white-space: nowrap;
}
.category .box {
  display: inline-block;
  vertical-align: middle;
  height: 1em;
  width: 1em;
  border-radius: 3px;
  margin-right: 8px;
}
.category .label {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-family: Graphik;
  font-weight: 500;
  color: #1f2e77;
}
.category.condensed .box {
  margin-right: 4px;
}
