/*
 * colors.less
 */
.reveal-setup-modal .instruction {
  margin-bottom: 4px;
  font-weight: 500;
  color: #4c5892;
}
.reveal-setup-modal h3 {
  color: #1f2e77;
}
.reveal-setup-modal .permissions-container {
  display: flex;
  margin: 0 1em;
  border: 1px solid #4c5892;
  border-radius: 2px;
  padding: 1em;
}
.reveal-setup-modal .permissions-container ol {
  flex-basis: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
}
.reveal-setup-modal .category-name {
  font-weight: 500;
  margin-bottom: 4px;
  color: #4c5892;
}
.reveal-setup-modal .routes {
  line-height: 22px;
  margin-left: 1em;
  color: #7982ad;
}
