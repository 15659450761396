@import 'src/less/colors';

.clientModes {
  margin-bottom: 48px;
}

.clientModeButton {
  display: inline-block;
  margin-right: 16px;
}

.permissionsContainer {
  margin-bottom: 48px;
  .permissionContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border-radius: 8px;

    &:hover {
      background-color: @SecondarySkyBlue20;
    }

    .toggle {
      margin-bottom: 0px;
    }

    .permissionTitle {
      color: @PrimaryCobalt40;
      font-weight: 500;
      font-size: 14px;

      &.active {
        color: @PrimaryCobalt;
      }
    }
  }
}
