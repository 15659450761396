@import 'src/less/colors';

.alertBox {
  background-color: @SecondaryRed20;
  border-radius: 8px;
  padding: 16px;
  color: @SecondaryRed120;

  .title {
    color: @SecondaryRed120;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .description {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: @SecondaryRed120;
  }
}
