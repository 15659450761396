/*
 * colors.less
 */
.tableHeader {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 18px 24px;
  background-color: #f8faff;
  color: #7982ad;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  border-bottom: 1px solid #dae0f4;
}
.tableHeader .name {
  width: 300px;
}
.tableHeader .headerStage {
  flex: 1;
}
.tableHeader .headerStage.span2 {
  flex: 2;
}
.tableHeader .headerStage.archived {
  text-align: center;
}
