@import 'src/less/colors';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;

  .chart {
    flex: 1;
    max-width: 300px;
    max-height: 300px;
    margin: auto;
    aspect-ratio: 1;
    position: relative;
  }

  .legend {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .legendValue {
      text-align: right;
      white-space: nowrap;

      .percentage {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 600;
        color: @PrimaryCobalt;
      }

      .number {
        display: inline-block;
        vertical-align: middle;
        font-family: Graphik;
        font-size: 14px;
        font-weight: 400;
        color: @PrimaryCobalt60;
      }
    }
  }
}
