/*
 * colors.less
 */
.projectProfileViewSettingsHeader {
  position: relative;
  margin: 6px 0px 26px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  z-index: 3;
}
.projectProfileViewSettingsHeader .displayTypeDropdown {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #7982ad;
  display: flex;
  align-items: center;
}
.projectProfileViewSettingsHeader .displayTypeDropdown .displayTypeDropdownTrigger {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
.projectProfileViewSettingsHeader .displayTypeDropdown .displayTypeDropdownTrigger svg {
  width: 16px;
  height: 16px;
  margin-top: -2px;
}
.projectProfileViewSettingsHeader .right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.projectProfileViewSettingsHeader .right .disabledButton {
  filter: grayscale(100%);
  background: #edf0fa;
  cursor: initial;
  color: grey !important;
}
.projectProfileViewSettingsHeader .right .weekSelectorContainer {
  position: relative;
  padding: 0px 16px;
  width: 200px;
}
.projectProfileViewSettingsHeader .right .weekSelectorContainer .currentWeek {
  height: 32px;
  width: calc(100%);
  border: 1px solid #b6c1e9;
  background-color: white;
  padding: 5px 32px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #7982ad;
}
.projectProfileViewSettingsHeader .right .weekSelectorContainer .prevSelector,
.projectProfileViewSettingsHeader .right .weekSelectorContainer .nextSelector {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  background-color: white;
  border: 1px solid #b6c1e9;
  border-radius: 24px;
  text-align: center;
  padding: 5px 0px;
  cursor: pointer;
}
.projectProfileViewSettingsHeader .right .weekSelectorContainer .prevSelector {
  left: 0;
}
.projectProfileViewSettingsHeader .right .weekSelectorContainer .nextSelector {
  right: 0;
}
