/*
 * colors.less
 */
.match-level-gauge {
  height: 16px;
  display: inline-flex;
  gap: 3px;
  flex-direction: row;
}
.match-level-gauge .back-bar,
.match-level-gauge .fill-bar,
.match-level-gauge .none-bar {
  display: inline-block;
  height: 100%;
  width: 3px;
}
.match-level-gauge .back-bar {
  background-color: #e9ebf2;
}
.match-level-gauge .fill-bar {
  background-color: #709dff;
}
.match-level-gauge.positive .fill-bar {
  background-color: #28ca42;
}
