@import 'src/less/colors';

.fieldItem {
  padding: 16px 8px;
  &:not(:last-child) {
    border-bottom: 1px solid @SecondaryBlue20;
  }
  display: flex;
  align-items: center;

  .fieldEditIcon {
    visibility: hidden;

    display: inline-block;
    vertical-align: middle;

    &::before {
      vertical-align: initial;
    }
  }

  :hover > .fieldEditIcon {
    visibility: visible;
    margin-left: 0.25rem;
    color: #333;
  }
}

.handle {
  padding: 0 0.9rem;

  display: inline-block;
  vertical-align: middle;

  &::before {
    vertical-align: initial;
  }
}

.fieldName {
  flex: 1 0;
  cursor: pointer;
}

.fieldTypeTag {
  background-color: @SecondarySkyBlue10;
  color: @PrimaryCobalt;
  border-radius: 2px;
  padding: 6px 4px;
  line-height: 22px;

  i {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;

    &::before {
      vertical-align: initial;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.cell {
  flex: 1 0;
}

.fieldAction {
  cursor: pointer;
}

.buttonAdd:global(.ui.button) {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  i {
    margin-left: 0.5rem;
  }
}

.modalActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
