/*
 * colors.less
 */
.box {
  text-align: center;
  margin: 30px auto;
}
.button {
  font-family: Gilroy;
  color: #4c5892;
  font-size: 12px;
  text-decoration: underline;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
}
.modal {
  width: 720px;
  min-height: 400px;
}
