@import 'src/less/colors';

.snoozeDimmer {
  position: absolute;
  inset: -15px;

  background-color: @PrimaryCobaltOp30;
}

.infoBox {
  margin-top: 8px;
}
