@import 'src/less/colors';

.button {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 22px;
  border-radius: 50%;
  background: @SecondaryBlue10;
  color: @PrimaryCobalt;
  border: 1px solid white;

  cursor: pointer;

  &.active {
    background: @SecondaryRed;
    color: white;
  }
}
