/*
 * colors.less
 */
.fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #404564;
}
.infoBox {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
  background-color: #f1f6ff;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  color: #1f2e77;
  font-size: 12px;
}
.infoBox .infoBoxTitle {
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 0px;
}
.infoBox .infoBoxPicture {
  width: 210px;
}
.infoBoxInstructions {
  flex-shrink: 0;
  align-self: center;
}
.modalActions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
