@import 'src/less/colors';

.container {
  padding: 24px;
  padding-bottom: 40px;
}

.title {
  margin-top: 12px;
  margin-bottom: 16px;
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  color: @PrimaryCobalt;
}

.bullet {
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 12px 0px;
  gap: 8px;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
}

.count {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: @PrimaryCobalt;
  border-radius: 20px;
}

.main {
  background-color: @PrimaryPink80;
}

.extended {
  background-color: @SecondarySkyBlue20;
}

.paragraph {
  flex: 1;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: @PrimaryCobalt60;

  strong {
    font-weight: 500;
  }
}

.cta {
  margin: 16px 0;
  text-align: center;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: @PrimaryCobalt;
}

.button {
  display: block;
  margin: auto;
}
