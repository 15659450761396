/*
 * colors.less
 */
.profile-tabs-menu.plugin {
  padding: 8px 16px 0 16px;
  box-shadow: inset 0px -1px 0px #dae0f4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.profile-tabs-menu.plugin .tab {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  font-family: Gilroy;
  color: #a5abc9;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}
.profile-tabs-menu.plugin .tab .ui.label {
  padding: 3px 5px 2px;
}
.profile-tabs-menu.plugin .tab:hover {
  outline: none;
  color: #709dff;
}
.profile-tabs-menu.plugin .tab.active {
  color: #4864c9;
  border-bottom: 2px solid #4864c9;
}
.profile-tabs-menu.plugin .tab.active .ui.label {
  background-color: white;
  color: #4864c9;
}
