/*
 * colors.less
 */
.settings-menu .settings-username-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}
.settings-menu .settings-username-title .username {
  margin-top: 5px;
  margin-left: 16px;
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  color: #1f2e77;
}
.settings-menu .ui.text.menu {
  width: 80%;
}
.settings-menu .ui.text.menu .item {
  padding: 8px;
  margin: 4px 0;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  color: #a5abc9;
  border-left: 2px solid #f8faff;
}
.settings-menu .ui.text.menu .item:hover {
  color: #a5abc9;
}
.settings-menu .ui.text.menu .item.active {
  border-radius: 0;
  cursor: default;
  color: #709dff;
  border-left: 2px solid #709dff;
}
.settings-menu .user-initial-thumbnail {
  width: 44px;
  height: 44px;
  font-size: 16px;
  padding-top: 3px;
}
.settings-menu .settings-menu-divider {
  margin: 12px 4px;
}
