/*
 * colors.less
 */
.button-toggle.ui.buttons {
  border: 1px solid #e9ebf2;
  border-radius: 2px;
  height: 28px;
}
.button-toggle.ui.buttons .ui.button {
  padding: 6px 11px;
  background-color: white;
  border-radius: 0;
  color: #e9ebf2;
}
.button-toggle.ui.buttons .ui.button.active {
  margin: -1px;
  background-color: #f1f6ff;
  border: 1px solid #709dff;
  border-radius: 2px;
  color: #709dff;
  z-index: 1;
}
.button-toggle.ui.buttons .ui.button i {
  font-size: 16px;
}
