/*
 * colors.less
 */
.header {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  background: white;
  padding: 24px;
}
.header > div {
  flex: 1 1;
}
.header .headerTitle {
  text-align: center;
}
.header .links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}
.banner {
  height: 300px;
  overflow: hidden;
}
.previewSection {
  margin: 24px 0px;
  padding: 0px 24px;
}
.previewSection .sectionTitle {
  margin-bottom: 12px;
}
.jobsfilter {
  margin-bottom: 12px;
}
.jobPostingCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.jobPostingCardsContainer.gridLayout {
  flex-direction: row;
  align-items: center;
}
.jobPostingCardsContainer.gridLayout .jobPostingCard {
  flex: 1 1;
}
.jobPostingCardsContainer .jobPostingCard {
  border: 1px solid #dae0f4;
  padding: 24px;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
}
.jobPostingCardsContainer .firstSectionPreview {
  margin-top: 12px;
  font-size: 12px;
}
.nativeFooter {
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
}
