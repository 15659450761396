@import 'src/less/colors';

.alert {
  margin: 8px 0;
  padding: 7.5px 12px;

  border-radius: 3px;
  border-left: 4px solid @SecondaryRed;
  font-size: 12px;
  font-family: Graphik;

  &.error {
    background-color: @SecondaryRed10;
  }

  &.warning {
    border-color: @Yellow;
    background-color: @Yellow05;
  }

  &.info {
    border-color: @SecondaryBlue;
    background-color: @SecondaryBlue10;
  }

  &.flex {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .title {
    font-weight: 500;
    color: @PrimaryCobalt;
    margin-right: 4px;
  }

  .description {
    color: @PrimaryCobalt80;
  }
}
