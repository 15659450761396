/*
 * colors.less
 */
.smart-tags-and-summary .summary-section {
  padding: 24px 0;
  border-bottom: 1px solid #dae0f4;
}
.smart-tags-and-summary .summary-section-title {
  color: #1f2e77;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}
.smart-tags-and-summary .talent-strategist-container .talent-strategist-title {
  margin-bottom: 10px;
}
.smart-tags-and-summary .talent-strategist-container .talent-strategist {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #4c5892;
}
.smart-tags-and-summary .profile-skills .title {
  display: flex;
  flex-direction: row;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #4c5892;
  margin-bottom: 8px;
}
.smart-tags-and-summary .profile-skills .smart-tag-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: #e6f5f9;
  color: #2ea4cc;
}
.smart-tags-and-summary .profile-skills .smart-tag-icon {
  height: 10px;
  width: 10px;
}
.smart-summary .profile-summary span {
  font-size: 12px;
}
.smart-summary .summary-section-title {
  display: flex;
  align-items: center;
}
.smart-summary .summary-section-title img {
  width: 22px;
  height: 22px;
  display: inline;
  margin-left: 8px;
  clip-path: circle(50%);
}
