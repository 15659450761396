@import 'src/less/colors';

.genericText {
  font-family: Graphik;
  margin: 0;
  padding: 0;

  &.medium {
    font-weight: 400;
    font-size: 12px;
  }

  &.cobalt {
    color: @PrimaryCobalt;
  }

  &.cobalt60 {
    color: @PrimaryCobalt60;
  }
}
