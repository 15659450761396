@import '/src/less/colors';

.inboxContainer {
  width: 100%;
  height: 100%;
  display: flex;

  .threadsContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-right: 1px solid @SecondarySkyBlue20;
    width: 350px;
    min-width: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 2px 0px 4px 0px #1f2e770f;

    .threadsHeader {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 24px 32px;
      border-bottom: 1px solid @SecondarySkyBlue20;

      .threadsTitleContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        color: @PrimaryCobalt;

        h3 {
          margin-bottom: 0;
        }
      }

      .threadsSearch {
        width: 100%;
      }

      .sharedUsersCheckbox {
        padding: 0px;
        margin-top: 12px;
        margin-right: 12px;
      }
    }

    .thread {
      padding: 24px 32px 24px 32px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-right: 6px solid white;

      cursor: pointer;
      // &:not(:first-of-type) {
      //   border-top: 1px solid @SecondarySkyBlue20;
      // }

      //&:not(:last-of-type) {
      border-bottom: 1px solid @SecondarySkyBlue20;
      //}

      &.selected {
        border-right: 6px solid @SecondaryBlue;
      }

      .lastMessagePreviewContainer {
        display: flex;
        gap: 6px;

        .messageIcon {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          border-radius: 4px;
          text-align: center;
        }
        .messagePreview {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: @PrimaryCobalt;
          font-family: Graphik;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0px;
          text-align: left;

          .sender {
            font-family: Graphik;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0px;
            text-align: left;
            color: @PrimaryCobalt60;
          }
        }
      }

      .profileInfo {
        display: flex;
        gap: 12px;
        align-items: center;

        .avatar {
          height: 40px;
          width: 40px;
          border-radius: 40px;
        }

        .profileHeader {
          display: flex;
          flex-direction: column;
          gap: 4px;
          flex: 1;
          min-width: 0px;
          white-space: nowrap;

          .nameAndTimestamp {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profileName {
              font-family: Gilroy;
              font-size: 16px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0px;
              text-align: left;

              color: @SecondaryBlue;
              margin-bottom: 0px;
            }

            .lastMessageDate {
              //styleName: Body 12/Regular 400;
              font-family: Graphik;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: right;
              color: @PrimaryCobalt60;
            }
          }

          .headline {
            font-family: Graphik;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: left;
            color: @PrimaryCobalt;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .chatWindowContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: @SecondarySkyBlue05;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    .conversationProfileHeader {
      flex-shrink: 0;
      height: fit-content;
      background: @PrimaryCobalt;
      color: white;
      padding: 24px 32px 24px 32px;
      box-shadow: 0px 2px 4px 0px #1f2e770f;
      z-index: 1;

      .avatarAndInfos {
        display: flex;
        align-items: center;
        flex-grow: 1;
        max-width: 56.75%;
        min-width: 0px;

        img {
          height: 64px;
          width: 64px;
        }
      }

      .infos {
        margin: 0px 16px;
        min-width: 0;
        flex: 1;

        .fullname {
          display: flex;
          font-family: 'Gilroy';
          margin-right: 6px;
          line-height: 27px;
          margin-bottom: 0px;
        }

        .headline {
          //styleName: Body 12/Medium 500;
          font-family: Graphik;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          @supports (-webkit-line-clamp: 2) {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
          }
        }
      }
    }

    .conversationContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 0;
      flex-grow: 1;
      min-height: 0;

      .conversation {
        flex-grow: 1;
        width: inherit;
        background-color: @SecondarySkyBlue05;
        background-color: #f8faff;
        display: flex;
        flex-direction: column;
        padding: 24px 32px;
        grid-gap: 24px;
        gap: 24px;
        max-height: inherit;
        min-height: 0;
        overflow-y: auto;

        .messageContainer {
          width: fit-content;
          text-align: right;
          display: flex;
          flex-direction: row;
          gap: 12px;
          max-width: 60%;

          &.fromMe {
            align-self: flex-end;

            .messageData .message {
              background-color: @PrimaryColorLight;
              color: white;
              align-self: flex-end;
            }

            .messageMetadata {
              align-self: flex-end;
            }
          }

          .messageAvatar {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            border-radius: 50%;
          }

          .messageData {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;

            .message {
              white-space: pre-wrap;
              width: fit-content;
              padding: 12px;
              background-color: white;
              color: @PrimaryCobalt;
              align-self: flex-start;
              border-radius: 8px;
              box-shadow: 0px 2px 4px 0px #1f2e770f;
              text-align: left;
              min-width: fit-content;
            }

            .messageMetadata {
              display: flex;
              align-items: center;
              gap: 6px;

              .messageIcon {
                width: 14px;
                height: 14px;
                padding: 1px;
                border-radius: 2px;

                i {
                  display: block;
                  margin-top: -4px;
                }
              }

              .from,
              .time {
                font-family: Graphik;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: right;
              }

              .from {
                color: @SecondaryBlue;
              }

              .time {
                color: @PrimaryCobalt60;
              }
            }
          }
        }
      }

      .messageInputContainer {
        flex-shrink: 0;
        height: fit-content;
        width: 100%;
        background: white;
        padding: 16px 32px;
        border-top: 1px solid @SecondarySkyBlue20;
        display: flex;
        flex-direction: column;
        gap: 12px;
        box-shadow: 0px 8px 32px -2px #1f2e7726;

        .senderSelectorContainer {
          display: flex;
          align-items: center;
          gap: 16px;

          .senderToRecipient {
            font-family: Graphik;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: @PrimaryCobalt60;
            display: flex;
            gap: 4px;
            align-items: center;

            .sender,
            .recipient {
              color: @SecondaryBlue;
            }
          }
        }

        .form {
          display: flex;
          gap: 24px;
          align-items: center;
        }

        .trigger {
          width: 30%;
          min-width: 200px;

          &:not(:disabled) {
            cursor: pointer;
          }
        }

        .dropdownOption {
          display: flex;
          gap: 8px;
          align-items: center;

          .dropdownIconContainer {
            border-radius: 3px;
            padding: 1px;
            text-align: center;
            width: 18px;
            height: 18px;

            i {
              line-height: 16px;
            }
          }
        }

        .input {
          border: none;
          box-shadow: none;
          padding: 0px;

          input {
            border: none;
            padding-left: 0px;
          }
        }
      }
    }
  }
}
