/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * colors.less
 */
.new-skip-popup {
  padding: 0;
}
.new-skip-popup,
.ui.modal.new-skip-profile-modal {
  width: 720px;
}
.new-skip-popup .disqualify-popup-header,
.ui.modal.new-skip-profile-modal .disqualify-popup-header {
  display: block;
  font-family: 'Gilroy', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: #f3f4f8;
  margin: 0em;
  padding: 1.25rem 1.5rem;
  box-shadow: none;
  color: #1f2e77;
  border-bottom: 1px solid #dae0f4;
  text-align: center;
}
.new-skip-popup .disqualify-popup-header div:first-of-type,
.ui.modal.new-skip-profile-modal .disqualify-popup-header div:first-of-type {
  font-size: 22px;
  line-height: 1.28571429em;
  font-weight: 700;
}
.new-skip-popup .disqualify-popup-body,
.ui.modal.new-skip-profile-modal .disqualify-popup-body {
  padding: 24px;
}
.new-skip-popup .disqualify-popup-footer,
.ui.modal.new-skip-profile-modal .disqualify-popup-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 24px 24px 24px;
}
.new-skip-popup .disqualify-popup-footer .align-left,
.ui.modal.new-skip-profile-modal .disqualify-popup-footer .align-left {
  flex-grow: 1;
}
.new-skip-popup .checkbox-container,
.ui.modal.new-skip-profile-modal .checkbox-container {
  height: 47px;
  padding-top: 2px;
}
.new-skip-popup .checkbox-container.selected,
.ui.modal.new-skip-profile-modal .checkbox-container.selected {
  padding-top: 2px;
  padding-bottom: 3.5px;
}
.new-skip-popup .checkbox-container.left,
.ui.modal.new-skip-profile-modal .checkbox-container.left {
  padding-right: 5px;
}
.new-skip-popup .checkbox-container.right,
.ui.modal.new-skip-profile-modal .checkbox-container.right {
  padding-left: 5px;
}
.new-skip-popup .checkbox-subcontainer,
.ui.modal.new-skip-profile-modal .checkbox-subcontainer {
  border: 1px solid #dae0f4;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.new-skip-popup .checkbox-subcontainer.selected,
.ui.modal.new-skip-profile-modal .checkbox-subcontainer.selected {
  border: 2px solid #709dff;
}
.new-skip-popup .checkbox-subcontainer .ui.checkbox,
.ui.modal.new-skip-profile-modal .checkbox-subcontainer .ui.checkbox {
  width: 100%;
}
.new-skip-popup .checkbox-subcontainer .ui.checkbox label,
.ui.modal.new-skip-profile-modal .checkbox-subcontainer .ui.checkbox label {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #1d1e21;
}
.new-skip-popup .checkbox-subcontainer .ui.checkbox label::before,
.ui.modal.new-skip-profile-modal .checkbox-subcontainer .ui.checkbox label::before {
  margin-top: 10px;
}
.new-skip-popup .checkbox-subcontainer .ui.checkbox label::after,
.ui.modal.new-skip-profile-modal .checkbox-subcontainer .ui.checkbox label::after {
  margin-top: 10px;
}
.new-skip-popup .schedule-reminder .label,
.ui.modal.new-skip-profile-modal .schedule-reminder .label {
  color: red;
}
.new-skip-popup .schedule-reminder .reminder-duration-select,
.ui.modal.new-skip-profile-modal .schedule-reminder .reminder-duration-select {
  margin-left: 4px;
}
.new-skip-popup .schedule-reminder .reminder-duration-select .text,
.ui.modal.new-skip-profile-modal .schedule-reminder .reminder-duration-select .text {
  padding-right: 16px;
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;
  color: #4864c9;
}
.new-skip-popup .schedule-reminder .reminder-duration-select i.dropdown.icon,
.ui.modal.new-skip-profile-modal .schedule-reminder .reminder-duration-select i.dropdown.icon {
  opacity: 0.3;
}
.new-skip-popup .schedule-reminder .reminder-duration-select .visible.menu.transition .text,
.ui.modal.new-skip-profile-modal .schedule-reminder .reminder-duration-select .visible.menu.transition .text {
  color: black;
}
.new-skip-popup .inputs-row .input-container,
.ui.modal.new-skip-profile-modal .inputs-row .input-container {
  flex-grow: 1;
  flex-shrink: 0;
}
.new-skip-popup .inputs-row .input-container .input-label,
.ui.modal.new-skip-profile-modal .inputs-row .input-container .input-label {
  margin-bottom: 18px;
}
.new-skip-popup .input-container .input-label,
.ui.modal.new-skip-profile-modal .input-container .input-label {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.new-skip-popup .input-container .input-label .icon,
.ui.modal.new-skip-profile-modal .input-container .input-label .icon {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: #f1f6ff;
  color: #709dff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.new-skip-popup .input-container .input-label .basic-icon,
.ui.modal.new-skip-profile-modal .input-container .input-label .basic-icon {
  color: #709dff;
  font-size: 18px;
  margin-right: 8px;
}
.new-skip-popup .input-container .input-label .basic-icon + span,
.ui.modal.new-skip-profile-modal .input-container .input-label .basic-icon + span {
  padding-top: 3px;
}
