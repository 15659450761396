/*
 * colors.less
 */
.campaign-sub-header {
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 72px auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  background: white;
  border-bottom: 1px solid #d2d5e4;
}
.campaign-sub-header .form-errors {
  grid-row: 2;
  grid-column: 1 / span 2;
  font-weight: 600;
  font-family: Gilroy;
  font-size: 16px;
  color: #f66f81;
}
.campaign-sub-header .form-errors .details {
  font-size: 12px;
  text-align: left;
  line-height: 16px;
}
.campaign-sub-header .form-errors .count {
  margin-left: 32px;
  white-space: nowrap;
}
.campaign-sub-header .campaign-sub-header_breadcrumb {
  grid-row: 1;
  grid-column: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
}
.campaign-sub-header .campaign-sub-header_breadcrumb .campaign-sub-header_breadcrumb-section {
  font-family: Gilroy;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  color: #1f2e77;
  white-space: nowrap;
}
.campaign-sub-header .campaign-sub-header_breadcrumb .campaign-sub-header_breadcrumb-section.collapsible {
  text-overflow: ellipsis;
  overflow: hidden;
}
.campaign-sub-header .campaign-sub-header_breadcrumb .campaign-sub-header_breadcrumb-divider {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  color: #1f2e77;
}
.campaign-sub-header .campaign-sub-header_actions-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-sub-header .span-delimiter {
  font-size: 14px;
  color: #7982ad;
  font-weight: 600;
}
.campaign-sub-header .span-delimiter::before,
.campaign-sub-header .span-delimiter::after {
  content: '';
  height: 1px;
  width: 16px;
  background: #a5abc9;
  display: inline-block;
  position: relative;
  top: -3px;
  margin: 0 8px;
}
