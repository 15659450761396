@import 'src/less/colors';

.nudge {
  position: absolute;
  top: -1px;
  right: -1px;
  height: 8px;
  width: 8px;
}

.blinker {
  position: absolute;
  inset: -1px;
  border-radius: 100%;
  background-color: @YellowOp30;
  border: 1px solid white;
  animation-name: blink;
  animation-duration: 1600ms;
  animation-iteration-count: infinite;
}

.fill {
  position: absolute;
  inset: 0;
  border-radius: 100%;
  background-color: @Yellow;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  69% {
    transform: scale(2.5);
  }
  81% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(1);
  }
}
