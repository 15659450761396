/*
 * colors.less
 */
.task-status-button.ui.button {
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  margin: 0;
  border-radius: 4px;
}
.task-status-button.ui.button i {
  font-weight: 400;
}
.task-status-button.ui.button i.ri-check-line {
  margin-right: 6px;
}
.task-status-button.ui.button.pending {
  background-color: #f3f4f8;
  color: #767689;
}
.task-status-button.ui.button.pending i {
  margin-right: 8px;
  color: #d2d5e4;
}
.task-status-button.ui.button.in-progress {
  color: #ffbd2e;
  background-color: #eba91a1a;
}
.task-status-button.ui.button.in-progress i {
  margin-right: 8px;
}
.task-status-button.ui.button.hovered {
  background-color: #edf0fa;
  color: #4864c9;
}
.task-status-button.ui.button.completed {
  background-color: #28ca421a;
  color: #28ca42;
}
.task-status-button.ui.button.deleted {
  background-color: #ff60591a;
  color: #ff6059;
}
.task-status-button.ui.button.deleted i {
  margin-right: 8px;
}
