@import 'src/less/colors';

.superPipelineSettingsContainer {
  background: white;
  border-radius: 8px;
  padding: 32px;

  .description {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt60;
    margin-bottom: 24px;
  }

  .infoBox {
    background: @SecondarySkyBlue10;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 32px;

    .infoBoxTitle {
      color: @PrimaryCobalt;
      font-family: Graphik;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }

    .infoBoxDescription {
      font-family: Graphik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: @PrimaryCobalt60;
    }
  }

  .scopedPipelineInfoBox {
    margin-bottom: 24px;
  }

  .stepsTitle {
    font-family: Gilroy;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: @PrimaryCobalt !important;
    margin-bottom: 16px !important;
  }

  .stepsTable {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .stepContainer {
      display: flex;
      align-items: center;
      gap: 12px;

      .stepNumber {
        background: @SecondaryBlue;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        padding: 7px 0px 5px 0px;
        color: white;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
      }

      .newStepButton,
      .deleteSubstepButton {
        cursor: pointer;
      }

      .stepValue {
        flex-grow: 1;
        background-color: @SecondaryBlue05;
        padding: 8px 12px;
        border-radius: 8px;
        font-family: Graphik;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        color: @PrimaryCobalt;
      }
    }

    .stepExtension {
      margin-left: 44px;
      border: 1px solid @SecondaryBlue20;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
      padding: 16px 24px;

      .substepActions {
        margin-top: 12px;
      }

      > div:last-of-type {
        .newSubstepContainer {
          margin-bottom: 0px;
        }
        .newSubstepSpacer {
          height: 32px;
        }
      }
    }

    .substepContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 4px;

      .substepNumber {
        background: @SecondaryBlue05;
        color: @PrimaryCobalt;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        padding: 7px 0px 5px 0px;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
      }

      .newStepButton,
      .deleteSubstepButton {
        cursor: pointer;
      }

      .substep {
        flex-grow: 1;
        border: 1px solid @SecondaryBlue20;
        padding: 5px 10px;
        border-radius: 8px;
        color: @PrimaryCobalt;
      }
    }
  }

  .actionButton {
    padding: 0px 8px;
  }

  .panel {
    width: fit-content;

    .panelOption {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;

      .panelOptionIcon,
      i {
        width: 16px;
        color: @PrimaryCobalt40;
      }
    }
  }
}

.newSubstepNameInput {
  width: 100%;
  margin-bottom: 12px;
}

.scopeSelector {
  margin-top: 4px;
  border-radius: 8px !important;
  display: block;
  box-shadow: 0px 2px 4px @PrimaryCobaltOp06 !important;
  margin-bottom: 12px;
  input {
    border-radius: 8px !important;
    border: 1px solid @SecondaryBlue20 !important;
    width: 100% !important;
  }
}

.departmentsCheckboxContainer {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;

  span {
    margin-top: 2px;
  }
}

.warning {
  padding: 12px 24px;
  background-color: @SecondaryRed40;
  color: @SecondaryRed120;
  border-radius: 8px;
  font-weight: 500;
}

.newSubstepNameLabel,
.stepsLabel {
  margin-bottom: 6px;
  font-weight: 500;
}

.subStepEditionRawContainer {
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  > div {
    margin-bottom: 0px;
  }

  .subStepEditionInput {
    flex-grow: 1 !important;
  }
}

// .newSubstepContainer {
//   font-weight: 400;
//   font-size: 12px;
//   color: @SecondaryBlue;
//   border: 1px solid @SecondaryBlue;
//   border-radius: 8px;
//   padding: 4px;
//   // margin-bottom: 8px;
//   cursor: pointer;
//   background: @SecondarySkyBlue10;
// }

// .newSubstepSpacer {
//   height: 40px;
// }

.newSubstepContainer {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: @PrimaryCobalt40;
  border: 2px dashed @PrimaryCobalt20;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  margin: 0px 44px 4px 44px;
  opacity: 0;
  transition: opacity 200ms;
}

.newSubstepContainer.newSubstepVisible {
  opacity: 1;
}

.newSubstepContainer:hover {
  background: @PrimaryCobalt05;
}

.newSubstepSpacer {
  height: 40px;
}
