@import 'src/less/colors';

.cannotBeContacted {
  background: @SecondarySkyBlue05;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;

  h4 {
    font-weight: 500;
    color: @PrimaryCobalt;
    font-family: 'Graphik';
  }

  p {
    font-weight: 400;
    color: @PrimaryCobalt60;
    font-family: 'Graphik';
  }
}
