/*
 * colors.less
 */
.nameAndHeadline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
}
.nameAndHeadline .fullname {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  color: #4864c9;
}
.nameAndHeadline .fullname .fullnameText {
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  color: #4864c9;
}
.nameAndHeadline .fullname .fullnameText.clickable:hover {
  text-decoration: underline;
}
.nameAndHeadline .fullname .icon {
  vertical-align: middle;
  opacity: 0.7;
  padding-right: 3px;
}
.nameAndHeadline .fullname .icon::before {
  vertical-align: initial;
}
.nameAndHeadline .headline {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #4c5892;
}
