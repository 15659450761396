@import 'src/less/colors';

.warningBox {
  background-color: @Yellow20;
  min-height: 64px;
  border-radius: 8px;
  padding: 16px;
  color: @Yellow120;

  .title {
    color: @Yellow120;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .description {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: @Yellow120;
  }
}
