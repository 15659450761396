/*
 * colors.less
 */
.themeContainer .inputContainer:not(:last-of-type) {
  margin-bottom: 12px;
}
.themeContainer .inputContainer .inputLabel {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
.themeContainer .inputContainer .colorsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}
.themeContainer .inputContainer .colorOption {
  border: 1px solid #dae0f4;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
}
.themeContainer .inputContainer .colorOption.selectedColor {
  border: 2px solid #4864c9;
}
.themeContainer .inputContainer .colorInputContainer {
  margin-top: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.themeContainer .inputContainer .borderRadiusSelector {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
