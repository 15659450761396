@import 'src/less/colors';

.title {
  margin-top: 26px;
  margin-bottom: 16px;
  color: @PrimaryCobalt;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.attachmentType,
.attachmentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: @PrimaryCobalt80;

  :global(.edit-button) {
    background-color: transparent;
    color: #4c5892;
    padding: 0px 0px 2px 0px;

    &:hover {
      background-color: transparent;
      color: #4c5892;
    }
  }

  :global(.download-icon-container) {
    &:focus-visible {
      outline-style: auto;
      outline-width: 1px;
    }
    :global(.download-icon) {
      color: transparent;
      stroke: @PrimaryCobalt80;
      cursor: pointer;
      margin-top: -4px;

      &:hover {
        opacity: 1;
      }
    }
  }

  :global(.download-button) {
    margin-top: -4px;
  }

  & > i {
    margin-right: 10px;
    padding-bottom: 4px;
    font-weight: normal;
    font-size: 18px;
  }
  & > span {
    margin-right: 0.5em;
  }

  .attachmentDate {
    color: @PrimaryCobalt60;
    font-weight: 500;
    margin: 0px 8px 0px 4px;
  }
}

.attachmentViewer {
  margin-bottom: 24px;
}
