/*
 * colors.less
 */
.paragraph {
  margin: 16px 0;
  padding: 12px;
  border-radius: 4px;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background-color: #f1f6ff;
  color: #4864c9;
}
.label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.pill {
  padding: 4px 8px;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  border-radius: 80px;
  color: #4864c9;
  background-color: #e2ebff;
}
