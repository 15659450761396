.job-admin .segment {
  background-color: white;
  border-radius: 2px;
  border: 1px solid var(--SecondaryBlue20);
  padding: 12px;
}
.job-admin .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-admin div.job-title {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.job-admin div.job-title a {
  color: var(--PrimaryCobalt);
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.job-admin div.job-title a > .circle {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--GreenColor);
  margin-right: 0.5em;
  margin-bottom: 1.5px;
}
.job-admin div.job-title a > i {
  margin-left: 0.5em;
  margin-bottom: 5px;
}
.job-admin div.job-title .title {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.job-admin .buttons-row {
  margin-top: 12px;
  display: flex;
}
.job-admin .ui.button.comment-button {
  /* font-family: Gilroy, sans-serif; */
  font-weight: 700;
  font-size: 14px;
  color: var(--PrimaryCobalt60);
  background-color: white;
  border: solid 1px var(--PrimaryCobalt60);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
}
.job-admin .ui.button.comment-button:focus {
  border: solid 1px var(--PrimaryCobalt80);
}
.job-admin .ui.button.comment-button img {
  flex-shrink: 0;
}
/* Dropdown */
.job-admin .step-selector .ui.selection.dropdown {
  margin-left: 12px;
  border-radius: 2px;
  color: var(--SecondarySkyBlue120);
  border: 1px solid var(--SecondarySkyBlue120);
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}
.job-admin .step-selector .ui.selection.dropdown .menu {
  max-height: 250px;
}
.job-admin .step-selector .ui.selection.visible.dropdown > .text {
  color: var(--SecondarySkyBlue120);
}
.job-admin .step-selector .ui.selection.dropdown:hover,
.job-admin .step-selector .ui.selection.active.dropdown {
  border: 1px solid var(--SecondarySkyBlue120);
  border-color: var(--SecondarySkyBlue120);
}
.job-admin .step-selector .ui.selection.dropdown .menu,
.job-admin .step-selector .ui.selection.active.dropdown:hover .menu,
.job-admin .step-selector .ui.selection.dropdown .menu:hover {
  border-color: var(--SecondarySkyBlue120);
}
.job-admin .step-selector .ui.selection.dropdown .menu > .item {
  color: var(--SecondarySkyBlue120);
  font-size: 12px;
  white-space: nowrap;
}
.job-admin .step-selector .ui.selection.dropdown .menu > .item:hover {
  color: var(--SecondarySkyBlue120);
}
.job-admin .step-selector .ui.selection.dropdown .menu .active.item {
  color: var(--SecondarySkyBlue120);
  font-weight: 500;
}
.job-admin .write-comment-button {
  width: 100%;
  font-weight: 600;
}
