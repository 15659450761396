@import 'src/less/colors';

.icon {
  margin: 0 4px;
  color: @PrimaryCobalt60;
}

.content {
  h1 {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin: 0;
    margin-bottom: 8px;
  }

  h2 {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-align: left;
    color: @SecondarySkyBlue80;
    margin: 0;
  }

  p {
    font-family: Graphik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
