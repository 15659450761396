/*
 * colors.less
 */
.campaign-profiles-page {
  height: 100%;
  border-bottom: 1px solid #dae0f4;
}
.campaign-profiles-page .campaign-profiles-page__row:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.campaign-profiles-page .loading-placeholder {
  min-height: 250px;
  height: 100%;
  display: flex;
  align-items: center;
}
.campaign-profiles-page .loading-placeholder .ui.loader {
  z-index: 1;
}
.campaign-profiles-page .empty-profiles-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 120px 60px;
  font-weight: 500;
  font-size: 18px;
  color: #1f2e77;
}
.campaign-profiles-page .empty-profiles-page .buttons {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-items: flex-start;
}
.campaign-profiles-page .empty-profiles-page .buttons span {
  margin-bottom: 30px;
}
.campaign-profiles-page .empty-profiles-page .image,
.campaign-profiles-page .empty-profiles-page .image-placeholder {
  margin-top: 42px;
  width: 456px;
}
