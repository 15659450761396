/*
 * colors.less
 */
.atsLastSyncValue,
.atsLastSyncText {
  color: #7982ad;
  font-family: Graphik;
  font-size: 12px;
}
.atsLastSyncText {
  font-weight: 400;
}
.atsLastSyncText:not(.minimized) {
  margin-left: 8px;
}
.atsLastSyncValue {
  font-weight: 500;
  margin-left: 4px;
}
