/*
 * colors.less
 */
.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: right;
  gap: 5px;
}
.pill {
  display: inline-flex;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  gap: 6px;
  cursor: pointer;
  border-radius: 28px;
  border: 1px solid #dae0f4;
  color: #7982ad;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.pill:hover {
  transition: border-color ease 150ms;
  border-color: #a5abc9;
}
.activePill {
  border: 1px solid #a5abc9;
  color: #4864c9;
  font-weight: 600;
}
.dropdownPanel {
  width: fit-content;
  min-width: 272px;
}
.close {
  height: 14px;
  width: 14px;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
}
.close:hover {
  color: #4864c9;
}
.preview {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 14px;
  background-color: #e9ebf2;
  color: #a5abc9;
}
.arrow:hover {
  color: #4864c9;
}
