/*
 * colors.less
 */
.table {
  margin-bottom: 12px;
  border: 1px solid #dae0f4;
  border-radius: 3px;
}
.table .pagination {
  display: block;
  width: 100%;
  text-align: right;
  padding: 8px;
}
.campaign {
  flex: 1 0;
}
.avatar {
  flex: 0 0;
  min-width: 32px;
  margin-left: 16px;
  margin-right: 8px;
}
.nameAndHeadline {
  flex: 1 0;
}
