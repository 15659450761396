/*
 * colors.less
 */
.radio-button-selector .rds-button-selected.ui.basic.button,
.radio-button-selector .rds-button-default.ui.button {
  padding: 6px 1.5em 6px;
  border: 1px solid transparent;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
}
.radio-button-selector .rds-button-selected.ui.basic.button:focus,
.radio-button-selector .rds-button-default.ui.button:focus {
  outline: 0;
  border-color: #6d83d4;
  box-shadow: 0 0 0 1px #6d83d4 inset !important;
}
.radio-button-selector .rds-button-selected.ui.basic.button {
  box-shadow: 0px 0px 0px 1px #dae0f4 inset;
  background-color: #e2ebff !important;
  border: 1px solid #4864c9;
  color: #3450b5 !important;
}
.radio-button-selector .rds-button-default.ui.button {
  background-color: #F1F1F3;
  color: #7982ad;
}
