/*
 * colors.less
 */
.dropdownContainer {
  height: 40px;
  width: 100%;
  border: 1px solid #dae0f4;
  border-left: none;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  background: white;
}
.dropdownContainer.mini {
  height: 32px;
}
.dropdownContainer > div {
  width: 100%;
}
.dropdownContainer:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #dae0f4;
}
.dropdownContainer:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
