/*
 * colors.less
 */
.inputContainer {
  margin-bottom: 24px;
}
.inputContainer .inputLabel {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  color: #1f2e77;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.inputContainer .atsLogo {
  width: 20px;
  height: auto;
  border-radius: 4px;
}
.inputContainer .recommendation {
  padding: 4px 8px;
  background-color: #e2ebff;
  border-radius: 80px;
  font-family: 'Graphik';
  font-weight: 600;
  font-size: 12px;
  color: #4864c9;
}
.departmentsContainer {
  margin-bottom: 24px;
}
.actions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
