/*
 * colors.less
 */
.profile-timeline {
  margin-top: 36px;
  margin-left: 24px;
  margin-right: 24px;
}
.profile-timeline .empty-activity {
  padding-top: 34px;
  display: block;
  margin: auto;
}
.profile-timeline .activity {
  margin-top: 12px;
  position: relative;
  padding-left: 32px;
}
.profile-timeline .activity:not(:last-child) {
  margin-bottom: 24px;
}
.profile-timeline .activity .first-line {
  display: flex;
  justify-content: space-between;
}
.profile-timeline .activity .date {
  font-size: 12px;
  color: #a5abc9;
}
.profile-timeline .activity .label {
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.profile-timeline .activity .value {
  font-size: 12px;
  color: #7982ad;
}
.profile-timeline .activity .body {
  margin-top: 10px;
  font-size: 13px;
  white-space: pre-line;
}
.profile-timeline .activity::before {
  content: "";
  background: #e2ebff;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 24px);
  width: 1px;
}
.profile-timeline .activity:last-child::before {
  height: calc(100% + 10px);
}
.profile-timeline .activity:first-child::before {
  top: -2px;
  left: 0px;
  height: calc(100% - -2px + 24px);
}
.profile-timeline .activity:last-child::before {
  top: 0;
  left: 0px;
  height: -2px;
}
.profile-timeline .activity::after {
  font-family: 'remixicon' !important;
  color: #a5abc9;
  font-size: 16px;
  content: "\ee2b";
  position: absolute;
  background-color: white;
  padding: 0 2px;
  border-radius: 10px;
  top: -2px;
  left: -8px;
}
.profile-timeline .basic-activity::after {
  content: "\ed90";
}
.profile-timeline .email-activity::after {
  content: "\eef6";
}
.profile-timeline .note-activity::after {
  content: "\f043";
}
.profile-timeline .linkedin-note-activity::after,
.profile-timeline .linkedin-inmail-activity::after {
  content: "\eeb5";
}
.profile-timeline .scorecard-activity::after {
  content: "\eb55";
}
.profile-timeline .scorecard-activity .rating-indicator {
  font-size: 22px;
  margin-right: 12px;
}
.profile-timeline .scorecard-activity .rating-indicator.yes,
.profile-timeline .scorecard-activity .rating-indicator.strong-yes {
  color: #28ca42;
}
.profile-timeline .scorecard-activity .rating-indicator.no,
.profile-timeline .scorecard-activity .rating-indicator.definitely-not {
  color: #ff6059;
}
.profile-timeline .scorecard-activity .rating-indicator.mixed {
  color: #ffbd2e;
}
.profile-timeline .scorecard-activity .overall-recommendation {
  margin-top: 12px;
}
.profile-timeline .scorecard-activity .overall-recommendation .overall-recommendation-content {
  display: flex;
  align-items: center;
  color: #0b1a63;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 6px;
}
.profile-timeline .scorecard-activity .questions {
  margin-top: 12px;
}
.profile-timeline .scorecard-activity .questions .question {
  margin-bottom: 12px;
}
.profile-timeline .scorecard-activity .scorecard-summary {
  margin-top: 12px;
}
.profile-timeline .scorecard-activity .scorecard-summary .scorecard-attributes {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 6px;
}
.profile-timeline .scorecard-activity .scorecard-summary .scorecard-attributes .icon.question-mark-icon {
  margin-left: 0px;
}
