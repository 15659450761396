@import 'src/less/colors';

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;

  .item {
    width: 10px;
    height: 6px;
    border-radius: 4px;
    background-color: @SecondarySkyBlue40;
  }

  .active {
    width: 24px;
    background-color: @SecondarySkyBlue;
  }

}