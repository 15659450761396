@import 'src/less/colors.less';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 8px;
  flex: 1.5 0;
}

span.pill {
  // special padding to allow room for icon
  padding: 2px 8px;
  min-height: 24px;

  max-width: 200px;

  span {
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.pillLogo {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: -4px;
  margin-right: -4px;
}

.rejectedAtsPill {
  opacity: 0.4;
}

.notInAJobPill span {
  color: @SecondaryBlue60;
}
