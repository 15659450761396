/*
 * colors.less
 */
.emptyState {
  margin: auto;
  max-width: 1024px;
  padding: 64px;
  flex: 1;
  background-color: white;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
