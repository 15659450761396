/*
 * colors.less
 */
.emptyStates {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
}
.emptyStates .emptyState {
  max-width: 720px;
  flex: 1;
  background-color: white;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
