/*
 * colors.less
 */
.visual {
  position: absolute;
  inset: 0;
  background-color: #1f2e77;
  display: flex;
  align-items: center;
  justify-content: center;
}
