@import 'src/less/colors';

.archived {
  justify-content: center;
  border-left: 1px solid @SecondaryBlue20;

  .count {
    text-align: center;
  }
}
