/*
 * colors.less
 */
.emptyState {
  text-align: center;
  margin: 100px auto;
  font-size: 15px;
  font-family: Graphik;
  color: #7982ad;
}
