@import 'src/less/colors';

.infoBox {
  background-color: @SecondarySkyBlue10;
  min-height: 52px;
  border-radius: 8px;
  padding: 16px;
  color: @PrimaryCobalt;

  .title {
    color: @PrimaryCobalt;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .description {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: @PrimaryCobalt60;
  }
}
