/*
 * colors.less
 */
.timeline {
  padding-bottom: 20px;
}
.timeline .timeline-card-mail-wrapper .content {
  background: transparent;
  border: none;
}
.timeline h4.past-activities-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.timeline .select-show-external {
  background: none !important;
  color: #1f2e77;
  font-size: 14px;
  font-weight: 600;
  outline: none;
}
.timeline .empty-past-activity {
  padding: 16px;
  color: #1f2e77;
  background-color: white;
  border: 1px solid #dae0f4;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  margin-top: 14px;
}
