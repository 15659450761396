/*
 * colors.less
 */
.fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #404564;
  display: flex;
  align-items: center;
  gap: 4px;
}
.connectPageWithPreview {
  display: flex !important;
  gap: 24px;
  min-height: 576px;
}
.showPlaceholder {
  color: #a5abc9;
}
.connectPagePreviewContainer {
  flex: 1 1 0px;
  width: 0px;
}
.fieldsContainer {
  flex: 1 1 0px;
  width: 0px;
}
.fieldsContainer input,
.fieldsContainer div:global(.ui.selection.dropdown) {
  border-radius: 8px !important;
}
.fieldsContainer .customInputContainer {
  padding: 0.75em 1em;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  display: inline-flex;
  cursor: text;
  width: 100%;
}
.fieldsContainer .customInputContainer .urlPrefix {
  flex-shrink: 1;
  white-space: nowrap;
}
.fieldsContainer .customInput {
  flex-shrink: 1;
  min-width: 0px;
}
.fieldsContainer .customInput input {
  padding: 0px !important;
  border: none !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}
.fieldsSectionContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 12px 0px;
}
.fieldsSectionContainer .fieldsTagSection,
.fieldsSectionContainer .fieldsQuestionSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.fieldsSectionContainer .fieldsTagSection span,
.fieldsSectionContainer .fieldsQuestionSection span {
  color: #1f2e77;
  margin-bottom: -8px;
}
.fieldsSectionContainer .fieldsQuestionSection {
  flex-grow: 1;
}
.modalActions:global(.actions) button:first-of-type {
  padding-left: 16px !important;
}
.urlCheckLoader:global(.ui.loader) {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.urlCheckLoader:global(.ui.loader)::before {
  border-color: #b6c1e9 !important;
}
.urlCheckLoader:global(.ui.loader)::before,
.urlCheckLoader:global(.ui.loader)::after {
  width: inherit;
  height: inherit;
}
.pillMessage {
  padding: 2px 8px 0px !important;
}
.copyIcon {
  color: #7982ad;
  margin-left: 4px;
  cursor: pointer;
}
.externalLink {
  margin-left: 4px;
  margin-top: -1px;
  cursor: pointer;
}
.externalLink a {
  color: #7982ad;
}
