.root {
  padding: 12px;
  z-index: 0;
  position: relative;
}
.spaceHorizontal {
  margin: 0px 12px;
}
.activitiesContainer {
  padding: 16px 10px 25px 10px;
}
.activitiesContainer .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activitiesContainer .activityButton:global(.ui.button) {
  padding: 8px 12px;
  margin: 0px 4px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(31, 46, 119, 0.1);
  color: var(--PrimaryCobalt60);
  border-radius: 4px;
  transition: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.activitiesContainer .activityButton:global(.ui.button):hover {
  background-color: var(--SecondaryBlue05);
  box-shadow: 0px 2px 8px rgba(31, 46, 119, 0.1);
  color: var(--PrimaryCobalt60);
}
.activitiesContainer .activityButton:global(.ui.button).active {
  background-color: var(--SecondarySkyBlue20);
  color: var(--SecondaryBlue);
}
.darkOverlay {
  background-color: var(--SecondaryBlue);
  opacity: 0.6;
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: -70px;
  z-index: 10;
}
.exitActivityModal {
  position: absolute;
  top: -55px;
  left: 5px;
}
.exitActivityModal button:global(.ui.button) {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0px 0px 1px 1px !important;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalWrapper {
  position: sticky;
  z-index: 10;
}
