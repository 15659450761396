.department-selector {
  min-width: 200px;
}
.department-selector .ui.dropdown {
  white-space: nowrap;
}
.department-selector .ui.dropdown .default.text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.department-selector .ui.dropdown .item.option-department.with-sections {
  font-weight: 600;
  font-size: 14px;
}
