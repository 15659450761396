@import 'src/less/colors';

.path {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  gap: 16px;

  .index {
    width: 14px;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt60;
    text-align: center;
  }

  .icons {
    .separator {
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
      font-size: 14px;
      color: @PrimaryCobalt60;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      border-radius: 4px;
    }
  }

  .name {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @SecondaryBlue;
  }
}
