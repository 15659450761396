/*
 * colors.less
 */
.floating-label-input {
  position: relative;
}
.floating-label-input.error input.label-input,
.floating-label-input.input-error input.label-input {
  border-color: #E08383;
}
.floating-label-input .floating-style {
  font-weight: 600;
  transform: translateY(-8px);
  opacity: 1 !important;
}
.floating-label-input .floating-label {
  pointer-events: none;
  font-size: 15px;
  font-family: Gilroy, sans-serif;
  font-weight: 400;
  color: #1f2e77;
  opacity: 0.6;
  display: inline-block;
  position: absolute;
  left: 1em;
  top: 16px;
  transition: all 150ms ease-in;
}
.floating-label-input .floating-label.floating {
  font-weight: 600;
  transform: translateY(-8px);
  opacity: 1 !important;
}
.floating-label-input .label-input {
  font-family: Graphik, sans-serif;
  font-size: 14px !important;
  padding: 25px 1em 8px 1em !important;
}
.floating-label-input .label-input:-webkit-autofill ~ label.floating-label {
  font-weight: 600;
  transform: translateY(-8px);
  opacity: 1 !important;
}
.ui.form .field.field .floating-label-input input:-webkit-autofill {
  box-shadow: 0 0 0 100px ivory inset !important;
  border-color: #e5dfa1 !important;
}
