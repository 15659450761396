/*
 * colors.less
 */
.new-campaign-preview {
  display: flex;
  padding-top: 32px;
  height: 100%;
}
.new-campaign-preview > div {
  padding-bottom: 30px;
}
.new-campaign-preview .campaign-template-selector {
  padding-left: 57px;
  padding-right: 50px;
  flex-basis: 30%;
  overflow-y: scroll;
  box-shadow: inset -1px 0px 0px #dae0f4;
}
.new-campaign-preview .campaign-template-selector .campaign-template.ui.segment {
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  margin-bottom: 18px;
}
.new-campaign-preview .campaign-template-selector .campaign-template.ui.segment .campaign-template-title {
  margin-bottom: 0px;
}
.new-campaign-preview .campaign-template-selector .campaign-template-icons {
  display: flex;
}
.new-campaign-preview .campaign-template-selector .campaign-template-icons .task-icon {
  border-radius: 50%;
  margin: 0 5px;
}
.new-campaign-preview .campaign-template-selector .campaign-template-icons .icon-wrapper:not(:last-child)::after {
  content: '';
  height: 1px;
  width: 8px;
  background: #d2d5e4;
  display: inline-block;
  position: relative;
  top: -3px;
  margin: 0 1px;
}
.new-campaign-preview .campaign-template-selector .empty-campaign-template {
  display: flex;
}
.new-campaign-preview .campaign-template-selector .empty-campaign-template .icon-container {
  flex-shrink: 0;
  background: #fef4f4;
  height: 48px;
  width: 48px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-campaign-preview .campaign-template-selector .empty-campaign-template .icon-container i {
  font-size: 20px;
  color: #e45a6b;
}
.new-campaign-preview .campaign-template-selector .templates-list-section-title {
  color: #4c5892;
  font-weight: 700;
  font-size: 14px;
}
.new-campaign-preview .campaign-template-selector .campaign-template-title {
  margin-bottom: 12px;
  color: #1f2e77;
}
.new-campaign-preview .campaign-template-selector .campaign-template-description {
  color: #4c5892;
}
.new-campaign-preview .campaign-template-selector .selected-campaign-template {
  border: 1px solid #5c89eb;
}
.new-campaign-preview .campaign-template-selector .selected-campaign-template .campaign-template-title {
  color: #4864c9;
}
.new-campaign-preview .new-campaign-preview-display {
  border-left: 1px solid #f3f4f8;
  padding-left: 70px;
  padding-right: 70px;
  flex-basis: 70%;
  overflow-y: scroll;
}
.new-campaign-preview .new-campaign-preview-display .preview-display-header {
  margin-bottom: 40px;
  color: #4c5892;
}
.new-campaign-preview .new-campaign-preview-display .preview-display-header > h2 {
  color: #4864c9;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.new-campaign-preview .new-campaign-preview-display .preview-display-header p {
  color: #1f2e77;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container {
  height: 502px;
  padding: 0px;
  position: relative;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container > div:not(.loader):not(.editor-blocker) {
  height: 500px;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .editor-blocker {
  position: absolute;
  background: white;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .editor-blocker.editor-blocker-top {
  border-top: 1px solid #dae0f4;
  height: 26px;
  width: calc(100% + 4px);
  top: -2px;
  left: -2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .editor-blocker.editor-blocker-right {
  border-right: 1px solid #dae0f4;
  border-top: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
  height: calc(100% + 4px);
  width: 26px;
  top: -2px;
  right: -2px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .editor-blocker.editor-blocker-bottom {
  border-bottom: 1px solid #dae0f4;
  height: 26px;
  width: calc(100% + 3px);
  bottom: -2px;
  left: -2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .editor-blocker.editor-blocker-left {
  border-left: 1px solid #dae0f4;
  border-top: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
  height: calc(100% + 4px);
  width: 26px;
  top: -2px;
  left: -2px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .loader-container {
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container iframe {
  height: 100%;
  min-width: 0px !important;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .blockbuilder-preview {
  right: 0px !important;
}
.new-campaign-preview .new-campaign-preview-display .unlayer-editor-container .blockbuilder-preferences {
  display: none !important;
}
.new-campaign-preview .new-campaign-preview-display .create-first-step {
  text-align: center;
}
.new-campaign-preview .new-campaign-preview-display .create-first-step .create-first-step-title {
  color: #1f2e77;
}
.new-campaign-preview .new-campaign-preview-display .create-first-step .create-first-step-description {
  color: #4c5892;
}
