/*
 * colors.less
 */
.connectBox {
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
  margin-bottom: 12px;
  background: linear-gradient(0deg, #f1f6ff, #f1f6ff), linear-gradient(0deg, #e2ebff, #e2ebff);
}
.connectBox .title {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.connectBox .title .formName {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4864c9;
}
.connectBox .fieldsContainer .field {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.connectBox .fieldsContainer .field .question {
  width: 50%;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.connectBox .fieldsContainer .field .answer {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
