/*
 * colors.less
 */
.profile-resume-container {
  margin-bottom: 80px !important;
  padding: 24px;
  background-color: white;
  border: 1px solid #dae0f4;
  border-radius: 2px;
}
.profile-resume-container .remove-trigger {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.profile-resume-container .remove-trigger .remove-button {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #f66f81;
  cursor: pointer;
}
.profile-resume-container .remove-trigger .remove-button:hover {
  text-decoration: underline;
}
