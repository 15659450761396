/*
 * colors.less
 */
.navigation {
  display: flex;
  align-items: center;
  padding-right: 13px;
  gap: 24px;
}
.navigation .tab {
  padding: 0;
  padding-bottom: 10px;
  display: inline-flex;
  align-items: center;
  height: 32px;
  gap: 8px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.navigation .tab:hover {
  border-bottom-color: #7982ad;
}
.navigation .tab:hover .label {
  color: #7982ad;
}
.navigation .tab .label {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #a5abc9;
}
.navigation .tab.active {
  border-bottom-color: #4864c9;
}
.navigation .tab.active .label {
  color: #4864c9;
}
.navigation .tab .counter {
  margin: 0;
}
.navigation .separator {
  flex: 1;
}
.navigation .remainder {
  position: relative;
  padding-bottom: 12px;
}
.navigation .remainder .trigger {
  padding: 0;
  display: inline-block;
  color: #a5abc9;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.navigation .remainder .trigger:hover {
  color: #7982ad;
}
.navigation .remainder .trigger.active {
  color: #4864c9;
}
.navigation .remainder .dropdown {
  display: none;
  position: absolute;
  top: calc(100%);
  background-color: white;
  box-shadow: 0 2px 16px 0 #1f2e7726;
}
.navigation .remainder .dropdown .option {
  margin: 0;
  padding: 8px 12px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #a5abc9;
  cursor: pointer;
}
.navigation .remainder .dropdown .option:hover {
  background-color: #f8faff;
}
.navigation .remainder .dropdown .option.active {
  color: #4864c9;
}
.navigation .remainder .dropdown.open {
  display: block;
}
