/*
 * colors.less
 */
.task-row {
  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #e2ebff;
  border-left: 2px solid transparent;
}
.task-row:not(.header) {
  cursor: pointer;
}
.task-row:last-child {
  border-bottom: none;
}
.task-row.selected {
  border-left: 2px solid #4864c9;
}
.task-row:hover:not(.header) {
  background-color: rgba(72, 100, 201, 0.03);
}
.task-row.header {
  min-height: 55px;
  display: flex;
  flex-direction: row;
  cursor: unset;
  background-color: #f8faff;
  border-top: 1px solid #e8e8e8;
}
.task-row.header > .header-cell.cell {
  font-size: 12px;
  color: #7982ad;
  font-weight: 500;
}
.task-row .cell {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 10px;
  font-size: 12px;
}
.task-row .cell.checkbox {
  cursor: pointer;
  padding-left: 22.5px;
  padding-right: 11px;
}
.task-row .cell.todo-title {
  flex-grow: 1.7;
  font-weight: 500;
  color: #4c5892;
}
.task-row .cell.profile {
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-grow: 3;
  font-weight: 500;
  color: #4864c9;
}
.task-row .cell.profile img {
  flex-shrink: 0;
  width: 32px;
  margin-right: 12px;
}
.task-row .cell.owner {
  flex-grow: 1.5;
  font-weight: 400;
  color: #1f2e77;
  overflow: hidden;
}
.task-row .cell.owner span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.task-row .cell.sequence {
  flex-grow: 1.5;
  color: #4c5892;
}
.task-row .cell.status {
  flex: 0 0 200px;
}
.task-row .cell.date {
  flex-grow: 1.5;
  color: #a5abc9;
}
