/*
 * colors.less
 */
.offer {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
}
.offer:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.offer:hover .title {
  text-decoration: underline;
}
.offer .title {
  flex: 1;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: #1f2e77;
  text-align: left;
}
.offer .profiles {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding: 0 16px;
  border-radius: 8px;
  background-color: #f1f6ff;
}
.offer .profiles .count {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #4864c9;
}
.offer .profiles .avatars {
  display: flex;
  margin-left: 4px;
}
.offer .profiles .avatars .avatar {
  width: 24px;
  height: 24px;
  margin-left: -4px;
  border-radius: 24px;
  background-color: white;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #a5abc9;
}
