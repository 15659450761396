@import 'src/less/colors';

.reasonsLabel {
  margin-top: 20px;

  .mainTitle {
    font-size: 14px;
    font-weight: 700;
    color: @PrimaryCobalt80;
  }

  .subTitle {
    font-size: 12px;
    font-weight: 400;
    color: @PrimaryCobalt40;
  }
}

.reasonsButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

  .button {
    white-space: normal;
    padding: 8px !important;
    background: @PrimaryCobalt05;
    border-radius: 2px;
    color: @PrimaryCobalt80;
    font-size: 12px;
    font-weight: 400;
    font-family: Graphik, sans-serif;
    border: 1px solid @PrimaryCobalt05;

    &:hover,
    &:active,
    &:focus {
      background: @PrimaryCobalt05;
    }
  }

  .buttonSelected {
    border-color: @SecondaryBlue;
  }
}
