/*
 * colors.less
 */
.container {
  padding: 24px;
  padding-bottom: 40px;
}
.title {
  margin-top: 12px;
  margin-bottom: 16px;
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  color: #1f2e77;
}
.bullet {
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 12px 0px;
  gap: 8px;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.count {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #1f2e77;
  border-radius: 20px;
}
.main {
  background-color: #fad3d5;
}
.extended {
  background-color: #e2ebff;
}
.paragraph {
  flex: 1;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: #7982ad;
}
.paragraph strong {
  font-weight: 500;
}
.cta {
  margin: 16px 0;
  text-align: center;
  font-family: Graphik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #1f2e77;
}
.button {
  display: block;
  margin: auto;
}
