/*
 * colors.less
 */
.integration-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}
.integration-header:not(:first-child) {
  margin-top: 48px;
}
.integration-header-number {
  font-size: 14px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  color: #1f2e77;
  border: 1px solid #1f2e77;
  text-align: center;
  margin-right: 6px;
}
.integration-header-text {
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #1f2e77;
}
