/*
 * colors.less
 */
.profile-job-state {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}
.profile-job-state .state-part {
  color: #4864c9;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 2 * 10px);
}
.profile-job-state .hidden-state-part {
  color: #ffbd2e;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
}
.profile-job-state .date-part {
  color: #A0A0A0;
}
.profile-job-state .icon-container {
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-job-state .job-state-icon,
.profile-job-state i.icon.job-state-icon {
  color: #4864c9;
  height: 10px;
  width: 10px;
}
.profile-job-state .job-state-icon.handshake,
.profile-job-state i.icon.job-state-icon.handshake {
  margin-right: 2px;
}
.profile-job-state .job-state-icon.minus,
.profile-job-state i.icon.job-state-icon.minus {
  margin-left: 3px;
}
.profile-hidden-state {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.profile-hidden-state .hidden-state-part {
  color: #ffbd2e;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
}
.profile-hidden-state .date-part {
  color: #A0A0A0;
}
