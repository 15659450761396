@import 'src/less/colors';

.card {
  margin-top: 12px;
  padding: 16px;
  padding-bottom: 0;
  background-color: @SecondarySkyBlue10;
  border-radius: 4px;
  border: 1px solid @SecondarySkyBlue20;
  border-radius: 4px;
}
