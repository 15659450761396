@import 'src/less/colors';

.root {
  border: 1px solid @SecondaryBlue20;
  border-radius: 2px;
  background-color: white;
  padding: 10px 12px;
  border-radius: 8px;
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  color: @PrimaryCobalt;
  margin-bottom: 16px;
}

.seeMore {
  margin-top: 20px;
  margin-bottom: 8px;
  line-height: 13px;
  color: @PrimaryCobalt60;
  font-family: Graphik, sans-serif;
  font-size: 12px;

  text-decoration: underline;
  cursor: pointer;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
