.actions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.atsModal {
  // Specific for plugin, but won't affect desktop users
  max-width: calc(100% - 80px);
}
