@import 'src/less/colors';

.header {
  display: flex;
  align-items: center;
  color: @PrimaryCobalt60;
  height: 54px;
  padding: 18px 24px;
  background-color: @SecondarySkyBlue05;
  border-bottom: 1px solid @SecondaryBlue20;

  .cell {
    font-family: Graphik;
    font-weight: 500;
    font-size: 14px;
    color: @PrimaryCobalt60;

    &:first-child {
      flex: 1;
    }

    &:not(:first-child) {
      width: 150px;
      text-align: center;
    }
  }
}
