@import 'src/less/colors';

.emptyState {
  padding: 24px;
  text-align: center;
  border: 1px solid @SecondaryBlue20;
  color: @PrimaryCobalt60;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.separator {
  border: 1px solid @SecondaryBlue20;
  border-bottom: none;
  margin: 32px 0 0;
}
