@import 'src/less/colors.less';

.genericTabMenu {
  padding: 10px 20px 10px 20px;
  // Smulates border withotu adding size
  box-shadow: inset 0px -1px 0px @SecondaryBlue20;

  .tab {
    display: inline-block;
    padding: 6px 12px 6px 12px;
    font-weight: 500;
    color: @PrimaryCobalt60;
    cursor: pointer;
    margin: 0px 8px;

    &.activeTab {
      background-color: @SecondarySkyBlue20;
      color: @SecondaryBlue;
      border-radius: 4px;
    }
  }
}
