@import 'src/less/colors';

.job-posting-display {
  .display-header {
    display: flex;
    flex-direction: row;
  }

  .display-header-info {
    display: flex;
    flex-direction: column;
  }

  .header {
    margin-bottom: 0;
    color: @SecondarySkyBlue;
    display: inline-block;
  }

  .link-icon {
    margin-left: 10px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .header-and-status {
    display: flex;
    flex-direction: row;

    a:hover .header {
      text-decoration: underline;
    }
  }

  .hs-flex-spacer {
    flex: 1;
  }

  .publication-status {
    display: flex;
    align-items: center;
    margin-left: 16px;

    line-height: 20px;
    font-family: Graphik, sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 12px;

    &.published {
      //background-color: @PositiveColor10;
      color: @PositiveColor;
    }
    &.unpublished {
      //background-color: @PositiveColor10;
      color: @PrimaryCobalt60;
    }

    i {
      margin-right: 5px;
    }
  }
  .posting-management-info {
    margin-bottom: 36px;
  }

  .foldering-display {
    margin-top: 8px;
    margin-bottom: 16px;
    color: @PrimaryCobalt60;
  }

  .locations-display {
    font-family: Gilroy, sans-serif;
    font-weight: 700;
    margin-top: 16px;
  }

  .job-description-container {
    margin-top: 40px;
  }
}
