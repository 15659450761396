@import 'src/less/colors';

.content:global(.content) {
  max-height: 540px;
  overflow: auto;
}

.QRCodeModalHeader {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: @SecondarySkyBlue10;
  background: linear-gradient(
    180deg,
    rgba(241, 246, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  font-family: Gilroy;
  font-size: 40px;
  font-weight: 900;
  line-height: 50px;
  text-align: center;
  padding-top: 48px;
  margin-bottom: 50px;
  color: @PrimaryCobalt;
}

.QRCodeContainer {
  width: fit-content;
  border-radius: 40px;
  background: linear-gradient(180deg, #0880f5 0%, #006ae0 100%);
  padding: 24px;
  margin: auto;
  margin-bottom: 70px;

  canvas {
    border-radius: 16px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;

  span {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @PrimaryCobalt;
  }

  a {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    color: @SecondaryBlue;
    text-decoration: underline;
  }
}

.modalActions:global(.actions) {
  padding: 16px 32px !important;
  box-shadow: 0px 2px 16px 0px #1f2e7726;

  button:first-of-type {
    padding-left: 16px !important;
  }

  button:not(:first-of-type) {
    margin-left: 8px;
  }
}
