/*
 * colors.less
 */
table.table {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dae0f4;
  overflow: hidden;
  border-collapse: initial;
  border-spacing: 0;
}
table.table thead {
  background: #f8faff;
}
table.table thead tr.row {
  background: transparent;
}
table.table thead th {
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 14px;
  color: #7982ad;
  padding: 1em 0.8em;
  text-align: inherit;
}
table.table tr.row {
  background: white;
  border-bottom: 1px solid #dae0f4;
}
table.table tr.row td {
  border-top: 1px solid #dae0f4;
  padding: 0.8em;
}
.twoLineCell span {
  display: block;
}
.twoLineCell span:first-child {
  color: #1f2e77;
  font-weight: 500;
  margin-bottom: 5px;
}
.twoLineCell span:not(:first-child) {
  color: #7982ad;
  font-weight: 400;
}
