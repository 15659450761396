/*
 * colors.less
 */
.mainTitle {
  font-size: 26px;
  font-weight: 700;
  color: #1f2e77;
}
.privacySection {
  margin-bottom: 40px;
}
.privacySection .title {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  font-family: Gilroy !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #1f2e77 !important;
}
.privacySection .background {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #dae0f4;
  padding: 25px;
}
.privacySection .background .paragraph {
  font-size: 14px;
  font-weight: 400;
  color: #7982ad;
  margin-bottom: 20px;
}
.privacySection .content {
  display: grid;
  width: 100%;
  position: relative;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin: 16px 0px;
  font-family: Graphik;
  color: #7982ad;
  font-weight: 400;
  font-size: 14px;
}
.privacySection .content span {
  color: #1f2e77;
  font-weight: 500;
  margin-left: 8px;
}
.privacySection .content .field {
  margin-bottom: 4px;
}
.privacySection .content .input {
  width: 100%;
}
.privacySection .actions {
  display: flex;
  justify-content: right;
  gap: 8px;
}
