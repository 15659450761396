@import 'src/less/colors.less';

.selectDropdown {
  padding: 6px 2px 6px 8px;
  font-size: 12px;
  //margin-left: auto;
  margin-right: 8px;
  font-family: Graphik;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  border: 2px solid transparent;
  display: flex;
  gap: 8px;

  cursor: pointer;

  &:hover,
  &.triggerActive {
    border: 2px solid @SecondarySkyBlue60;
    border-radius: 8px;
    background: white;
  }
}

.dropdownOptionList {
  ul {
    text-decoration: none;
    padding: 0;
    margin: 0;
    pointer-events: all;
  }

  li {
    text-decoration: none;
    list-style: none;
    pointer-events: all;

    font-weight: 500;
    font-size: 12px;
    color: @PrimaryCobalt;

    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    &:hover {
      color: @SecondaryBlue;
    }

    &.active {
      color: @SecondaryBlue;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    i.activeOption {
      margin-left: auto;
      font-size: 16px;
    }
  }

  span {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: @PrimaryCobalt60;
    margin-bottom: 8px;
    display: inline-block;
  }
}

.group {
  border-radius: 0px;
  padding: 4px 8px 4px 16px;
  &.coloredGroup {
    background-color: @SecondarySkyBlue05;
  }
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: @PrimaryCobalt60;
  border-top: 1px solid @SecondaryBlue20;
  border-bottom: none;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  + .sequencePopupItem {
    border-top: none !important;
  }

  .optionsPanel {
    padding: 16px;
    width: max-content;
  }

  .selectAllContainer {
    background-color: @SecondarySkyBlue05;
    color: @PrimaryCobalt60;
    padding: 4px 8px 4px 16px;
    font-family: Graphik;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    display: inline-flex;
    gap: 4px;
    align-items: center;

    span {
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}

.sequenceList {
  width: 100%;
  overflow: overlay;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  .sequenceItemName {
    color: @PrimaryCobalt;
    display: flex;
    gap: 4px;
    align-items: center;

    :global(.pill-message) {
      margin: 0px;
      padding: 0 4px !important;
    }
  }

  .sequencePopupItem {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    color: @SecondaryBlue;
    border-top: 1px solid @SecondaryBlue20;
    width: 100%;
    height: 37px;
    justify-content: space-between;

    &:first-of-type {
      border-top: none;
    }

    &:hover {
      background-color: @SecondaryBlue05;
    }

    :global(.sequence-container) {
      flex-grow: 1;

      :global(.row-icon-container) {
        margin-top: 0;
      }
    }
    .sequenceTitle {
      margin-right: 8px;
      line-height: 12px;
      font-size: 12px;
      font-weight: 500;
      max-width: calc(100% - 80px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .pluginSequenceTitle {
      margin-right: 8px;
      line-height: 12px;
      font-size: 12px;
      font-weight: 500;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    // Overrides for sequence icons
    :global(.row-sequence-container) {
      display: flex;
      justify-content: flex-end;

      :global(.more-actions.hidden) {
        display: none;
      }
    }
  }
}
