/*
 * colors.less
 */
.content {
  display: block;
  text-align: center;
  font-family: Graphik;
  color: #7982ad;
  font-weight: 400;
}
.content .link {
  font-weight: 500;
  color: #4864c9;
  text-decoration: underline;
}
