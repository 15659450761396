/*
 * colors.less
 */
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
.profile-row {
  min-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-top: 1px solid #e8e8e8;
  padding: 8px 8px 8px 0;
  cursor: pointer;
}
.profile-row:hover,
.profile-row.selected {
  background-color: rgba(72, 100, 201, 0.03);
}
.profile-row .row-checkbox-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 11px;
}
.profile-row .avatar-container {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0;
  min-width: 32px;
  margin-right: 13px;
}
.profile-row .avatar-container img {
  width: 32px;
  height: 32px;
}
.profile-row .avatar-container .source-indicator {
  z-index: 1;
  position: absolute;
  top: 22px;
  left: 22px;
  background-color: #709dff;
  height: 12px;
  min-width: 12px;
  border-radius: 12px;
  border: 1px solid white;
  box-sizing: border-box;
}
.profile-row .avatar-container .source-indicator i {
  position: absolute;
  top: 1.8px;
  left: 1.4px;
}
.profile-row .avatar-container .source-indicator i.small.icon {
  font-size: 6px;
}
.profile-row .avatar-container .source-indicator span {
  position: absolute;
  font-size: 10px;
  line-height: 1;
  top: 2px;
  left: 14px;
  color: white;
}
.profile-row .avatar-container .source-indicator.hover {
  width: 85px;
}
.profile-row .avatar-container .avatar-show-interest {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #4864c9;
  color: white;
  border: 1px solid white;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-row .middle-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}
.profile-row .full-name {
  display: flex;
  align-items: center;
  width: 25%;
  color: #4864c9;
}
.profile-row .full-name .full-name-text {
  flex-grow: 1;
}
.profile-row .job-wishes {
  margin: 0 21px;
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Graphik, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #4c5892;
}
.profile-row .job-wishes i.search {
  font-size: 12px;
}
.profile-row .skills {
  margin: 0 21px;
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.profile-row .buttons-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.profile-row .buttons-container .ui.button.basic {
  background-color: white !important;
  width: 100px;
  height: 29px;
  padding: 6px 16px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}
.profile-row .buttons-container .ui.button.basic:not(:hover) {
  box-shadow: 0px 0px 0px 1px #e8e8e8 inset !important;
}
.profile-row .buttons-container .ui.button.basic i {
  margin-right: 0.5em;
}
.profile-row .add-to-sequence-modal-wrapper {
  position: relative;
}
.profile-row .add-to-sequence-modal-wrapper .skip-reason {
  display: inline;
  margin-right: 24px;
  color: #7982ad;
}
