/*
 * colors.less
 */
.panel {
  width: 272px;
  border: 2px solid #c6d8ff;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 16px 0px #1f2e7726;
}
