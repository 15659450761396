/*
 * colors.less
 */
.profile-watch-label-container {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  height: 23px;
  width: 1px;
  position: relative;
}
.profile-watch-label-container .profile-watch-label-subcontainer {
  position: absolute;
}
.profile-watch-label-container .profile-watch-label-subcontainer .watch-label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  z-index: 999999;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 23px;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 13px;
  color: white;
}
