/*
 * colors.less
 */
.root {
  padding: 12px;
}
.block {
  margin-bottom: 24px;
}
.block .reveal-pending-actions {
  margin-top: 0;
  margin-bottom: 24px;
}
.pluginLayout {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: minmax(0, 1fr);
}
.pluginLayout .pluginMain {
  grid-row: 1;
  position: relative;
}
.pluginLayout .pluginFooter {
  grid-row: 2;
}
.header {
  background-color: white;
  z-index: 1;
  position: relative;
}
.header.minimized {
  padding: 8px;
  border-bottom: 1px solid #dae0f4;
}
.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
