@import 'src/less/colors';
@import 'src/less/fonts';

.wrapper {
  font-family: Gilroy, sans-serif;
  background: #ffffff;
  border: 1px solid #dae0f4;
  min-width: 300px;
  max-width: 336px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid #4864c9;
  color: @PrimaryCobalt80;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  z-index: 15;
}

.pluginWrapper {
  min-width: unset;
  max-height: unset;
  width: 334px;
}

.absoluteLeft {
  left: 0px;
}

.absoluteRight {
  right: 40px;
}

.header {
  height: 48px;
  padding: 0 12px;
  width: 100%;
  border-bottom: 1px solid #f3f3f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  padding: 10px;
  font-family: Graphik, sans-serif;
}

.paddingY {
  padding: 10px 0px;
}

.stage {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 12px;
  &:hover {
    background: #edf0fa;
  }
}

.stageDisabled {
  cursor: auto;
  background: @PrimaryCobalt05;
  opacity: 0.7;
  &:hover {
    background: @PrimaryCobalt05;
  }
}

.moveButton {
  background-color: @SecondaryBlue !important;
  color: white !important;
  border-radius: 2px !important;
  padding: 5px 8px !important;
  font-size: 14px !important;
}

.headerStageSelected {
  cursor: pointer;

  i {
    padding-right: 10px;
  }
}

.customButton {
  background-color: @PrimaryCobalt05;
  padding: 8px;
  border-radius: 8px;
  color: @PrimaryCobalt80;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid @PrimaryCobalt05;
  font-family: Graphik, sans-serif;
  white-space: nowrap;
  cursor: pointer;
}

.buttonActive {
  border: 2px solid #4864c9;
}

.paragraph {
  display: block;
  font-weight: 600;
  color: @PrimaryCobalt80;
  font-size: 12px;
  margin-bottom: 10px;
}

.paragraphLight {
  display: block;
  color: @PrimaryCobalt80;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 400;
}

.buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    flex: 1;
  }
}

.sequence {
  padding-top: 10px;
  padding-bottom: 5px;
  color: @PrimaryCobalt80;
  font-size: 12px;
  font-weight: 400;
}

.sequenceName {
  color: @SecondaryBlue;
  font-weight: 600;
}

.blueBox {
  margin-top: 15px;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  background-color: @SecondarySkyBlue20;
  color: @PrimaryCobalt80;
  font-weight: 400;
  white-space: normal;
  span {
    display: block;
    margin-bottom: 5px;
  }
}
