/*
 * colors.less
 */
.missions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.missions .missionLink {
  width: inherit;
  font-size: 12px medium;
  font-weight: 500;
  font-family: Graphik;
  color: #4864c9;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.missions .missionLink:not(:last-child) :first-child {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.missions .missionLink:not(:last-child)::after {
  content: ',';
  margin-left: 2px;
}
.missions .missionLink:last-child .missionTitle {
  max-width: 80%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.missions .morePill {
  padding: 3px 8px;
  margin-left: 8px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  background-color: #e9ebf2;
  color: #7982ad;
}
