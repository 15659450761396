/*
 * colors.less
 */
.jobsLayoutContainer .inputContainer:not(:last-of-type) {
  margin-bottom: 12px;
}
.jobsLayoutContainer .inputContainer .inputLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
.extraLinksContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.extraLinksContainer .extraLinkContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
