.hsNotes {
  padding: 10px 12px;
  border: 1px solid var(--SecondaryBlue20);
  border-radius: 2px;
  background-color: white;
}
.hsNotes-container {
  position: relative;
  z-index: 1;
}
.hsNotes-container:before {
  border-left: 3px solid rgba(17, 46, 71, 0.1);
  content: " ";
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
.hsNotes h2 {
  margin-bottom: 12px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  color: var(--SecondaryColorDark);
}
