/*
 * colors.less
 */
.profile-tab-content {
  height: calc(100% - 52px);
  overflow-y: auto;
}
.profile-tab-content h4 {
  color: #1f2e77;
}
.candidate-view {
  z-index: 0;
}
.candidate-view hr {
  border: none;
  border-top: 1px solid #e9ebf2;
  margin-top: 32px;
}
.candidate-view .action-section-container {
  padding: 16px 20px 0px 20px;
  position: relative;
  width: 43.25%;
  height: 100%;
  overflow-y: auto;
  box-shadow: inset 1px 0px 0px #dae0f4;
}
.candidate-view .action-section-container.no-scroll {
  overflow-y: hidden;
}
.candidate-view .action-section-container .exit-action-modal {
  margin-right: auto;
  position: fixed;
  z-index: 11;
  width: calc(calc(0.4325 * calc(100% - 174px)) - 20px);
  min-width: 350px;
  right: 0;
}
.candidate-view .action-section-container .exit-action-modal button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0px 0px 1px 1px !important;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.candidate-view .action-section-container .dark-overlay {
  background-color: #1f2e77;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: -16px -20px 0px -19px;
  z-index: 10;
}
.candidate-view .action-section-container .actions-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  right: 18px;
  z-index: 12;
}
.candidate-view .action-section-container .actions-buttons-container button,
.candidate-view .action-section-container .actions-buttons-container button:focus {
  padding: 8px 12px;
  margin: 0px 4px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(31, 46, 119, 0.1);
  color: #7982ad;
  border-radius: 4px;
  transition: none;
}
.candidate-view .action-section-container .actions-buttons-container button:hover,
.candidate-view .action-section-container .actions-buttons-container button:focus:hover {
  background-color: #f6f7fc;
  box-shadow: 0px 2px 8px rgba(31, 46, 119, 0.1);
  color: #7982ad;
}
.candidate-view .action-section-container .actions-buttons-container button.active,
.candidate-view .action-section-container .actions-buttons-container button:focus.active {
  background-color: #e2ebff;
  color: #4864c9;
}
.candidate-view .action-section-container .actions-buttons-container i {
  vertical-align: -1px;
  margin-right: 8px;
}
.candidate-view .action-section-container .actions-buttons-container .more-actions {
  line-height: 31px;
}
.candidate-view .action-section-container h4 {
  color: #1f2e77;
  margin-bottom: 0px;
}
.candidate-view .action-section-container h4 .ui.label {
  margin-left: 8px;
  padding: 3px 4px 1px;
  color: #4864c9;
  background-color: #e2ebff;
}
.candidate-view .action-section-container .reveal-pending-actions {
  margin-top: 8px;
}
.candidate-view .action-section-container .reveal-pending-actions .action-item-container.ui.segment {
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.1);
  margin-bottom: 0px;
}
.candidate-view h3 {
  color: #1f2e77;
}
.candidate-view .contact-flow-preview-and-activity .sequence-preview-header {
  margin-top: 0;
}
.candidate-view .candidate-explicit-tasks {
  margin: 15px 0;
}
.candidate-view .time-mode-switch {
  margin-top: 32px;
}
.candidate-view .time-mode-switch button.ui.button {
  color: #a5abc9;
  background-color: transparent;
  padding: 8px 0px;
  margin-right: 12px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid transparent;
  border-radius: 0px;
}
.candidate-view .time-mode-switch button.ui.button:hover {
  color: #4c5892;
  border-bottom: 1px solid #4c5892;
}
.candidate-view .time-mode-switch button.ui.button.active,
.candidate-view .time-mode-switch button.ui.button.active:hover {
  color: #4864c9;
  border-bottom: 1px solid #4864c9;
}
.candidate-view .buttons-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #dae0f4;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 25px;
}
.candidate-view .buttons-container .buttons {
  display: flex;
  margin-top: 10px;
  position: relative;
  gap: 8px;
}
.candidate-view .buttons-container .button {
  justify-content: center;
  flex: 1;
}
.candidate-view .buttons-container .skip-btn {
  border: 1px solid #4864c9;
  border-radius: 2px;
  color: #4864c9;
  font-weight: 700;
  font-size: 18px;
  background: white;
}
.candidate-view .buttons-container .trigger-btn {
  background: #4864c9;
  border-radius: 2px;
  color: white;
  font-weight: 700;
  font-size: 18px;
}
.candidate-view .buttons-container .label {
  margin-bottom: 2px;
  color: #1f2e77;
  font-size: 14px;
  font-weight: 500;
}
.candidate-view .buttons-container .count {
  margin-bottom: 16px;
  color: #1f2e77;
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 700;
}
#reveal-profile-view {
  overflow-y: hidden;
}
#reveal-profile-view .background-section-title {
  color: #1f2e77;
  text-align: left;
  margin-left: 0;
}
.ats-details {
  margin-top: 36px;
  margin-left: 24px;
  margin-right: 24px;
}
.ats-details .empty-details {
  padding-top: 34px;
  display: block;
  margin: auto;
  width: 80%;
}
.ats-details h3 {
  color: #1f2e77;
}
.ats-details .label {
  font-size: 12px;
  color: #7982ad;
  margin-right: 0.5em;
}
.ats-details .value {
  font-size: 12px;
  font-weight: 500;
  color: #1f2e77;
}
.ats-details .application:not(:last-child) {
  margin-bottom: 14px;
}
.ats-details .application .application-title {
  font-family: Gilroy;
  font-weight: 700;
  font-size: 16px;
  color: #1f2e77;
  margin-bottom: 2px;
}
.ats-details .application .application-info-item {
  margin-bottom: 2px;
}
.ats-details .application .application-date {
  font-size: 12px;
  font-weight: 500;
  color: #1f2e77;
}
.ats-details .application .rejected {
  color: #ff6059;
}
.ats-details .application .hired {
  color: #28ca42;
}
.ats-details .application .active {
  color: #709dff;
}
.ats-details .additional-details {
  margin-bottom: 48px;
}
.ats-details .additional-details h3 {
  display: flex;
}
.ats-details .additional-details h3 a {
  margin-left: 0.5em;
  font-size: 14px;
}
.ats-details .additional-details .additional-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}
.sequence-preview-popup-content {
  width: 400px;
}
