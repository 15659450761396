/*
 * colors.less
 */
.omega-criteria-filters .filter-icon {
  margin-right: 8px;
}
.omega-criteria-filters .filter-icon img {
  border-radius: 4px;
}
.omega-criteria-filters .ui.dropdown .ui.label {
  height: 24px;
  border-radius: 3px;
  padding: 4px 8px;
  background: #e2ebff;
  color: #4864c9;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.omega-criteria-filters .ui.dropdown.visible {
  border-color: #c6d8ff !important;
}
.omega-criteria-filters .ui.dropdown .menu {
  min-width: calc(100% + 4px);
  left: -1px;
}
.omega-criteria-filters .ui.dropdown .menu .item {
  font-weight: 400;
}
.omega-criteria-filters .label {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  margin: 16px 0 4px 0;
}
.omega-criteria-filters .sub-title {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1f2e77;
  margin: 4px 0 8px 0;
}
.omega-criteria-filters .show-advanced-filters {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4864c9;
  cursor: pointer;
}
.omega-criteria-filters .show-advanced-filters .ui.label {
  padding: 0em 4px 0em 4px;
  margin: 0px 0px 0px 4px;
  vertical-align: middle;
  color: #4864c9;
  background-color: #e2ebff;
  font-size: 12px;
}
.omega-criteria-filters .show-advanced-filters i {
  vertical-align: -2px;
}
