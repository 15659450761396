/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.jobs-selector.ui.segment {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  border-top: none;
  margin: 0;
  padding: 18px 60px;
  align-content: space-between;
}
.jobs-selector.ui.segment .title {
  color: #4864c9;
  margin-bottom: 0px;
  flex-grow: 1;
  align-self: center;
}
.jobs-selector.ui.segment .sub-title {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #7982ad;
}
.jobs-selector.ui.segment .jobs-list {
  flex-shrink: 0;
  display: flex;
  width: 30%;
  align-items: baseline;
}
.jobs-selector.ui.segment .jobs-list .dropdown {
  flex-shrink: 0;
  width: auto;
  margin-left: 8px;
  flex-grow: 1;
}
.jobs-selector.ui.segment .jobs-list .dropdown .menu .item {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.jobs-selector.ui.segment .jobs-list .dropdown .text {
  color: #1f2e77;
}
.jobs-selector.ui.segment .jobs-list .dropdown:last-of-type .item:last-of-type {
  display: flex;
  flex-direction: column-reverse;
}
.jobs-selector.ui.segment .jobs-list .dropdown:last-of-type .item:last-of-type i {
  margin-left: 4px;
  color: #7982ad;
}
.jobs-selector.ui.segment .jobs-list .dropdown:last-of-type .item:last-of-type .description {
  margin-left: 0;
  margin-top: 4px;
  color: #7982ad;
}
.jobs-selector.ui.segment .jobs-list .checkbox {
  margin-left: 17px;
}
.jobs-selector.ui.segment .jobs-list .checkbox label {
  font-weight: normal;
  color: #1f2e77;
}
.jobs-selector.ui.segment .jobs-list .menu {
  border-top-width: 1px !important;
}
.large-button-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #e9ebf2;
  margin: 6px;
}
.large-button-toggle .text {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #1f2e77;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.large-button-toggle .label {
  margin-left: 4px;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  color: #5c89eb;
  background-color: #f1f6ff;
  height: 20px;
  border-radius: 10px;
  padding: 0 10px;
  display: flex;
  align-items: baseline;
}
.large-button-toggle.active {
  background-color: #4864c9;
  border: 1px solid #4864c9;
}
.large-button-toggle.active .text {
  color: white;
}
