/*
 * colors.less
 */
.activity-timeline .activity-timeline-item {
  display: flex;
  flex-direction: row;
}
.activity-timeline .activity-timeline-item .activity-icon-container {
  position: relative;
}
.activity-timeline .activity-timeline-item:not(:last-child) {
  margin-bottom: 24px;
}
.activity-timeline .activity-timeline-item:not(:last-child) .activity-icon-container:after {
  border-left: 2px solid #e9ebf2;
  content: '';
  position: absolute;
  top: 24px;
  left: 24px / 2;
  bottom: -24px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.activity-timeline .activity-timeline-item .activity-icon {
  margin-right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #4864c9;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity-timeline .activity-timeline-item .activity-icon i.ri-send-plane-fill,
.activity-timeline .activity-timeline-item .activity-icon i.ri-send-plane-line {
  position: relative;
  top: 1px;
  right: 1px;
}
.activity-timeline .activity-timeline-item .activity-content {
  display: flex;
  flex-direction: column;
}
.activity-timeline .activity-timeline-item .profile-avatar-and-name {
  font-weight: 500;
}
.activity-timeline .activity-timeline-item .profile-avatar-and-name a {
  color: #6d83d4;
}
.activity-timeline .activity-timeline-item .activity-details {
  font-weight: 500;
}
.activity-timeline .activity-timeline-item .activity-details a {
  color: #4c5892;
}
.activity-timeline .activity-timeline-item .activity-date {
  color: #a5abc9;
}
.activity-timeline .activity-timeline-item .see-more-toggle {
  color: #7982ad;
  margin-top: 2px;
  cursor: pointer;
}
.activity-timeline .activity-timeline-item .see-more-toggle:hover {
  text-decoration: underline;
}
