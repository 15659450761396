@import 'src/less/colors';

.card {
  background: #ffffff;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  margin: 16px 0;
}

.title {
  color: @PrimaryCobalt;
}

.filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}
