@import 'src/less/colors';

.action {
  overflow-x: hidden;

  .missionName {
    font-size: 12px;
    font-family: Graphik;
    font-weight: 500;
    color: @SecondaryBlue;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .description {
    font-size: 10px;
    font-family: Graphik;
    font-weight: 400;
    color: @PrimaryCobalt80;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
