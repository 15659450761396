/*
 * colors.less
 */
.label {
  margin: 0;
  padding: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  cursor: pointer;
}
.label:hover {
  text-decoration: underline;
}
