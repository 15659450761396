/*
 * colors.less
 */
.jobDescriptionContainer {
  margin-top: 40px;
}
.inputContainer:not(:first-of-type) {
  margin-top: 12px;
}
.inputInlineLabel {
  margin-left: 8px;
  display: inline-block;
}
.removeJobPostingIcon {
  margin-left: 8px;
  color: #7982ad;
  cursor: pointer;
}
.inputLabel {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
.simpleSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.sectionContainer {
  margin-bottom: 24px;
}
