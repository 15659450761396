@import 'src/less/colors';

.panel {
  padding: 12px;
  background-color: #f8faff;
  .table {
    border: 1px solid @SecondaryBlue20;
    border-radius: 8px;
    border-collapse: separate !important;
    margin-bottom: 12px;
    background-color: white;
    color: @PrimaryCobalt;

    .hourRow {
      .hourRange,
      .dayCell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
      }

      .hourRange {
        border-right: 1px solid @SecondaryBlue10;
      }

      .dayCell {
        text-align: center;
        justify-content: center;
      }

      &:first-of-type {
        td  {
          &:first-of-type {
            border-right: 1px solid @SecondaryBlue10;
          }
        }
      }

      td {
        padding: 4px 8px;
        white-space: nowrap;
        border-bottom: 1px solid @SecondaryBlue10;

        input {
          cursor: pointer;
        }
      }
    }
  }
}
