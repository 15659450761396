/*
 * colors.less
 */
.inboxContainer {
  width: 100%;
  height: 100%;
  display: flex;
}
.inboxContainer .threadsContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid #e2ebff;
  width: 350px;
  min-width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 0px 4px 0px #1f2e770f;
}
.inboxContainer .threadsContainer .threadsHeader {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 32px;
  border-bottom: 1px solid #e2ebff;
}
.inboxContainer .threadsContainer .threadsHeader .threadsTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: #1f2e77;
}
.inboxContainer .threadsContainer .threadsHeader .threadsTitleContainer h3 {
  margin-bottom: 0;
}
.inboxContainer .threadsContainer .threadsHeader .threadsSearch {
  width: 100%;
}
.inboxContainer .threadsContainer .threadsHeader .sharedUsersCheckbox {
  padding: 0px;
  margin-top: 12px;
  margin-right: 12px;
}
.inboxContainer .threadsContainer .thread {
  padding: 24px 32px 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-right: 6px solid white;
  cursor: pointer;
  border-bottom: 1px solid #e2ebff;
}
.inboxContainer .threadsContainer .thread.selected {
  border-right: 6px solid #4864c9;
}
.inboxContainer .threadsContainer .thread .lastMessagePreviewContainer {
  display: flex;
  gap: 6px;
}
.inboxContainer .threadsContainer .thread .lastMessagePreviewContainer .messageIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 4px;
  text-align: center;
}
.inboxContainer .threadsContainer .thread .lastMessagePreviewContainer .messagePreview {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}
.inboxContainer .threadsContainer .thread .lastMessagePreviewContainer .messagePreview .sender {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.inboxContainer .threadsContainer .thread .profileInfo {
  display: flex;
  gap: 12px;
  align-items: center;
}
.inboxContainer .threadsContainer .thread .profileInfo .avatar {
  height: 40px;
  width: 40px;
  border-radius: 40px;
}
.inboxContainer .threadsContainer .thread .profileInfo .profileHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 0px;
  white-space: nowrap;
}
.inboxContainer .threadsContainer .thread .profileInfo .profileHeader .nameAndTimestamp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inboxContainer .threadsContainer .thread .profileInfo .profileHeader .nameAndTimestamp .profileName {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4864c9;
  margin-bottom: 0px;
}
.inboxContainer .threadsContainer .thread .profileInfo .profileHeader .nameAndTimestamp .lastMessageDate {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
  color: #7982ad;
}
.inboxContainer .threadsContainer .thread .profileInfo .profileHeader .headline {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inboxContainer .chatWindowContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f8faff;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
}
.inboxContainer .chatWindowContainer .conversationProfileHeader {
  flex-shrink: 0;
  height: fit-content;
  background: #1f2e77;
  color: white;
  padding: 24px 32px 24px 32px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  z-index: 1;
}
.inboxContainer .chatWindowContainer .conversationProfileHeader .avatarAndInfos {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 56.75%;
  min-width: 0px;
}
.inboxContainer .chatWindowContainer .conversationProfileHeader .avatarAndInfos img {
  height: 64px;
  width: 64px;
}
.inboxContainer .chatWindowContainer .conversationProfileHeader .infos {
  margin: 0px 16px;
  min-width: 0;
  flex: 1;
}
.inboxContainer .chatWindowContainer .conversationProfileHeader .infos .fullname {
  display: flex;
  font-family: 'Gilroy';
  margin-right: 6px;
  line-height: 27px;
  margin-bottom: 0px;
}
.inboxContainer .chatWindowContainer .conversationProfileHeader .infos .headline {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
@supports (-webkit-line-clamp: 2) {
  .inboxContainer .chatWindowContainer .conversationProfileHeader .infos .headline {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
  }
}
.inboxContainer .chatWindowContainer .conversationContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  flex-grow: 1;
  min-height: 0;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation {
  flex-grow: 1;
  width: inherit;
  background-color: #f8faff;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  grid-gap: 24px;
  gap: 24px;
  max-height: inherit;
  min-height: 0;
  overflow-y: auto;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer {
  width: fit-content;
  text-align: right;
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-width: 60%;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer.fromMe {
  align-self: flex-end;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer.fromMe .messageData .message {
  background-color: #4864c9;
  color: white;
  align-self: flex-end;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer.fromMe .messageMetadata {
  align-self: flex-end;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageAvatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .message {
  white-space: pre-wrap;
  width: fit-content;
  padding: 12px;
  background-color: white;
  color: #1f2e77;
  align-self: flex-start;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
  text-align: left;
  min-width: fit-content;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata {
  display: flex;
  align-items: center;
  gap: 6px;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata .messageIcon {
  width: 14px;
  height: 14px;
  padding: 1px;
  border-radius: 2px;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata .messageIcon i {
  display: block;
  margin-top: -4px;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata .from,
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata .time {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata .from {
  color: #4864c9;
}
.inboxContainer .chatWindowContainer .conversationContainer .conversation .messageContainer .messageData .messageMetadata .time {
  color: #7982ad;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer {
  flex-shrink: 0;
  height: fit-content;
  width: 100%;
  background: white;
  padding: 16px 32px;
  border-top: 1px solid #e2ebff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0px 8px 32px -2px #1f2e7726;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .senderSelectorContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .senderSelectorContainer .senderToRecipient {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  display: flex;
  gap: 4px;
  align-items: center;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .senderSelectorContainer .senderToRecipient .sender,
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .senderSelectorContainer .senderToRecipient .recipient {
  color: #4864c9;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .form {
  display: flex;
  gap: 24px;
  align-items: center;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .trigger {
  width: 30%;
  min-width: 200px;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .trigger:not(:disabled) {
  cursor: pointer;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .dropdownOption {
  display: flex;
  gap: 8px;
  align-items: center;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .dropdownOption .dropdownIconContainer {
  border-radius: 3px;
  padding: 1px;
  text-align: center;
  width: 18px;
  height: 18px;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .dropdownOption .dropdownIconContainer i {
  line-height: 16px;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .input {
  border: none;
  box-shadow: none;
  padding: 0px;
}
.inboxContainer .chatWindowContainer .conversationContainer .messageInputContainer .input input {
  border: none;
  padding-left: 0px;
}
