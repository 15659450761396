/*
 * colors.less
 */
.container {
  display: block;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}
.container .button {
  font-family: Graphik;
  font-size: 12px;
  text-decoration: underline;
  color: #7982ad;
  cursor: pointer;
}
.container .button:hover {
  color: #1f2e77;
}
