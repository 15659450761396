@import 'src/less/colors';
@import 'src/less/mixins';
@import 'src/utils';

.settingsContainer {
  display: flex;
  width: 100%;

  .connectContainer {
    display: flex;
    gap: 8px;

    input {
      border-radius: 8px;
    }
  }

  .linkedAccountsSection {
    flex-grow: 1;

    .loaderContainer {
      height: 100%;
      display: flex;

      align-items: center;
      justify-content: center;
    }

    .settingsHeader {
      margin-bottom: 15px;
      h1 {
        font-weight: 700;
        font-size: 26px;
        color: @PrimaryCobalt;
        flex-grow: 1;
      }
    }

    h2 {
      font-family: Gilroy;
      font-weight: 700;
      font-size: 18px;
      color: @PrimaryCobalt;
      margin-bottom: 8px;
    }

    h3 {
      margin-top: 20px !important;
      margin-bottom: 5px !important;
      font-size: 13px;
      color: @Black;
      font-weight: 400;
    }

    .separator {
      border-bottom: 1px solid #e5e5e5;
      height: 0px;
      width: 100%;
      background: #e5e5e5;
    }

    .section {
      margin-bottom: 30px;

      &.visible {
        background-color: white;
        border: 1px solid @SecondaryBlue20;
        border-radius: 2px;
        padding: 24px 24px 0px 24px;
      }

      .segment {
        background-color: @SecondarySkyBlue10;
        color: @PrimaryCobalt60;
        font-weight: 500;
        padding: 5px 8px;
        border-radius: 5px;
        margin-bottom: 12px;
      }

      .description {
        color: @PrimaryCobalt60;
      }

      .settingsPlaceholder {
        text-align: center;
        margin-bottom: 24px;
      }

      .inputContainer {
        margin-bottom: 24px;

        .inputLabel {
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          margin-bottom: 8px;
          color: @PrimaryCobalt80;

          .noEmphasis {
            font-size: 12px;
            font-weight: 400;
            font-style: italic;
          }
        }

        .previewLabel {
          color: @PrimaryCobalt60;
          font-size: 12px;
        }

        .inputDescription {
          font-size: 12px;
          color: @PrimaryCobalt40;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .input-emphasis {
          //font-weight: 500;
          font-size: 12px;
          //font-style: italic;
          line-height: 15px;
          margin-bottom: 8px;
          color: @PrimaryCobalt60;
        }

        .input-error {
          font-size: 12px;
          color: @NegativeColor;

          input {
            border-color: @NegativeColor;
          }
        }

        .input-element {
          &.half-width {
            width: 50%;
          }

          .field.signature-field {
            .content-edition-field {
              border: 1px solid @SecondaryBlue20;
              padding: 0.39285714em 1em;
              border-radius: 2px;
              font-size: 1em;
              box-shadow: none;
              outline: none;
              min-height: 80px;
              overflow: auto;

              &:focus {
                border-color: @SecondaryBlue;
              }
            }
          }
        }

        .accordion {
          .content {
            padding-bottom: 0px;
          }

          .title:not(.active) {
            padding-bottom: 0px;
          }
        }
      }

      .inputs-row {
        display: flex;
        flex-direction: row;

        &.align-right {
          justify-content: flex-end;

          .input-container {
            &.half-width:first-child:last-child {
              padding-right: 0px;
              padding-left: 12px;
            }
          }
        }

        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0px;
        }

        .input-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 0;

          &.half-width {
            width: 50%;
          }

          padding-right: 12px;
          padding-left: 12px;

          &:first-child {
            padding-left: 0px;
          }

          &:last-child {
            padding-right: 0px;
          }

          &.half-width:first-child:last-child {
            padding-right: 12px;
          }
        }
      }
    }

    .settings-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.5em;
      gap: 8px;

      .input {
        flex-grow: 1;
      }

      .ui.button.primary {
        margin-left: 10px;
      }
    }

    .settings-header {
      h1 {
        display: inline;
      }
      .pill-message {
        margin-left: 10px;
        vertical-align: top;
      }

      .settings-submit {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-end;
        overflow-y: visible;

        button.save-modifications {
          .Button(#ffffff; @PrimaryColorLight);
          margin-bottom: 8px;
          line-height: 20px;
          padding: 5px 15px;
          border-radius: 4px;
          transition: 150ms;
          font-size: 14px;

          &.disabled {
            cursor: default;
            background: #dfdfe6;
          }

          &:active {
            background: @PrimaryColor;
          }
        }

        .settings-submit-message {
          text-align: right;

          .just-saved {
            color: @PrimaryColorLight;
            opacity: 0.8;
          }

          .error-message {
            font-size: 13px;
            color: @SecondaryColor;
          }
        }
      }
    }
  }

  .messaging-modal-opener {
    color: @PrimaryColorLight;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}
