/*
 * colors.less
 */
.sendMailMockup {
  position: absolute;
  bottom: 0;
  left: 9%;
  width: 82%;
  box-shadow: 0 8px 32px -2px #1f2e7726;
}
