@import 'src/less/colors';

.container {
  display: block;
  margin-top: 8px;
}

.pill {
  display: inline-flex;
  height: 28px;
  align-items: center;
  padding: 0 12px;
  gap: 6px;

  cursor: pointer;

  border-radius: 28px;
  border: 1px solid @SecondaryBlue20;

  color: @PrimaryCobalt60;
  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  &:hover {
    transition: border-color ease 150ms;
    border-color: @PrimaryCobalt40;
  }
}

.dropdownPanel {
  width: fit-content;
  min-width: 272px;
  padding: 12px;
}

.distanceTitle {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: @PrimaryCobalt;
}

.close {
  height: 14px;
  width: 14px;
  font-size: 14px;
  padding: 0;

  cursor: pointer;

  &:hover {
    color: @SecondaryBlue;
  }
}

.distance {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 14px;
  background-color: @PrimaryCobalt10;
  color: @PrimaryCobalt40;
}

.arrow {
  &:hover {
    color: @SecondaryBlue;
  }
}
