/*
 * colors.less
 */
.counter {
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  margin: 0 8px;
  padding: 0 4px;
  font-size: 12px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  line-height: 16px;
  background-color: #edf0fa;
  color: #a5abc9;
}
.counter.active {
  background-color: #e2ebff;
  color: #4864c9;
}
