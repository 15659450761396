.loading-component {
  background: linear-gradient(90deg, #ececec, #cccaca, #ececec);
  background-size: 200% 100%;
  border-radius: 2px;
  animation: Gradient 5s linear infinite;
  display: block;
  height: 0.7rem;
  margin: 0.5rem 0;
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
