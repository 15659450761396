/*
 * colors.less
 */
.modalDescription {
  font-family: Graphik;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1em;
  letter-spacing: 0px;
  text-align: center;
  color: #1f2e77;
}
.choicesContainer {
  display: flex;
  gap: 8px;
}
.choicesContainer .choiceCard {
  flex: 1 1;
  text-align: center;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #dae0f4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}
.choicesContainer .choiceCard img {
  width: 100%;
}
.choicesContainer .choiceCard .choiceTitle {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #1f2e77;
  margin: 8px 0px;
}
.choicesContainer .choiceCard .choiceDescription {
  flex: 1 1;
  color: #7982ad;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.choicesContainer .choiceCard .choiceCardAction {
  position: relative;
  width: 100;
}
.choicesContainer .choiceCard .choiceCardAction .choiceButton {
  width: 100%;
  padding: 10px 0px;
  height: auto;
  line-height: 20px;
  transition: background 250ms;
  position: relative;
  z-index: 12;
}
.choicesContainer .choiceCard .choiceCardAction .choiceButton.AIGeneration {
  background: transparent;
}
.choicesContainer .choiceCard .choiceCardAction .gradientButtonBackground {
  background: linear-gradient(90deg, #4568dc 0%, #b06ab3 100%);
  width: 100%;
  padding: 10px 0px;
  height: auto;
  line-height: 20px;
  border-radius: 8px;
  color: transparent;
  position: absolute;
  z-index: 0;
  top: 0;
}
.choicesContainer .choiceCard:hover .choiceButton.AIGeneration {
  background: transparent;
}
