.matching-details {
  margin: -2px;
  min-width: 100%;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.matching-details .main-score {
  margin: 2px 12px 2px 4px;
  font-size: 14px;
}
.matching-details .main-score i {
  font-size: 12px;
  line-height: 13px;
}
.matching-details .matching-labels-container {
  padding-right: 40px;
}
