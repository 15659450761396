/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.ui.modal.new-candidate-modal .contact-type {
  text-align: center;
  margin-bottom: 16px;
}
.ui.modal.new-candidate-modal .human-contact-subtype {
  margin-bottom: 24px;
}
.ui.modal.new-candidate-modal input,
.ui.modal.new-candidate-modal .ui.dropdown {
  border-radius: 8px;
}
.ui.modal.new-candidate-modal .dropzone {
  padding: 1em;
}
.ui.modal.new-candidate-modal .dropzone p {
  margin-bottom: 0.5em;
}
.ui.modal.new-candidate-modal .ui.loader:after {
  border-color: #767676 transparent transparent;
}
.ui.modal.new-candidate-modal .ui.segment {
  padding: 0px;
  border: none;
}
.ui.modal.new-candidate-modal .ui.accordion .content {
  padding: 0;
  margin-top: 24px;
}
.ui.modal.new-candidate-modal .ui.accordion .content:after {
  display: none;
}
.ui.modal.new-candidate-modal .actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ui.modal.new-candidate-modal .job-dropdown {
  margin-bottom: 24px;
}
.ui.modal.new-candidate-modal .job-dropdown > span {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #4c5892;
}
