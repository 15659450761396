/*
 * colors.less
 */
.search-view {
  display: flex;
  flex: 1;
  margin: 24px 0;
  padding: 0 40px;
  gap: 24px;
}
.search-view .filters-header {
  margin-top: 0px;
}
.search-view .search-view-search {
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #1f2e77;
}
.search-view .search-view-title {
  color: #1f2e77;
  margin-bottom: 8px;
}
.search-view .contact-filter-by-text {
  display: flex;
  width: 100%;
}
