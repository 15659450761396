@import 'src/less/colors.less';

.linkedProfileSwitchButton {
  background-color: @SecondarySkyBlue20 !important;
  border-radius: 15px;
  padding: 3px;
  padding-left: 14px;
  padding-right: 14px;
  span { 
    color: @PrimaryColorLight;
    font-family: 'Graphik', 'Gilroy';
    font-size: 13px;
    font-weight: 500;
  }
  svg { 
    color: @PrimaryColorLight;
  }
}