/*
 * colors.less
 */
.graph-wrapper .header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #dae0f4;
}
.graph-wrapper .header .controls {
  display: flex;
  height: 40px;
}
.graph-wrapper .header .controls .dropdown {
  margin-right: 10px;
}
.graph-wrapper .header .controls .dropdown .item {
  display: flex;
  align-items: flex-end;
}
.graph-wrapper .header .controls .dropdown .dropdown-item {
  display: flex;
  align-items: flex-end;
}
.graph-wrapper .header .controls .dropdown .dropdown-item i {
  color: #a5abc9;
  margin-right: 10px;
  width: 16px;
  font-size: 16px;
  line-height: 16px;
}
.graph-wrapper .header .controls .buttons {
  display: flex;
}
.graph-wrapper .header .controls .buttons .type {
  font-family: Graphik, sans-serif;
  cursor: pointer;
  color: #0b1a63;
  padding: 10px 16px;
  background-color: white;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  display: flex;
  align-items: center;
  border: 1px solid #dae0f4;
  margin-right: 0px;
  border-radius: 0px;
}
.graph-wrapper .header .controls .buttons .type i {
  font-size: 14px;
  margin-right: 8px;
  color: #7982ad;
}
.graph-wrapper .header .controls .buttons .type.isActive {
  background-color: #edf0fa;
  color: #1f2e77;
}
.graph-wrapper .header .controls .buttons .type:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: none;
}
.graph-wrapper .header .controls .buttons .type:nth-last-child(1) {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.graph-wrapper .content {
  padding: 24px 32px;
}
.graph-wrapper .empty-state {
  text-align: center;
  margin: 100px auto;
  font-size: 15px;
  opacity: 0.3;
}
