/*
 * colors.less
 */
.panel {
  padding: 12px;
  background-color: #f8faff;
}
.panel .table {
  border: 1px solid #dae0f4;
  border-radius: 8px;
  border-collapse: separate !important;
  margin-bottom: 12px;
  background-color: white;
  color: #1f2e77;
}
.panel .table .hourRow .hourRange,
.panel .table .hourRow .dayCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.panel .table .hourRow .hourRange {
  border-right: 1px solid #edf0fa;
}
.panel .table .hourRow .dayCell {
  text-align: center;
  justify-content: center;
}
.panel .table .hourRow:first-of-type td:first-of-type {
  border-right: 1px solid #edf0fa;
}
.panel .table .hourRow td {
  padding: 4px 8px;
  white-space: nowrap;
  border-bottom: 1px solid #edf0fa;
}
.panel .table .hourRow td input {
  cursor: pointer;
}
