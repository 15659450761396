/*
 * colors.less
 */
.open-candidates.ui.dropdown {
  background-color: white;
  border-radius: 2px;
  height: 32px;
  padding: 4px 15px;
  border: 1px solid #4864c9;
  color: #4864c9 !important;
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
.open-candidates.ui.dropdown .divider {
  color: #4864c9 !important;
}
.open-candidates.ui.dropdown i.icon {
  margin: 0;
  height: 30px;
  padding-left: 10px;
}
