@import 'src/less/colors';

.customTaskPreviewContainer {
  padding: 24px;
  background-color: @SecondaryBlue05;
  min-height: 100%;

  .previewTitle {
    text-align: center;
    color: @PrimaryCobalt;
    font-family: Graphik;
  }

  .customTaskPreview {
    padding: 12px;
    border-radius: 8px;
    border: none !important;
    background-color: white;

    .customTaskPreviewHeader {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;

      .taskIcon {
        width: 16px;
        height: 16px;
      }

      h5 {
        margin-top: 3px;
      }
    }

    .fakeCompletionButton {
      background-color: @PositiveColor !important;
      width: 100%;
    }
  }
}
