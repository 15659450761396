/*
 * colors.less
 */
.dropdownContent {
  z-index: 2;
}
clickable {
  cursor: pointer;
}
.trigger {
  font-size: 12px;
}
.dropdown {
  color: #5c89eb;
}
