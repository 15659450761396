/*
 * colors.less
 */
.card {
  display: grid;
  grid-template-rows: auto 1fr;
  background: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.card .cardHeader {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 24px;
}
.card .cardHeader h3 {
  white-space: nowrap;
  color: #1f2e77;
  margin: 0;
}
.card .cardBody {
  padding: 24px;
}
.loader {
  margin: 100px auto;
}
