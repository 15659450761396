@import 'src/less/colors';

.remoteSelector {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 20px 24px;
  padding: 8px 12px;
  column-gap: 8px;

  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;

  &:hover {
    border-color: @SecondaryBlue;
  }

  .star {
    grid-row: 1 / span 2;
    grid-column: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    font-size: 16px;

    background: none;
    color: @PrimaryCobalt40;
    border: 1px solid @SecondaryBlue20;
    border-radius: 32px;

    &:not(:disabled) {
      cursor: pointer;

      &:hover {
        color: @SecondaryBlue;
        border-color: @SecondaryBlue;
      }
    }

    &:disabled {
      color: @SecondaryBlue20;
    }

    &.active {
      color: white;
      background-color: @SecondaryBlue;
      border-color: @SecondaryBlue;

      &:hover {
        color: white;
        background-color: @SecondaryBlue80;
      }
    }
  }

  .location {
    grid-row: 1;
    grid-column: 2;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: @PrimaryCobalt;
  }

  .remote {
    grid-row: 2;
    grid-column: 2;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 8px;

    .label {
      grid-column: 1;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      color: @PrimaryCobalt60;
    }

    .pills {
      grid-column: 2;
      white-space: nowrap;
      line-height: 24px;
    }
  }

  .remove {
    grid-row: 1 / span 2;
    grid-column: 3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    font-size: 16px;

    background: none;
    border: none;
    color: @PrimaryCobalt60;

    cursor: pointer;

    &:hover {
      color: @SecondaryBlue;
    }
  }
}

.nudge {
  display: inline-block;
  margin-left: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 3px 8px;
  border: 1px solid @SecondaryBlue20;
  border-top: none;
  box-shadow: 0px 1px 2px 0px @PrimaryCobaltOp06;

  .nudgeButton {
    display: inline;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    font-family: Graphik;
    font-size: 12px;

    &:not(:disabled) {
      color: @SecondaryBlue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: @PrimaryCobalt;
      }
    }

    &:disabled {
      color: @PrimaryCobalt80;
    }
  }
}
