/*
 * colors.less
 */
.pagination {
  display: inline-flex;
}
.pagination .paginationButton {
  height: 32px;
  min-width: 32px;
  border-top: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
  border-right: 1px solid #dae0f4;
  color: #7982ad;
  user-select: none;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.pagination .paginationButton:not(:disabled) {
  cursor: pointer;
}
.pagination .paginationButton:not(:disabled):hover {
  background-color: #f8faff;
}
.pagination .paginationButton:disabled {
  color: #dae0f4;
}
.pagination .paginationButton.active {
  background-color: #4864c9;
  color: white;
}
.pagination .paginationButton.active:not(:disabled):hover {
  background-color: #3450b5;
}
.pagination .paginationButton.previous {
  border-left: 1px solid #dae0f4;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.pagination .paginationButton.next {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
