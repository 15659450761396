@import 'src/less/colors';

:global(.ui.dropdown .menu) > .dropdownCustomFieldsHeader {
  color: @PrimaryCobalt60;
  text-transform: none;
  font-size: 13px;
  line-height: 140%;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  padding: 4px 16px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.dropdownContainer {
  position: relative;
  margin-top: 13px;
  margin-right: 20px;

  .ui.dropdown,
  .ui.dropdown input,
  .ui.dropdown .divider {
    cursor: pointer !important;
  }
}

.customPlaceholder {
  position: absolute;
  display: flex;
  color: @SecondaryBlue;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  gap: 8px;
  z-index: 10;
  background-color: white;
  top: 50%;
  left: 12px;
  transform: translateY(calc(-50% + 7px));
  width: calc(100% - 40px);
  pointer-events: none;
}

.dropdownCustomFieldsAdd {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-family: Graphik, sans-serif;
  align-items: center;
  color: @SecondaryBlue;
  font-size: 13px;
  line-height: 140%;
  font-weight: 500;
  padding-left: 0;
}

.inputFilename {
  height: 37px;
}
:global(.ui.selection.dropdown .menu > .item).dropdownOption {
  font-weight: 500;
}
:global(.ui.dropdown .menu > .divider).dropdownDivider {
  margin: 3px 8px 5px;
}
.dropdownOption {
}
