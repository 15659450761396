@import 'src/less/colors';

.modalContent {
  padding: 0 !important;
  height: 400px;
}

.modalDescription {
  font-family: Graphik;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1em;
  letter-spacing: 0px;
  text-align: center;
  color: @PrimaryColor;
}

.jobPostingmodalContainer {
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  gap: 24px;
}

.templateSelectorContainer {
  width: 100%;
  min-width: 100%;
  display: inline-block;
  height: inherit;
  display: flex;
  flex-direction: column;

  .description {
    color: @PrimaryCobalt80;
  }

  > div {
    &:first-of-type {
      padding: 24px 24px 0px 24px;
    }
    &:last-of-type {
      padding: 0px 24px 24px 24px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    padding: 0px 24px;
  }
}

.jobPostingActions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.jobPostingPreviewTrigger {
  color: @PrimaryCobalt60;
}

.jobPostingPreview {
  width: 400px;
}

.offerSearch {
  width: 100%;
  margin-top: 24px;
  flex-shrink: 0;
}

.jobPostingsContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;

  .emptyState {
    color: @PrimaryCobalt60;
    text-align: center;
  }

  .jobPosting {
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px #1f2e771a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .jobPostingHeader {
      h4 {
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: @PrimaryCobalt;
        margin-bottom: 0px;
        display: inline;
      }
    }

    .publicationStatus {
      display: inline-flex;
      align-items: center;
      margin-left: 16px;

      line-height: 20px;
      font-family: Graphik, sans-serif;
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 12px;

      &.published {
        color: @PositiveColor;
      }
      &.unpublished {
        color: @PrimaryCobalt60;
      }

      i {
        margin-right: 5px;
      }
    }
  }
}

.choicesContainer {
  display: inline-flex;
  gap: 8px;
  width: 100%;
  min-width: 100%;
  margin-left: 0px;
  transition: margin-left 250ms;
  padding: 24px;

  &.hidden {
    margin-left: calc(-100% - 24px);
  }

  .choiceCard {
    flex: 1 1;
    text-align: center;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid @SecondaryBlue20;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: fit-content;
    margin: auto;

    img {
      width: 100%;
    }

    .choiceTitle {
      font-family: Gilroy;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
      color: @PrimaryCobalt;
      margin: 8px 0px;
    }

    .choiceDescription {
      flex: 1 1;
      color: @PrimaryCobalt60;
      font-family: Graphik;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
    }

    .choiceCardAction {
      position: relative;
      width: 100;
      .choiceButton {
        width: 100%;
        padding: 10px 0px;
        height: auto;
        line-height: 20px;
        transition: background 250ms;
        position: relative;
        z-index: 12;

        &.AIGeneration {
          background: transparent;
        }
      }

      .gradientButtonBackground {
        background: linear-gradient(90deg, #4568dc 0%, #b06ab3 100%);
        width: 100%;
        padding: 10px 0px;
        height: auto;
        line-height: 20px;
        border-radius: 8px;
        color: transparent;
        position: absolute;
        z-index: 0;
        top: 0;
      }
    }

    &:hover {
      .choiceButton.AIGeneration {
        background: transparent;
      }
    }
  }
}

.actions {
  padding: 24px !important;
}
