/*
 * colors.less
 */
.ellipsis-menu {
  height: 20px;
  width: 20px;
  background: #F9F9F9;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ellipsis-menu .ellipsis-icon {
  fill: #8e8e90;
}
.ellipsis-menu:hover {
  border: 1px solid rgba(72, 100, 201, 0.5);
  background: white;
}
.ellipsis-menu:hover .ellipsis-icon {
  fill: #4864c9;
}
