/*
 * colors.less
 */
.kanbanColumn {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.kanbanColumn .columnHeader {
  color: #1f2e77;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0px 16px 12px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}
.kanbanColumn .columnHeader h4 {
  margin: 0;
  line-height: 18px;
}
.kanbanColumn .columnHeader .itemCounter:global(.ui.label) {
  background-color: #e2ebff;
  color: #4864c9;
  padding: 4px 5px 4px 5px;
  margin-left: 8px;
}
.kanbanColumn.disabled .columnHeader {
  color: #7982ad;
}
.kanbanColumn.disabled .itemCounter:global(.ui.label) {
  background-color: #edf0fa;
  color: #7982ad;
}
.kanbanColumn .columnContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 200px;
  padding: 0px 16px 16px 16px;
}
.kanbanColumn .columnContent::-webkit-scrollbar {
  display: none;
}
.kanbanColumn .columnContent .kanbanColumnAnchor,
.kanbanColumn .columnContent .kanbanAnchor {
  height: 0px;
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 2px;
  background-color: #6d83d4;
  pointer-events: none;
  transition: height 250ms;
}
.kanbanColumn .columnContent .kanbanColumnAnchor.visible,
.kanbanColumn .columnContent .kanbanAnchor.visible {
  height: 4px;
}
.kanbanColumn .columnContent .emptyStateCard {
  width: 100%;
  background-color: #edf0fa;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  color: #7982ad;
  font-family: Graphik;
  font-weight: 400;
  font-size: 14px;
}
.kanbanColumn .columnContent .kanbanColumnAnchor {
  width: calc(100% - 32px);
  left: 16px;
}
.kanbanColumn .columnContent .disabledColumnOverlay {
  position: absolute;
  height: calc(100% + 4px);
  width: calc(100% - 28px);
  top: -2px;
  left: 14px;
  border-radius: 4px;
  background-color: rgba(150, 150, 150, 0.4);
}
.kanbanColumn .showMore {
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
  color: #1f2e77;
  margin-bottom: 24px;
}
.kanbanColumn a:last-child {
  margin-bottom: 24px;
}
