/*
 * colors.less
 */
.global-search-bar-container {
  position: relative;
}
.mini-profile-search-results {
  background-color: white;
  position: absolute;
  z-index: 100;
  top: 45px;
  width: 300px;
  border: 1px solid #dae0f4;
  max-height: 300px;
  overflow-y: scroll;
}
.mini-profile-search-results .reveal-profile-mini-card {
  width: 100%;
  cursor: pointer;
}
.mini-profile-search-results .reveal-profile-mini-card .reveal-profile-mini-card-subcontainer {
  width: 100%;
  padding: 0.5em 0.7em 0.4em;
}
.mini-profile-search-results .reveal-profile-mini-card .ui.grid .row {
  align-items: center;
}
.mini-profile-search-results .reveal-profile-mini-card .content {
  color: #4864c9;
  font-weight: 600;
  margin-bottom: 2px;
}
.mini-profile-search-results .reveal-profile-mini-card .name {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 700;
}
.mini-profile-search-results .reveal-profile-mini-card .headline {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #4c5892;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.mini-profile-search-results .reveal-profile-mini-card:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.mini-profile-search-results .reveal-profile-mini-card:hover {
  background-color: #f1f6ff;
}
.mini-profile-search-results a {
  width: inherit;
}
.mini-profile-search-icon-container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.mini-profile-search-bar .debounced-search-bar {
  width: 300px !important;
  z-index: 101;
  margin-right: 30px;
}
