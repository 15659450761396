@import 'src/less/colors';

.providerHelper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
  background-color: @SecondarySkyBlue10;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  color: @PrimaryCobalt;
}

.providerHelperInstructions {
  flex-shrink: 0;
  align-self: center;
}

.breadcrumbDividerIcon {
  font-size: 18px;
  margin: 0px 10px;
}

.subHeader {
  color: @PrimaryCobalt60;
}

.providerHelperInstructionsHeaderClose {
  display: inline-block;
  float: right;
  cursor: pointer;
}

.providerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}
.providerHeader:not(:first-child) {
  margin-top: 48px;
}
.providerHeaderNumber {
  font-size: 14px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  color: #1f2e77;
  border: 1px solid #1f2e77;
  text-align: center;
  margin-right: 6px;
}
.providerHeaderText {
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #1f2e77;
}
