/*
 * colors.less
 */
.wrapper {
  background-color: #f1f6ff;
  padding: 15px;
  margin: 10px 0;
  font-family: 'Graphik';
}
.wrapper .application {
  font-weight: 400;
  font-size: 12px;
  color: #7982ad;
  display: flex;
  align-items: center;
  gap: 5px;
}
.wrapper .application .icon {
  padding: 5px 4px 3px 4px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  border-radius: 100%;
  background-color: #3450b5;
  color: white;
}
.wrapper .application .bold {
  color: #1f2e77;
  font-weight: 500;
}
.wrapper .message {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 8px;
  color: #1f2e77;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 10px;
}
.wrapper .explanation {
  margin-top: 8px;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: #7982ad;
}
.wrapper .explanation .link {
  color: #7982ad;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper .explanation .link:hover {
  color: #4c5892;
}
