@import 'src/less/colors';

.flexBetween {
  justify-content: space-between;
}

.column {
  flex-direction: column;
  align-items: flex-start !important;
}

.contactout {
  margin: 12px 0;
  display: flex;
  align-items: center;
  border: 1px solid #dae0f4;
  border-radius: 3px;
  padding: 5px;
  gap: 20px;
  .iconWrapper {
    padding-left: 10px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .label {
    color: @PrimaryCobalt;
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 700;
  }

  .noResults {
    color: #7982ad;
    font-family: 'Graphik';
    font-size: 12px;
  }

  .button {
    margin-right: 10px;
  }

  .contactoutResult {
    h5 {
      margin-bottom: 5px;
    }
  }

  .flexWrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex: 0.9;

    .flexItem {
      font-size: 12px;
      font-family: 'Graphik';
      font-weight: 400;
      color: @SecondaryBlue;
      text-decoration: underline;
      cursor: pointer;
      overflow-wrap: anywhere;
    }
  }

  &.minimized {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 4px;
    padding: 8px;
    row-gap: 4px;
    column-gap: 0;
    justify-content: space-between;
    align-items: center;

    .iconWrapper {
      grid-row: 1;
      grid-column: 1;
      min-width: unset;
      white-space: nowrap;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .label {
        font-size: 12px;
        font-family: Graphik;
        font-weight: 400;
        color: @PrimaryCobalt60;
      }
    }

    .noResults {
      text-align: right;
    }

    .expand {
      grid-row: 1;
      grid-column: 2;
      padding: 0;
      white-space: nowrap;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      font-family: Graphik;
      font-weight: 500;
      color: @SecondaryBlue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      i {
        margin-left: 2px;
        line-height: normal;
      }
    }

    .contactoutResult {
      grid-column: 1 / span 2;
      padding-top: 4px;
      border-top: 1px solid @SecondaryBlue10;
    }
  }
}

.plugin {
  background-color: white;
  padding: 12px;
  margin: 10px 0;
  border-radius: 8px;

  .iconWrapper {
    align-self: center;
    padding-left: 0px;
  }

  .button {
    align-self: center;
  }
}

.quota {
  color: @PrimaryCobalt60;
  font-size: 12px;
  font-family: 'Graphik';
  font-weight: 500;
  margin-left: 8px;
  display: inline-block;

  &.quotaPlugin {
    display: block;
  }
}
