@import 'src/less/colors.less';

table.table {
  width: 100%;
  border-radius: 8px;
  border: 1px solid @SecondaryBlue20;
  overflow: hidden;
  border-collapse: initial;
  border-spacing: 0;

  thead {
    background: @SecondarySkyBlue05;

    tr.row {
      background: transparent;
    }

    th {
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 14px;
      color: @PrimaryCobalt60;
      padding: 1em 0.8em;
      text-align: inherit;
    }
  }

  tr.row {
    background: white;
    border-bottom: 1px solid @SecondaryBlue20;

    td {
      border-top: 1px solid @SecondaryBlue20;
      padding: 0.8em;
    }
  }
}

.twoLineCell {
  span {
    display: block;
  }

  span:first-child {
    color: @PrimaryCobalt;
    font-weight: 500;
    margin-bottom: 5px;
  }

  span:not(:first-child) {
    color: @PrimaryCobalt60;
    font-weight: 400;
  }
}
