/*
 * colors.less
 */
.activity-graph {
  width: 100%;
}
.activity-graph-total {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.activity-total-contacted {
  font-weight: 600;
  color: #1f2e77;
}
.activity-total-contacted-placeholder {
  visibility: hidden;
}
.activity-graph-heatmap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.activity-graph-heatmap {
  display: flex;
  padding: 8px 0px 5px 0px;
  width: 100%;
}
