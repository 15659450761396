@import 'src/less/colors';

.offers {
  margin-bottom: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;

  .header {
    height: 52px;
    padding: 0 16px;
    display: flex;
    grid-template-columns: 1fr 184px 380px;
    align-items: center;
    gap: 12px;

    .title {
      flex: 1;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: @PrimaryCobalt;
    }

    .column {
      text-align: left;
      font-family: Graphik;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      color: @PrimaryCobalt60;

      &.main {
        width: 160px;
        text-align: left;
        @media (max-width: 1400px) {
          width: 100px;
        }
      }

      &.extra {
        width: 80px;
        text-align: center;
      }
    }

    .menu {
      width: 32px;
    }
  }
}
