/*
 * colors.less
 */
.graph {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 32px 1fr;
  padding-bottom: 16px;
}
.graph .scale {
  height: 300px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  text-align: right;
}
.graph .scale .tick {
  font-size: 12px;
  font-family: Graphik;
  color: #7982ad;
}
.graph .dataContainer {
  grid-column: 2;
  min-width: 0;
}
.graph .dataContainer .scrollableData {
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 0 200px 0;
  margin: -10px 0 -200px 0;
}
.graph .dataContainer .scrollableData .data {
  flex: 1;
  display: inline-grid;
  grid-template-rows: 300px auto;
  align-items: stretch;
}
.graph .dataContainer .scrollableData .data .rulers {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 9px 0;
}
.graph .dataContainer .scrollableData .data .rulers .ruler {
  border-bottom-width: 1px;
  border-bottom-color: #dae0f4;
}
.graph .dataContainer .scrollableData .data .rulers .ruler:not(:first-child) {
  border-bottom-style: dashed;
}
.graph .dataContainer .scrollableData .data .rulers .ruler:first-child {
  border-bottom-style: solid;
}
.graph .dataContainer .scrollableData .data .areas {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: stretch;
  padding: 10px 16px;
}
.graph .dataContainer .scrollableData .data .areas .section {
  min-width: 80px;
  flex: 1;
}
.graph .dataContainer .scrollableData .data .labels {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  padding: 0 16px;
}
.graph .dataContainer .scrollableData .data .labels .label {
  min-width: 80px;
  flex-basis: 240px;
  flex-shrink: 1;
  text-align: center;
  color: #7982ad;
}
