@import 'src/less/colors';

.campaignFunnels {
  margin: 20px 0;
  background: white;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  .top {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @SecondaryBlue20;
    padding: 0 11px 0 24px;

    h3 {
      color: @PrimaryCobalt;
      margin: 0;
    }

    .topRight {
      display: inline-flex;
      align-items: center;
      gap: 16px;
    }
  }

  .tableHeader {
    display: flex;
    align-items: center;
    height: 54px;
    padding: 18px 24px;
    background-color: @SecondarySkyBlue05;
    color: @PrimaryCobalt60;
    font-size: 14px;
    font-family: Graphik;
    font-weight: 500;
    border-bottom: 1px solid @SecondaryBlue20;

    .name {
      width: 300px;
    }

    .headerStage {
      flex: 1;

      &.wider {
        flex: 2;
      }

      &.centered {
        text-align: center;
      }
    }
  }
}

.nbUnsubscribe {
  justify-content: center;
  border-left: 1px solid @SecondaryBlue20;

  .count {
    text-align: center;
  }
}
