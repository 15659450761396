/*
 * colors.less
 */
.filtersContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}
.filtersContainer h2 {
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  color: #1f2e77;
}
.filtersContainer .userContainer {
  min-width: 200px;
}
.filtersContainer .selectedProjects {
  min-width: 350px;
  max-width: 350px;
}
.filtersContainer .selectedStages {
  width: 350px;
}
