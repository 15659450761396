@import 'src/less/colors';

.title {
  color: @PrimaryCobalt;
}

.input {
  width: 100%;
}

.checkbox {
  margin-bottom: 8px;
}
