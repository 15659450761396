/*
 * colors.less
 */
.missions-and-sequences-management-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.missions-and-sequences-management-container .missions-management-container {
  z-index: 1;
}
.missions-and-sequences-management-container .sequences-management-container {
  z-index: 0;
}
.missions-and-sequences-management-container .missions-management-container,
.missions-and-sequences-management-container .sequences-management-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
}
.missions-and-sequences-management-container .missions-management-container .title,
.missions-and-sequences-management-container .sequences-management-container .title {
  width: 80px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Graphik';
  color: #7982ad;
  flex-shrink: 0;
}
.missions-and-sequences-management-container .missions-management-container .missions,
.missions-and-sequences-management-container .sequences-management-container .missions,
.missions-and-sequences-management-container .missions-management-container .sequences,
.missions-and-sequences-management-container .sequences-management-container .sequences {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
}
.missions-and-sequences-management-container .missions-management-container .missions,
.missions-and-sequences-management-container .sequences-management-container .missions {
  border: 1px solid #dae0f4;
  border-radius: 2px;
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 500;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-name,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-name {
  padding: 7px 12px;
  height: 32px;
  line-height: 19px;
  color: #4864c9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.missions-and-sequences-management-container .missions-management-container .missions .missions-stage,
.missions-and-sequences-management-container .sequences-management-container .missions .missions-stage {
  flex-shrink: 0;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-item,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-item {
  padding: 7px 8px;
  height: 32px;
  border-left: 1px solid #dae0f4;
  line-height: 19px;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-item .mission-pipeline-dropdown,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-item .mission-pipeline-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 19px;
  color: #7982ad;
  cursor: pointer;
  align-items: baseline;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-item .mission-pipeline-dropdown span,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-item .mission-pipeline-dropdown span {
  width: 100%;
  padding-right: 8px;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-item .mission-pipeline-dropdown div,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-item .mission-pipeline-dropdown div {
  height: 17px;
  margin-top: -2px;
  flex-shrink: 0;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-item .mission-pipeline-dropdown div :last-child,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-item .mission-pipeline-dropdown div :last-child {
  margin-left: 2px;
  vertical-align: -2px;
}
.missions-and-sequences-management-container .missions-management-container .missions .mission-item div:last-of-type,
.missions-and-sequences-management-container .sequences-management-container .missions .mission-item div:last-of-type {
  right: 0px;
  top: 35px;
  border: none;
}
.missions-and-sequences-management-container .missions-management-container .show-more-missions,
.missions-and-sequences-management-container .sequences-management-container .show-more-missions {
  background-color: #f6f7fc;
  border: 1px solid #dae0f4;
  padding: 6px 4px 6px 8px;
  margin-left: 4px;
  border-radius: 2px;
  color: #7982ad;
  cursor: pointer;
}
.missions-and-sequences-management-container .missions-management-container .show-more-missions i,
.missions-and-sequences-management-container .sequences-management-container .show-more-missions i {
  vertical-align: -2px;
  margin-left: 4px;
}
.missions-and-sequences-management-container .missions-management-container .new-mission-icon,
.missions-and-sequences-management-container .sequences-management-container .new-mission-icon {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  font-family: Graphik;
  font-weight: 500;
}
.missions-and-sequences-management-container .missions-management-container .new-mission-icon span,
.missions-and-sequences-management-container .sequences-management-container .new-mission-icon span {
  margin-left: 8px;
  color: #4864c9;
  line-height: 20px;
}
.missions-and-sequences-management-container .missions-management-container .new-mission-icon span:first-of-type,
.missions-and-sequences-management-container .sequences-management-container .new-mission-icon span:first-of-type {
  font-weight: 500;
}
.missions-and-sequences-management-container .missions-management-container .new-mission-icon i,
.missions-and-sequences-management-container .sequences-management-container .new-mission-icon i {
  margin-left: 8px;
  vertical-align: -2px;
  color: #4864c9;
}
.missions-and-sequences-management-container .missions-management-container .sequences,
.missions-and-sequences-management-container .sequences-management-container .sequences {
  height: 100%;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos {
  max-width: 99.7%;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos .sequence-divider,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos .sequence-divider {
  width: 10px;
  height: 10px;
  border-left: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
  border-bottom-left-radius: 4px;
  margin-top: -8px;
  margin-right: 8px;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos .sequence-container,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos .sequence-container {
  margin-right: 8px;
  flex-grow: 1;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos span,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos span {
  margin-right: 8px;
  color: #4864c9;
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos .row-icon-container,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos .row-icon-container {
  margin-top: 0px;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos i,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos i {
  color: #7982ad;
  margin-top: -4px;
  font-size: 16px;
  cursor: pointer;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos i:first-of-type,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos i:first-of-type {
  margin-top: -10px;
  font-size: 12px;
}
.missions-and-sequences-management-container .missions-management-container .sequences .sequences-infos .sequence-actions,
.missions-and-sequences-management-container .sequences-management-container .sequences .sequences-infos .sequence-actions {
  height: 17px;
}
.missions-and-sequences-management-container .missions-management-container .empty-sequence,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence {
  display: inline-flex;
  width: 100%;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission,
.missions-and-sequences-management-container .sequences-management-container .empty-mission {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.missions-and-sequences-management-container .missions-management-container .new-mission-container,
.missions-and-sequences-management-container .sequences-management-container .new-mission-container {
  flex-shrink: 0;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission,
.missions-and-sequences-management-container .sequences-management-container .empty-mission,
.missions-and-sequences-management-container .missions-management-container .empty-sequence,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence {
  color: #7982ad;
  align-items: baseline;
  position: relative;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission p,
.missions-and-sequences-management-container .sequences-management-container .empty-mission p,
.missions-and-sequences-management-container .missions-management-container .empty-sequence p,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Graphik';
  flex: 1;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission div,
.missions-and-sequences-management-container .sequences-management-container .empty-mission div,
.missions-and-sequences-management-container .missions-management-container .empty-sequence div,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence div {
  flex-shrink: 0;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission span,
.missions-and-sequences-management-container .sequences-management-container .empty-mission span,
.missions-and-sequences-management-container .missions-management-container .empty-sequence span,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence span {
  cursor: pointer;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission span:first-of-type,
.missions-and-sequences-management-container .sequences-management-container .empty-mission span:first-of-type,
.missions-and-sequences-management-container .missions-management-container .empty-sequence span:first-of-type,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence span:first-of-type {
  font-weight: 500;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission i,
.missions-and-sequences-management-container .sequences-management-container .empty-mission i,
.missions-and-sequences-management-container .missions-management-container .empty-sequence i,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence i {
  vertical-align: -2px;
}
.missions-and-sequences-management-container .missions-management-container .empty-mission span,
.missions-and-sequences-management-container .sequences-management-container .empty-mission span,
.missions-and-sequences-management-container .missions-management-container .empty-sequence span,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence span,
.missions-and-sequences-management-container .missions-management-container .empty-mission i,
.missions-and-sequences-management-container .sequences-management-container .empty-mission i,
.missions-and-sequences-management-container .missions-management-container .empty-sequence i,
.missions-and-sequences-management-container .sequences-management-container .empty-sequence i {
  margin-left: 8px;
  color: #4864c9;
}
.missions-and-sequences-management-container.plugin {
  padding: 6px 15px 6px;
  row-gap: 0px;
  border-top: 1px solid #dae0f4;
  border-bottom: 1px solid #dae0f4;
}
.missions-and-sequences-management-container.plugin .missions-management-container {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}
.missions-and-sequences-management-container.plugin .missions-management-container .new-mission-icon {
  font-size: 12px;
}
.missions-and-sequences-management-container.plugin .missions-management-container .empty-mission,
.missions-and-sequences-management-container.plugin .sequences-management-container .empty-mission {
  font-size: 12px;
}
.missions-and-sequences-management-container.plugin .missions-management-container .empty-sequence,
.missions-and-sequences-management-container.plugin .sequences-management-container .empty-sequence {
  font-size: 12px;
}
.missions-and-sequences-management-container.plugin .missions-management-container .empty-mission p,
.missions-and-sequences-management-container.plugin .sequences-management-container .empty-mission p,
.missions-and-sequences-management-container.plugin .missions-management-container .empty-sequence p,
.missions-and-sequences-management-container.plugin .sequences-management-container .empty-sequence p {
  font-size: 12px;
}
.missions-and-sequences-management-container.plugin .title {
  display: none;
}
.missions-and-sequences-management-container.plugin .show-more-missions {
  padding: 0px 0px 0px 2px;
  background-color: white;
  border: none;
  cursor: pointer;
}
.missions-and-sequences-management-container.plugin .show-more-missions i {
  margin-left: 0;
}
.missions-and-sequences-management-container.plugin .missions {
  border: none;
  padding: 0;
}
.missions-and-sequences-management-container.plugin .missions .mission-item {
  border: none;
  padding: 8px 0px 3px 0px;
}
.missions-and-sequences-management-container.plugin .missions .mission-name {
  padding: 8px 8px 3px 0px;
  color: #1f2e77;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px !important;
}
.missions-and-sequences-management-container.plugin .missions .mission-stage {
  flex-shrink: 0;
}
.missions-and-sequences-management-container.plugin .sequences-management-container {
  width: 100%;
}
.missions-and-sequences-management-container.plugin .sequences-infos span {
  flex-grow: 1;
}
.missions-and-sequences-management-container.plugin .sequences-infos i:last-of-type {
  width: 17px;
}
.missions-and-sequences-management-container.plugin .sequence-divider {
  margin-left: 3px;
}
.missions-and-sequences-management-container.plugin .more-sequence-trigger {
  margin-left: 8px !important;
  cursor: pointer;
}
.missions-and-sequences-management-container.plugin.minimized {
  gap: 2px;
  padding: 0;
  border: none;
}
.missions-and-sequences-management-container.plugin.minimized .missions-management-container,
.missions-and-sequences-management-container.plugin.minimized .sequences-management-container {
  height: auto;
}
.missions-and-sequences-management-container.plugin.minimized .missions .mission-name {
  padding: 0;
  height: auto;
}
.more-missions-popup {
  padding: 0 !important;
}
.more-missions-popup .all-mission-header {
  padding: 12px;
  font-family: 'Graphik';
  font-weight: 500;
  color: #1f2e77;
  font-size: 12px;
  line-height: 12px;
  height: 36px;
  cursor: pointer;
}
.more-missions-popup .all-mission-header .contact-name {
  margin-right: 4px;
}
.more-missions-popup .all-mission-header i {
  vertical-align: bottom;
}
.more-missions-popup .all-missions-item {
  border-top: 1px solid #dae0f4;
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding: 3px 0px;
}
.more-missions-popup .all-missions-item .mission-stage {
  flex-shrink: 0;
  cursor: pointer;
}
.more-missions-popup .all-missions-item .mission-name {
  padding: 8px 12px;
  height: 32px;
  line-height: 19px;
  color: #4864c9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.more-missions-popup .all-missions-item .mission-item {
  padding: 7.5px 8px;
  height: 32px;
  line-height: 19px;
}
.more-missions-popup .all-missions-item .mission-item .mission-pipeline-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 19px;
  color: #7982ad;
}
.more-missions-popup .all-missions-item .mission-item .mission-pipeline-dropdown span {
  width: 100%;
  margin-right: 8px;
}
.more-missions-popup .all-missions-item .mission-item .mission-pipeline-dropdown div {
  height: 17px;
  margin-top: -2px;
  flex-shrink: 0;
}
.more-missions-popup .all-missions-item .mission-item .mission-pipeline-dropdown div :last-child {
  margin-left: 2px;
  vertical-align: -2px;
}
.more-missions-popup .move-to-stage-action {
  width: 100%;
}
.more-missions-popup .move-to-stage-action > div:first-child {
  position: unset;
  width: 100%;
  max-width: unset;
  border: none;
  box-shadow: none;
  border-top: 1px solid #dae0f4;
  border-radius: 0;
}
.more-sequences-popup {
  overflow-x: hidden;
  padding: 0 !important;
  min-width: 0px;
  box-shadow: 0px 2px 16px rgba(31, 46, 119, 0.15) !important;
}
.more-sequences-popup:not(.empty) {
  max-height: 480px;
}
.more-sequences-popup-plugin {
  width: 100%;
  padding: 0 !important;
  min-width: 0 !important;
  overflow-y: auto;
  max-width: 335px !important;
  max-height: 300px;
}
.more-sequences-popup-plugin .sequence-item-name {
  max-width: 100% !important;
}
.more-sequences-popup-plugin.mini {
  width: calc(100% - 26px);
  height: 180px;
}
.more-sequences-popup,
.more-sequences-popup-plugin {
  border-radius: 8px !important;
  border: 2px solid #c6d8ff !important;
}
.more-sequences-popup.visible.transition,
.more-sequences-popup-plugin.visible.transition {
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden;
}
.more-sequences-popup .sequence-search-bar,
.more-sequences-popup-plugin .sequence-search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  gap: 8px;
  border-bottom: 2px solid #c6d8ff !important;
}
.more-sequences-popup .sequence-search-bar i,
.more-sequences-popup-plugin .sequence-search-bar i {
  color: #7982ad;
}
.more-sequences-popup .sequence-search-bar .ui.input,
.more-sequences-popup-plugin .sequence-search-bar .ui.input {
  flex-grow: 1;
}
.more-sequences-popup .sequence-search-bar .ui.input input,
.more-sequences-popup-plugin .sequence-search-bar .ui.input input {
  border: none !important;
  padding: 0px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.more-sequences-popup .sequence-popup-item,
.more-sequences-popup-plugin .sequence-popup-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  color: #4864c9;
  border-top: 1px solid #dae0f4;
  width: 100%;
  height: 37px;
}
.more-sequences-popup .sequence-popup-item:first-of-type,
.more-sequences-popup-plugin .sequence-popup-item:first-of-type {
  border-top: none;
}
.more-sequences-popup .sequence-popup-item:hover,
.more-sequences-popup-plugin .sequence-popup-item:hover {
  background-color: #f6f7fc;
}
.more-sequences-popup .sequence-popup-item .sequence-container,
.more-sequences-popup-plugin .sequence-popup-item .sequence-container {
  flex-grow: 1;
}
.more-sequences-popup .sequence-popup-item .sequence-item-name,
.more-sequences-popup-plugin .sequence-popup-item .sequence-item-name {
  margin-right: 8px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  max-width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.more-sequences-popup .sequence-popup-item .row-icon-container,
.more-sequences-popup-plugin .sequence-popup-item .row-icon-container {
  margin-top: 0;
}
.sequence-preview-popup,
.sequence-preview-popup-plugin {
  overflow: auto;
}
.sequence-preview-popup {
  width: 100%;
  max-width: 400px !important;
  max-height: 600px !important;
  overflow: auto;
}
.sequence-preview-popup-plugin {
  width: 400px !important;
  min-width: 400px !important;
  max-width: 400px !important;
  left: 0px !important;
  max-height: 400px;
}
.all-missions-popup {
  width: calc(40% - 183px);
  max-width: calc(40% - 183px) !important;
  padding: 0 !important;
  max-height: 300px;
  overflow-y: auto;
  left: unset !important;
  right: 20px !important;
  transform: none !important;
}
.all-missions-popup-plugin {
  width: 334px;
  max-width: 334px !important;
  padding: 0 !important;
  max-height: 300px;
  overflow-y: auto;
  right: 20px !important;
  transform: none !important;
}
.all-missions-popup .projects-search-bar.ui.input > input,
.all-missions-popup-plugin .projects-search-bar.ui.input > input {
  border: none !important;
}
.all-missions-popup .all-missions-header,
.all-missions-popup-plugin .all-missions-header {
  padding: 9.5px 12px;
  font-weight: 500;
  font-size: 12px;
}
.all-missions-popup .mission-popup-item,
.all-missions-popup-plugin .mission-popup-item {
  padding: 9.5px 12px;
  cursor: pointer;
  font-size: 12px;
  color: #4864c9;
  font-weight: 500;
  border-top: 1px solid #dae0f4;
}
.all-missions-popup .mission-popup-item:hover,
.all-missions-popup-plugin .mission-popup-item:hover {
  background-color: #f6f7fc;
}
.all-missions-popup .mission-popup-item.disabled,
.all-missions-popup-plugin .mission-popup-item.disabled {
  background-color: #f6f7fc;
  color: #7982ad;
}
