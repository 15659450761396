/*
 * colors.less
 */
.name-and-headline {
  overflow-x: hidden;
}
.name-and-headline .full-name {
  display: flex;
  align-items: center;
  color: #4864c9;
}
.name-and-headline .full-name .full-name-text {
  width: 100%;
}
.name-and-headline .full-name .full-name-text .full-name-icon {
  vertical-align: middle;
  color: #6d83d4;
  padding-right: 3px;
}
.name-and-headline .full-name .full-name-text .full-name-icon::before {
  vertical-align: initial;
}
.name-and-headline .full-name .full-name-text div {
  width: 100%;
}
.name-and-headline .full-name .full-name-text div div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100% !important;
}
.name-and-headline .headline {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4c5892;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.name-and-headline .headline-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.name-and-headline .cardCustomFieldsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.name-and-headline .cardCustomFieldsContainer .remainingCustomFields {
  color: #7982ad;
}
.name-and-headline .cardCustomFieldsList {
  display: flex;
  gap: 2px;
}
.name-and-headline .cardCustomField {
  flex-shrink: 0;
  height: 18px;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  background: #f6f7fc;
  display: flex;
}
.name-and-headline .cardCustomField.customFieldHidden {
  visibility: hidden !important;
}
.name-and-headline .cardCustomField .customFieldTitle,
.name-and-headline .cardCustomField .customFieldValue {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.name-and-headline .cardCustomField .customFieldTitle {
  color: #1f2e77;
}
.name-and-headline .cardCustomField .customFieldTitle + .customFieldValue {
  margin-left: 4px;
}
.name-and-headline .cardCustomField .customFieldValue {
  color: #7982ad;
}
