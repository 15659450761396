.showMoreTasks {
  padding: 18px 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .showMoreActions div {
    color: #4864c9;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Graphik';

    i {
      margin: 0px 4px 0px 0px;
    }

    p {
      margin-bottom: 0;
      line-height: 20px;
      font-weight: 500;
    }

    :global(.label) {
      color: #4864c9;
      margin: 0px 4px;
      background-color: #e2ebff;
      padding: 3px 4px;
      font-weight: 500;
    }
  }

  p {
    color: #70748b;
  }
}
