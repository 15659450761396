.scorecard-activity .label {
  font-size: 14px;
  font-weight: 500;
  color: #1f2e77;
}
.scorecard-activity .overall-recommendation-content {
  display: flex;
  align-items: center;
  color: #000329;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 6px;
}
.scorecard-activity .rating-indicator {
  font-size: 22px;
  margin-right: 12px;
}
.scorecard-activity .rating-indicator.strong-yes,
.scorecard-activity .rating-indicator.yes {
  color: #28ca42;
}
.scorecard-activity .rating-indicator.mixed {
  color: #ffbd2e;
}
.scorecard-activity .rating-indicator.definitely-not,
.scorecard-activity .rating-indicator.not {
  color: #ff6059;
}
.scorecard-activity .value {
  font-size: 12px;
  color: #70748b;
}
.scorecard-activity .scorecard-attributes {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 6px;
}
