/*
 * colors.less
 */
/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.contact-flow-action-editor,
.ui.segment.contact-flow-action-editor {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
}
.contact-flow-action-editor h3,
.ui.segment.contact-flow-action-editor h3,
.contact-flow-action-editor h4,
.ui.segment.contact-flow-action-editor h4,
.contact-flow-action-editor h5,
.ui.segment.contact-flow-action-editor h5 {
  color: #709dff;
}
.contact-flow-action-editor .email-header,
.ui.segment.contact-flow-action-editor .email-header {
  margin-top: 4px;
  margin-bottom: 12px;
}
.contact-flow-action-editor .template-header,
.ui.segment.contact-flow-action-editor .template-header {
  margin: 0 16px 0 0;
}
.contact-flow-action-editor .action-editor-header,
.ui.segment.contact-flow-action-editor .action-editor-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contact-flow-action-editor .detach-button,
.ui.segment.contact-flow-action-editor .detach-button {
  display: flex;
  align-items: center;
}
.contact-flow-action-editor .detach-button i,
.ui.segment.contact-flow-action-editor .detach-button i {
  font-weight: 600;
}
.contact-flow-action-editor .edit-template-button,
.ui.segment.contact-flow-action-editor .edit-template-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #709dff;
  font-size: 12px;
  font-weight: 500;
}
.contact-flow-action-editor .edit-template-button:hover span,
.ui.segment.contact-flow-action-editor .edit-template-button:hover span {
  text-decoration: underline;
}
.contact-flow-action-editor .edit-template-button i,
.ui.segment.contact-flow-action-editor .edit-template-button i {
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 1px;
}
.contact-flow-action-editor .action-template-editor,
.ui.segment.contact-flow-action-editor .action-template-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.contact-flow-action-editor .template-selector,
.ui.segment.contact-flow-action-editor .template-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-flow-action-editor .action-trigger-container,
.ui.segment.contact-flow-action-editor .action-trigger-container {
  display: flex;
  margin-bottom: 8px;
}
