/*
 * colors.less
 */
.bubble {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 232px;
  color: #fcc5cc;
}
.logos {
  display: flex;
  position: absolute;
  inset: 100px 0 0 auto;
  width: 100%;
  gap: 16px;
  justify-content: center;
}
