/*
 * colors.less
 */
.table {
  border-radius: 8px !important;
  overflow: hidden;
}
.table .header {
  background: #f8faff !important;
  border: 1px solid #dae0f4 !important;
}
.table .header .th {
  padding: 12px;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  text-transform: uppercase;
}
.table .row {
  background: #ffffff !important;
  border-bottom: 1px solid #dae0f4 !important;
}
.table .row .number {
  color: #7982ad;
  font-size: 12px;
}
.table .row .name {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
}
.table .row .subtypeContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.table .row .subtype {
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.table .row .subtype {
  color: #1f2e77;
}
.table .row .icon {
  margin: 0px 5px;
  color: #7982ad;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
}
