.skip-ratio-graph {
  width: 100%;
  padding: 35px 20px 20px 15px;
}
.skip-ratio-graph-empty {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skip-ratio-graph-empty-text {
  font-size: 15px;
  opacity: 0.3;
}
