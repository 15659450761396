/*
 * colors.less
 */
.stickyNoteContainer {
  width: 100%;
  overflow: hidden;
}
.stickyNote {
  border: 1px solid #ffe5ab;
  border-radius: 8px;
  min-width: 0px;
  width: fit-content;
  display: flex;
  font-size: 12px;
  padding: 0px 10px;
  color: #7982ad;
  cursor: pointer;
  max-width: 100%;
}
.stickyNote.editing {
  border-color: #ffbd2e;
}
.stickyNote.readOnly {
  background-color: #fff9ed;
  margin-top: 2px;
}
.stickyNote .stickyNoteText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.editIcon {
  margin-right: 6px;
}
.stickyNoteInput,
.stickyNoteInput input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent !important;
  display: flex !important;
  color: #7982ad !important;
}
.stickyNoteInput {
  padding: 0px 0px !important;
}
.stickyNoteInput input {
  padding: 0 !important;
}
.stickyNoteCounter {
  margin-left: 10px;
  cursor: default;
}
