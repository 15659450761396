@import 'src/less/colors';

.metaTaskFormContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 14px;

  .metaTaskTitle {
    color: @PrimaryCobalt;
    font-weight: 500;
    font-family: Graphik;
    width: 100%;
  }

  .helperText {
    background-color: @SecondaryBlue05;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 8px;
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 600;
    color: @PrimaryCobalt80;
  }

  .metaTaskAnswersContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .metaTaskAnswerContainer {
      flex: 1 1;

      .metaTaskSelectAnswer {
        cursor: pointer;
        background-color: @SecondaryBlue05;
        border-radius: 5px;
        border: 1px solid transparent;
        padding: 8px;
        text-align: center;
        font-family: Graphik;
        font-size: 14px;
        color: @PrimaryCobalt80;
        min-width: 150px;
        white-space: nowrap;

        &:hover {
          background-color: @SecondaryBlue10;
        }

        &.selected {
          border: 1px solid @SecondaryBlue;
        }
      }

      .metaTaskTextArea {
        display: inline-block;
      }
    }
  }
}

.classicField {
  .metaTaskTitle {
    margin-bottom: 8px;
  }
}

.metaTaskAnswer {
  flex-grow: 1;

  .metaTaskInput {
    width: 100%;
  }

  .metaTaskTextArea {
    resize: none;
  }

  .datePickerInput {
    padding: 1em;
    border-radius: 8px;
    display: inline-flex;
    height: 32px;
    align-items: center;
    padding: 0 12px;
    grid-gap: 8px;
    gap: 8px;
    border: 1px solid @SecondaryBlue20;
    background-color: white;
    border-radius: 8px;
  }
}
