/*
 * colors.less
 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .title {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
}
.toggleLabelAndTooltip {
  display: flex;
  align-items: center;
  gap: 4px;
}
.toggleLabelAndTooltip > div {
  margin-bottom: 0px;
}
.description {
  color: #7982ad;
  margin-bottom: 20px;
}
.modalForm .modalFormField {
  margin: 0px 0px 25px 0px !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.modalForm .optionWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.modalForm .optionInput {
  margin: 5px 0px;
  flex: 1;
}
.modalForm .optionInput input {
  border: 1px solid #dae0f4;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06);
  border-radius: 8px;
}
.modalForm .modalActions {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.modalForm .conditionsChainingForm {
  display: flex;
  width: 100%;
  gap: 20px;
}
.modalForm .conditionsChainingForm .left {
  flex: 1;
}
.modalForm .conditionsChainingForm .right {
  flex: 0.4;
  background: #f3f4f8;
}
.modalForm .conditionsChainingForm .padding {
  padding: 12px 24px 24px 24px;
}
.aiTokenSection {
  margin-bottom: 24px;
}
.aiTokenSection .aiTokenOptionGroupName {
  margin-bottom: 8px;
  display: block;
}
