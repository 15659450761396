@import 'src/less/colors';

.card {
  display: grid;
  grid-template-rows: auto 1fr;
  background: white;
  border: 1px solid @SecondaryBlue20;
  border-radius: 8px;

  .cardHeader {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @SecondaryBlue20;
    padding: 0 24px;

    h3 {
      white-space: nowrap;
      color: @PrimaryCobalt;
      margin: 0;
    }
  }

  .cardBody {
    padding: 24px;
  }
}

.loader {
  margin: 100px auto;
}
