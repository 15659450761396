@import 'src/less/colors';

.container {
  text-align: center;
  padding: 16px;
  gap: 16px;

  .title {
    color: @PrimaryCobalt;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .description {
    font-weight: 400;
    color: @PrimaryCobalt60;
    margin-bottom: 4px;
  }

  .ctaContainer {
    cursor: pointer;

    svg {
      width: 10px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-weight: 500;
    color: @SecondaryBlue;
  }
}
