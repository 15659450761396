@import 'src/less/colors';

.root {
  padding: 12px;
}

.block {
  margin-bottom: 24px;

  .reveal-pending-actions {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.pluginLayout {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: minmax(0, 1fr);

  .pluginMain {
    grid-row: 1;
    position: relative;
  }

  .pluginFooter {
    grid-row: 2;
  }
}

.header {
  background-color: white;
  z-index: 1;
  position: relative;

  &.minimized {
    padding: 8px;
    border-bottom: 1px solid @SecondaryBlue20;
  }
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
