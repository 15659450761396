/*
 * colors.less
 */
.card {
  background: #ffffff;
  border: 1px solid #dae0f4;
  border-radius: 8px;
  margin: 16px 0;
}
.card .header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #dae0f4;
}
.card .header .title {
  color: #1f2e77;
}
.card .content {
  padding: 24px;
}
