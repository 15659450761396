@import 'src/less/colors';

.table {
  border-radius: 8px !important;
  //overflow: hidden;

  .header {
    background: @SecondarySkyBlue05 !important;
    border: 1px solid @SecondaryBlue20 !important;

    .headerRow {
      .th:first-of-type {
        border-top-left-radius: 8px;
      }

      .th:last-of-type {
        border-top-right-radius: 8px;
      }
    }

    .th {
      font-family: 'Graphik';
      font-weight: 500;
      font-size: 14px;
      color: @PrimaryCobalt60;
    }
  }

  .row {
    background: white !important;
    border-bottom: 1px solid @SecondaryBlue20 !important;

    &:last-of-type {
      .name {
        border-bottom-left-radius: 8px;
      }

      .actions {
        border-bottom-right-radius: 8px;
      }
    }

    .actionPanel {
      width: fit-content;

      .actionPanelItem {
        span {
          margin-right: 8px;
          color: @PrimaryCobalt60;
        }
      }
    }

    .name {
      color: @SecondaryBlue !important;
      font-weight: 600 !important;
    }

    .type {
      color: @PrimaryCobalt !important;
    }

    .icon {
      margin: 0px 0px;
      color: @PrimaryCobalt60;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
