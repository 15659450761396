/*
 * colors.less
 */
.step {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  gap: 16px;
}
.step .index {
  width: 14px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #7982ad;
  text-align: center;
}
.step .icons .separator {
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  border-left: 1px solid #7982ad;
}
.step .icons .icon {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
}
.step .name {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: #4864c9;
}
