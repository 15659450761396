@import 'src/less/colors.less';

.applicationTitle {
  display: flex;
  align-items: center;
  min-width: 0;
  flex-grow: 1;

  .applicationTitleParts {
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 4px;
  }

  .applicationTitleStage {
    font-weight: 400;
    color: @PrimaryCobalt60;
    font-size: 12px;
    font-family: Graphik;
  }
}
