@import 'src/less/colors';

.container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 16px;

  .descriptionContainer {
    font-family: Graphik;
    font-size: 12px;
    line-height: 16px;

    .title {
      color: @PrimaryCobalt;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .description {
      font-weight: 400;
      color: @PrimaryCobalt60;
      margin-bottom: 4px;
    }

    .ctaContainer {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: @SecondaryBlue;
    }
  }
}
