.dynamicImportsSection {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }

    .actions {
      display: flex;
      gap: 8px;
    }
  }

  .content {
    margin-top: 24px;
    .inputsContainer {
      display: flex;
      gap: 24px;
      align-items: center;

      > div {
        flex: 1 1;
      }

      .input {
        margin-bottom: 24px;
      }
    }
  }
}
