/*
 * colors.less
 */
.container {
  background-color: #f8faff;
  padding: 32px;
  overflow-y: scroll;
  max-height: 70vh;
}
.header {
  color: #4864c9;
  font-size: 16px;
  line-height: 20px;
}
.header2 {
  color: #1f2e77;
  font-size: 14px;
  line-height: 17px;
}
.content {
  border: 1px solid #dae0f4;
  background-color: white;
}
.head {
  border-bottom: 1px solid #dae0f4;
}
.placeholder {
  margin-bottom: 16px;
}
.head,
.body {
  padding: 24px;
}
.grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 12px;
}
.label {
  color: #1f2e77;
  font-size: 13px;
  line-height: 140%;
  min-width: 100px;
  display: flex;
  align-items: center;
}
.label i {
  color: #a5abc9;
  display: inline-block;
  margin-right: 8px;
}
.option {
  background-color: #f6f7fc;
  display: inline-block;
  padding: 4px 8px;
  color: #4864c9;
  margin-right: 8px;
}
.disabled {
  opacity: 0.3;
}
.muted {
  color: #a5abc9;
}
