/*
 * colors.less
 */
.wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.wrapper .statementWrapper {
  border: 1px solid #dae0f4;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.wrapper .simplifiedStatementWrapper {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  padding-left: 0;
  flex: 1;
}
.wrapper .simplifiedStatementFieldWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.wrapper .simplifiedStatementInput {
  width: 100%;
  margin: 0 !important;
}
.wrapper .changeInputModeWrapper {
  cursor: pointer;
}
.wrapper .ifStatementHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.wrapper .andConditions {
  display: flex;
  flex-direction: column;
}
.wrapper .subConditions {
  padding-left: 150px;
}
.wrapper .button {
  align-self: flex-end;
  margin: 10px 0;
}
.wrapper .clearIcon {
  color: #7982ad;
  cursor: pointer;
}
.wrapper .input {
  flex: 1;
  border-radius: 8px !important;
  display: block;
  box-shadow: 0px 2px 4px rgba(31, 46, 119, 0.06) !important;
}
.wrapper .input input {
  border-radius: 8px !important;
  border: 1px solid #dae0f4 !important;
  width: 100% !important;
}
.wrapper .previewTitle {
  font-family: 'Gilroy';
  font-weight: 700;
  font-size: 16px;
  color: #1f2e77;
  margin-bottom: 0;
}
.wrapper .previewSubtitle {
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: #4864c9;
  display: block;
  margin: 20px 0;
}
.previewOutput {
  padding: 16px;
  background: #f1f6ff;
  border-radius: 8px;
  font-family: 'Graphik';
  font-weight: 400;
  font-size: 14px;
  color: #4864c9;
  display: flex;
  gap: 5px;
  align-items: center;
}
.statement {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px !important;
  flex: 1;
}
.statement label {
  min-width: 100px;
}
.columnStatement {
  flex-direction: column;
  align-items: unset;
  gap: 2px;
  margin-top: 10px !important;
}
.popup {
  top: -35px !important;
  left: -5px !important;
}
