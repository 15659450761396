/*
 * colors.less
 */
.infoBox {
  background-color: #f1f6ff;
  min-height: 52px;
  border-radius: 8px;
  padding: 16px;
  color: #1f2e77;
}
.infoBox .title {
  color: #1f2e77;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.infoBox .description {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7982ad;
}
