/*
 * colors.less
 */
.background-toggle {
  cursor: pointer;
  color: #7982ad;
}
.background-toggle:hover {
  text-decoration: underline;
}
.background-toggle.down:after {
  content: '';
  background-image: url('/images/icons/dark-down-caret.png');
  background-size: 8px 5px;
  width: 8px;
  height: 5px;
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
  opacity: 1;
  display: inline-block;
}
.background-toggle.up:after {
  content: '';
  background-image: url('/images/icons/dark-up-caret.png');
  background-size: 8px 5px;
  width: 8px;
  height: 5px;
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
  opacity: 1;
  display: inline-block;
}
