/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * colors.less
 */
.bulk-action-controls {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}
.bulk-action-controls .left-controls,
.bulk-action-controls .right-controls {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.bulk-action-controls .left-controls > .ui.dropdown,
.bulk-action-controls .right-controls > .ui.dropdown,
.bulk-action-controls .left-controls > .ui.button,
.bulk-action-controls .right-controls > .ui.button,
.bulk-action-controls .left-controls > .button-hover-wrapper,
.bulk-action-controls .right-controls > .button-hover-wrapper {
  white-space: nowrap;
}
.bulk-action-controls .left-controls > .ui.dropdown:first-child,
.bulk-action-controls .right-controls > .ui.dropdown:first-child,
.bulk-action-controls .left-controls > .ui.button:first-child,
.bulk-action-controls .right-controls > .ui.button:first-child,
.bulk-action-controls .left-controls > .button-hover-wrapper:first-child,
.bulk-action-controls .right-controls > .button-hover-wrapper:first-child {
  margin-left: 0;
}
.bulk-action-controls .left-controls > .ui.dropdown:last-child,
.bulk-action-controls .right-controls > .ui.dropdown:last-child,
.bulk-action-controls .left-controls > .ui.button:last-child,
.bulk-action-controls .right-controls > .ui.button:last-child,
.bulk-action-controls .left-controls > .button-hover-wrapper:last-child,
.bulk-action-controls .right-controls > .button-hover-wrapper:last-child {
  margin-right: 0;
}
.bulk-action-controls .left-controls > .ui.dropdown .ui.button,
.bulk-action-controls .right-controls > .ui.dropdown .ui.button,
.bulk-action-controls .left-controls > .ui.button .ui.button,
.bulk-action-controls .right-controls > .ui.button .ui.button,
.bulk-action-controls .left-controls > .button-hover-wrapper .ui.button,
.bulk-action-controls .right-controls > .button-hover-wrapper .ui.button {
  margin: 0;
}
.bulk-action-button.ui.button {
  white-space: nowrap;
  padding: 5px 15px;
  height: 32px;
  margin-right: 6px;
  line-height: 20px;
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 14px;
}
.bulk-action-button.ui.button i {
  vertical-align: top;
}
.bulk-action-button.ui.button .stack-icon {
  margin-right: 0.5em;
}
.enable-popups-screenshot {
  margin-top: 24px;
  width: 100%;
}
