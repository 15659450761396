@import 'src/less/colors';

.visual {
  position: absolute;
  inset: 0;
  background-color: @PrimaryCobalt;
  display: flex;
  align-items: center;
  justify-content: center;
}
