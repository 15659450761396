/*
 * colors.less
 */
.viewport {
  display: grid;
  min-height: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
}
.header {
  grid-row: 1;
  grid-column: 1 / span 2;
  height: 72px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  background-color: white;
  border-bottom: 1px solid #dae0f4;
}
.header .headerTabs {
  display: flex;
  gap: 32px;
  align-items: center;
}
.header .title {
  margin: 0;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
.header .title:not(.selected) {
  color: #7982ad;
}
.header .title.selected {
  color: #1f2e77;
}
.sidebar {
  grid-row: 2;
  grid-column: 1;
  width: 320px;
  padding: 24px;
  border-right: 1px solid #dae0f4;
  box-shadow: 2px 0px 4px 0px #1f2e770f;
}
.sidebar .title {
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #1f2e77;
}
.sidebar .searchInput {
  width: 100%;
}
.sidebar .filters {
  margin: 16px 0;
}
.main {
  grid-row: 2;
  grid-column: 2;
  padding: 24px 40px;
}
