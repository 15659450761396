@import 'src/less/colors';

.title {
  font-size: 22px;
  font-weight: 700;
  font-family: Gilroy;
  color: @PrimaryCobalt;
  margin-bottom: 8px;
}

.linkedATSUserContainer {
  display: flex;
  width: 100%;
  align-items: center;

  .linkedUser {
    flex-grow: 1;
    font-family: Graphik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: @PrimaryCobalt60;
  }

  .removeLinkedUser {
    margin-left: 12px;

    svg {
      color: @PrimaryCobalt60;
      cursor: pointer;
    }
  }
}
