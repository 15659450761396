/*
 * colors.less
 */
.ui.button.download-button {
  background-color: transparent;
  color: #4c5892;
  padding: 2px 0px 0px 4px;
}
.ui.button.download-button.disabled {
  color: #a5abc9;
}
