.buttonGroup {
  display: inline-flex;
  align-items: center;
}
.buttonGroup .buttonInGroup:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid white;
}
.buttonGroup .buttonInGroup:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
