/*
 * colors.less
 */
.createMetaTaskAndPreviewFormContainer {
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #e2ebff;
}
.createMetaTaskAndPreviewFormContainer > div {
  flex: 1 1;
  padding: 12px 24px;
}
.createMetaTaskAndPreviewFormContainer .fieldLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #404564;
}
.createMetaTaskAndPreviewFormContainer .previewContainer {
  flex: 1 1;
  padding: 12px 24px;
  border-left: 1px solid #e2ebff;
}
.createMetaTaskAndPreviewFormContainer .previewContainer .previewTitle {
  text-align: center;
  color: #1f2e77;
  font-family: Graphik;
}
.modalFooter:global(.actions) {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  color: red;
}
