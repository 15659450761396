/*
 * colors.less
 */
.pill {
  display: inline-flex;
  padding: 0px 8px;
  align-items: center;
  font-size: 10px;
  border-radius: 1em;
  background-color: #edf0fa;
  color: #4864c9;
  gap: 4px;
}
