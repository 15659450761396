/*
 * colors.less
 */
.settings-container .legal-settings {
  flex-grow: 1;
}
.settings-container .settings-header h1 {
  font-weight: 700;
  font-size: 26px;
  color: #1f2e77;
  flex-grow: 1;
}
.settings-container .content {
  margin: 26px 0px;
  background-color: white;
  width: 100%;
  border-radius: 4px;
  padding: 24px;
  border: 1px solid #dae0f4;
}
.settings-container .content .description {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
}
.settings-container .content .links {
  margin-top: 12px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.settings-container .content .links a {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #4864c9 !important;
}
.settings-container .content .links a i {
  margin-left: 10px;
}
