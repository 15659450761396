/*
 * colors.less
 */
.dropdown {
  padding: 0 !important;
}
.mainButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.scheduleButton {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid white;
}
.dropdownLeft {
  left: 100% !important;
  transform: translateX(-100%);
}
