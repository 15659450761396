@import 'variables';

[class^='custom-icon-'],
[class*=' custom-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-icon-sms-task {
  &:before {
    content: @custom-icon-sms-task;
  }
}
.custom-icon-manual-task {
  &:before {
    content: @custom-icon-manual-task;
  }
}
