@import 'src/less/colors';

.container {
  padding: 24px;

  .emptyState {
    text-align: center;
    margin: 100px auto;
    font-size: 15px;
    opacity: 0.3;
  }
}
