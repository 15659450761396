.two-column-layout {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.two-column-layout .column-1 {
  flex-grow: 1;
}
@media screen and (min-width: 1350px) {
  .two-column-layout .column-1 {
    margin-right: 42px;
  }
}
.two-column-layout .column-2 {
  flex: 1 0 330px;
}
@media screen and (min-width: 1350px) {
  .two-column-layout .column-2 {
    max-width: 330px;
  }
}
@media screen and (min-width: 1445px) {
  .two-column-layout .column-2 {
    max-width: 400px;
  }
}
