@import 'src/less/colors';

.benchmark {
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid @SecondaryBlue20;
  box-shadow: 0px 2px 4px 0px @PrimaryCobaltOp06;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
}

.title {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;

  color: @PrimaryCobalt;
}

.partnership {
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 4px;
  gap: 6px;

  border-radius: 8px;
  background-color: @SecondaryBlue05;
  color: @PrimaryCobalt60;

  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  .logo {
    height: 24px;
    width: 24px;
    border-radius: 8px;
  }

  .link {
    color: @SecondaryBlue;
    font-weight: 500;
    text-decoration: underline;
  }
}

.legend {
  display: flex;
  color: @PrimaryCobalt40;

  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  .left {
    flex: 1;
    text-align: left;
  }

  .right {
    flex: 1;
    text-align: right;
  }
}

.graph {
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: 21px;
}

.bar {
  z-index: 1;
  height: 6px;
  min-width: 80px;
}

.handle {
  margin: 0 -6px;
  z-index: 2;
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 100%;
}

.upperLabel {
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  color: @PrimaryCobalt40;

  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  white-space: nowrap;
}

.label {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: @PrimaryCobalt40;

  font-family: Graphik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  white-space: nowrap;

  .tooltip {
    color: inherit;
  }
}

.first {
  background-color: #a8b5af;
}

.second {
  background-color: #367c3e;

  &.handle {
    height: 16px;
    width: 16px;
    border: 2px solid white;
    .label {
      color: @PrimaryCobalt;
      font-weight: 500;
    }
  }
}

.third {
  background-color: #82d69f;
}

.fourth {
  background-color: #fee2e5;
}
