/*
 * colors.less
 */
.message {
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #7982ad;
  margin-top: 2px;
  margin-bottom: 8px;
}
.address {
  font-weight: 500;
}
.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}
.action {
  display: inline-flex;
  padding: 0;
  align-items: center;
  font-family: Graphik;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  gap: 4px;
}
.action:not(:disabled) {
  color: #4864c9;
  cursor: pointer;
}
.action:not(:disabled):hover {
  text-decoration: underline;
}
.action:disabled {
  color: #a5abc9;
}
.action .icon {
  font-size: 16px;
}
