/*
 * utils.less
 */
/*
 * colors.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
.relevant-activities {
  border-style: solid;
  border-width: 1px;
  border-color: #e7e9eb;
  margin-bottom: 7px !important;
  padding: 15px 15px 15px 15px !important;
}
.relevant-activities h3 {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
  margin: 0px;
}
.relevant-activities .content {
  transition: height 0.1s ease;
  overflow: hidden;
  font-family: Graphik;
  font-size: 14px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
}
