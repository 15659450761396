@import 'src/less/colors';
@import 'src/less/fonts';

.actionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: @SecondarySkyBlue10;
  border-radius: 8px;
  margin: 8px 24px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: @PrimaryCobalt;
  line-height: 20px;
}

.description {
  margin-top: 4px;
  color: @PrimaryCobalt;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.button:global(.ui.button) {
  height: 32px;
  border-radius: 2px;
  padding: 0 12px;
  flex-shrink: 0;
  margin-left: 24px;
  > i {
    margin-top: -5px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
  }
}
