/*
 * colors.less
 */
.header {
  display: flex;
  align-items: center;
  color: #7982ad;
  height: 54px;
  padding: 18px 24px;
  background-color: #f8faff;
  border-bottom: 1px solid #dae0f4;
}
.header .cell {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #7982ad;
}
.header .cell:first-child {
  flex: 1;
}
.header .cell:not(:first-child) {
  width: 150px;
  text-align: center;
}
