@import 'src/less/colors';

.subHeader {
  height: 72px;
  display: flex;
  border-bottom: 1px solid @PrimaryCobalt20;
  background-color: white;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .title {
    margin: 0;
    padding: 0;
    font-family: Gilroy;
    font-weight: 700;
    font-size: 22px;
    color: @PrimaryCobalt;
  }

  .subtypes {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 24px;

    .subtype {
      line-height: 1em;
      list-style: none;

      button {
        font-family: Gilroy;
        font-weight: 700;
        font-size: 22px;
        color: @PrimaryCobalt40;
        cursor: pointer;
        &:hover {
          color: @SecondaryBlue80;
        }
      }

      &.selected {
        button {
          color: @PrimaryCobalt;
        }
      }
    }
  }
}
