@import 'src/less/colors';

.container {
  width: 240px;
  margin: 16px 0;
}

.dropdown {
  width: 100%;
}

.dropdownTrigger {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.title {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: @PrimaryCobalt;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  font-family: Gilroy;
  font-size: 16px;
  color: @PrimaryCobalt;
}

.actions {
  margin: 8px 0;
  min-height: 16px;
}

.action {
  display: block;
  margin: 4px 0;
  padding: 0;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  &:not(:disabled) {
    color: @SecondaryBlue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@options-displayed: 8.7;
@option-height: 40px;
@padding-y: 8px;

.menu {
  padding: @padding-y 0;
  // Leave a padding height at the end to indicate there are more elements than displayed
  max-height: @options-displayed * @option-height + @padding-y;

  overflow-y: auto;
}

.menuItem {
  position: relative;

  .menuItemSelect {
    padding: 10px 12px;
    padding-right: 40px;
  }

  .menuItemDelete {
    display: none;

    position: absolute;
    right: 8px;
    top: 4px;
  }

  &:hover {
    .menuItemDelete {
      display: block;
    }
  }
}
