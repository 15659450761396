/*
 * utils.less
 */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide-scrollbars {
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
.hide-scrollbars ::-webkit-scrollbar {
  display: none;
}
.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.generic-form-style {
  font-family: Graphik;
  color: #2b3d4f;
}
.generic-form-style .field {
  margin-bottom: 10px;
}
/*
 * mixins.less
 */
.Tag {
  background: #1f2e77;
  color: #f8f8f8;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: #4864c9;
  padding-left: 14px;
  padding-right: 14px;
  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}
.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}
.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}
.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}
.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: #1f2e77;
}
.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1f2e77;
}
.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DefaultTableCells tbody tr td {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
}
.PipelineTableCells tbody tr td {
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.DefaultTableHeader thead th {
  color: #a5abc9;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
}
.DefaultTableHeader thead th:first-letter {
  text-transform: capitalize;
}
.DefaultFirstColumn tbody > tr > td:first-child {
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #4c5892;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.HoverableRows tbody tr:hover {
  background: #fafafa;
}
.UppercaseHeader thead th {
  height: 25px;
  color: #4c5892;
  text-transform: uppercase;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
}
.LargeFormInput {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput .large-form-input {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.LargeFormInput:focus {
  outline: None;
  font-family: Graphik, sans-serif;
  box-shadow: 0 1px 9px 0 #10173d;
  display: block;
  width: 100%;
  height: 52px;
  background-color: #fff;
  padding: 16px 26px;
  border-radius: 0;
  border: none;
}
.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
}
.PillMessage.small {
  padding: 3px 8px;
}
.PillMessage.mini {
  padding: 0px 8px;
}
.PillMessage.mini i {
  vertical-align: text-bottom;
  margin-right: 2px;
}
.PillMessage.spaced {
  margin-left: 8px;
}
.PillMessage.green {
  background-color: #28ca421a;
  color: #14b62e;
}
.PillMessage.red {
  background-color: #fee2e5;
  color: #ff3930;
}
.PillMessage.yellow {
  background-color: #eba91a1a;
  color: #eba91a;
}
.PillMessage.grey {
  background-color: #e9ebf2;
  color: #7982ad;
}
.PillMessage.orange {
  background-color: #fff1c6;
  color: #eba91a;
}
.PillMessage.blue {
  background-color: #e2ebff;
  color: #5c89eb;
}
/*
 * colors.less
 */
.resume-data-card {
  font-size: 14px;
  color: #1d1e21;
}
.resume-data-card.v4 {
  padding-top: 0;
}
.resume-data-card.v4 .profile-header-container {
  width: 100%;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #dae0f4;
  margin-bottom: 16px;
}
.resume-data-card .profile-header {
  flex: 1;
  min-width: 0;
  margin: 0 !important;
  padding: 0 !important;
}
.resume-data-card .profile-header.with-bottom-margin {
  margin-bottom: 26px !important;
}
.resume-data-card .profile-header .avatar-and-infos {
  display: flex;
  flex-flow: row nowrap;
}
.resume-data-card .profile-header .avatar-and-infos .avatar {
  position: relative;
  margin-right: 20px !important;
  min-width: 96px !important;
  min-height: 96px !important;
  width: 96px !important;
}
.resume-data-card .profile-header .avatar-and-infos .avatar img {
  width: 96px;
  height: 96px;
}
.resume-data-card .profile-header .avatar-and-infos .avatar .header-annotations {
  position: absolute;
  left: 0;
  top: 0;
}
.resume-data-card .profile-header .avatar-and-infos .avatar .source-indicator {
  z-index: 10;
  position: absolute;
  top: 68px;
  left: 68px;
  background-color: #709dff;
  height: 22px;
  min-width: 22px;
  border-radius: 11px;
  border: 2px solid white;
  box-sizing: border-box;
}
.resume-data-card .profile-header .avatar-and-infos .avatar .source-indicator i {
  position: absolute;
  top: 3.5px;
  left: 3px;
}
.resume-data-card .profile-header .avatar-and-infos .avatar .source-indicator span.prequalified-label {
  position: absolute;
  left: 24px;
  color: white;
  font-size: 12px;
}
.resume-data-card .profile-header .avatar-and-infos .avatar .source-indicator.hover {
  line-height: 18px;
  width: 115px;
}
.resume-data-card .profile-header .avatar-and-infos .avatar .source-indicator.career-page {
  background-color: #28ca42;
}
.resume-data-card .profile-header .avatar-and-infos .infos {
  flex: 1 0;
  min-width: 0;
}
.resume-data-card .profile-header .avatar-and-infos .infos .fullname {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #4864c9;
}
.resume-data-card .profile-header .avatar-and-infos .infos .fullname.unbiased {
  text-shadow: 0 0 10px #4864c9;
  color: transparent;
}
.resume-data-card .profile-header .avatar-and-infos .infos .fullname.unbiased:hover {
  text-shadow: 0 0 0px #000;
  color: #4864c9;
}
.resume-data-card .profile-header .avatar-and-infos .infos .fullname i {
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;
}
.resume-data-card .profile-header .avatar-and-infos .infos .fullname i.copied {
  color: #a5abc9;
}
.resume-data-card .profile-header .avatar-and-infos .infos .headline {
  margin-bottom: 4px;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #1f2e77;
}
.resume-data-card .profile-header .avatar-and-infos .infos .headline:not(.watch-profile) {
  margin-bottom: 6px;
}
.resume-data-card .profile-header .avatar-and-infos .infos .main-education,
.resume-data-card .profile-header .avatar-and-infos .infos .phone-number {
  margin-bottom: 0px;
  margin-left: 6px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #7982ad;
  margin-top: 0px;
}
.resume-data-card .profile-header .avatar-and-infos .infos .main-education.phone-number,
.resume-data-card .profile-header .avatar-and-infos .infos .phone-number.phone-number {
  font-size: 13px;
}
.resume-data-card .profile-header .avatar-and-infos .infos .main-education.phone-number.reveal-profile,
.resume-data-card .profile-header .avatar-and-infos .infos .phone-number.phone-number.reveal-profile {
  font-size: 12px;
  margin-left: 0px;
  margin-bottom: 4px;
}
.resume-data-card .profile-header .avatar-and-infos .infos .main-education.main-education,
.resume-data-card .profile-header .avatar-and-infos .infos .phone-number.main-education {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resume-data-card .profile-header .avatar-and-infos .infos .main-education i.ri-phone-fill,
.resume-data-card .profile-header .avatar-and-infos .infos .phone-number i.ri-phone-fill {
  vertical-align: bottom;
}
.resume-data-card .profile-header .avatar-and-infos .infos .sources-container {
  align-items: center;
  margin-bottom: 8px;
}
.resume-data-card .profile-header .add-attachment-dropdown {
  white-space: nowrap;
}
.resume-data-card .profile-header .add-attachment-dropdown button {
  white-space: nowrap;
}
.resume-data-card .profile-header .add-attachment-dropdown i.dropdown.icon {
  margin-left: 0;
}
.resume-data-card .pending-extended-warning {
  display: flex;
  padding: 8px;
  gap: 8px;
  align-items: center;
  font-family: Graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #bd8508;
  background-color: #fff9ed;
}
.resume-data-card .change-profile-stage.dropdown {
  min-width: 140px !important;
  max-width: 150px !important;
  font-size: 1rem !important;
  padding: 8px !important;
  padding-left: 2rem !important;
  margin-top: 8px;
  color: #7982ad !important;
  border-color: #7982ad !important;
  min-height: auto !important;
  font-family: Gilroy !important;
}
.resume-data-card .change-profile-stage.dropdown .icon,
.resume-data-card .change-profile-stage.dropdown .text {
  font-weight: 700 !important;
}
.resume-data-card .change-profile-stage.dropdown .icon {
  padding: 8px 0.75rem !important;
}
.resume-data-card .change-profile-stage.dropdown .menu {
  border: none;
}
.resume-data-card .change-profile-stage.dropdown .menu .item .text {
  color: #7982ad !important;
  font-size: 12px;
  font-family: Graphik;
  font-weight: 600 !important;
}
.resume-data-card .profile-stage-selector .menu.transition.visible,
.resume-data-card .profile-step-selector .menu.transition.visible {
  top: 30px !important;
  width: 140px !important;
  max-width: 150px !important;
  min-width: 100px !important;
}
.resume-data-card .profile-step-selector:nth-child(2) {
  margin-top: 12px;
}
.resume-data-card button.open-skip-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding: 8px;
  width: 140px;
}
.resume-data-card button.open-skip-modal .button-text {
  flex-grow: 1;
}
.resume-data-card .summary-tag-stack-container .stack-container {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}
.resume-data-card .summary-tag-stack-container .stack-container .summary-title {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.resume-data-card .summary-tag-stack-container .stack-container .summary-title .summary-subtitle {
  font-size: 14px;
  font-family: Graphik;
}
.resume-data-card .summary-tag-stack-container .stack-container .stack .technology-icon {
  cursor: pointer;
  display: inline-block;
  max-width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle !important;
}
.resume-data-card .summary-tag-stack-container .stack-container .stack .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}
.resume-data-card .summary-tag-stack-container .stack-container .stack .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  font-family: Graphik;
  padding-right: 2px;
  padding-left: 2px;
}
.resume-data-card .summary-tag-stack-container .profile-summary {
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px !important;
  white-space: pre-line;
  color: #4c5892;
}
.resume-data-card .profile-hotness {
  margin-bottom: 18px;
  vertical-align: middle;
}
.resume-data-card .profile-hotness .uploaded-days-hot {
  color: orange;
  opacity: 1;
}
.resume-data-card .profile-hotness .uploaded-days-cold {
  line-height: 18px;
}
.resume-data-card .profile-hotness .emoji-image {
  margin-bottom: 4px;
  opacity: 1 !important;
  height: 18px !important;
  width: 18px !important;
}
.resume-data-card .attachments-container {
  margin: 18px -4px;
  display: flex;
  flex-wrap: wrap;
}
.resume-data-card .step-modification-view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.hired-card {
  box-shadow: none !important;
  border: 1px solid #dae0f4 !important;
}
.skill-popup {
  box-shadow: none !important;
}
.skill-popup .skill-name {
  font-size: 14px;
  font-weight: 600;
  color: #1f2e77;
}
.skill-popup .skill-description {
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
}
.skill-popup .has-details {
  min-width: 220px;
}
.profile-documents {
  margin-top: 18px;
}
.profile-documents .no-attachments {
  border: 1px solid #e2ebff;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
  color: #7982ad;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.profile-documents-modal {
  z-index: 10;
}
