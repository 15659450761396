/*
 * colors.less
 */
.linkedProfileSwitchButton {
  background-color: #e2ebff !important;
  border-radius: 15px;
  padding: 3px;
  padding-left: 14px;
  padding-right: 14px;
}
.linkedProfileSwitchButton span {
  color: #4864c9;
  font-family: 'Graphik', 'Gilroy';
  font-size: 13px;
  font-weight: 500;
}
.linkedProfileSwitchButton svg {
  color: #4864c9;
}
