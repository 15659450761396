/*
 * colors.less
 */
.container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b1a6380;
  cursor: pointer;
  z-index: 1000;
}
.modal {
  width: 90%;
  height: 70%;
  max-height: 1024px;
  max-width: 1080px;
  border-radius: 16px;
  overflow: hidden;
  cursor: default;
}
.layout {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.illustration {
  position: relative;
}
.right {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  height: 100%;
  overflow: hidden;
}
.content {
  overflow-y: auto;
}
.content h1 {
  color: #1f2e77;
  font-weight: 700;
  font-family: Gilroy;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}
.content p {
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: #7982ad;
  margin: 24px 0;
}
.header {
  margin-bottom: 24px;
}
.footer {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
