@import 'src/less/colors';

.inputContainer {
  margin-bottom: 24px;

  .inputLabel {
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 14px;
    color: @PrimaryCobalt;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .atsLogo {
    width: 20px;
    height: auto;
    border-radius: 4px;
  }

  .recommendation {
    padding: 4px 8px;
    background-color: @SecondarySkyBlue20;
    border-radius: 80px;
    font-family: 'Graphik';
    font-weight: 600;
    font-size: 12px;
    color: @SecondaryBlue;
  }
}

.departmentsContainer {
  margin-bottom: 24px;
}

.actions {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
