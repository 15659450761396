@import 'src/less/colors.less';

.callout {
  border: 1px solid @SecondarySkyBlue20;
  border-radius: 8px;

  &.borderless {
    border: none;
  }

  &.primary {
    padding: 24px;
    background-color: @SecondarySkyBlue10;
  }

  &:last-of-type {
    margin-bottom: 24px;
  }
}

.title {
  color: @PrimaryCobalt;
  font-size: 16px;
  font-family: 'Gilroy', sans-serif;
  margin: 16px 0px 16px 0px !important;
}

.calloutInfoLine {
  display: grid;
  grid-template-columns: 15px 0.3fr 0.7fr;

  font-size: 12px;
  font-family: Graphik, sans-sefir;
  font-weight: 500;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .calloutInfoLineIcon {
    font-size: 1rem;
    padding-top: 3px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;

    svg,
    svg *,
    i,
    i * {
      fill: @PrimaryCobalt40;
      color: @PrimaryCobalt40;
      height: 14px;
    }
  }

  .calloutInfoLineTitle {
    margin-left: 10px;
    color: @PrimaryCobalt60;
  }

  .calloutInfoLineInfo {
    color: @PrimaryCobalt;
  }
}
