/*
 * colors.less
 */
.accordion-filter.ui.accordion,
.basic-filter {
  padding: 16px 0px 16px 0px;
  border: none;
  border-bottom: 1px solid #dae0f4;
  box-shadow: none;
  background-color: transparent;
  margin: 0;
  border-radius: 0px;
}
.accordion-filter.ui.accordion .delay-count-input input,
.basic-filter .delay-count-input input {
  border: 2px solid transparent !important;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.accordion-filter.ui.accordion .delay-count-input input:hover,
.basic-filter .delay-count-input input:hover,
.accordion-filter.ui.accordion .delay-count-input input:active,
.basic-filter .delay-count-input input:active,
.accordion-filter.ui.accordion .delay-count-input input:focus,
.basic-filter .delay-count-input input:focus {
  border: 2px solid #c6d8ff !important;
}
.accordion-filter.ui.accordion .accordion-filter-content .dropdown:not(.icon),
.basic-filter .accordion-filter-content .dropdown:not(.icon) {
  border: 2px solid transparent;
  box-shadow: 0px 2px 4px 0px #1f2e770f;
}
.accordion-filter.ui.accordion .accordion-filter-content .dropdown:not(.icon) .dropdown.icon,
.basic-filter .accordion-filter-content .dropdown:not(.icon) .dropdown.icon {
  border: none !important;
}
.accordion-filter.ui.accordion .accordion-filter-content .dropdown:not(.icon):hover,
.basic-filter .accordion-filter-content .dropdown:not(.icon):hover {
  border: 2px solid #c6d8ff;
}
.accordion-filter.ui.accordion .accordion-filter-content .dropdown:not(.icon) .menu,
.basic-filter .accordion-filter-content .dropdown:not(.icon) .menu {
  border: 2px solid #c6d8ff;
}
.accordion-filter.ui.accordion .accordion-filter-title,
.basic-filter .accordion-filter-title,
.accordion-filter.ui.accordion .basic-filter-title,
.basic-filter .basic-filter-title {
  cursor: pointer;
  padding: 0;
  color: #1f2e77;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.accordion-filter.ui.accordion .accordion-filter-title .title,
.basic-filter .accordion-filter-title .title,
.accordion-filter.ui.accordion .basic-filter-title .title,
.basic-filter .basic-filter-title .title {
  display: inline-block;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  padding: 0;
}
.accordion-filter.ui.accordion .accordion-filter-title .title h4,
.basic-filter .accordion-filter-title .title h4,
.accordion-filter.ui.accordion .basic-filter-title .title h4,
.basic-filter .basic-filter-title .title h4 {
  margin: 0;
  display: inline-block;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  vertical-align: middle;
}
.accordion-filter.ui.accordion .input-label,
.basic-filter .input-label {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #1f2e77;
  margin-bottom: 4px;
}
.accordion-filter.ui.accordion .ui.label,
.basic-filter .ui.label {
  padding: 0em 4px 0em 4px;
  margin: 0px 0px 0px 4px;
  vertical-align: middle;
  color: #4864c9;
  background-color: #e2ebff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}
.accordion-filter.ui.accordion .filter-count-label,
.basic-filter .filter-count-label {
  display: inline-block;
  margin-left: 12px;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: white;
  background-color: #4864c9;
  height: 20px;
  border-radius: 10px;
  padding: 0 10px;
}
.accordion-filter.ui.accordion .content,
.basic-filter .content {
  padding-top: 16px !important;
}
.accordion-filter.ui.accordion .ui.accordion.compact,
.basic-filter .ui.accordion.compact {
  margin-top: 12px;
}
.accordion-filter.ui.accordion .ui.accordion.compact .title,
.basic-filter .ui.accordion.compact .title {
  color: #4864c9;
}
.accordion-filter.ui.accordion .ui.accordion.compact .title i,
.basic-filter .ui.accordion.compact .title i {
  width: 16px;
  height: 16px;
}
.accordion-filter.ui.accordion .ui.accordion.compact .title i:last-of-type,
.basic-filter .ui.accordion.compact .title i:last-of-type {
  margin-left: 4px;
}
.accordion-filter.ui.accordion .ui.accordion.compact .content,
.basic-filter .ui.accordion.compact .content {
  padding-top: 7px !important;
}
