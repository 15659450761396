.timeSelectorDropdown {
  display: flex;
  align-items: center;
  gap: 8px;

  .dropdown {
    // Dropdown actual div
    > div {
      font-size: 12px;

      // Dropdown container
      > div:first-child {
        padding: 0;
        padding-right: 20px;
      }
    }
  }
}
