.footer {
  padding: 8px 12px;
  text-align: right;
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .languageDropdown {
    width: 100px !important;
    min-width: 100px !important;
    color: #7982ad !important;
    font-family: Graphik, sans-serif !important;
    border: none !important;
    font-size: 12px !important;
  }

  :global(.ui.upward.selection.dropdown .menu) {
    border: none !important;
  }

  :global(.ui.selection.dropdown .menu > .item) {
    font-size: 12px !important;
  }
}
