/*
 * mixins.less
 */

@import './colors';

.Tag {
  background: @PrimaryColor;
  color: @White;
  font-family: Gilroy;
  font-weight: 600;
  margin-right: 3px;
  padding-top: 4px;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}

.Label {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
  background: @PrimaryColorLight;
  padding-left: 14px;
  padding-right: 14px;

  line-height: 26px;
  font-family: Graphik;
  letter-spacing: 0.5px;
  font-size: 13px;
  color: white;
}

.Button(@color: @PrimaryColorLight; @backgroundColor: #ffffff) {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid @color;
  font-family: Graphik;
  color: @color;
  background-color: @backgroundColor;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
}

.button-attributes {
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  transition: 150ms;
  justify-content: center;
}

.without-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}

.with-border-sizing {
  height: 32px;
  line-height: 20px;
  padding: 5px 15px;
}

.FilledButton(@color: white; @backgroundColor: @PrimaryColorLight; @activeColor: @PrimaryColor) {
  .button-attributes();
  .without-border-sizing();
  background-color: @backgroundColor;
  color: @color;

  &:hover,
  &:focus {
    .button-attributes();
    .without-border-sizing();
    background-color: @backgroundColor;
    color: @color;
  }

  &:active {
    .button-attributes();
    .without-border-sizing();
    background-color: @activeColor;
  }
}

.BorderedButton(@color: @PrimaryColorLight; @activeColor: @PrimaryColor) {
  .button-attributes();
  .with-border-sizing();
  background-color: white;
  color: @color;
  border: 1px solid @color;

  &:hover,
  &:focus {
    .button-attributes();
    .with-border-sizing();
    background-color: white;
    color: @color;
    border: 1px solid @color;
  }

  &:active {
    .button-attributes();
    .with-border-sizing();
    background-color: white;
    color: @activeColor;
    border: 1px solid @activeColor;
  }
}

.BicolorBorderedButton(
  @color: @PrimaryColorLight;
  @borderColor: rgba(34,36,38,0.15);
  @borderHoverColor: rgba(34,36,38,0.35);
  @activeColor: @PrimaryColor
) {
  .button-attributes();
  .with-border-sizing();
  background-color: white;
  color: @color;
  border: 1px solid @borderColor;

  &:hover,
  &:focus {
    .button-attributes();
    .with-border-sizing();
    background-color: white;
    color: @color;
    border: 1px solid @borderHoverColor;
  }

  &:active {
    .button-attributes();
    .with-border-sizing();
    background-color: white;
    color: @activeColor;
    border: 1px solid @activeColor;
  }
}

.PageTitle {
  font-family: Gilroy;
  font-weight: 600;
  font-size: 25px;
  color: @PrimaryColor;
}

.PageHeader {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: @PrimaryColor;
}

.TextEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@1pxlightgrayborder: 1px solid @PrimaryCobalt05;
.BorderLessHeaderTable(@border-style: @1pxlightgrayborder) {
  border: none;

  thead th,
  tbody td {
    padding: 16px 10px 16px 0;
  }

  tbody tr td {
    text-align: left;
  }

  thead,
  thead tr,
  thead tr th {
    border: none;
  }

  tbody,
  tbody tr {
    border: none;
  }

  tbody td {
    border-top: @border-style;
    border-bottom: none;
  }

  tbody td:first-child {
    border-left: none;
  }

  tbody td:last-child {
    border-right: none;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }
}

.DefaultTableCells {
  tbody tr td {
    font-family: Graphik, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    color: @PrimaryCobalt80;
  }
}

.PipelineTableCells {
  tbody tr td {
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
  }
}

.DefaultTableHeader {
  thead {
    th {
      color: @PrimaryCobalt40;
      font-family: Graphik, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      text-align: center;
      white-space: nowrap;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.DefaultFirstColumn {
  tbody > tr > td:first-child {
    font-family: Graphik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;

    color: @PrimaryCobalt80;
    white-space: nowrap;

    .TextEllipsis();
  }
}

.HoverableRows {
  tbody tr {
    &:hover {
      background: #fafafa;
    }
  }
}

.UppercaseTableHeader() {
  thead th {
    font-family: Graphik, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 1px;
    text-align: left;
    color: @PrimaryCobalt40;
  }
}

.UppercaseHeader {
  thead {
    th {
      height: 25px;
      color: @PrimaryCobalt80;
      text-transform: uppercase;
      font-family: Graphik, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
    }
  }
}

.HoverButton(@color: @PrimaryColorLight; @hoverColor: @PrimaryColorLight) {
  .button-attributes();
  .with-border-sizing();
  color: @color;
  border: 1px solid @color;
  background-color: white;

  &:hover {
    color: white;
    background: @hoverColor;
    border: 1px solid @hoverColor;
  }

  &:active {
    color: @hoverColor;
    background: white;
    border: 1px solid @hoverColor;
  }
}

.LargeFormInput {
  .large-form-input {
    outline: None;
    font-family: Graphik, sans-serif;
    box-shadow: 0 1px 9px 0 #10173d;
    display: block;
    width: 100%;
    height: 52px;
    background-color: #fff;
    padding: 16px 26px;
    border-radius: 0;
    border: none;
  }

  .large-form-input();

  &:focus {
    .large-form-input();
  }
}

.PillMessage {
  padding: 0.4em 0.75em;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;

  &.small {
    padding: 3px 8px;
  }

  &.mini {
    padding: 0px 8px;

    i {
      vertical-align: text-bottom;
      margin-right: 2px;
    }
  }

  &.spaced {
    margin-left: 8px;
  }

  &.green {
    background-color: @PositiveColor10;
    color: @PositiveColor120;
  }

  &.red {
    background-color: @SecondaryRed20;
    color: @NegativeColor120;
  }

  &.yellow {
    background-color: @WarningColor10;
    color: @WarningColor120;
  }

  &.grey {
    background-color: @PrimaryCobalt10;
    color: #7982ad;
  }

  &.orange {
    background-color: #fff1c6;
    color: #eba91a;
  }

  &.blue {
    background-color: @SecondarySkyBlue20;
    color: @SecondarySkyBlue120;
  }
}
