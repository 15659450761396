/*
 * colors.less
 */
.heading1 {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}
.headingActions {
  justify-self: flex-end;
}
.customFieldRow {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.5rem;
}
.customFieldRow:last-child {
  margin-bottom: 1rem;
}
.customFieldLabel {
  color: #7982ad;
  font-family: Graphik, sans-serif;
  width: 160px;
}
.customFieldValue {
  color: #1f2e77;
  font-family: Graphik, sans-serif;
}
.companyGlobalInterestIcon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 2px;
  bottom: 2px;
  width: 32px;
  height: 32px;
  font-size: 16px;
  background-color: #3450b5;
  border-radius: 100%;
  border: 2px solid white;
  color: white;
}
.profilePowerHourIcon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 2px;
  top: 0px;
  width: 23px;
  height: 23px;
  font-size: 14px;
  background-color: #28ca42;
  border-radius: 100%;
  border: 2px solid white;
  color: white;
}
.curatedAutoSendButton {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
