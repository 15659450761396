@import 'src/less/colors';

.counter {
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  margin: 0 8px;
  padding: 0 4px;
  font-size: 12px;
  font-family: Graphik, sans-serif;
  font-weight: 500;
  line-height: 16px;
  background-color: @SecondaryBlue10;
  color: @PrimaryCobalt40;

  &.active {
    background-color: @SecondarySkyBlue20;
    color: @SecondaryBlue;
  }
}
