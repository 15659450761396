@import 'src/less/colors';

.trigger {
  margin: 0;
  padding: 0;
  font-family: Graphik;
  color: @SecondaryBlue;
  font-weight: 500;
  background-color: transparent;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .arrow {
    margin-right: 0.25em;
    transition: transform 150ms ease;

    &.folded {
      transform: rotate(-0.25turn);
    }
  }
}

.content {
  margin-top: 4px;
}
