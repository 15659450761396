/*
 * colors.less
 */
.modal {
  padding: 40px;
}
.title {
  font-family: Gilroy;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #1f2e77;
}
.paragraph {
  margin: 32px 0;
  font-family: Graphik;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #1f2e77;
}
.cta {
  display: flex;
  margin: auto;
  width: 300px;
}
.cta:not(:last-child) {
  margin-bottom: 12px;
}
