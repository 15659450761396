@import 'src/less/colors.less';

div.providersSettings:global(.settings) {
  h2 {
    color: @PrimaryCobalt;
    margin-bottom: 5px;
  }

  .section {
    margin-top: 20px;
  }
}

.providersGrid {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;

  :global(.integration-link) {
    width: 100%;
  }
}
