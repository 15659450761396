.gmail-alias-preview {
  background-color: #f4f7f7;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px;
}
.gmail-alias-preview .gmail-icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  opacity: 0.16;
  margin-right: 10px;
  flex-shrink: 0;
}
.gmail-alias-preview .square-outline {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  opacity: 0.16;
  margin-right: 10px;
  flex-shrink: 0;
  background-image: url(https://www.gstatic.com/images/icons/material/system/2x/check_box_outline_blank_black_20dp.png);
}
.gmail-alias-preview .star-outline {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  opacity: 0.16;
  margin-right: 10px;
  flex-shrink: 0;
  background-image: url(https://www.gstatic.com/images/icons/material/system/2x/star_border_black_20dp.png);
}
.gmail-alias-preview .important-arrow {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.16;
  margin-right: 10px;
  flex-shrink: 0;
  background-image: url(https://www.gstatic.com/images/icons/material/system/2x/label_important_googyellow500_20dp.png);
  background-size: 20px;
  opacity: 0.7;
}
.gmail-alias-preview .alias {
  color: #202124;
  display: inline;
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.gmail-alias-preview .alias.placeholder {
  opacity: 0.8;
}
