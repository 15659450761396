/*
 * colors.less
 */
.recommendation-criterias {
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #dae0f4;
}
.recommendation-criterias .title {
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Graphik, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #1f2e77;
}
.recommendation-criterias .title .icon {
  vertical-align: text-bottom;
  margin-right: 8px;
  color: #4864c9;
}
.recommendation-criterias .settings .settings-title {
  color: #1f2e77;
  font-weight: 500;
  font-size: 12px;
}
.recommendation-criterias .settings .tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin: 8px 0px 16px;
}
.recommendation-criterias .settings .tags .calibration-label {
  padding: 8px;
  line-height: 12px;
  border-radius: 2px;
  background-color: #f3f4f8;
  color: #4c5892;
}
