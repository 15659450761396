.settingsDropdown {
  margin-left: 12px;
  padding: 4px 7px;
  color: #7982ad;
  border-radius: 2px;
  border: 1px solid #d2d5e4;
  background-color: white;
}
.dropdown {
  display: flex !important;
}
.icon {
  margin-top: 2px;
}
