/*
 * colors.less
 */
.ui.modal.new-sender-modal.configure-users .users-checkbox-list .checkbox-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
}
.ui.modal.new-sender-modal.configure-users .users-checkbox-list .checkbox-list-item {
  margin-bottom: 10px;
}
.ui.modal.new-sender-modal.configure-users .users-checkbox-list .checkbox-list-item label {
  font-family: Graphik, sans-serif;
  font-size: 15px;
  color: #2b3d4f;
}
.modal-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
