/*
 * colors.less
 */
.activityFeedTrigger {
  width: 100%;
  display: block;
  text-align: end;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 12px;
  line-height: 140%;
  color: #5c89eb;
  font-weight: 400;
  margin-top: 13px;
}
.activityFeedModal {
  background: #ffffff;
  border: 1px solid #dae0f4;
  position: absolute;
  right: 0;
  top: 63px;
  min-width: 38vw;
  height: 93vh;
  z-index: 9;
}
.activityFeedContent {
  padding: 20px 15px;
  height: 86vh;
  overflow-y: scroll;
}
.closeModal {
  background-color: #ffffff !important;
  position: absolute;
  right: 39vw;
  top: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.actionsTabs {
  box-shadow: inset 0px -1px 0px #dae0f4;
  font-size: 14px;
  display: flex;
  gap: 20px;
  color: #a5abc9;
  cursor: pointer;
}
.tabActive {
  color: #5c89eb;
  font-weight: 500;
  border-bottom: 1px solid #5c89eb;
  padding: 14px 16px;
}
.tab {
  padding: 14px 16px;
}
.emptyActivity {
  font-size: 12px;
  color: #7982ad;
}
