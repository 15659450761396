@import 'src/less/colors';

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftPan,
  .rightPan {
    flex: 1 1;

    .panTitle {
      font-family: Graphik;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: @PrimaryCobalt;
    }

    .subTitle {
      font-family: Graphik;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: @PrimaryCobalt80;
      margin: 16px 0px 8px;
    }
  }
}
