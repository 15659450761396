/*
 * colors.less
 */
.pricingValidationModal .subTitle {
  color: #1f2e77;
  padding: 36px 42px;
}
.pricingValidationModal .content {
  padding: 0px 42px 12px;
  color: #1f2e77;
}
.pricingValidationModal .content li {
  margin: 14px 0px;
  line-height: 22px;
}
.pricingValidationModal .content .conditions {
  margin-top: 32px;
  position: relative;
}
.pricingValidationModal .content .conditions .checkboxContainer {
  margin: 12px 0px;
}
.pricingValidationModal .content .conditions .checkboxContainer label {
  font-size: 14px !important;
}
