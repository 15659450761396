/*
 * colors.less
 */
.card {
  margin: 16px 0;
  min-width: 450px;
  display: grid;
  grid-template-rows: auto 1fr;
  background: white;
  border: 1px solid #dae0f4;
  border-radius: 8px;
}
.card .cardHeader {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dae0f4;
  padding: 0 24px;
}
.card .cardHeader h3 {
  white-space: nowrap;
  color: #1f2e77;
  margin: 0;
}
.card .cardBody {
  min-width: 0;
}
.card .cardBody .loader {
  margin: 100px auto;
}
.table .row {
  display: flex;
  align-items: center;
  padding: 18px 24px;
}
.table .row:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.table .header {
  color: #7982ad;
  height: 54px;
  padding: 18px 24px;
  background-color: #f8faff;
}
.table .header .cell {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #7982ad;
}
.table .cell {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: #1f2e77;
}
.table .cell:first-child {
  flex: 1;
}
.table .cell:not(:first-child) {
  width: 150px;
  text-align: center;
}
.table .cell:is(button):not(:disabled) {
  cursor: pointer;
}
.table .cell:is(button):not(:disabled):hover {
  text-decoration: underline;
}
.link .linkTitle {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.link .linkUrl {
  font-family: Graphik;
  font-weight: 400;
  font-size: 12px;
  color: #7982ad;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.linkSubtitle {
  overflow-wrap: break-word;
}
