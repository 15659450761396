/*
 * colors.less
 */
.search-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-filters:not(:empty) {
  margin: -4px;
  padding-bottom: 24px;
}
.search-filters .filter-label.ui.button {
  flex-shrink: 0;
  margin: 4px;
  display: flex;
  align-items: center;
  padding: 8px;
  font-weight: 500;
  font-family: Graphik, sans-serif;
  background-color: #f1f6ff;
}
.search-filters .filter-label.ui.button:hover {
  background-color: #e2ebff;
}
.search-filters .filter-label.ui.button .text {
  color: #4c5892;
  font-size: 12px;
  line-height: 16px;
}
.search-filters .filter-label.ui.button i.ri-close-line {
  margin-left: 8px;
  font-size: 16px;
}
.search-filters .filter-label.ui.button i.ri-close-line:hover {
  color: #7982ad;
}
.search-filters .reset-all-button.ui.button {
  flex-shrink: 1;
  white-space: nowrap;
  margin: 4px 4px 4px 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  border: none;
  background-color: white;
  color: #4864c9;
}
.search-filters .reset-all-button.ui.button:hover {
  background-color: white;
  color: #6d83d4;
}
.search-filters .reset-all-button.ui.button:active {
  background-color: white;
  color: #3450b5;
}
.ui.button.reset-button {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  border: none;
  background-color: white;
  color: #4864c9;
  text-decoration: underline;
}
.ui.button.reset-button:hover,
.ui.button.reset-button:focus {
  background-color: white;
  color: #4864c9;
}
.ui.button.reset-button:active {
  background-color: white;
  color: #1f2e77;
}
.ui.button.reset-button.main-reset {
  margin-left: 12px;
  height: 32px;
  padding: 10px 0;
  text-decoration: none;
}
.ui.button.reset-button.main-reset:hover,
.ui.button.reset-button.main-reset:active {
  text-decoration: underline;
}
